import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { usePhoneInput } from 'react-international-phone';
import isMailFine from 'is-mail-fine';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// style
import * as S from './ClientGeneralInformation.styled';

// components
import { BaseInput } from 'components/Inputs';
import { FormContainer } from 'components/Form/FormContainer';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';
import Loader from 'components/Loader/Loader';
import {
  FormProvider,
  Input,
  InputDate,
  InputPhone,
  Select,
} from 'components/HookForm';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import useFunctionBar from 'hooks/useFunctionBar';
import useTranslate from 'hooks/useTranslate';
import useMediaQuery from 'hooks/useMediaQuery';
import { useClientsPost } from 'services/clientService';
import { useFormStatus } from 'hooks/useFormStatus';

export const ClientGeneralInformation = () => {
  const { clientId } = useParams();
  const isNewClient = clientId === 'new';
  const { locale } = useContext(UserContext);
  const tr = useTranslate().use().global;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const mutationContext = useClientsPost({
    searchParams: {
      useVersioning: 1,
    },
  });
  const {
    mutate,
    isSuccess: mutationSuccess,
    isError: mutationError,
    data: mutationResponse,
  } = mutationContext;
  const {
    setFunctions,
    setButtons,
    // buttonHandler,
    clientData: data,
    setIsGoBackEnabled,
    clientContext,
  } = useOutletContext();
  const functionBarFunctions = useFunctionBar([]);
  const {
    country: { dialCode },
  } = usePhoneInput({
    defaultCountry: new Intl.Locale(locale).region.toLowerCase(),
  });
  const bypassUnsavedChanges = useRef();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .max(
        50,
        `${tr['name']} ${tr['can-not-exceed'].toLowerCase()} 50 ${tr['characters'].toLowerCase()}`,
      )
      .test(
        'is-name-or-surname-filled',
        tr['name-or-surname-required'],
        (value, ctx) => value.trim() || ctx.parent.lastName.trim(),
      ),
    lastName: yup
      .string()
      .max(
        50,
        `${tr['lastName']} ${tr['can-not-exceed'].toLowerCase()} 50 ${tr['characters'].toLowerCase()}`,
      )
      .test(
        'is-name-or-surname-filled',
        tr['name-or-surname-required'],
        (value, ctx) => value.trim() || ctx.parent.firstName.trim(),
      ),
    country: yup
      .string()
      .max(
        100,
        `${tr['country']} ${tr['can-not-exceed'].toLowerCase()} 100 ${tr['characters'].toLowerCase()}`,
      ),
    city: yup
      .string()
      .max(
        200,
        `${tr['city']} ${tr['can-not-exceed'].toLowerCase()} 200 ${tr['characters'].toLowerCase()}`,
      ),
    email: yup
      .string()
      .max(
        254,
        `${tr['firstName']} ${tr['can-not-exceed'].toLowerCase()} 254 ${tr['characters'].toLowerCase()}`,
      )
      .email(tr['invalid-email'])
      .test('is-valid', tr['invalid-email'], (value) =>
        value ? isMailFine(value) : true,
      ),
    isActive: yup.boolean(),
    note: yup
      .string()
      .max(
        8000,
        `${tr['note']} ${tr['can-not-exceed'].toLowerCase()} 8000 ${tr['characters'].toLowerCase()}`,
      ),
  });

  const defaultValues = {
    id: data?.customer?.id,
    firstName: data?.customer?.name ?? '',
    lastName: data?.customer?.lastName ?? '',
    birthday: data?.customer?.birthday ?? null,
    phone: {
      prefix: data?.customer?.phonePrefix ?? '+' + dialCode,
      number: data?.customer?.phoneNumber ?? '',
    },
    country: data?.customer?.country ?? '',
    city: data?.customer?.city ?? '',
    email: data?.customer?.email ?? '',
    isActive: isNewClient ? true : (data?.customer?.isActive ?? null),
    note: data?.customer?.note ?? '',
    version: data?.customer?.version ?? null,
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    clearErrors,
    formState: { isDirty },
  } = methods;

  const onSubmit = useCallback((data) => {
    const postData = structuredClone(data);

    if (data.phone.prefix && data.phone.number) {
      postData.phonePrefix = data.phone.prefix;
      postData.phoneNumber = data.phone.number;
    }

    if (data?.firstName) {
      postData.name = data.firstName;
    }

    delete postData.phone;
    mutate(postData);
  }, []);

  const { isLoading, isFormDisabled } = useFormStatus({
    resourceContext: clientContext,
    mutationContext: mutationContext,
    setButtons: setButtons,
    methods: methods,
    hasInitialResourse: !isNewClient,
    setIsGoBackEnabled: setIsGoBackEnabled,
    bypassUnsavedChangesRef: bypassUnsavedChanges,
    resetForm: () => reset(defaultValues),
    submit: handleSubmit(onSubmit),
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  useEffect(() => {
    clearErrors('lastName');
  }, [firstName]);

  useEffect(() => {
    clearErrors('firstName');
  }, [lastName]);

  useEffect(() => {
    if (data?.customer) {
      reset(defaultValues);
    }
  }, [data]);

  useEffect(() => {
    setFunctions(functionBarFunctions);
  }, []);

  useEffect(() => {
    bypassUnsavedChanges.current = false;
  }, [clientId]);

  useEffect(() => {
    if (mutationSuccess) {
      bypassUnsavedChanges.current = true;
      const responseCustomerId = mutationResponse?.data?.customer?.id;
      let successMessage;

      if (isNewClient && responseCustomerId) {
        navigate(
          '/functions/tables/clients/' +
            responseCustomerId +
            '/general-information',
          {
            replace: true,
          },
        );
        successMessage = tr['client-created'];
      } else {
        successMessage = tr['client-updated'];
      }

      if (mutationResponse.status === 200) {
        toast.success(successMessage);
      }

      if (clientId !== 'new') {
        clientContext.refetch();
      }
    }
  }, [mutationSuccess, mutationError]);

  const cardCreationDate = data?.customer?.createTimeUtc
    ? DateTime.fromISO(data.customer.createTimeUtc, {
        zone: 'utc',
        locale,
      }).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    : '';

  return (
    <S.ClientGeneralInformation>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <FormProvider methods={methods}>
            <FormContainer disabled={isFormDisabled}>
              <div className="form">
                <div className="row">
                  <div className="input-container">
                    <Input
                      name="firstName"
                      label={tr['firstName']}
                      disabled={isFormDisabled}
                    />
                  </div>
                  <div className="input-container">
                    <Input
                      name="lastName"
                      label={tr['lastName']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-container">
                    <InputDate
                      name="birthday"
                      label={tr['dob']}
                      disabled={isFormDisabled}
                    />
                  </div>
                  <div className="input-container">
                    <InputPhone
                      name="phone"
                      prefixName="phonePrefix"
                      label={tr['phone-number']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-container">
                    <Input
                      name="country"
                      label={tr['country']}
                      disabled={isFormDisabled}
                    />
                  </div>
                  <div className="input-container">
                    <Input
                      name="city"
                      label={tr['city']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-container">
                    <BaseInput
                      id="createTimeUtc"
                      label={tr['registerDate'] + ' (UTC)'}
                      value={cardCreationDate}
                      disabled={true}
                    />
                  </div>
                  <div className="input-container">
                    <Input
                      name="email"
                      label={tr['email']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-container">
                    <Select
                      name="isActive"
                      label={tr['activity']}
                      disabled={isFormDisabled}
                      options={[
                        { label: tr['active'], value: true },
                        { label: tr['inactive'], value: false },
                      ]}
                    />
                  </div>
                  <div className="input-container">
                    <Input
                      name="note"
                      label={tr['note']}
                      disabled={isFormDisabled}
                    />
                  </div>
                </div>
              </div>
            </FormContainer>
          </FormProvider>
          <UnsavedChangesDialogBox
            condition={isDirty}
            checkIfBypass={() => bypassUnsavedChanges.current}
          />
        </>
      )}
    </S.ClientGeneralInformation>
  );
};
