import styled from 'styled-components/macro';
import { RootContainer, ScrollableContainer } from 'pages/Pages.styled';

export { RootContainer, ScrollableContainer };

export const TermsAndConditions = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  gap: 60px;
  /* height: calc(100vh - 186.5px); */
  overflow: auto;

  /* ::-webkit-scrollbar {
    display: none;
  } */

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
