import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const DialogPresets = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  width: calc(100% - 100px);

  @media screen and (max-width: 960px) {
    width: calc(100% - 40px);
  }

  .content {
    font-family: ${fontFamilies.family_Secondary};

    max-width: 560px;
    padding: 10px 0px;
  }

  a {
    color: ${colors.link};
  }
`;
