import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './MobileFilter.styled';

// icons
import { ArrowRevert } from 'icons/dynamic';

// components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import TheButton from 'components/Buttons/TheButton';
import { FilterRow } from './partials/FilterRow/FilterRow';
import { SubDialog } from './partials/SubDialog/SubDialog';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useTable } from 'hooks/useTable';

export const MobileFilter = ({ onClose }) => {
  const tr = useTranslate().use().global;
  const { applyFilters } = useTable();
  const {
    state: {
      filters: { state: filtersState },
    },
  } = useContext(TableContext);

  const handleClose = () => {
    applyFilters(filtersState);
    onClose();
  };

  return (
    <S.MobileFilter>
      <ContentLayout hideFab>
        <DialogLayout headerText={tr['filters']} getBack={handleClose} modal>
          <Content tr={tr} />
        </DialogLayout>
      </ContentLayout>
    </S.MobileFilter>
  );
};

export const Content = ({ tr }) => {
  const {
    state: {
      columns,
      filters: { state: filtersState },
    },
  } = useContext(TableContext);
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const { setFilter, clearFilters, resetFilters } = useTable();
  const [targetColumn, setTargetColumn] = useState();

  useEffect(() => {
    resetFilters();
  }, []);

  const isVisible = (column) => {
    if (!sectorId && column?.isSectorSpecific) {
      return;
    }

    return !!column.filterType;
  };

  const onlyActivityFilterActive =
    filtersState.length == 1 && filtersState[0].field === 'IsActive';

  return (
    <>
      <S.FilterList>
        {columns?.map(
          (column) =>
            isVisible(column) && (
              <FilterRow
                column={column}
                key={column.id}
                setTargetColumn={setTargetColumn}
              />
            ),
        )}
        {targetColumn && (
          <SubDialog
            tableColumn={targetColumn}
            onClose={() => setTargetColumn(null)}
            setFilter={setFilter}
          />
        )}
      </S.FilterList>
      <S.Controls>
        {!onlyActivityFilterActive && (
          <TheButton
            secondary
            raised
            action={clearFilters}
            title={tr['reset']}
            icon={<ArrowRevert bold />}
            width="50%"
            height="35"
          />
        )}
      </S.Controls>
    </>
  );
};
