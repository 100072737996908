import React, { useState, useEffect, useContext } from 'react';

import { useTranslate } from 'hooks/useTranslate';

// Style
import * as S from './SelectExcuses.styled';

// Component
import SelectList from '../CustomSelect/children/SelectList/SelectList';

// Context
import { CreateOrderContext } from 'context/SchedulerCreatingEventContext';

const SelectExcuses = ({
  arr,
  select,
  currentlySelected,
  close,
  overrideSaveFunc,
  overrideSaveCond,
}) => {
  // Hooks
  // -- translation
  const tr = useTranslate().use().global;

  // -- state
  // --- local
  const [excuseInput, setExcuseInput] = useState('');

  // --- global
  const { excuse } = useContext(CreateOrderContext);

  // -- side efect
  useEffect(() => {
    if (currentlySelected) {
      switch (currentlySelected.type) {
        case 'list':
          return;
        case 'other':
          setExcuseInput(currentlySelected.label);
          break;
        default:
          return;
      }
    }
  }, [currentlySelected]);

  useEffect(() => {
    overrideSaveFunc(
      () => () =>
        excuse.setExcuse({
          label: excuseInput,
          type: 'other',
          category: 'excuse',
        }),
    );
    overrideSaveCond(excuseInput.length !== 0);
  }, [excuseInput]);

  return (
    <S.SelectExcusesContainer>
      <SelectList
        arr={arr}
        action={(x) => {
          select(x);
          // additionalInput.setInput('');
          close();
        }}
        currentlySelected={(id) => {
          if (!id) return;
          return currentlySelected?.id === id;
        }}
      />
      <div className="otherExcuse">
        <span className="otherExcuse_label">{tr.annotherReason}</span>
        <input
          className="otherExcuse_input"
          type="text"
          value={excuseInput}
          onChange={(e) => setExcuseInput(e.target.value)}
        />
      </div>
    </S.SelectExcusesContainer>
  );
};

export default SelectExcuses;
