import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const ButtonSquare = styled.div`
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  background-color: ${colors.bg_grey_1};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${shadow.xsm};
  border-radius: 3px;
  cursor: pointer;

  .square-button {
    &__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${({ height }) => (height ? height : 'auto')};
      height: ${({ height }) => (height ? height : 'auto')};
    }

    &__text-container {
      display: flex;
      padding-right: 5px;
    }
  }
`;
