import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const ClientItem = styled.div`
  background-color: ${({ isSelected }) =>
    isSelected ? colors.bg_grey_1 : 'unset'};

  &:last-of-type .container {
    border-bottom: 1px solid ${colors.bg_grey_1};
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${colors.bg_grey_1};
    height: 50px;
  }

  .innerDiv {
    display: flex;
  }

  .clientInfoWrapper {
    width: 100%;
  }

  .clientName {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: ${fontFamilies.family_Secondary};

    .nameWrapper {
      margin-right: 5px;

      max-width: 100%;
      min-width: 100%;

      width: 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .firstName {
      font-weight: 900;
    }
  }

  .additionalInfo {
    font-size: 12px;
  }

  .clientActions {
    min-width: 50px;

    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-right: 5px;

    .arrowRight {
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 20px;
    }
  }
`;
