import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

// style
import * as S from './Projects.styled';

// components
import { getAppUserCredentials } from 'pages/Login/AuthProviders/AuthProviders';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { TheButton } from 'components/Buttons/TheButton';
// import { Footer } from 'components/Footer/Footer';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

const Projects = () => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };
  const isMobile = useMediaQuery('(max-width: 960px)');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { token, user, companyId, setCompany, setEmployees } =
    useContext(UserContext);

  const selectCompany = (e, companyId) => {
    e.preventDefault();
    e.stopPropagation();

    console.log({ user, token });

    queryClient.removeQueries(['sectors']);
    getAppUserCredentials(token, companyId)
      .then((apiUserResponse) => {
        console.log({ apiUserResponse });

        setCompany(apiUserResponse.data.companyId, apiUserResponse.data.token);
        setCurrentCompany(apiUserResponse.data.companyId);
        setEmployees(apiUserResponse.data.employeeIds);

        console.log({ e, companyId });
        // TODO: implement call to B/E to receive token and save it to localStorage
        navigate(APP_ROOT);
      })
      .catch((err) => {
        console.log(err);

        if (err.response?.status === 401) {
          navigate('/login');

          return;
        }

        let errorMessage;

        switch (err.code) {
          case 'ERR_BAD_REQUEST':
            errorMessage = tr.global['company-unavailable'];
            break;
          default:
            errorMessage = err.response?.data?.title;
            Object.entries(err.response?.data?.errors).forEach((element) => {
              errorMessage += ' ' + element[0] + ': ' + element[1][0];
            }, errorMessage);
        }

        toast.error(errorMessage);
      });
  };

  const createCompany = () => {
    console.log('TODO: create new business account');
    navigate(APP_ROOT + 'login', { state: { newCompany: true } });
  };

  const [currentCompany, setCurrentCompany] = useState(companyId);
  useEffect(() => {
    // console.log({ currentCompany, companyId });
    setCurrentCompany(companyId);
  }, [companyId, currentCompany]);

  return (
    <S.Projects>
      <S.RootContainer>
        <PageHeader>
          <div className="control">
            <TheButton
              title={tr.global['create-new-business-account']}
              action={createCompany}
              raised={isMobile}
            />
          </div>
        </PageHeader>

        <S.ScrollableContainer>
          <S.Container>
            {user?.companies?.length > 0 &&
              user?.companies
                ?.sort((a, b) =>
                  !a.isAccessible && b.isAccessible
                    ? +1
                    : a.isAccessible && !b.isAccessible
                      ? -1
                      : 0,
                )
                .map((company, index) => (
                  <S.CompanyCard
                    key={index}
                    error={!company.isAccessible}
                    // error={true}
                    onClick={(e) => selectCompany(e, company.id)}
                    current={!!(currentCompany === company.id)}
                  >
                    {/* <span></span> */}
                    <div className="name">
                      <span>{company.name}</span>
                      <span>→</span>
                    </div>

                    <div className="address">Karieso g. 4. Vilnius 07322</div>

                    {!company.isAccessible && (
                      <div className="message">
                        {company.status.toLowerCase() === 'queued'
                          ? tr.global['company-creation-in-progress']
                          : tr.global['license-expired']}
                      </div>
                    )}
                  </S.CompanyCard>
                ))}
          </S.Container>
        </S.ScrollableContainer>
      </S.RootContainer>

      {/* {!isMobile && <Footer content={2} />} */}
    </S.Projects>
  );
};

export default Projects;
