import { colors } from 'common/colors/colors';
import { Link as CRELink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(CRELink)`
  color: ${colors.text_Secondary};
  text-decoration: none;

  :hover {
    text-decoration: underline;
    text-decoration-color: ${colors.text_Secondary};
  }

  :visited {
    color: unset;
  }

  :active {
    color: ${colors.text_Secondary};
  }
`;
