// style
import * as S from './SelectList.styled';

// icons
import { Check } from 'icons/dynamic';

// components
import { Loader } from 'components/Loader/Loader';

export const SelectList = ({ arr, action, currentlySelected, belongsTo }) => {
  return (
    <S.UList className="listOfExcuses">
      {arr ? (
        arr.map((item, i) => (
          <li key={i} onClick={() => action(item, belongsTo)}>
            {item.label}
            {(currentlySelected(item.id) || currentlySelected(item?.value)) && (
              <Check black width={16} color="#ff0000" />
            )}
          </li>
        ))
      ) : (
        <Loader />
      )}
    </S.UList>
  );
};

export default SelectList;
