import { useContext, useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { PhoneInput } from 'react-international-phone';

// style
import * as S from 'components/InputFieldPhone/InputFieldPhone.styled';
import { StyledInputErrorMessage } from '../InputsCommon.styled';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import useTranslate from 'hooks/useTranslate';

export default ({ id, label, disabled, value, errorMessage, ...other }) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);

  const [dropdownDisplayStyle, setDropdownDisplayStyle] = useState('none');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const phoneInputRef = useRef();
  const flagButtonRef = useRef();
  const dropdownRef = useRef();

  let delayedClose;

  const handleClick = (e) => {
    e.stopPropagation();
    clearTimeout(delayedClose);

    flagButtonRef.current = phoneInputRef.current.querySelector(
      '.react-international-phone-country-selector-button',
    );
    dropdownRef.current = phoneInputRef.current.querySelector(
      '.react-international-phone-country-selector-dropdown',
    );

    setDropdownVisible(false); // need to close in case of re-rendered inpyut
    // need timeout this to determine actual attribute value
    setTimeout(() => {
      setDropdownVisible(
        flagButtonRef.current?.getAttribute('aria-expanded') === 'true',
      );
    });
  };

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    if (!dropdownVisible) {
      delayedClose = setTimeout(() => {
        flagButtonRef.current.setAttribute('aria-expanded', false);
        setDropdownDisplayStyle('none');
      }, 180);
    } else {
      flagButtonRef.current.setAttribute('aria-expanded', true);
      setDropdownDisplayStyle('block');
    }
  }, [dropdownVisible]);

  return (
    <ClickAwayListener
      mouseEvent="mousedown"
      onClickAway={() => dropdownVisible && flagButtonRef.current.click()}
    >
      <S.InputFieldPhone
        onClick={handleClick}
        dropdownDisplayStyle={dropdownDisplayStyle}
        dropdownVisible={dropdownVisible}
        ref={phoneInputRef}
        phoneInputRef={phoneInputRef.current}
        isInvalid={!!errorMessage}
      >
        <label htmlFor={id}>{label}</label>
        <div className="input-wrapper">
          {disabled ? (
            <div className="disabled-input">{value || '-'}</div>
          ) : (
            <PhoneInput
              defaultCountry={new Intl.Locale(locale).region.toLowerCase()}
              value={value || ''}
              inputProps={{
                id,
                placeholder: tr['input'],
                disabled,
              }}
              {...other}
            />
          )}
        </div>
        <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
      </S.InputFieldPhone>
    </ClickAwayListener>
  );
};
