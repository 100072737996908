import { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import AutoNumeric from 'autonumeric';

// style
import * as S from './ServiceCard.styled';

// icons
import { CubeSVG, Minus } from 'icons/dynamic';

// components
import CustomSelect from 'components/DialogLayout/CustomSelect/CustomSelect';
import TitleAndValueChild from 'components/DialogLayout/CustomSelect/children/TitleAndValueChild/TitleAndValueChild';
import ServiceCardComposition from './ServiceCardComposition/ServiceCardComposition';
import { BaseInput } from 'components/Inputs';
import { FlashMessage } from 'components/FlashMessage/FlashMessage';

// hooks
import useNumber from 'hooks/useNumber';
import { useTranslate } from 'hooks/useTranslate';
import useService from 'hooks/useService';
import { useSystemGetUnits } from 'services/systemService';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';
import { FLASH_MESSAGE_TYPE } from 'common/constants';

const ServiceCard = ({
  service,
  deleteService,
  openModal,
  setModalContent,
  opened,
  updateService,
}) => {
  const tr = useTranslate().use().global;
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits, currencySymbol, currencySymbolPlacement } =
    useNumber({ price: true });
  const { getTranslatedSmsDuration } = useService({});
  const [compositionIsOpened, setCompositionIsOpened] = useState(false);

  const composition = !!service.composition?.length;
  const unitShortName = getUnitsById(service.unitId)?.label;

  useEffect(() => {
    if (service.quantity === 0) {
      handleAmountBlur(1);
    }
  }, [service.quantity]);

  const handleAmountBlur = (val) =>
    updateService(
      {
        quantity: val,
        ...(service.quantity > val && {
          discountWithVat: 0,
        }),
      },
      service.index,
    );

  const updatePrice = (val) =>
    updateService(
      {
        unitPriceWithVat: val,
        ...(service.unitPriceWithVat > val && {
          discountWithVat: 0,
        }),
      },
      service.index,
    );

  const openSelection = (prompt, flashMessages) => {
    setModalContent({ content: prompt, flashMessages });
    opened.setOpenedProduct(service);
    openModal();
  };

  const titleDanger = service?.canBeSold === false;
  const durationDanger =
    service?.unitDurationInMinutesNorm !== undefined &&
    service?.unitDurationInMinutes !== service.unitDurationInMinutesNorm;

  const priceDifferFromNorm =
    service?.unitPriceWithVat !== service.unitPriceWithVatNorm;

  const vatDifferFromNorm = service?.vatPercent !== service.vatPercentNorm;
  const smsDifferFromNorm =
    service?.smsPeriod !== undefined &&
    service?.smsPeriod !== service.smsPeriodCustomer;

  return (
    <S.StyledServiceCard compositionIsOpened={compositionIsOpened}>
      <div className="serviceHeader">
        <div className="deleteAndPath">
          <div
            className="productPath"
            // onClick={() => setCompositionIsOpened((prev) => !prev)}
          >
            {composition && (
              <div className={`comp_icon${titleDanger ? ' danger' : ''}`}>
                <CubeSVG />
              </div>
            )}
            <span>{service.parentTitle && service.parentTitle + ' / '} </span>
            <span
              className={titleDanger ? 'danger' : ''}
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                titleDanger ? tr['service-not-provided'] : '',
              )}
            >
              {service.name}
            </span>
            {/* {composition && (
              <div className="comp_arrow">
                <Arrow bold width={16} height={16} />
              </div>
            )} */}
          </div>
        </div>
        <div className="deleteBtn" onClick={() => deleteService(service.index)}>
          <Minus black width={10} height={10} />
        </div>
      </div>
      {composition && (
        <div className="comp_container">
          <div className="inner">
            <ServiceCardComposition composition={service.composition} />
          </div>
        </div>
      )}
      <div className="serviceInfoForm">
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild title={`${tr['duration']}/${tr['volume']}`}>
              {service?.unitId !== null && service?.unitId !== undefined ? (
                formatUnits(service.unitVolume) +
                (!!unitShortName && ` ${unitShortName}`)
              ) : (
                <span
                  className={durationDanger ? 'danger' : ''}
                  data-tooltip-id="tooltip"
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    durationDanger ? (
                      <>
                        {`${tr['current-duration']}: ${getTimeFromMinutes(service.unitDurationInMinutesNorm)}`}
                      </>
                    ) : (
                      ''
                    ),
                  )}
                >
                  {getTimeFromMinutes(service.unitDurationInMinutes)}
                </span>
              )}
            </TitleAndValueChild>
            <TitleAndValueChild
              title={tr.sms}
              editable
              arrow
              action={() =>
                openSelection(
                  'select-sms',
                  smsDifferFromNorm && (
                    <FlashMessage type={FLASH_MESSAGE_TYPE.WARNING}>
                      {tr['current-sms']}: {service.smsPeriod}
                    </FlashMessage>
                  ),
                )
              }
              warning={smsDifferFromNorm}
            >
              {getTranslatedSmsDuration(service?.smsPeriodCustomer)}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild title={tr.unitPrice}>
              <span
                data-tooltip-id="tooltip"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  priceDifferFromNorm ? (
                    <>
                      {`${tr['current-price']}: ${
                        service.unitPriceWithVatNorm === null
                          ? '-'
                          : formatPrice(service.unitPriceWithVatNorm, true)
                      }`}
                    </>
                  ) : (
                    ''
                  ),
                )}
              >
                <BaseInput
                  className={
                    priceDifferFromNorm || service?.canBeSold === false
                      ? 'danger'
                      : ''
                  }
                  type="number"
                  value={service.unitPriceWithVat}
                  onBlur={updatePrice}
                  currencySymbol={currencySymbol}
                  currencySymbolPlacement={currencySymbolPlacement}
                  decimalPlaces={4}
                  minimumValue="0"
                  emptyInputBehavior={0}
                />
              </span>
            </TitleAndValueChild>
            <TitleAndValueChild title={tr.amount} editable>
              <BaseInput
                type="number"
                value={service.quantity}
                onBlur={(val) => {
                  handleAmountBlur(val);
                }}
                onFocus={(e) => e.target.select()}
                minimumValue="0"
                emptyInputBehavior={1}
              />
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild title={tr.vatRate} editable>
              <span
                data-tooltip-offset={6}
                data-tooltip-id="tooltip"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  vatDifferFromNorm
                    ? service.vatPercentNorm === null
                      ? '-'
                      : AutoNumeric.format(service.vatPercentNorm, {
                          decimalPlaces: 2,
                          currencySymbol: '%',
                          currencySymbolPlacement: 's',
                        })
                    : '',
                )}
              >
                <BaseInput
                  className={
                    vatDifferFromNorm || service.canBeSold === false
                      ? 'danger'
                      : ''
                  }
                  type="number"
                  placeholder="-"
                  value={service?.vatPercent}
                  onChange={(val) =>
                    updateService({ vatPercent: val }, service.index)
                  }
                  onBlur={(val) =>
                    updateService({ vatPercent: val }, service.index)
                  }
                  decimalPlaces={2}
                  currencySymbol="%"
                  currencySymbolPlacement="s"
                  maximumValue="99.99"
                  minimumValue="0"
                />
              </span>
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild
              title={tr.discount}
              editable
              arrow
              action={() => openSelection('select-discount')}
            >
              {formatPrice(service.discountWithVat)}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
        <CustomSelect paddingSize="SmEven">
          <div className="titleAndValueWrapper">
            <TitleAndValueChild
              title={tr.finalPrice}
              // children={formatPrice(service?.finalAmountWithVat || 0)}
              bold
            >
              {formatPrice(service?.finalAmountWithVat || 0)}
            </TitleAndValueChild>
          </div>
        </CustomSelect>
      </div>
    </S.StyledServiceCard>
  );
};

export default ServiceCard;
