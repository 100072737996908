import { useContext } from 'react';

// style
import * as S from '../../MobileFilter.styled';

// components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import SelectList from 'components/DialogLayout/CustomSelect/children/SelectList/SelectList';
import { FilterRow } from '../FilterRow/FilterRow';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useTranslate from 'hooks/useTranslate';

// helpers
import { TABLE_FILTER_TYPE } from 'common/constants';

export const SubDialog = ({ tableColumn, onClose, setFilter }) => {
  let content = <></>;
  const {
    state: {
      filters: { state: filtersState },
    },
  } = useContext(TableContext);

  switch (tableColumn.filterType) {
    case TABLE_FILTER_TYPE.INPUT_RANGE:
      content = (
        <RangeInputs
          tableColumn={tableColumn}
          filterType={TABLE_FILTER_TYPE.INPUT}
        />
      );

      break;
    case TABLE_FILTER_TYPE.DATE_RANGE:
      content = (
        <RangeInputs
          tableColumn={tableColumn}
          filterType={TABLE_FILTER_TYPE.DATE}
        />
      );

      break;
    case TABLE_FILTER_TYPE.SELECT:
      content = (
        <SelectList
          arr={tableColumn.options}
          currentlySelected={(value) =>
            value !== undefined &&
            value ===
              filtersState?.find(
                (filter) => filter.field === tableColumn.filter,
              )?.value
          }
          action={(option) =>
            setFilter(option.value, tableColumn.filter, false)
          }
        />
      );

      break;
  }

  return (
    <ContentLayout hideFab>
      <DialogLayout headerText={tableColumn?.label} getBack={onClose} modal>
        <S.FilterList>{content}</S.FilterList>
      </DialogLayout>
    </ContentLayout>
  );
};

const RangeInputs = ({ tableColumn, filterType }) => {
  const tr = useTranslate().use().global;

  const filterNames = {
    from: tableColumn.filter + 'From',
    to: tableColumn.filter + 'Till',
  };

  return (
    <>
      <FilterRow
        column={{
          label: tr['date'] + ' ' + tr['from'],
          filterType: filterType,
          filter: filterNames.from,
          inputProps: tableColumn?.inputProps,
        }}
      />
      <FilterRow
        column={{
          label: tr['date'] + ' ' + tr['to'],
          filterType: filterType,
          filter: filterNames.to,
          inputProps: tableColumn?.inputProps,
        }}
      />
    </>
  );
};
