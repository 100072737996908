import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const TimePanel = styled.div`
  min-width: ${(props) => (['en-US'].includes(props.locale) ? 87 : 57)}px;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
  padding-top: 10px;

  .time__cell {
    height: ${(props) => props.cellDuration && props.cellDuration * 20}px;
    position: relative;

    border-right: 1px solid ${colors.bg_grey_2};

    span {
      color: ${colors.bg_Primary};
      font-weight: 900;
      font-size: 0.75rem;
      font-family: ${fontFamilies.family_Sen};

      position: absolute;
      top: -6px;
      /* right: 15px; */
    }
  }

  .time__cell-border {
    border-top: 1px solid ${colors.bg_grey_2};
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
  }

  @media screen and (max-width: 960px) {
    min-width: ${(props) => (['en-US'].includes(props.locale) ? 61 : 41)}px;

    .time__cell {
      span {
        font-size: 11px;
        top: -6px;
        right: 10px;
      }
    }

    .time__cell-border {
      width: 5px;
    }
  }
`;
