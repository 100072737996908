import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const SetContents = styled.div`
  width: 100%;

  > label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  button {
    /* margin-top: 10px; */
    margin-left: auto;
    background-color: ${colors.bg_Secondary};
  }

  button {
    /* background-color: transparent; */
    border: none;
    border-radius: 50%;
    /* bottom: 18px; */
    line-height: 0;
    padding: 0;
    /* position: absolute; */
    /* right: 0; */
  }
`;

export const SetServiceContainer = styled.div`
  align-items: ${({ hasContents }) => !hasContents && 'center'};
  background-color: ${({ disabled }) => !disabled && colors.bg_grey_1};
  /* border: 1px solid ${colors.table_borders_Primary}; */
  border-radius: 5px;
  display: flex;
  flex-direction: ${({ hasContents }) => hasContents && 'column'};
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.875rem;
  gap: 10px;
  padding: ${({ disabled }) => !disabled && '20px'};

  @media (max-width: 960px) {
    margin-inline: -10px;
    padding: ${({ disabled }) => !disabled && '10px'};
  }

  .empty {
    font-size: 1rem;
  }
`;

export const SetService = styled.div`
  align-items: center;
  background-color: ${colors.bg_Secondary};
  border: 1px solid ${colors.bg_grey_2};
  border-radius: inherit;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 10px;

  @media (max-width: 960px) {
    padding-inline: 10px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  > button > svg {
    background-color: ${colors.bg_Secondary};
    cursor: pointer;
    height: 20px;
    flex-shrink: 0;
    width: 20px;
  }

  .info__title {
  }

  .info__title span {
    font-weight: bold;
  }

  .info__data {
    color: ${colors.bg_grey_3};
    font-size: 0.75rem;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 960px) {
      gap: 10px;
    }
  }

  .info__data span {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    white-space: nowrap;
    /* align-items: center; */
  }
`;
