import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

// context
import { UserContext } from 'context/UserContext';

export const useRestoreAppUserCredentials = () => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const navigate = useNavigate();
  const { token, user, companyId, updateUser } = useContext(UserContext);

  const [userLocal, setUserLocal] = useState(user);
  const [companyIdLocal, setCompanyIdLocal] = useState(companyId);

  const restoreAppUserCredentials = useCallback(
    async (token) =>
      await axios.get(`${process.env.REACT_APP_API}myInfo`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    [],
  );

  useEffect(() => {
    if (!token) {
      navigate(APP_ROOT + 'login');
    }
    if (!user) {
      // get user data to check for token type

      restoreAppUserCredentials(token)
        .then((apiResponse) => {
          // console.log({ apiResponse });
          updateUser(apiResponse.data);

          // restore user
          setUserLocal(apiResponse.data.user);

          // if there's company, restore company, otherwise navigate to dashboard for company selection
          if (!apiResponse.data.companyId) {
            navigate(APP_ROOT + 'dashboard');
          } else {
            setCompanyIdLocal(apiResponse.data.companyId);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // token expired
            navigate(APP_ROOT + 'login');
          }
          console.log({ err });
        });
    }
  }, [token, user, APP_ROOT, navigate, restoreAppUserCredentials, updateUser]);

  return { user: userLocal, companyId: companyIdLocal };
};
