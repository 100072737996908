import styled from 'styled-components/macro';

export const SetServiceSelectModal = styled.div`
  background-color: white;

  height: calc(100% - 80px);
  max-height: 1000px;

  display: flex;

  width: 1200px;

  border-radius: 10px;

  @media screen and (max-width: 960px) {
  }
`;
