import React, { memo } from 'react';
import * as S from './ColumnSkeleton.styled';

const ColumnSkeleton = ({ cells }) => {
  return (
    <S.ColumnSkeleton>
      {[...Array(cells)].map((item, i) => {
        return (
          <div key={i}>
            <div className="skeleton_body--cell"></div>
          </div>
        );
      })}
    </S.ColumnSkeleton>
  );
};

export default memo(ColumnSkeleton);
