import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

const gradient = (color) => `
    135deg,
    ${color} 16.67%,
    transparent 16.67%,
    transparent 50%,
    ${color} 50%,
    ${color} 66.67%,
    transparent 66.67%,
    transparent 100%
`;

export const MultiAppointmentsCard = styled.div`
  width: 100%;

  position: relative;

  border-radius: 5px;

  /* border: ${(props) => !props.draggable && '1px solid #768fd3'}; */

  background-image: ${({ color }) => `linear-gradient(${gradient(color)})`};
  background-size: 16.97px 16.97px;
`;

export const ServiceWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: ${(props) => props.topPosition}%;
  height: ${(props) => props.height}%;

  > div {
    border-radius: 0;
  }

  :first-child > div {
    border-radius: 5px 5px 0 0;
  }

  :last-child > div {
    border-radius: 0 0 5px 5px;
  }
`;
