import React, { Fragment } from 'react';

// Style
import * as S from './OrderRowEntries.styled';

// Components
import OrderRowEntry from './OrderRowEntry/OrderRowEntry';
import OrderRowBreak from '../OrderRowBreak/OrderRowBreak';
import OrderRowNote from '../OrderRowNote/OrderRowNote';

const OrderRowEntries = ({
  services,
  headers,
  deleteEntrie,
  updateServiceKey,
  updateAmount,
}) => {
  const rowEntrie = (i, service, isComp) => (
    <S.OrderRowEntries
      key={i}
      lastItem={services.length - 1 === i}
      isComp={isComp}
    >
      {headers.map((header, x) => (
        <td key={x}>
          <OrderRowEntry
            index={i}
            headerId={header.id}
            service={service}
            deleteEntrie={deleteEntrie}
            updateServiceKey={updateServiceKey}
            updateAmount={updateAmount}
          />
        </td>
      ))}
    </S.OrderRowEntries>
  );

  return (
    <>
      {services.map((service, i) => {
        const isBreak = service.serviceId === 1;
        const isLastItem = services.length - 1 === i;
        let serviceExistsInRestItems = false;
        for (let index = i; index <= services.length; index++) {
          const item = services[index];

          if (item?.serviceId !== 1 && item?.unitDurationInMinutes) {
            serviceExistsInRestItems = true;
            break;
          }
        }

        return isBreak ? (
          !isLastItem && serviceExistsInRestItems && (
            <OrderRowBreak
              colSpan={headers.length}
              key={i}
              index={i}
              updateBreak={updateServiceKey}
              data={service}
            />
          )
        ) : service.composition ? (
          <Fragment key={i}>
            {rowEntrie(i, service, true)}
            {/* {service.composition.map((compItem, index) => (
              <S.OrderRowEntries
                key={i + '-' + index}
                lastItem={services.length - 1 === i}
                compItem={{
                  last: index === service.composition.length - 1,
                }}
              >
                {headers.map((header, x) => (
                  <td key={x}>
                    <OrderRowEntry
                      index={i}
                      headerId={header.id}
                      service={compItem}
                      updateAmount={updateAmount}
                      compItem
                    />
                  </td>
                ))}
              </S.OrderRowEntries>
            ))} */}
          </Fragment>
        ) : (
          rowEntrie(i, service)
        );
      })}
      <OrderRowNote colSpan={headers.length} />
    </>
  );
};

export default OrderRowEntries;
