import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { fontFamilies } from 'common/fonts/fonts';

export const OnlineReservationsSettings = styled.section`
  /* flex: 1 0 auto; */
  /* display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  width: 100%;
  
  @media screen and (max-width: 960px) {
    height: calc(100vh - 54px);
  }
  
  > div:not(.footer) {
    flex: 1 0 auto;
  } */
`;

export const PageContents = styled.div`
  margin: 40px 40px 0;

  @media screen and (max-width: 960px) {
    margin: 10px;
  }
`;

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  /* flex-direction: column; */
  /* nav + & {
    height: 100vh;
    margin: 40px 40px 0;

    @media screen and (max-width: 960px) {
      margin: 10px;
    }
  } */

  @media screen and (max-width: 960px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

export const Title = styled.h2`
  font-family: ${fontFamilies.family_Primary};
  font-size: 30px;
  /* align-items: flex-end; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  line-height: 38px;

  /* h2 { */
  color: ${colors.text_Secondary};
  /* flex: auto; */
  font-size: 1.875rem;
  font-weight: 700;
  /* margin: 0 40px 20px 0; */
  margin: 0 0 20px;
  min-width: max-content;
  padding-bottom: 20px;
  /* display: flex; */
  /* justify-content: space-between; */
  border-bottom: 1px solid ${colors.table_border};

  @media (max-width: 960px) {
    font-size: 1.125rem;
    margin: unset;
    /* padding-bottom: 0; */
    width: 100%;
  }

  span {
    font-weight: 400;
  }

  div {
    flex: 1;
  }
  /* } */
`;

export const Links = styled.div`
  border-right: 1px solid ${colors.table_border};
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  padding-right: 40px;
  width: min-content;

  @media screen and (max-width: 960px) {
    border-right: unset;
    margin-right: unset;
    padding-right: 0;
    width: 100%;
  }

  a {
    align-items: center;
    border-left: 5px solid transparent;
    border-radius: 0 25px 25px 0;
    box-shadow: ${shadow.md};
    color: ${colors.text_Secondary};
    display: flex;
    height: 50px;
    font-weight: 900;
    text-decoration: none;
    padding: 0 20px 0 15px;
    white-space: nowrap;

    @media screen and (max-width: 960px) {
      box-shadow: unset;
      border-bottom: 1px solid ${colors.table_border};
      border-left: unset;
      border-radius: unset;
      padding-left: 0;
      width: 100%;
    }

    :not(:last-child) {
      margin-bottom: 10px;

      @media screen and (max-width: 960px) {
        margin-bottom: unset;
      }
    }

    &.active {
      border-left: 5px solid ${colors.tab_borders_active};

      @media screen and (max-width: 960px) {
        border: unset;
        border-bottom: 1px solid ${colors.table_border};
      }
    }
  }
`;

export const TabContents = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    width: 0;
  }
`;
