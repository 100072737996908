import { useCallback, useContext } from 'react';

// style
import * as S from './SchedulerCalendarDouble.styled';

// icons
import { ArrowRevert } from 'icons/dynamic';

// components
import { InputFieldDate } from 'components/InputFieldDate/InputFieldDate';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { UserContext } from 'context/UserContext';

export const SchedulerCalendarDouble = (props) => {
  const {
    state,
    activeStartDays,
    setActiveStartDays,
    focus,
    minDate,
    maxDate,
    resetToDefault,
    hasDateValues,
    setShouldRefetch,
    calendarType,
    ...restProps
  } = props;
  const { locale } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const tr = useTranslate().use().global;

  const formatMonthYear = useCallback(
    (locale, date) => {
      let monthLong = new Intl.DateTimeFormat(locale, {
        month: isMobile ? 'long' : 'numeric',
        year: 'numeric',
      }).format(date);

      monthLong = monthLong.charAt(0).toUpperCase() + monthLong.slice(1);

      return `\xa0\xa0${monthLong}\xa0\xa0`;
    },
    [isMobile],
  );

  // infrastructure required to override view changes when selecting
  // dates belonging to a different month from default activeStartDate
  const changeCurrentActiveStartDate = (
    action,
    activeStartDate,
    currentActiveStartDate,
    view,
  ) => {
    if (
      [
        'prev',
        'prev2',
        'next',
        'next2',
        'drillUp',
        'drillDown',
        'onChange',
      ].includes(action)
    ) {
      let newDate = new Date(currentActiveStartDate);
      let monthShift = 0,
        yearShift = 0;

      switch (action) {
        case 'prev':
          switch (view) {
            case 'month':
              monthShift = -1;
              break;
            case 'year':
              yearShift = -1;
              break;
            case 'decade':
              yearShift = -10;
              break;
            case 'century':
              yearShift = -100;
              break;
          }
          break;
        case 'next':
          switch (view) {
            case 'month':
              monthShift = +1;
              break;
            case 'year':
              yearShift = +1;
              break;
            case 'decade':
              yearShift = +10;
              break;
            case 'century':
              yearShift = +100;
              break;
          }
          break;
        case 'prev2':
          switch (view) {
            case 'month':
              yearShift = -1;
              break;
            case 'year':
              yearShift = -10;
              break;
            case 'decade':
              yearShift = -100;
              break;
          }
          break;
        case 'next2':
          switch (view) {
            case 'month':
              yearShift = +1;
              break;
            case 'year':
              yearShift = +10;
              break;
            case 'decade':
              yearShift = +100;
              break;
          }
          break;
        case 'drillUp':
        case 'drillDown':
          if (view === 'century') break; // eslint-disable-next-line
        case 'onChange':
          newDate = new Date(activeStartDate);
          break;
        default:
      }

      monthShift && newDate.setMonth(newDate.getMonth() + monthShift);
      yearShift && newDate.setFullYear(newDate.getFullYear() + yearShift);

      return newDate;
    }
  };

  const changeCurrentActiveStartDateFrom = useCallback(
    ({ action, activeStartDate, value, view }) => {
      setActiveStartDays((old) => {
        const newDates = { ...old };

        newDates.start = changeCurrentActiveStartDate(
          action,
          activeStartDate,
          newDates?.start,
          view,
        );

        return newDates;
      });
    },
    [setActiveStartDays],
  );

  const changeCurrentActiveStartDateTo = useCallback(
    ({ action, activeStartDate, value, view }) => {
      setActiveStartDays((old) => {
        const newDates = { ...old };

        newDates.end = changeCurrentActiveStartDate(
          action,
          activeStartDate,
          newDates?.end,
          view,
        );

        return newDates;
      });
    },
    [setActiveStartDays],
  );

  const sharedMenuProps = {
    labelPosition: isMobile && 'inline',
    stayOpen: !isMobile,
    width: 'calc(100% - 40px)',
    menuRender: 'relative',
    formatMonthYear,
    noIndicators: true,
    noToday: true,
    hasDateValues,
    setShouldRefetch,
    locale,
    calendarType,
    ...restProps,
  };

  const isExceedingLimit = (startDate, endDate) => {
    return (
      new Date(startDate).setFullYear(startDate.getFullYear() + 1) <
        new Date(endDate) ||
      new Date(endDate).setFullYear(endDate.getFullYear() - 1) >
        new Date(startDate)
    );
  };

  const changeValue = (headerId, value) => {
    const newDate = new Date(value?.value); // New date provided by the user

    // Case 1: If no dates are set, set both 'date-from' and 'date-to' to the same value
    if (!state['date-from']?.value && !state['date-to']?.value) {
      state['date-from'].setValue('date-from', {
        value: newDate,
        label: newDate.toLocaleDateString(locale),
      });
      state['date-to'].setValue('date-to', {
        value: newDate,
        label: newDate.toLocaleDateString(locale),
      });

      // Update activeStartDays to reflect the same date for both start and end
      setActiveStartDays((old) => ({
        ...old,
        start: newDate,
        end: newDate, // Make sure start and end are the same initially
      }));
      return; // Exit here since no further checks are needed when both dates are initially set
    }

    if (headerId === 'date-from') {
      let currentEndDate = state['date-to']?.value
        ? new Date(state['date-to'].value.value)
        : null;

      // Case 2: If date-to is not set or new start date is after date-to, or it exceeds the 1-year limit
      if (
        !currentEndDate ||
        newDate > currentEndDate ||
        isExceedingLimit(newDate, currentEndDate)
      ) {
        currentEndDate = new Date(newDate); // Adjust date-to to be 1 year after date-from
        isExceedingLimit(newDate, currentEndDate) &&
          currentEndDate.setFullYear(newDate.getFullYear() + 1);

        state['date-to'].setValue('date-to', {
          value: currentEndDate,
          label: currentEndDate.toLocaleDateString(locale),
        });
      }

      // Set date-from
      state['date-from'].setValue('date-from', {
        value: newDate,
        label: newDate.toLocaleDateString(locale),
      });

      // Update activeStartDays with both start and end dates
      setActiveStartDays((old) => ({
        ...old,
        start: newDate,
        end: currentEndDate, // Ensure the end date is updated to the new date-to
      }));
    } else if (headerId === 'date-to') {
      let currentStartDate = state['date-from']?.value
        ? new Date(state['date-from'].value.value)
        : newDate;

      // Case 3: If no date-from is set, set it to the same as date-to
      if (!state['date-from']?.value) {
        currentStartDate = newDate;
        state['date-from'].setValue('date-from', {
          value: newDate,
          label: newDate.toLocaleDateString(locale),
        });
      }

      // Set date-to
      state['date-to'].setValue('date-to', {
        value: newDate,
        label: newDate.toLocaleDateString(locale),
      });

      // Update activeStartDays with both start and end dates
      setActiveStartDays((old) => ({
        ...old,
        start: currentStartDate,
        end: newDate, // Ensure the end date is set to the new date-to
      }));
    }
  };

  return (
    <S.SchedulerCalendarDouble>
      <div className="pickers">
        <InputFieldDate
          id={'date-from'}
          state={{
            ...state['date-from'],
            headerId: 'date-from',
            setValue: changeValue,
          }}
          label={toSentenceCase(tr['from'])}
          activeStartDate={activeStartDays?.start}
          onActiveStartDateChange={changeCurrentActiveStartDateFrom}
          focus={focus?.rangeStart}
          {...sharedMenuProps}
        />

        <InputFieldDate
          id={'date-to'}
          state={{
            ...state['date-to'],
            headerId: 'date-to',
            setValue: changeValue,
          }}
          label={toSentenceCase(tr['to'])}
          minDate={minDate}
          maxDate={maxDate}
          activeStartDate={activeStartDays?.end}
          onActiveStartDateChange={changeCurrentActiveStartDateTo}
          focus={focus?.rangeEnd}
          {...sharedMenuProps}
        />
      </div>

      <div className="controls">
        <button
          className="reset-button"
          type="button"
          onClick={() => resetToDefault({ refetch: true })}
        >
          <ArrowRevert width={12} height={20} bold />
          {tr['current-month']}
        </button>
      </div>
    </S.SchedulerCalendarDouble>
  );
};
