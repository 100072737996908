import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './index.css';
import App from './App';
import { QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
require('./common/prototypes');

// REDUX
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// reducers
import allReducers from './redux/reducers';

// -- all states
const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(thunk)),
);

if (window.location.hostname === 'servepro.specto.lt') {
  // TODO: CHANGE TO process.env.NODE_ENV !== 'development', WHEN THERE IS AN ABILITY TO USE .env in PRODUCTION
  Sentry.init({
    dsn: 'https://638e23586d5b6008f517ed5639fb85ee@o4506614879092736.ingest.sentry.io/4506614882697216',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/servepro\.specto\.lt/],
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const queryClient = new QueryClient();

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
