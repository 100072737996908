export const updateFunctionBarItem = (arr, id, key, value) => {
  if (!arr.state?.length) return;

  arr.setState((prev) => {
    const updatedArr = [...prev];
    // updatedArr.find((item) => item.id === id)[key] = value;
    const found = updatedArr.find((item) => item.id === id);

    if (found) found[key] = value;

    return updatedArr;
  });
};
