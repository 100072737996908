import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSchedule } from 'redux/actions/scheduleActions';

// style
import * as S from './NavigationPanel.styled';

// icons
import { Arrow, ArrowDouble, Calendar } from 'icons/dynamic';

// components
import SchedulerCalendar from 'components/Calendar/SchedulerCalendar/SchedulerCalendar';
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useMediaQuery from 'hooks/useMediaQuery';
import { useSystemGetCultureById } from 'services/systemService';
import {
  datesAreOnSameMonth,
  datesAreOnSameYear,
  getFirstDayOfWeek,
} from 'common/helpers/dateOperations';

const NavigationPanel = ({
  dateState,
  toggleDaysFunctions,
  type,
  numberOfDays,
  dynamicDays,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale } = useContext(UserContext);
  const { data: culture } = useSystemGetCultureById({
    id: locale,
  });

  // Hooks
  const tr = useTranslate().use().global;
  const dispatch = useDispatch();

  // -- state
  // --- local
  const [openCalendar, setOpenCalendar] = useState(false);
  const [date, setDate] = useState(null);
  const [weekDay, setWeekDay] = useState(null);

  // Variables
  let dayLong = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
  }).format(new Date(dateState));

  let dayShort = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
  }).format(new Date(dateState));

  dayLong = dayLong.charAt(0).toUpperCase() + dayLong.slice(1);
  dayShort = dayShort.charAt(0).toUpperCase() + dayShort.slice(1);

  // Functions
  useEffect(() => {
    handleDateChange();
  }, [isMobile, dateState, culture]);

  const getRangeDates = () => {
    if (numberOfDays >= dynamicDays?.total) {
      const lastDay = new Date(
        dateState.getFullYear(),
        dateState.getMonth(),
        dateState.getDate() - dynamicDays?.past + numberOfDays - 1,
      );

      const firstDay = new Date(
        lastDay.getFullYear(),
        lastDay.getMonth(),
        lastDay.getDate() - dynamicDays?.total + 1,
      );

      return [firstDay, lastDay];
    }
  };

  const handleDateChange = () => {
    setWeekDay(() => {
      const string = new Intl.DateTimeFormat(locale, {
        weekday: isMobile ? 'short' : 'long',
      }).format(new Date(dateState));

      return string.charAt(0).toUpperCase() + string.slice(1);
    });
    setDate(() => {
      if (isMobile) {
        if (numberOfDays === 1) {
          return dateState.toLocaleDateString(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
        } else if (numberOfDays === 7) {
          const monday = getFirstDayOfWeek(dateState);
          const sunday = new Date(monday);
          sunday.setDate(sunday.getDate() + 6);

          return getRangeDate(monday, sunday, true, true);
        } else if (numberOfDays === 30) {
          return dateState.toLocaleDateString(locale, {
            year: 'numeric',
            month: '2-digit',
          });
        } else if (numberOfDays >= dynamicDays?.total) {
          const [firstDay, lastDay] = getRangeDates();

          return getRangeDate(firstDay, lastDay, true);
        }
      } else {
        if (numberOfDays === 1) {
          return dateState.toLocaleDateString(locale, {
            dateStyle: 'long',
          });
        } else if (numberOfDays === 7) {
          // const date = new Date(dateState);

          // const day = date.getDay();

          // const monday = date.getDate() - day + (day === 0 ? -6 : 1);
          // const sunday = date.getDate() - day + (day === 0 ? 6 : 7);
          const monday = getFirstDayOfWeek(dateState);
          const sunday = new Date(monday);
          sunday.setDate(sunday.getDate() + 6);

          // date.setDate(monday);

          // let dateString = date.toLocaleDateString(locale, {
          //   year: 'numeric',
          //   month: 'long',
          //   day: 'numeric',
          // });

          // const ind =
          //   dateString.indexOf(' ' + date.getDate()) +
          //   (date.getDate() >= 10 ? 3 : 2);

          // return dateString.slice(0, ind) + ' - ' + sunday + dateString.slice(ind); // WTF

          const sameMonth = datesAreOnSameMonth(monday, sunday);
          const sameYear = datesAreOnSameYear(monday, sunday);

          return ` ${monday.toLocaleString(locale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })} ${
            ' - ' +
            sunday.toLocaleString(locale, {
              ...(!sameYear && { year: 'numeric' }),
              // ...(!sameMonth && { month: 'long' }),
              month: 'long',
              day: 'numeric',
            })
          }`;
        } else if (numberOfDays === 30) {
          return dateState.toLocaleDateString(locale, {
            year: 'numeric',
            month: 'long',
          });
        } else if (numberOfDays >= dynamicDays?.total) {
          const [firstDay, lastDay] = getRangeDates();

          return getRangeDate(firstDay, lastDay);
        }
      }
    });
  };

  const checkIfItsToday = () => {
    if (numberOfDays >= dynamicDays?.total) return false;

    const today = new Date();

    return (
      dateState.getDate() === today.getDate() &&
      dateState.getMonth() === today.getMonth() &&
      dateState.getFullYear() === today.getFullYear()
    );
  };

  const getRangeDate = (startDate, endDate, short, includeDay = false) => {
    if (!culture) {
      return;
    }

    let dateTimeFormatInfoKey;

    if (includeDay) {
      dateTimeFormatInfoKey = short
        ? 'abbreviatedMonthGenitiveNames'
        : 'monthGenitiveNames';
    } else {
      dateTimeFormatInfoKey = short ? 'abbreviatedMonthNames' : 'monthNames';
    }

    let dateString =
      startDate.getFullYear() +
      ' ' +
      culture.dateTimeFormatInfo[dateTimeFormatInfoKey][startDate.getMonth()];

    if (includeDay) {
      if (includeDay) {
        const startDay = startDate.toLocaleDateString(locale, {
          day: '2-digit',
        });

        dateString += ` ${startDay}`;
      }
    } else if (startDate.getMonth() === endDate.getMonth()) {
      return dateString;
    }

    dateString +=
      ' - ' +
      culture.dateTimeFormatInfo[dateTimeFormatInfoKey][endDate.getMonth()];

    if (includeDay) {
      const endDay = endDate.toLocaleDateString(locale, {
        day: '2-digit',
      });

      return `${dateString} ${endDay}`;
    }

    return dateString;
  };

  const handleScheduleDateChange = (date) => {
    dispatch(
      updateSchedule({
        dateState: date,
      }),
    );
  };

  const checkAndExecute = (key) => key && moveByDay(key);

  const moveByDay = (direction) => {
    handleDateChange();
    let newDay = new Date(dateState);

    switch (direction) {
      case 'today':
        newDay = new Date();
        break;
      case 'back':
        newDay.setDate(newDay.getDate() - 1);
        break;
      case 'forward':
        newDay.setDate(newDay.getDate() + 1);
        break;
      case 'weekBack':
        newDay.setDate(newDay.getDate() - 7);
        break;
      case 'weekForward':
        newDay.setDate(newDay.getDate() + 7);
        break;
      case 'monthBack':
        newDay.setMonth(newDay.getMonth() - 1);
        break;
      case 'monthForward':
        newDay.setMonth(newDay.getMonth() + 1);
        break;
      default:
    }

    dispatch(
      updateSchedule({
        dateState: newDay,
      }),
    );
  };

  return (
    <S.NavigationPanel>
      {openCalendar && (
        <div className="calendar__wrapper">
          <SchedulerCalendar
            close={setOpenCalendar}
            selectDay={handleScheduleDateChange}
            selected={dateState}
          />
        </div>
      )}
      <S.Wrapper>
        <S.Navigation
          isToday={checkIfItsToday() || !toggleDaysFunctions?.today}
          singleArrowsDisabled={
            !toggleDaysFunctions?.singleArrowBack ||
            !toggleDaysFunctions?.singleArrowForward
          }
          doubleArrowsDisabled={
            !toggleDaysFunctions?.doubleArrowsBack ||
            !toggleDaysFunctions?.doubleArrowsForward
          }
        >
          <ArrowDouble
            bold
            width={20}
            className="arrow arrow__double-left"
            onClick={() =>
              checkAndExecute(toggleDaysFunctions?.doubleArrowsBack)
            }
          />
          <Arrow
            bold
            width={20}
            className="arrow arrow__left"
            onClick={() =>
              checkAndExecute(toggleDaysFunctions?.singleArrowBack)
            }
          />
          <div className="actions-wrapper">
            <Calendar
              height={20}
              width={20}
              onClick={() => setOpenCalendar(!openCalendar)}
              className="calendar-icon"
            />
            {!isMobile && (
              <div
                className="today-button"
                onClick={() => checkAndExecute(toggleDaysFunctions?.today)}
              >
                {tr.today}
              </div>
            )}
          </div>
          <Arrow
            bold
            width={20}
            className="arrow"
            onClick={() =>
              checkAndExecute(toggleDaysFunctions.singleArrowForward)
            }
          />
          <ArrowDouble
            bold
            width={20}
            className="arrow"
            onClick={() =>
              checkAndExecute(toggleDaysFunctions?.doubleArrowsForward)
            }
          />
        </S.Navigation>
        <S.Date>
          <span className="date-string">{date}</span>
          {numberOfDays === 1 && <span>&nbsp;{weekDay}</span>}
        </S.Date>
      </S.Wrapper>
    </S.NavigationPanel>
  );
};

export default NavigationPanel;
