import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const AnnouncementsDetails = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  position: relative;

  .filler {
    margin-bottom: 0;
    height: 570px;
  }

  .sitting-girl__logo {
    position: absolute;
    bottom: 30px;
    left: 80px;
    right: 0;

    svg {
      width: 100%;
    }
  }

  > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 960px) {
    .filler {
      height: 400px;
    }

    .sitting-girl__logo {
      bottom: 0px;
      left: 0px;

      svg {
        height: 400px;
      }
    }

    > div:not(.filler, .sitting-girl__logo) {
      margin-bottom: 40px;

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }
`;

export const DetailsWithIcon = styled.div`
  display: flex;
  align-items: center;

  font-family: ${fontFamilies.family_Secondary};
  font-size: 18px;

  .icon-container {
    height: 20px;
    margin-right: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
`;

export const DetailsGroup = styled.div`
  h4 {
    font-size: 24px;
    margin: 0;

    height: 50px;

    display: flex;
    align-items: center;
  }

  .details__list {
    margin-top: 20px;

    > div {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 960px) {
    h4 {
      font-size: 18px;
      height: unset;
    }
  }
`;
