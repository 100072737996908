import { useEffect } from 'react';
import { useEmployeesFind } from 'services/employeeService';

export const useSearchEmployees = (params) => {
  const {
    sectorId,
    calendarView,
    searchQuery,
    checkDay,
    displayOnlyWorkingEmployees,
    setDisplayArr,
  } = params;

  const employeesBE = useEmployeesFind({
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      enabled: !!sectorId,
    },
    searchParams: {
      sectorId,
      calendarView,
      ...(searchQuery && { searchPhrase: searchQuery }),
      ...(checkDay && { employmentCheckDay: checkDay }),
      ...(calendarView !== 'i' && {
        isWorking: displayOnlyWorkingEmployees,
      }),
    },
  });

  useEffect(() => {
    if (employeesBE.data) {
      setDisplayArr(employeesBE.data.employees);
    }
  }, [employeesBE]);

  return employeesBE;
};
