import { Navigate, Route, Routes, useNavigate } from 'react-router';

// translate
import { useTranslate } from 'hooks/useTranslate';

// style
import * as S from './Settings.styled';

// pages
import SettingsTemplate from './SettingsTemplate';

// components
import { Footer } from 'components/Footer/Footer';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from 'context/UserContext';
import axios from 'axios';

export const Settings = () => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  // Hooks
  const tr = useTranslate().use().global;
  const { token, companyId, updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  const restoreAppUserCredentials = useCallback(
    async (token) =>
      await axios.get(`${process.env.REACT_APP_API}myInfo`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    [],
  );

  const [companyIdLocal, setCompanyIdLocal] = useState(companyId);

  useEffect(() => {
    if (!token) {
      navigate(APP_ROOT + 'login');
    }
    if (!companyId) {
      // get user data to check for token type
      restoreAppUserCredentials(token)
        .then((apiResponse) => {
          console.log({ apiResponse });
          updateUser(apiResponse.data);

          console.log(apiResponse.data);

          if (!apiResponse.data.companyId) {
            navigate(APP_ROOT + 'dashboard');
          } else {
            setCompanyIdLocal(apiResponse.data.companyId);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // token expired
            navigate(APP_ROOT + 'login');
          }
          console.log({ err });
        });
    }
  }, [
    token,
    companyId,
    APP_ROOT,
    navigate,
    restoreAppUserCredentials,
    updateUser,
  ]);

  const branchTabs = [
    { name: tr['all'], acl: 1, route: 'all' },
    { name: `${tr['branch']} 1`, acl: 1, route: '1' },
    { name: `${tr['branch']} 2`, acl: 1, route: '2' },
    { name: `${tr['branch']} 3`, acl: 1, route: '3' },
  ];

  const settingsRouteConfig = [
    {
      id: 'system',
      route: 'system',
      subItems: [
        {
          id: 'general',
          route: 'general/*',
        },
      ],
    },
    {
      id: 'branches',
      route: 'branches',
      subItems: [
        {
          id: 'sector-details',
          route: 'sector-details/*',
          tabs: branchTabs,
        },
        {
          id: 'calendar',
          route: 'calendar/*',
          tabs: branchTabs,
        },
        {
          id: 'sms',
          route: 'sms/*',
          tabs: branchTabs,
        },
        {
          id: 'online-reservations',
          route: 'online-reservations/*',
          tabs: branchTabs,
        },
      ],
    },
  ];

  return (
    !!companyIdLocal && (
      <S.Settings>
        <Routes>
          {settingsRouteConfig.map((item, i) => (
            <React.Fragment key={i}>
              {i === 0 && (
                <Route key={i} index element={<Navigate to={item.route} />} />
              )}
              {item?.subItems && (
                <Route path={item.route}>
                  {item.subItems.map((subItems, i) => (
                    <React.Fragment key={i}>
                      {i === 0 && (
                        <Route index element={<Navigate to={subItems.id} />} />
                      )}
                      <Route
                        path={subItems.route}
                        element={
                          <SettingsTemplate
                            key={subItems.id}
                            funcTabs={subItems?.tabs}
                          />
                        }
                      />
                    </React.Fragment>
                  ))}
                </Route>
              )}
            </React.Fragment>
          ))}

          {/* <Route index element={<Navigate to="system" />} />

        <Route path="system">
          <Route index element={<Navigate to="general" />} />

          <Route path="general/*" element={<SettingsTemplate key={1} />} />
        </Route> */}

          {/* <Route path="branches">
          <Route index element={<Navigate to="contact-details" />} />

          <Route
            path="contact-details/*"
            element={<SettingsTemplate key={2} />}
          />
          <Route path="calendar/*" element={<SettingsTemplate key={3} />} />
          <Route path="sms" element={<SettingsTemplate key={4} />} />
          <Route
            path="online-reservations"
            element={<SettingsTemplate key={5} />}
          />
        </Route> */}
        </Routes>

        <Footer content={2} />
      </S.Settings>
    )
  );
};

export default Settings;
