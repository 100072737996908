import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  &.react-tooltip {
    border-radius: 10px;
    box-shadow:
      0 0 3px #0f1d5b26,
      0 4px 10px #0f1d5b4d;
    color: ${colors.text_Secondary};
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.6875rem;
    padding: 10px;
    z-index: 99;

    & .centered {
      text-align: center;
    }
  }
`;
