import { useRef, useState, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

// style
import * as S from './OrderHeader.styled';

// icons
import { Euro, LetterIInCircle } from 'icons/dynamic';

// components
import { BaseInputDate } from 'components/Inputs';
import Checkbox from 'components/Checkbox/Checkbox';
import InputFieldTime from 'components/InputFieldTime/InputFieldTime';
import Badge from 'components/Badge/Badge';

// contexts
import { OrderContext } from 'context/OrderContext';
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import useStatusList from 'hooks/useStatusList';
import useTranslate from 'hooks/useTranslate';
import useOrder from 'hooks/order/useOrder';
import { useAppointment } from 'hooks/useAppointment';
import { colors } from 'common/colors/colors';

const OrderHeader = ({
  fillRows,
  status,
  workSchedule,
  orderId,
  dateDisabled,
  toggled,
  functions,
  displayOrderInfo,
  isOrderPaid,
}) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { order, employeeId } = useContext(OrderContext);
  const { sectorData } = useContext(MainContext);
  let currStatus = useStatusList([status])[0];
  const location = useLocation();
  const multiSelect =
    location.state?.multi === 'multi-selects' && location.state;
  const availableTimesRef = useRef(null);
  const { getDateAndTime } = useAppointment();
  const { handleDateTimeInput } = useOrder({
    order: order.state,
    setOrder: order.setState,
  });

  // const [showAvailableTimes, setShowAvailableTimes] = useState(false);

  // const [orderDuration, setOrderDuration] = useState([]);

  // Functions
  // const openAvailableTimes = () => {
  //   if (dateDisabled) return;
  //   setShowAvailableTimes(!showAvailableTimes);
  // };

  // const getDateToString = () => {
  //   let date = time.start.toLocaleDateString(locale, {
  //     year: 'numeric',
  //     month: 'numeric',
  //     day: 'numeric',
  //   });

  //   let weekday = time.start.toLocaleDateString(locale, {
  //     weekday: 'short',
  //   });
  //   weekday = toSentenceCase(weekday);

  //   return date + ' ' + weekday;
  // };

  const updateOrderTime = (x) => {
    order.setState((prev) => {
      return { ...prev, startTime: prev.startDate, endTime: prev.endDate };
    });
  };

  const toggleReminder = () => {
    order.setState((prev) => {
      return {
        ...prev,
        sendVisitReminder: !prev.sendVisitReminder,
      };
    });
  };

  const { jsDate, startTimeString, endTimeString } = getDateAndTime(
    order?.state?.startTimeUtc,
    order?.state?.endTimeUtc,
    order?.state?.timezoneIanaId,
  );

  return (
    <S.OrderHeader
      bg={currStatus?.background}
      // showAvailableTimes={showAvailableTimes}
      dateDisabled={dateDisabled}
    >
      <div className="order-header">
        <Badge
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            tr[isOrderPaid ? 'paid' : 'debt'],
          )}
          icon={<Euro />}
          md
          color={colors[isOrderPaid ? 'paidStatus' : 'debtStatus']}
        />
        <span className="order-header__status">{currStatus?.title}</span>
        <span># {orderId}</span>
        <Checkbox
          text="SMS:"
          value={{
            state: order.state.sendVisitReminder,
            action: toggleReminder,
            // state: demoToggleSMS,
            // action: () => setDemoToggleSMS(!demoToggleSMS),
          }}
        />
        {!!employeeId && (
          <>
            <div className="order-header__date-time">
              <BaseInputDate
                value={jsDate.toString()}
                onChange={(date) =>
                  handleDateTimeInput('date', DateTime.fromISO(date))
                }
              />
              {/* <span className="input">{getDateToString()}</span> */}
              <div className="time">
                <span className="input">
                  <InputFieldTime
                    value={startTimeString}
                    onChange={(value) =>
                      handleDateTimeInput('startTime', value)
                    }
                  />
                </span>
                <span className="dash">-</span>
                <div className="input">
                  <InputFieldTime
                    value={endTimeString}
                    onChange={(value) => handleDateTimeInput('endTime', value)}
                    disabled={!order.state.savedProducts.length}
                  />
                </div>
                {order.state.multipleDaysSpanWarning && (
                  <span
                    className="warning-icon"
                    data-tooltip-id="tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      tr['order-cannot-extend-to-the-next-day'],
                    )}
                  >
                    <LetterIInCircle
                      width={16}
                      height={16}
                      color={colors.mandyRed}
                    />
                  </span>
                )}
              </div>
            </div>
            {/* <TheButton
              className="vacant-times-btn"
              title={tr['vacant-times']}
              icon={<Check inCircle bold color={colors.text_Secondary} />}
              customBackgroundColor="#fff"
              action={openAvailableTimes}
            /> */}
          </>
        )}
      </div>
      {/* <S.AvailableTimes
        bg={currStatus?.background}
        ref={availableTimesRef}
        grow={showAvailableTimes}
      >
        <AvailableTimeCalendar
          id={orderId}
          workSchedule={workSchedule}
          orderDuration={{ state: orderDuration, setState: setOrderDuration }}
          updateOrderTime={updateOrderTime}
        />
      </S.AvailableTimes> */}
    </S.OrderHeader>
  );
};

export default OrderHeader;
