import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const UList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: -10px 0;

  li {
    height: 50px;
    border-bottom: 1px solid ${colors.bg_grey_1};

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;
  }
`;
