import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, inCircle, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = inCircle ? (
        <path
          d="M 15,0 C 6.728145,0 0,6.731205 0,15 0,23.26875 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 6.8901,16.2228 h -5.6673 v 5.6673 c 0,0.6756 -0.5472,1.2228 -1.2228,1.2228 -0.67557,0 -1.22274,-0.5472 -1.22274,-1.2228 V 16.2228 H 8.10984 C 7.43733,16.2228 6.8871,15.6756 6.8871,15 c 0,-0.67557 0.55023,-1.22274 1.22274,-1.22274 h 5.66742 V 8.1129 c 0,-0.67557 0.54717,-1.22274 1.22274,-1.22274 0.6756,0 1.2228,0.54717 1.2228,1.22274 v 5.66436 h 5.6673 c 0.6726,0 1.2228,0.54717 1.2228,1.22274 0,0.6756 -0.5502,1.2228 -1.2228,1.2228 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      ) : (
        <path
          d="M 28.23765,13.23771 H 16.76235 V 1.76229 C 16.76235,0.790092 15.97215,0 15,0 14.027805,0 13.23771,0.790092 13.23771,1.76229 V 13.23771 H 1.76229 C 0.790092,13.23771 0,14.027805 0,15 c 0,0.97215 0.790092,1.76235 1.76229,1.76235 h 11.47542 v 11.4753 C 13.23771,29.20995 14.027805,30 15,30 c 0.97215,0 1.76235,-0.79005 1.76235,-1.76235 v -11.4753 h 11.4753 C 29.20995,16.76235 30,15.97215 30,15 30,14.027805 29.20995,13.23771 28.23765,13.23771 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = inCircle ? (
        <>
          <path
            d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 21.8901,13.777215 h -5.6673 v -5.66742 c 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274 -0.67557,0 -1.22274,0.54717 -1.22274,1.22274 v 5.66742 H 8.10984 c -0.675555,0 -1.22274,0.54717 -1.22274,1.22274 0,0.675495 0.547185,1.222695 1.22274,1.222695 h 5.66742 v 5.66745 c 0,0.6756 0.54717,1.2228 1.22274,1.2228 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 v -5.66745 h 5.6673 c 0.6756,0 1.2228,-0.5472 1.2228,-1.222695 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="M 16.2228,1.222743 C 16.2228,0.5471775 15.6756,0 15,0 14.32443,0 13.77726,0.5471775 13.77726,1.222743 V 13.77726 H 1.222743 C 0.5471775,13.77726 0,14.32443 0,15 c 0,0.6756 0.5471775,1.2228 1.222743,1.2228 H 13.77726 V 28.7772 C 13.77726,29.4528 14.32443,30 15,30 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 V 16.2228 H 28.7772 C 29.4528,16.2228 30,15.6756 30,15 30,14.32443 29.4528,13.77726 28.7772,13.77726 H 16.2228 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    default:
      content = inCircle ? (
        <>
          <path
            d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 7.82025,15.63735 h 6.54558 v 6.54555 c 0,0.35055 0.2868,0.63735 0.63732,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 v -6.54555 h 6.54555 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.35052 -0.2868,-0.637335 -0.63735,-0.637335 H 15.6405 V 7.8171 c 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 -0.35052,0 -0.63732,0.2868 -0.63732,0.63735 v 6.545565 H 7.82025 C 7.469715,14.362665 7.1829,14.64948 7.1829,15 c 0,0.35055 0.286815,0.63735 0.63735,0.63735 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="M 0.6373485,15.63735 H 14.36265 v 13.7253 C 14.36265,29.7132 14.649465,30 15,30 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 v -13.7253 h 13.7253 C 29.7132,15.63735 30,15.35055 30,15 30,14.649465 29.7132,14.36265 29.36265,14.36265 H 15.63735 V 0.6373485 C 15.63735,0.2868075 15.35055,0 15,0 14.649465,0 14.36265,0.2868075 14.36265,0.6373485 V 14.36265 H 0.6373485 C 0.2868075,14.36265 0,14.649465 0,15 c 0,0.35055 0.2868075,0.63735 0.6373485,0.63735 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
