import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const CustomerSearchContainer = styled.section`
  height: calc(100vh - 70px);
  width: 100%;

  display: flex;
  flex-direction: column;

  .customer-search-content {
    flex: 1 0 auto;
  }

  .content__layout {
    margin: 40px 40px 0;
  }
`;

export const Header = styled.section`
  h2 {
    color: ${colors.text_Secondary};
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0 40px 20px 0;
    padding-bottom: 2px;
  }
`;

export const Main = styled.section`
  .client-select-wrapper {
    padding: 20px;
    background-color: ${colors.bg_grey_1};
    border-radius: 10px;
  }

  .client-select__table-container {
    position: relative;
    margin-top: 20px;
    height: 185px;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: white;
      border: 1px solid rgba(217, 217, 217, 0.3);
      border-radius: 10px;
      margin-top: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${colors.bg_Primary};
    }
  }
`;
