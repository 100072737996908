import { clientHeaders } from 'models/client';

const clientReducer = (
  state = {
    headers: clientHeaders,
    // data: [],
    // status: 'idle',
    filterStatus: false,
  },
  action,
) => {
  switch (action.type) {
    // case 'CLIENT_REQUEST':
    //   return { ...state, status: 'loading', data: [] };
    // case 'CLIENT_SUCCESS':
    // case 'CLIENT_UPDATE':
    //   return {
    //     ...state,
    //     status: 'success',
    //     data: action.payload,
    //   };
    // case 'CLIENT_FAIL':
    //   return {
    //     ...state,
    //     status: 'failed',
    //     data: action.payload,
    //   };
    case 'CLIENT_UPDATE_FILTER_STATUS':
      return { ...state, filterStatus: action.payload };
    case 'CLIENT_TABLE_UPDATE':
      return { ...state, headers: action.payload };
    default:
      return state;
  }
};

export default clientReducer;
