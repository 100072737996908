import { useContext } from 'react';
import { useNavigate } from 'react-router';

// style
import * as S from './TableRecord.styled';

// icons
import { Arrow, TrashCan } from 'icons/dynamic';

// components
import { RecordCell } from './partials/RecordCell/RecordCell';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import { useTable } from 'hooks/useTable';

export const TableRecord = ({
  data,
  navigation,
  hasDeleteButton,
  onRecordDelete,
  isInactive,
}) => {
  const navigate = useNavigate();
  const {
    state: { columns },
  } = useContext(TableContext);
  const { getColumnVisibility } = useTable();

  const inactive = isInactive(data);

  const handleNavigate = () => {
    if (typeof navigation === 'boolean') {
      navigate(data.id);
    } else {
      navigation(data);
    }
  };

  return (
    <S.TableRecord onDoubleClick={handleNavigate} inactive={inactive}>
      {!!navigation && (
        <RecordCell
          key={data.id + '-navigate'}
          column={{
            cellClassNames: 'record-navigation',
          }}
          onClick={handleNavigate}
        >
          <Arrow black tip="right" height="16" width="16" />
        </RecordCell>
      )}
      {hasDeleteButton && (
        <RecordCell
          key={data.id + '-delete'}
          column={{
            cellClassNames: `record-delete ${inactive ? 'inactive' : ''}`,
          }}
          onClick={() => onRecordDelete(data)}
        >
          <TrashCan width={14} height={14} />
        </RecordCell>
      )}
      {columns?.map(
        (column) =>
          getColumnVisibility(column) && (
            <RecordCell
              key={data.id + '-' + column.id}
              column={column}
              data={data}
            />
          ),
      )}
    </S.TableRecord>
  );
};
