// style
import * as S from './BaseCheckbox.styled';

// icons
import { Check, Circle } from 'icons/dynamic';

export default ({ value, label, onClick, disabled, excludeLabel, ...rest }) => {
  const commonProps = {
    width: 16,
    height: 16,
    onClick: disabled ? null : onClick,
  };

  return (
    <S.BaseCheckbox disabled={disabled} excludeLabel={excludeLabel} {...rest}>
      {value ? (
        <Check inCircle black {...commonProps} />
      ) : (
        <Circle bold {...commonProps} />
      )}
      {label && (
        <label onClick={disabled || excludeLabel ? null : onClick}>
          {label}
        </label>
      )}
    </S.BaseCheckbox>
  );
};
