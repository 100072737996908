import styled from 'styled-components/macro';

export const Badge = styled.div`
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ iconSize }) => iconSize + 'px !important'};
    height: ${({ iconSize }) => iconSize + 'px !important'};
  }
`;
