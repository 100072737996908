import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const SectorStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;

  @media screen and (max-width: 1300px) {
    flex-direction: row;
    column-gap: 20px;
    row-gap: 40px;

    .sector {
      width: 260px;
    }
  }

  .sector {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 260px;

    ul {
      padding: 0;
      margin: 0;
      gap: 10px;
      display: flex;
      flex-direction: column;

      li {
        list-style: none;

        .red {
          color: ${colors.mandyRed};
        }

        .yellow {
          color: ${colors.discount};
        }
      }
    }
  }
`;
