import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';

export const FilterRow = styled.div`
  height: 50px;
  align-items: center;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.bg_grey_1};

  > div {
    flex: 1;
  }

  .input-wrapper .clear-icon {
    padding-right: 0;

    svg {
      margin-right: -5px;
    }
  }

  ${BaseInput} {
    input {
      padding: unset;
    }
  }
`;
