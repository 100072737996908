import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const HeaderRow = styled.tr`
  height: 50px;
  color: ${colors.table_thead_text_Primary};
  background-color: ${colors.bg_Primary};

  th {
    border-color: ${colors.table_head_borders_and_arrows_Primary};

    :last-of-type {
      border-right: 1px solid ${colors.table_head_borders_and_arrows_Primary};
    }
  }
`;
