import { useContext } from 'react';

// Context
import { UserContext } from 'context/UserContext';

export default function useDate() {
  const { locale } = useContext(UserContext);

  const getDate = (date) => new Date(date).toLocaleDateString(locale);

  const getDateTime = (date) => new Date(date).toLocaleString(locale);

  const getCurrentMonth = (limit) => {
    const today = new Date();

    switch (limit) {
      case 'start':
        return new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
        ).toLocaleDateString(locale);
      case 'end':
        return new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
        ).toLocaleDateString(locale);
      default:
    }
  };

  return {
    getDate,
    getDateTime,
    getCurrentMonth,
  };
}
