export const colors = {
  header_logo_and_text_Primary: 'white',
  header_logo_and_text_Secondary: '#0f1d5b',

  link: '#ffa35f',

  footer_logo_Primary: '#0f1d5b',
  footer_logo_Secondary: '#fff',

  calendar_color_Primary: '#0f1d5b',
  calendar_color_Secondary: '#d6d3dc',

  bg_Primary: '#0f1d5b',
  bg_Secondary: '#fff',

  text_Primary: '#fff',
  text_Secondary: '#0f1d5b',

  table_thead_text_Primary: '#fff',
  table_thead_text_Secondary: '#0f1d5b',

  table_borders_Primary: '#e8e8e8',
  table_borders_Secondary: '#454e78',

  table_bg_Primary: '#fff',
  table_bg_Secondary: '#0f1d5b',

  tab_borders_active: '#ff9954',

  filterGrey: '#d1d3da',

  small_details: '#ffa35f',

  sidebar_bg_selected: '#e7f2fa',

  // apacioje yra borderiai

  table_arrows_Primary: '#e8e8e8',
  table_arrows_Secondary: 'red',

  searchInpuyPlaceholder: '#8d92a3',

  // table

  table_head_borders_and_arrows_Primary: '#454e78',
  table_head_borders_and_arrows_Secondary: 'green',

  table_text_Primary: '#0f1d5b',
  table_text_Secondary: '#fff',

  table_row_hover: '#fafafa',

  leftside_Primary: '#fff',
  leftside_Secondary: '#0f1d5b',

  footer_Primary: '#fff',
  footer_Secondary: '#0f1d5b',

  bg_Button_Primary: '#ffa35f',
  bg_Button_Secondary: '#0f1d5b',
  bg_Button_Tertiary: '#d9f5f1',

  button_Primary: '#fff',
  button_Secondary: '#ffa35f',

  bg_grey_1: '#f7f6f7',
  bg_grey_2: '#e8e8e8',
  bg_grey_3: '#8d92a3',
  bg_grey_4: '#d9d9d9',
  bg_grey_5: '#f3f3f3',
  bg_grey_6: '#d1d3da',

  mandyRed: '#e54651',
  warning: '#fff0b3',
  warningText: '#ffa35f',

  table_border: '#d9d9d9',
  function_row_placeholder: '#d1d3da',
  functionBarIcons: '#0f1d5b',
  functionBarTogledIcons: 'white',

  // varneles
  checkedPrimary: '#1c1f50',
  disabledGrey: '#8d92a3',

  // timeIndicator
  indicatorRed: '#e54651',

  // mobile
  logoPrimaryMobile: '#0f1d5b',

  // calendar
  calendar_button_Secondary: '#0f1d5b',
  calendar_button_text_color: '#fff',
  calendar_button_text_color_secondary: '#fff',
  calendar_date_text_not_selected: '#bab9b9',
  calendar_date_text_checked: '#fff',
  calendar_office_number: '#ffa35f',
  calendar_cells_border: '#e8e8e8',
  calendar_weekend: '#8d92a3',
  calendar_diff_month_day: '#d1d3da',
  calendar_greenIsh: '#d9f5f1',

  // creating event
  orderDetailsHeader: '#d9f5f1',

  // overlay
  overlay: 'rgba(15, 29, 91, 0.4)',
  overlay_white: '#fff',

  // status color
  ordered: '#AFF3E4',
  willArrive: '#76DCC8',
  arrived: '#FFA35F',
  debt: '#c8e1f3', // needs update
  paid: '#e7f2fa', // needs update
  canceled: '#A7ABB6',
  absent: '#F9B1B1',
  confirmed: '#d8e2fd',
  cancelAccomplished: '#d8e2fd',

  // order balance status
  paidStatus: '#48A392',
  debtStatus: '#E75B65',

  // amounts
  discount: '#ffa35f',
  discounted: '#5999b4',

  // messages
  info: '#e7f2fa',
  error: '#ff999a',
  'error--hover': '#ff0002',
};
