import { initStore } from './store';

// data model
import { userHeaders } from 'models/user';

// hooks and helpers
import {
  getAgeOptions,
  getAvatar,
  getDateOptions,
  getOptions,
  getPersonAge,
  getPredefinedLabel,
} from 'common/helpers/modelFunctions';
import useDate from 'hooks/useDate';

export const useConfigureStore = () => {
  const sliceId = 'users';

  const updateOneAction = (propName, currState, { headerId, handler }) => {
    // make a copy of the old slice
    const updatedHeaders = [...currState[sliceId]];

    // create a new property (function)
    updatedHeaders.find((header) => header.id === headerId)[propName] = handler;

    return { [sliceId]: updatedHeaders };
  };

  const actions = {
    UPDATE_EMPLOYEE_HEADER_OPTIONS: (currState, { headerId, handler }) =>
      updateOneAction('options', currState, { headerId, handler }),

    UPDATE_EMPLOYEE_HEADER_DISPLAY: (currState, { headerId, handler }) =>
      updateOneAction('display', currState, { headerId, handler }),
  };

  // --------------------------------

  // const { data, isSuccess } = useEmployeesGetAll();
  const { getDate } = useDate();

  userHeaders.forEach((header) => {
    switch (header.type) {
      case 'calendar':
        header.display = (record) => getDate(record[header.id]);
        header.options = getDateOptions;
        break;
      case 'age':
        header.display = (record) => getPersonAge(record['dob']);
        header.options = getAgeOptions;
        break;
      case 'avatar':
        header.display = (record) => getAvatar(record[header.id]);
        break;
      case 'switch':
        header.display = (record) => getPredefinedLabel(record, header);
        break;
      default:
        header.display = (record) => record[header.id];
        header.options = getOptions;
    }
  });

  // --------------------------------

  initStore(actions, { [sliceId]: userHeaders });
};

export default useConfigureStore;
