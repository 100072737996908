import styled from 'styled-components';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SelectExcusesContainer = styled.div`
  padding: 20px;

  .otherExcuse {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .otherExcuse_label {
    font-size: 12px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_grey_3};
    padding-bottom: 5px;
  }

  .otherExcuse_input {
    border: 1px solid ${colors.bg_grey_2};
    border-radius: 5px;
    outline: none;

    height: 35px;
    padding: 0 10px;

    font-size: 14px;
    color: ${colors.bg_Primary};
  }
`;
