// style
import * as S from './FunctionBarOptions.styled';

// components
import { Overlay } from 'components/Overlay/Overlay';

// hooks and helpers
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useModal } from 'hooks/useModal';

export const FunctionBarOptions = ({ title, children }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const { isShowing, toggle } = useModal(isMobile);

  return !isMobile ? (
    <S.FunctionBarOptions>
      {/* <p>
        {title}
        <span>
          <Arrow color="white" />
        </span>
      </p> */}
      {children}
    </S.FunctionBarOptions>
  ) : (
    <Overlay isVisible={isShowing} hide={toggle}>
      {children}
    </Overlay>
  );
};

export default FunctionBarOptions;
