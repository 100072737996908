import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const DialogLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: ${(props) => props.innerHeight - 54}px;
  overflow: auto;
  width: 100%;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media screen and (min-width: 961px) {
    min-height: 100vh;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: white;
      border: none;
      margin-top: 70px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${colors.bg_Primary};
    }
  }
`;

export const Header = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.modal ? colors.bg_grey_1 : colors.bg_Primary};
  box-shadow: ${shadow.sm};
  box-sizing: border-box;
  color: ${(props) =>
    props.modal ? colors.text_Secondary : colors.text_Primary};
  display: flex;
  height: 54px;
  justify-content: space-between;
  padding: 0 20px;
  position: ${(props) => (props.navSticky ? 'sticky' : 'fixed')};
  top: 0;
  width: 100%;
  z-index: 5;
  gap: 20px;

  @media screen and (min-width: 961px) {
    height: 70px;
    padding: 0 40px;
  }

  .header__info-container {
    align-items: center;
    display: flex;
    gap: 20px;

    @media screen and (min-width: 961px) {
      gap: 30px;
    }
  }

  .header__burger-menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 30px;

    svg {
      fill: #fff;
    }
  }

  button {
    height: 30px;
    width: 30px;

    @media screen and (min-width: 961px) {
      height: 40px;
    }

    svg {
      width: 18px;
    }

    &.header__back-button {
      box-shadow: none;
      color: currentColor;
      width: unset;

      svg {
        width: 24px;
        height: auto;
      }
    }
  }

  .header__title {
    display: -webkit-box;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* word-break: break-all; */

    @media screen and (min-width: 961px) {
      font-size: 30px;
      padding-bottom: 2px;
      line-height: 38px;
    }
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;

  button {
    @media screen and (min-width: 961px) {
      height: 35px;
      min-width: 110px;
      width: auto;
    }

    &.header__close-button {
      height: 30px;
      min-width: unset;

      svg {
        width: unset;
        height: unset;
      }
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  height: ${(props) => props.innerHeight * 100}px;
  padding-top: 54px;

  @media screen and (min-width: 961px) {
    height: ${(props) => (props.navSticky ? 'calc(100vh - 70px)' : '100vh')};
    padding-top: ${(props) => (props.navSticky ? 0 : 70)}px;
  }
`;

export const Child = styled.div`
  background-color: ${(props) => props.darkTheme && colors.bg_grey_1};
  flex: 1 0 auto;

  @media screen and (min-width: 961px) {
    background-color: unset;
  }
`;

export const Footer = styled.div``;
