import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';

export const InputFieldDuration = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin: 0 10px 5px;
    white-space: nowrap;
  }

  .input-container {
    align-items: center;
    background-color: white;
    border: 1px solid
      ${({ disabled }) => (disabled ? 'transparent' : colors.filterGrey)};
    border-radius: 5px;
    color: ${({ hasValue }) =>
      hasValue ? colors.text_Secondary : colors.filterGrey};
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: ${({ disabled }) => !disabled && 'center'};
    height: 38px;
    width: 100%;

    :hover {
      border-color: ${({ disabled }) => !disabled && 'hsl(0, 0%, 70%)'};
    }

    :focus-within {
      border-color: ${({ disabled }) => !disabled && colors.bg_grey_3};
      color: ${({ hasValue }) => hasValue && colors.text_Secondary};
      font-weight: 900;
    }
  }

  input {
    border: none;
    border-radius: 5px;
    caret-color: transparent;
    color: ${colors.text_Secondary};
    outline: none;
    padding: 2px 10px;
    padding-inline: 5px;
    text-align: ${({ disabled }) => !disabled && 'center'};
    width: min-content;

    width: ${({ hasValue, disabled }) =>
      !hasValue && disabled ? '1em' : '1.7em'};

    /* // override for Safari
    @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
      width: ${({ hasValue, disabled }) =>
      disabled ? (hasValue ? '1.6em' : '1em') : '1.7em'};
    } */

    :first-child {
      margin-left: 5px;
      padding-right: ${({ disabled }) => (!disabled ? '2px' : 0)};
      text-align: ${({ disabled }) => !disabled && 'right'};
    }

    :last-child {
      margin-right: 5px;
      padding-left: 2px;
      text-align: ${({ disabled }) => !disabled && 'left'};
    }

    // no spinners
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      width: 0;
      padding: 0;
      display: none;
    }

    :focus-within {
      font-weight: 900;
    }

    :focus-within::placeholder {
      /* color: ${colors.text_Secondary}; */
    }

    :invalid {
      color: red;
    }

    :disabled {
      background-color: transparent;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      box-shadow: inset 0 0 20px 20px transparent;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${colors.text_Secondary};
    }
  }
`;
