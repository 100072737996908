import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

// style
import * as S from './InputFieldDuration.styled';

export const InputFieldDuration = ({ id, label, state, disabled }) => {
  // select focused value with marker
  const selectValue = (e) =>
    (e.target.name === 'hours' || e.target.name === 'minutes') &&
    e.target?.select();

  // const isEmpty = (context) => ['', '00'].includes(context);
  const isEmpty = (context) => [''].includes(context);

  // override delete and backspace keys
  const validateInput = (e) => {
    let localHours = hours;
    let localMinutes = minutes;
    if (['Delete', 'Backspace'].includes(e.key)) {
      if (e.target.name === 'hours') {
        localHours = '';
        setHours(localHours);
      } else if (e.target.name === 'minutes') {
        localMinutes = '';
        setMinutes(localMinutes);
      }
      if (isEmpty(localHours) && isEmpty(localMinutes)) {
        debounceValidate(e);
      }
    }
  };

  const debounceValidate = useDebouncedCallback((e) => validateValue(e), 500);

  // add leading '0' if only one digit has been input
  const fixValue = (e) => {
    if (!/^\d+$/.test(e.key)) {
      return; // allow only numbers
    }

    const perspectiveValue = `${
      (e.target.selectionStart === e.target.selectionEnd &&
        e.target.value.substr(1)) ||
      '0'
    }${e.key}`;

    if (e.target.name === 'hours') {
      setHours(perspectiveValue);
    } else {
      setMinutes(perspectiveValue);
    }
    debounceValidate(e);
  };

  const validateValue = (e) => {
    // the updated state might be required inside effect
    let localHours = hours;
    let localMinutes = minutes;

    if (e.target.name === 'hours' && Number(e.target.value) > 23) {
      // autofix to max 23 hours
      setHours('23');
      localHours = '23';
    }
    if (e.target.name === 'minutes' && Number(e.target.value) > 59) {
      // autofix to max 59 minutes
      setMinutes('59');
      localMinutes = '59';
    }

    // if blurred away from both fields, update state
    if (e.target?.parentNode !== e.relatedTarget?.parentNode) {
      window.getSelection().removeAllRanges();

      // if one value is empty or '00', and another is not empty, set the empty value it to '00'
      if (isEmpty(localHours) && !isEmpty(localMinutes)) {
        localHours = '00';
        setHours(localHours);
      }
      if (!isEmpty(localHours) && isEmpty(localMinutes)) {
        localMinutes = '00';
        setHours(localMinutes);
      }

      if (state?.value?.label !== localHours + ':' + localMinutes) {
        if (isEmpty(localHours) && isEmpty(localMinutes)) {
          setHours('');
          setMinutes('');
          state?.setValue(id, undefined);
          setHasValue(false);
        } else {
          state?.setValue(
            id,
            {
              value: convertToMinutes(localHours, localMinutes),
              label: (localHours || '00') + ':' + (localMinutes || '00'),
            },
            state.type,
          );

          setHours(localHours);
          setMinutes(localMinutes);
        }
      }
    }

    if ((!isEmpty(localHours) || !isEmpty(localMinutes)) && !hasValue) {
      setHasValue(true);
    }
  };

  const convertToMinutes = (hours, minutes) =>
    Number(hours) * 60 + Number(minutes);

  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  // defaults might be late, update local state upon getting them
  useEffect(() => {
    // if (state.default) {
    setHours(`${state?.default?.label?.split(':')[0] || ''}`);
    setMinutes(`${state?.default?.label?.split(':')[1] || ''}`);
    state.default ? setHasValue(true) : setHasValue(false);
    // }
  }, [state?.default]);

  const [hasValue, setHasValue] = useState(!!state?.value);

  // useEffect(() => {
  //   console.log({ state, hours, minutes, hasValue });
  // }, [state, hours, minutes, hasValue]);

  return (
    <S.InputFieldDuration hasValue={hasValue} disabled={disabled}>
      {label && <label htmlFor={id}>{label}</label>}

      <div
        id={id}
        className="input-container"
        onKeyDown={validateInput} // intercept Delete, Backspace
        // onKeyPress={fixValue}
        onClick={selectValue} // mark value with selector
      >
        <input
          type="text"
          // inputMode="numeric"
          placeholder="--"
          pattern="[0-9]{2,2}"
          maxLength={2}
          size={2}
          name="hours"
          disabled={disabled}
          value={hours}
          onKeyDown={fixValue}
          onBlur={validateValue}
          onChange={() => {}}
          autoComplete="off"
        />
        :
        <input
          type="text"
          // inputMode="numeric"
          placeholder="--"
          pattern="[0-9]{2,2}"
          maxLength={2}
          size={2}
          name="minutes"
          disabled={disabled}
          value={minutes}
          onKeyDown={fixValue}
          onBlur={validateValue}
          onChange={() => {}}
          autoComplete="off"
        />
      </div>
    </S.InputFieldDuration>
  );
};

export default InputFieldDuration;
