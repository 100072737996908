import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Active = styled.div`
  background-color: ${colors.header_logo_and_text_Primary};
  border-radius: 3px;
  cursor: pointer;
  display: none;
  height: 3px;
  position: relative;
  top: -3px;
`;

export const NavBarDesktop = styled.nav`
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  margin: 0 15px;
  padding: 0;

  li {
    align-self: stretch;
    display: block;
    height: 70px;
    padding: 0 15px;

    :hover {
      backdrop-filter: brightness(80%);
    }

    a {
      align-items: center;
      box-sizing: border-box;
      color: ${colors.header_logo_and_text_Primary};
      display: flex;
      height: inherit;
      text-decoration: none;
    }

    a.active + ${Active} {
      display: flex;
    }
  }
`;
