import { useLocation } from 'react-router';

// style
import * as S from './PageHeader.styled';
import useTranslate from 'hooks/useTranslate';

export const PageHeader = ({ index, children, controls, ...other }) => {
  const location = useLocation().pathname.split('/');

  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };

  return (
    <S.PageHeader {...other}>
      <h1>
        <span>
          {tr.main[location[location.length - (index || 2)]]}&nbsp;|&nbsp;
        </span>
        <div>{tr.main[location[location.length - (index || 1)]]}</div>
        {controls}
      </h1>
      {children}
    </S.PageHeader>
  );
};
