import styled from 'styled-components/macro';

export const ServiceItemButton = styled.div`
  /* height: 16px; */
  margin-left: auto;
  position: relative;
  /* width: 16px; */

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
