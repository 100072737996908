import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <path
          d="m 23.954236,24.6168 c -1.49775,1.2129 -3.2334,1.8561 -5.022,1.8561 -3.4713,0 -6.5022,-2.3994 -8.07636,-5.9325 h 8.19966 c 0.97215,0 1.7622,-0.79005 1.7622,-1.7622 0,-0.972 -0.79005,-1.76205 -1.7622,-1.76205 H 9.8514763 c -0.093975,-0.6549 -0.14685,-1.33035 -0.14685,-2.02056 0,-0.452265 0.023505,-0.89574 0.06462,-1.333335 H 22.388986 c 0.972,0 1.76205,-0.790005 1.76205,-1.76211 0,-0.972105 -0.79005,-1.76211 -1.76205,-1.76211 h -11.81505 c 1.474305,-3.90309 4.66665,-6.61674 8.3583,-6.61674 1.80615,0 3.5565,0.654915 5.0661,1.891335 0.7518,0.61674 1.86195,0.50808 2.47875,-0.243765 C 27.093736,4.417035 26.985136,3.3069 26.233336,2.69016 24.086386,0.9309855 21.563686,0 18.932236,0 13.346341,0 8.5915663,4.246695 6.8705713,10.140975 h -1.982385 c -0.972105,0 -1.76211,0.790005 -1.76211,1.76211 0,0.972105 0.790005,1.76211 1.76211,1.76211 h 1.34508 c -0.03231,0.440535 -0.05286,0.883995 -0.05286,1.333335 0,0.68427 0.044055,1.35687 0.11748,2.02062 h -1.41264 c -0.97209,0 -1.76211,0.7899 -1.76211,1.76205 0,0.97215 0.79002,1.76205 1.76211,1.76205 h 2.199705 C 8.9645413,26.0763 13.563661,30 18.932236,30 c 2.6079,0 5.1102,-0.91335 7.2423,-2.64315 0.7548,-0.6138 0.87225,-1.72395 0.25845,-2.47875 -0.6138,-0.7548 -1.72095,-0.87225 -2.47875,-0.25845 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = (
        <path
          d="M 7.2032291,20.1579 C 8.9821391,25.8951 13.640314,30 19.093219,30 c 2.57055,0 5.0433,-0.9048 7.1523,-2.6133 0.52575,-0.42495 0.60525,-1.1952 0.1803,-1.72095 -0.4248,-0.5256 -1.19505,-0.6051 -1.7208,-0.1803 -1.6689,1.35405 -3.60975,2.06925 -5.6118,2.06925 -4.13859,0 -7.705575,-3.0381 -9.3102599,-7.3968 h 9.4355099 c 0.6756,0 1.22265,-0.54705 1.22265,-1.22265 0,-0.67545 -0.54705,-1.2225 -1.22265,-1.2225 H 9.1196891 c -0.15588,-0.8742 -0.24147,-1.782 -0.24147,-2.71428 0,-0.681615 0.045855,-1.34793 0.13143,-1.998975 H 22.690819 c 0.67545,0 1.2225,-0.547125 1.2225,-1.22262 0,-0.675495 -0.54705,-1.22262 -1.2225,-1.22262 H 9.5384291 C 11.002519,5.81967 14.731504,2.44524 19.090069,2.44524 c 2.0235,0 3.97965,0.727455 5.66085,2.105955 0.5226,0.42792 1.29285,0.35151 1.7208,-0.168105 0.42795,-0.522675 0.35145,-1.292925 -0.16815,-1.720845 C 24.182419,0.9200205 21.688219,0 19.093219,0 13.417189,0 8.6031341,4.447275 6.9984491,10.554255 h -2.52471 c -0.675495,0 -1.22262,0.547125 -1.22262,1.22262 0,0.675495 0.547125,1.22262 1.22262,1.22262 h 2.07234 c -0.073365,0.654105 -0.116145,1.32042 -0.116145,1.998975 0,0.92613 0.07641,1.83093 0.2109,2.71428 h -2.167095 c -0.675495,0 -1.22262,0.54705 -1.22262,1.2225 0,0.6756 0.547125,1.22265 1.22262,1.22265 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    default:
      content = (
        <path
          d="m 3.3880017,19.1025 c 0,0.35055 0.28677,0.63735 0.637275,0.63735 h 3.32343 C 9.0151917,25.692 13.724697,30 19.265802,30 c 2.5332,0 4.97085,-0.8922 7.0548,-2.5842 0.27405,-0.22305 0.31545,-0.62445 0.0924,-0.89535 -0.22305,-0.27405 -0.6246,-0.31545 -0.89535,-0.0924 -1.8546,1.5039 -4.0149,2.2974 -6.25185,2.2974 -4.856025,0 -9.001545,-3.744 -10.5947403,-8.9856 H 19.396452 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 H 8.3428617 c -0.23898,-1.1088 -0.366435,-2.2686 -0.366435,-3.46674 0,-0.930435 0.07647,-1.841745 0.22305,-2.721195 H 23.013102 c 0.3504,0 0.6372,-0.28677 0.6372,-0.637275 0,-0.350505 -0.2868,-0.63729 -0.6372,-0.63729 H 8.4671367 C 9.8787117,5.378655 14.183547,1.274562 19.265802,1.274562 c 2.25915,0 4.43865,0.809343 6.306,2.342013 0.27075,0.22305 0.6723,0.1848 0.89535,-0.08922 0.22305,-0.270855 0.1848,-0.67233 -0.08925,-0.89538 C 24.281202,0.9113115 21.821352,0 19.265802,0 13.495272,0 8.6264517,4.664895 7.1575167,11.00265 h -3.13224 c -0.350505,0 -0.637275,0.286785 -0.637275,0.63729 0,0.350505 0.28677,0.637275 0.637275,0.637275 h 2.886885 c -0.13701,0.88263 -0.2103,1.79076 -0.2103,2.721195 0,1.19169 0.12108,2.35149 0.34095,3.46674 h -3.017535 c -0.350505,0 -0.637275,0.2868 -0.637275,0.63735 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
