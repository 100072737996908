import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const InputFieldNumber = styled.div`
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  flex-direction: column;

  label {
    color: ${colors.bg_grey_3};
    font-size: 0.875rem;
    margin: 0 10px 5px;
    white-space: nowrap;
  }

  .input-wrapper {
    height: 36px;
    position: relative;

    input {
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 5px;
      color: ${colors.text_Secondary};
      height: 100%;
      outline: none;
      padding-left: 8px;
      padding-right: 36px;
      width: 100%;

      :hover {
        border: 1px solid hsl(0, 0%, 70%);
      }

      :focus {
        border: 1px solid ${colors.bg_grey_3};
        font-weight: bold;
      }

      :disabled {
        background-color: transparent;
        border: 1px solid transparent;

        ::placeholder {
          color: ${({ hasValue }) =>
            !hasValue ? colors.text_Secondary : colors.filterGrey};
        }
      }
    }

    &__icon {
      align-items: center;
      display: flex;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translate(0%, -50%);

      :hover {
        cursor: pointer;

        svg {
          fill: ${colors.text_Secondary};
        }
      }

      svg {
        fill: hsl(0, 0%, 80%);
        pointer-events: all;
      }

      > div {
        display: flex;
      }
    }

    input:focus + &__icon {
      svg {
        fill: ${colors.bg_grey_3};
      }

      :hover svg {
        fill: ${colors.text_Secondary};
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
