import { StyledSVG } from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <StyledSVG
    width={width ? width : '94.14'}
    height={height ? height : '94.14'}
    viewBox="0 0 94.14 94.14"
    color={color ? color : 'currentColor'}
    {...rest}
  >
    <g>
      <g>
        <path d="M47.35,52c8.29,0,15.39-6,19.88-11a7.72,7.72,0,0,0,0-10.31c-4.49-5-11.59-11-19.88-11s-15.38,6-19.87,11a7.72,7.72,0,0,0,0,10.31C32,46.07,39.07,52,47.35,52ZM30.46,33.42c3.23-3.6,9.72-9.65,16.89-9.65S61,29.82,64.25,33.42a3.72,3.72,0,0,1,0,5C61,42,54.52,48,47.35,48s-13.66-6-16.89-9.65A3.72,3.72,0,0,1,30.46,33.42Z" />
        <path d="M47.35,43.73a7.83,7.83,0,1,0-7.82-7.83A7.84,7.84,0,0,0,47.35,43.73Z" />
        <path d="M92.14,0H2A2,2,0,0,0,0,2V74a2,2,0,0,0,2,2H37.41V90.14h-8.3a2,2,0,0,0,0,4H65a2,2,0,0,0,0-4h-8.3V76H92.14a2,2,0,0,0,2-2V2A2,2,0,0,0,92.14,0Zm-2,4V61.38H4V4ZM52.73,90.14H41.41V76H52.73Zm2-18.19H4V65.38H90.14V72Z" />
      </g>
    </g>
  </StyledSVG>
);
