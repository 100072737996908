import { useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './ColumnsVisibility.styled';
// components
import { BaseCheckbox } from 'components/Inputs';

// contexts
import { TableContext } from 'context/TableContext';

export const ColumnsVisibility = () => {
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  const {
    state: { columns },
    dispatch: tableDispatch,
  } = useContext(TableContext);

  const checkIfVisible = useCallback(
    (column) => (column?.isSectorSpecific ? !!sectorId : true),
    [sectorId],
  );

  const enabledColumnsAmount = columns.filter(
    (column) => checkIfVisible(column) && column.isVisible,
  ).length;

  const disabled = enabledColumnsAmount <= 1;

  const handleClick = useCallback(
    (column) => {
      if (disabled && column.isVisible) {
        return;
      }

      tableDispatch({
        type: 'SET_COLUMN',
        payload: { ...column, isVisible: !column.isVisible },
      });
    },
    [disabled],
  );

  return (
    <S.ColumnsVisibility>
      {columns?.map(
        (column) =>
          checkIfVisible(column) && (
            <BaseCheckbox
              value={column.isVisible}
              key={column.id}
              label={column.label}
              onClick={() => handleClick(column)}
              disabled={disabled}
            />
          ),
      )}
    </S.ColumnsVisibility>
  );
};
