import { useEffect, useState } from 'react';

// style
import * as S from './Clients.styled';

// components
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import FunctionBarOptions from 'components/FunctionBar/FunctionBarOptions/FunctionBarOptions';
import { ColumnsVisibility } from 'components/FunctionBar/FunctionBarOptions/Options/Table/ColumnsVisibility/ColumnsVisibility';
import { ClientsTable } from './partials/ClientsTable/ClientsTable';

// contexts
import { TableContextProvider } from 'context/TableContext';

// hooks
import useFunctionBar from 'hooks/useFunctionBar';
import useTranslate from 'hooks/useTranslate';

export const Clients = () => {
  const tr = useTranslate().use().global;
  //   const { data: sectorDb, isSectorsSuccess } = useSectorsGetAll();
  const [functionBarFunctions] = useState(useFunctionBar('clients'));
  //   const [tabs, setTabs] = useState([]);

  //   useEffect(() => {
  //     const sectorDbClone = structuredClone(sectorDb);
  //     sectorDbClone?.forEach((sector) => {
  //       sector.params = { sectorId: sector.id };
  //       delete sector.id;
  //     });
  //     sectorDbClone?.unshift({ id: '', name: tr['all'], end: true });
  //     setTabs(sectorDbClone);
  //   }, [isSectorsSuccess, sectorDb, tr]);

  useEffect(() => {
    // overrideFunctionMethod(
    //     tableFunctions,
    //     'send-sms-link-all',
    //     'hide',
    //     () => true,
    //   );

    //   overrideFunctionMethod(
    //     tableFunctions,
    //     'send-sms-link-filtered',
    //     'hide',
    //     () =>
    //       !!ifRecordsFiltered(currentRecords) &&
    //       !ifRecordsSelected(currentRecords),
    //   );

    //   overrideFunctionMethod(
    //     tableFunctions,
    //     'send-sms-link-selected',
    //     'hide',
    //     () => !!ifRecordsSelected(currentRecords),
    //   );

    //   // overrideFunctionMethod(
    //   //   tableFunctions,
    //   //   'restore-card-filtered',
    //   //   'hide',
    //   //   () =>
    //   //     !!ifRecordsInactive(ifRecordsFiltered(currentRecords)) &&
    //   //     !ifRecordsSelected(currentRecords)
    //   // );

    //   overrideFunctionMethod(
    //     tableFunctions,
    //     'restore-card-selected',
    //     'hide',
    //     () => !!ifRecordsInactive(ifRecordsSelected(currentRecords)),
    //   );

    //   overrideFunctionMethod(
    //     tableFunctions,
    //     'deactivate-card-selected',
    //     'hide',
    //     () => !ifRecordsInactive(ifRecordsSelected(currentRecords)),
    //   );

    //   // -- override table functions button hiding conditions
    //   tableFunctionsItem.hide = () =>
    //     tableFunctions?.some((record) => record.hide());

    //   // tableFunctionsItem.action = (
    //   //   <FunctionBarOptions title={tr.global.functions}>
    //   //     <TableFunctions contents={tableFunctions} />
    //   //   </FunctionBarOptions>
    //   // );

    //   tableFunctionsItem.subItems = tableFunctions;

    functionBarFunctions.find((func) => func.id === 'hide-column').action = (
      <FunctionBarOptions title={tr.showColumns}>
        <ColumnsVisibility />
      </FunctionBarOptions>
    );
  }, []);

  return (
    <MainLayout>
      <TableContextProvider>
        <S.Clients>
          <FunctionBar functions={functionBarFunctions} />
          <S.Container>
            <ClientsTable />
          </S.Container>
        </S.Clients>
      </TableContextProvider>
    </MainLayout>
  );
};
