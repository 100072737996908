export const SCHEDULE_TYPE = Object.freeze({
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  PERSONAL: 'PERSONAL',
});

export const SCHEDULE_CELL_HEIGHT = 20;

export const DIALOG_BOX_TYPE = Object.freeze({
  CONFIRMATION: 'CONFIRMATION',
});

export const TABLE_FILTER_TYPE = Object.freeze({
  SELECT: 'SELECT',
  INPUT: 'INPUT',
  INPUT_RANGE: 'INPUT_RANGE',
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
});

export const ORDER_COMPLETION_STATUS = Object.freeze({
  ORDERED: 'ordered',
  WILL_ARRIVE: 'willarrive',
  ARRIVED_AND_WAITING: 'arrivedandwaiting',
  WILL_NOT_ARRIVE: 'willnotarrive',
  NOT_ARRIVED: 'notarrived',
  CONFIRMED: 'confirmed',
});

export const FLASH_MESSAGE_TYPE = Object.freeze({
  INFO: 'info',
  WARNING: 'warning',
});
