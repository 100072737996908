export const ConditionalWrapper = ({
  condition,
  wrapper,
  secondaryWrapper,
  children,
}) =>
  condition
    ? wrapper(children)
    : secondaryWrapper
      ? secondaryWrapper(children)
      : children;
