import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';

export const InputValueDuration = styled.div`
  align-items: center;
  border-radius: 5px;
  color: ${({ hasValue }) =>
    hasValue ? colors.text_Secondary : colors.filterGrey};
  display: inline-flex;
  flex-wrap: nowrap;
  font-family: ${fontFamilies.family_Secondary};
  /* justify-content: ${({ disabled }) => !disabled && 'center'}; */
  justify-content: center;
  position: relative;
  user-select: ${({ disabled }) => disabled && 'none'};
  width: 100%;

  :hover {
    border-color: ${({ disabled }) => !disabled && colors.disabledGrey};
  }

  :focus-within {
    border-color: ${({ disabled }) => !disabled && colors.bg_grey_3};
    color: ${({ hasValue }) => hasValue && colors.text_Secondary};
    font-weight: 900;
  }

  span {
    padding-block: 2px;
  }

  :hover input,
  :hover span,
  :focus-within input,
  :focus-within span {
    background-color: ${({ disabled, isChanged }) =>
      !disabled && !isChanged && 'white'};
  }

  :hover input,
  :focus-within input {
    :nth-child(1) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    :nth-child(3) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  input,
  span {
    background-color: ${({ isChanged }) =>
      isChanged ? colors.info : 'transparent'};
  }

  input {
    border: none;
    border-radius: 2.5px;
    caret-color: transparent;
    color: ${colors.text_Secondary};
    outline: none;
    padding: 2px 10px;
    padding-inline: 5px;
    text-align: ${({ disabled }) => !disabled && 'center'};
    width: min-content;

    width: ${({ hasValue, disabled }) =>
      !hasValue && disabled ? '1em' : '1.7em'};

    /* // override for Safari
    @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
      width: ${({ hasValue, disabled }) =>
      disabled ? (hasValue ? '1.6em' : '1em') : '1.7em'};
    } */

    :nth-child(1) {
      margin-left: 5px;
      padding-right: ${({ disabled }) => (!disabled ? '2px' : 0)};
      text-align: ${({ disabled }) => !disabled && 'right'};
    }

    :nth-child(3) {
      margin-right: 5px;
      padding-left: 2px;
      text-align: ${({ disabled }) => !disabled && 'left'};
    }

    // no spinners
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      width: 0;
      padding: 0;
      display: none;
    }

    :focus-within {
      font-weight: 900;
    }

    :focus-within::placeholder {
      /* color: ${colors.text_Secondary}; */
    }

    :invalid {
      color: red;
    }

    :disabled {
      background-color: transparent;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      box-shadow: inset 0 0 20px 20px transparent;
      transition: background-color 5000s ease-in-out 0s;
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${colors.text_Secondary};
    }
  }

  &.custom {
    border: 1px solid ${colors.text_Secondary};
  }

  .reset {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
    color: white;
    display: none;
    cursor: pointer;
  }

  &.custom .reset {
    display: block;
  }
`;
