import * as S from '../SVG.styled';

const SportsSVG = ({ color, width, height }) => {
  const content = (
    <>
      <path d="m112.2,200l-29.7-10.06,29.13-85.97,29.7,10.06c.63.21,1.28.32,1.93.32.91,0,1.82-.21,2.66-.62,1.43-.71,2.51-1.95,3.03-3.45l9.68-28.56c1.06-3.14-.62-6.54-3.76-7.61l-5.74-1.94,7.76-22.9c.51-1.51.4-3.16-.3-4.58-.71-1.43-1.95-2.51-3.45-3.03l-18.28-6.19,6.59-19.46c.51-1.51.4-3.16-.3-4.58-.71-1.43-1.95-2.51-3.45-3.03L113.85.32c-1.51-.51-3.16-.4-4.58.3s-2.51,1.95-3.03,3.46l-6.59,19.46-18.28-6.19c-3.14-1.07-6.54.62-7.61,3.76l-7.76,22.9-5.74-1.94c-1.51-.51-3.16-.4-4.58.3-1.43.71-2.51,1.95-3.03,3.45l-9.68,28.56c-1.06,3.14.62,6.54,3.76,7.61l29.7,10.06-29.13,85.97-29.7-10.06c-3.14-1.07-6.55.62-7.61,3.76L.32,200.28c-.51,1.51-.4,3.16.3,4.58.71,1.43,1.95,2.51,3.45,3.03l5.74,1.94-7.76,22.9c-1.06,3.14.62,6.54,3.76,7.61l18.28,6.19-6.59,19.46c-.51,1.51-.4,3.16.3,4.58.71,1.43,1.95,2.51,3.45,3.03l23.82,8.07c.64.22,1.29.32,1.93.32,2.5,0,4.83-1.58,5.68-4.08l6.6-19.47,18.28,6.19c.63.21,1.28.32,1.93.32.91,0,1.82-.21,2.66-.62,1.43-.71,2.51-1.95,3.03-3.45l7.76-22.9,5.74,1.94c.63.21,1.28.32,1.93.32.91,0,1.82-.21,2.66-.62,1.43-.71,2.51-1.95,3.03-3.45l9.68-28.58c1.06-3.14-.62-6.54-3.76-7.61Zm-68.96,68.39l-12.45-4.22,4.67-13.78,6.23,2.11,6.23,2.11-4.67,13.78Zm32.49-17.03l-30.19-10.23-11.91-4.03s0,0,0,0l-18.28-6.19,5.83-17.21,60.38,20.46-5.83,17.21ZM115.69,13.61l12.45,4.22-4.67,13.78-12.45-4.22,4.67-13.78Zm-32.49,17.03l27.47,9.31,14.63,4.96s0,0,.01,0l18.27,6.19-5.83,17.21-30.19-10.23-30.19-10.23,5.83-17.21Zm-21.1,24.71l83.22,28.2-5.83,17.19-83.22-28.2,5.83-17.19Zm25.71,40.55l8.7,2.95,3.76,1.27-29.13,85.97-3.76-1.27-8.69-2.95,29.13-85.97Zm9.03,130.76l-83.22-28.2,5.83-17.21,83.22,28.2-5.83,17.21Z" />
      <path d="m286,217.81c-3.22-.79-6.47,1.18-7.26,4.4-.59,2.4-1.34,4.78-2.23,7.08-1.2,3.09.34,6.57,3.43,7.76.71.28,1.45.41,2.17.41,2.41,0,4.67-1.46,5.6-3.83,1.08-2.79,1.99-5.67,2.7-8.56.79-3.22-1.18-6.47-4.4-7.26Z" />
      <path d="m278.18,192.43c1.22,4.28,1.95,8.71,2.16,13.18.15,3.21,2.8,5.72,5.99,5.72.09,0,.19,0,.29,0,3.31-.16,5.87-2.96,5.71-6.27-.25-5.39-1.13-10.74-2.6-15.91-.91-3.19-4.23-5.03-7.42-4.12-3.19.91-5.03,4.23-4.12,7.42Z" />
      <path d="m258.3,148.24c-2.84-1.71-6.53-.78-8.23,2.06s-.78,6.53,2.06,8.23c8.07,4.84,14.91,11.64,19.8,19.66,1.13,1.85,3.11,2.88,5.13,2.88,1.06,0,2.14-.28,3.12-.88,2.83-1.72,3.73-5.42,2-8.25-5.89-9.67-14.15-17.87-23.87-23.7Z" />
      <path d="m251.9,128.03v-25.25c0-3.31-2.69-6-6-6h-47.22c-3.31,0-6,2.69-6,6v25.27c0,1.89.89,3.67,2.41,4.81,1.05.78,2.31,1.19,3.59,1.19.57,0,1.14-.08,1.7-.25,7.06-2.09,14.43-3.14,21.92-3.14s14.83,1.05,21.91,3.13c1.81.53,3.77.18,5.28-.96s2.4-2.91,2.4-4.8Zm-12-7.64c-11.56-2.3-23.72-2.29-35.22,0v-11.61h35.22v11.6Z" />
      <path d="m277.2,137.46v-45.28c0-12.48-10.15-22.63-22.63-22.63h-64.57c-12.48,0-22.63,10.15-22.63,22.63v45.28c-21.84,16.92-34.79,43.19-34.79,70.91s13.77,55.63,36.85,72.48c1.03.75,2.27,1.15,3.54,1.15h98.66c1.27,0,2.51-.4,3.54-1.15,23.07-16.84,36.84-43.94,36.84-72.48s-12.95-53.99-34.79-70.91Zm-7.59,132.54h-94.66c-19.06-14.66-30.38-37.55-30.38-61.63s12.08-48.47,32.3-63.03c1.57-1.13,2.49-2.94,2.49-4.87v-48.29c0-5.86,4.77-10.63,10.63-10.63h64.57c5.86,0,10.63,4.77,10.63,10.63v48.29c0,1.93.93,3.74,2.49,4.87,20.23,14.56,32.3,38.13,32.3,63.03s-11.32,46.97-30.38,61.63Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 312 282"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default SportsSVG;
