import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { Loader } from 'components/Loader/Loader.styled';

export const ScheduleDay = styled.div`
  flex: 1 0 auto;

  display: flex;
  overflow: auto;
  position: relative;

  width: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
    border: none;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.bg_Primary};
  }

  .scheduleDay-wrapper {
    z-index: 1;
    width: 100%;

    height: 0;
  }

  .schedule__body {
    display: flex;
    position: relative;

    min-width: 100%;
    width: fit-content;
  }

  @media screen and (max-width: 961px) {
    .schedule__body {
      padding-right: ${(props) => props.openPendingOrders && 170}px;
      transition: padding 300ms ease-in-out;
    }

    .vertical {
      position: absolute;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
    }

    .horizontal {
      overflow-y: auto;
      /* overflow-x: hidden; */
      /* width: fit-content; */
      min-width: 100%;
      max-height: 100%;
    }

    .pending-orders-mobile {
      height: 100%;
      /* z-index: 3; */

      position: absolute;
      right: 0;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 200px;

  height: ${(props) => props.height}px;

  position: sticky;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    position: relative;
    width: 40%;
  }

  ${Loader} {
    position: static;

    > div {
      top: 0;
    }
  }
`;

export const Cell = styled.div`
  min-width: ${({ defaultColumnWidth }) => defaultColumnWidth}px;
  width: ${(props) => props.width && props.width + 'px'};
  flex: ${({ width }) => (width ? 'none' : 1)};
  transition: all 300ms ease-in-out 0s;

  @media screen and (max-width: 960px) {
    min-width: 95px;
    width: ${(props) =>
      props.width > props.columnWidth ? props.width : props.columnWidth}px;
  }
`;
