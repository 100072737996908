import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const TimeIndicator = styled.div`
  position: absolute;
  margin-top: -4px;

  display: flex;
  align-items: center;

  width: 100%;

  top: ${(props) => props.position && props.position}%;

  pointer-events: none;

  .timeIndicator__bubble {
    width: 8px;
    height: 8px;

    border-radius: 50%;

    background-color: ${colors.indicatorRed};

    position: sticky;
    left: 50px;

    visibility: ${(props) => props.showBubble && 'visible !important'};
  }

  .timeIndicator__line {
    height: 1px;
    width: 100%;
    background-color: ${colors.indicatorRed};
    position: absolute;
  }
`;
