import * as S from '../SVG.styled';

const BeautySVG = ({ color, width, height }) => {
  const content = (
    <>
      <path d="m76.29,105.06c1.1,1.96,3.14,3.06,5.24,3.06.99,0,2-.25,2.93-.77,2.89-1.62,3.92-5.28,2.3-8.17L38.27,12.8c10.39-2.77,21.76,1.71,27.26,11.51l28.84,51.39c1.62,2.89,5.28,3.92,8.17,2.3,2.89-1.62,3.92-5.28,2.3-8.17l-28.84-51.39C66.24,1.07,44.17-5.13,26.8,4.62c-1.39.78-2.41,2.08-2.84,3.61-.43,1.53-.23,3.17.54,4.56l51.79,92.27Z" />
      <path d="m173.36,174.4c-6.51-.63-12.22-4.27-15.29-9.73l-13.93-24.82c-1.62-2.89-5.28-3.92-8.17-2.3-2.89,1.62-3.92,5.28-2.3,8.17l13.93,24.82c4.99,8.89,14.18,14.79,24.6,15.8,13.03,1.26,24.62,8.81,31.02,20.21,8.33,14.83,6.34,33.34-4.94,46.06-2.94,3.33-6.42,6.13-10.34,8.33-.72.4-1.44.78-2.17,1.13-.22.11-.43.23-.63.36-.41.12-.81.29-1.2.51-2.89,1.62-3.92,5.28-2.3,8.17l12.75,22.71c.57,1.02.71,2.2.4,3.32-.32,1.12-1.05,2.06-2.07,2.63-1.02.57-2.19.71-3.32.4-1.12-.32-2.06-1.05-2.63-2.07l-15.79-28.13c-.77-2.19-2.79-3.83-5.25-4-13.52-.92-25.55-8.51-32.18-20.31-6.4-11.39-6.8-25.23-1.09-37.01,4.56-9.42,4.31-20.34-.68-29.23l-6.62-11.8c-1.62-2.89-5.28-3.92-8.17-2.3-2.89,1.62-3.92,5.28-2.3,8.17l6.62,11.8c3.07,5.47,3.2,12.24.34,18.13-7.42,15.31-6.89,33.3,1.43,48.12,8.05,14.35,22.24,23.91,38.42,26.06l14.8,26.38c2.14,3.81,5.64,6.56,9.84,7.75,1.47.41,2.97.62,4.45.62,2.77,0,5.5-.71,7.98-2.1,3.81-2.14,6.56-5.64,7.75-9.84s.66-8.62-1.48-12.44l-9.72-17.32c4.56-2.74,8.64-6.11,12.13-10.06,14.67-16.53,17.25-40.6,6.42-59.89-8.32-14.82-23.39-24.64-40.33-26.28Z" />
      <path d="m161.84,253.54c2.17.52,4.38.78,6.57.78,4.78,0,9.52-1.23,13.78-3.62,6.22-3.49,10.84-9.14,13.03-15.91,2.42-7.46,1.6-15.67-2.24-22.5-3.84-6.84-10.42-11.81-18.03-13.62-6.92-1.66-14.15-.65-20.37,2.83-6.22,3.49-10.84,9.13-13.03,15.89-2.42,7.47-1.61,15.67,2.23,22.5,3.84,6.84,10.42,11.81,18.05,13.63Zm-8.86-32.44c1.25-3.88,3.91-7.12,7.48-9.12,2.45-1.37,5.17-2.08,7.92-2.08,1.26,0,2.53.15,3.79.45,4.44,1.06,8.12,3.84,10.36,7.82,2.24,3.99,2.69,8.58,1.28,12.94-1.25,3.88-3.91,7.13-7.48,9.13-3.57,2-7.72,2.58-11.68,1.63,0,0,0,0,0,0-4.46-1.07-8.14-3.85-10.38-7.83-2.23-3.98-2.69-8.58-1.27-12.94Z" />
      <path d="m99.01,185.29L195.83,12.79c.78-1.39.97-3.03.54-4.56-.43-1.53-1.45-2.83-2.84-3.61-17.38-9.75-39.45-3.55-49.2,13.83L62.26,164.67c-3.07,5.46-8.78,9.1-15.29,9.73-16.93,1.64-32.01,11.46-40.33,26.28-10.83,19.29-8.24,43.35,6.42,59.88,3.83,4.34,8.36,7.99,13.46,10.85,5.09,2.86,10.56,4.82,16.25,5.84,3.06.55,6.12.82,9.16.82,18.54,0,36.01-9.96,45.31-26.54,8.32-14.82,8.85-32.8,1.43-48.12-2.85-5.88-2.72-12.66.34-18.13Zm-12.24,60.37c-8.32,14.83-25.15,22.78-41.89,19.78-4.37-.78-8.58-2.29-12.5-4.49-3.92-2.2-7.4-5-10.35-8.34-11.28-12.71-13.26-31.21-4.93-46.05,6.4-11.4,17.99-18.95,31.02-20.21,10.42-1.01,19.61-6.91,24.6-15.8L154.8,24.32c4.42-7.88,12.64-12.32,21.09-12.32,2.06,0,4.13.26,6.17.81l-93.51,166.61c-4.99,8.89-5.24,19.82-.68,29.23,5.71,11.78,5.3,25.61-1.09,37.01Z" />
      <path d="m65.75,201.51c-6.22-3.49-13.45-4.5-20.36-2.84-7.63,1.82-14.2,6.78-18.04,13.62-3.84,6.84-4.65,15.04-2.24,22.5,2.19,6.77,6.81,12.42,13.03,15.91,4.26,2.39,9,3.62,13.78,3.62,2.19,0,4.4-.26,6.57-.78,7.64-1.83,14.22-6.8,18.05-13.63,3.83-6.83,4.65-15.03,2.23-22.5-2.18-6.76-6.81-12.41-13.03-15.9Zm.33,32.53c-2.24,3.99-5.92,6.77-10.38,7.83,0,0,0,0,0,0-3.96.95-8.11.37-11.68-1.63-3.57-2-6.23-5.25-7.49-9.14-1.41-4.35-.95-8.95,1.28-12.93,2.24-3.99,5.92-6.76,10.37-7.83,1.25-.3,2.51-.45,3.77-.45,2.75,0,5.47.7,7.92,2.08,3.57,2,6.23,5.24,7.48,9.13,1.41,4.36.96,8.95-1.28,12.94Z" />
      <path d="m308.3,35.46c-3.54-4.75-8.96-7.47-14.89-7.47h-63.48c-5.92,0-11.35,2.72-14.88,7.47-3.54,4.75-4.6,10.72-2.91,16.4l14.94,50.2v47.98c0,3.31,2.69,6,6,6h7.4v134.94c0,11.68,9.51,21.19,21.19,21.19s21.19-9.51,21.19-21.19v-134.94h7.4c3.31,0,6-2.69,6-6v-47.98l14.94-50.2c1.69-5.67.63-11.65-2.91-16.4Zm-37.44,255.51c0,5.07-4.12,9.19-9.19,9.19s-9.19-4.12-9.19-9.19v-134.94h18.38v134.94Zm13.4-146.94h-45.19v-36.85h45.19v36.85Zm15.44-95.6l-13.92,46.75h-48.24l-13.92-46.75c-.6-2.01-.22-4.12,1.03-5.8s3.17-2.64,5.26-2.64h10.55v24.26c0,3.31,2.69,6,6,6s6-2.69,6-6v-24.26h18.38v24.26c0,3.31,2.69,6,6,6s6-2.69,6-6v-24.26h10.55c2.09,0,4.01.96,5.27,2.64s1.63,3.79,1.03,5.8Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 312 312.33"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default BeautySVG;
