import { colors } from 'common/colors/colors';
import styled, { css } from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';

export const PendingOrders = styled.div`
  min-width: 170px;
  z-index: 4;
  position: sticky;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${colors.bg_grey_1};
  box-shadow: -3px 0 2px -2px rgba(15, 29, 91, 0.15);
  ${({ screenHeight, scheduleHeaderSize, dataLoaded }) => css`
    top: ${scheduleHeaderSize}px;
    max-height: ${screenHeight - scheduleHeaderSize}px;
    height: ${screenHeight && dataLoaded ? screenHeight + `px` : '100vh'};
  `}

  .content {
    position: relative;
    flex: 1;
    padding: 0 5px;
    width: 100%;
    height: 100%;
    overflow: auto;
    top: 10px;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

  @media screen and (max-width: 960px) {
    max-height: ${(props) => props.screenHeight}px;
    border-radius: 3px;
    top: 0;

    .filler {
      height: ${({ numberOfDays }) => (numberOfDays.visible > 1 ? 114 : 85)}px;
      padding: 10px;
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      box-shadow: ${shadow.md};
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .filler__title {
        padding: 0;
        position: relative;
        display: block;
        font-size: 12px;
        font-weight: 600;
      }

      .filler__close {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 0 4px;
      }

      .clear_button {
        position: absolute;
        bottom: 10px;
        height: 20px;
        left: 50%;
        transform: translate(-50%);

        button {
          font-size: 0.75rem;

          svg {
            height: 14px;
            width: 14px;
          }

          :hover {
            background-color: ${colors.bg_Button_Secondary};
            color: white;

            svg {
              fill: white;
            }
          }

          span {
            padding-bottom: 2px;
          }
        }
      }
    }

    .content {
      top: 0;
    }
  }
`;

export const DraggableContainer = styled.div`
  width: 100%;
  position: absolute;
  margin-top: ${(props) =>
    props.contentScroll ? -10 - props.contentScroll : -10}px;
  pointer-events: none;
  transition: all 0.1s;
`;

export const DragInHere = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  span {
    color: whitesmoke;
    font-size: 28px;
  }
`;
