import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <>
          <path
            d="M 14.997795,30 C 14.5455,30 14.09613,29.82675 13.75251,29.4831 c -0.68727,-0.6873 -0.68727,-1.8033 0,-2.4936 L 25.74435,14.997795 13.75251,3.008955 c -0.68727,-0.687255 -0.68727,-1.8033165 0,-2.4935115 0.687255,-0.687258 1.80324,-0.687258 2.49354,0 L 29.4831,13.75251 C 29.8149,14.084385 30,14.530815 30,14.997795 30,15.46485 29.8149,15.9141 29.4831,16.24305 L 16.24605,29.4801 c -0.34365,0.34365 -0.7959,0.5169 -1.2453,0.5169 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 1.76073,30 c -0.4522965,0 -0.9016575,-0.17325 -1.2452865,-0.5169 -0.687258,-0.6873 -0.687258,-1.8033 0,-2.4936 L 12.507225,14.997795 0.5154435,3.008955 c -0.687258,-0.687255 -0.687258,-1.8033165 0,-2.4935115 0.687258,-0.687258 1.8033165,-0.687258 2.4935115,0 L 16.24605,13.75251 c 0.3318,0.331875 0.5169,0.778305 0.5169,1.245285 0,0.467055 -0.1851,0.916305 -0.5169,1.245255 L 3.006015,29.4831 C 2.662395,29.82675 2.2101,30 1.76073,30 Z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    case 700:
      content = (
        <>
          <path
            d="m 14.99997,30 c -0.311805,0 -0.626655,-0.11925 -0.865095,-0.3576 -0.476865,-0.47685 -0.476865,-1.25025 0,-1.73025 L 27.0471,15 14.134875,2.087835 c -0.476865,-0.476865 -0.476865,-1.250256 0,-1.7301825 0.476865,-0.47686995 1.250325,-0.47686995 1.730175,0 L 29.64225,14.134905 C 29.8716,14.36418 30,14.67597 30,15 c 0,0.324 -0.1284,0.63585 -0.35775,0.86505 L 15.86505,29.6424 C 15.62655,29.88075 15.31485,30 14.99997,30 Z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 1.222743,30 c -0.311799,0 -0.6266565,-0.11925 -0.8650905,-0.3576 -0.47686995,-0.47685 -0.47686995,-1.25025 0,-1.73025 L 13.269825,15 0.3576525,2.087835 c -0.47686995,-0.476865 -0.47686995,-1.253313 0,-1.7301825 0.4768695,-0.47686995 1.2533175,-0.47686995 1.7301825,0 L 15.86505,14.134905 C 16.0944,14.36418 16.2228,14.67597 16.2228,15 c 0,0.324 -0.1284,0.63585 -0.35775,0.86505 L 2.087835,29.6424 C 1.849395,29.88075 1.537605,30 1.222743,30 Z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
    default:
      content = (
        <>
          <path
            d="m 14.99883,29.998805 c -0.162495,0 -0.325005,-0.06375 -0.449265,-0.18795 -0.248535,-0.24855 -0.248535,-0.65325 0,-0.9018 L 28.461,14.99762 14.549565,1.0893229 c -0.248535,-0.24853201 -0.248535,-0.65319451 0,-0.90172801 0.24852,-0.24853305 0.653235,-0.24853305 0.901785,0 L 29.81205,14.54834 C 29.9331,14.66942 30,14.828735 30,14.99762 c 0,0.168885 -0.0669,0.331335 -0.18795,0.449235 l -14.3607,14.3607 c -0.12435,0.12435 -0.2868,0.1881 -0.4494,0.1881 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="m 0.63806092,29.998805 c -0.1625025,0 -0.325005,-0.06375 -0.449271,-0.18795 -0.2485335,-0.24855 -0.2485335,-0.65325 0,-0.9018 L 14.10027,14.997635 0.18878993,1.0893484 c -0.2517198,-0.24853351 -0.2517198,-0.65001001 0,-0.89854201 0.2517195,-0.24853365 0.65000849,-0.25171995 0.89854197,0 L 15.44805,14.55155 c 0.12105,0.12108 0.18795,0.280395 0.18795,0.449205 0,0.1689 -0.0669,0.3315 -0.18795,0.4494 l -14.3607181,14.3607 c -0.12426598,0.1242 -0.28676847,0.18795 -0.44927098,0.18795 z"
            fill="currentColor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
