import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const GeneralCalendarSettings = styled.div`
  width: 100%;
  max-width: 750px;

  form {
    background-color: ${colors.bg_grey_1};
    padding: 30px;

    padding-right: 10px;
    padding-bottom: 20px;

    border-radius: 10px;

    width: 100%;
    max-width: 750px;

    display: flex;
    flex-wrap: wrap;

    .container {
      width: fit-content;

      margin-right: 20px;

      flex-grow: 1;

      &:last-child {
        flex: 1 1;

        min-width: 150px;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .single-row {
      height: 100%;

      display: flex;
      align-items: center;
    }

    .single-input {
      flex-grow: 1;
      margin-bottom: 20px;
    }

    .double-inputs-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      margin-bottom: 20px;

      .double-inputs {
        display: flex;

        > div {
          margin-right: 10px;
          min-width: 125px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .label {
      margin: 0px 10px 5px 10px;

      font-size: 14px;
      font-family: ${fontFamilies.family_Secondary};
      color: ${colors.bg_grey_3};

      overflow: hidden;
      white-space: nowrap;
    }

    .select__menu-list {
      max-height: 150px;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 10px;
    form {
      border-radius: 5px;
      padding: 10px;

      .double-inputs-wrapper {
        margin-bottom: 10px;

        .double-inputs {
          display: flex;

          > div {
            min-width: 100px;
          }
        }
      }
    }
  }
`;
