import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const Text = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  max-width: 560px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;
