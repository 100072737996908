import styled from 'styled-components/macro';

export const Sort = styled.div``;

export const Criterias = styled.div`
  margin: 20px 20px 80px;
`;

export const Controls = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: calc(100% - 40px);
  bottom: 20px;
  left: 20px;
`;
