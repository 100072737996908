import styled from 'styled-components/macro';

export const AppliedFilters = styled.div`
  width: 100%;
  margin: -10px 0 10px;

  /* > div {
    margin-bottom: 5px;
  } */
`;
