import { useCallback, useContext } from 'react';

// style
import * as S from './FilterCell.styled';

// components
import { BaseInput, BaseInputDate, BaseSelect } from 'components/Inputs';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useTable } from 'hooks/useTable';

// helpers
import { TABLE_FILTER_TYPE } from 'common/constants';
import { toSentenceCase } from 'common/helpers/stringOperations';

export const FilterCell = ({ column }) => {
  const tr = useTranslate().use().global;
  const {
    state: {
      filters: { state: filtersState },
    },
  } = useContext(TableContext);
  const { setFilter } = useTable();

  let content;

  const getRangeField = useCallback(
    (type) => {
      const filterNames = {
        from: column.filter + 'From',
        to: column.filter + 'Till',
      };

      return {
        value: filtersState?.find(
          (filter) => filter.field === filterNames[type],
        )?.value,
        placeholder: toSentenceCase(tr[type]) + ':',
        onChange: (value) => setFilter(value, filterNames[type]),
      };
    },
    [column, filtersState],
  );

  switch (column.filterType) {
    case TABLE_FILTER_TYPE.INPUT_RANGE:
      content = (
        <div className="input-range">
          <BaseInput {...getRangeField('from')} {...column.inputProps} />
          <BaseInput {...getRangeField('to')} {...column.inputProps} />
        </div>
      );

      break;
    case TABLE_FILTER_TYPE.DATE_RANGE:
      content = (
        <div className="date-range">
          <BaseInputDate
            {...getRangeField('from')}
            className="date-picker half-height"
            isClearable={true}
          />
          <BaseInputDate
            {...getRangeField('to')}
            className="date-picker half-height"
            isClearable={true}
          />
        </div>
      );

      break;
    case TABLE_FILTER_TYPE.SELECT:
      content = (
        <BaseSelect
          value={
            filtersState?.find((filter) => filter.field === column.filter)
              ?.value
          }
          options={column.options}
          onChange={(value) => setFilter(value, column.filter)}
          isClearable={true}
          // styles={S.selectMenuStyles({
          //   minWidth: column?.minWidth,
          //   maxWidth: column?.maxWidth,
          //   width: column?.width,
          // })}
          inPortal={false}
        />
      );

      break;
    case TABLE_FILTER_TYPE.INPUT:
      content = (
        <BaseInput
          value={
            filtersState?.find((filter) => filter.field === column.filter)
              ?.value || ''
          }
          onChange={(val) => setFilter(val, column.filter)}
          {...(column?.inputProps && { ...column.inputProps })}
        />
      );

      break;
  }

  return (
    <S.FilterCell
      minWidth={column?.minWidth}
      maxWidth={column?.maxWidth}
      width={column?.width}
    >
      {content}
    </S.FilterCell>
  );
};
