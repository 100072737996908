import { useState, useEffect, useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './Services.styled';

// icons
import { Check, Pencil, ArrowRevert, TrashCan } from 'icons/dynamic';

// components
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { CategoryTree } from './CategoryTree/CategoryTree';
import { Service } from './CategoryTree/Service/Service';
import { CategoryDetails } from './CategoryDetails/CategoryDetails';
import { ServiceDetails } from './ServiceDetails/ServiceDetails';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { SearchWidget } from 'components/SearchWidget/SearchWidget';
import { Overlay } from 'components/Overlay/Overlay';
import { DialogBox } from 'components/DialogBox/DialogBox';
import { PageHeader } from 'components/PageHeader/PageHeader';

// contexts
import { MainContext } from 'pages/Main/Main';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useFunctionBar } from 'hooks/useFunctionBar';
import { useToggler } from 'hooks/useToggler';
import { useSectorsTabs } from 'hooks/useSectorsTabs';
import { useTranslate } from 'hooks/useTranslate';
import {
  useServicesGetCategories,
  useServicesGetServices,
} from 'services/serviceService';
import { getItem, removeObjectById } from 'common/helpers/serviceFunctions';
import { toCamelCase, toSentenceCase } from 'common/helpers/stringOperations';
import { DIALOG_BOX_TYPE } from 'common/constants';

const Services = ({ setButtons }) => {
  const tableTitle = 'services';

  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };
  const isMobile = useMediaQuery('(max-width: 960px)');

  const {
    filterCriteria,
    searchWidgetVisible,
    searchQueries,
    setSearchIconVisibility,
  } = useContext(MainContext);

  const { ['services']: searchQuery } = {
    ...(searchQueries && { ...searchQueries }),
  };

  const filterParams =
    !!filterCriteria &&
    Object.entries(filterCriteria).reduce(
      (acc, item) => ({
        ...acc,
        ...(item[1].value
          ? { [toCamelCase(item[0])]: item[1].value?.value }
          : {}),
      }),
      {},
    );

  // get search params
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId'); // current sector tab

  // get service tree from B/E
  const { data: dbData, isSuccess } = useServicesGetCategories({
    searchParams: {
      ...filterParams,
      includeEmpty: true,
      ...(sectorId && { sectorId }),
    },
  });

  const [categories, setCategories] = useState([]);
  const [activeItem, setActiveItemOriginal] = useState();

  const { data: filteredDBData, isSuccess: isFilterSuccess } =
    useServicesGetServices({
      searchParams: {
        ...(searchQuery && { searchPhrase: searchQuery }),
        ...filterParams,
        ...(sectorId && { sectorId }),
      },
      queryParams: {
        retry: false,
        enabled: !!searchQuery,
        refetchOnWindowFocus: false,
      },
    });

  useEffect(() => {
    setSearchIconVisibility(true);
    return () => setSearchIconVisibility(false);
  }, []);

  // create wrapped state setter for activeItem (in order to remove temporary
  // items if operation was cancelled - e.g. another item is selected)
  const setActiveItem = useCallback(
    (update) => {
      setActiveItemOriginal((currentState) => {
        // determine the nextState
        const nextState =
          typeof update === 'function' ? update(currentState) : update;

        // if last state was "new" -> cleanup the new item
        if (currentState?.id === 'new' && !nextState?.selected) {
          removeObjectById(currentState.id, currentState.type, setCategories);
        }

        if (nextState)
          if (isMobile) {
            if (
              currentState?.id === nextState?.id &&
              currentState?.type === nextState?.type &&
              !currentState?.selected
            ) {
              // console.log('repetitive click');
              nextState.selected = true;
            }
          } else nextState.selected = true;

        // preserve the existing functionality
        return nextState;
      });
    },
    [isMobile],
  );

  // update state from B/E
  useEffect(() => {
    isSuccess && setCategories(dbData);
  }, [isSuccess, dbData]);

  // setup FunctionBar

  // -- setup sector tabs
  const tabs = useSectorsTabs({ includeAllTab: true });

  // -- retrieve initial FunctionBar functions
  // const [functionBarFunctions] = useState(useFunctionBar(tableTitle));
  // const [functionBarFunctions] = useState(useFunctionBar(['print']));
  const [functionBarFunctions] = useState();

  // epmty Details view if any filter params changed
  useEffect(() => {
    setActiveItem(undefined);
  }, [filterCriteria, searchParams, searchQuery, setActiveItem]);

  const Wrapper = (children) => {
    const presetButtons = [
      {
        id: 'edit',
        icon: <Pencil bold />,
        borderless: true,
        show: false,
      },
      {
        id: 'cancel',
        icon: <ArrowRevert bold />,
        // secondary: true,
        outline: true,
        show: false,
      },
      {
        id: 'save',
        icon: <Check bold />,
        borderless: true,
        show: false,
      },
    ];

    const name =
      activeItem.id === 0
        ? activeItem.type === 'category'
          ? tr.global['unassigned-to-category']
          : tr.global['ungrouped']
        : filteredDBData?.services?.find(
            (service) =>
              service.type === 'service' && service.id === activeItem.id,
          )?.name || getItem(categories, activeItem.id, activeItem.type)?.name;

    return (
      <ContentLayout hideFab>
        <DialogLayout
          headerText={name}
          getBack={() =>
            setActiveItem(
              isMobile
                ? { id: activeItem.id, type: activeItem.type }
                : undefined,
            )
          }
          buttons={presetButtons}
          modal
        >
          {children}
        </DialogLayout>
      </ContentLayout>
    );
  };

  // delete confirmation dialog box visibility state
  const confirmDialogHandler = useToggler(false);

  // confirmation dialog box buttons
  const [confirmDeleteButtons, setConfirmDeleteButtons] = useState([
    {
      id: 'cancel',
      title: tr.global['cancel'],
      action: confirmDialogHandler.off,
      inverted: true,
    },
    {
      id: 'remove',
      title: toSentenceCase(tr.global['remove']),
      icon: <TrashCan width={16} height={16} bold />,
    },
  ]);

  // check if activeItem is accessible from (a) service tree (b) search filter response data
  // and if not, reset activeItem
  const isServiceAccessible = useCallback(() => {
    const isItAccessible = isFilterSuccess
      ? filteredDBData?.services?.some(
          (service) => service.id === activeItem.id,
        )
      : !!getItem(categories, activeItem.id, activeItem.type);

    if (!isItAccessible) setActiveItem(undefined);

    return isItAccessible;
  }, [isFilterSuccess, filteredDBData, categories, activeItem, setActiveItem]);

  // logs
  // useEffect(() => console.log({ categories }), [categories]);
  // useEffect(() => activeItem && console.log({ activeItem }), [activeItem]);
  // useEffect(() => console.log({ filteredDBData }), [filteredDBData]);

  return (
    <>
      <S.Services isMobile={isMobile}>
        <FunctionBar tabs={tabs} functions={functionBarFunctions} />
        {/* <FunctionBar functions={functionBarFunctions} /> */}

        <S.RootContainer>
          <PageHeader className="no-hr" />

          <S.ScrollableContainer>
            <S.Container id="category-container">
              <SearchWidget queryId={'services'} />

              {isFilterSuccess ? (
                <div className="filter-results">
                  {filteredDBData?.services?.map((service) => (
                    <Service
                      key={service.id}
                      service={service}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      // serviceAction={() => console.log('show service contents')}
                      noIndent
                      search
                      setConfirmDeleteButtons={setConfirmDeleteButtons}
                      confirmDialogHandler={confirmDialogHandler}
                    />
                  ))}
                </div>
              ) : (
                !searchQuery && (
                  <CategoryTree
                    categories={categories}
                    setCategories={setCategories}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    searchParams={{
                      ...filterParams,
                      ...(sectorId && { sectorId }),
                    }}
                    setConfirmDeleteButtons={setConfirmDeleteButtons}
                    confirmDialogHandler={confirmDialogHandler}
                    sectorId={sectorId}
                    sectors={tabs}
                  />
                )
              )}
            </S.Container>

            {activeItem?.selected && isServiceAccessible() && (
              <S.Container>
                <ConditionalWrapper condition={isMobile} wrapper={Wrapper}>
                  {['service', 'set'].includes(activeItem.type) ? (
                    <ServiceDetails
                      service={filteredDBData?.services?.find(
                        (service) => service.id === activeItem.id,
                      )}
                      treeData={searchQuery ? filteredDBData : categories}
                      setCategories={setCategories}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      sectors={tabs}
                    />
                  ) : (
                    <CategoryDetails
                      treeData={categories}
                      setCategories={setCategories}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      sectorId={sectorId}
                      sectors={tabs}
                    />
                  )}
                </ConditionalWrapper>
              </S.Container>
            )}
          </S.ScrollableContainer>
        </S.RootContainer>

        {/* {!isMobile && <Footer content={2} />} */}
      </S.Services>

      <Overlay
        isVisible={confirmDialogHandler.active}
        hide={confirmDialogHandler.off}
      >
        <div className="modal-wrapper confirm">
          <DialogBox
            close={confirmDialogHandler.off}
            headerText={tr.global['warning']}
            buttons={confirmDeleteButtons}
            type={DIALOG_BOX_TYPE.CONFIRMATION}
          >
            <>{confirmDeleteButtons[0]?.message}</>
          </DialogBox>
        </div>
      </Overlay>
    </>
  );
};

export default Services;
