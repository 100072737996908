import { useEffect, useState } from 'react';

// components
import { Loader } from 'components/Loader/Loader';

export const LoaderDebounce = ({ debounce }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!debounce) {
      setDisplay(true);
      return;
    }

    let timer = setTimeout(() => {
      setDisplay(true);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    display && (
      <div className="loader">
        <Loader />
      </div>
    )
  );
};

export default LoaderDebounce;
