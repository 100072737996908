import useTranslate from 'hooks/useTranslate';
import Select, { components } from 'react-select';
import { useContext } from 'react';
import { DateTime } from 'luxon';

// style
// import * as S from './BaseInputDate.styled';
import * as S from 'components/InputField/InputField.styled';
import { StyledInputErrorMessage } from '../InputsCommon.styled';

// icons
import { Calendar } from 'icons/dynamic';

// components
import SideBarDatePicker from 'components/SideBar/SideBarDatePicker/SideBarDatePicker';

// contexts
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

export default ({
  id,
  label,
  disabled,
  value,
  onChange,
  errorMessage,
  placeholder,
  ...other
}) => {
  const { locale } = useContext(UserContext);
  const tr = useTranslate().use().global;
  let reactSelectValue = null;

  if (value && value?.trim() !== '') {
    reactSelectValue = {
      value: value,
      label: value ? new Date(value).toLocaleDateString(locale) : value,
      // label:
      // value && valueInISO
      //   ? new Date(value).toLocaleDateString(locale)
      //   : value,
    };
  }

  let placeholderText = '-';
  if (!disabled) {
    placeholderText = tr['select'];
  }

  if (placeholder) {
    placeholderText = placeholder;
  }

  return (
    // <S.BaseInputDate>
    <S.InputField
      disabled={disabled}
      noLabel={!label}
      className={'input-field'}
      fieldType={'calendar'}
      inputType={undefined}
      notClearable={undefined}
      noOptions={undefined}
      noValue={!value}
      extendOnOpen={undefined}
      isMenuOpen={undefined}
      menuHeight={undefined}
      stayOpen={undefined}
      prepend={undefined}
      required={undefined}
      width={undefined}
      isInvalid={!!errorMessage}
    >
      {!!label && <label htmlFor={id}>{label}</label>}
      <Select
        // menuIsOpen={true}
        className="select"
        classNamePrefix="select"
        placeholder={placeholderText}
        isDisabled={disabled}
        value={reactSelectValue}
        onChange={onChange}
        // valueInISO={valueInISO}
        components={{
          Menu,
          DropdownIndicator,
          IndicatorSeparator: null,
        }}
        {...other}
      />
      <StyledInputErrorMessage>{errorMessage}</StyledInputErrorMessage>
    </S.InputField>
    // </S.BaseInputDate>
  );
};

const Menu = (props) => {
  const { sectorData } = useContext(MainContext);

  return (
    <S.Menu {...props}>
      <SideBarDatePicker
        value={props.selectProps?.value?.value}
        onChange={(value) => {
          // const val = props?.selectProps?.valueInISO
          //   ? value.value
          //   : value.label;

          const val = DateTime.fromISO(value.value).toFormat('yyyy-LL-dd');
          props?.selectProps.onChange(val);
          props.setValue(val, 'select-option');
        }}
        toISOValue
        calendarType={
          sectorData?.settings?.firstDayOfWeek === 7 ? 'gregory' : 'iso8601'
        }
      />
    </S.Menu>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator className={'close'} {...props}>
    <Calendar width={16} height={16} />
  </components.DropdownIndicator>
);
