import { FormProvider } from 'react-hook-form';

export default ({ children, onSubmit, methods, ...other }) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} {...other}>
        {children}
      </form>
    </FormProvider>
  );
};
