import { useState } from 'react';
import Select from 'react-select';

// style
import * as S from './Pagination.styled';

// icons
import { Arrow, ArrowEnd } from 'icons/dynamic';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useMediaQuery from 'hooks/useMediaQuery';
import { colors } from 'common/colors/colors';

export const Pagination = ({
  recordsPerPage,
  recordsPerPageHandler,
  totalRecords,
  page,
  changePage,
}) => {
  const pageNumbers = [];
  // const [currentPage, setCurrentPage] = useState(1);

  for (let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const lastPage = () => pageNumbers.slice(-1)[0] || 1;

  const tr = useTranslate().use().pagination;

  const isMobile = useMediaQuery('(max-width: 960px)');

  const pageQtyOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const [selectMenuOpen, setSelectMenuOpen] = useState(false);

  const selectStyles = {
    control: (base, { isFocused, isSelected }) => ({
      ...base,
      fontWeight: isFocused ? 'bold' : 'inherit',
      borderColor: 'currentColor',
      '&:hover': {
        borderColor: 'currentColor',
      },
      borderRadius: selectMenuOpen ? '10px 10px 0 0' : '18px',
    }),
    input: (base, { isFocused }) => ({
      ...base,
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.text_Secondary,
    }),
    placeholder: (base) => ({
      ...base,
      color: colors.filterGrey,
    }),
    dropdownIndicator: (base, { isFocused }) => ({
      ...base,
      padding: '0 8px',
      color: isFocused ? colors.text_Secondary : colors.filterGrey,
    }),
    menu: (base, { isFocused }) => ({
      ...base,
      boxShadow: '0 2px 3px rgba(0, 0, 0, 16%)',
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      color: colors.text_Secondary,
      fontWeight: isSelected ? 'bold' : 'regular',
      backgroundColor: isFocused ? colors.bg_grey_2 : null,
      '&:active': {
        backgroundColor: colors.bg_grey_2,
        fontWeight: 'bold',
      },
    }),
  };

  const handleToFirstPage = () => changePage(1);

  const hanleToPreviusPage = () => page > 1 && changePage((prev) => prev - 1);

  const hanleToNextPage = () =>
    page < lastPage(pageNumbers) && changePage((prev) => prev + 1);

  const handleToLastPage = () => changePage(lastPage(pageNumbers));

  return (
    <S.Container>
      <S.ControlSection>
        <ArrowEnd
          bold
          width={20}
          className="arrow first-page"
          onClick={handleToFirstPage}
        />
        <Arrow
          bold
          width={20}
          className="arrow previus-page"
          onClick={hanleToPreviusPage}
        />
        <div className="page-indication">
          {page}&nbsp;/&nbsp;{lastPage()}
        </div>
        <Arrow
          bold
          width={20}
          className="arrow next-page"
          onClick={hanleToNextPage}
        />
        <ArrowEnd
          bold
          width={20}
          className="arrow"
          onClick={handleToLastPage}
        />
      </S.ControlSection>

      <S.InfoSection>
        {!isMobile && <label htmlFor="select-page-qty">{tr.show}</label>}
        <Select
          id="select-page-qty"
          className="select"
          classNamePrefix="select"
          options={pageQtyOptions}
          styles={selectStyles}
          value={{ value: recordsPerPage, label: recordsPerPage }}
          onChange={({ value }) => recordsPerPageHandler(value)}
          // menuIsOpen={true}
          onMenuOpen={() => setSelectMenuOpen(true)}
          onMenuClose={() => setSelectMenuOpen(false)}
        />
        {/* <InputField
          id="select-page-qty"
          state={{
            type: 'switch',
            value: {
              value: recordsPerPage,
              label: recordsPerPage,
            },
            setValue: (id, { value }) => {
              console.log({ value });
              recordsPerPageHandler(value);
            },
          }}
          notClearable
          options={pageQtyOptions}
        /> */}
        {!isMobile && (
          <span>
            {tr['overall']}: <b>{totalRecords}</b>
          </span>
        )}
      </S.InfoSection>
    </S.Container>
  );
};

export default Pagination;
