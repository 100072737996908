import { useContext } from 'react';

// style
import * as S from './FiltersRow.styled';

// components
import { FilterCell } from './partials/FilterCell/FilterCell';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import { useTable } from 'hooks/useTable';

export const FiltersRow = ({ hasNavigation, hasDeleteButton }) => {
  const {
    state: { columns },
  } = useContext(TableContext);
  const { getColumnVisibility } = useTable();

  return (
    <S.FiltersRow>
      {hasNavigation && <th></th>}
      {hasDeleteButton && <th></th>}
      {columns?.map(
        (column) =>
          getColumnVisibility(column) && (
            <FilterCell key={column.id + '-' + 'filter'} column={column} />
          ),
      )}
    </S.FiltersRow>
  );
};
