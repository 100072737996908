import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const DataPanelColumn = styled.div`
  min-width: ${({ defaultColumnWidth }) => defaultColumnWidth}px;
  width: ${(props) => props.expand && props.expand + 'px'};
  flex: ${(props) => (props.expand ? 'none' : 1)};

  transition: all 300ms ease-in-out;

  .collumn-body {
    position: relative;

    border: ${(props) => props.isActive && '1px solid red'};
  }

  &:not(:first-child) {
    .timeIndicator__bubble {
      visibility: hidden;
    }
  }

  &:first-child {
    .dataPanelCell {
      border-left: 1px solid rgba(0, 0, 0, 0%);
    }
  }

  &:last-child {
    .collumn-body {
      border-right: 1px solid ${colors.bg_grey_2};
    }
  }

  @media screen and (max-width: 960px) {
    min-width: 95px;

    width: ${(props) =>
      props.expand > props.columnWidth ? props.expand : props.columnWidth}px;
  }
`;
