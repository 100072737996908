import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const AddButton = styled.div`
  width: 100%;
  height: 100%;

  > button {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    background-color: ${colors.bg_Primary};

    box-shadow: 0 1px 2px rgba(15, 29, 91, 50%);

    div {
      display: flex;
    }

    svg {
      fill: white;
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 960px) {
    > button {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
