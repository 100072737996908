import { useCallback, useReducer, useState } from 'react';

export const useFormState = (fields, config = { skipDefaults: false }) => {
  const { skipDefaults, auxUpdateFn } = config;

  const [options, setOptions] = useState(
    fields.reduce((acc, field) => {
      return { ...acc, [field.id]: field.options };
    }, {}),
  );

  const reducer = (state, action) => {
    if (action.id === 'setup') {
      return action.value;
    } else {
      const newState = { ...state };

      switch (action.id) {
        case 'update-default':
          newState[action.fieldId].default = action.value;
          break;
        default:
          newState[action.id].value = action.value;
      }
      return newState;
    }
  };

  // field conversion, if necessary
  const convertFieldToObject = useCallback(
    (data, type) =>
      data !== undefined
        ? typeof data === 'object'
          ? data
          : {
              value: data,
              label: ['hours', 'minutes'].includes(type)
                ? formDurationLabel(data, type)
                : `${data}`,
            }
        : undefined,
    [],
  );

  // change value function
  const setValue = useCallback(
    (headerId, value, type) => {
      dispatch({ id: headerId, value: convertFieldToObject(value, type) });
      auxUpdateFn && auxUpdateFn(headerId, value);
    },
    [auxUpdateFn, convertFieldToObject],
  );

  const formDurationLabel = (data, type) => {
    switch (type) {
      case 'minutes':
        return data >= 60
          ? `${Math.floor(data / 60) < 10 ? 0 : ''}${Math.floor(data / 60)}:${
              data % 60 > 9 ? data % 60 : `0${data % 60}`
            }`
          : `00:${data > 9 ? data : `0${data}`}`;
      default:
      // return data;
    }
  };

  // default values
  const defaultValues = useCallback(
    (resource) =>
      resource?.reduce((acc, field) => {
        return {
          ...acc,
          [field.id]: {
            type: field.type,
            value:
              !skipDefaults && 'default' in field
                ? ['switch', 'search', 'select', 'array', 'minutes'].includes(
                    field.type,
                  ) || field.type === 'object'
                  ? convertFieldToObject(field.default, field.type)
                  : field.options?.find(
                      (option) => option.value === field.default,
                    )
                : null,
            setValue,
            ...('default' in field && {
              default: convertFieldToObject(field.default, field.type),
            }),
          },
        };
      }, {}),
    [skipDefaults, setValue, convertFieldToObject],
  );

  const [state, dispatch] = useReducer(reducer, defaultValues(fields));

  const resetState = useCallback(
    (resource) => dispatch({ id: 'setup', value: defaultValues(resource) }),
    [defaultValues],
  );

  // reset values handler
  const resetFields = useCallback(
    (fieldIds, clear) => {
      if (fieldIds) {
        fieldIds.forEach((fieldId) => {
          setValue(
            fieldId,
            clear ? undefined : defaultValues(fields)[fieldId].default,
          );
        });
      } else {
        fields.forEach((field) => {
          setValue(
            field.id,
            clear ? undefined : defaultValues(fields)[field.id].default,
          );
        });
      }
    },
    [setValue, defaultValues, fields],
  );

  const resetOptions = useCallback(
    (resource) =>
      setOptions(
        resource.reduce((acc, field) => {
          return { ...acc, [field.id]: field.options };
        }, {}),
      ),
    [setOptions],
  );

  // clear values handler
  const clearFields = useCallback(
    (fieldIds) => resetFields(fieldIds, true),
    [resetFields],
  );

  // update default value of specific field (for async B/E data)
  const updateDefault = useCallback(
    (id, value) => {
      dispatch({
        id: 'update-default',
        fieldId: id,
        value: convertFieldToObject(value),
      });
      setValue(id, convertFieldToObject(value));
    },
    [setValue, convertFieldToObject],
  );

  // return the headers' state
  return {
    options,
    state,
    setValue,
    resetFields,
    resetOptions,
    clearFields,
    updateDefault,
    resetState,
  }; // setValue's headerId: 'setup' -> setup all state
};
