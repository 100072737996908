import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const FormContainer = styled.div`
  background-color: ${(props) =>
    props.disabled ? 'transparent' : colors.bg_grey_1};
  border: 1px solid
    ${(props) => (props.disabled ? colors.bg_grey_2 : 'transparent')};
  border-radius: 10px;
  /* margin-bottom: 20px; */
  max-width: 670px;
  padding: 30px;

  @media screen and (max-width: 960px) {
    max-width: unset;
  }

  @media screen and (max-width: 600px) {
    /* padding: unset; */
    border: none;
    padding: 10px;
    width: unset;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;

    @media screen and (max-width: 960px) {
      /* flex-direction: column; */
      row-gap: 20px;
    }

    @media screen and (max-width: 600px) {
      row-gap: 10px;
    }
  }

  .row {
    display: flex;
    gap: 20px;
    width: 100%;

    @media screen and (max-width: 600px) {
      /* display: block; */
      flex-direction: column;
      gap: 10px;
    }

    /* .input-container {
      flex: 1 0 50%;
    } */
  }

  .input-container {
    position: relative;
    flex: 1 0 calc(50% - 10px);

    @media screen and (max-width: 960px) {
      width: 100%;
    }

    /* :last-child {
      flex: 100%;
    } */

    &.half-width {
      flex: 0 1 calc(50% - 10px);
    }
  }
`;
