import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { fontFamilies } from 'common/fonts/fonts';

export const SettingsTemplate = styled.section``;

export const PageContents = styled.div`
  margin: 40px 40px 0;

  @media screen and (max-width: 960px) {
    margin: 10px 10px 80px;
  }
`;

export const Container = styled.div`
  display: flex;
  font-family: ${fontFamilies.family_Secondary};

  @media screen and (max-width: 960px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

export const Title = styled.h2`
  border-bottom: 1px solid ${colors.table_borders_Primary};
  color: ${colors.text_Secondary};
  display: flex;
  flex-wrap: wrap;
  font-family: ${fontFamilies.family_Primary};
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 38px;
  margin: 0 0 20px;
  min-width: max-content;
  padding-bottom: 20px;

  @media (max-width: 960px) {
    font-size: 1.125rem;
    margin: unset;
    width: 100%;
  }

  span {
    font-weight: 400;
  }

  div {
    flex: 1;
  }
`;

export const Links = styled.div`
  height: 100%;
  margin-right: 40px;
  position: sticky;
  top: 180px;

  @media screen and (max-width: 960px) {
    margin-right: revert;
    width: 100%;
  }

  & ~ * {
    border-left: 1px solid ${colors.table_borders_Primary};
    padding-left: 40px;

    @media screen and (max-width: 960px) {
      border-left: revert;
      padding-left: revert;
    }
  }

  a {
    align-items: center;
    border-left: 5px solid transparent;
    border-radius: 0 25px 25px 0;
    box-shadow: ${shadow.md};
    color: ${colors.text_Secondary};
    display: flex;
    height: 50px;
    font-weight: 900;
    padding: 0 20px 0 15px;
    text-decoration: none;
    white-space: nowrap;

    @media screen and (max-width: 960px) {
      border-bottom: 1px solid ${colors.table_borders_Primary};
      border-left: unset;
      border-radius: unset;
      box-shadow: unset;
      padding-left: 0;
      width: 100%;
    }

    :not(:last-child) {
      margin-bottom: 10px;

      @media screen and (max-width: 960px) {
        margin-bottom: unset;
      }
    }

    &.active {
      border-left: 5px solid ${colors.tab_borders_active};

      @media screen and (max-width: 960px) {
        border: unset;
        border-bottom: 1px solid ${colors.table_border};
      }
    }
  }
`;

export const TabContents = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    width: 0;
  }
`;
