import React from 'react';

// Style
import * as S from './ActionColumn.styled';

const ActionColumn = ({ icon, action }) => {
  return (
    <S.ActionColumn>
      <div className="icon-wrapper" onClick={action}>
        {icon}
      </div>
    </S.ActionColumn>
  );
};

export default ActionColumn;
