import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const StyledSVG = styled.svg`
  fill: ${(props) => (props.color ? props.color : colors.bg_Primary)};

  /* transition-property: transform; */

  /* ROTATE ANIMATION */
  transition-duration: ${(props) => props.scaleAnimation && '0.5s'};
  transform: ${(props) => props.rotateAnimation && 'rotate(45deg)'};
  margin: ${({ margin }) => margin};

  /* SCALE ANIMATION */
  animation: ${(props) => props.scaleAnimation && 'resize 0.2s ease-out'};

  @keyframes resize {
    0% {
      ${(props) => props.scaleAnimation && 'transform: scale(1)'}
    }

    80% {
      ${(props) => props.scaleAnimation && 'transform: scale(1.2)'}
    }

    100% {
      ${(props) => props.scaleAnimation && 'transform: scale(1)'}
    }
  }

  /* transform: translate(0px, 50px) scale(0.5); */

  @media screen and (max-width: 961px) {
    &.arrow-revert {
      transform: translate3d(1px, 0, 0);
    }
  }
`;

export default StyledSVG;
