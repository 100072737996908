import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const SetListItem = styled.div`
  display: flex;
  gap: 10px;
  height: 60px;

  .setListItem__info-container {
    border: 1px solid ${colors.bg_grey_2};
    border-radius: 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
  }

  .setListItem__title {
    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;
    display: flex;

    &-set {
      flex-shrink: 0;
      height: 14px;
      margin-right: 5px;
      width: 14px;
    }

    &-name {
      font-weight: 600;
      margin-right: 5px;
    }

    > span {
      word-wrap: break-word;
    }
  }

  .setListItem__details-container {
    color: ${colors.bg_grey_3};
    display: flex;
    padding-top: 5px;

    > div {
      margin-right: 20px;
    }
  }

  .setListItem__details {
    align-items: center;
    display: flex;
  }

  .setListItem__details-icon {
    display: flex;
    height: 13px;
    margin-right: 5px;
    width: 13px;

    svg {
      fill: ${colors.bg_grey_3};
      height: 100%;
      width: 100%;
    }
  }

  .setListItem__details-label {
    font-family: ${fontFamilies.family_Secondary};
    font-size: 11px;
  }

  .setListItem__action-container {
    align-items: center;
    display: flex;
    gap: 10px;
    width: 125px;
  }

  .setListItem__amount {
    align-items: center;
    border: 1px solid ${colors.bg_grey_2};
    border-radius: 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .setListItem__amount > div,
  .setListItem__amount .input-wrapper {
    height: calc(100% + 2px);
    /* width: calc(100% + 2px); */
  }

  .setListItem__amount > div {
    margin: -1px;
  }

  .setListItem__trash-wrapper {
    color: ${colors.bg_grey_3};
    cursor: pointer;
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
    }

    :hover .setListItem__trash-icon svg {
      color: ${colors.mandyRed};
    }
  }
`;
