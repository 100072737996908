import styled from 'styled-components/macro';

export const BusinessDetails = styled.div`
  gap: 50px;

  &,
  .field-group {
    display: flex;
    flex-direction: column;
  }

  .field-group {
    gap: 20px;
  }

  .field-group--horizontal {
    flex-direction: row;

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;
    }

    > * {
      /* flex-basis: 50%; */
      flex: 1 1 50%;
    }
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  fieldset > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  fieldset > div > * {
    display: inline;
  }

  label {
    display: flex;
    align-items: start;
  }

  label svg {
    flex-shrink: 0;
    height: 16px;
    margin-right: 10px;
    width: 16px;
  }

  label span {
    display: block;
  }

  /* input[type='checkbox'] {
    appearance: revert;
  } */

  input[type='checkbox'] ~ span svg {
    margin-right: 10px;
  }
`;
