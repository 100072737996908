import styled from 'styled-components/macro';

export const WorkspaceWrapper = styled.div`
  position: absolute;

  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  display: flex;

  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  padding: 0 40px;

  > div {
    margin-bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &:last-child {
      margin-bottom: 0px;
    }
  }

  @media screen and (min-width: 961px) {
    top: unset;
    bottom: 140px;
    right: 100px;
    transform: unset;

    width: unset;
    padding: 0;

    > div {
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
`;
