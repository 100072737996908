import * as S from '../SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 9.2204025,8.19015 c 0,4.51545 3.2314355,8.18715 7.2057895,8.18715 3.97425,0 7.2057,-3.6717 7.2057,-8.18715 C 23.631892,3.674715 20.400442,0 16.426192,0 12.451838,0 9.2204025,3.674715 9.2204025,8.19015 Z m 11.9657395,0 c 0,3.167235 -2.1339,5.74137 -4.75995,5.74137 -2.626154,0 -4.760054,-2.574135 -4.760054,-5.74137 0,-3.16722 2.1339,-5.744415 4.760054,-5.744415 2.62605,0 4.75995,2.577195 4.75995,5.744415 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 22.519042,15.558 -0.29655,-0.10095 c -0.3912,-0.1314 -0.8223,-0.06105 -1.14645,0.19575 -1.37265,1.07295 -2.98065,1.6416 -4.64985,1.6416 -1.66926,0 -3.274275,-0.56865 -4.65,-1.6416 -0.324059,-0.2538 -0.755114,-0.32715 -1.146434,-0.19575 L 10.330163,15.558 c -1.2840155,0.44325 -2.4457505,1.2045 -3.3567755,2.20425 -0.45552,0.4983 -0.42189,1.2717 0.079485,1.72725 0.498315,0.45555 1.271775,0.4218 1.727295,-0.0795 0.568635,-0.62055 1.2717905,-1.11285 2.0483105,-1.4277 1.68144,1.14945 3.604395,1.75485 5.600714,1.75485 1.99635,0 3.91935,-0.6054 5.6007,-1.75485 2.1279,0.8682 3.53715,2.94405 3.53715,5.2644 v 4.30755 H 6.0654075 c -0.67563,0 -1.222875,0.5472 -1.222875,1.22295 0,0.6756 0.547245,1.2228 1.222875,1.2228 H 26.786842 c 0.67575,0 1.22295,-0.5472 1.22295,-1.2228 v -5.5305 c 0,-3.4698 -2.20425,-6.5607 -5.49075,-7.69185 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 9.3885675,25.0596 c -0.476925,0.477 -0.476925,1.2504 0,1.7304 0.238455,0.2385 0.55029,0.35775 0.8651705,0.35775 0.314895,0 0.626715,-0.11925 0.865185,-0.35775 l 2.632215,-2.6322 c 0.05808,-0.05805 0.106995,-0.11925 0.152865,-0.18645 0.01835,-0.0306 0.03363,-0.0612 0.04891,-0.0918 0.02139,-0.03975 0.04586,-0.07635 0.06114,-0.11925 0.01529,-0.03975 0.02751,-0.0825 0.03974,-0.12525 0.0092,-0.03375 0.02446,-0.06735 0.03059,-0.10395 0.03057,-0.159 0.03057,-0.321 0,-0.48 -0.0061,-0.03675 -0.0214,-0.07035 -0.03059,-0.10395 -0.01222,-0.04275 -0.02139,-0.0825 -0.03974,-0.1254 -0.01835,-0.04275 -0.03975,-0.0795 -0.06114,-0.11925 -0.01835,-0.03045 -0.03057,-0.0642 -0.04891,-0.09165 -0.04587,-0.0672 -0.09478,-0.1314 -0.152865,-0.18645 l -2.632215,-2.6322 c -0.476925,-0.477 -1.2534455,-0.477 -1.7303555,0 -0.476925,0.47685 -0.476925,1.25025 0,1.73025 l 0.54723,0.54735 h -6.722715 c -0.675645,0 -1.222875,0.5472 -1.222875,1.2228 0,0.6756 0.54723,1.2228 1.222875,1.2228 h 6.725775 l -0.547245,0.54735 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 16.486807,15.7983 c 3.78945,0 6.8745,-3.54396 6.8745,-7.897515 C 23.361307,3.54723 20.279407,0 16.486807,0 12.694148,0 9.6122475,3.544035 9.6122475,7.900785 c 0,4.356735 3.0850955,7.897515 6.8745595,7.897515 z m 0,-14.5234665 c 3.0882,0 5.59965,2.9735415 5.59965,6.6259515 0,3.652395 -2.51145,6.622755 -5.59965,6.622755 -3.088305,0 -5.599724,-2.97036 -5.599724,-6.622755 0,-3.65241 2.51142,-6.6259515 5.599724,-6.6259515 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 8.0441925,19.1703 c 0.736215,-0.8064 1.67004,-1.41825 2.6994605,-1.77525 1.708275,1.24935 3.687465,1.9059 5.746304,1.9059 2.0589,0 4.038,-0.6597 5.74635,-1.9059 2.6421,0.9147 4.4172,3.40695 4.4172,6.20205 v 5.12805 H 5.6857575 c -0.35058,0 -0.637425,0.2868 -0.637425,0.63735 0,0.3507 0.286845,0.6375 0.637425,0.6375 H 27.287857 c 0.35055,0 0.63735,-0.2868 0.63735,-0.6375 v -5.7654 c 0,-3.34635 -2.1258,-6.3231 -5.2938,-7.41315 l -0.30915,-0.10515 c -0.20385,-0.07005 -0.42705,-0.0318 -0.5991,0.102 -1.54575,1.20795 -3.35595,1.8453 -5.2395,1.8453 -1.883624,0 -3.693884,-0.63735 -5.239619,-1.8453 -0.16893,-0.1338 -0.395205,-0.17205 -0.599175,-0.102 l -0.312345,0.10515 c -1.2365855,0.42705 -2.3552555,1.1601 -3.2380655,2.1258 -0.23904,0.2613 -0.219915,0.663 0.04143,0.90195 0.261345,0.2391 0.66291,0.2199 0.901935,-0.0414 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 11.263163,24.2793 -1.6572905,1.6572 c -0.248595,0.2487 -0.248595,0.6534 0,0.90195 0.124305,0.12435 0.286845,0.1881 0.4493855,0.1881 0.16254,0 0.32508,-0.06375 0.44937,-0.1881 l 2.747265,-2.74725 c 0.02869,-0.02865 0.05738,-0.06375 0.07968,-0.09885 0.0096,-0.0159 0.01595,-0.0318 0.0255,-0.0477 0.01275,-0.0192 0.02231,-0.0414 0.03188,-0.06375 0.0096,-0.02235 0.01275,-0.0414 0.01912,-0.06375 0.0064,-0.0192 0.01275,-0.0351 0.01593,-0.05415 0.01593,-0.08295 0.01593,-0.16575 0,-0.2487 -0.0032,-0.01905 -0.0096,-0.03825 -0.01593,-0.05415 -0.0064,-0.02235 -0.01275,-0.0414 -0.01912,-0.06375 -0.0096,-0.0222 -0.02232,-0.0414 -0.03188,-0.06375 -0.0096,-0.0159 -0.01593,-0.0318 -0.0255,-0.0477 -0.0223,-0.0351 -0.05099,-0.06705 -0.07968,-0.09885 l -2.747265,-2.74725 c -0.248581,-0.24855 -0.6533405,-0.24855 -0.9019355,0 -0.248595,0.24855 -0.248595,0.6534 0,0.90195 L 11.259968,22.998 H 2.7122175 c -0.35058,0 -0.637425,0.28695 -0.637425,0.6375 0,0.35055 0.286845,0.63735 0.637425,0.63735 h 8.5509455 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
