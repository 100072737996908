import { useTranslate } from 'hooks/useTranslate';

const APP_ROOT = process.env.REACT_APP_APP_ROOT;

export const useTopMenu = () => {
  const tr = useTranslate().use().main;

  const menuMain = [
    {
      name: tr['functions'],
      acl: 1,
      route: APP_ROOT + 'functions',
    },
    {
      name: tr['reports'],
      acl: 2,
      route: APP_ROOT + 'reports',
    },
    {
      name: tr['warehouse'],
      acl: 2,
      route: APP_ROOT + 'warehouse',
    },
    {
      name: tr['settings'],
      acl: 1,
      route: APP_ROOT + 'settings',
    },
  ];

  return menuMain;
};
