import { useState, useEffect, useCallback, useMemo } from 'react';
import { applyStartTime } from 'common/helpers/dateOperations';

// Utility functions
const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

const mergeHours = (cacheHours, dbHours) => {
  const hours = { ...dbHours };
  for (const [key, value] of Object.entries(cacheHours)) {
    hours[key] = value;
  }
  return hours;
};

const mergeDatabaseArray = (
  totalArray = [],
  newArray = [],
  newArrayRange = null,
) => {
  // Create a map from the newArray for quick lookup
  const newMap = new Map(
    newArray.map((entry) => [entry.date.getTime(), entry]),
  );

  // Helper function to deep clone the 'hours' object
  const cloneHours = (hours) => {
    if (!hours) return null;
    return Object.keys(hours).reduce((clone, key) => {
      clone[key] = new Date(hours[key].getTime());
      return clone;
    }, {});
  };

  // Determine if dateRange is valid
  const hasDateRange =
    newArrayRange &&
    newArrayRange.startDate instanceof Date &&
    !isNaN(newArrayRange.startDate.getTime()) &&
    newArrayRange.endDate instanceof Date &&
    !isNaN(newArrayRange.endDate.getTime());

  const startDate = hasDateRange ? newArrayRange.startDate : new Date(0);
  const endDate = hasDateRange ? newArrayRange.endDate : new Date();

  // Create the merged array
  const mergedArray = [];

  // Add entries from totalArray, updating with newArray values if they exist
  totalArray.forEach((entry) => {
    const dateKey = entry.date.getTime();
    const inRange = entry.date >= startDate && entry.date <= endDate;

    if (newMap.has(dateKey)) {
      // If the date is in newArray, update with newArray's entry (deep cloned)
      mergedArray.push({
        date: entry.date,
        hours: cloneHours(newMap.get(dateKey).hours),
      });
    } else if (!hasDateRange || !inRange) {
      // If no range is specified or date is outside the range, keep the original entry from totalArray
      mergedArray.push({
        date: entry.date,
        hours: cloneHours(entry.hours),
      });
    }
    // If the date is within the range but not in newArray, it will not be added (effectively removed)
  });

  // Add entries from newArray that don't exist in totalArray
  newArray.forEach((newEntry) => {
    const dateKey = newEntry.date.getTime();
    if (!totalArray.some((entry) => entry.date.getTime() === dateKey)) {
      mergedArray.push({
        date: newEntry.date,
        hours: cloneHours(newEntry.hours),
      });
    }
  });

  // Sort the mergedArray by date
  return mergedArray.sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const useCacheManagement = () => {
  const [currentDatabaseArray, setCurrentDatabaseArray] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [cacheArray, setCacheArray] = useState([]);
  const [liveArray, setLiveArray] = useState([]);
  const [totalDatabaseArray, setTotalDatabaseArray] = useState([]);

  const generateLiveArray = useCallback(
    (startDate, endDate) => {
      if (!startDate || !endDate) return [];

      const dateRangeArray = generateDateRange(startDate, endDate);

      return dateRangeArray.map((date) => {
        const dbObject = totalDatabaseArray.find(
          (obj) => obj.date.getTime() === date.getTime(),
        );
        const cacheObject = cacheArray.find(
          (c) => c.date.getTime() === date.getTime(),
        );

        const hours = dbObject
          ? cacheObject
            ? mergeHours(cacheObject.hours, dbObject.hours || {})
            : dbObject.hours || {}
          : cacheObject
            ? cacheObject.hours
            : {};

        return {
          date,
          hours,
        };
      });
    },
    [totalDatabaseArray, cacheArray],
  );

  const getTotalDatabaseRange = useCallback(() => {
    if (totalDatabaseArray.length === 0) {
      return { startDate: null, endDate: null };
    }

    // Find the earliest and latest dates directly from the totalDatabaseArray
    const startDate = totalDatabaseArray[0].date;
    const endDate = totalDatabaseArray[totalDatabaseArray.length - 1].date;

    return { startDate, endDate };
  }, [totalDatabaseArray]);

  const [updateCurrent, setUpdateCurrent] = useState(false);

  useEffect(() => {
    // console.log({
    //   updateCurrent,
    //   dateRange,
    //   totalDatabaseArray,
    //   currentDatabaseArray,
    // });
    setTotalDatabaseArray((prevArray) => {
      const merged = mergeDatabaseArray(
        prevArray,
        currentDatabaseArray,
        updateCurrent ? null : dateRange,
        // null,
      );
      // console.log({ merged });
      setUpdateCurrent(false);
      return merged;
    });
  }, [currentDatabaseArray, cacheArray, dateRange, updateCurrent]);

  const getCacheRange = useCallback(() => {
    if (cacheArray.length === 0) {
      return { startDate: null, endDate: null };
    }

    const startDate = cacheArray[0].date;
    const endDate = cacheArray[cacheArray.length - 1].date;

    return { startDate, endDate };
  }, [cacheArray]);

  useEffect(() => {
    const totalDatabaseRange = getTotalDatabaseRange();
    const cacheRange = getCacheRange();

    const startDate = Math.min(
      !totalDatabaseRange.startDate ? Infinity : totalDatabaseRange.startDate,
      !dateRange.startDate ? Infinity : dateRange.startDate,
      !cacheRange.startDate ? Infinity : cacheRange.startDate,
      applyStartTime(new Date()),
    );

    const endDate = Math.max(
      !totalDatabaseRange.endDate ? -Infinity : totalDatabaseRange.endDate,
      !dateRange.endDate ? -Infinity : dateRange.endDate,
      !cacheRange.endDate ? -Infinity : cacheRange.endDate,
      applyStartTime(new Date()),
    );

    setLiveArray(generateLiveArray(new Date(startDate), new Date(endDate)));
  }, [generateLiveArray, getTotalDatabaseRange, dateRange, getCacheRange]);

  const maintainCache = useCallback(
    (date, value, index) => {
      setCacheArray((prevCacheArray) => {
        const existingCacheObjectIndex = prevCacheArray.findIndex(
          (obj) => obj.date.getTime() === date.getTime(),
        );
        const databaseObject = totalDatabaseArray.find(
          (obj) => obj.date.getTime() === date.getTime(),
        );

        const shouldClearCache =
          value === undefined && databaseObject?.hours[index] === undefined;

        let updatedCacheArray = [...prevCacheArray];

        if (existingCacheObjectIndex > -1) {
          const existingCacheObject =
            updatedCacheArray[existingCacheObjectIndex];
          if (
            shouldClearCache ||
            databaseObject?.hours[index]?.getTime() === value?.getTime()
          ) {
            delete existingCacheObject.hours[index];
          } else {
            existingCacheObject.hours[index] = value;
          }

          if (Object.keys(existingCacheObject.hours).length === 0) {
            updatedCacheArray.splice(existingCacheObjectIndex, 1);
          } else {
            updatedCacheArray[existingCacheObjectIndex] = existingCacheObject;
          }
        } else if (
          databaseObject?.hours[index]?.getTime() !== value?.getTime()
        ) {
          const newCacheObject = {
            date,
            hours: { [index]: value },
          };

          let insertionIndex = 0;
          while (
            insertionIndex < updatedCacheArray.length &&
            updatedCacheArray[insertionIndex].date.getTime() < date.getTime()
          ) {
            insertionIndex++;
          }
          updatedCacheArray.splice(insertionIndex, 0, newCacheObject);
        }

        return updatedCacheArray;
      });

      setLiveArray((prevLiveArray) => {
        return prevLiveArray.map((liveObj) => {
          if (liveObj.date.getTime() === date.getTime()) {
            const newHours = { ...liveObj.hours };

            const isReverting =
              value === undefined &&
              totalDatabaseArray.find(
                (obj) => obj.date.getTime() === date.getTime(),
              )?.hours[index] === undefined;

            if (
              isReverting ||
              value?.getTime() ===
                totalDatabaseArray
                  .find((obj) => obj.date.getTime() === date.getTime())
                  ?.hours[index]?.getTime()
            ) {
              delete newHours[index];
            } else {
              newHours[index] = value;
            }
            return { ...liveObj, hours: newHours };
          }
          return liveObj;
        });
      });
    },
    [totalDatabaseArray],
  );

  const hasHourChanged = useCallback(
    (date, index) => {
      // Find the corresponding objects in cache and database arrays
      const cacheObject = cacheArray.find(
        (obj) => obj.date.getTime() === date.getTime(),
      );
      const databaseObject = totalDatabaseArray.find(
        (obj) => obj.date.getTime() === date.getTime(),
      );

      // Get the cached and database values
      const cachedValue = cacheObject?.hours?.[index];
      const databaseValue = databaseObject?.hours?.[index];

      // Case 1: Database object does not exist
      if (!databaseObject) {
        // If the cache object exists and has a value, it's a change
        return (
          cacheObject?.hours?.hasOwnProperty(index) && cachedValue !== undefined
        );
      }

      // Case 2: Database object exists
      if (cacheObject) {
        // Case 2a: Cache value exists and is different from database value
        if (cachedValue !== undefined) {
          // Check if the cache value is different from the database value
          if (databaseValue === undefined) {
            // The value is new (does not exist in the database)
            return true;
          }
          return cachedValue?.getTime() !== databaseValue?.getTime();
        }

        // Case 2b: Cache value is undefined and database value is defined
        if (
          cacheObject?.hours?.hasOwnProperty(index) &&
          cachedValue === undefined &&
          databaseValue !== undefined
        ) {
          return true;
        }
      }

      // If the database object exists but there's no matching index in cache,
      // or if both are undefined or both are defined and matching, it's not a change
      return false;
    },
    [cacheArray, totalDatabaseArray],
  );

  const hasAnyChanges = useCallback(() => {
    return cacheArray.length > 0;
  }, [cacheArray]);

  const clearCache = useCallback((fromDate) => {
    setCacheArray((prevCacheArray) => {
      if (!fromDate) {
        return []; // Clear all cache
      }

      const from = new Date(fromDate).setHours(0, 0, 0, 0); // Normalize to start of the day
      return prevCacheArray.filter((item) => item.date.getTime() < from);
    });

    // setLiveArray(generateLiveArray(dateRange.startDate, dateRange.endDate)); // Update liveArray
  }, []);

  const clearTotalDatabase = useCallback((fromDate) => {
    setTotalDatabaseArray((prevCacheArray) => {
      if (!fromDate) {
        return []; // Clear all cache
      }

      const from = new Date(fromDate).setHours(0, 0, 0, 0); // Normalize to start of the day
      return prevCacheArray.filter((item) => item.date.getTime() < from);
    });

    // setLiveArray(generateLiveArray(dateRange.startDate, dateRange.endDate)); // Update liveArray
  }, []);

  const applyTemplateToRange = useCallback(
    (startDate, endDate, templateArray, config = {}) => {
      const { repetition = 'daily', application = {} } = config;
      const {
        workdays = false,
        weekends = false,
        holidays = false,
      } = application;

      let currentDate = new Date(startDate);
      let templateIndex = 0;

      const createDateWithTime = (timeDate, baseDate) => {
        if (!(timeDate instanceof Date) || isNaN(timeDate)) return undefined;
        return new Date(
          baseDate.getFullYear(),
          baseDate.getMonth(),
          baseDate.getDate(),
          timeDate.getHours(),
          timeDate.getMinutes(),
          timeDate.getSeconds(),
          timeDate.getMilliseconds(),
        );
      };

      const getTemplateForDate = (index) => {
        const template = templateArray[index];
        const timeValues = {};

        if (template) {
          Object.entries(template.hours).forEach(([hourIndex, value]) => {
            timeValues[hourIndex] = createDateWithTime(value, currentDate);
          });
        }

        return timeValues;
      };

      const getDayOfWeekIndex = (date) => {
        const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, etc.
        return dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Adjust so Monday is 0, Tuesday is 1, etc.
      };

      const getWeekNumber = (date) => {
        const tempDate = new Date(date.getTime());
        const dayOfWeek = tempDate.getDay() === 0 ? 7 : tempDate.getDay();
        tempDate.setDate(tempDate.getDate() + (4 - dayOfWeek));
        const firstDayOfYear = new Date(tempDate.getFullYear(), 0, 1);
        const pastDaysOfYear =
          (tempDate - firstDayOfYear + 86400000) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
      };

      const shouldApplyTemplate = () => {
        const dayOfMonth = currentDate.getDate();
        const weekNumber = getWeekNumber(currentDate);

        switch (repetition) {
          case 'daily':
            return true;
          case 'every-second-day':
            return (dayOfMonth - startDate.getDate()) % 2 === 0;
          case 'odd-days':
            return dayOfMonth % 2 !== 0;
          case 'even-days':
            return dayOfMonth % 2 === 0;
          case 'weekly':
            return true;
          case 'every-second-week':
            return (
              (getWeekNumber(currentDate) - getWeekNumber(startDate)) % 2 === 0
            );
          case 'odd-weeks':
            return weekNumber % 2 !== 0;
          case 'even-weeks':
            return weekNumber % 2 === 0;
          case 'continuous':
          default:
            return true;
        }
      };

      // Set starting index for weekly-based repetitions
      if (
        ['weekly', 'every-second-week', 'odd-weeks', 'even-weeks'].includes(
          repetition,
        )
      ) {
        const startDayIndex = getDayOfWeekIndex(currentDate);
        templateIndex = startDayIndex % 7;
      }

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();

        if (
          ((workdays && [1, 2, 3, 4, 5].includes(dayOfWeek)) ||
            (weekends && [6, 0].includes(dayOfWeek))) &&
          shouldApplyTemplate()
        ) {
          const template = getTemplateForDate(templateIndex);
          Object.entries(template).forEach(([index, value]) => {
            maintainCache(
              new Date(currentDate),
              !value ? undefined : new Date(value),
              parseInt(index, 10),
            );
          });
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);

        if (
          !(
            repetition === 'continuous' &&
            ((!workdays && [1, 2, 3, 4, 5].includes(dayOfWeek)) ||
              (!weekends && [6, 0].includes(dayOfWeek)))
          )
        ) {
          templateIndex = (templateIndex + 1) % templateArray.length;
        }
      }
    },
    [maintainCache],
  );

  const getMatchingHour = (date, index) => {
    const cacheObject = cacheArray.find(
      (obj) => obj.date.getTime() === date.getTime(),
    );
    const databaseObject = totalDatabaseArray.find(
      (obj) => obj.date.getTime() === date.getTime(),
    );

    const cacheValue = cacheObject?.hours[index];
    const databaseValue = databaseObject?.hours[index];

    // If the value exists in the cache, return it. Otherwise, return the value from the database, or undefined if neither exists.
    return cacheValue !== undefined ? cacheValue : databaseValue;
  };

  // Function to process the cache and generate the array for the backend
  const processCacheForBackend = useCallback(() => {
    // Collect all relevant cache dates
    const cacheDates = new Set(cacheArray.map((obj) => obj.date.getTime()));

    // Create a map of all database hours for quick lookup
    const databaseHoursMap = new Map(
      totalDatabaseArray.map((obj) => [obj.date.getTime(), obj.hours]),
    );

    // Iterate over cache array to process matching logic
    return cacheArray.map((cacheObject) => {
      // Initialize hours for the current cache object
      const newHours = { ...cacheObject.hours };

      // Get the corresponding database hours if available
      const databaseHours =
        databaseHoursMap.get(cacheObject.date.getTime()) || {};

      // Iterate over each index in the cache object
      for (let index = 0; index < 4; index++) {
        if (newHours.hasOwnProperty(index)) {
          const matchingIndex = index % 2 === 0 ? index + 1 : index - 1;

          // Only process the matching index if it doesn't already exist in cache
          if (!newHours.hasOwnProperty(matchingIndex)) {
            // Directly get the value for the matching index from the database
            newHours[matchingIndex] = databaseHours[matchingIndex];
          }

          // Skip the next index as it's already processed
          index++;
        }
      }

      // Include any additional values from the database that are not in the cache
      for (let index = 0; index < 4; index++) {
        if (
          !newHours.hasOwnProperty(index) &&
          databaseHours.hasOwnProperty(index)
        ) {
          newHours[index] = databaseHours[index];
        }
      }

      return {
        date: cacheObject.date,
        hours: newHours,
      };
    });
  }, [cacheArray, totalDatabaseArray]);

  /**
   * Function to get the slice of liveArray between the start and end dates.
   * @param {Date} startDate - The start date for the slice.
   * @param {Date} endDate - The end date for the slice.
   * @returns {Array} - The slice of liveArray within the date range.
   */
  const getLiveArraySlice = useCallback(
    (startDate, endDate) => {
      if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        throw new Error('Invalid date arguments provided.');
      }

      // Filter liveArray to return items within the specified date range
      return liveArray.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    },
    [liveArray],
  );

  /**
   * Function to clear the cache within a specific date range.
   * @param {Date} startDate - The start date of the range to clear.
   * @param {Date} endDate - The end date of the range to clear.
   */
  const clearRange = (startDate, endDate) => {
    const emptyTemplateArray = [
      {
        hours: {
          0: undefined,
          1: undefined,
          2: undefined,
          3: undefined,
        },
      },
    ];

    const config = {
      repetition: 'continuous', // Ensure this applies to every day
      application: {
        workdays: true,
        weekends: true,
        holidays: true,
      },
    };

    // Apply the empty template to clear the range
    applyTemplateToRange(startDate, endDate, emptyTemplateArray, config);
  };

  /**
   * Function to check if the liveArray has any objects with any values in the specified date range.
   * @param {Date} startDate - The start date of the range to check.
   * @param {Date} endDate - The end date of the range to check.
   * @returns {boolean} - Returns true if there are any values in the range, otherwise false.
   */
  const hasValuesInRange = useCallback(
    (startDate, endDate) => {
      const startTime = startDate?.getTime();
      const endTime = endDate?.getTime();

      return liveArray.some((entry) => {
        const entryDate = new Date(entry.date); // Assuming each object in liveArray has a 'date' field
        const entryTime = entryDate.getTime();

        // Check if the entry's date falls within the specified range
        if (entryTime >= startTime && entryTime <= endTime) {
          // Check if any of the hours (0 to 3) have defined values
          for (let hourIndex = 0; hourIndex <= 3; hourIndex++) {
            if (entry.hours[hourIndex] !== undefined) {
              return true; // Found a value within the specified range
            }
          }
        }

        return false; // No values found for this entry
      });
    },
    [liveArray],
  );

  /**
   * Function to check if a specific date has any hour values in liveArray.
   * @param {Date} date - The date to check.
   * @returns {boolean} - Returns true if the object for that date has any hour values, otherwise false.
   */
  const hasDateValues = useCallback(
    (date) => {
      // Reuse hasValuesInRange by setting both start and end date to the same date
      return hasValuesInRange(date, date);
    },
    [hasValuesInRange],
  );

  /**
   * Function to validate if a specific date and hours entry has errors.
   * @param {Date} date - The date to check.
   * @param {number} index - The index of the hours entry to validate.
   * @returns {number} - Returns 1 if the corresponding value doesn't exist,
   * 2 if the value order is incorrect, otherwise returns 0.
   */
  const hasDateErrors = useCallback(
    (date, index) => {
      // Convert the provided date to its time value for comparison
      const dateTime = date.getTime();

      // Find the corresponding entry in liveArray for the provided date
      const entry = liveArray.find(
        (e) => new Date(e.date).getTime() === dateTime,
      );

      if (!entry) {
        return 0; // If there's no entry for the date, no errors can be determined.
      }

      const hours = entry.hours || {};
      const isEvenIndex = index % 2 === 0;

      // Determine the corresponding index to check against
      const correspondingIndex = isEvenIndex ? index + 1 : index - 1;

      // 1. Check if the current index exists and the corresponding value is missing (either way)
      if (
        (hours.hasOwnProperty(index) &&
          !hours.hasOwnProperty(correspondingIndex)) ||
        (!hours.hasOwnProperty(index) &&
          hours.hasOwnProperty(correspondingIndex)) ||
        (hours[index] && !hours[correspondingIndex]) ||
        (!hours[index] && hours[correspondingIndex])
      ) {
        return 1; // One of the corresponding values does not exist
      }

      // 2. Check the order of values
      const currentValue = hours[index];
      const correspondingValue = hours[correspondingIndex];

      if (currentValue !== undefined && correspondingValue !== undefined) {
        if (isEvenIndex && currentValue > correspondingValue) {
          return 2; // Error: current value is greater than the corresponding value
        } else if (!isEvenIndex && currentValue < correspondingValue) {
          return 2; // Error: current value is less than the corresponding value
        }
      }

      return 0; // No errors found
    },
    [liveArray],
  );

  /**
   * Function to check if liveArray has any errors in any hour field.
   * @returns {Array<{ date: Date, error: string }> | undefined} -
   * An array of objects with 'date' and 'error' keys, or undefined if no errors found.
   */
  const hasAnyErrors = useCallback(() => {
    const errorEntries = [];

    liveArray.forEach((entry) => {
      const date = new Date(entry.date);

      for (let index = 0; index < 4; index++) {
        // Assuming we only check indices 0-3
        const errorType = hasDateErrors(date, index);
        if (errorType === 1) {
          errorEntries.push({ date, error: 'missing-value' });
          break; // Break out of the loop once an error is found for this date
        } else if (errorType === 2) {
          errorEntries.push({ date, error: 'time-sequence-mismatch' });
          break; // Break out of the loop once an error is found for this date
        }
      }
    });

    return errorEntries.length > 0 ? errorEntries : undefined;
  }, [liveArray, hasDateErrors]);

  const getCurrentLiveArray = useMemo(() => {
    return dateRange.startDate && dateRange.endDate
      ? getLiveArraySlice(dateRange.startDate, dateRange.endDate)
      : [];
  }, [getLiveArraySlice, dateRange]);

  const isDateVisible = useCallback(
    (date) =>
      date.getTime() >= dateRange?.startDate?.getTime() &&
      date.getTime() <= dateRange?.endDate?.getTime(),
    [dateRange],
  );

  return {
    liveArray: getCurrentLiveArray,
    cacheArray,
    maintainCache,
    hasHourChanged,
    hasAnyChanges,
    setCurrentDatabaseArray,
    setDateRange,
    getCacheRange,
    clearCache,
    clearTotalDatabase,
    getMatchingHour,
    processCacheForBackend,
    applyTemplateToRange,
    getLiveArraySlice,
    clearRange,
    hasValuesInRange,
    hasDateValues,
    hasDateErrors,
    hasAnyErrors,
    isDateVisible,
    setUpdateCurrent,
  };
};

export default useCacheManagement;
