import * as S from './ButtonSquare.styled';

export const ButtonSquare = ({
  onClick,
  height = '20px',
  children,
  Icon,
  ...rest
}) => {
  console.log(rest);
  return (
    <S.ButtonSquare onClick={onClick} height={height} {...rest}>
      <div className="square-button__icon-container">{Icon}</div>
      {children && (
        <span className="square-button__text-container">{children}</span>
      )}
    </S.ButtonSquare>
  );
};

export default ButtonSquare;
