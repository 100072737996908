import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const DB_PATH = process.env.REACT_APP_DB_ROOT;

export const useFetch = (key, params, config) => {
  const context = useQuery(
    [key, ...(!!params ? [params] : [])],
    ({ queryKey }) => fetcher({ queryKey }),
    {
      enabled: !!key,
      ...config,
    },
  );

  return context;
};

export const fetcher = ({ queryKey, pageParam }) => {
  const [key, params] = queryKey;

  return axios
    .get(
      DB_PATH +
        key +
        (params?.id ? '/' + params.id : '') +
        (params?.query ? '?' + params.query : ''),
    )
    .then((res) => res.data);
};

// TODO: needs adaptation to curent app.
// The following hooks are taken from https://www.smashingmagazine.com/2022/01/building-real-app-react-query/
export const useGenericMutation = (func, url, params, updater) => {
  const queryClient = useQueryClient();

  return useMutation(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url, params]);

      const previousData = queryClient.getQueryData([url, params]);

      queryClient.setQueryData([url, params], (oldData) => {
        return updater ? (oldData, data) : data;
      });

      return previousData;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, _, context) => {
      queryClient.setQueryData([url, params], context);
    },

    onSettled: () => {
      queryClient.invalidateQueries([url, params]);
    },
  });
};

export const useDelete = (url, params, updater) => {
  return useGenericMutation(
    (id) => axios.delete(`${url}/${id}`),
    url,
    params,
    updater,
  );
};

export const usePost = (url, params, updater) => {
  return useGenericMutation(
    (data) => axios.post(url, data),
    url,
    params,
    updater,
  );
};

export const useUpdate = (url, params, updater) => {
  return useGenericMutation(
    (data) => axios.patch(url, data),
    url,
    params,
    updater,
  );
};
