import { useContext, useEffect, useState } from 'react';

// style
import * as S from './BusinessTypeSelector.styled';

// components
import { ListSelector } from 'components/ListSelector/ListSelector';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

export const BusinessTypeSelector = ({ setStage, setNext, auto }) => {
  const tr = useTranslate().use().global;
  const { setLanguage } = useContext(UserContext);

  useRestoreAppUserCredentials();

  const [type, setType] = useState();

  // set function for 'Next' button
  useEffect(
    () =>
      setNext(() => () => {
        console.log('BusinessTypeSelector');
        // setLanguage(lng?.value);
        setStage('fill-business-data' + (auto ? '-auto' : ''));
      }),
    [setStage, type, setNext, setLanguage, auto],
  );

  // useEffect(
  //   () => isLocalesSuccess && console.log({ locales }),
  //   [locales, isLocalesSuccess]
  // );

  return (
    <S.BusinessTypeSelector>
      <ListSelector
        state={{
          current: type,
          options: [
            { value: 'company', label: tr['legal-entity'] },
            { value: 'private', label: tr['self-employment'] },
          ],
          default: 'company',
          setValue: setType,
        }}
        type={'radio'}
      />
    </S.BusinessTypeSelector>
  );
};

export default BusinessTypeSelector;
