import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// style
import * as S from './UserDetails.styled';

// components
import { InputField } from 'components/InputField/InputField';
import { BaseCheckbox } from 'components/Inputs';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';
import { toSentenceCase } from 'common/helpers/stringOperations';

export const UserDetails = ({ setStage, setNext, auto }) => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  const { user, updateUser } = useContext(UserContext);

  // const { data, isSuccess } = useCountriesGetAll(['flag', 'name']);

  // configure required fields
  const setupFields = useCallback(() => {
    return [
      {
        id: 'email',
        level: 1,
        type: 'select',
        default: user?.email,
      },
      {
        id: 'firstName',
        level: 1,
        type: 'search',
        default: user?.firstName,
      },
      {
        id: 'lastName',
        level: 1,
        type: 'search',
        default: user?.lastName,
      },
    ];
  }, [user]);

  const [consentTac, setConsentTac] = useState(false);
  const [consentPp, setConsentPp] = useState(false);

  // create form state
  const { state, options, resetOptions } = useFormState(setupFields());

  // useEffect(() => {
  //   console.log({ isSuccess });
  // }, [isSuccess]);

  // useEffect(
  //   () => isSuccess && resetOptions(setupFields()),
  //   [isSuccess, resetOptions, setupFields]
  // );

  useEffect(
    () =>
      setNext(() => () => {
        console.log('UserDetails');

        if (
          consentTac &&
          consentPp &&
          (state?.['firstName'].value || state?.['lastName'].value)
        ) {
          const firstName = state?.['firstName']?.value
            ? toSentenceCase(state['firstName'].value?.value)
            : '';
          const lastName = state?.['lastName']?.value
            ? toSentenceCase(state['lastName'].value.value)
            : '';

          updateUser({
            user: {
              ...user,
              ...(firstName && { firstName }),
              ...(lastName && { lastName }),
              fullName: `${firstName} ${lastName}`.trim(),
            },
          });

          if (auto) {
            setStage('select-business-type-auto');
          } else {
            navigate(APP_ROOT + 'dashboard');
          }
        } else {
          console.log('must comply to agreements');
        }
      }),
    [
      state,
      consentPp,
      consentTac,
      updateUser,
      setNext,
      setStage,
      user,
      navigate,
      APP_ROOT,
      auto,
    ],
  );

  // useEffect(
  //   () => console.log({ user, state, options, data }),
  //   [user, state, options, data]
  // );

  useEffect(() => {
    console.log({ consentTac, consentPp });
  }, [consentTac, consentPp]);

  useEffect(() => {
    console.log({ user, state, options });
  }, [user, state, options]);

  return (
    <S.UserDetails>
      <div className="field-group">
        <InputField
          id={'email'}
          label={tr['email']}
          state={state?.['email']}
          options={options?.['email']}
          disabled
        />

        <InputField
          id={'firstName'}
          label={tr['firstName']}
          state={state?.['firstName']}
          options={options?.['firstName']}
        />

        <InputField
          id={'lastName'}
          label={tr['lastName']}
          state={state?.['lastName']}
          options={options?.['lastName']}
        />
      </div>

      <fieldset>
        <BaseCheckbox
          id="consent-tac"
          label={
            <>
              {tr['agree-to']} <a href="/">{tr['consent-tac']}</a>
            </>
          }
          value={consentTac}
          onClick={() => setConsentTac((old) => !old)}
        />
        <BaseCheckbox
          id="consent-pp"
          label={
            <>
              {tr['agree-to']} <a href="/">{tr['consent-pp']}</a>
            </>
          }
          value={consentPp}
          onClick={() => setConsentPp((old) => !old)}
        />
      </fieldset>
    </S.UserDetails>
  );
};

export default UserDetails;
