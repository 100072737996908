// style
import * as S from './MainLayout.styled';

// components
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

export const MainLayout = ({ children }) => {
  return (
    <S.MainLayout>
      <ErrorBoundary>{children}</ErrorBoundary>
    </S.MainLayout>
  );
};
