import { useImperativeHandle, useState, forwardRef } from 'react';
import classNames from 'classnames';

// style
import * as S from './Carousel3d.styled';

export const Carousel3d = forwardRef(function Carousel3d({ images }, ref) {
  const [currAngle, setCurrAngle] = useState(0);

  const n = images.length;
  const deltaAngle = 360 / n;

  const initBgIndexArray = Array.from(Array(n), (_, index) => index).filter(
    (index) => ![n - 1, 0, 1].includes(index),
  );

  useImperativeHandle(ref, () => ({
    slide(dir) {
      rotate(dir);
    },
  }));

  const rotate = (direction) => {
    const carousel = document.getElementsByClassName('carousel')[0];
    const items = document.getElementsByClassName('item');

    let currIndex, nextIndex;

    const sceneAngle = Math.abs(
      carousel.style.transform.replace(/[^0-9]/g, '') % 360,
    );

    currIndex = sceneAngle / deltaAngle;
    nextIndex = currIndex + (direction === 'right' ? +1 : -1);
    if (direction === 'right' && nextIndex > n - 1) {
      nextIndex = 0;
    } else if (direction === 'left' && nextIndex < 0) {
      nextIndex = n - 1;
    }

    const currItem = items[currIndex];
    const nextItem = items[nextIndex];

    const leftIndex = nextIndex === 0 ? n - 1 : nextIndex - 1;
    const rightIndex = nextIndex === n - 1 ? 0 : nextIndex + 1;

    const fgItems = document.querySelectorAll(
      `.item[data-index="${leftIndex}"],.item[data-index="${nextIndex}"],.item[data-index="${rightIndex}"]`,
    );
    const bgItems = document.querySelectorAll(
      `.item:not([data-index="${leftIndex}"]):not([data-index="${nextIndex}"]):not([data-index="${rightIndex}"])`,
    );

    // hide all but three front items
    bgItems.forEach((item) => item.classList.add('hide'));
    fgItems.forEach((item) => item.classList.remove('hide'));

    // fade every item, which is not in front
    if (currItem.classList.contains('fadeIn')) {
      currItem.classList.replace('fadeIn', 'fadeOut');
    }

    if (nextItem.classList.contains('fadeOut')) {
      nextItem.classList.replace('fadeOut', 'fadeIn');
    }

    let tempAngle;

    if (direction === 'left') {
      tempAngle = currAngle + deltaAngle;
    }
    if (direction === 'right') {
      tempAngle = currAngle - deltaAngle;
    }
    setCurrAngle(tempAngle);

    carousel.style.transform = `rotateY(${tempAngle}deg)`;
    Array.from(items).forEach((item) => {
      item.style.transform = `rotateY(${-tempAngle}deg)`;
    });
  };

  return (
    <S.Carousel3d currAngle={currAngle}>
      <div className="scene">
        <div className="carousel">
          {images.map((image, index) => {
            return (
              <div
                style={{
                  transform: `rotateY(${
                    index * deltaAngle
                  }deg) translateZ(60px) rotateY(-${index * deltaAngle}deg)`,
                }}
                key={index}
              >
                <div
                  className={classNames(
                    'item',
                    index === 0 ? 'fadeIn' : 'fadeOut',
                    initBgIndexArray.includes(index) && 'hide',
                  )}
                  data-index={index}
                >
                  <img src={image} alt="app" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </S.Carousel3d>
  );
});

export default Carousel3d;
