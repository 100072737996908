import {
  useState,
  useRef,
  useEffect,
  memo,
  useCallback,
  Suspense,
  useContext,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  addAppointment,
  deleteAppointment,
  resetAppointment,
} from 'redux/actions/appointmentsAction';
import {
  addPendingOrder,
  deletePendingOrder,
} from 'redux/actions/pendingOrdersAction';
import { updateSchedule } from 'redux/actions/scheduleActions';

// style
import * as S from './ScheduleDay.styled';

// components
import TimePanel from './TimePanel/TimePanel';
import NavigationPanel from '../../NavigationPanel/NavigationPanel';
import PendingOrders from 'components/ScheduleCustom/PendingOrders/PendingOrders';
import AddButton from './AddButton/AddButton';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import TimeOff from 'components/DialogLayout/children/TimeOff/TimeOff';
import DataPanel from './DataPanel/DataPanel';
import ScheduleHeader from './ScheduleHeader/ScheduleHeader';
import EmployeeSelection from './EmployeeSelection/EmployeeSelection';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';
import { UserContext } from 'context/UserContext';
import { FunctionsContext } from 'context/FunctionsContext';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { useFunctionList } from 'hooks/useFunctionList';
import { useModifyOrderDetailsHandler } from 'hooks/schedule/useModifyOrderDetailsHandler';
import { useScheduleSize } from 'hooks/schedule/useScheduleSize';
import { useEmployeeOrders } from 'hooks/schedule/useEmployeeOrders';
import useModal from 'hooks/useModal';
import { ORDER_COMPLETION_STATUS, SCHEDULE_TYPE } from 'common/constants';
import { toIsoString } from 'common/helpers/dateOperations';

export const ScheduleDay = ({
  startHour,
  endHour,
  cellDuration,
  defaultColumnWidth,
  dataSource,
  updateSource,
  updateFunctionBarItem,
  searchHeader,
  numberOfDays,
  page,
  personalDays,
  limit,
  showOnlyWorkingDays,
  personal,
  setColumnFetching,
  shouldUpdateSource,
  dataSourceDate,
  dataSourceSector,
  dataSourcePage,
  scrollToHour,
  isLoading,
}) => {
  // Hooks
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // TODO: newOrder - useless. remove it.
  const { newOrder } = useContext(FunctionsContext);
  const { adjustSectorData } = useContext(MainContext);
  const { locale } = useContext(UserContext);
  const dateState = useSelector((state) => state.scheduleReducer.dateState);
  const { employees } = useSelector((state) => state.calendarView);
  const scheduleDayContainerRef = useRef(null);
  const verticalScrollContainerRef = useRef(null);
  const scheduleHeaderRef = useRef(null);
  const timePanelRef = useRef();
  const isFirstLoadScrollAmountCalculated = useRef();
  const currentScheduleScrollTop = useRef();
  const { toggle, isShowing } = useModal();

  const quantityFunc = useFunctionList([
    'show-one-professional',
    'show-two-professionals',
    'show-three-professionals',
  ]);

  // const defaultFilter = [1, 2, 3, 4, 5, 7, 8, 9, 'excuse'];
  const defaultFilter = [
    ORDER_COMPLETION_STATUS.ORDERED,
    ORDER_COMPLETION_STATUS.WILL_ARRIVE,
    ORDER_COMPLETION_STATUS.ARRIVED_AND_WAITING,
    ORDER_COMPLETION_STATUS.NOT_ARRIVED,
    ORDER_COMPLETION_STATUS.CONFIRMED,
  ];
  let scrollPos = 0;
  const scrollContainer = (
    isMobile ? verticalScrollContainerRef : scheduleDayContainerRef
  ).current;

  const employeeId = searchParams.get('employeeId');
  const sectorId = searchParams.get('sectorId');

  const [appointmentsFilter, setAppointmentsFilter] = useState(defaultFilter);
  const [draggingAppointment, setDraggingAppointment] = useState(null);
  const [showPendingOrders, setShowPendingOrders] = useState(!isMobile);
  const [scheduleHeight, setScheduleHeight] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [professionalsQuantity, setProfessionalsQuantity] = useState(1);
  const [selectQuantityFunc, setSelectQuantityFunc] = useState(quantityFunc);
  const [getBackTo, setGetBackTo] = useState(null);
  const [newOrderInfo, setNewOrderInfo] = useState(null);
  const [cache, setCache] = useState([]);
  const [scheduleScrollAmount, setScheduleScrollAmount] = useState();
  const [scheduleScrollEvents, setScheduleScrollEvents] = useState({});
  const [selectedProfessional, setSelectedProfessional] = useState();
  const [cellWidth, setCellWidth] = useState();
  const [toggledEmployee, setToggledEmployee] = useState(false);
  const [days, setDays] = useState([]);
  const [showEndLoader, setShowEndLoader] = useState(false);
  const [isFirstScrollToTime, setIsFirstScrollToTime] = useState(true);
  const [scheduleScrollTargetNode, setScheduleScrollTargetNode] = useState();

  useScheduleSize(
    scheduleDayContainerRef,
    setScheduleHeight,
    setColumnWidth,
    professionalsQuantity,
    showPendingOrders,
  );

  const handleQuantitySelect = useCallback(
    (quantity, title, icon) => {
      setProfessionalsQuantity(quantity);

      setTimeout(() => {
        updateFunctionBarItem(
          'show-number-of-professionals',
          'iconTitle',
          title,
        );
        updateFunctionBarItem('show-number-of-professionals', 'icon', icon);
      }, 250);
    },
    [updateFunctionBarItem],
  );

  useEffect(() => {
    if (personal && !employeeId) return;
    dispatch(
      updateSchedule({
        cellDuration,
        startHour,
        endHour,
        cell: null,
        ...(!location.state?.scrollIntoView && { selectedProfessional: null }),
        ...(personal && {
          selectedProfessional: employeeId ? { id: employeeId } : null,
        }),
      }),
    );
  }, [employeeId]);

  useEffect(() => {
    let routeBack;

    switch (location.state?.numberOfDays) {
      case 1:
      default:
        routeBack = '../../day';
        break;
      case 7:
        routeBack = '../../week';
        break;
      case 30:
        routeBack = '../../month';
    }

    routeBack && setGetBackTo(routeBack);
    setNewOrderInfo(newOrder);
  }, []);

  useEffect(() => {
    setShowPendingOrders(() =>
      !isMobile ? !isMobile : !!location.state?.pendingOrders,
    );
  }, [isMobile]);

  useEffect(() => {
    const container = scheduleDayContainerRef.current;
    if (!container || !dataSource?.length) return;

    container.addEventListener('scroll', loadProfessionals);

    return () => {
      container.removeEventListener('scroll', loadProfessionals);
    };
  }, [dataSource, page.state, showEndLoader]);

  // Function Bar Config
  useEffect(() => {
    updateFunctionBarItem('show-hidden-list', 'action', () =>
      setShowPendingOrders((prev) => !prev),
    );
    updateFunctionBarItem('show-canceled-orders', 'action', () =>
      filterOrdersByStatus(ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE),
    );
  }, []);

  useEffect(() => {
    updateFunctionBarItem('cancel', 'hide', () => !!cache.length);
    updateFunctionBarItem('cancel', 'action', undoChanges);
  }, [cache]);

  useEffect(() => {
    updateFunctionBarItem(
      'show-canceled-orders',
      'isActive',
      appointmentsFilter.includes(ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE), // canceled id
    );
  }, [appointmentsFilter]);

  useEffect(() => {
    updateFunctionBarItem('show-hidden-list', 'isActive', showPendingOrders);
  }, [showPendingOrders]);

  useEffect(() => {
    updateFunctionBarItem(
      'show-number-of-professionals',
      'subItems',
      selectQuantityFunc,
    );
  }, [selectQuantityFunc]);

  useEffect(() => {
    updateFunctionBarItem(
      'show-number-of-professionals',
      'scope',
      showPendingOrders ? 0 : 2,
    );
  }, [showPendingOrders]);

  useEffect(() => {
    setSelectQuantityFunc((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          action: () => handleQuantitySelect(item.value, item.name, item.icon),
        };
      });
    });
  }, [handleQuantitySelect]);

  useEffect(() => {
    setSelectedProfessional(null);
  }, [sectorId]);

  // Functions
  const undoChanges = (modify = true) => {
    if (!cache.length) return;

    const lastElement = cache.at(-1);

    switch (lastElement.type) {
      case 'inScheduler':
        const order = lastElement.new;
        order.durationInMinutes = lastElement.old.durationInMinutes;

        dispatch(resetAppointment(lastElement.old));

        if (modify) {
          const modifyBody = {
            id: lastElement.old.id,
            version: lastElement.new.version,
            startTimeUtc: lastElement.old.startTimeUtc,
            employeeId: lastElement.old.employeeId,
            durationInMinutes: lastElement.old.durationInMinutes,
          };

          modifyOrder.mutate(modifyBody);
        }

        break;
      case 'fromSchedulerToPending':
        dispatch(deletePendingOrder(lastElement.old.id));
        dispatch(addAppointment(lastElement.old));

        if (modify) {
          const modifyBody = {
            id: lastElement.old.id,
            version: lastElement.new.version,
            startTimeUtc: lastElement.old.startTimeUtc,
            employeeId: lastElement.old.employeeId,
            durationInMinutes: lastElement.old.durationInMinutes,
          };

          modifyOrder.mutate(modifyBody);
        }

        break;
      case 'fromPendingToScheduler':
        dispatch(deleteAppointment(lastElement.old.id));
        dispatch(addPendingOrder(lastElement.old));

        if (modify) {
          const modifyBody = {
            id: lastElement.old.id,
            version: lastElement.new.version,
            startTimeUtc: lastElement.old.startTimeUtc,
            employeeId: null,
            durationInMinutes: lastElement.old.durationInMinutes,
          };

          modifyOrder.mutate(modifyBody);
        }

        break;

      default:
        break;
    }

    setCache(cache.slice(0, -1));
  };

  // API
  const employeeOrdersBE = useEmployeeOrders(
    sectorId,
    dateState,
    numberOfDays,
    dataSource,
    personalDays.past,
    page.state,
    showOnlyWorkingDays,
    dataSourceDate,
    dataSourceSector,
  );

  const modifyOrder = useModifyOrderDetailsHandler({
    state: cache,
    setState: setCache,
    undo: undoChanges,
  });

  // Functions

  const getToggleDaysFunctions = () => {
    if (searchHeader.compare) {
      return {
        singleArrowBack: 'prev day from B/E',
        singleArrowForward: 'second day from B/E',
      };
    }

    if (numberOfDays?.visible === 1) {
      return {
        singleArrowBack: 'back',
        singleArrowForward: 'forward',
        doubleArrowsBack: 'weekBack',
        doubleArrowsForward: 'weekForward',
        today: 'today',
      };
    } else if (
      numberOfDays?.visible === 7 ||
      numberOfDays?.visible >= personalDays.total
    ) {
      return {
        singleArrowBack: 'weekBack',
        singleArrowForward: 'weekForward',
        doubleArrowsBack: 'monthBack',
        doubleArrowsForward: 'monthForward',
        today: 'today',
      };
    }
  };

  const draggingScroll = (e) => {
    let mouseDown = false;
    let startX, scrollLeft;

    const queueDiv = e.target.closest('#pending');
    const appointmentDiv = e.target.closest('#collumn');

    if (queueDiv || appointmentDiv) return;

    const containerRef = scheduleDayContainerRef.current;
    mouseDown = true;
    startX = e.pageX - containerRef.offsetLeft;
    scrollLeft = containerRef.scrollLeft;
    document.body.style.cursor = 'grab';

    const dragging = (e) => {
      e.preventDefault();

      if (!mouseDown) return;

      const x = e.pageX - containerRef.offsetLeft;
      const scroll = x - startX;
      containerRef.scrollLeft = scrollLeft - scroll;
    };

    const stopDragging = () => {
      mouseDown = false;
      document.body.style.cursor = 'auto';
      document.body.removeEventListener('mousemove', dragging);
    };

    document.body.addEventListener('mousemove', dragging);
    document.body.addEventListener('mouseup', stopDragging, { once: true });
  };

  const filterOrdersByStatus = (id) => {
    setAppointmentsFilter((prev) => {
      const found = prev.find((element) => element === id);

      return found ? defaultFilter : [id];
    });
  };

  const loadProfessionals = (e) => {
    const x = e.target;
    const left = x.scrollLeft;
    const maxLeft = x.scrollWidth - x.clientWidth;

    const personalView = numberOfDays?.visible >= personalDays.total;

    if (left > scrollPos) {
      scrollPos = left;
      if (dataSource.length >= limit && !personalView) return;

      if (personalView) {
        if (showOnlyWorkingDays) {
          if (!shouldUpdateSource) return;
        }
      }

      if (showEndLoader) return;
      if (left >= maxLeft - 10) {
        setShowEndLoader(true);
        setTimeout(() => {
          const newPage = page.state + 1;
          updateSource(newPage);
          setShowEndLoader(false);

          page.setState(newPage);
        }, 100);
      }
    } else if (left < scrollPos) {
      scrollPos = left;

      let skeletonColumnsAmount = 14;
      let notVisibleRowsAmount = dataSource.filter(
        (item) => item.notInView,
      ).length;

      if (numberOfDays.visible === 7) {
        notVisibleRowsAmount *= 7;
        skeletonColumnsAmount = 7;
      } else if (personal) {
        notVisibleRowsAmount = numberOfDays.notInView;
      }
      const x = notVisibleRowsAmount * (cellWidth || defaultColumnWidth);

      if (page.state === 1) return;
      if (left < x) {
        // let newPage = page.state - 1 > 0 ? page.state - 1 : 0;
        const currentPage =
          Math.ceil(
            e.target.scrollLeft /
              (skeletonColumnsAmount * (cellWidth || defaultColumnWidth)),
          ) || 1;

        updateSource(currentPage);
        page.setState(currentPage);
      }
    }
  };

  const findPageNumber = (elementIndex) => {
    // Check if the index is valid
    if (elementIndex <= 0) {
      return 1;
    }

    // Calculate the page number based on the element index
    const elementsPerPage =
      (numberOfDays.visible === 1 && 7) || (numberOfDays.visible === 7 && 1);
    const firstPageElements =
      (numberOfDays.visible === 1 && 14) || (numberOfDays.visible === 7 && 3);

    if (elementIndex < firstPageElements) {
      // Element is on the first page

      return 1;
    } else {
      // Calculate the page number for subsequent pages
      const remainingElements = elementIndex - firstPageElements;
      const pageNumber =
        Math.ceil((remainingElements + 1) / elementsPerPage) + 1; // Adding 1 to account for the first page
      return pageNumber;
    }
  };

  // Calculate scroll amount for first schedule load
  useEffect(() => {
    if (
      !isLoading &&
      !isFirstLoadScrollAmountCalculated.current &&
      scheduleScrollTargetNode
    ) {
      setTimeout(
        () => {
          const containerRect = scrollContainer?.getBoundingClientRect();
          const scrollTargetRect =
            scheduleScrollTargetNode?.getBoundingClientRect();
          const headerRect =
            scheduleHeaderRef?.current?.getBoundingClientRect();
          const timePanelRect = timePanelRef?.current?.getBoundingClientRect();

          if (
            scrollTargetRect?.top &&
            containerRect?.top &&
            timePanelRect?.width
          ) {
            setScheduleScrollAmount({
              x:
                scrollTargetRect.left -
                containerRect.left -
                timePanelRect.width,
              y:
                scrollTargetRect.top -
                containerRect.top -
                (headerRect?.height || 0) -
                10,
            });
          }
        },
        isMobile ? 500 : 0, // wait for columns expantion animation (mobile)
      );

      isFirstLoadScrollAmountCalculated.current = true;
    }
  }, [isLoading, scheduleScrollTargetNode]);

  // Scroll to target node
  useLayoutEffect(() => {
    if (scheduleScrollAmount) {
      scrollContainer?.scrollTo({
        left: scheduleScrollAmount.x,
      });

      setTimeout(() => {
        scrollContainer?.scrollTo({
          top: scheduleScrollAmount.y,
          behavior: isFirstScrollToTime ? 'smooth' : 'instant',
        });
        setIsFirstScrollToTime(false);
      }, 200);
    }
  }, [scheduleScrollAmount]);

  // Set schedule scroll position to the previus scroll position (solves issue when there is no schedules for the day)
  useEffect(() => {
    const scrollEvent = () => {
      if (dataSource?.length) {
        currentScheduleScrollTop.current = scrollContainer.scrollTop;
      }
    };

    scrollContainer?.addEventListener('scroll', scrollEvent);

    if (currentScheduleScrollTop.current) {
      scrollContainer.scrollTop = currentScheduleScrollTop.current;
    }

    return () => {
      scrollContainer?.removeEventListener('scroll', scrollEvent);
    };
  }, [dataSource]);

  const refetchOrders = () => {
    if (employeeOrdersBE.length) {
      employeeOrdersBE.map((request) => request.refetch());
    } else {
      employeeOrdersBE.refetch();
    }
  };

  const contextObj = {
    dataSource,
    refetchOrders: refetchOrders,
    pendingOrders: {
      state: showPendingOrders,
      setShow: setShowPendingOrders,
    },
    draggingAppointment: {
      state: draggingAppointment,
      setState: setDraggingAppointment,
    },

    screenHeight: scheduleHeight,
    columnWidth: columnWidth,
    cache: { state: cache, setState: setCache, undo: undoChanges },
    compare: searchHeader.compare,
    numberOfDays,
    loader: {
      end: showEndLoader,
    },
    searchParams,
    getBackTo,
    currPage: page.state,
    personalDays: personalDays,
    showOnlyWorkingDays,
    personal,
    setColumnFetching,
    shouldUpdateSource,
    dataSourceDate,
    dataSourceSector,
    dataSourcePage,
    newOrderInfo,
    sectorId,
    appointmentsFilter,
    setScheduleScrollTargetNode,
    scheduleScrollEvents: {
      state: scheduleScrollEvents,
      set: setScheduleScrollEvents,
    },
    selectedProfessional: {
      value: selectedProfessional,
      set: setSelectedProfessional,
    },
    cellWidth: {
      value: cellWidth,
      set: setCellWidth,
    },
    toggledEmployee: {
      value: toggledEmployee,
      set: setToggledEmployee,
    },
    days,
    scheduleHeaderRef,
    type: personal
      ? SCHEDULE_TYPE.PERSONAL
      : numberOfDays.visible === 7
        ? SCHEDULE_TYPE.WEEK
        : SCHEDULE_TYPE.DAY,

    professionalsQuantity: showPendingOrders ? 1 : professionalsQuantity,
    defaultColumnWidth,
    toggleEmployeeSelection: toggle,
    isLoading,
  };

  useEffect(() => {
    adjustSectorData();
  }, [sectorId]);

  useEffect(() => {
    setSearchParams(Object.fromEntries(new URLSearchParams(location.search)));
  }, [location.search]);

  useEffect(() => {
    page.setState(1);
    setNewOrderInfo(newOrder);
    const personalView = numberOfDays?.visible >= personalDays.total;

    if (!scheduleDayContainerRef.current) return;

    if (!personalView) scheduleDayContainerRef.current.scrollLeft = 0;
  }, [dateState, sectorId]);

  useEffect(() => {
    if (!employees?.length) return;

    if (newOrderInfo) {
      const foundIndex = employees.findIndex(
        (emp) => emp.employee.id === newOrderInfo.employeeId,
      );

      if (foundIndex < 0) return;

      page.setState(findPageNumber(foundIndex));
    }
  }, [employees, newOrderInfo]);

  useEffect(() => {
    const single = numberOfDays.visible !== 7;

    setColumnFetching(() => {
      return showOnlyWorkingDays
        ? employeeOrdersBE.some((query) => query.isFetching)
        : single
          ? employeeOrdersBE.isFetching
          : employeeOrdersBE.some((query) => query.isFetching);
    });
  }, [setColumnFetching, showOnlyWorkingDays, employeeOrdersBE]);

  const onScheduleScroll = () => {
    if (!Object.keys(scheduleScrollEvents).length) {
      return;
    }

    for (const scrollEvent of Object.values(scheduleScrollEvents)) {
      scrollEvent();
    }
  };

  const getColumnDay = useCallback(
    (day) => {
      const columnDay = new Date(dateState);

      if (numberOfDays.visible === 1) return columnDay;

      if (numberOfDays.visible === 7) {
        const currentDay = columnDay.getDay() - 1;
        columnDay.setDate(columnDay.getDate() - currentDay + day);

        return columnDay;
      }

      if (numberOfDays.visible >= personalDays.total) {
        if (showOnlyWorkingDays) {
          if (!dataSource) return new Date();

          return new Date(dataSource[0]?.days[day]?.day);
        } else {
          return new Date(
            columnDay.getFullYear(),
            columnDay.getMonth(),
            columnDay.getDate() - personalDays.past + day,
          );
        }
      }
    },
    [
      dateState,
      dataSource,
      showOnlyWorkingDays,
      numberOfDays.visible,
      personalDays.total,
      personalDays.past,
    ],
  );

  useEffect(() => {
    let daysArray = [...Array(numberOfDays.visible).keys()];

    daysArray = daysArray.map((day) => {
      const notInView = numberOfDays.notInView > day;

      const date = getColumnDay(day);

      return { day, notInView, date };
    });

    if (showOnlyWorkingDays && !shouldUpdateSource) {
      const diff = dataSource[0]?.days.length - numberOfDays.visible;

      if (diff < 0) {
        daysArray.splice(diff);
      }
    }

    setDays(daysArray);
  }, [numberOfDays, showOnlyWorkingDays, dataSource, getColumnDay]);

  // Snaps directional scrolling
  useLayoutEffect(() => {
    if (isMobile) {
      let panningStartPosition = null;
      let scrollAccelarationInterval = null;
      let panningTime = null;
      let touchPosition = null;
      let lockedDirection = null;
      let panDirection = null;
      let panningTimeResetTimeout = null;
      let shouldResetPanning = false;

      addEventListener('touchstart', (e) => {
        if (!verticalScrollContainerRef.current) {
          return;
        }

        touchPosition = {
          x: e.touches[0].screenX,
          y: e.touches[0].screenY,
        };
        panningStartPosition = {
          x: verticalScrollContainerRef.current.scrollLeft,
          y: verticalScrollContainerRef.current.scrollTop,
        };
        lockedDirection = null;
        panDirection = {
          x: 0,
          y: 0,
        };
        panningTime = new Date().getTime();
        clearInterval(scrollAccelarationInterval);
      });

      verticalScrollContainerRef.current.addEventListener('touchmove', (e) => {
        e.preventDefault();

        if (shouldResetPanning) {
          panningTime = new Date().getTime();
          panningStartPosition = {
            x: verticalScrollContainerRef.current.scrollLeft,
            y: verticalScrollContainerRef.current.scrollTop,
          };
          shouldResetPanning = false;
        }

        clearTimeout(panningTimeResetTimeout);
        panningTimeResetTimeout = setTimeout(() => {
          shouldResetPanning = true;
        }, 100);

        const touches = {
          x: e.touches[0].screenX,
          y: e.touches[0].screenY,
        };

        const panningDiff = {
          x: touchPosition.x - touches.x,
          y: touchPosition.y - touches.y,
        };

        touchPosition = {
          x: touches.x,
          y: touches.y,
        };

        const directionKey =
          Math.abs(panningDiff.x) > Math.abs(panningDiff.y) ? 'x' : 'y';

        if (!lockedDirection) {
          lockedDirection = {
            x: 0,
            y: 0,
          };

          lockedDirection[directionKey] = 1;
        }

        const newPanDirection = panningDiff[directionKey] > 0 ? 1 : -1;

        if (directionKey === 'x') {
          if (newPanDirection !== panDirection.x) {
            panningStartPosition.x =
              verticalScrollContainerRef.current.scrollLeft;
            panDirection.x = newPanDirection;
          }
        } else {
          if (newPanDirection !== panDirection.y) {
            panningStartPosition.y =
              verticalScrollContainerRef.current.scrollTop;
            panDirection.x = newPanDirection;
          }
          panDirection.y = newPanDirection;
        }

        if (lockedDirection.x) {
          verticalScrollContainerRef.current.scrollLeft += panningDiff.x;
        } else {
          verticalScrollContainerRef.current.scrollTop += panningDiff.y;
        }
      });

      verticalScrollContainerRef.current.addEventListener('touchend', () => {
        if (!verticalScrollContainerRef.current || !lockedDirection) {
          return;
        }

        if (shouldResetPanning) {
          panningTime = 0;
        }

        let scrollAccelaration = 0;
        const overallPanningDiff = {
          x:
            verticalScrollContainerRef.current.scrollLeft -
            panningStartPosition.x,
          y:
            verticalScrollContainerRef.current.scrollTop -
            panningStartPosition.y,
        };

        const directionKey = lockedDirection.x === 1 ? 'x' : 'y';

        if (
          lockedDirection[directionKey] === 1 &&
          Math.abs(overallPanningDiff[directionKey]) < 100
        ) {
          return;
        }

        panningTime = new Date().getTime() - panningTime;

        scrollAccelaration =
          (overallPanningDiff[directionKey] / panningTime) * 10;

        scrollAccelarationInterval = setInterval(() => {
          if (scrollAccelaration > -0.4 && scrollAccelaration < 0.6) {
            clearInterval(scrollAccelarationInterval);
          }

          if (directionKey === 'x') {
            verticalScrollContainerRef.current.scrollLeft += scrollAccelaration;
          } else {
            verticalScrollContainerRef.current.scrollTop += scrollAccelaration;
          }

          scrollAccelaration = scrollAccelaration * 0.99;
        });

        panningStartPosition = null;
      });
    }
  }, [isMobile]);

  return (
    <ScheduleContext.Provider value={contextObj}>
      <Routes>
        <Route
          index
          element={
            <>
              <div className="navigation-container">
                <NavigationPanel
                  dateState={dateState}
                  toggleDaysFunctions={getToggleDaysFunctions()}
                  type={searchHeader.compare && 'compare'}
                  numberOfDays={numberOfDays?.visible}
                  dynamicDays={personalDays}
                />
              </div>

              <Suspense fallback={<></>}>
                <S.ScheduleDay
                  ref={scheduleDayContainerRef}
                  onMouseDown={draggingScroll}
                  id="schedule"
                  height={scheduleHeight}
                  openPendingOrders={showPendingOrders}
                  onScroll={onScheduleScroll}
                  locale={locale}
                >
                  <div className="scheduleDay-wrapper">
                    <div className="vertical">
                      <div
                        className="horizontal"
                        ref={verticalScrollContainerRef}
                      >
                        {(!dataSource || dataSource.length > 0) && (
                          <ScheduleHeader
                            elementRef={scheduleHeaderRef}
                            showPendingOrders={showPendingOrders}
                          />
                        )}
                        <div className="schedule__body">
                          {(!dataSource || dataSource.length > 0) && (
                            <TimePanel
                              scrollToHour={scrollToHour}
                              elementRef={timePanelRef}
                            />
                          )}
                          <DataPanel
                            key={
                              toIsoString(dateState).split('T')[0] +
                              (showOnlyWorkingDays && 'working-days')
                            }
                            days={days}
                            scrollToHour={scrollToHour}
                          />
                          {(!dataSource || dataSource.length > 0) &&
                            !isMobile &&
                            showPendingOrders && (
                              <PendingOrders hideClear={personal} />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile && showPendingOrders && (
                    <div className="pending-orders-mobile">
                      <PendingOrders hideClear={personal} />
                    </div>
                  )}
                </S.ScheduleDay>
              </Suspense>
              <div className="schedule__add-button-container">
                <AddButton />
              </div>
              <EmployeeSelection
                isVisible={isShowing}
                toggleVisibility={toggle}
              />
            </>
          }
        />
        <Route
          path="/no-work-time/*"
          element={
            <ContentLayout hideFab>
              <DialogLayout
                headerText={tr['time-off']}
                exit
                exitFunc={() => {
                  navigate('../');
                }}
                darkTheme
                childProps={{
                  saveFunc: (x) => {
                    // dispatch(addAppointment(x))
                  },
                }}
                Child={TimeOff}
              />
            </ContentLayout>
          }
        />
      </Routes>
    </ScheduleContext.Provider>
  );
};

export default memo(ScheduleDay);
