import * as S from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <S.StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : 30}
    height={height ? height : 30}
    version="1.1"
    viewBox="0 0 30 30"
    color={color}
    {...rest}
  >
    <path
      d="M 10.254105,30 C 9.672735,30 9.132885,29.6886 8.8422,29.1843 L 3.953925,20.7159 H 1.6314 C 0.729681,20.7159 0,19.98615 0,19.0845 V 1.6314 C 0,0.729681 0.729681,0 1.6314,0 H 28.3686 C 29.27025,0 30,0.729681 30,1.6314 v 17.4531 c 0,0.90165 -0.72975,1.6314 -1.6314,1.6314 H 16.5543 l -4.88829,8.4684 C 11.375325,29.6886 10.835475,30 10.254105,30 Z M 3.2628,17.4531 h 1.6314 c 0.58137,0 1.12122,0.3114 1.411905,0.8157 l 3.948,6.837 3.947985,-6.837 c 0.290685,-0.5043 0.83061,-0.8157 1.41186,-0.8157 H 26.7372 V 3.2628 H 3.2628 Z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  </S.StyledSVG>
);
