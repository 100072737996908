import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { usePhoneInput } from 'react-international-phone';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isMailFine from 'is-mail-fine';
import { DateTime } from 'luxon';

// style
import * as S from './EmployeeGeneralInformation.styled';

// components
import { BaseInput } from 'components/Inputs';
import { Avatar } from 'components/Avatar/Avatar';
import { TheButton } from 'components/Buttons/TheButton';
import Overlay from 'components/Overlay/Overlay';
import { AvatarCropper } from 'components/AvatarCropper/AvatarCropper';
import { SectorStats } from './partials/SectorStats/SectorStats';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';
import { DangerousDataSubmitionWarningDialogBox } from './partials/DangerousDataSubmitionWarningDialogBox/DangerousDataSubmitionWarningDialogBox';
import { FormContainer } from 'components/Form/FormContainer';
import { FlashMessage } from 'components/FlashMessage/FlashMessage';
import Loader from 'components/Loader/Loader';
import {
  InputDate,
  FormProvider,
  Input,
  InputPhone,
  Select,
} from 'components/HookForm';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFunctionBar } from 'hooks/useFunctionBar';
import { useEmployeesPost } from 'services/employeeService';
import { useMediaQuery } from 'hooks/useMediaQuery';
import useToggler from 'hooks/useToggler';
import useModal from 'hooks/useModal';
import { useFormStatus } from 'hooks/useFormStatus';

// helpers
import { fillImgPath } from 'common/helpers/imagesFunctions';

export const EmployeeGeneralInformation = () => {
  const { employeeId } = useParams();
  const { locale, employeeIds, logoutUser, user } = useContext(UserContext);
  const picturePickerStatus = useToggler();
  const uploadedPhoto = useRef();
  const shouldRelogRef = useRef();
  const bypassUnsavedChanges = useRef();
  const tr = useTranslate().use().global;
  const isNewEmployee = employeeId === 'new';
  const mutationContext = useEmployeesPost({
    asFormData: true,
    fileFieldName: 'photo',
    searchParams: {
      useVersioning: 1,
    },
  });
  const {
    mutate,
    isSuccess: mutationSuccess,
    isError: mutationError,
    data: mutationResponse,
  } = mutationContext;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const {
    setFunctions,
    setButtons,
    buttonHandler,
    employeeData: data,
    sectorStatsData,
    setIsGoBackEnabled,
    employeeContext,
  } = useOutletContext();
  const functionBarFunctions = useFunctionBar([]);
  const { isShowing, toggle } = useModal(false);
  const {
    country: { dialCode },
  } = usePhoneInput({
    defaultCountry: new Intl.Locale(locale).region.toLowerCase(),
  });
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .max(
        50,
        `${tr['name']} ${tr['can-not-exceed'].toLowerCase()} 50 ${tr['characters'].toLowerCase()}`,
      )
      .test(
        'is-name-or-surname-filled',
        tr['name-or-surname-required'],
        (value, ctx) => value.trim() || ctx.parent.lastName.trim(),
      ),
    lastName: yup
      .string()
      .max(
        50,
        `${tr['lastName']} ${tr['can-not-exceed'].toLowerCase()} 50 ${tr['characters'].toLowerCase()}`,
      )
      .test(
        'is-name-or-surname-filled',
        tr['name-or-surname-required'],
        (value, ctx) => value.trim() || ctx.parent.firstName.trim(),
      ),
    email: yup
      .string()
      .max(
        100,
        `${tr['email']} ${tr['can-not-exceed'].toLowerCase()} 100 ${tr['characters'].toLowerCase()}`,
      )
      .email(tr['invalid-email'])
      .test(
        'is-valid',
        tr['invalid-email'],
        // (message) => `${message.path} is invalid`,
        (value) => (value ? isMailFine(value) : true),
      ),
    isActive: yup.boolean().required(),
    allowLogin: yup.boolean().required(),
  });

  const isEditingSelf =
    employeeIds.includes(employeeId) || data?.employee?.email === user?.email;
  const defaultValues = {
    id: data?.employee?.id,
    firstName: data?.employee?.firstName ?? '',
    lastName: data?.employee?.lastName ?? '',
    birthday: data?.employee?.birthday ?? null,
    phone: {
      prefix: data?.employee?.phonePrefix ?? '+' + dialCode,
      number: data?.employee?.phoneNumber ?? '',
    },
    email: data?.employee?.email ?? '',
    roleId: null,
    allowLogin: isNewEmployee ? true : (data?.employee?.allowLogin ?? null),
    isActive: isNewEmployee ? true : (data?.employee?.isActive ?? null),
    photo: uploadedPhoto.current,
    version: data?.employee?.version ?? null,
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    clearErrors,
    formState: { isValid, isSubmitting, isDirty },
    getValues,
  } = methods;

  const onSubmit = useCallback((data) => {
    const postData = structuredClone(data);

    if (data.phone.prefix && data.phone.number) {
      postData.phonePrefix = data.phone.prefix;
      postData.phoneNumber = data.phone.number;
    }

    delete postData.phone;
    delete postData.name;

    // Not implemented in BE yet
    postData.roleId = null;
    mutate(postData);
  }, []);

  const { isLoading, isFormDisabled, handleOnSave } = useFormStatus({
    resourceContext: employeeContext,
    mutationContext: mutationContext,
    setButtons: setButtons,
    methods: methods,
    hasInitialResourse: !isNewEmployee,
    setIsGoBackEnabled: setIsGoBackEnabled,
    bypassUnsavedChangesRef: bypassUnsavedChanges,
    resetForm: () => reset(defaultValues),
    submit: handleSubmit(onSubmit),
    confirmationModal: () => {
      if (isEditingSelf) {
        const allowLoginValue = getValues('allowLogin');
        const isActiveValue = getValues('isActive');
        const isEmailDanger =
          getFieldState('email').isDirty && defaultValues.email !== '';
        const isAllowLiginDanger =
          getFieldState('allowLogin').isDirty &&
          (allowLoginValue === false || allowLoginValue === 0);
        const isActiveLiginDanger =
          getFieldState('isActive').isDirty &&
          (isActiveValue === false || isActiveValue === 0);

        if (isEmailDanger || isAllowLiginDanger || isActiveLiginDanger) {
          toggle();

          return false;
        }
      }

      return true;
    },
  });

  const photo = watch('photo');
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  useEffect(() => {
    clearErrors('lastName');
  }, [firstName]);

  useEffect(() => {
    clearErrors('firstName');
  }, [lastName]);

  useEffect(() => {
    if (data?.employee) {
      reset(defaultValues);
      uploadedPhoto.current = fillImgPath(data.employee?.photoUrl);
    }
  }, [data]);

  useEffect(() => {
    setFunctions(functionBarFunctions);
  }, []);

  useEffect(() => {
    bypassUnsavedChanges.current = false;
  }, [employeeId]);

  useEffect(() => {
    if (mutationSuccess) {
      bypassUnsavedChanges.current = true;
      const responseUserId = mutationResponse?.data?.employee?.id;
      let successMessage;

      if (isNewEmployee && responseUserId) {
        navigate(
          '/functions/tables/employees/' +
            responseUserId +
            '/general-information',
          {
            replace: true,
          },
        );
        successMessage = tr['employee-created'];
      } else {
        if (shouldRelogRef.current) {
          logoutUser();
          navigate('/');
        } else {
          successMessage = tr['employee-updated'];
        }
      }

      if (mutationResponse.status === 200) {
        toast.success(successMessage);
      }

      if (employeeId !== 'new') {
        employeeContext.refetch();
      }
    }
  }, [mutationSuccess, mutationError]);

  const cardCreationDate = data?.employee?.createTimeUtc
    ? DateTime.fromISO(data.employee.createTimeUtc, {
        zone: 'utc',
        locale,
      }).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    : '';

  return (
    <S.EmployeeGeneralInformation>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isEditingSelf && isMobile && (
            <FlashMessage>{tr['you-are-on-a-personal-card']}</FlashMessage>
          )}
          <FormProvider methods={methods}>
            <S.AvatarPicker>
              <div className="avatar-picker__image">
                <Avatar imagePath={uploadedPhoto.current} raised="md" />
              </div>
              {!isFormDisabled && (
                <div className="avatar-picker__button">
                  <TheButton
                    secondary
                    raised
                    action={() => picturePickerStatus.on()}
                    title={photo ? tr['change-photo'] : tr['upload-photo']}
                    height="25px"
                  />
                </div>
              )}
              <Overlay
                isVisible={picturePickerStatus.active}
                hide={() => picturePickerStatus.off()}
              >
                {picturePickerStatus.active && (
                  <AvatarCropper
                    src={uploadedPhoto.current}
                    close={() => picturePickerStatus.off()}
                    updatePicture={(value) => {
                      uploadedPhoto.current = value.value;
                      setValue('photo', value.label, { shouldDirty: true });
                    }}
                  />
                )}
              </Overlay>
            </S.AvatarPicker>
            <div>
              {isEditingSelf && !isMobile && (
                <FlashMessage>{tr['you-are-on-a-personal-card']}</FlashMessage>
              )}
              <FormContainer disabled={isFormDisabled}>
                <div className="form">
                  <div className="row">
                    <div className="input-container">
                      <Input
                        name="firstName"
                        label={tr['firstName']}
                        disabled={isFormDisabled}
                      />
                    </div>
                    <div className="input-container">
                      <Input
                        name="lastName"
                        label={tr['lastName']}
                        disabled={isFormDisabled}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-container">
                      <InputDate
                        name="birthday"
                        label={tr['dob']}
                        disabled={isFormDisabled}
                      />
                    </div>
                    <div className="input-container">
                      <InputPhone
                        name="phone"
                        prefixName="phonePrefix"
                        label={tr['phone-number']}
                        disabled={isFormDisabled}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-container">
                      <BaseInput
                        id="createTimeUtc"
                        label={tr['registerDate'] + ' (UTC)'}
                        value={cardCreationDate}
                        disabled={true}
                      />
                    </div>
                    <div className="input-container">
                      <Input
                        name="email"
                        label={tr['email']}
                        disabled={isFormDisabled}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-container">
                      <Select
                        name="roleId"
                        label={tr['role-for-common-permissions']}
                        disabled={isFormDisabled}
                        options={[
                          { label: 'Sistemos administratorius', value: 0 },
                          { label: 'Administratorė', value: 0 },
                          { label: 'Specialistas', value: 1 },
                        ]}
                      />
                    </div>
                    <div className="input-container">
                      <Select
                        name="allowLogin"
                        label={tr['allow-login-to-project'] + '*'}
                        disabled={isFormDisabled}
                        options={[
                          { label: tr['yes'], value: true },
                          { label: tr['no'], value: false },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-container half-width">
                      <Select
                        name="isActive"
                        label={tr['activity'] + '*'}
                        disabled={isFormDisabled}
                        options={[
                          { label: tr['active'], value: true },
                          { label: tr['inactive'], value: false },
                        ]}
                      />
                    </div>
                    {/* <div className="input-container"></div> */}
                  </div>
                </div>
              </FormContainer>
            </div>
          </FormProvider>
          <SectorStats data={sectorStatsData} />
          <DangerousDataSubmitionWarningDialogBox
            isVisible={isShowing}
            toggle={toggle}
            onConfirm={() => {
              shouldRelogRef.current = true;
              handleOnSave();
            }}
          />
          <UnsavedChangesDialogBox
            condition={isDirty}
            checkIfBypass={() => bypassUnsavedChanges.current}
          />
        </>
      )}
    </S.EmployeeGeneralInformation>
  );
};

export default EmployeeGeneralInformation;
