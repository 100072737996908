import * as S from '../SVG.styled';

export default ({ color, width, height, ...rest }) => {
  const content = (
    <path
      d="M 27.236625,0 H 14.19648 C 12.67542,0 11.439015,1.2364095 11.439015,2.757465 V 6.61494 H 1.63668 c -0.901362,0 -1.630755,0.729405 -1.630755,1.630755 V 19.2903 c 0,0.9015 0.729393,1.6308 1.630755,1.6308 h 0.94584 v 3.90495 c 0,0.6582 0.397305,1.25415 1.00809,1.5063 0.20163,0.08295 0.412155,0.1245 0.622665,0.1245 0.424005,0 0.842055,-0.16605 1.15338,-0.47745 L 10.422,20.9211 h 1.017015 v 6.32145 c 0,1.521 1.236405,2.75745 2.757465,2.75745 h 13.040145 c 1.521,0 2.75745,-1.23645 2.75745,-2.75745 V 2.757465 C 29.994075,1.2364095 28.757625,0 27.236625,0 Z M 5.84403,20.88855 V 19.2903 c 0,-0.90135 -0.72939,-1.63065 -1.630755,-1.63065 H 3.267435 V 9.876465 h 16.80279 V 17.65965 H 9.74598 c -0.415095,0 -0.812415,0.16305 -1.114845,0.44475 h -0.00297 l -0.03558,0.03255 z m 20.888445,5.85 H 14.700525 V 20.9211 h 7.00035 c 0.90135,0 1.6308,-0.7293 1.6308,-1.6308 V 8.245695 c 0,-0.90135 -0.72945,-1.630755 -1.6308,-1.630755 h -7.00035 V 3.26151 h 12.03195 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
