import * as S from '../SVG.styled';

export default ({ color, width, height, bold, thin, regular = true }) => {
  const content = bold ? (
    <path d="m29.966 1.4209c-0.02065-0.10597-0.05163-0.20808-0.09064-0.30632-0.0029-0.00731-0.0052-0.014771-8e-3 -0.022049-0.04159-0.10084-0.09264-0.19658-0.15116-0.28702-0.0037-0.005863-0.0069-0.011922-0.0109-0.017728-0.12996-0.19535-0.29747-0.36313-0.49309-0.49313-6e-3 -0.004033-0.01233-0.007278-0.01836-0.011223-0.09006-0.058478-0.18558-0.10932-0.28626-0.15073-0.0075-0.003084-0.0152-0.005307-0.02266-0.008265-0.09809-0.038829-0.19992-0.069898-0.30576-0.090693-6e-3 -0.0011308-0.01176-0.0015596-0.0175-0.0026346-0.10526-0.019594-0.21311-0.031084-0.32411-0.031084h-11.705c-0.97321 0-1.7623 0.78907-1.7623 1.7623 0 0.97321 0.78906 1.7623 1.7623 1.7623h7.451l-23.467 23.467c-0.68839 0.6881-0.68839 1.8039 0 2.492 0.34391 0.3442 0.79509 0.51629 1.246 0.51629s0.90208-0.1721 1.246-0.51629l23.467-23.467v7.451c0 0.97321 0.78906 1.7623 1.7623 1.7623 0.97321 0 1.7623-0.78906 1.7623-1.7623v-11.705c0-0.11091-0.01148-0.21903-0.03098-0.32428-0.0012-0.0057-0.0018-0.01142-0.0029-0.017121z" />
  ) : thin ? (
    <g transform="matrix(0.31867222,0,0,0.31867222,-5.8212847e-7,3.002934e-6)">
      <path
        d="m 2,94.14061 c 0.51172,0 1.02344,-0.19531 1.41406,-0.58594 L 29.81543,67.1533 90.14062,6.82903 v 35.0235 c 0,1.10449 0.89551,2 2,2 1.10449,0 2,-0.89551 2,-2 V 2.00096 c 0,-0.13129 -0.01367,-0.26233 -0.03906,-0.39154 C 94.08886,1.54942 94.0664,1.49394 94.0498,1.43608 94.03027,1.36912 94.01464,1.30144 93.98828,1.23643 93.95898,1.1677 93.91992,1.10533 93.88476,1.04069 93.85742,0.99125 93.83593,0.93974 93.80371,0.89225 93.65723,0.67271 93.46875,0.48405 93.24902,0.33744 93.20214,0.3057 93.15039,0.2841 93.10058,0.25687 93.03613,0.22116 92.97363,0.18222 92.90527,0.15378 92.83984,0.12692 92.77246,0.11154 92.70507,0.09183 92.64745,0.07468 92.59179,0.05234 92.53222,0.04044 92.40331,0.01468 92.27148,9.5e-4 92.14062,9.5e-4 L 52.28808,-1e-5 c -1.10449,0 -2,0.89549 -2,2 0,1.10451 0.89551,2 2,2 l 35.02441,8.4e-4 -22.98535,22.98486 c -9.8e-4,9.8e-4 -0.0019,0.0016 -0.0029,0.0026 L 0.58594,90.72655 c -0.78125,0.78125 -0.78125,2.04688 0,2.82812 C 0.97656,93.94529 1.48828,94.14061 2,94.14061 Z"
        id="path6"
      />
    </g>
  ) : (
    <g transform="matrix(0.30568384,0,0,0.30568384,-1.555e-6,-2.3192096e-6)">
      <path
        d="M 98.06152,3.21655 C 98.03808,3.09863 97.99414,2.98962 97.96093,2.87567 97.92089,2.73981 97.88964,2.60217 97.83495,2.47027 97.77831,2.33465 97.70116,2.2116 97.63183,2.08404 97.57617,1.98339 97.53222,1.87872 97.46777,1.78216 97.1748,1.3435 96.79785,0.96655 96.35937,0.67346 96.25976,0.60711 96.15234,0.56152 96.04882,0.50494 95.92382,0.43652 95.8037,0.36108 95.67089,0.30609 95.5371,0.25085 95.39843,0.21881 95.26073,0.17865 95.14843,0.14569 95.041,0.10217 94.92479,0.07904 94.666,0.02759 94.40331,0 94.14061,0 H 54.28807 c -2.20898,0 -4,1.79102 -4,4 0,2.20898 1.79102,4 4,4 H 84.48436 L 64.916,27.56885 c -0.0019,0.0016 -0.0039,0.0027 -0.0059,0.0044 L 1.17188,91.3125 c -1.5625,1.56152 -1.5625,4.09473 0,5.65625 C 1.95313,97.75 2.97657,98.14063 4,98.14063 c 1.02343,0 2.04785,-0.39062 2.82812,-1.17188 L 33.22753,70.56921 c 0,-6.7e-4 9.8e-4,-0.0012 0.002,-0.0018 L 90.14062,13.65625 v 30.19629 c 0,2.20898 1.79102,4 4,4 2.20898,0 4,-1.79102 4,-4 V 4 c 0,-0.26263 -0.02734,-0.5249 -0.0791,-0.78345 z"
        id="path6"
      />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
