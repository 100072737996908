import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const GeneralSystemSettings = styled.div``;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  border-radius: 10px;
  background-color: ${colors.bg_grey_1};
  padding: 30px;
  width: 560px;

  @media screen and (max-width: 960px) {
    margin: 20px 10px 80px;
    padding: 10px;
    width: unset;
    border-radius: 5px;
  }

  :not(:last-child) {
    margin-bottom: 20px;
  }

  > div {
    width: 100%;
    margin-bottom: 20px;

    @media screen and (max-width: 960px) {
      margin-bottom: 10px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  > button {
    margin-left: auto;
    margin-right: 10px;
  }

  :nth-child(1) > div {
    width: calc(50% - 10px);
    /* margin-bottom: 20px; */

    @media screen and (max-width: 960px) {
      /* margin-bottom: 10px; */
      width: 100%;

      :nth-child(2) {
        order: 3;
      }
    }

    :nth-child(3),
    :nth-child(4) {
      margin-bottom: 0;

      @media screen and (max-width: 960px) {
        margin-bottom: 10px;
      }
    }
  }

  :nth-child(2) > div:first-child {
    width: calc(50% - 10px);
  }

  div.group:not(:last-child) {
    margin-bottom: 10px;
  }
`;
