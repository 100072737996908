import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 26.75545,0 H 10.299056 3.2445405 c -1.684155,0 -3.056541,1.3723875 -3.056541,3.05655 v 13.5894 c 0,0.67545 0.547122,1.22265 1.2226185,1.22265 0.6755025,0 1.2226125,-0.5472 1.2226125,-1.22265 V 3.05655 c 0,-0.336225 0.2751,-0.61131 0.61131,-0.61131 H 10.299056 18.4783 v 13.81251 c 0,0.67545 0.5472,1.22265 1.22265,1.22265 0.67545,0 1.22265,-0.5472 1.22265,-1.22265 v -2.3841 h 6.44325 v 7.9959 c 0,0.3363 -0.2751,0.6114 -0.6114,0.6114 H 23.2129 c -0.675449,0 -1.222499,0.54705 -1.222499,1.2225 0,0.6756 0.54705,1.22265 1.222499,1.22265 h 3.54255 c 1.684201,0 3.05655,-1.37235 3.05655,-3.05655 V 3.05961 c 0,-1.6841625 -1.372349,-3.05655046 -3.05655,-3.05655046 z M 20.9236,11.425365 V 2.44524 h 5.83185 c 0.3363,0 0.6114,0.275085 0.6114,0.61131 v 8.368815 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 10.555856,17.9694 c 3.585315,0 6.504344,-3.30717 6.504344,-7.36932 0,-4.06215 -2.91903,-7.372395 -6.504344,-7.372395 -3.5853305,0 -6.5043305,3.307185 -6.5043305,7.372395 0,4.065195 2.919,7.36932 6.5043305,7.36932 z m 0,-12.296475 c 2.237385,0 4.05909,2.20989 4.05909,4.927155 0,2.717265 -1.821705,4.92402 -4.05909,4.92402 -2.2374005,0 -4.0590905,-2.209815 -4.0590905,-4.92402 0,-2.71422 1.82169,-4.927155 4.0590905,-4.927155 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15.981101,16.96995 -0.2628,-0.08865 c -0.3912,-0.1314 -0.82221,-0.06105 -1.14621,0.1956 -1.185931,0.92925 -2.576655,1.41825 -4.016295,1.41825 -1.4396255,0 -2.8273055,-0.492 -4.0162955,-1.41825 -0.324,-0.25365 -0.758025,-0.327 -1.14621,-0.1956 l -0.26592,0.0918 c -2.955675,1.01775 -4.939371,3.7992 -4.939371,6.92295 v 4.8813 C 0.1879995,29.45295 0.7351215,30 1.410618,30 H 19.70095 c 0.675451,0 1.22265,-0.54705 1.22265,-1.22265 v -4.8813 c 0,-3.12375 -1.98675,-5.9052 -4.945499,-6.9261 z M 18.4783,27.5517 H 2.6332305 v -3.65865 c 0,-1.9623 1.179825,-3.72585 2.961795,-4.48395 1.494645,1.0026 3.194085,1.53135 4.9607695,1.53135 1.766685,0 3.466126,-0.52575 4.960756,-1.53135 1.784999,0.7611 2.964899,2.52165 2.964899,4.48395 v 3.65865 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 0.833316,17.355 c 0.3505425,0 0.6373485,-0.2868 0.6373485,-0.63735 V 2.5494 c 0,-0.70428 0.5704305,-1.274703 1.2746955,-1.274703 h 7.35501 9.165075 V 16.31295 c 0,0.35055 0.2868,0.63735 0.63735,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 v -3.123015 h 7.9923 v 8.973915 c 0,0.70425 -0.57045,1.2747 -1.2747,1.2747 h -3.69345 c -0.35055,0 -0.63735,0.2868 -0.63735,0.63735 0,0.35055 0.2868,0.63735 0.63735,0.63735 h 3.69345 c 1.40535,0 2.5494,-1.14405 2.5494,-2.5494 V 2.5494 C 29.807145,1.144041 28.663095,0 27.257745,0 H 10.10037 2.74536 C 1.3400085,0 0.1959675,1.144041 0.1959675,2.5494 v 14.16825 c 0,0.35055 0.2868075,0.63735 0.6373485,0.63735 z M 28.532445,2.5494 v 9.36264 h -7.9923 V 1.274697 h 6.7176 c 0.70425,0 1.2747,0.570423 1.2747,1.274703 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 4.224105,10.414275 c 0,3.88464 2.756535,7.045875 6.144045,7.045875 3.38751,0 6.144045,-3.161235 6.144045,-7.045875 0,-3.88464 -2.756535,-7.04907 -6.144045,-7.04907 -3.38751,0 -6.144045,3.16125 -6.144045,7.04907 z m 11.01339,0 c 0,3.183555 -2.18292,5.771175 -4.869345,5.771175 -2.686425,0 -4.869345,-2.5908 -4.869345,-5.771175 0,-3.18036 2.18292,-5.77437 4.869345,-5.77437 2.686425,0 4.869345,2.590815 4.869345,5.77437 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 0.833391,30 h 19.069404 c 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 v -5.0892 c 0,-2.98605 -1.896,-5.64375 -4.72275,-6.61575 l -0.27405,-0.0924 c -0.20385,-0.07005 -0.4269,-0.0318 -0.599055,0.102 -1.351185,1.0548 -2.93499,1.61565 -4.57935,1.61565 -1.64436,0 -3.228165,-0.55755 -4.57935,-1.61565 -0.1689,-0.1338 -0.39516,-0.17205 -0.599115,-0.102 l -0.27405,0.0924 c -2.823465,0.972 -4.71957,3.6297 -4.71957,6.61575 v 5.0892 C 0.192855,29.7132 0.4796625,30 0.8302035,30 Z m 0.6373485,-5.72655 c 0,-2.42505 1.5296355,-4.5858 3.8113455,-5.3919 1.513695,1.0962 3.266415,1.6761 5.086035,1.6761 1.819635,0 3.56916,-0.57675 5.085975,-1.6761 2.2818,0.8094 3.81135,2.97 3.81135,5.3919 V 28.7253 H 1.4707395 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
