import { areObjectsIdentical, isObject } from './objectFunctions';

export const removeDuplicates = (array, property) => {
  const uniqueMap = new Map();
  array.forEach((obj) => {
    uniqueMap.set(obj[property], obj);
  });
  return Array.from(uniqueMap.values());
};

export const areArraysIdentical = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (isObject(arr1[i]) || isObject(arr2[i])) {
      if (!areObjectsIdentical(arr1[i], arr2[i])) {
        return false;
      }
    } else if (Array.isArray(arr1[i]) || Array.isArray(arr2[i])) {
      if (!areArraysIdentical(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
