import { useEffect } from 'react';

export const useTouch = ({
  touchFrameRef,
  swipeSensitivityGap = 50,
  onSwipe,
}) => {
  useEffect(() => {
    let touchStart = {};
    let touchEnd = {};

    touchFrameRef.current.addEventListener(
      'touchstart',
      (event) => {
        touchStart = {
          x: event.changedTouches[0].screenX,
          y: event.changedTouches[0].screenY,
        };
      },
      false,
    );

    touchFrameRef.current.addEventListener(
      'touchend',
      (event) => {
        touchEnd = {
          x: event.changedTouches[0].screenX,
          y: event.changedTouches[0].screenY,
        };

        handleGesture(touchStart, touchEnd);
      },
      false,
    );
  }, []);

  const handleGesture = (tStart, tEnd) => {
    if (tEnd.x + swipeSensitivityGap < tStart.x) {
      onSwipe('left');
    }

    if (tEnd.x - swipeSensitivityGap > tStart.x) {
      onSwipe('right');
    }
  };

  return { onSwipe };
};
