import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const SimpleHeader = styled.div`
  height: 70px;
  background-color: ${colors.bg_Primary};

  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  box-shadow: ${shadow.sm};

  .logo__container {
    height: 40px;
    width: 162px;

    display: flex;
    align-items: center;

    svg {
      display: block;
      height: 2rem;
      margin-top: 0.5rem;
    }
  }

  .nav__container {
    color: white;
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  .nav__back-logo {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }

  .nav__back-text {
    font-size: 18px;
    font-family: ${fontFamilies.family_Secondary};
  }

  @media screen and (max-width: 960px) {
    height: 54px;
    padding: 0 20px;

    .logo__container {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);

      height: 22px;
      width: 118px;
    }

    .nav__back-logo {
      width: 16px;
      height: 13px;
      margin-right: 10px;

      display: flex;
      align-items: center;
    }

    .nav__back-text {
      font-size: 16px;
    }
  }
`;
