import { Loader } from 'components/Loader/Loader';

const Orders = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Orders;
