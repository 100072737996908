import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const StyledServiceCard = styled.div`
  width: 100%;
  color: ${colors.bg_Primary};
  font-family: ${fontFamilies.family_Secondary};
  padding: 5px 0;

  .serviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .deleteAndPath {
    display: flex;
    align-items: center;
  }

  .deleteBtn {
    min-width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid ${colors.bg_Primary};
    text-align: center;
  }

  .productPath {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;

    font-size: 14px;
    span {
      font-weight: 600;
    }

    .comp_icon {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }

    .comp_arrow {
      display: flex;
      align-items: center;
      margin-left: 10px;

      transform: rotate(
        ${({ compositionIsOpened }) => (compositionIsOpened ? -90 : 90)}deg
      );

      transition: transform 0.2s ease-in-out;
    }
  }

  .comp_container {
    display: grid;
    grid-template-rows: ${({ compositionIsOpened }) =>
      compositionIsOpened ? 1 : 0}fr;
    transition: grid-template-rows 0.2s ease-out;
  }

  .inner {
    overflow: hidden;
  }

  .openDetailsBtn {
    display: flex;
    transform: rotate(90deg);
    height: 13.676px;

    svg {
      height: 7.806px;
      width: 13.676px;
    }
  }

  .serviceInfoForm {
    padding-top: 10px;

    > div:first-child {
      margin-top: 0;
    }
  }

  .titleAndValueWrapper {
    width: 100%;
    display: flex;

    > div:nth-child(2) {
      margin-left: 15px;
    }
  }

  .danger {
    color: ${colors.warningText};
  }
`;
