/*
  available props:
    - hide:       {boolean}:         hides respective column in the table
    - hideSort:   {boolean}:         prevents field from sort
    - hideFilter: {boolean}:         prevents field from filter
    - count:      {boolean}:         accounts field into table TotalsRow
    - display:    {function}:        function providing label (or other display form) dependent on value
    - options:    {function, array}: function providing (or) the array of unique value objects - [{value, label}, ...]
    - default:    {any}:             default value from the array of options
*/

export const employeeHeaders = [
  {
    id: 'registerDate',
    level: 1,
    text: 'Registracijos data',
    type: 'calendar',
    inputType: 'date',
    justify: 'justify',
    filterType: 'range',
    // default: { from: getCurrentMonth('start'), to: getCurrentMonth('end') },
  },
  {
    id: 'avatar',
    level: 1,
    text: 'Foto',
    type: 'avatar',
    // inputType: 'text',
    justify: 'center',
    hideFilter: true,
    hideSort: true,
  },
  {
    id: 'firstName',
    level: 1,
    text: 'Vardas',
    type: 'search',
    inputType: 'text',
    minWidth: 75,
    width: 100,
    count: true,
  },
  {
    id: 'lastName',
    level: 1,
    text: 'Pavardė',
    type: 'search',
    inputType: 'text',
    minWidth: 125,
    width: 150,
    count: true,
  },
  {
    id: 'dob',
    level: 1,
    text: 'Gimimo data',
    type: 'calendar',
    inputType: 'date',
    filterType: 'range',
  },
  {
    id: 'phone1',
    level: 1,
    text: 'Mobilus tel. nr.',
    type: 'search',
    inputType: 'tel',
    minWidth: 150,
    width: 150,
  },
  {
    id: 'phone2',
    level: 1,
    text: 'Telefono nr.',
    type: 'search',
    inputType: 'tel',
    minWidth: 150,
    width: 150,
  },
  {
    id: 'email',
    level: 1,
    text: 'El. paštas',
    type: 'search',
    inputType: 'text',
    count: true,
  },
  {
    id: 'language',
    level: 1,
    text: 'Kalba',
    type: 'switch',
    minWidth: 85,
    options: [
      { value: 'en', label: 'English' },
      { value: 'lt', label: 'Lietuvių' },
      { value: 'ru', label: 'Русский' },
    ],
  },
  {
    id: 'branches',
    level: 1,
    text: 'Filialas',
    type: 'array',
  },
  {
    id: 'profession',
    level: 1,
    text: 'Profesija',
    type: 'select',
    count: true,
  },
  {
    id: 'visibilitySelf',
    level: 1,
    text: 'Rodyti kalendoriuje',
    type: 'switch',
    inputType: 'text',
    minWidth: 105,
    width: 105,
    options: [
      { value: true, label: 'Rodyti' },
      { value: false, label: 'Slėpti' },
    ],
  },
  {
    id: 'visibilityOthers',
    level: 1,
    text: 'Matyti kalendoriuje',
    type: 'switch',
    inputType: 'text',
    minWidth: 105,
    width: 105,
    options: [
      { value: true, label: 'Visus' },
      { value: false, label: 'Tik save' },
    ],
  },
  {
    id: 'priority',
    level: 1,
    text: 'Pozicija',
    type: 'function',
    minWidth: 85,
    hideSort: true,
    hideFilter: true,
  },
  {
    id: 'serveId',
    level: 1,
    text: 'ID Serve Online sistemoje',
    type: 'search',
    minWidth: 85,
  },
  {
    id: 'inActive',
    level: 1,
    text: 'Aktyvumas',
    type: 'switch',
    inputType: 'text',
    minWidth: 105,
    width: 105,
    options: [
      { value: false, label: 'Aktyvus' },
      { value: true, label: 'Neaktyvus' },
    ],
    default: false,
  },
];
