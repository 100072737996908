import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { colors } from 'common/colors/colors';
import { SetListItem } from '../SetList/SetListItem/SetListItem.styled';

export const ServiceList = styled.section`
  padding: 40px;
  max-width: 540px;

  flex-grow: 1;

  box-shadow: ${shadow.lg};

  display: flex;
  flex-direction: column;

  .list__header {
    padding-bottom: 40px;

    h2 {
      margin: 0;
      padding: 0;
      padding-bottom: 40px;
      font-size: 30px;
      font-weight: 600;
    }
  }

  .list__body {
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;

        :last-child {
          margin-bottom: 0;
        }
      }
    }

    &-empty {
      border: 1px solid red;

      text-align: center;

      font-size: 1rem;

      padding: 10px;

      border: 1px solid ${colors.bg_grey_2};
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 20px;
    width: 100%;
    box-shadow: none;

    .list__header {
      display: none;
    }
  }
`;

export const ServiceModal = styled.div`
  margin: 10px 0;
  width: calc(100vw - 100px);

  ${SetListItem} {
    min-height: 60px;
    height: unset;

    align-items: stretch;

    .setListItem__info-container {
      padding: 10px;
    }

    .setListItem__action-container {
      width: unset !important;

      padding: 0;

      .setListItem__amount {
        margin-right: 0;

        height: 100%;
      }
    }
  }
`;
