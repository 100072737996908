import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const GeneralInfo = styled.div`
  .header-container {
    display: flex;
    /* flex-wrap: nowrap; */
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 5px;

    button {
      height: 40px;
      min-width: 110px;
      width: unset;
    }
  }

  .button-container {
    align-items: start;
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 20px;
    justify-content: right;
    margin-right: 5px;
  }

  .modified-by-container {
    display: flex;
    margin-top: 20px;

    .avatar-container {
      height: 40px;
      margin-right: 10px;
      width: 40px;
    }

    .additional-info-container {
      color: ${colors.bg_grey_3};
      display: flex;
      flex-direction: column;
      font-family: ${fontFamilies.family_Secondary};
      font-size: 14px;
      justify-content: center;
    }
  }

  @media screen and (max-width: 960px) {
    .modified-by-container {
      .avatar-container {
        height: 30px;
        margin-right: 20px;
        width: 30px;
      }

      .additional-info-container {
        font-size: 12px;
      }
    }
  }

  .edit-title-container {
    margin-top: 40px;

    .select__control {
      border: 1px solid;
      border-inline: none;
      border-top: none;
      border-radius: 0;
    }

    label,
    .select__input-container {
      margin-left: 0;
    }

    .select__value-container {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 960px) {
    .edit-title-container {
      margin-top: 30px;
    }
  }
`;
