import { useNavigate } from 'react-router';

// style
import * as S from './EmployeeCard.styled';

// icons
import { Arrow, TrashCan } from 'icons/dynamic';

// components
import { Avatar } from 'components/Avatar/Avatar';
import { RecordCell } from 'components/Tables/Table/partials/TableRecord/partials/RecordCell/RecordCell';

// hooks and helpers
import { useTable } from 'hooks/useTable';
import { getFullName } from 'common/helpers/stringOperations';
import { fillImgPath } from 'common/helpers/imagesFunctions';

const exludedColumns = ['photoUrl'];

export const EmployeeCard = ({
  data,
  selectOne,
  columns,
  inactive,
  onRecordDelete,
}) => {
  const navigate = useNavigate();
  const { getColumnVisibility } = useTable();
  const proffessionColumn = columns.find(
    (column) => column.id === 'professionId',
  );

  return (
    <S.EmployeeCard inactive={inactive} selected={data.isSelected}>
      {/* inActive={data.inActive} */}
      <S.Header inactive={inactive}>
        <TrashCan
          bold
          onClick={() => onRecordDelete(data)}
          className="delete-button"
        />

        {/* <span className="mark" onClick={() => selectOne(data.id)}>
           {data.isSelected && <Check inCircle black width={16} height={16} />}
        </span> */}

        <span className="avatar" onClick={() => navigate(data.id)}>
          <Avatar imagePath={fillImgPath(data.photoUrl)} raised="md" />
        </span>
        <span className="text-wrapper" onClick={() => navigate(data.id)}>
          <span className="full-name">
            {getFullName(data.firstName, data.lastName)}
          </span>
          <span className="profession">
            {proffessionColumn?.options?.[data?.proffesionId]}
          </span>
        </span>

        <span className="details" onClick={() => navigate(data?.id)}>
          <Arrow black height="16" width="16" />
        </span>
      </S.Header>

      <S.Main>
        <tbody>
          {columns?.map(
            (column) =>
              !column.mobileHidden &&
              !exludedColumns.includes(column.id) &&
              getColumnVisibility(column) && (
                <tr key={column.id + '-' + data.id}>
                  <td>{column?.label}:</td>
                  <RecordCell data={data} column={column} dashIfNoContent />
                </tr>
              ),
          )}
        </tbody>
      </S.Main>
    </S.EmployeeCard>
  );
};
