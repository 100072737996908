import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, inCircle, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = inCircle ? (
        <path
          d="M 15,0 C 6.731205,0 0,6.728145 0,15 0,23.2719 6.731205,30 15,30 23.26875,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 8.17095,10.992465 -9.74217,9.742185 c -0.23844,0.2415 -0.550245,0.36075 -0.862035,0.36075 -0.311805,0 -0.626655,-0.11925 -0.865095,-0.36075 l -4.87263,-4.8696 c -0.476865,-0.47685 -0.476865,-1.253265 0,-1.730145 0.479925,-0.476865 1.25331,-0.476865 1.730175,0 l 4.00755,4.007595 8.877105,-8.877165 c 0.47685,-0.476865 1.25025,-0.476865 1.7271,0 0.48,0.476865 0.48,1.25025 0,1.72713 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      ) : (
        <path
          d="m 29.481563,4.9268074 c -0.687449,-0.6873592 -1.803598,-0.6873592 -2.491047,0 L 10.585022,21.332317 3.0064613,13.753786 c -0.6873591,-0.687359 -1.8035842,-0.687359 -2.49094181,0 -0.68735915,0.687359 -0.68735915,1.803538 0,2.490987 l 8.82697891,8.826889 c 0.3436796,0.34365 0.7960336,0.51705 1.2454636,0.51705 0.449429,0 0.901799,-0.1734 1.245478,-0.51705 L 29.484413,7.4206892 c 0.687449,-0.6873591 0.687449,-1.8035827 0,-2.4938818 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    case 700:
      content = inCircle ? (
        <>
          <path
            d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="M 21.44385,9.265365 12.566775,18.1425 8.55924,14.13495 c -0.476865,-0.47688 -1.25025,-0.47688 -1.73019,0 -0.476865,0.476865 -0.476865,1.25025 0,1.73025 l 4.869585,4.8726 c 0.238425,0.23835 0.55023,0.3576 0.86508,0.3576 0.314865,0 0.62667,-0.11925 0.865095,-0.3576 l 9.74214,-9.742245 c 0.477,-0.47688 0.477,-1.250265 0,-1.73019 -0.47685,-0.476865 -1.25325,-0.476865 -1.7301,0 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="m 10.408592,22.454168 -8.3207594,-8.32079 c -0.4768644,-0.476864 -1.25331148,-0.476864 -1.73018039,0 -0.47686935,0.47688 -0.47686935,1.250249 0,1.730248 l 9.18584609,9.185839 c 0.2384397,0.238349 0.5502287,0.357599 0.8650937,0.357599 0.31485,0 0.626654,-0.11925 0.865079,-0.357599 L 29.642363,6.6807723 c 0.476849,-0.4768644 0.476849,-1.2502484 0,-1.7301877 -0.476849,-0.4768645 -1.253398,-0.4768645 -1.730248,0 L 10.405532,22.457168 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
    default:
      content = inCircle ? (
        <>
          <path
            d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 12.01086,20.529 c 0.124275,0.1242 0.2868,0.18795 0.449325,0.18795 0.162525,0 0.32505,-0.06375 0.44934,-0.18795 L 23.06565,10.372815 c 0.24855,-0.248565 0.24855,-0.65328 0,-0.901845 -0.24855,-0.248565 -0.65325,-0.248565 -0.9018,0 L 12.457005,19.1778 7.82985,14.550645 c -0.248565,-0.248565 -0.65328,-0.248565 -0.901845,0 -0.248565,0.248565 -0.248565,0.653205 0,0.901905 l 5.07648,5.07645 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <path
          d="m 9.762597,25.02624 c 0.1242902,0.12435 0.286816,0.1881 0.449341,0.1881 0.16251,0 0.325035,-0.06375 0.449325,-0.1881 L 29.813587,5.8739451 c 0.248551,-0.2485803 0.248551,-0.6532958 0,-0.9018611 -0.24855,-0.2485653 -0.653251,-0.2485653 -0.901801,0 L 10.211938,23.671888 1.0882737,14.548261 c -0.24856536,-0.248565 -0.65328236,-0.248565 -0.90184917,0 -0.24856637,0.248566 -0.24856637,0.653281 0,0.901817 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
