import { useState, useContext, useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

// style
import * as S from './CustomerSearch.styled';

// components
import Footer from 'components/Footer/Footer';
import ClientsSelect from 'components/DialogLayout/ClientsSelect/ClientsSelect';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import BasicTable from 'components/Tables/BasicTable/BasicTable';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import SearchWidget from 'components/SearchWidget/SearchWidget';

// contexts
import { CreateOrderContext } from 'context/SchedulerCreatingEventContext';
import { MainContext } from 'pages/Main/Main';
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useFunctionBar from 'hooks/useFunctionBar';
import useMediaQuery from 'hooks/useMediaQuery';
import { useClientsFindGet } from 'services/clientService';

const CustomerSearch = () => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const navigate = useNavigate();
  const location = useLocation();
  const functionBarFunctions = useFunctionBar(['new-client']); //, 'functions'
  // let functionsListItems = useFunctionList([
  //   'write-an-email',
  //   'deactivate-record',
  // ]);
  const { locale } = useContext(UserContext);
  const { searchQueries, setSearchIconVisibility } =
    useContext(MainContext);
  const { ['clients']: searchPhrase } = {
    ...(searchQueries && { ...searchQueries }),
  };
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const { data, isLoading } = useClientsFindGet({
    searchParams: {
      sectorId,
      ...(searchPhrase && { searchPhrase: searchPhrase }),
    },
    queryParams: {
      refetchOnWindowFocus: false,
    },
  });
  const [functionBarItems, setFunctionBarItems] =
    useState(functionBarFunctions);
  const [selectedClient, setSelectedClient] = useState({});
  const [orderTime, setOrderTime] = useState(new Date());

  if (data?.customers?.length) {
    const clients = data?.customers?.map((item) => {
      if (item?.phonePrefix && item?.phoneNumber) {
        item.phone = item.phonePrefix + item.phoneNumber;
      }
      if (item?.birthday) {
        item.birthday = new Date(item?.birthday).toLocaleDateString(locale);
      }

      return item;
    });

    data.customers = clients;
  }

  const columns = [
    {
      id: 'name',
      label: tr['firstName'],
    },
    {
      id: 'lastName',
      label: tr['lastName'],
    },
    {
      id: 'city',
      label: tr['city'],
    },
    {
      id: 'birthday',
      label: tr['dateOfBirth'],
    },
    {
      id: 'phone',
      label: tr['phone-number'],
    },
  ];

  const multiSelect =
    location.state?.multi === 'multi-selects' && location.state?.multi;

  useEffect(() => {
    updateFunctionBarItem('new-client', 'action', () =>
      navigate('/functions/tables/clients/new/general-information'),
    );
    // updateFunctionBarItem('functions', 'hide', () => false);
  }, []);

  useEffect(() => {
    setSearchIconVisibility(true);

    return () => {
      setSearchIconVisibility(false);
    };
  }, []);

  const updateFunctionBarItem = (id, key, value) => {
    setFunctionBarItems((prev) => {
      const newArr = [...prev];
      newArr.find((item) => item.id === id)[key] = value;
      return newArr;
    });
  };

  const navigateToCreateOrder = (record) =>
    navigate('/functions/tables/orders/new', {
      state: {
        ...location.state,
        selectedClient: record ?? selectedClient,
      },
    });

  const navigateToClient = (record) =>
    navigate(`/functions/tables/clients/${record.id}/general-information`);

  const isEmployeeSelected =
    location.state?.employee || location?.state?.wantedEmployees?.length;

  const navigateRecord = (record) => {
    if (isEmployeeSelected) {
      navigateToCreateOrder(record);
    } else {
      navigateToClient(record);
    }
  };

  return (
    <CreateOrderContext.Provider
      value={{
        client: { selectedClient, setSelectedClient },
        time: { orderTime, setOrderTime },
        sectorId: location.state?.searchParams?.sectorId,
      }}
    >
      <Routes>
        <Route
          index
          element={
            <S.CustomerSearchContainer>
              <div className="customer-search-content">
                <FunctionBar
                  functions={functionBarItems}
                  fabMarginY={isEmployeeSelected ? 96 : 20}
                />
                {isMobile ? (
                  <>
                    <SearchWidget queryId={'clients'} />

                    <ClientsSelect
                      navigation={navigateToClient}
                      records={data?.customers || []}
                      hasMore={data?.hasMore}
                    />
                  </>
                ) : (
                  <div className="content__layout">
                    <S.Header>
                      <h2>{tr.clientSearch}</h2>
                    </S.Header>

                    <S.Main>
                      <div className="client-select-wrapper">
                        <SearchWidget
                          queryId={'clients'}
                          raised
                          placeholder={tr.clientSearch}
                          darkTheme={false}
                        />

                        <div className="client-select__table-container">
                          <BasicTable
                            columns={columns}
                            isLoading={isLoading}
                            data={{
                              records: data?.customers || [],
                            }}
                            select={{
                              selected: selectedClient,
                              setSelected: setSelectedClient,
                            }}
                            navigation={{
                              arrow: navigateToClient,
                              doubleClick: navigateRecord,
                            }}
                            hasMore={data?.hasMore}
                          />
                        </div>
                      </div>

                      {Object.keys(selectedClient).length !== 0 && (
                        <CustomerInfo
                          customer={selectedClient}
                          createOrder={isEmployeeSelected}
                          multiSelect={multiSelect}
                          navigateToCreateOrder={navigateToCreateOrder}
                        />
                      )}
                    </S.Main>
                  </div>
                )}
              </div>
              <Footer content={2} />
            </S.CustomerSearchContainer>
          }
        />
        {/* <Route
          path="new-client/*"
          element={
            <ClientDetails
              buttonHandler={() =>
                console.log(
                  'Save button pressed, performing additional actions',
                )
              }
            />
          }
        /> */}
      </Routes>
    </CreateOrderContext.Provider>
  );
};

export default CustomerSearch;
