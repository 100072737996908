import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AvatarEditor from 'react-avatar-editor';

// style
import * as S from './AvatarCropper.styled';

// icons
import { TrashCan } from 'icons/dynamic';

// components
import { DialogBox } from 'components/DialogBox/DialogBox';
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const AvatarCropper = ({ src, close, updatePicture }) => {
  const tr = useTranslate().use().global;

  const [localPicture, setLocalPicture] = useState(src);

  // picture crop dialog box
  const cropRef = useRef(null);
  // picture selection dialog box
  const inputRef = useRef(null);

  // zoom slider value
  const [slideValue, setSlideValue] = useState(10);

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 8 * 1024 * 1024) {
      toast.error(tr['file-size-limit-exceeded']);
    } else {
      setLocalPicture(e.target.files[0]);
    }
  };

  // save handler
  const handleConfirm = async () => {
    if (cropRef) {
      if (localPicture) {
        const dataUrl = cropRef.current.getImage().toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        updatePicture({ value: URL.createObjectURL(blob), label: blob });
      } else {
        updatePicture(undefined);
      }

      close();
    }
  };

  const pickerButtons = [
    {
      id: 'select',
      title: tr['select'],
      inverted: true,
      action: () => {
        inputRef.current.value = '';
        inputRef?.current?.click();
      },
      // style: { marginRight: 'auto', flex: '0 1 30%' },
      stayOpen: true,
    },
    [
      {
        id: 'cancel',
        title: tr['cancel'],
        inverted: true,
        action: () => null, // will close automatically
      },
      {
        id: 'confirm',
        title: tr['apply'],
        action: handleConfirm,
      },
    ],
  ];

  return (
    <DialogBox
      headerText={tr['change-photo']}
      close={close}
      buttons={pickerButtons}
    >
      <S.AvatarCropper>
        <AvatarEditor
          ref={cropRef}
          image={localPicture}
          style={{ width: 'unset', height: 'unset' }}
          border={50}
          borderRadius={150}
          color={[128, 128, 128, 0.5]}
          scale={slideValue / 10}
          rotate={0}
        />

        <input
          type="range"
          min={10}
          max={50}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />

        <input
          type="file"
          accept="image/*"
          placeholder="blah"
          ref={inputRef}
          onChange={handlePictureChange}
          style={{ display: 'none' }}
        />

        {localPicture && (
          <TheButton
            flat
            inverted
            width="min-content"
            height="min-content"
            icon={<TrashCan bold inCircle />}
            customTextColor="red"
            customBackgroundColor="white"
            action={() => setLocalPicture(undefined)}
          />
        )}
      </S.AvatarCropper>
    </DialogBox>
  );
};
