import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SetList = styled.section`
  padding: 40px;
  max-width: 660px;

  flex-grow: 1;

  position: relative;

  display: flex;
  flex-direction: column;

  svg {
    width: 100%;
    height: 100%;
  }

  .list__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .list__title-wrapper {
      display: flex;

      h2 {
        font-size: 30px;
        margin: 0;
        padding: 0;
      }
    }

    .list__setIcon-wrapper {
      width: 30px;
      height: 30px;

      margin-right: 25px;

      flex-shrink: 0;

      margin-top: 3px;
    }

    .list__exitIcon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 4px 6px;

      svg {
        width: unset;
        height: unset;
      }
    }
  }

  .list__path-wrapper {
    padding-top: 30px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};

    display: flex;

    > div {
      margin-right: 3px;
    }

    .last {
      font-weight: 600;
    }
  }

  .list__list-wrapper {
    padding-top: 30px;

    display: flex;
    flex-direction: column;

    flex: 1;
    flex-grow: 1;
    overflow: hidden;

    .list__label-container {
      padding-bottom: 10px;
      border-bottom: 1px solid ${colors.bg_grey_2};
      color: ${colors.bg_grey_3};

      display: flex;
      justify-content: space-between;

      span {
        display: block;
        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};

        &:last-child {
          width: 110px;
        }
      }
    }

    .list__list-container {
      padding-top: 10px;

      margin-bottom: 10px;

      flex: 1;

      overflow: auto;

      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      > div {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &-empty {
        border: 1px solid red;
        text-align: center;
        border: 1px solid ${colors.bg_grey_2};
        border-radius: 5px;
        padding: 10px;
        color: ${colors.bg_grey_2};
      }
    }
  }

  .list__save-button {
    height: 40px;
    width: 100%;
    flex-shrink: 0;

    button {
      width: 100%;
    }
  }
`;
