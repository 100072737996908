import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 16.0077,18.34575 H 8.46789 c -0.690555,0 -1.249875,0.5583 -1.249875,1.2477 0,0.6894 0.55932,1.24785 1.249875,1.24785 h 7.53981 c 0.6906,0 1.24995,-0.55845 1.24995,-1.24785 0,-0.6894 -0.55935,-1.2477 -1.24995,-1.2477 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 13.09866,21.7395 H 8.46789 c -0.690555,0 -1.249875,0.55845 -1.249875,1.24785 0,0.6894 0.55932,1.24785 1.249875,1.24785 h 4.63077 c 0.690555,0 1.24986,-0.55845 1.24986,-1.24785 0,-0.6894 -0.559305,-1.24785 -1.24986,-1.24785 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 28.74705,7.380675 h -3.1653 V 1.24779 C 25.58175,0.5583855 25.0224,0 24.3318,0 H 5.71503 C 5.024475,0 4.465155,0.5583855 4.465155,1.24779 V 7.380675 H 1.2498705 C 0.559317,7.380675 0,7.939065 0,8.628465 V 23.3898 c 0,0.6894 0.559317,1.24785 1.2498705,1.24785 H 4.096455 v 4.1145 C 4.096455,29.44155 4.65576,30 5.346315,30 H 24.813 c 0.6906,0 1.24995,-0.55845 1.24995,-1.24785 v -4.1145 H 28.7502 C 29.44065,24.63765 30,24.0792 30,23.3898 V 8.628465 c 0,-0.6894 -0.55935,-1.24779 -1.2498,-1.24779 M 6.964905,2.49558 H 23.07885 V 7.380675 H 6.964905 Z M 23.56005,27.50445 H 6.59307 V 17.2569 H 23.56005 Z M 27.4971,22.1421 h -1.4373 v -6.1329 c 0,-0.6894 -0.55935,-1.247835 -1.24995,-1.247835 H 5.343195 c -0.690555,0 -1.249875,0.558435 -1.249875,1.247835 v 6.1329 H 2.496615 V 9.876255 H 27.49395 V 22.1421 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 16.049625,19.1418 H 8.19567 c -0.358035,0 -0.65097,0.29295 -0.65097,0.651 0,0.35805 0.292935,0.651 0.65097,0.651 h 7.853955 c 0.35805,0 0.651,-0.29295 0.651,-0.651 0,-0.35805 -0.29295,-0.651 -0.651,-0.651 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 13.01937,22.67985 h -4.8237 c -0.36129,0 -0.65097,0.29295 -0.65097,0.651 0,0.3579 0.28968,0.65085 0.65097,0.65085 h 4.8237 c 0.358035,0 0.65097,-0.29295 0.65097,-0.65085 0,-0.35805 -0.292935,-0.651 -0.65097,-0.651 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 0.6769965,24.40485 H 4.293135 V 29.349 c 0,0.35805 0.292935,0.651 0.65097,0.651 h 20.27772 c 0.36135,0 0.651,-0.29295 0.651,-0.651 v -4.94415 h 3.45015 c 0.35805,0 0.651,-0.29295 0.651,-0.65085 V 8.351955 c 0,-0.358035 -0.29295,-0.65097 -0.651,-0.65097 h -3.94815 V 0.6509715 C 25.374825,0.2929365 25.085175,0 24.723825,0 H 5.331435 C 4.970145,0 4.680465,0.2929365 4.680465,0.6509715 V 7.700985 H 0.6769965 c -0.358035,0 -0.6509715,0.292935 -0.6509715,0.65097 V 23.754 c 0,0.3579 0.2929365,0.65085 0.6509715,0.65085 z M 24.567675,28.698 H 5.59509 V 16.70385 H 24.570825 V 28.698 Z M 5.982405,1.3019415 h 18.08727 V 7.700985 H 5.982405 Z M 1.3279665,9.002925 H 28.668675 V 23.103 h -2.79915 v -7.05 c 0,-0.35805 -0.28965,-0.651 -0.65085,-0.651 H 4.944105 c -0.358035,0 -0.65097,0.29295 -0.65097,0.651 v 7.05 H 1.3279665 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
