// style
import * as S from './SelectSMSDuration.styled';

// components
import SelectList from '../CustomSelect/children/SelectList/SelectList';

// hooks
import useService from 'hooks/useService';

const SelectSMSDuration = ({ service, select }) => {
  const { getSmsDurationOptions } = useService({ service });

  return (
    <S.SelectSMSContainer>
      <SelectList
        arr={getSmsDurationOptions()}
        currentlySelected={(value) => service.smsPeriodCustomer === value}
        action={select}
        belongsTo={service?.orderId}
      />
    </S.SelectSMSContainer>
  );
};

export default SelectSMSDuration;
