import * as S from '../SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 27.0505,4.1109 H 2.9495 c -1.6261,0 -2.9496,1.3235 -2.9496,2.9496 v 15.879 c 0,1.6261 1.3235,2.9496 2.9496,2.9496 h 24.101 c 1.6261,0 2.9496,-1.3235 2.9496,-2.9496 V 7.0605 c 0,-1.6261 -1.3235,-2.9496 -2.9496,-2.9496 z m 0.50433,18.828 c 0,0.27815 -0.22619,0.50433 -0.50433,0.50433 H 2.9495 c -0.27815,0 -0.50433,-0.22619 -0.50433,-0.50433 V 7.0599 c 0,-0.27815 0.22619,-0.50433 0.50433,-0.50433 h 24.101 c 0.27815,0 0.50433,0.22619 0.50433,0.50433 z"
        strokeWidth="0.30566"
      />
      <path
        d="m 21.5825,16.4199 c -0.49822,-0.45543 -1.2715,-0.42181 -1.727,0.07641 -0.45543,0.49822 -0.42181,1.2715 0.07641,1.727 l 4.7438,4.3434 c 0.23536,0.21396 0.53184,0.32094 0.82527,0.32094 0.33011,0 0.66022,-0.13449 0.90168,-0.39735 0.45543,-0.49822 0.42181,-1.2715 -0.07641,-1.727 z"
        strokeWidth="0.30566"
      />
      <path
        d="m 8.4151,16.4199 -4.7438,4.3434 c -0.49822,0.45543 -0.53184,1.2287 -0.076414,1.727 0.24147,0.26286 0.57158,0.39735 0.90169,0.39735 0.29343,0 0.58992,-0.10698 0.82527,-0.32094 l 4.7438,-4.3434 c 0.49822,-0.45543 0.53184,-1.2287 0.07641,-1.727 -0.45543,-0.49822 -1.2287,-0.53184 -1.727,-0.07641 z"
        strokeWidth="0.30566"
      />
      <path
        d="M 26.1615,7.7268 C 25.70607,7.22858 24.9328,7.19496 24.4345,7.650386 l -9.4356,8.6378 -9.4356,-8.6378 C 5.06508,7.194956 4.2918,7.228576 3.8363,7.7268 3.38087,8.22502 3.41449,8.9983 3.912714,9.4538 l 10.261,9.3959 c 0.2323,0.21396 0.52878,0.32094 0.82527,0.32094 0.29649,0 0.59297,-0.10698 0.82527,-0.32094 l 10.261,-9.3959 c 0.49822,-0.45543 0.53184,-1.2287 0.07641,-1.727 z"
        strokeWidth="0.30566"
      />
    </>
  ) : (
    <>
      <path
        d="m 2.4375,25.7111 h 25.125 c 1.3447,0 2.4376,-1.0929 2.4376,-2.4376 v -16.55 c 0,-1.3447 -1.0929,-2.4376 -2.4376,-2.4376 H 2.4375 c -1.3447,0 -2.4376,1.0929 -2.4376,2.4376 v 16.553 c 0,1.3447 1.0929,2.4376 2.4376,2.4376 z M 1.2745,6.7231 c 0,-0.64047 0.52257,-1.163 1.163,-1.163 h 25.125 c 0.64047,0 1.163,0.52257 1.163,1.163 v 16.553 c 0,0.64047 -0.52257,1.163 -1.163,1.163 H 2.4375 c -0.64047,0 -1.163,-0.52257 -1.163,-1.163 z"
        strokeWidth="0.31864"
      />
      <path
        d="m 20.5299,16.9901 c -0.23898,0.2581 -0.21986,0.66277 0.03824,0.90175 l 4.9453,4.5279 c 0.12108,0.11152 0.27722,0.16569 0.43016,0.16569 0.17207,0 0.34413,-0.0701 0.47159,-0.20712 0.23898,-0.2581 0.21986,-0.66277 -0.03824,-0.90175 l -4.9453,-4.5279 c -0.26128,-0.23898 -0.66277,-0.21986 -0.90175,0.03824 z"
        strokeWidth="0.31864"
      />
      <path
        d="m 4.0498,22.5881 c 0.15295,0 0.30908,-0.05417 0.43016,-0.16569 l 4.9453,-4.5279 c 0.2581,-0.23898 0.27722,-0.64047 0.038237,-0.90175 -0.23898,-0.2581 -0.64047,-0.27722 -0.90175,-0.03824 l -4.9453,4.5279 c -0.2581,0.23898 -0.27722,0.64047 -0.038237,0.90175 0.12427,0.13702 0.29634,0.20712 0.47159,0.20712 z"
        strokeWidth="0.31864"
      />
      <path
        d="m 14.5679,18.5421 c 0.12108,0.11152 0.27722,0.16569 0.43016,0.16569 0.15295,0 0.30908,-0.05417 0.43016,-0.16569 l 10.697,-9.795 C 26.38332,8.50812 26.40244,8.10663 26.16346,7.84535 25.92448,7.58406 25.52299,7.56813 25.26171,7.807113 l -10.267,9.3999 -10.263,-9.3999 C 4.473607,7.571323 4.068937,7.587253 3.829957,7.84535 3.590977,8.10345 3.610097,8.50812 3.868194,8.7471 l 10.697,9.795 z"
        strokeWidth="0.31864"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
