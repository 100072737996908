// style
import * as S from './TableHead.styled';

// components
import { HeaderRow } from './partials/HeaderRow/HeaderRow';
import { FiltersRow } from './partials/FiltersRow/FiltersRow';

export const TableHead = ({ hasNavigation, hasDeleteButton }) => (
  <S.TableHead>
    <HeaderRow
      hasNavigation={hasNavigation}
      hasDeleteButton={hasDeleteButton}
    />
    <FiltersRow
      hasNavigation={hasNavigation}
      hasDeleteButton={hasDeleteButton}
    />
  </S.TableHead>
);
