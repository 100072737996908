import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const AppointmentsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 40px;

  /* overflow-y: hidden;
  overflow-x: visible; */

  pointer-events: none;
`;

export const Text = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  max-width: 560px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;
