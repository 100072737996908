const pendingOrdersReducer = (
  state = {
    orders: [],
  },
  action,
) => {
  switch (action.type) {
    case 'PENDING_ORDERS_SET':
      return { ...state, orders: action.payload.orders };

    case 'PENDING_ORDERS_ADD':
      return { ...state, orders: [action.payload, ...state.orders] };

    case 'PENDING_ORDER_UPDATE':
      const x = state.orders.map((order) => {
        if (order.id === action.payload.id) {
          return action.payload;
        } else {
          return order;
        }
      });

      return { ...state, orders: x };

    case 'PENDING_ORDER_DELETED':
      const filteredOrders = state.orders.filter(
        (order) => order.id !== action.payload,
      );

      return { ...state, orders: filteredOrders };
    case 'APPOINTMENT_RESET':
      return {
        ...state,
        orders: state.orders.filter(
          (order) => order.id !== action.payload.order.id,
        ),
      };
    default:
      return state;
  }
};

export default pendingOrdersReducer;
