import { useState } from 'react';

// Style
import * as S from './ServiceCardDisplaySettings.styled';

// Translation
import { useTranslate } from 'hooks/useTranslate';

// Components
import Checkbox from 'components/Checkbox/Checkbox';
import AppointmentCard from 'components/AppointmentCard/AppointmentCard';

export const ServiceCardDisplaySettings = () => {
  const tr = useTranslate().use().global;

  const checkListArr = [
    { id: 'name', label: tr['client-name'], value: true },
    { id: 'lastName', label: tr['client-last-name'], value: true },
    { id: 'phone', label: tr['phone-number'], value: false },
    { id: 'serviceName', label: tr['service-name'], value: true },
    // { id: 'cabinet', label: tr['office-number'], value: true },
    {
      id: 'iconNewClient',
      label: `${tr['indication-label']} "${tr['new-client']}"`,
      value: true,
    },
    {
      id: 'iconSms',
      label: `${tr['indication-label']} "${tr['generating-sms']}"`,
      value: true,
    },
    {
      id: 'iconBirthday',
      label: `${tr['indication-label']} "${tr['today-birthday']}"`,
      value: true,
    },
    {
      id: 'iconNote',
      label: `${tr['indication-label']} "${tr['order-note']}"`,
      value: true,
    },
  ];

  // Hooks
  const [checkList, setCheckList] = useState(checkListArr);

  // functions
  const toggleCheckList = (index) => {
    setCheckList((prev) => {
      const newArr = prev.map((item, i) => {
        if (i === index) {
          return { ...item, value: !item.value };
        } else {
          return item;
        }
      });

      return newArr;
    });
  };

  return (
    <S.ServiceCardDisplaySettings>
      <p className="settings__label">{tr['service-display']} </p>
      <div className="settings__container">
        <ul className="settings__list">
          {checkList.map((item, i) => (
            <li key={i}>
              <Checkbox
                text={item.label}
                value={{ state: item.value, action: () => toggleCheckList(i) }}
              />
            </li>
          ))}
        </ul>
        <div className="settings__card">
          <div className="settings__service-card-wrapper">
            <AppointmentCard displaySettings={checkList} />
          </div>
        </div>
      </div>
    </S.ServiceCardDisplaySettings>
  );
};

export default ServiceCardDisplaySettings;
