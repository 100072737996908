import { useState } from 'react';
import { useEffect } from 'react';

export const useHideOnScroll = () => {
  const [shiftBy, setShiftBy] = useState(0);
  const [navBarTop, setNavBarTop] = useState();

  useEffect(() => {
    let previousScrollYPosition = window.scrollY;

    setNavBarTop(document.getElementById('header-nav')?.clientHeight);

    const isScrollingUp = (currentScrollYPosition) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;

      const newScrollDirection = isScrollingUp(currentScrollYPosition)
        ? 'down'
        : 'up';
      previousScrollYPosition =
        currentScrollYPosition > 0 ? currentScrollYPosition : 0;

      // shift/return NavBar and FunctionBar
      if (newScrollDirection === 'down' && currentScrollYPosition > navBarTop) {
        setShiftBy(navBarTop);
      } else {
        setShiftBy(0);
      }
    };

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

    const onResize = () =>
      setNavBarTop(document.getElementById('header-nav').clientHeight);

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [navBarTop]);
  return {
    shiftBy,
    navBarTop,
  };
};

export default useHideOnScroll;
