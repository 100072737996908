import React from 'react';

// Style
import * as S from './IconValueColumn.styled';

const IconValueColumn = ({ icon, value, ...rest }) => {
  return (
    <S.IconValue {...rest}>
      <div className="icon-wrapper">{icon}</div>
      <div className="value-wrapper">{value}</div>
    </S.IconValue>
  );
};

export default IconValueColumn;
