import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const Container = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 16px;
  position: relative;
  width: max-content;

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .labelText {
    color: ${({ disabled }) => disabled && colors.disabledGrey};
    margin-right: 3px;
    padding-top: 2px;
  }
`;
