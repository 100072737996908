import * as S from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <S.StyledSVG
    width={width ? width : 17}
    height={height ? height : 11.148}
    viewBox="0 0 17 11.148"
    color={color || 'currentColor'}
    {...rest}
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          width="17"
          height="11.148"
          transform="translate(0 0)"
          // fill="#0f1d5b"
        />
      </clipPath>
    </defs>
    <g id="Group_2790" data-name="Group 2790" transform="translate(0 0)">
      <g transform="translate(0 0)" clipPath="url(#clipPath)">
        <path
          d="M16.307,4.882H2.365l3.7-3.7A.693.693,0,0,0,5.084.2L.2,5.084a.711.711,0,0,0-.087.106C.1,5.207.1,5.226.088,5.243a.64.64,0,0,0-.035.067.65.65,0,0,0-.022.071c-.006.02-.013.038-.017.059a.694.694,0,0,0,0,.271c0,.02.012.039.017.059a.65.65,0,0,0,.022.07.64.64,0,0,0,.035.067c.01.017.017.036.028.053A.711.711,0,0,0,.2,6.065l4.881,4.881a.693.693,0,0,0,.98-.98l-3.7-3.7H16.307a.693.693,0,1,0,0-1.386"
          transform="translate(0 0)"
          // fill="#0f1d5b"
        />
      </g>
    </g>
  </S.StyledSVG>
);
