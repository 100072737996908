import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { TheButton } from 'components/Buttons/TheButton.styled';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';
import styled from 'styled-components/macro';

export const DiscountModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 562px;
  gap: 20px;
  padding-bottom: 10px;

  .block {
    display: flex;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      span {
        font-family: ${fontFamilies.family_Secondary};
      }

      ${BaseInput} {
        width: 120px;

        .input-wrapper,
        .input-wrapper input {
          background-color: ${colors.bg_grey_1};
          height: 27px;
          min-height: unset;
        }

        .input-wrapper {
          border: unset;

          input {
            font-size: 0.875rem;
            max-width: 110px;
          }

          .clear-icon {
            display: none;
          }
        }
      }
    }

    &.result {
      border: 1px solid ${colors.canceled};
      border-radius: 5px;
      padding: 14px 0;

      .item {
        gap: 10px;

        span {
          text-align: center;
          font-size: 0.75rem;

          &.value {
            font-size: 1.125rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    padding: 30px 20px;
    width: 100%;
    height: 100%;

    .block {
      flex-direction: column;
      gap: 30px;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;

        ${BaseInput} {
          margin-left: auto;
        }
      }

      &.result {
        flex-direction: row;

        .item {
          flex-direction: column;
        }
      }
    }

    ${TheButton} {
      height: auto;
      margin-top: auto;
      height: 35px;
    }
  }
`;
