import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <path
          d="m 7.1349197,0.51671573 c -0.68787,0.68787597 -0.68817,1.80397947 0,2.49214947 L 19.126565,15.0003 7.1349197,26.99175 c -0.68817,0.6882 -0.688455,1.8042 0,2.4921 0.68847,0.6879 1.80429,0.6885 2.49216,0 L 22.865015,16.2462 c 0.33075,-0.33075 0.5163,-0.77865 0.5163,-1.2462 0,-0.467595 -0.18555,-0.91551 -0.5163,-1.24623 L 9.6270797,0.51612623 c -0.68817,-0.68816849 -1.80399,-0.68816849 -2.49216,0 z"
          fill="currentColor"
          strokeWidth="1.5"
          transform="translate(2.3937545)"
        />
      );

      break;
    case 700:
      content = (
        <path
          d="m 23.111553,14.999982 c 0,0.3243 -0.129,0.635249 -0.35835,0.864449 L 8.9755408,29.641913 c -0.4774943,0.477449 -1.2517933,0.477449 -1.7289727,0 -0.4774944,-0.477299 -0.4774944,-1.251598 0,-1.729048 L 20.159407,14.999982 7.2465681,2.0870988 c -0.4774945,-0.4771943 -0.4774945,-1.25149935 0,-1.72898323 0.4774794,-0.47748719 1.2517934,-0.47748719 1.7289727,0 L 22.753203,14.135488 c 0.22935,0.229275 0.35835,0.540464 0.35835,0.864494 z"
          fill="currentColor"
          strokeWidth="1.5"
          transform="translate(2.3937545)"
        />
      );
      break;
    default:
      content = (
        <path
          d="m 20.425011,15.00015 c 0,0.1689 -0.0672,0.33105 -0.18675,0.4506 L 5.8753401,29.813401 c -0.2488801,0.24885 -0.6523201,0.24885 -0.9012001,0 -0.24888,-0.249 -0.24888,-0.65235 0,-0.9012 L 18.886461,15.00015 4.97414,1.0878714 c -0.24888,-0.24888306 -0.24888,-0.65232458 0,-0.9012076 0.24888,-0.24888317 0.65232,-0.24888317 0.9012001,0 L 20.238261,14.549235 c 0.11955,0.119505 0.18675,0.28203 0.18675,0.450915 z"
          fill="currentColor"
          strokeWidth="1.5"
          transform="translate(2.3937545)"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
