import StyledSVG from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <StyledSVG
    width={width ? width : '19.58'}
    height={height ? height : '14.155'}
    viewBox="0 0 19.58 14.155"
    color={color}
    {...rest}
  >
    <g transform="translate(-1377.077 -2439.832)">
      <path
        d="M1396.024,2439.832H1377.71a.634.634,0,0,0-.633.633v12.888a.634.634,0,0,0,.633.633h18.314a.634.634,0,0,0,.633-.633v-12.888A.634.634,0,0,0,1396.024,2439.832Zm-.211,13.311h-17.892v-12.467h17.892Z"
        transform="translate(0 0)"
      />
      <path
        d="M1396.971,2459.807l7.114-7.115a.422.422,0,0,0-.6-.6l-6.517,6.517-6.451-6.451a.422.422,0,0,0-.6.6Z"
        transform="translate(-10.038 -9.578)"
      />
      <path
        d="M1388.926,2481.179a.42.42,0,0,0,.3-.124l2.91-2.91a.422.422,0,0,0-.6-.6l-2.91,2.91a.422.422,0,0,0,.3.721Z"
        transform="translate(-9.016 -29.66)"
      />
      <path
        d="M1441.016,2476.749a.422.422,0,1,0-.6.6l2.91,2.909a.422.422,0,0,0,.6-.6Z"
        transform="translate(-49.877 -29.029)"
      />
    </g>
  </StyledSVG>
);
