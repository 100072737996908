import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const ProfessionalHeader = styled.div``;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: visible;

  color: ${colors.bg_Primary};

  ${(props) =>
    props.variant === 'multi' &&
    css`
       {
        flex-direction: row;
        margin-left: 30px;

        .details {
          margin-left: 20px;
        }
      }
    `}

  @media screen and (max-width: 960px) {
    align-items: center;
    margin-left: 0;

    ${(props) =>
      props.variant === 'multi' &&
      css`
       {
        margin-left:  ${(props) => (['en-US'].includes(props.locale) ? 30 : 16)}px;
      }
    `}
  }

  .details {
    h4 {
      font-size: 0.875rem;
      font-family: ${fontFamilies.family_Primary};
      font-weight: 900;
      margin: 0;
      padding: 5px 0 0 0;
      text-align: center;

      @media screen and (max-width: 960px) {
        font-size: 14px;
        padding-top: 3px;
      }
    }

    span {
      font-size: 0.688rem;
      font-family: ${fontFamilies.family_Sen};

      padding: 5px 0 0 0;

      display: block;
      text-align: ${(props) =>
        props.variant === 'multi' ? 'start' : 'center'};

      @media screen and (max-width: 960px) {
        display: none;
      }
    }
  }
`;

export const ImgContainer = styled.div`
  position: relative;

  width: 60px;
  height: 60px;

  font-size: 1.5rem;

  display: flex;

  gap: 10px;

  .img-wrapper {
    width: 100%;
    height: 100%;

    cursor: pointer;

    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
    border: ${(props) =>
      props.selected ? `2px solid ${colors.calendar_office_number}` : 'none'};

    transition: border 100ms ease-in;

    border-radius: 100px;
  }

  .actions-wrapper {
    position: absolute;

    right: 65px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 3px;

    height: 100%;

    &__context {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      padding: 3px;
      border-radius: 3px;

      padding: 3px;

      position: relative;

      padding: 7px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    @media screen and (max-width: 960px) {
      right: 56px;
    }
  }

  .hover {
    border-radius: 50%;

    :hover {
      background-color: ${colors.bg_grey_2};
      cursor: pointer;
    }
  }

  .pin {
    position: absolute;
    top: 0;
    left: 100%;

    transform: translate(-50%, 0%);

    padding: 7px;

    display: flex;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  @media screen and (max-width: 960px) {
    width: 50px;
    height: 50px;

    font-size: 1.25rem;
  }
`;

export const OfficeWrapper = styled.div`
  position: absolute;
  height: 20px;
  bottom: 0;
  left: 40px;

  display: flex;
  justify-content: space-between;

  .pinnedProfessional {
    position: absolute;
    top: -65px;
    right: 1px;

    button {
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
  }

  @media screen and (max-width: 960px) {
    height: 20px;
    width: 45px;

    .pinnedProfessional {
      top: -30px;
      right: 0;

      button {
        width: 18px;
        height: 18px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
`;

export const OfficeNumber = styled.div`
  min-width: 20px;
  height: 20px;

  padding: 0 5px 2px;

  border-radius: 100px;

  background-color: ${colors.calendar_office_number};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  font-weight: 900;

  color: ${colors.calendar_button_text_color};

  display: ${(props) => props.variant === 'multi' && 'none'};

  @media screen and (max-width: 960px) {
    width: 20px;
    height: 20px;

    font-size: 12px;

    box-sizing: border-box;
  }
`;

export const MoreButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 0 5px;
  padding-top: ${(props) => (props.toggled ? '2px' : 0)};
  padding-bottom: ${(props) => (props.toggled ? '2px' : 0)};

  width: 30px;

  border: 1px solid ${colors.bg_Primary};

  flex-direction: column;
  justify-content: ${(props) => (props.toggled ? 'space-between' : 'center')};

  div {
    border-radius: 50%;
    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    color: ${colors.bg_Primary};
  }

  button {
    border-radius: 50%;
    width: 28px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  button:last-child {
    svg {
      height: ${(props) => (props.toggled ? '15' : '16')}px;
      width: ${(props) => (props.toggled ? '15' : '16')}px;
    }
  }

  @media screen and (max-width: 960px) {
    /* padding-top: ${(props) => (props.toggled ? '10px' : '5px')}; */
    height: ${(props) => (props.toggled ? '110px' : '20px')};
    width: 20px;
    border: ${(props) => '1px solid ' + props.color};

    button {
      width: 18px;
      height: 18px;

      svg {
        height: 3px !important;
        width: 11px !important;
      }
    }
  }
`;
