import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';
import styled from 'styled-components/macro';

const gridLayout = `
grid-template-columns: repeat(3, 1fr);
grid-gap: 10px;
display: grid;
grid-auto-rows: minmax(100px, auto);

place-items: center;

@media screen and (min-width: 550px) {
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 650px) {
  grid-template-columns: repeat(5, 1fr);
}
@media screen and (min-width: 750px) {
  grid-template-columns: repeat(6, 1fr);
}
@media screen and (min-width: 961px) {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 1060px) {
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1260px) {
  grid-template-columns: repeat(5, 1fr);
}
@media screen and (min-width: 1460px) {
  grid-template-columns: repeat(6, 1fr);
}

`;

// Mobile first

export const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0;
  position: relative;
`;

export const SelectProfessionals = styled.div`
  flex: 1 0 auto;
  min-height: calc(100% - 200px);

  @media screen and (min-width: 961px) {
    width: 610px;
  }

  @media screen and (min-width: 1060px) {
    width: 800px;
  }

  @media screen and (min-width: 1260px) {
    width: 990px;
  }

  @media screen and (min-width: 1460px) {
    width: 1180px;
  }

  .checkBox {
    font-size: 0.875rem;
    font-family: ${fontFamilies.family_Secondary};
    height: 20px;

    width: fit-content;
    padding: 0 20px;

    display: flex;
    align-items: center;

    gap: 20px;

    > label {
      flex-direction: row-reverse;

      span {
        padding: 0 !important;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .labelText {
      padding-top: 0;
      margin-left: 10px;
    }
  }

  .employee__loader {
    height: 140px;
    position: relative;
  }

  .has_more {
    text-align: center;
    color: ${colors.mandyRed};
  }

  @media screen and (min-width: 961px) {
    margin-top: 20px;
    /* width: 100vw; */
    /* max-width: 1200px; */
    overflow: auto;
    height: ${(props) => (props.selectedFooter ? 330 : 330)}px;

    .checkBox {
      height: 20px;
      gap: 30px;
      font-size: 1.125rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${colors.bg_Primary};
    }

    ::-webkit-scrollbar-track {
      background-color: white;
      border: none;
    }
  }
`;

export const SelectProfessionalsContainer = styled.div`
  padding: 20px;
  width: 100%;

  ${({ grid }) => grid && gridLayout}

  .empty-message {
    text-align: center;
  }
`;

export const Footer = styled.div`
  min-height: 200px;
  width: 100%;
  background-color: ${colors.bg_grey_1};
  box-shadow: ${shadow.lg};

  position: sticky;
  bottom: 0;

  padding: 20px;

  .selected-container {
    margin-bottom: 20px;
    ${gridLayout};

    overflow: auto;

    max-height: 102px;

    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  button {
    padding: 10px 0;
    height: 35px;

    svg {
      width: 16px;
      height: 16px;
      padding: 0;
      margin-right: 10px;
    }
  }
  @media screen and (min-width: 961px) {
    max-width: 1200px;
    width: 100%;
    margin-top: 20px;
    min-height: 130px;
    height: 130px;
    box-shadow: none;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;

    .selected-container {
      grid-template-columns: repeat(7, 1fr);
    }

    > button {
      width: fit-content;
      padding: 0 20px;
      position: absolute;
      right: 0;
      top: 150px;
    }
  }
`;
