import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const OrderInformation = styled.div`
  padding: 40px;
`;

export const LabelValue = styled.div`
  display: flex;
  flex-wrap: nowrap;

  .label-container {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 40px;

    font-size: 18px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 900;

    .label {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .value-container {
    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-family: ${fontFamilies.family_Secondary};

    > div:last-child {
      margin: 0;
    }

    .employee-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      .avatar {
        width: 50px;
        height: 50px;
      }

      .fullName {
        margin-left: 10px;
      }
    }

    .date-wrapper {
      display: flex;
      align-items: center;
      height: 50px;
      margin-bottom: 7px;
    }
  }
`;
