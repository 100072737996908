import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const AnnouncementsForm = styled.div`
  h2 {
    font-size: 40px;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 18px;
    font-family: ${fontFamilies.family_Secondary};

    margin: 0;
    margin-top: 15px;
  }

  .user-details__container {
    padding: 5px;
    background-color: white;
    border-radius: 100px;

    margin-top: 40px;

    display: flex;

    box-shadow: ${shadow.md};
  }

  .user-details__avatar {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .user-details__info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    span:first-child {
      font-size: 18px;
      font-weight: 900;
    }

    span:last-child {
      font-size: 16px;
    }
  }

  .user-details__comment {
    margin-top: 40px;
    height: 350px;

    border-radius: 20px;
    overflow: hidden;

    box-shadow: ${shadow.md};

    position: relative;

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      resize: none;

      padding: 20px;
      padding-left: 50px;

      font-size: 18px;
      font-family: ${fontFamilies.family_Secondary};
      color: ${colors.bg_Primary};

      ::placeholder {
        color: ${colors.bg_grey_3};
      }

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background-color: white;
        border: none;
        border-radius: 10px;
        margin-top: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${colors.bg_Primary};
      }
    }

    .text-area__icon {
      position: absolute;
      top: 20px;
      left: 20px;

      height: 25px;
      width: 25px;

      svg {
        fill: ${colors.bg_grey_3};
      }
    }
  }

  .user-details__add-photo {
    background-color: white;
    margin-top: 40px;
    padding: 0 75px;

    height: 80px;
    border-radius: 100px;

    display: flex;
    align-items: center;

    box-shadow: ${shadow.md};

    cursor: pointer;

    span {
      color: ${colors.bg_grey_3};
      font-size: 18px;
      font-family: ${fontFamilies.family_Secondary};
    }
  }

  .user-details__send-button {
    margin-top: 40px;
    height: 60px;

    > button {
      font-size: 25px;
      width: 100%;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      margin-top: 10px;
    }

    .user-details__container {
      margin-top: 15px;
    }

    .user-details__avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .user-details__info {
      span:first-child {
        font-size: 14px;
      }

      span:last-child {
        font-size: 12px;
      }
    }

    .user-details__comment {
      margin-top: 20px;
      height: 220px;

      border-radius: 10px;

      textarea {
        padding: 10px;
        padding-left: 40px;

        font-size: 14px;
      }

      .text-area__icon {
        top: 10px;
        left: 10px;

        height: 20px;
        width: 20px;
      }
    }

    .user-details__add-photo {
      margin-top: 20px;
      padding: 0 45px;
      height: 50px;

      span {
        font-size: 14px;
      }
    }

    .user-details__send-button {
      margin-top: 20px;
      height: 32px;

      > button {
        font-size: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
`;
