import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 11.56191,0.03046853 c -3.965835,0 -7.190355,3.66686297 -7.190355,8.17266797 0,4.5057895 3.22452,8.1696445 7.190355,8.1696445 3.96579,0 7.19034,-3.663855 7.19034,-8.1696445 0,-4.505805 -3.22455,-8.17266798 -7.19034,-8.17266797 z m 0,13.90176747 c -2.6205,0 -4.74984,-2.568645 -4.74984,-5.7290995 0,-3.16047 2.12934,-5.73216 4.74984,-5.73216 2.6205,0 4.74984,2.57169 4.74984,5.73216 0,3.1604545 -2.12934,5.7290995 -4.74984,5.7290995 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 23.3832,16.735781 c -3.6486,0 -6.6168,2.96835 -6.6168,6.61695 0,3.64845 2.9682,6.6168 6.6168,6.6168 3.6486,0 6.6168,-2.96835 6.6168,-6.6168 0,-3.6486 -2.9682,-6.61695 -6.6168,-6.61695 z m 0,10.7901 c -2.30325,0 -4.1763,-1.87305 -4.1763,-4.1763 0,-2.30325 1.87305,-4.1763 4.1763,-4.1763 2.30325,0 4.1763,1.87305 4.1763,4.1763 0,2.30325 -1.87305,4.1763 -4.1763,4.1763 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.60345,22.132481 v -1.1562 c 0,-0.67425 -0.54615,-1.22025 -1.22025,-1.22025 -0.67425,0 -1.22025,0.546 -1.22025,1.22025 v 1.1562 h -1.1562 c -0.67425,0 -1.22025,0.546 -1.22025,1.22025 0,0.6741 0.546,1.22025 1.22025,1.22025 h 1.1562 v 1.1562 c 0,0.6741 0.546,1.22025 1.22025,1.22025 0.6741,0 1.22025,-0.54615 1.22025,-1.22025 v -1.1562 h 1.1562 c 0.6741,0 1.22025,-0.54615 1.22025,-1.22025 0,-0.67425 -0.54615,-1.22025 -1.22025,-1.22025 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M 16.0464,27.525881 H 2.44356 v -4.2984 c 0,-2.3154 1.40634,-4.38675 3.52959,-5.25315 1.677855,1.14705 3.596715,1.7511 5.588775,1.7511 2.214765,0 4.338075,-0.74445 6.140925,-2.15685 0.53085,-0.4149 0.62535,-1.18365 0.21045,-1.7145 -0.41475,-0.5307 -1.18365,-0.62535 -1.71435,-0.21045 -1.369785,1.0707 -2.974425,1.63815 -4.640085,1.63815 -1.665645,0 -3.267225,-0.56745 -4.64001,-1.63815 -0.32337,-0.2532 -0.75657,-0.3264 -1.14399,-0.1953 L 5.4759,15.552131 C 2.202555,16.680881 0,19.762031 0,23.224481 v 5.51865 c 0,0.6741 0.5460645,1.22025 1.220256,1.22025 H 16.04325 c 0.67425,0 1.22025,-0.54615 1.22025,-1.22025 0,-0.67425 -0.546,-1.22025 -1.22025,-1.22025"
        fill="currentColor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 11.418105,15.794942 c 3.780645,0 6.858495,-3.53583 6.858495,-7.8792304 0,-4.3434 -3.07785,-7.88235325 -6.858495,-7.88235325 -3.7806,0 -6.85851,3.53577325 -6.85851,7.88235325 0,4.3465804 3.07791,7.8792304 6.85851,7.8792304 z m 0,-14.4897243 c 3.08109,0 5.586645,2.9666189 5.586645,6.610494 0,3.6438753 -2.505555,6.6073203 -5.586645,6.6073203 -3.081075,0 -5.586645,-2.963445 -5.586645,-6.6073204 0,-3.643875 2.50557,-6.610494 5.586645,-6.6104939 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 23.7393,17.445092 c -3.45315,0 -6.26085,2.8077 -6.26085,6.2607 0,3.45315 2.8077,6.26085 6.26085,6.26085 3.453,0 6.2607,-2.8077 6.2607,-6.26085 0,-3.453 -2.8077,-6.2607 -6.2607,-6.2607 z m 0,11.2497 c -2.7504,0 -4.98885,-2.2386 -4.98885,-4.989 0,-2.75025 2.23845,-4.98885 4.98885,-4.98885 2.7504,0 4.98885,2.2386 4.98885,4.98885 0,2.7504 -2.23845,4.989 -4.98885,4.989 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="m 26.21625,23.069942 h -1.8411 v -1.84095 c 0,-0.35295 -0.2862,-0.636 -0.63585,-0.636 -0.3498,0 -0.636,0.28305 -0.636,0.636 v 1.84095 h -1.84095 c -0.3498,0 -0.636,0.2862 -0.636,0.636 0,0.34965 0.2862,0.63585 0.636,0.63585 h 1.84095 v 1.84095 c 0,0.35295 0.2862,0.636 0.636,0.636 0.34965,0 0.63585,-0.28305 0.63585,-0.636 v -1.84095 h 1.8411 c 0.34965,0 0.63585,-0.2862 0.63585,-0.63585 0,-0.3498 -0.2862,-0.636 -0.63585,-0.636 z"
        fill="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M 0.6422865,29.966642 H 16.09215 c 0.3498,0 0.636,-0.2862 0.636,-0.636 0,-0.34965 -0.2862,-0.63585 -0.636,-0.63585 H 1.278216 v -5.11605 c 0,-2.7918 1.771074,-5.2782 4.406994,-6.18765 1.70748,1.24635 3.682035,1.90455 5.73291,1.90455 2.16534,0 4.24173,-0.73125 6.00948,-2.1144 0.27675,-0.21615 0.32445,-0.6168 0.10815,-0.89355 -0.21615,-0.2766 -0.6168,-0.3243 -0.8934,-0.108 -1.54215,1.2051 -3.34824,1.8441 -5.22741,1.8441 -1.87917,0 -3.685215,-0.63585 -5.22735,-1.8441 -0.16851,-0.13365 -0.394275,-0.1686 -0.597765,-0.10185 l -0.31161,0.105 C 2.120835,17.270192 0,20.240042 0,23.578742 v 5.7519 c 0,0.3498 0.2861685,0.636 0.6359295,0.636"
        fill="currentColor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
