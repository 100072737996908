import styled from 'styled-components/macro';

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .icon-wrapper {
    width: 25px;
    height: 25px;
    margin-left: -4px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
