import { useContext, useLayoutEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';

// style
import * as S from './OrderNoteMobile.styled';

// icons
import { Check, Ex } from 'icons/dynamic';

// components
import ButtonSquare from 'components/Buttons/ButtonSquare';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useOrderNoteStatus } from 'hooks/useOrderNoteStatus';

const OrderNoteMobile = () => {
  const tr = useTranslate().use().global;
  const { isNewOrder } = useContext(OrderContext);
  const {
    inputRef,
    isNoteDisabled,
    isEditingNote,
    inputValue,
    setInputValue,
    setIsEditingNote,
    handleClickAway,
    handleSubmit,
    handleReset,
  } = useOrderNoteStatus();

  useLayoutEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.height = 'inherit';
      inputRef.current.style.height = inputRef.current.scrollHeight + 1 + 'px';
    }
  }, [inputRef.current?.scrollHeight, inputValue, isEditingNote]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <S.TextContainer>
        {!isNewOrder && isEditingNote && (
          <div className="buttons-container">
            <ButtonSquare
              onClick={handleReset}
              Icon={<Ex black width={10} height={10} />}
            />
            <ButtonSquare
              onClick={handleSubmit}
              Icon={<Check black width={10} height={10} />}
            />
          </div>
        )}
        <textarea
          disabled={isNoteDisabled}
          ref={inputRef}
          rows={1}
          placeholder={tr['note']}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => setIsEditingNote(true)}
        />
      </S.TextContainer>
    </ClickAwayListener>
  );
};

export default OrderNoteMobile;
