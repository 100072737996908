// style
import * as S from './BasicTableRecord.styled';

// icons
import { Arrow } from 'icons/dynamic';

export const BasicTableRecord = ({ columns, record, navigation, ...rest }) => (
  <S.BasicTableRecord {...rest}>
    {navigation && (
      <td
        className="navigation-column"
        onClick={() => navigation.arrow(record)}
      >
        <Arrow bold height={15} width={15} />
      </td>
    )}
    {columns.map((column) => (
      <td
        onDoubleClick={() => navigation.doubleClick(record)}
        key={column.id + '-' + record.id}
      >
        {record[column.id]}
      </td>
    ))}
  </S.BasicTableRecord>
);
