import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="m 26.371875,2.4972 h -3.6159 c -0.10695,-0.892515 -0.87105,-1.589406 -1.79115,-1.589406 h -1.33575 C 19.066725,0.3362205 18.287325,0 17.465025,0 h -0.34845 c -0.80685,0 -1.56795,0.3148215 -2.13645,0.8802825 -0.0092,0.00917 -0.01835,0.018342 -0.02751,0.0275115 h -1.33266 c -0.92307,0 -1.684155,0.693831 -1.791135,1.589406 h -3.6159 c -1.179825,0 -2.13957,0.95976 -2.13957,2.139585 V 7.08201 H 3.62811 c -1.179825,0 -2.139585,0.95976 -2.139585,2.139585 V 27.8604 c 0,1.1799 0.95976,2.1396 2.139585,2.1396 h 18.162015 c 1.17975,0 2.1396,-0.9597 2.1396,-2.1396 V 9.221595 c 0,-1.179825 -0.95985,-2.139585 -2.1396,-2.139585 H 8.51553 V 4.94244 h 3.322455 c 0.146715,0.84972 0.88641,1.497705 1.77891,1.497705 h 7.34793 c 0.8895,0 1.63215,-0.647985 1.77885,-1.497705 h 3.3225 V 23.2725 c 0,0.6756 0.5472,1.22265 1.22265,1.22265 0.67545,0 1.22265,-0.54705 1.22265,-1.22265 V 4.636785 c 0,-1.179825 -0.95985,-2.139585 -2.1396,-2.139585 z m -4.89045,25.0545 H 3.933765 V 9.524205 H 7.28373 c 0,0 0.00918,0 0.01224,0 0.00304,0 0.00916,0 0.012225,0 h 14.17623 V 27.5517 Z M 20.325975,3.994905 h -6.07026 v -0.64188 h 1.28991 c 0.4431,0 0.85275,-0.241455 1.0698,-0.62964 0.0183,-0.03363 0.04575,-0.067245 0.07935,-0.100875 0.06735,-0.06723 0.2109,-0.17727 0.4218,-0.17727 h 0.34845 c 0.25065,0 0.42795,0.15282 0.51045,0.296475 0.21705,0.37902 0.62355,0.61131 1.06065,0.61131 h 1.28985 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  ) : (
    <path
      d="m 22.076925,29.99685 c 0.87945,0 1.5933,-0.71385 1.5933,-1.5933 V 8.976105 c 0,-0.87945 -0.71385,-1.59321 -1.5933,-1.59321 H 7.60434 V 4.19649 c 0,-0.175245 0.143385,-0.31863 0.318645,-0.31863 h 4.39086 v 0.31545 c 0,0.68826 0.557625,1.245885 1.245885,1.245885 h 7.660095 c 0.68835,0 1.2459,-0.557625 1.2459,-1.245885 V 3.87786 h 4.39095 c 0.1752,0 0.3186,0.143385 0.3186,0.31863 v 19.42746 c 0,0.35055 0.2868,0.63735 0.6372,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 V 4.19649 c 0,-0.87945 -0.71385,-1.593195 -1.59315,-1.593195 h -4.39095 V 2.19225 c 0,-0.68826 -0.55755,-1.245888 -1.2459,-1.245888 h -1.67595 C 19.068975,0.353691 18.342525,0 17.571375,0 h -0.36315 c -0.67245,0 -1.3065,0.261285 -1.78125,0.736059 -0.0669,0.066915 -0.1338,0.137016 -0.19125,0.210303 h -1.6728 c -0.688275,0 -1.245885,0.557628 -1.245885,1.245888 V 2.603295 H 7.926165 C 7.04673,2.603295 6.33297,3.31704 6.33297,4.19649 v 3.186405 h -3.1896 c -0.879435,0 -1.593195,0.71376 -1.593195,1.59321 V 28.40685 C 1.550175,29.2863 2.263935,30 3.14337,30 H 22.076925 Z M 13.58841,2.22093 h 1.981965 c 0.23265,0 0.44295,-0.124275 0.55755,-0.3282 0.051,-0.08922 0.11475,-0.17526 0.19125,-0.24855 0.23895,-0.2389755 0.55755,-0.369618 0.88905,-0.369618 h 0.36315 c 0.4461,0 0.86355,0.238983 1.0866,0.627723 0.11475,0.19755 0.32505,0.318645 0.55125,0.318645 h 1.98195 v 1.9437 H 13.58841 Z M 2.82474,28.40355 V 8.976105 c 0,-0.17526 0.143385,-0.318645 0.31863,-0.318645 h 3.810945 c 0,0 0.00955,0 0.01275,0 0.00318,0 0.00955,0 0.01275,0 h 15.09711 c 0.17535,0 0.31875,0.143385 0.31875,0.318645 V 28.40685 c 0,0.1752 -0.1434,0.3186 -0.31875,0.3186 H 3.14337 c -0.175245,0 -0.31863,-0.1434 -0.31863,-0.3186 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
