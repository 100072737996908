import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteAppointment,
  resetAppointment,
  updateAppointment,
} from 'redux/actions/appointmentsAction';
import {
  deletePendingOrder,
  updatePendingOrder,
} from 'redux/actions/pendingOrdersAction';

// hooks
import { useServiceGetOrder } from 'services/orderService';

export const useModifyOrderHandler = ({ context, isPending }) => {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);
  const orderContext = useServiceGetOrder({
    id: orderId,
    queryParams: {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!orderId,
    },
    seachParams: {
      serviceListMode: 'both',
    },
  });

  const { isSuccess, data, isError, variables, error } = context;

  const orderData = JSON.stringify(orderContext.data);

  const updateOrderFromAPI = (id) => {
    setOrderId(id);
    if (orderId) orderContext.refetch();
  };

  const deleteOrder = (id) => {
    if (id) {
      const dispatchFunction = isPending
        ? deletePendingOrder
        : deleteAppointment;
      dispatch(dispatchFunction(id));
    }
  };

  useEffect(() => {
    if (!orderContext.isSuccess) return;
    const order = orderContext.data.order;

    if (!isPending) {
      dispatch(resetAppointment(order));
    }
  }, [orderContext.isSuccess, orderData]);

  useEffect(() => {
    if (!orderContext.isError) return;

    if (orderContext.error.response.status) {
      deleteOrder(orderId);
    }
  }, [orderContext.isError, orderId]);

  useEffect(() => {
    if (!isSuccess) return;

    const modifyData = data.data.order;

    const dispatchFunction = isPending ? updatePendingOrder : updateAppointment;
    dispatch(dispatchFunction(modifyData));
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    const { id } = variables;

    switch (error.response.status) {
      case 412:
        // Trying to update already modified entity
        updateOrderFromAPI(id);
        break;

      case 400:
        const errorObj = error.response.data.errors;
        const ordersCannotUnconfirm = errorObj.hasOwnProperty(
          'Orders_CannotUnconfirmPaidOrder',
        );
        const ordersCannotConfirm = errorObj.hasOwnProperty(
          'Orders_CannotConfirmAlreadyConfirmed',
        );

        if (ordersCannotUnconfirm || ordersCannotConfirm) {
          updateOrderFromAPI(id);
        }

        break;
      case 404:
        // Not found
        deleteOrder(id);
        break;

      case 402:
      case 403:
      case 429:
      default:
        break;
    }
  }, [isError]);

  return context;
};
