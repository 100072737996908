import { useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

// style
import * as S from './ServiceContents.styled';

// icons
import { SetSVG } from 'icons/dynamic';

// components
import { BaseCheckbox } from 'components/Inputs';
import { InputValueNumber } from '../InputValueNumber/InputValueNumber';
import { InputValueDuration } from '../InputValueDuration/InputValueDuration';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { minutesToHoursAndMinutes } from 'common/helpers/timeCalculations';
import { toSentenceCase } from 'common/helpers/stringOperations';
import { useNumber } from 'hooks/useNumber';
import { useSystemGetUnits } from 'services/systemService';

export const ServiceContents = ({
  contents,
  getDbItem,
  toggleActivity,
  updateCache,
  disabled,
  isDisabledByParent,
  hasValueChanged,
  ...props
}) => {
  const tr = useTranslate().use().global;

  const isGroup = !!contents.services;
  const isSet = contents.hasComp;
  const isMaterial = [null, undefined].includes(
    contents?.baseDurationInMinutes,
  );

  const type = contents.type;

  const dbItem = getDbItem(type, contents.id);

  const getActualPrice = useCallback(
    (object) =>
      object &&
      ('empUnitPriceWithVat' in object && object['empUnitPriceWithVat'] !== null
        ? object['empUnitPriceWithVat']
        : 'sectorUnitPriceWithVat' in object
          ? object['sectorUnitPriceWithVat']
          : undefined),
    [],
  );

  const getDefaultPrice = useCallback(
    (object) =>
      object &&
      ('sectorUnitPriceWithVat' in object
        ? object['sectorUnitPriceWithVat']
        : undefined),
    [],
  );

  const getActualDuration = useCallback((object, valueType) => {
    const propId = `emp${toSentenceCase(valueType)}InMinutes`;
    const basePropId = `base${toSentenceCase(valueType)}InMinutes`;
    return (
      object &&
      (propId in object && object[propId] !== null
        ? object[propId]
        : basePropId in object
          ? object[basePropId]
          : undefined)
    );
  }, []);

  const getDefaultDuration = useCallback((object, valueType) => {
    const basePropId = `base${toSentenceCase(valueType)}InMinutes`;
    return object && (basePropId in object ? object[basePropId] : undefined);
  }, []);

  const defaultUnitPriceWithVat = useMemo(
    () => getDefaultPrice(dbItem),
    [dbItem, getDefaultPrice],
  );

  const currentUnitPriceWithVat = useMemo(
    () => getActualPrice(contents),
    [contents, getActualPrice],
  );

  const defaultDurationString = useMemo(
    () =>
      minutesToHoursAndMinutes(getDefaultDuration(dbItem, 'duration'), {
        string: true,
      }),
    [dbItem, getDefaultDuration],
  );

  const currentDurationString = useMemo(
    () =>
      minutesToHoursAndMinutes(getActualDuration(contents, 'duration'), {
        string: true,
      }),
    [contents, getActualDuration],
  );

  const defaultBreakString = useMemo(
    () =>
      minutesToHoursAndMinutes(getDefaultDuration(dbItem, 'break'), {
        string: true,
      }),
    [dbItem, getDefaultDuration],
  );

  const currentBreakString = useMemo(
    () =>
      minutesToHoursAndMinutes(getActualDuration(contents, 'break'), {
        string: true,
      }),
    [contents, getActualDuration],
  );

  const isCustom = useCallback((object, valueType) => {
    const propId =
      valueType === 'price'
        ? 'empUnitPriceWithVat'
        : `emp${toSentenceCase(valueType)}InMinutes`;
    return object && propId in object && object[propId] !== null;
  }, []);

  const { currencySymbol, currencySymbolPlacement, formatPrice } = useNumber({
    price: true,
  });

  // units load handler
  const { isSuccess: isUnitsSuccess, getUnitsById } = useSystemGetUnits({
    queryParams: { enabled: isMaterial },
  });

  // useEffect(
  //   () => console.log({ defaultBreakString, currentBreakString }),
  //   [defaultBreakString, currentBreakString],
  // );

  return (
    <S.ServiceContents
      isGroup={isGroup}
      isSet={isSet}
      selectable={!disabled}
      {...props}
      isDisabledByParent={isDisabledByParent(contents.type, contents.id)}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   console.table({
      //     crContents: contents,
      //     dbContents: getDbItem(contents.type, contents.id),
      //   });
      // }}
    >
      <BaseCheckbox
        value={contents.isEmpAssigned}
        className="mark"
        onClick={(e) => {
          e.stopPropagation();
          !disabled && toggleActivity(type, contents.id);
        }}
      />

      <div className="details">
        <div className="title">
          {isSet && (
            <div
              className="icon"
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <>
                  {contents.composition?.map((service) => (
                    <div key={service.id}>{service.name}</div>
                  ))}
                </>,
              )}
            >
              <SetSVG sm />
            </div>
          )}

          {contents.name}
        </div>

        {!isGroup && (
          <>
            <InputValueNumber
              id={'price'}
              className={classNames('price', props.className)}
              value={currentUnitPriceWithVat}
              defaultValue={defaultUnitPriceWithVat}
              updateValue={(value, config) => {
                updateCache(
                  contents.type,
                  contents.id,
                  'empUnitPriceWithVat',
                  value,
                  // config,
                );
              }}
              disabled={disabled}
              tooltipTitle={tr['base-price']}
              tooltipContents={defaultUnitPriceWithVat}
              isCustom={isCustom(contents, 'price')}
              hasValueChanged={hasValueChanged(
                contents.type,
                contents.id,
                'empUnitPriceWithVat',
              )}
              decimalPlaces={4}
              currencySymbol={currencySymbol}
              currencySymbolPlacement={currencySymbolPlacement}
            />

            {isMaterial ? (
              <div className="amount">
                <span>{contents.unitVolume}</span>
                {isUnitsSuccess && getUnitsById(contents.unitId)?.label}
              </div>
            ) : (
              <>
                <InputValueDuration
                  id={'duration'}
                  className={classNames('duration', props.className)}
                  value={currentDurationString}
                  defaultValue={defaultDurationString}
                  updateValue={(value, config) => {
                    updateCache(
                      contents.type,
                      contents.id,
                      'empDurationInMinutes',
                      value,
                      // config,
                    );
                  }}
                  disabled={isMaterial || disabled}
                  tooltipTitle={tr['base-duration']}
                  tooltipContents={defaultDurationString}
                  isCustom={isCustom(contents, 'duration')}
                  hasValueChanged={hasValueChanged(
                    contents.type,
                    contents.id,
                    'empDurationInMinutes',
                  )}
                />

                <InputValueDuration
                  id={'break'}
                  className={classNames('break', props.className)}
                  value={currentBreakString}
                  defaultValue={defaultBreakString}
                  updateValue={(value, config) => {
                    updateCache(
                      contents.type,
                      contents.id,
                      'empBreakInMinutes',
                      value,
                      // config,
                    );
                  }}
                  disabled={isMaterial || disabled}
                  tooltipTitle={tr['base-break']}
                  tooltipContents={defaultBreakString}
                  isCustom={isCustom(contents, 'break')}
                  hasValueChanged={hasValueChanged(
                    contents.type,
                    contents.id,
                    'empBreakInMinutes',
                  )}
                />
              </>
            )}
          </>
        )}

        {!!contents.services && !isSet && (
          <div className="subgroup">
            {contents.services.map((service, index) => (
              <ServiceContents
                className={classNames(
                  service.hasComp ? 'set' : 'service',
                  index === contents.services?.length - 1 && 'last',
                )}
                key={service.id}
                contents={service}
                getDbItem={getDbItem}
                toggleActivity={toggleActivity}
                updateCache={updateCache}
                disabled={disabled}
                isDisabledByParent={isDisabledByParent}
                hasValueChanged={hasValueChanged}
              />
            ))}
          </div>
        )}
      </div>
    </S.ServiceContents>
  );
};

export default ServiceContents;
