import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Active = styled.div`
  background-color: ${colors.functionBarIcons};
  border-radius: 3px;
  bottom: -1.5px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  height: 10px;
  left: 50%;
  max-height: 3px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const FunctionBar = styled.nav`
  align-items: center;
  background-color: ${colors.bg_grey_1};
  box-shadow: 0px 2px 3px -2px rgb(0, 0, 0, 0.5);
  display: flex;
  height: ${(props) => (props.isVisible ? '70px' : '0')};
  justify-content: space-between;
  padding: 0 30px;
  position: sticky;
  top: ${(props) => `${70 - props.shiftBy}px`};
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 3;

  @media (max-width: 960px) {
    height: ${(props) => (props.isVisible ? '38px' : '0')};
    padding: 0 10px;
    top: ${(props) => `${54 - props.shiftBy}px`};
    width: 100vw;
    z-index: 1;
  }
`;

export const TabContainer = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  margin-right: ${(props) => props.functionsPresent && '20px'};
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    font-family: ${fontFamilies.family_Secondary};
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      cursor: pointer;
      margin: 0 15px;
      position: relative;
      white-space: nowrap;

      a {
        display: block;
        align-items: center;
        box-sizing: border-box;
        color: ${colors.functionBarIcons};
        font-size: 0.875rem;
        height: inherit;
        padding-bottom: 14px;
        text-decoration: none;
      }

      a.active {
        font-weight: 900;
      }

      a.active + ${Active} {
        display: flex;
      }
    }
  }

  @media (max-width: 960px) {
    font-size: 0.75rem;
    font-weight: ${(props) => props.shiftBy && 900};

    ul {
      li {
        margin: 0 10px;
        padding-bottom: 5px;

        a {
          font-size: 12px;
          padding-bottom: 0;
        }
      }
    }
  }
`;

export const FunctionContainer = styled.div``;
