const functionBarReducer = (
  state = { functionBarTabs: [], functionBarFunctions: [] },
  action,
) => {
  switch (action.type) {
    case 'FN_BAR_TABS_UPDATE':
      return {
        functionBarTabs: action.payload,
        functionBarFunctions: state.functionBarFunctions,
      };
    case 'FN_BAR_FUNCTIONS_UPDATE':
      return {
        functionBarTabs: state.functionBarTabs,
        functionBarFunctions: action.payload,
      };
    default:
      return state;
  }
};

export default functionBarReducer;
