import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

// style
import * as S from './PendingOrdersHeader.style';

// icons
import { FilterClearSVG } from 'icons/dynamic';

// components
import TheButton from 'components/Buttons/TheButton';

// contexts
import { UserContext } from 'context/UserContext';
import { ScheduleContext } from 'context/ScheduleContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';

export const PendingOrdersHeader = ({ showPendingOrders, hideClear }) => {
  const { locale } = useContext(UserContext);
  const { sectorData } = useContext(MainContext);
  const [dateTime, setDateTime] = useState();

  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const { pendingOrders, selectedProfessional, numberOfDays, personal } =
    useContext(ScheduleContext);

  useEffect(() => {
    if (sectorData?.settings?.timezoneIanaId) {
      setDateTime(
        DateTime.now()
          .setLocale(locale)
          .setZone(sectorData.settings.timezoneIanaId),
      );
    }
  }, [sectorData?.settings?.timezoneIanaId]);

  useEffect(() => {
    let timeInterval;
    if (!isMobile && sectorData?.settings?.timezoneIanaId) {
      timeInterval = setInterval(
        () =>
          setDateTime(
            DateTime.now()
              .setLocale(locale)
              .setZone(sectorData.settings.timezoneIanaId),
          ),
        10000,
      );
    }

    return () => clearInterval(timeInterval);
  }, [isMobile]);

  const clearFilter = () => selectedProfessional.set(null);

  return (
    <S.PendingOrdersHeader
      id="pending-orders-header"
      showPendingOrders={showPendingOrders}
      numberOfDays={numberOfDays}
    >
      <div className="filler">
        <div className="container">
          <span className="filler__time">
            {dateTime?.toLocaleString({ ...DateTime.TIME_SIMPLE }) || '-:-'}
          </span>
          <span className="filler__title">{tr['pending-orders']}</span>

          {selectedProfessional?.value?.id && !personal && (
            <div className="clear_button">
              <TheButton
                secondary
                flat
                action={clearFilter}
                title={tr['reset']}
                Icon={FilterClearSVG}
                outline
              />
            </div>
          )}
        </div>
      </div>
    </S.PendingOrdersHeader>
  );
};

export default PendingOrdersHeader;
