import * as S from './ScheduleDay.styled';

// Components
import DailyView from 'components/ScheduleCustom/DailyView/DailyView';
import Footer from 'components/Footer/Footer';
import { memo } from 'react';

const ScheduleDay = () => {
  return (
    <S.ScheduleDay>
      <DailyView />
      {/* <Footer content={2} /> */}
    </S.ScheduleDay>
  );
};

export default memo(ScheduleDay);
