import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled, { css } from 'styled-components/macro';

export const BasicTable = styled.div`
  table {
    width: 100%;
    margin: 0 auto;
    overflow: scroll;
    font-family: ${fontFamilies.family_Secondary};

    ${({ hasNavigation }) =>
      hasNavigation &&
      css`
        tbody tr td:nth-of-type(2),
        thead tr th:nth-of-type(2) {
          padding-left: 10px;
        }

        .navigation-column {
          width: 20px;
          padding: 0;
          min-width: unset;

          svg {
            display: flex;
          }
        }
      `}
  }

  .has-more {
    display: block;
    padding-top: 18px;
    color: ${colors.mandyRed};
    font-size: 0.875rem;
    font-family: ${fontFamilies.family_Secondary};
  }
`;
