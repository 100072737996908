// style
import * as S from './CategoryTree.styled';

// components
import { Category } from './Category/Category';
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const CategoryTree = ({
  categories,
  setCategories,
  activeItem,
  setActiveItem,
  serviceAction,
  groupAction,
  searchParams,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  sectorId,
  sectors,
  averagePrices,
  autoExpandCategories,
}) => {
  const tr = useTranslate().use().global;

  const setNewActiveItem = (context) =>
    setActiveItem({ id: 'new', type: context, selected: true });

  const addNewItemToServiceTree = (context) =>
    setCategories((old) => {
      const newCategories = structuredClone(old);

      newCategories.push({
        id: 'new',
        type: context,
        name: tr['new-' + context],
        isActive: true,
        isEmpty: true,
        assignedToSectorIds: [...(sectorId ? [Number(sectorId)] : [])],
      });

      return newCategories;
    });

  const createNewItem = (context) => {
    console.log('create new ' + context);
    if (activeItem?.id === 'new') {
      setActiveItem(undefined);
    }
    addNewItemToServiceTree(context);
    setNewActiveItem(context);
  };

  return (
    <S.CategoryTree>
      {!serviceAction && (
        <div className="button-container">
          <TheButton
            title={tr['new-category'] + ' +'}
            secondary
            inverted
            flat
            height={'25px'}
            action={() => createNewItem('category')}
          />
        </div>
      )}

      {categories?.map((category) => (
        <Category
          key={'category' + category.id}
          category={category}
          categories={categories}
          setCategories={setCategories}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          serviceAction={serviceAction}
          groupAction={groupAction}
          searchParams={searchParams}
          setConfirmDeleteButtons={setConfirmDeleteButtons}
          confirmDialogHandler={confirmDialogHandler}
          sectors={sectors}
          averagePrices={averagePrices}
          autoExpandCategories={autoExpandCategories}
        />
      )) || 'no data'}
    </S.CategoryTree>
  );
};
