export const userHeaders = [
  { id: 'registerDate', text: 'Registracijos data', type: 'calendar' },
  { id: 'username', text: 'Vartotojo vardas', type: 'search' },
  { id: 'avatar', text: 'Foto', type: 'avatar' },
  { id: 'email', text: 'Email', type: 'search', valueType: 'text' },
  {
    id: 'currency',
    text: 'Valiutos simbolis',
    type: 'switch',
    valueType: 'text',
    options: [
      { value: '€', label: '€' },
      { value: '$', label: '$' },
      { value: '£', label: '£' },
      { value: '₽', label: '₽' },
    ],
  },
  {
    id: 'language',
    text: 'Programos kalba',
    type: 'switch',
    valueType: 'text',
    options: [
      { value: 'en', label: 'English' },
      { value: 'lt', label: 'Lietuvių' },
      { value: 'ru', label: 'Русский' },
    ],
  },
  {
    id: 'country',
    text: 'Šalis',
    type: 'search',
    valueType: 'text',
  },
  {
    id: 'city',
    text: 'Miestas',
    type: 'search',
    valueType: 'text',
  },
  {
    id: 'vat',
    text: 'Standartinis PVM',
    type: 'switch',
    valueType: 'text',
    default: 21,
  },
  { id: 'vats', text: 'PVM tarifai', type: 'array', valueType: 'number' },
  {
    id: 'professions',
    text: 'Profesijos',
    type: 'array',
    valueType: 'text',
  },
  {
    id: 'priorities',
    text: 'Prioritetai',
    type: 'select',
    valueType: 'text',
  },
  {
    id: 'timezone',
    text: 'Laiko juosta',
    type: 'select',
    valueType: 'text',
  },
  {
    id: 'weekStart',
    text: 'Savaitės pradžia',
    type: 'switch',
    valueType: 'text',
    options: [
      { value: 0, label: 'Pirmadienis' },
      { value: 6, label: 'Sekmadienis' },
    ],
  },
];
