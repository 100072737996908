import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from 'react-advanced-click-away';

// style
import * as S from './ContextMenu.styled';
import { useEscapeKey } from 'hooks/useEscapeKey';

export const ContextMenu = (props) => {
  const { setShowMenu, functions } = props;

  const menuRef = useRef();

  const [xPos, setXPos] = useState(props.xPos);
  const [yPos, setYPos] = useState(props.yPos);
  const [visibility, setVisibility] = useState('hidden');

  useEffect(() => {
    if (menuRef.current) {
      const menuWidth = menuRef.current.offsetWidth;
      const menuHeight = menuRef.current.offsetHeight;

      if (xPos + menuWidth > window.innerWidth) {
        setXPos(xPos - menuWidth);
      } else {
        setXPos(xPos);
      }
      if (yPos + menuHeight > window.innerHeight) {
        setYPos(yPos - menuHeight);
      } else {
        setYPos(yPos);
      }

      setVisibility('visible');
    }
  }, []);

  // close menu also with Esc key
  useEscapeKey(() => setShowMenu(false));

  return (
    <ClickAwayListener
      onClickAway={() => setShowMenu(false)}
      // mouseEvent="mousedown"
    >
      <S.ContextMenu
        ref={menuRef}
        xPos={xPos}
        yPos={yPos}
        visibility={visibility}
        className="context-menu"
      >
        {functions.map(
          (fn) =>
            fn.hide() && (
              <S.ContextMenuItem
                key={fn.id}
                onClick={() => {
                  fn.action();
                  setShowMenu(false);
                }}
                color={fn.color}
              >
                {fn.icon}
                {fn.title}
              </S.ContextMenuItem>
            ),
        )}
      </S.ContextMenu>
    </ClickAwayListener>
  );
};

export default ContextMenu;
