// style
import * as S from './AnnouncementsForm.styled';

// icons
import { MessageSVG, PaperPlaneSVG } from 'icons/dynamic';

// components
import Avatar from 'components/Avatar/Avatar';
import TheButton from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

const AnnouncementsForm = () => {
  const tr = useTranslate().use().global;

  return (
    <S.AnnouncementsForm>
      <h2>{tr['the-announcements-and-suggestions']}</h2>
      <p>{tr['the-announcements-and-suggestions-p']}</p>
      <div className="user-details__container">
        <div className="user-details__avatar">
          <Avatar />
        </div>
        <div className="user-details__info">
          <span>Vardenis Pavardenis</span>
          <span>UAB Įmonės pavadinimas</span>
        </div>
      </div>
      <div className="user-details__comment">
        <div className="text-area__icon">
          <MessageSVG />
        </div>
        <textarea placeholder={tr['message-text']} />
      </div>
      <div className="user-details__add-photo">
        <span>{tr['add-an-image']}</span>
      </div>
      <div className="user-details__send-button">
        <TheButton
          icon={<PaperPlaneSVG bold color={colors.bg_Secondary} />}
          title={tr['send']}
          primary
        />
      </div>
    </S.AnnouncementsForm>
  );
};

export default AnnouncementsForm;
