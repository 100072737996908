import { PageHeader } from 'components/PageHeader/PageHeader.styled';
import styled from 'styled-components/macro';

export const MainLayout = styled.div`
  /* height: calc(100vh - 70px); */
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    height: calc(100vh - 54px);
  }

  > div:not(.footer) {
    flex: 1 0 auto;
  }

  ${PageHeader} > h1 {
    margin-left: 0;
  }
`;

export const Container = styled.div`
  nav + & {
    margin: 40px 40px 0;

    @media screen and (max-width: 960px) {
      margin: 10px 20px 80px;
    }
  }

  @media screen and (max-width: 960px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;
