import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const ColumnSkeleton = styled.div`
  width: 200px;

  .skeleton_body {
    &--cell {
      border: 1px solid white;
      height: 20px;
      background-color: ${colors.bg_grey_5};
    }
  }
`;
