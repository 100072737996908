import { Loader } from 'components/Loader/Loader';

const Suppliers = () => {
  // const spreadButton = document.getElementById('myBtn');
  // spreadButton.addEventListener('click', () => {
  //   alert('hello');
  // });
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Suppliers;
