import { useContext, useEffect, useState } from 'react';

// style
import * as S from './LanguageSelector.styled';

// components
import { ListSelector } from 'components/ListSelector/ListSelector';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useSystemGetCultureOptions } from 'services/systemService';

export const LanguageSelector = ({ setStage, setNext, auto }) => {
  const { user, setLanguage } = useContext(UserContext);

  const { data: locales, isSuccess: isLocalesSuccess } =
    useSystemGetCultureOptions({
      labelFormat: ['id', 'nativeName'],
      searchParams: { popular: true },
    });

  const [lng, setLng] = useState();

  // set function for 'Next' button
  useEffect(
    () =>
      setNext(() => () => {
        console.log('LanguageSelector');
        setLanguage(lng?.value);
        setStage(auto ? 'fill-basic-info-auto' : 'fill-basic-info');
      }),
    [setStage, lng, setNext, setLanguage, auto],
  );

  useEffect(() => {
    isLocalesSuccess && console.log({ locales });
  }, [locales, isLocalesSuccess]);

  return (
    <S.LanguageSelector>
      <ListSelector
        state={{
          current: lng,
          options: [
            { value: 'en', label: 'English' },
            { value: 'lt', label: 'Lietuvių' },
            { value: 'ru', label: 'Русский' },
          ],
          // default: 'lt',
          default: user?.settings?.uiLanguage,
          setValue: setLng,
        }}
        type={'radio'}
      />
    </S.LanguageSelector>
  );
};

export default LanguageSelector;
