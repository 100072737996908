import * as S from '../SVG.styled';
export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <g transform="scale(0.30568576)">
      <path d="M 85.87,8.51 48.31,59.75 31.12,45.95 c -1.72,-1.38 -4.24,-1.11 -5.62,0.62 -1.38,1.72 -1.11,4.24 0.62,5.62 l 20.45,16.42 c 0.71,0.57 1.6,0.88 2.5,0.88 0.17,0 0.35,-0.01 0.52,-0.03 1.08,-0.14 2.06,-0.72 2.7,-1.6 L 92.33,13.24 C 93.64,11.46 93.25,8.95 91.47,7.65 89.69,6.35 87.19,6.73 85.88,8.51 Z" />
      <path d="m 87.49,25.16 c -1.97,1 -2.76,3.41 -1.76,5.38 2.32,4.58 3.72,9.46 4.2,14.53 h -3.92 c -2.21,0 -4,1.79 -4,4 0,2.21 1.79,4 4,4 h 3.92 C 88.04,72.53 72.52,88.05 53.06,89.94 v -3.92 c 0,-2.21 -1.79,-4 -4,-4 -2.21,0 -4,1.79 -4,4 v 3.92 C 25.6,88.05 10.08,72.53 8.19,53.07 h 3.92 c 2.21,0 4,-1.79 4,-4 0,-2.21 -1.79,-4 -4,-4 H 8.19 C 10.09,25.61 25.61,10.09 45.07,8.2 v 3.92 c 0,2.21 1.79,4 4,4 2.21,0 4,-1.79 4,-4 V 8.2 c 6.95,0.67 13.57,3.08 19.35,7.08 1.82,1.26 4.31,0.8 5.57,-1.01 C 79.25,12.45 78.79,9.96 76.98,8.7 68.75,3.01 59.1,0 49.07,0 22.01,0 0,22.01 0,49.07 c 0,27.06 22.01,49.07 49.07,49.07 27.06,0 49.07,-22.01 49.07,-49.07 0,-7.79 -1.77,-15.24 -5.27,-22.15 -1,-1.97 -3.41,-2.76 -5.38,-1.76 z" />
    </g>
  ) : (
    <g transform="scale(0.31867431)">
      <path d="m 27.88,45.51 c -0.86,-0.69 -2.12,-0.55 -2.81,0.31 -0.69,0.86 -0.55,2.12 0.31,2.81 l 20.45,16.42 c 0.36,0.29 0.8,0.44 1.25,0.44 0.09,0 0.17,0 0.26,-0.02 0.54,-0.07 1.03,-0.36 1.35,-0.8 L 88.71,10.06 C 89.36,9.17 89.17,7.92 88.28,7.26 87.39,6.61 86.14,6.8 85.48,7.69 L 46.69,60.61 27.87,45.5 Z" />
      <path d="m 47.07,94.14 c 25.96,0 47.07,-21.12 47.07,-47.07 0,-7.47 -1.7,-14.62 -5.06,-21.24 -0.5,-0.98 -1.7,-1.38 -2.69,-0.88 -0.99,0.5 -1.38,1.7 -0.88,2.69 2.77,5.46 4.29,11.32 4.57,17.44 h -6.06 c -1.1,0 -2,0.9 -2,2 0,1.1 0.9,2 2,2 h 6.07 c -1.02,22.16 -18.86,40 -41.02,41.02 v -6.07 c 0,-1.1 -0.9,-2 -2,-2 -1.1,0 -2,0.9 -2,2 V 90.1 C 22.91,89.08 5.07,71.24 4.05,49.08 h 6.07 c 1.1,0 2,-0.9 2,-2 0,-1.1 -0.9,-2 -2,-2 H 4.05 C 5.07,22.91 22.91,5.07 45.07,4.05 v 6.07 c 0,1.1 0.9,2 2,2 1.1,0 2,-0.9 2,-2 V 4.05 c 8.08,0.37 15.82,2.97 22.49,7.58 0.91,0.63 2.15,0.4 2.78,-0.51 0.63,-0.91 0.4,-2.15 -0.51,-2.78 C 65.95,2.88 56.69,0 47.07,0 21.12,0 0,21.12 0,47.07 0,73.02 21.12,94.14 47.07,94.14 Z" />
    </g>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
