import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const Client = styled.div`
  @media screen and (max-width: 960px) {
    background-color: ${colors.bg_Secondary};
    width: 100vw;
    height: 100%;
  }
`;

export const Container = styled.div`
  margin: 40px 40px 0;

  @media screen and (max-width: 960px) {
    margin: 10px 10px 80px;
  }
`;
