import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const AuthProviders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    align-items: center;
    background-color: ${colors.button_Primary};
    border: 1px solid ${colors.bg_grey_4};
    border-radius: 35px;
    color: ${colors.bg_Button_Secondary};
    display: flex;
    font-size: 0.875rem;
    gap: 10px;
    height: 35px;
    justify-content: start;
    padding-inline: 10px;

    @media screen and (max-width: 960px) {
      /* width: 275px; */
    }

    :hover {
      border-color: ${colors.bg_grey_3};
    }

    :active {
      box-shadow: inset 0 0 3px ${colors.bg_grey_3};
    }
  }

  button span {
    align-self: center;
    flex: 1;
  }
`;
