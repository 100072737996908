// components
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { Footer } from 'components/Footer/Footer';

// hooks and helpers
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useNavigate } from 'react-router';

export const TabTemplate = ({
  name,
  view,
  renderInParent,
  state,
  setValue,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)');

  const View = view;
  // console.log({ name });

  return (
    view &&
    (isMobile && !renderInParent ? (
      <ContentLayout>
        <DialogLayout
          headerText={name}
          getBack={() => navigate('../')}
          // getBack={() => navigate(-1)}
          // footer={<Footer content={2} />}
          Child={view}
          modal
        />
      </ContentLayout>
    ) : (
      <View title={name} state={state} setValue={setValue} />
    ))
  );
};

export default TabTemplate;
