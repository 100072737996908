import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 16.0077,18.34575 H 8.46789 c -0.690555,0 -1.249875,0.5583 -1.249875,1.2477 0,0.6894 0.55932,1.24785 1.249875,1.24785 h 7.53981 c 0.6906,0 1.24995,-0.55845 1.24995,-1.24785 0,-0.6894 -0.55935,-1.2477 -1.24995,-1.2477 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 13.09866,21.7395 H 8.46789 c -0.690555,0 -1.249875,0.55845 -1.249875,1.24785 0,0.6894 0.55932,1.24785 1.249875,1.24785 h 4.63077 c 0.690555,0 1.24986,-0.55845 1.24986,-1.24785 0,-0.6894 -0.559305,-1.24785 -1.24986,-1.24785 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 28.74705,7.380675 h -3.1653 V 1.24779 C 25.58175,0.5583855 25.0224,0 24.3318,0 H 5.71503 C 5.024475,0 4.465155,0.5583855 4.465155,1.24779 V 7.380675 H 1.2498705 C 0.559317,7.380675 0,7.939065 0,8.628465 V 23.3898 c 0,0.6894 0.559317,1.24785 1.2498705,1.24785 H 4.096455 v 4.1145 C 4.096455,29.44155 4.65576,30 5.346315,30 H 24.813 c 0.6906,0 1.24995,-0.55845 1.24995,-1.24785 v -4.1145 H 28.7502 C 29.44065,24.63765 30,24.0792 30,23.3898 V 8.628465 c 0,-0.6894 -0.55935,-1.24779 -1.2498,-1.24779 M 6.964905,2.49558 H 23.07885 V 7.380675 H 6.964905 Z M 23.56005,27.50445 H 6.59307 V 17.2569 H 23.56005 Z M 27.4971,22.1421 h -1.4373 v -6.1329 c 0,-0.6894 -0.55935,-1.247835 -1.24995,-1.247835 H 5.343195 c -0.690555,0 -1.249875,0.558435 -1.249875,1.247835 v 6.1329 H 2.496615 V 9.876255 H 27.49395 V 22.1421 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 5.351535,18.29837 v 7.02795 c 0,0.2631 0.12684,0.51375 0.33615,0.6723 L 10.743,29.75987 c 0.149055,0.111 0.32346,0.16815 0.497895,0.16815 0.126855,0 0.256905,-0.0285 0.377415,-0.0888 0.285435,-0.14265 0.46302,-0.4281 0.46302,-0.7485 V 18.29837 l 5.14092,-6.536385 C 17.4315,11.52095 17.4855,11.181605 17.3586,10.883495 17.2254,10.57586 16.92405,10.37606 16.5879,10.37606 H 0.8385795 c -0.336174,0 -0.6374775,0.1998 -0.7706787,0.507435 -0.1268577,0.294945 -0.07292617,0.637455 0.1363887,0.87849 L 5.34519,18.29837 Z M 15.7032,11.64464 10.9491,17.68622 c -0.0888,0.111 -0.13635,0.2505 -0.13635,0.3933 v 10.1517 l -4.192635,-3.1206 v -7.0311 c 0,-0.1428 -0.047595,-0.27915 -0.136395,-0.3933 L 1.72974,11.64464 H 15.6999 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 12.392145,8.1559848 c 0.34251,0.01269 0.646965,-0.25371 0.66282,-0.602565 0.16809,-3.485415 2.610135,-6.212862 5.562735,-6.212862 2.9526,0 5.5722,2.958957 5.5722,6.593427 0,3.6344852 -2.49915,6.5902652 -5.5722,6.5902652 -0.1269,0 -0.2505,-0.0063 -0.37425,-0.01586 -0.3489,-0.0222 -0.65325,0.23469 -0.6819,0.583575 -0.02535,0.3489 0.23475,0.65325 0.58365,0.6819 0.1554,0.0126 0.31395,0.0189 0.4725,0.0189 3.77085,0 6.84075,-3.52659 6.84075,-7.8587852 0,-4.33218 -3.0699,-7.8620046 -6.84075,-7.8620046 -3.77085,0 -6.631515,3.2602446 -6.831315,7.4211846 -0.01585,0.348855 0.253725,0.646965 0.60258,0.66282"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 24.73545,16.17662 -0.30765,-0.1047 c -0.20295,-0.0666 -0.42495,-0.03165 -0.59625,0.10155 -1.5381,1.20195 -3.3396,1.83945 -5.21385,1.83945 -0.89115,0 -1.7697,-0.1428 -2.61645,-0.42825 -0.333,-0.111 -0.69135,0.0666 -0.80235,0.3996 -0.111,0.333 0.0666,0.6915 0.3996,0.8025 0.9768,0.32655 1.9917,0.4947 3.0192,0.4947 2.04555,0 4.0182,-0.65655 5.71815,-1.8966 2.62905,0.9102 4.3956,3.3903 4.3956,6.17175 v 5.10285 H 14.92929 c -0.352035,0 -0.63429,0.2853 -0.63429,0.6342 0,0.3489 0.282255,0.63435 0.63429,0.63435 H 29.36565 C 29.7177,29.92802 30,29.64257 30,29.29367 v -5.73705 c 0,-3.32685 -2.1153,-6.2922 -5.26455,-7.37685"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
