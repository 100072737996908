import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { dropdownRollIn } from 'common/styles/animations';

export const FunctionBarItem = styled.div`
  align-items: center;
  border-radius: 0.625rem;
  color: ${({ isDisabled }) =>
    isDisabled ? colors.canceled : colors.text_Secondary};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: ${(props) => (props.showOnDesktop ? 'flex' : 'none')};
  min-height: 60px;
  justify-content: center;
  /* margin: 0.2rem 0; */
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 60px;
  background-color: ${colors.bg_grey_1};

  #context-tree {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }

  @media screen and (max-width: 960px) {
    align-items: unset;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);

    border-radius: ${(props) =>
      props.showDropdown && props.hasDropdown ? 0.625 : 1.25}rem;

    color: ${(props) => props.theme.color};
    display: ${(props) => (props.showOnMobile ? 'flex' : 'none')};
    font-weight: 600;
    flex-direction: column;
    min-height: 35px;
    padding: 10px;
    width: auto;

    > div:first-child {
      padding-inline: 10px;

      color: ${(props) => props.color};
    }

    :not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid ${colors.table_borders_Primary};
    margin-top: 10px;

    animation: ${dropdownRollIn} 0.2s ease-in-out;
    transform-origin: top;
  }

  .dropdown__item {
    /* min-height: 35px; */
    border-radius: 17.5px;
    padding: 5px 10px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 500;
    width: 100%;
    line-height: 1.25;
    align-items: center;

    display: flex;

    :first-child {
      margin-top: 5px;
    }

    :focus,
    :active,
    &.active {
      background-color: ${colors.bg_grey_2};
    }

    > span > svg {
      @media screen and (max-width: 960px) {
        /* flex: 0 0 16px; */
        height: 16px;
        margin-right: 10px;
        width: 16px;
      }
    }
  }

  // Wrapperd in media screen
  @media screen and (min-width: 961px) {
    :hover {
      background-color: ${({ isDisabled }) =>
        isDisabled ? 'transparent' : colors.bg_grey_2};

      :not(:active):not(.active):before {
        display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};

        @media screen and (max-width: 960px) {
          display: none;
        }
      }
    }
  }

  :active,
  &.active {
    background-color: ${({ isDisabled }) =>
      isDisabled ? 'transparent' : colors.bg_Primary};
    color: ${({ isDisabled }) =>
      isDisabled ? colors.canceled : colors.text_Primary};

    @media screen and (max-width: 960px) {
      /* background-color: ${colors.text_Primary};
      color: ${colors.bg_Primary}; */

      background-color: ${(props) =>
        props.hasDropdown ? 'white' : colors.bg_Primary};
      color: ${({ hasDropdown, isDisabled }) =>
        isDisabled
          ? colors.canceled
          : hasDropdown
            ? colors.bg_Primary
            : colors.text_Primary};
    }
  }

  ::before {
    background-color: ${colors.bg_Primary};
    border-radius: 1rem;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.31);
    color: ${colors.text_Primary};
    content: attr(data-text);
    display: none;
    font-size: 12px;
    font-weight: bold;
    height: 14px;
    position: absolute;
    padding: 3px 15px 5px 15px;
    right: 100%;
    text-align: center;
    top: 3px;
    transition: 0.2s ease-in-out;
    width: max-content;
  }

  > div {
    @media screen and (max-width: 960px) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    > span {
      display: none;

      @media screen and (max-width: 960px) {
        display: block;
        font-family: ${fontFamilies.family_Primary};
        font-size: 1rem;

        white-space: nowrap;

        :first-of-type {
          flex-basis: 100%;
        }

        :active {
          /* color: white; */
        }

        svg {
          margin-right: 2px;
          display: block;
          transition: all 0.3s ease-in-out;
          transform: ${(props) =>
            props.showDropdown ? 'rotate(270deg)' : 'rotate(90deg)'};
        }
      }
    }

    > svg {
      height: 30px;
      width: 30px;

      @media screen and (max-width: 960px) {
        height: 16px;
        flex: 0 0 16px;
        margin-right: 10px;
      }
    }
  }
`;
