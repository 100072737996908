import { useCallback } from 'react';
import { components } from 'react-select';

// style
import * as S from './FilterButton.styled';

// icons
import { Arrow } from 'icons/dynamic';

// hooks
import useTranslate from 'hooks/useTranslate';

export const FilterButton = ({
  children,
  onClick,
  onClear,
  hasValueSelected,
}) => {
  const tr = useTranslate().use().global;

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      onClear();
    },
    [onClear],
  );

  return (
    <S.FilterButton hasValueSelected={hasValueSelected}>
      <div className="content" onClick={onClick}>
        {hasValueSelected ? (
          <>
            <div className="value">{children}</div>
            <div className="clear-icon" onClick={handleClear}>
              <components.ClearIndicator
                getStyles={() => ({ display: 'flex' })}
                getClassNames={() => null}
                cx={() => null}
              />
            </div>
          </>
        ) : (
          tr['select']
        )}
      </div>
      <div className="chevron" onClick={onClick}>
        <Arrow black width={18} height={18} />
      </div>
    </S.FilterButton>
  );
};
