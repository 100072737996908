import * as S from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <S.StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : 30}
    height={height ? height : 30}
    version="1.1"
    viewBox="0 0 30 30"
    color={color}
    {...rest}
  >
    <>
      <path
        d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <circle cx="15" cy="15" r="9" fill="currentcolor" />
    </>
  </S.StyledSVG>
);
