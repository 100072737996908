// style
import * as S from './ContactUs.styled';

// icons
import { Calendar, Clock, EnvelopeSVG, SmartphoneSVG } from 'icons/dynamic';

// images
import { GirlSitting } from 'images';

// components
import { PageHeader } from 'components/PageHeader/PageHeader';

// hooks
import useTranslate from 'hooks/useTranslate';

export const ContactUs = ({ setStage, setNext }) => {
  const tr = useTranslate().use().global;

  // useEffect(
  //   () => console.log({ user, state, options }),
  //   [user, state, options]
  // );

  return (
    <S.ContactUs>
      <S.RootContainer>
        <PageHeader />

        <S.ScrollableContainer>
          <S.Container>
            <div className="info">
              <section>
                <h2 className="info__header">
                  {tr['servepro-customer-service']}
                </h2>

                <div className="info__description">
                  <div>
                    <SmartphoneSVG />
                    +370 682 09111
                  </div>

                  <div>
                    <EnvelopeSVG />
                    <a href="mailto:info@serve.lt">info@serve.lt</a>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="info__header">{tr['opening-hours']}</h2>

                <div className="info__description">
                  <div>
                    <Calendar />
                    {tr['working-days']}: I - V
                  </div>

                  <div>
                    <Clock />
                    {tr['working-hours']}: 08:00 - 17:00
                  </div>
                </div>
              </section>
            </div>

            <div className="picture">
              <GirlSitting />
            </div>
          </S.Container>
        </S.ScrollableContainer>
      </S.RootContainer>

      {/* {!isMobile && <Footer content={2} />} */}
    </S.ContactUs>
  );
};

export default ContactUs;
