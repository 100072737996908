import { useNavigate } from 'react-router';

// style
import * as S from './SimpleHeader.styled';

// icons
import { ArrowBack } from 'icons/dynamic';

// images
import { ServeProLogo } from 'images';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

const SimpleHeader = () => {
  const tr = useTranslate().use().global;
  const navigate = useNavigate();

  return (
    <S.SimpleHeader>
      <div className="logo__container">
        <ServeProLogo color={colors.header_logo_and_text_Primary} />
      </div>
      <div
        className="nav__container"
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="nav__back-logo">
          <ArrowBack />
        </div>
        <div className="nav__back-text">{tr['back']}</div>
      </div>
    </S.SimpleHeader>
  );
};

export default SimpleHeader;
