import styled from 'styled-components/macro';

export const OrderCardMobile = styled.div`
  border-radius: 5px;
  overflow: hidden;
  background-color: white;

  .selectsContainer {
    padding: 20px 10px;

    > div:not(:first-child) {
      padding-top: 20px;
    }
  }

  .selectsContainer_products {
    > div:not(:first-child):not(:last-child) {
      margin-top: 10px;
    }
  }

  .addMoreServices {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 -20px 0;

    div {
      margin-right: 10px;
    }
  }
`;
