import * as S from '../SVG.styled';

export default ({ color, width, height, ...rest }) => {
  const content = (
    <>
      <path
        d="M 14.999985,0 C 6.730275,0 0,6.730275 0,14.999985 0,23.26965 6.730275,30 14.999985,30 23.26965,30 30,23.26965 30,14.999985 30,6.730275 23.27265,0 14.999985,0 Z m 0,26.7372 C 8.527785,26.7372 3.2628,21.4722 3.2628,14.999985 3.2628,8.527785 8.527785,3.2628 14.999985,3.2628 21.4722,3.2628 26.7372,8.527785 26.7372,14.999985 26.7372,21.4722 21.4722,26.7372 14.999985,26.7372 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 18.88575,6.69186 c -0.9018,0 -1.6314,0.72969 -1.6314,1.6314 v 7.51329 L 12.508455,8.00292 C 12.128775,7.377045 11.37834,7.080435 10.67535,7.2762 9.969405,7.471965 9.48294,8.11563 9.48294,8.848275 V 21.67695 c 0,0.9018 0.729675,1.6314 1.6314,1.6314 0.901725,0 1.6314,-0.7296 1.6314,-1.6314 v -6.98826 l 4.74591,7.83366 c 0.29955,0.49545 0.8364,0.786 1.3941,0.786 0.14535,0 0.2937,-0.0207 0.43905,-0.05925 0.7059,-0.19575 1.19235,-0.8394 1.19235,-1.57215 V 8.32326 c 0,-0.90171 -0.72975,-1.6314 -1.6314,-1.6314 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
