import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const Breadcrumbs = styled.div`
  border-bottom: 1px solid ${colors.table_borders_Primary};
  color: ${colors.bg_grey_3};
  font-family: ${fontFamilies.family_Secondary};
  font-size: 1.25rem;
  padding-bottom: 5px;

  @media (max-width: 960px) {
    font-size: 0.875rem;
  }

  .last {
    font-weight: bold;
  }
`;
