import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { dropdownRollIn } from 'common/styles/animations';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';
import { InputField, Menu } from 'components/InputField/InputField.styled';

export const FilterCell = styled.th`
  min-width: ${({ minWidth }) => minWidth && minWidth + 'px !important'};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth + 'px !important'};
  width: ${({ width }) => width && width + 'px !important'};
  text-align: left;

  .date-range {
    padding-bottom: 2px;
  }

  .input-range {
    ${BaseInput} {
      .input-wrapper,
      input {
        min-height: 20.5px;
        max-height: 20.5px;
      }
    }
  }

  ${BaseInput} {
    .input-wrapper {
      border: none;
      background-color: transparent;

      input {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        color: #000;
        /* -webkit-text-fill-color: ${colors.filterGrey}; */
      }

      .clear-icon {
        display: flex;
        align-self: center;
        padding: 4px !important;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  ${InputField} {
    .select {
      height: calc(100% + 1px);
      margin: 0 -5px 0;
      min-width: max-content;
      width: calc(100% + 10.5px);
      text-align: left;

      span {
        width: 0;
      }
    }

    .select__control {
      border: none;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;

      &--is-focused {
        font-weight: bold;

        .select__dropdown-indicator {
          color: ${colors.text_Secondary};
        }
      }
    }

    .select__placeholder {
      color: ${colors.filterGrey};
    }

    .select__input-container {
      cursor: text;

      .select__input {
        grid-area: unset !important;
      }
    }

    .select__value-container {
      padding: 2px 5px;
      min-height: unset;
    }

    .select__single-value {
      color: ${colors.text_Secondary};
    }

    .regular-select {
      .select__dropdown-indicator {
        svg {
          width: 10px;
          stroke: currentColor;

          path {
            stroke-width: 1;
          }
        }
      }
    }

    .select__indicator {
      padding: 4px !important;
      cursor: pointer;
      width: 24px;
      height: 24px;

      &:hover {
        color: ${colors.text_Secondary};
      }
    }

    .select__menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 16%);
      margin-top: 1px;
      border: none;
      transform-origin: top;
      animation: ${dropdownRollIn} 0.2s ease-in-out;
    }

    .half-height {
      .select {
        &__control {
          min-height: 20.5px;
          max-height: 20.5px;
        }

        &__value-container {
          padding-top: 0;
          padding-bottom: 0;
        }

        &__input-container {
          margin-top: 1px;
          margin-bottom: 1px;
        }

        &__indicator {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .date-picker {
      .select {
        &__menu {
          position: absolute;
          height: 0;

          > div {
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            left: 50%;
            overflow: visible;
            position: absolute;
            transform: translateX(-50%);
            width: 240px;
            border-radius: 15px;
            padding: 20px 20px 20px;
          }
        }
      }
    }

    ${Menu} {
      min-width: ${(props) =>
        props.minWidth && props.minWidth + 'px !important'};
      max-width: ${(props) => props.width && props.maxWidth + 'px !important'};
      width: ${(props) => props.width && props.width + 'px !important'};

      .select__menu-list {
        border: none;
        background-color: ${colors.bg_grey_1};
        border-radius: 0 0 5px 5px;

        ::-webkit-scrollbar {
          display: block;
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 4px;
        }

        .select__option {
          overflow-x: hidden;
          text-overflow: ellipsis;
          padding: 8px 5px;
          color: ${colors.text_Secondary};
          border-radius: 0;
          margin: 0;
          font-size: 12px;

          :active,
          &--is-focused {
            background-color: ${colors.bg_grey_2};
          }

          :active,
          &--is-selected {
            font-weight: bold;
          }
        }
      }
    }
  }
`;

// export const selectMenuStyles = ({ minWidth, maxWidth, width }) => ({
//   menu: {
//     minWidth: minWidth ? minWidth + 'px !important' : '',
//     maxWidth: maxWidth ? width + 'px !important' : '',
//     width: width ? width + 'px !important' : '',
//     '.select__menu-list': {
//       border: 'none',
//       backgroundColor: colors.bg_grey_1,
//       borderRadius: '0 0 5px 5px',
//       '::-webkit-scrollbar': {
//         display: 'block',
//         width: '8px',
//       },
//       '::-webkit-scrollbar-track': {
//         borderRadius: '4px',
//       },
//       '.select__option': {
//         overflowX: 'hidden',
//         textOverflow: 'ellipsis',
//         padding: '8px 5px',
//         color: colors.text_Secondary,
//         borderRadius: 0,
//         margin: 0,
//         fontSize: '12px',
//         ':active, &--is-focused': {
//           backgroundColor: colors.bg_grey_2,
//         },
//         ':active, &--is-selected': {
//           fontWeight: 'bold',
//         },
//       },
//     },
//   },
// });
