import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

import { Links } from 'pages/Settings/SettingsTemplate.styled';
export { Links };

export const WorkplaceSettings = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.family_Secondary};
  position: relative;
`;

export const Container = styled.div`
  /* height: 3000px; */
  display: flex;

  @media screen and (max-width: 960px) {
    .link {
      padding: 0;

      .chevron {
        margin-left: auto;
        margin-right: 3px;
      }
    }
  }
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  margin: 10px;

  button {
    height: 30px;
    margin-right: 10px;
    width: 30px;
  }
`;
