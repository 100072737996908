import { useRef, useState } from 'react';

export const useOnHoverContextMenu = (delay = 500) => {
  const [isHovered, setIsHovered] = useState();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState();
  const openRef = useRef();

  const showContextMenuIcon = () => {
    isContextMenuOpen && clearTimeout(openRef.current);
    setIsHovered(true);
  };

  const hideContextMenuIcon = () => {
    setIsHovered(false);
    setIsContextMenuOpen(false);
  };

  const debounceHideContextMenuIcon = () => {
    if (isContextMenuOpen) {
      openRef.current = setTimeout(hideContextMenuIcon, delay);
    } else hideContextMenuIcon();
  };

  return {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  };
};
