const { Component } = require('react');

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App) n
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      //   // You can render any custom fallback UI
      //   return this.props.fallback;
      return (
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            padding: '20px',
            background: '#fff',
            zIndex: 999999,
          }}
        >
          Something went wrong, please reload page
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
