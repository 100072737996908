import { useEffect, useState } from 'react';

// hooks
import useFunctionBar from './useFunctionBar';
import useFunctionList from './useFunctionList';
import useStatusList from './useStatusList';
import { useOrderPaymentStatusPatch } from 'services/orderService';
import { ORDER_COMPLETION_STATUS } from 'common/constants';

export const useOrderFunctions = ({
  orderId,
  order,
  isDirty,
  isNewOrder,
  setOrder,
  setModalSwitch,
  modalToggle,
  setDefaultFormValues,
}) => {
  const {
    mutate: mutatePaymentStatus,
    isSuccess: isPaymentStatusSuccess,
    data: paymentStatusData,
  } = useOrderPaymentStatusPatch();

  let functionsList = [
    'change-status',
    'go-to-client',
    'functions',
    // 'print',
  ];

  if (!isNewOrder) {
    functionsList = ['change-payment-status', ...functionsList];
  }

  const functions = useFunctionBar(functionsList, true);

  const navigateToClientFunctionAction = () =>
    window.open(
      `/functions/tables/clients/${order.customer.id}/general-information`,
      '_blank',
    );

  functions.forEach((func) => {
    if (order?.customer?.id && func.id === 'go-to-client') {
      func.action = navigateToClientFunctionAction;
    } else if (['change-status', 'change-payment-status'].includes(func.id)) {
      func.isDisabled = order.status === ORDER_COMPLETION_STATUS.CONFIRMED;
    }

    return func;
  });

  const paymentStatusList = useFunctionList(['order-paid', 'order-unpaid']);
  const functionListItems = useFunctionList([
    'send-sms-reminder',
    'send-sms-link',
    'save-recommendation',
    'delete-order',
  ]);
  const statusList = useStatusList([
    ORDER_COMPLETION_STATUS.ORDERED,
    ORDER_COMPLETION_STATUS.WILL_ARRIVE,
    ORDER_COMPLETION_STATUS.WILL_NOT_ARRIVE,
    ORDER_COMPLETION_STATUS.NOT_ARRIVED,
  ]);

  const [functionBarFunctions, setFunctionBarFunctions] = useState(functions);

  const changePaymentStatusFunc = functionBarFunctions.find(
    (item) => item.id === 'change-payment-status',
  );
  const changeStatusFunc = functionBarFunctions.find(
    (item) => item.id === 'change-status',
  );
  const functionsFunc = functionBarFunctions.find(
    (item) => item.id === 'functions',
  );

  if (changePaymentStatusFunc) {
    changePaymentStatusFunc.subItems = paymentStatusList.reduce(
      (prev, curr) => {
        return [
          ...prev,
          {
            ...curr,
            name: curr.title,
            action: () => updatePaymentStatus(curr.id === 'order-paid'),
          },
        ];
      },
      [],
    );
  }

  changeStatusFunc.subItems = statusList.reduce((prev, curr) => {
    return [
      ...prev,
      {
        ...curr,
        name: curr.title,
        statusColor: curr.background,
        action: () => {
          changeOrderStatus(curr.id);
        },
      },
    ];
  }, []);

  functionsFunc.subItems = functionListItems.reduce((prev, curr) => {
    return [
      ...prev,
      {
        ...curr,
        name: curr.title,
        fontColor: curr?.color,
        color: curr?.background,
        action: () => {
          if (curr.id === 'send-sms-reminder' || curr.id === 'send-sms-link') {
            setModalSwitch(curr.id);
            modalToggle();
          } else {
            console.log('other action');
          }
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (!isNewOrder) {
      setFunctionBarFunctions(functions);
    }
  }, [isNewOrder]);

  useEffect(() => {
    if (!isNewOrder) {
      setFunctionBarFunctions((prev) =>
        prev.map((func) => {
          if (func.id === 'change-payment-status') {
            func.isDisabled = isDirty;
          }

          return func;
        }),
      );
    }
  }, [isDirty]);

  useEffect(() => {
    setFunctionBarFunctions((prev) => {
      return prev.map((func) => {
        if (func.id === 'change-status') {
          func.isDisabled = order.status === ORDER_COMPLETION_STATUS.CONFIRMED;
        }

        return func;
      });
    });
  }, [order.status]);

  useEffect(() => {
    if (order?.customer?.id) {
      setFunctionBarFunctions((prev) => {
        return prev.map((func) => {
          if (func.id === 'go-to-client') {
            func.action = navigateToClientFunctionAction;
          }

          return func;
        });
      });
    }
  }, [order?.customer?.id]);

  useEffect(() => {
    if (isPaymentStatusSuccess) {
      const orderFromBe = paymentStatusData.data.order;

      const updatedOrder = {
        ...order,
        isPaid: orderFromBe.isPaid,
        version: orderFromBe.version,
      };

      setOrder(updatedOrder);
      setDefaultFormValues(updatedOrder);
    }
  }, [isPaymentStatusSuccess]);

  const updatePaymentStatus = (isPayed) =>
    mutatePaymentStatus({
      id: orderId,
      version: order.version,
      isPaid: isPayed,
    });

  const changeOrderStatus = (value) => {
    setOrder((prev) => {
      if (prev.toggled) {
        return { ...prev, status: value };
      } else {
        return prev;
      }
    });
  };

  return {
    functionBarFunctions,
  };
};
