import {
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';

// pages
import Login from 'pages/Login/Login';
import Main from 'pages/Main/Main';
import NotFound from 'pages/NotFound';

// contexts
import { UserContextProvider } from 'context/UserContext';

// hooks
import { queryClient } from 'index';

const App = () => {
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  const Root = () => (
    <Routes>
      <Route
        index
        element={<Navigate to={APP_ROOT + 'functions'} replace={true} />}
      />

      <Route
        path={APP_ROOT + 'login'}
        element={
          <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <Login />
          </GoogleOAuthProvider>
        }
      />

      <Route path={APP_ROOT + '*'} element={<Main />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );

  const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </UserContextProvider>
    </QueryClientProvider>
  );
};

export default App;
