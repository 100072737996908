import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const HeaderByType = styled.div`
  color: ${(props) => (props.readOnly ? colors.bg_Primary : colors.bg_grey_3)};
  font-family: ${fontFamilies.family_Secondary};

  .headerByType__title-wrapper {
    display: flex;
    align-items: start;
  }

  .headerByType__icon-container {
    display: flex;
    flex-shrink: 0;
    height: 30px;
    margin-right: 20px;
    width: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    margin: 0;
    font-size: 30px;
    font-weight: 500;
  }

  @media screen and (max-width: 960px) {
    .headerByType__icon-container {
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }

    h2 {
      font-size: 20px;
    }
  }
`;
