import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Search = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.darkTheme ? colors.bg_grey_1 : 'white'};
  border-radius: 15px;
  color: ${colors.bg_Primary};
  box-shadow: ${(props) => props.raised && shadow.md};
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 400;
  flex-wrap: nowrap;
  gap: 10px;
  height: 30px;
  padding-inline: 10px;

  @media screen and (max-width: 960px) {
    padding: 0;
  }

  .search-icon {
    align-items: flex-end;
    color: ${colors.bg_grey_3};
    display: flex;
    flex-shrink: 0;
    justify-content: right;
    height: 17px;
    width: 17px;

    svg {
      color: ${colors.bg_Primary};
      height: 16px;
      width: 16px;
    }
  }

  .clear-input {
    cursor: pointer;

    svg {
      height: 17px;
      width: 17px;
    }
  }

  .search-input {
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;

    ::placeholder {
      color: ${(props) =>
        props.darkTheme ? colors.bg_grey_3 : colors.bg_grey_4};
    }
  }
`;
