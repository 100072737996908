import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const RecommendationModal = styled.div`
  width: 540px;
  font-family: ${fontFamilies.family_Secondary};
  font-size: 18px;

  .info-wrapper {
    display: flex;
    border-bottom: 1px solid ${colors.bg_grey_2};
    padding: 20px 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
      border: none;
    }
  }

  .label-container,
  .value-container {
    display: flex;
    flex-direction: column;
  }

  .label-container {
    width: 170px;

    margin-right: 60px;

    span {
      font-weight: 900;
      min-height: 50px;

      display: flex;

      align-items: center;
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .extraMargin {
    margin-bottom: 15px !important;
  }

  .value-container {
    width: 250px;

    .value {
      display: flex;
      align-items: center;
      min-height: 50px;
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .noPadding {
      padding: 0;
    }

    .value__avatar {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    .value__list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 5px;
    }
  }

  .total-price-container {
    font-weight: 900;
    padding: 30px 0 10px 0;
  }

  @media screen and (max-width: 960px) {
    font-size: 14px;
    width: 100%;

    .info-wrapper {
      padding: 10px 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
        border: none;
      }
    }

    .label-container {
      width: 130px;
      margin-right: 20px;

      span {
        min-height: 40px;
      }
    }

    .value-container {
      .value {
        min-height: 40px;
      }

      .value__avatar {
        width: 40px;
        height: 40px;
      }

      .value__list {
        position: relative;
      }
    }

    .total-price-container {
      margin-left: 0;
      white-space: nowrap;
      padding-bottom: 30px;
      padding-top: 10px;
    }
  }
`;
