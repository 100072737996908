import { useEffect, useState } from 'react';

// hooks
import { useFetch } from './useApi2';

export const useSectorsGetAll = (params = {}) => {
  const DB_SLICE = 'sectors';

  const context = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [prioritizedData, setPrioritizedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    if (context.isSuccess) {
      setPrioritizedData([...context.data[DB_SLICE]]);
    }
  }, [context.isSuccess, context.data]);

  return {
    ...context,
    isSuccess: context.isSuccess && prioritizedData !== undefined,
    data: prioritizedData,
  };
};
