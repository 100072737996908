import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <>
          <path
            d="M 5.6289356,12.414132 H 2.4766224 C 1.1105111,12.414132 -3.0534562e-8,13.562834 -3.0534562e-8,14.97594 V 26.618637 C -3.0534562e-8,28.031788 1.1105111,29.180489 2.4766224,29.180489 h 3.1523132 c 1.3661114,0 2.4766225,-1.148701 2.4766225,-2.561852 V 14.97594 c 0,-1.413106 -1.1105111,-2.561808 -2.4766225,-2.561808 z M 4.5801195,25.655036 h -1.057621 v -9.71551 h 1.057621 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(3.0534562e-8,-0.81951082)"
          />
          <path
            d="m 29.328629,13.647974 c -0.916651,-1.633457 -2.567702,-2.608833 -4.415554,-2.608833 h -4.377454 l 1.457101,-5.5261105 c 0.4995,-1.8949219 -0.4524,-3.7663403 -2.262152,-4.4508629 -1.771502,-0.66983171 -3.678154,0.085198 -4.539005,1.7950353 L 9.5891196,13.997579 C 9.2306942,14.705595 9.042669,15.501766 9.042669,16.300876 v 9.086859 c 0,2.091752 1.674587,3.792754 3.731089,3.792754 h 8.155463 c 1.301551,0 2.488352,-0.6699 3.172953,-1.795052 l 5.129555,-8.416958 c 0.990001,-1.624652 1.025251,-3.663559 0.0939,-5.320505 z m -3.105303,3.487203 -5.129555,8.416959 c -0.0411,0.06465 -0.0999,0.10575 -0.16155,0.10575 h -8.155523 c -0.09989,0 -0.20565,-0.1086 -0.20565,-0.267301 v -9.086859 c 0,-0.2496 0.05876,-0.496425 0.1704,-0.71676 l 5.60252,-11.1433166 c 0.03525,-0.067575 0.06165,-0.094005 0.0588,-0.096945 0.03225,0 0.11745,0.017625 0.170401,0.07344 0.01755,0.020565 0.0498,0.04995 0.0117,0.1968452 l -2.038952,7.7383274 c -0.138001,0.528811 -0.0264,1.092886 0.30855,1.524752 0.33495,0.431865 0.849001,0.687451 1.395451,0.687451 h 6.666007 c 0.799051,0 1.204501,0.567015 1.339651,0.810855 0.314401,0.558151 0.302701,1.216202 -0.0294,1.759802 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(3.0534562e-8,-0.81951082)"
          />
        </>
      );
      break;
    case 700:
      content = (
        <>
          <path
            d="m 5.63898,29.383725 c 1.12473,0 2.038575,-0.92295 2.038575,-2.0538 V 15.217575 C 7.677555,14.083665 6.76371,13.1637 5.63898,13.1637 H 2.03859 C 0.9138495,13.1637 0,14.08671 0,15.217575 v 12.11235 c 0,1.13385 0.9138495,2.0538 2.03859,2.0538 z M 2.44203,15.608775 h 2.79045 v 11.32995 H 2.44203 Z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-0.61282498)"
          />
          <path
            d="m 19.4538,11.449425 1.8093,-6.65673 C 21.7215,3.108645 20.9055,1.4887725 19.2795,0.856107 17.6535,0.220386 15.9573,0.862221 15.15345,2.41179 L 9.034635,14.218485 c -0.34536,0.663225 -0.52875,1.41204 -0.52875,2.16384 v 9.6306 c 0,1.86135 1.5129,3.37425 3.37422,3.37425 h 8.906145 c 1.1676,0 2.23425,-0.58995 2.8578,-1.5801 l 5.6022,-8.91855 c 0.9537,-1.5189 1.0056,-3.36192 0.13755,-4.929825 -0.8679,-1.56792 -2.45415,-2.506215 -4.2483,-2.506215 h -5.6787 z m 7.78755,3.689025 c 0.43095,0.779325 0.4035,1.693275 -0.0672,2.448075 l -5.60235,8.91855 c -0.17115,0.27195 -0.46455,0.43395 -0.78555,0.43395 h -8.906145 c -0.510405,0 -0.92913,-0.41565 -0.92913,-0.9291 v -9.6306 c 0,-0.3606 0.08864,-0.7182 0.25368,-1.03611 L 17.3235,3.536535 c 0.1956,-0.37593 0.4707,-0.473745 0.7029,-0.473745 0.1467,0 0.2781,0.039735 0.36375,0.070305 0.2139,0.082515 0.69375,0.35148 0.51345,1.017765 l -2.2281,8.2002 c -0.1008,0.36675 -0.0213,0.761025 0.2079,1.063605 0.2322,0.30258 0.5898,0.47985 0.97185,0.47985 h 7.27725 c 0.88935,0 1.6779,0.464565 2.10885,1.243935 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-0.61282498)"
          />
        </>
      );

      break;
    default:
      content = (
        <>
          <path
            d="M 7.3993295,27.852392 V 15.22377 c 0,-0.828526 -0.713806,-1.504082 -1.5933171,-1.504082 H 1.5933017 c -0.87949912,0 -1.59330207642544,0.675556 -1.59330207642544,1.504082 v 12.628622 c 0,0.828451 0.71380295642544,1.504052 1.59330207642544,1.504052 h 4.2127107 c 0.8795111,0 1.5933171,-0.675601 1.5933171,-1.504052 z m -6.1246822,0 V 15.22377 c 0,-0.108345 0.1306517,-0.22944 0.3186544,-0.22944 h 4.2127107 c 0.1880102,0 0.3186754,0.121095 0.3186754,0.22944 v 12.628622 c 0,0.1083 -0.1306652,0.22935 -0.3186754,0.22935 H 1.5933017 c -0.1880027,0 -0.3186544,-0.12105 -0.3186544,-0.22935 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(3.7642545e-7,-0.64355594)"
          />
          <path
            d="m 11.74905,29.356294 h 9.285778 c 0.997501,0 1.908752,-0.503551 2.437803,-1.347902 l 5.841008,-9.298662 c 0.870001,-1.383002 0.914551,-3.062254 0.12435,-4.493076 -0.790351,-1.430792 -2.237103,-2.284818 -3.871805,-2.284818 H 18.810625 L 20.913778,4.1883605 C 21.366328,2.5185733 20.359227,1.2853497 19.228075,0.84240957 18.099924,0.40265698 16.519372,0.62890632 15.722721,2.1648578 L 9.3431521,14.474759 C 9.0308517,15.07704 8.8651614,15.755776 8.8651614,16.434527 V 26.47554 c 0,1.587002 1.2905716,2.880754 2.8806936,2.880754 z M 10.146178,16.437677 c 0,-0.477901 0.11472,-0.952756 0.334591,-1.373387 L 16.860322,2.7511936 C 17.182223,2.1329903 17.685773,1.9163 18.160524,1.9163 c 0.21675,0 0.42375,0.044613 0.608701,0.1147186 0.5895,0.2294344 1.185301,0.8444502 0.917701,1.8227515 l -2.539804,9.3527229 h 8.422212 c 1.163251,0 2.192403,0.60864 2.756553,1.625177 0.564001,1.016506 0.528901,2.214708 -0.08925,3.199309 l -5.841158,9.298662 c -0.29625,0.471601 -0.802951,0.751951 -1.360651,0.751951 H 11.74905 c -0.885886,0 -1.606067,-0.720151 -1.606067,-1.606052 V 16.434527 Z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(3.7642545e-7,-0.64355594)"
          />
        </>
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
