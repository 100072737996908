// style
import * as S from './DialogButton.styled';

const DialogButton = ({ data, close }) => {
  return (
    <S.DialogButton
      inverted={data?.inverted}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        data?.action
          ? data.action()
          : console.error(`no action specified for button [ ${data?.title} ]`);
        if (data?.dontCloseAfter) return;
        close && !data.stayOpen && close();
      }}
      tertiary={data?.tertiary}
      style={data?.style}
      disabled={data?.disabled}
    >
      {data?.icon && <div className="icon-container">{data?.icon}</div>}

      <span className="title-container">{data?.title}</span>
    </S.DialogButton>
  );
};

export default DialogButton;
