import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

// components
import { Table } from 'components/Tables/Table/Table';
import { ClientCard } from '../ClientCard/ClientCard';

// contexts
import { MainContext } from 'pages/Main/Main';
import { TableContext } from 'context/TableContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useClientsBrowsePost, useClientsDelete } from 'services/clientService';
import { useTable } from 'hooks/useTable';

// helpers
import { TABLE_FILTER_TYPE } from 'common/constants';

export const ClientsTable = () => {
  const { state, dispatch } = useContext(TableContext);
  const { tableState } = useContext(MainContext);
  const tr = useTranslate().use().global;
  const navigate = useNavigate();
  const dataRequestEnabled = useRef();
  const { setFilter, setData } = useTable();
  const [action, setAction] = useState();
  const {
    mutate,
    isSuccess: isDeletionSuccess,
    isError: isDeletionError,
  } = useClientsDelete();
  const {
    data: { data } = {},
    isLoading,
    mutate: getClients,
  } = useClientsBrowsePost({
    searchParams: { ...state.pagination },
  });

  if (data?.customers?.length) {
    const clients = data?.customers?.map((item) => {
      if (item?.phonePrefix && item?.phoneNumber) {
        item.phone = item.phonePrefix + item.phoneNumber;
      }

      const years = DateTime.now()
        .diff(DateTime.fromISO(item.birthday), 'years')
        .toObject()?.years;

      if (years) {
        item.age = parseInt(years);
      }

      return item;
    });

    data.customers = clients;
  }

  useEffect(() => {
    if (dataRequestEnabled.current === true || state.filters?.applied.length) {
      getClients({
        sort: state.sort,
        filter: state.filters.applied,
      });

      dataRequestEnabled.current = true;
    }
  }, [
    // sectorId,
    state.pagination,
    state.sort,
    state.filters.applied,
    isDeletionSuccess,
    isDeletionError,
  ]);

  const handleRecordDelete = useCallback(
    () =>
      mutate({
        id: action.record.id,
        searchParams: {
          version: action.record.version,
        },
      }),
    [action?.record],
  );

  useEffect(() => {
    if (tableState?.clients) {
      dispatch({
        type: 'SET_STATE',
        payload: tableState?.clients,
      });
      dataRequestEnabled.current = true;
    }
  }, []);

  useEffect(() => {
    if (!tableState?.clients) {
      dispatch({
        type: 'SET_TABLE_NAME',
        payload: 'clients',
      });

      setFilter(true, 'IsActive');

      let columns = [
        {
          id: 'createTimeUtc',
          label: tr['registration-date'] + ' (UTC)',
          type: 'DATETIME',
          filter: 'CreateTime',
          filterType: TABLE_FILTER_TYPE.DATE_RANGE,
          sortable: true,
          minWidth: 138,
          maxWidth: 138,
        },
        {
          id: 'name',
          label: tr['firstName'],
          filter: 'Name',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
        },
        {
          id: 'lastName',
          label: tr['lastName'],
          filter: 'LastName',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          id: 'birthday',
          label: tr['dob'],
          type: 'DATE',
          filter: 'Birthday',
          filterType: TABLE_FILTER_TYPE.INPUT_RAGE,
          sortable: true,
          width: 138,
          minWidth: 138,
        },
        {
          id: 'age',
          label: tr['age'],
          filter: 'Age',
          filterType: TABLE_FILTER_TYPE.INPUT_RANGE,
          sortable: true,
          maxWidth: 40,
          minWidth: 40,
          inputProps: {
            type: 'number',
            decimalPlaces: 0,
          },
        },
        {
          id: 'phone',
          label: tr['phone2'],
          filter: 'PhoneNumber',
          filterType: TABLE_FILTER_TYPE.INPUT,
          // sortable: true,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          id: 'email',
          label: tr['email'],
          filter: 'Email',
          filterType: TABLE_FILTER_TYPE.INPUT,
          // sortable: true,
          minWidth: 200,
        },
        {
          id: 'city',
          label: tr['city'],
          filter: 'City',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
        },
        {
          id: 'country',
          label: tr['country'],
          filter: 'Country',
          filterType: TABLE_FILTER_TYPE.INPUT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
        },

        {
          id: 'isActive',
          label: tr['activity'],
          type: 'OPTION',
          options: [
            { label: tr['active'], value: true },
            { label: tr['inactive'], value: false },
          ],
          filter: 'IsActive',
          filterType: TABLE_FILTER_TYPE.SELECT,
          sortable: true,
          maxWidth: 120,
          minWidth: 120,
        },
      ];

      columns.map((column) => {
        column.isVisible = true;
      });

      dispatch({
        type: 'SET_COLUMNS',
        payload: columns,
      });
    }
  }, []);

  useEffect(() => {
    if (data?.customers) {
      setData(data.customers, data.totalCount);
    }
  }, [data]);

  const isRecordInactive = (record) => !record.isActive;
  const activeRecordDeleteMessage = tr['can-only-delete-inactive-cards'];

  return (
    <Table
      actions={{
        action,
        setAction,
        navigation: (record) => navigate(record.id + '/general-information'),
        delete: {
          action: handleRecordDelete,
          warning: tr['are-you-sure-you-want-to-delete-the-client-card'],
          activeRecordDeleteMessage,
        },
      }}
      mobileElement={ClientCard}
      isLoading={isLoading}
      isRecordInactive={isRecordInactive}
    />
  );
};
