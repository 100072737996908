import { Children, cloneElement, isValidElement, useState } from 'react';

// style
import * as S from './LoginForm.styled';

// components
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useMediaQuery from 'hooks/useMediaQuery';

export const LoginForm = ({
  title,
  description,
  stage,
  nextBtnTitle,
  children,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const [action, setAction] = useState();
  const [disabled, setDisabled] = useState();

  const hydratedChildren = Children.map(children, (child) =>
    // checking isValidElement is the safe way and avoids a typescript error too
    isValidElement(child)
      ? cloneElement(child, { setNext: setAction, setDisabled })
      : child,
  );

  return (
    <S.LoginForm>
      <div className="title-container">
        <div className="title">{title}</div>

        <div className="description">{description}</div>
      </div>

      <div className="content">
        {hydratedChildren}

        {!(stage === 'select-auth' || (stage === 'intro' && !isMobile)) && (
          <TheButton
            title={nextBtnTitle || tr['next'] + ' →'}
            primary
            height={'35'}
            width={isMobile ? '100%' : 'fit-content'}
            action={action}
            disabled={disabled}
          />
        )}
      </div>
    </S.LoginForm>
  );
};
