import { useEffect } from 'react';

export const useLeavePage = (condition) => {
  return useEffect(() => {
    const beforeUnloadListener = (event) => {
      if (condition) {
        event.preventDefault();
        return (event.returnValue = '');
      }
    };

    window.addEventListener('beforeunload', beforeUnloadListener, {
      capture: true,
    });

    return () =>
      window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
  }, [condition]);
};
