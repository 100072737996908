import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const PendingOrdersHeader = styled.div`
  width: 0;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 3;
  margin-bottom: -4px;

  ${({ showPendingOrders }) => css`
    min-width: ${showPendingOrders ? '170px' : 0};
    overflow: ${showPendingOrders ? 'visible' : 'hidden'};
  `}

  .filler {
    background-color: ${colors.bg_grey_1};
    height: 100%;
    width: 100%;
    box-shadow: -3px 0 2px -2px rgba(15, 29, 91, 0.15);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .container {
      height: calc(100% - 4px);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .filler__time {
        font-size: 30px;
        font-weight: 600;
        height: fit-content;
        display: flex;
        align-items: center;
        line-height: 1;
        padding-bottom: 5px;
      }

      .filler__title {
        font-size: 12px;
        font-weight: 600;
        padding-top: 3px;
        padding-bottom: 20px;
      }

      .clear_button {
        position: absolute;
        bottom: 10px;
        height: 20px;

        button {
          font-size: 0.75rem;

          svg {
            width: 14px !important;
            height: 14px !important;
          }

          :hover {
            background-color: ${colors.bg_Button_Secondary};
            color: white;

            svg {
              fill: white;
            }
          }

          span {
            padding-bottom: 2px;
          }
        }
      }
    }
  }
`;
