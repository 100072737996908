import styled from 'styled-components/macro';
import { colors } from '../../../../../common/colors/colors';

export const ProfessionalSelect = styled.div`
  height: 35px;
  background-color: ${(props) =>
    props.darkTheme ? colors.bg_grey_1 : 'white'};
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 20px 0 0px;

  .proffesionalSelect_img {
    height: 35px;
    width: 35px;
    flex-shrink: 0;

    background-color: ${(props) =>
      props.darkTheme ? 'white' : colors.bg_grey_1};

    border-radius: 50%;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .proffesionalSelect_info {
    padding-left: 10px;
    color: ${colors.bg_Primary};
    padding-bottom: 2px;
    width: 100%;

    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    line-height: normal;

    .proffesionalSelect_info_name {
      font-weight: 700;
      font-size: 14px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      width: fit-content;
      padding-right: 5px;
    }

    .proffesionalSelect_info_profession {
      font-size: 12px;
    }
  }

  .iconContainer {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate3d(50%, -40%, 0);
    width: 14px;
  }

  @media screen and (min-width: 961px) {
    height: 50px;
    cursor: pointer;

    padding: 0 46px 0 5px;

    .proffesionalSelect_img {
      height: 40px;
      min-width: 40px;
    }

    .proffesionalSelect_info {
      padding-left: 15px;

      min-width: 150px;

      .proffesionalSelect_info_name {
        font-size: 18px;
        padding-right: 10px;
      }

      .proffesionalSelect_info_profession {
        font-size: 15px;
        padding: 0;
      }
    }

    .iconContainer {
      right: 30px;
      /* svg {
        height: 8.98px;
        width: 16.06px;
      } */
    }
  }
`;
