import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { dropdownRollIn, dropdownRollOut } from 'common/styles/animations';
import { fontFamilies } from 'common/fonts/fonts';

export const InputFieldPhone = styled.div`
  border-bottom-left-radius: 0;
  font-family: ${fontFamilies.family_Secondary};

  label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin: 0 10px 5px;
    /* width: 100%; */
  }
  /* .react-international-phone-country-selector button,
  .react-international-phone-dial-code-preview,
  .react-international-phone-input {
    transform-origin: top;
    animation: ${dropdownRollIn} 0.2s ease-in-out;
  } */

  .input-wrapper {
    animation: ${dropdownRollIn} 0.2s ease-in-out;
    transform-origin: top;
    width: ${(props) => props.extendOnOpen && '100%'};
  }

  .react-international-phone-input-container {
    :hover {
      .react-international-phone-country-selector-button,
      .react-international-phone-dial-code-preview,
      .react-international-phone-input {
        border-block-color: hsl(0, 0%, 70%);
      }

      .react-international-phone-country-selector-button {
        border-left-color: hsl(0, 0%, 70%);
      }

      .react-international-phone-input {
        border-right-color: hsl(0, 0%, 70%);
      }
    }

    :focus-within {
      .react-international-phone-country-selector-button,
      .react-international-phone-dial-code-preview,
      .react-international-phone-input {
        border-block-color: ${colors.bg_grey_3};
      }

      .react-international-phone-country-selector-button {
        border-left-color: ${colors.bg_grey_3};
        /* outline: none; */
      }

      .react-international-phone-input {
        border-right-color: ${colors.bg_grey_3};
        font-weight: bold;
      }
    }
  }

  .react-international-phone-input {
    :hover {
      border-left: 1px solid hsl(0, 0%, 70%);
    }

    :focus-within {
      border-left: 1px solid ${colors.bg_grey_3};
    }
  }

  .react-international-phone-country-selector-button,
  .react-international-phone-input {
    padding: 18px 0;
  }

  .react-international-phone-country-selector-button {
    border-bottom-left-radius: ${(props) => !!props?.dropdownVisible && '0'};

    :hover {
      background-color: var(
        --react-international-phone-country-selector-background-color,
        var(--react-international-phone-background-color, white)
      );
    }

    /* &--active {
      border-right: 1px solid ${colors.bg_grey_3};
    } */
  }

  .react-international-phone-country-selector-button__flag-emoji {
    margin: 0 5px 0 10px;
  }

  .react-international-phone-country-selector-button__dropdown-arrow {
    margin-right: 10px;
  }

  .react-international-phone-input,
  .react-international-phone-dial-code-preview {
    font-size: 1rem;
  }

  .react-international-phone-dial-code-preview {
    color: ${colors.bg_grey_3};
  }

  .react-international-phone-input {
    border-bottom-right-radius: ${(props) => !!props?.dropdownVisible && '0'};
    color: ${colors.text_Secondary};
    font-size: 1rem;
    padding: 18px 10px;
    width: 100%;
  }

  .react-international-phone-country-selector-dropdown {
    animation: ${({ dropdownVisible }) =>
        dropdownVisible ? dropdownRollIn : dropdownRollOut}
      0.2s ease-in-out;
    border: 1px solid ${colors.table_borders_Primary};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
    box-shadow: none;
    display: ${(props) => props?.dropdownDisplayStyle} !important;
    padding: 5px 0;
    top: var(--react-international-phone-dropdown-left, 38px);
    transform-origin: top;
    width: ${(props) => props?.phoneInputRef?.offsetWidth + 'px'};

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    li {
      color: ${colors.text_Secondary};
      margin-inline: 5px;
      padding: 10px 15px;

      &.react-international-phone-country-selector-dropdown__list-item--focused {
        background-color: transparent;
        font-weight: 900;
      }

      span {
        font-size: inherit;
      }

      :hover {
        background-color: ${colors.sidebar_bg_selected};
        border-radius: 5px;
      }
    }
  }

  .disabled-input {
    align-items: center;
    display: grid;
    height: 36px;
    margin: 0 4px;
    padding: 2px 8px;
    /* transform-origin: top;
    animation: ${dropdownRollIn} 0.2s ease-in-out; */
  }
`;
