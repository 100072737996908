import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Intro = styled.div`
  align-items: center;
  background: radial-gradient(ellipse at top right, #245bb3, #0f1d5b);
  color: ${colors.text_Primary};
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100svh;
  justify-content: space-around;
  overflow-x: hidden;
  padding: 160px 0;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    padding: 60px 40px 40px;
  }

  .slider-container,
  .description-container {
    /* border: 1px solid ${colors.table_borders_Secondary}; */
    display: block;
    width: 50vw;
  }

  .slider-container {
    position: relative;
  }

  .description-container {
    @media screen and (max-width: 960px) {
      font-size: 0.875rem;
      width: 100vw;
    }
  }

  .title {
    font-family: ${fontFamilies.family_Primary};
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 30px;

    @media screen and (max-width: 960px) {
      font-size: 1.25rem;
    }
  }

  .bg-1,
  .bg-2 {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: calc(100vw * 0.01);
    position: absolute;

    @media screen and (max-width: 960px) {
      height: calc(100vw * 0.02);
    }
  }

  .bg-1 {
    margin-left: 150px;
    margin-right: 150px;
    opacity: 16%;
    width: calc(100% - 300px);

    @media screen and (max-width: 960px) {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }
  }

  .bg-2 {
    margin-left: 20px;
    margin-right: 20px;
    opacity: 50%;
    top: calc(100vw * 0.01);
    width: calc(100% - 40px);

    @media screen and (max-width: 960px) {
      top: calc(100vw * 0.02);
    }
  }

  img {
    border: 1px solid #245bb3;
    border-radius: 10px;
    object-fit: contain;
    width: calc(50vw - 260px);

    @media screen and (max-width: 960px) {
      width: auto;
    }
  }

  .description {
    margin-inline: auto;
    max-width: 700px;
    min-width: calc((50vw - 130px) / 2);

    @media screen and (max-width: 960px) {
      padding-inline: 40px;
    }
  }

  .slider-container {
    .react-slideshow-container + ul.indicators {
      margin-top: calc(100vw * 0.03);
      padding-left: 7px;

      @media screen and (max-width: 960px) {
        margin-top: calc(100vw * 0.06);
        padding-left: 9.5px;
      }
    }

    .react-slideshow-container + ul.indicators li {
      padding: 12.5px;

      @media screen and (max-width: 960px) {
        padding: 10px;
      }
    }

    .react-slideshow-container
      + ul.indicators
      .each-slideshow-indicator::before {
      background-color: white;
      height: 10px;
      width: 10px;

      @media screen and (max-width: 960px) {
        height: 7px;
        width: 7px;
      }
    }

    .react-slideshow-container .nav.default-nav {
      :first-child {
        left: 40px;

        @media screen and (max-width: 960px) {
          left: 0;
        }
      }

      :last-child {
        right: 40px;

        @media screen and (max-width: 960px) {
          right: 0;
        }
      }
    }

    .react-slideshow-fadezoom-wrapper {
      overflow: visible;
    }

    .react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap {
      align-items: center;
    }

    .react-slideshow-fadezoom-images-wrap div {
      line-height: 0;
    }
  }

  button {
    min-height: 30px;
  }
`;
