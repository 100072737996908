const scheduleInitialState = {
  cell: null,
  startHour: 0,
  endHour: 24,
  cellDuration: 15,
  dataSource: null,
  dateState: new Date(),
  pendingOrders: null,
  draggingAppointment: null,
  selectedProfessional: null,
};

const scheduleReducer = (state = scheduleInitialState, action) => {
  // TODO: check for changes? if there's not do nothing

  switch (action.type) {
    case 'SCHEDULE_UPDATE':
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default scheduleReducer;
