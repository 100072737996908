import { Fragment, useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

// style
import * as S from './ServiceTree.styled';

// icons
import { Arrow, SetSVG } from 'icons/dynamic';

// components
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { DialogLayout } from 'components/DialogLayout/DialogLayout';
import { BaseCheckbox } from 'components/Inputs';
import { TheButton } from 'components/Buttons/TheButton';
import { InputValueNumber } from '../InputValueNumber/InputValueNumber';
import { InputValueDuration } from '../InputValueDuration/InputValueDuration';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { getItem as getTreeItem } from 'common/helpers/serviceFunctions';
import { singularize, toSentenceCase } from 'common/helpers/stringOperations';
import { minutesToHoursAndMinutes } from 'common/helpers/timeCalculations';
import { useNumber } from 'hooks/useNumber';
import { useSystemGetUnits } from 'services/systemService';

export const ServiceTree = ({
  data,
  close,
  disabled,
  contentId,
  getDbItem,
  getItem,
  toggleActivity,
  updateCache,
  initialButtons,
  hasAnyChanges,
  hasValueChanged,
  isDisabledByParent,
  updatePropDownstream,
  setButtons,
  ...props
}) => {
  const tr = useTranslate().use().global;

  const isMaterial = [null, undefined].includes(data?.baseDurationInMinutes);

  const [activeOption, setActiveOption] = useState();

  const findItemById = (id, type) => getTreeItem(data, id, type);

  const ServiceTreeItems = ({ setButtons }) => {
    // setup/cleanup header buttons
    useEffect(() => {
      setButtons({ type: 'setup', value: initialButtons });
      return () => setButtons({ type: 'cleanup' });
    }, [setButtons]);

    // show/hide header button according to changes
    useEffect(() => {
      if (hasAnyChanges) {
        const isChanged = hasAnyChanges();
        setButtons({ type: 'show', id: 'cancel', value: isChanged });
        setButtons({ type: 'show', id: 'save', value: isChanged });
      }
    }, [setButtons]);

    const getActualPrice = useCallback(
      (object) =>
        object &&
        ('empUnitPriceWithVat' in object &&
        object['empUnitPriceWithVat'] !== null
          ? object['empUnitPriceWithVat']
          : 'sectorUnitPriceWithVat' in object
            ? object['sectorUnitPriceWithVat']
            : undefined),
      [],
    );

    const getDefaultPrice = useCallback(
      (object) =>
        object &&
        ('sectorUnitPriceWithVat' in object
          ? object['sectorUnitPriceWithVat']
          : undefined),
      [],
    );

    const getActualDuration = useCallback((object, valueType) => {
      const propId = `emp${toSentenceCase(valueType)}InMinutes`;
      const basePropId = `base${toSentenceCase(valueType)}InMinutes`;
      return (
        object &&
        (propId in object && object[propId] !== null
          ? object[propId]
          : basePropId in object
            ? object[basePropId]
            : undefined)
      );
    }, []);

    const getDefaultDuration = useCallback((object, valueType) => {
      const basePropId = `base${toSentenceCase(valueType)}InMinutes`;
      return object && (basePropId in object ? object[basePropId] : undefined);
    }, []);

    const isCustom = useCallback((object, valueType) => {
      const propId =
        valueType === 'price'
          ? 'empUnitPriceWithVat'
          : `emp${toSentenceCase(valueType)}InMinutes`;
      return object && propId in object && object[propId] !== null;
    }, []);

    const { currencySymbol, currencySymbolPlacement, formatPrice } = useNumber({
      price: true,
    });

    // units load handler
    const { isSuccess: isUnitsSuccess, getUnitsById } = useSystemGetUnits({
      queryParams: { enabled: isMaterial },
    });

    return ['subgroups', 'services'].map((itemType) => (
      <Fragment key={itemType}>
        {data?.[itemType]?.map((service) => {
          const type = service.hasComp ? 'set' : singularize(itemType);
          const dbItem = getDbItem(type, service.id);
          const item = getItem(type, service.id);

          const isMaterial = [null, undefined].includes(
            item?.baseDurationInMinutes,
          );

          const defaultUnitPriceWithVat = getDefaultPrice(dbItem);
          const currentUnitPriceWithVat = getActualPrice(service);

          const defaultDurationString = minutesToHoursAndMinutes(
            getDefaultDuration(dbItem, 'duration'),
            { string: true },
          );

          const currentDurationString = minutesToHoursAndMinutes(
            getActualDuration(service, 'duration'),
            { string: true },
          );

          const defaultBreakString = minutesToHoursAndMinutes(
            getDefaultDuration(dbItem, 'break'),
            { string: true },
          );

          const currentBreakString = minutesToHoursAndMinutes(
            getActualDuration(service, 'break'),
            { string: true },
          );

          return (
            <S.ServiceTree
              key={service.id}
              isFinal={itemType === 'services'}
              type={service.hasComp ? 'set' : type}
              isDisabledByParent={isDisabledByParent(item.type, item.id)}
            >
              <div className="item">
                <BaseCheckbox
                  label={
                    <>
                      {service.hasComp && (
                        <div className="contents">
                          <div
                            className="icon"
                            data-tooltip-id="tooltip"
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                              <>
                                {service.composition.map((service) => (
                                  <div key={service.id}>{service.name}</div>
                                ))}
                              </>,
                            )}
                          >
                            <SetSVG sm />
                          </div>
                        </div>
                      )}
                      {service.name}
                    </>
                  }
                  value={item.isEmpAssigned}
                  onClick={() => toggleActivity(type, service.id)}
                />

                {!service.hasComp && service.services && (
                  <div
                    className="arrow"
                    onClick={() => {
                      console.log({ type: service, data });
                      setActiveOption(() =>
                        findItemById(service.id, service.type),
                      );
                    }}
                  >
                    <Arrow black height="16" width="16" />
                  </div>
                )}
              </div>

              {itemType === 'services' && (
                <div className="card">
                  <div className="price">
                    <span>{tr['price']}</span>

                    <InputValueNumber
                      id={'price'}
                      value={currentUnitPriceWithVat}
                      defaultValue={defaultUnitPriceWithVat}
                      updateValue={(value, config) =>
                        updateCache(
                          service.type,
                          service.id,
                          'empUnitPriceWithVat',
                          value,
                          // config,
                        )
                      }
                      disabled={disabled}
                      tooltipTitle={tr['base-price']}
                      tooltipContents={defaultUnitPriceWithVat}
                      isCustom={isCustom(service, 'price')}
                      hasValueChanged={hasValueChanged(
                        service.type,
                        service.id,
                        'empUnitPriceWithVat',
                      )}
                      decimalPlaces={4}
                      currencySymbol={currencySymbol}
                      currencySymbolPlacement={currencySymbolPlacement}
                    />
                  </div>

                  {isMaterial ? (
                    <div className="amount">
                      <span>{tr['volume']}</span>

                      <div>
                        <span>{service.unitVolume}</span>
                        {isUnitsSuccess && getUnitsById(service.unitId)?.label}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="duration">
                        <span>{tr['duration']}</span>

                        <InputValueDuration
                          id={'duration'}
                          className={classNames('duration', props.className)}
                          value={currentDurationString}
                          defaultValue={defaultDurationString}
                          updateValue={(value, config) => {
                            updateCache(
                              service.type,
                              service.id,
                              'empDurationInMinutes',
                              value,
                              // config,
                            );
                          }}
                          disabled={isMaterial || disabled}
                          tooltipTitle={tr['base-duration']}
                          tooltipContents={defaultDurationString}
                          isCustom={isCustom(service, 'duration')}
                          hasValueChanged={hasValueChanged(
                            service.type,
                            service.id,
                            'empDurationInMinutes',
                          )}
                        />
                      </div>

                      <div className="break">
                        <span>{tr['break']}</span>

                        <InputValueDuration
                          id={'break'}
                          className={classNames('break', props.className)}
                          value={currentBreakString}
                          defaultValue={defaultBreakString}
                          updateValue={(value, config) => {
                            updateCache(
                              service.type,
                              service.id,
                              'empBreakInMinutes',
                              value,
                              // config,
                            );
                          }}
                          disabled={isMaterial || disabled}
                          tooltipTitle={tr['base-break']}
                          tooltipContents={defaultBreakString}
                          isCustom={isCustom(service, 'break')}
                          hasValueChanged={hasValueChanged(
                            service.type,
                            service.id,
                            'empBreakInMinutes',
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </S.ServiceTree>
          );
        })}
      </Fragment>
    ));
  };

  const selectAll = () => updatePropDownstream(data, 'isEmpAssigned', true);

  const deselectAll = () => updatePropDownstream(data, 'isEmpAssigned', false);

  return (
    <ContentLayout hideFab>
      <DialogLayout headerText={data?.name} getBack={close} modal>
        <div className="controls">
          <TheButton
            title={tr['select-all']}
            secondary
            outline
            flat
            action={selectAll}
          />
          <TheButton
            title={tr['deselect-all']}
            secondary
            outline
            flat
            action={deselectAll}
          />
        </div>

        <ServiceTreeItems />

        {activeOption && (
          <ServiceTree
            data={activeOption}
            id={activeOption.id}
            getDbItem={getDbItem}
            getItem={getItem}
            toggleActivity={toggleActivity}
            updateCache={updateCache}
            initialButtons={initialButtons}
            hasAnyChanges={hasAnyChanges}
            hasValueChanged={hasValueChanged}
            isDisabledByParent={isDisabledByParent}
            updatePropDownstream={updatePropDownstream}
            close={() => setActiveOption(undefined)}
          />
        )}
      </DialogLayout>
    </ContentLayout>
  );
};

export default ServiceTree;
