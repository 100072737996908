import { fontFamilies } from 'common/fonts/fonts';
import { FlashMessage } from 'components/FlashMessage/FlashMessage.styled';
import { FormContainer } from 'components/Form/FormContainer.styled';
import styled from 'styled-components/macro';

export const EmployeeGeneralInformation = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  ${FlashMessage} {
    @media screen and (min-width: 960px) {
      margin-bottom: 10px;
    }
  }

  /* ${FormContainer} {
    padding: 10px 10px 0;
  } */
`;

export const AvatarPicker = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-right: unset;
    margin: 10px 10px 20px;
  }

  .avatar-picker__image {
    width: 120px;
    height: 120px;
    margin: 0 20px 20px;
    flex-shrink: 0;

    @media screen and (max-width: 960px) {
      width: 70px;
      height: 70px;
      margin: 0;
      /* margin-bottom: unset; */
    }
  }

  .avatar-picker__button {
    @media screen and (max-width: 960px) {
      width: fit-content;
    }
  }
`;
