import { Fragment } from 'react';
import { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import { useTranslate } from 'hooks/useTranslate';

// style
import * as S from './AppointmentCard.styled';

// icons
import { Cake, Comment, LetterNInCircle, Sms } from 'icons/dynamic';

// hooks
import useStatusList from 'hooks/useStatusList';
import useObserver from 'hooks/useObserver';

const AppointmentCard = ({
  id,
  displaySettings,
  displayData,
  hoverDisplay,
  timeOff,
  onClick,
}) => {
  const iconList = [
    {
      id: 'iconNewClient',
      icon: <LetterNInCircle />,
      label: 'Naujas klientas',
    },
    { id: 'iconSms', icon: <Sms />, label: 'Užsakymas turi pastabą' },
    {
      id: 'iconBirthday',
      icon: <Cake />,
      label: 'Šiandien gimtadienis',
    },
    {
      id: 'iconNote',
      icon: <Comment />,
      label: 'SMS generuojamas',
    },
    // { icon: <Globe bold /> },
  ];

  const settingsObj = {
    name: { show: true },
    lastName: { show: true },
    phone: { show: false },
    // cabinet: { show: true },
    iconSms: { show: true },
    iconNote: { show: true },
    serviceName: { show: true },
    iconBirthday: { show: true },
    iconNewClient: { show: true },
  };

  // Hooks
  const statusObj = useStatusList(displayData?.status?.toLowerCase());

  const tr = useTranslate().use().global;

  // -- refs
  const containerRef = useRef();
  const serviceListRef = useRef();
  const iconListRef = useRef();

  // -- state
  const [settings, setSettings] = useState(settingsObj);
  const [displayInfo, setDisplayInfo] = useState({});
  const [showServicesEllipsis, setShowServicesEllipsis] = useState(false);

  let container = useObserver({
    ref: containerRef,
    debounceDelay: 10,
  });

  let iconContainer = useObserver({
    ref: iconListRef,
    debounceDelay: 10,
  });

  useEffect(() => {
    if (!displaySettings) return;
    setSettings((prev) => {
      const newObj = { ...prev };
      displaySettings.forEach((item) => {
        if (!item.id) return;
        newObj[item?.id].show = item.value;
      });

      return newObj;
    });
  }, [displaySettings]);

  useEffect(() => {
    const displayInfoObj = {
      firstName: displayData?.firstName || tr['firstName'],
      lastName: displayData?.lastName || tr['lastName'],
      services: structuredClone(displayData?.services) || [
        { serviceName: tr['service-name'] },
        // { serviceName: tr['service-name'] },
      ],
    };

    const availableSpace = container?.height + 6 - 39;
    const serviceLength = displayInfoObj.services.length;

    if (serviceLength < availableSpace) {
      const availableLength =
        serviceLength < Math.floor(availableSpace / 12.65)
          ? serviceLength
          : Math.floor(availableSpace / 12.65);

      const newLength =
        serviceLength === availableLength
          ? availableLength
          : availableLength - 1;

      displayInfoObj.services.length = newLength >= 0 ? newLength : 0;

      setShowServicesEllipsis(serviceLength !== availableLength);
    } else if (!availableSpace || availableSpace < 0) {
      displayInfoObj.services.length = 0;
    }

    setDisplayInfo(displayInfoObj);
  }, [displayData, container]);

  useEffect(() => {
    if (!displayInfo.services || timeOff) return;
    if (!serviceListRef.current.getBoundingClientRect().height) return;
  }, [container.height]);

  // Functions
  const checkSettingsForIcon = (element) => {
    if (!settings[element.id].show) return;

    return <Fragment key={element.id}>{element.icon}</Fragment>;
  };

  const getServiceHeight = () => {
    const max = container?.height + 6 - 39;
    const content = displayInfo?.services?.length * 12.65;

    return max > content
      ? showServicesEllipsis
        ? content + 12.65
        : content
      : max;
  };

  // Types
  const orderType = () => (
    <>
      <div className="service-card__content">
        {(settings.name.show || settings.lastName.show) && (
          <div className="service-card__name-wrapper">
            <div className="service-card__name">
              {settings.name.show && <span>{displayInfo?.firstName}</span>}{' '}
              {settings.lastName.show && <span>{displayInfo?.lastName}</span>}
            </div>
          </div>
        )}

        <div className="service-card__list-wrapper" ref={serviceListRef}>
          {settings.serviceName.show && (
            <ul className="service-card__list">
              {displayInfo?.services &&
                displayInfo.services.map((item, i) => (
                  <li key={i}>{item.serviceName}</li>
                ))}
              {showServicesEllipsis && <li>...</li>}
            </ul>
          )}
        </div>
        {settings.phone.show && (
          <div className="service-card__phone-wrapper">
            <span>+370 00 00 000</span>
          </div>
        )}
      </div>
      <div className="service-card__icons-list" ref={iconListRef}>
        {iconList.map((item, i) => (
          <div
            className="icon-wrapper"
            key={i}
            data-tooltip-id="tooltip"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(item?.label)}
          >
            {checkSettingsForIcon(item)}
          </div>
        ))}
      </div>
    </>
  );

  const timeOffType = () => (
    <S.TimeOffContainer>
      <span>{timeOff.title}</span>
      <span>{timeOff.duration}</span>
    </S.TimeOffContainer>
  );

  return (
    <S.AppointmentCard
      id={`order-${id}`}
      hoverDisplay={hoverDisplay}
      statusColor={statusObj?.background}
      containerHeight={container.height + 6}
      serviceListHeight={getServiceHeight()}
      ref={containerRef}
      onClick={onClick}
      timeOff={timeOff}
      width={container.width}
      iconContainerHeight={iconContainer.height || 0}
    >
      {timeOff ? timeOffType() : orderType()}
    </S.AppointmentCard>
  );
};

export default AppointmentCard;
