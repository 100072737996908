import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { OrderRowEntry } from './OrderRowEntry/OrderRowEntry.styled';

export const OrderRowEntries = styled.tr`
  td {
    padding: 0;

    :first-of-type {
      padding-left: 5px;

      .content {
        margin-left: 5px;
        border-radius: 5px 0 0 5px;

        border-radius: ${(props) =>
          props.isComp
            ? '5px 0 0 0'
            : props.compItem?.last
              ? '0 0 0 5px'
              : props.compItem
                ? '0'
                : '5px 0 0 5px'};
      }

      .background {
        border-radius: ${(props) => (props.lastChild ? '0 0 0 10px' : 0)};
      }
    }
    :last-of-type {
      padding-right: 5px;

      .content {
        margin-right: 5px;
        border-radius: 0 5px 5px 0;

        border-radius: ${(props) =>
          props.isComp
            ? '0 5px 0 0'
            : props.compItem?.last
              ? '0 0 5px 0'
              : props.compItem
                ? '0'
                : '0 5px 5px 0'};
      }
      .background {
        border-radius: ${(props) => (props.lastChild ? '0 0 10px 0' : 0)};
      }
    }
  }
`;
