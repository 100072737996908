import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const NavBarMobile = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .header-search-container {
    display: flex;
    visibility: ${({ searchIconVisible }) =>
      searchIconVisible ? 'visible' : 'hidden'};

    svg {
      color: ${colors.bg_Secondary};
      height: 16px;
      width: 16px;
    }

    @media screen and (min-width: 961px) {
      display: none;
    }
  }

  .search-container {
    display: flex;
    width: 100%;

    .search-exit {
      align-items: center;
      display: flex;
      padding: 5px;

      svg {
        fill: ${colors.bg_Primary};
      }
    }
  }
`;

export const BurgerContainer = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: 960px) {
    display: flex;
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }

  > svg {
    height: 100%;
    width: 100%;
    fill: white;
  }
`;

export const LogoContainer = styled.div`
  svg {
    display: block;
    height: 2rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 960px) {
    svg {
      height: 1.5rem;
    }
  }
`;
