import { useEffect } from 'react';

// style
import * as S from './ListSelector.styled';

// icons
import { BaseCheckbox, BaseRadioButton } from 'components/Inputs';

export const ListSelector = ({ state, type, legend }) => {
  const { current, options, setValue } = state;

  const getDefaultValue = () => {
    if (!('default' in state)) return;

    if (state.default instanceof Object) {
      return state.default;
    } else return options.find((option) => option.value === state.default);
  };

  useEffect(() => setValue(getDefaultValue()), []);

  return (
    <S.ListSelector className="list-selector">
      {legend && <legend>{legend}</legend>}

      <div className="options">
        {options?.map((option, index) => (
          <label key={index} onClick={() => setValue(option)}>
            {type === 'radio' && (
              <BaseRadioButton
                label={option.label}
                value={current?.value?.includes(option.value)}
              />
            )}
            {type === 'checkbox' && (
              <BaseCheckbox
                label={option.label}
                value={current?.value?.includes(option.value)}
              />
            )}
          </label>
        ))}
      </div>
    </S.ListSelector>
  );
};

export default ListSelector;
