import { useCallback } from 'react';

// style
import * as S from './HeaderRowCell.styled';

// components
import SortingArrows from 'components/SortingArrows';

// hooks
import { useTable } from 'hooks/useTable';

export const HeaderRowCell = ({ column }) => {
  const { sortOrder, sortColumn: appliedSortColumn, setSort } = useTable();

  const onSort = useCallback(
    (column) =>
      setSort(
        column.filter,
        column.id !== appliedSortColumn?.id
          ? 'asc'
          : sortOrder === 'asc'
            ? 'desc'
            : 'asc',
      ),
    [sortOrder, appliedSortColumn],
  );

  return (
    <S.HeaderRowCell isIcon={!!column?.icon}>
      {column?.icon ? column.icon : column.label}
      {column.sortable && (
        <SortingArrows
          direction={appliedSortColumn?.id === column.id ? sortOrder : null}
          onClick={() => onSort(column)}
        />
      )}
    </S.HeaderRowCell>
  );
};
