import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useLocation } from 'react-router-dom';

// style
import * as S from './OrderCardMobile.styled';

// icons
import { Check, Plus } from 'icons/dynamic';

// components
import OrderDetailsHeader from '../OrderDetailsHeader/OrderDetailsHeader';
import CustomSelect from 'components/DialogLayout/CustomSelect/CustomSelect';
import SimpleSelectChild from 'components/DialogLayout/CustomSelect/children/SimpleSelectChild/SimpleSelectChild';
import ServiceCard from '../ServiceCard/ServiceCard';
import TimeAndDateChild from 'components/DialogLayout/CustomSelect/children/TimeAndDateChild/TimeAndDateChild';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import SelectSMSDuration from 'components/DialogLayout/SelectSMSDuration/SelectSMSDuration';
import ServiceSelector from 'components/Cards/Services/ServiceSelector/ServiceSelector';
import DiscountModal from 'pages/Order/partials/OrderTable/partials/OrderRowEntries/OrderRowEntry/DiscountColumn/DiscountModal/DiscountModal';
import OrderRowBreak from 'pages/Order/partials/OrderTable/partials/OrderRowBreak/OrderRowBreak';
import OrderNoteMobile from 'components/DialogLayout/CustomSelect/children/TextChild/OrderNoteMobile';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useObserver from 'hooks/useObserver';
import useMediaQuery from 'hooks/useMediaQuery';
import { colors } from 'common/colors/colors';

const presetButtons = [
  {
    id: 'save',
    icon: <Check bold />,
    borderless: true,
    show: true,
  },
];

const OrderCard = ({
  index,
  disabled,
  toggledStatus,
  deleteService,
  setDisplayModal,
  modalToggle,
  modalIsShowing,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation();
  const multiSelect = location.state?.multi === 'multi-selects';

  // Hooks
  // -- state
  const [modalSwitch, setModalSwitch] = useState({
    content: '',
    flashMessages: '',
  });
  const [openedProduct, setOpenedProduct] = useState();

  // -- global
  const professionals = [];
  const {
    order,
    saveFunc,
    saveCond,
    products,
    addToOrder,
    updateServiceKey,
    professional,
    sectorId,
  } = useContext(OrderContext);

  // -- refs
  const displayRef = useRef();

  // console.log(professional);

  const searchParams = {
    ...(professional?.id && { employeeId: professional?.id }),
    ...(order?.state?.customer?.id && { customerId: order.state.customer.id }),
    sectorId,
    assignedToEmpOnly: 1,
  };

  // -- side effect
  useEffect(() => {
    switch (modalSwitch.content) {
      case 'select-service':
        setDisplayModal(
          <ServiceSelector
            handleAdd={addToOrder}
            data={{
              state: [],
              setState: () => {},
            }}
            close={handleCloseAction}
            searchParams={searchParams}
            showServiceAmountSelectionModal={false}
            toastTextOnAdd={tr['order-extended']}
            autoExpandCategories={true}
            includeUngroupedCategory={false}
          />,
        );
        break;
      case 'select-professionals':
        setDisplayModal(
          <SelectionPanel
            key={'select-professionals'}
            closeAction={() => setModalSwitch({ content: 'select-service' })}
            headerTitle={tr.professionals}
            searchTitle={tr.professionalSearch}
            array={professionals}
            arrayFilter={selectProfessionalsFilter}
            childProps={{
              selectedProf: professional.currSelectedProfessional,
              select: (prof) => selectProfessionals(prof),
            }}
            Child={SelectProfessionals}
          />,
        );
        break;
      case 'select-sms':
        setDisplayModal(
          <SelectionPanel
            key={'select-sms'}
            closeAction={() => handleCloseAction()}
            headerTitle="SMS"
            flashMessages={modalSwitch?.flashMessages}
          >
            <SelectSMSDuration
              service={openedProduct}
              select={(value) =>
                updateService(
                  { smsPeriodCustomer: value.value },
                  openedProduct.index,
                )
              }
            />
          </SelectionPanel>,
        );
        break;
      case 'select-discount': {
        setDisplayModal(
          <SelectionPanel
            key={'select-discount'}
            closeAction={() => handleCloseAction()}
            headerTitle={tr.discount}
            presetButtons={presetButtons}
          >
            <DiscountModal
              onSave={(value) =>
                updateService({ discountWithVat: value }, openedProduct.index)
              }
              price={openedProduct.unitPriceWithVat}
              discount={openedProduct.discountWithVat}
              quantity={openedProduct.quantity}
            />
          </SelectionPanel>,
        );
        break;
      }
      // case 'select-dateAndTime': {
      //   setDisplayModal(
      //     <SelectionPanel
      //       key={'select-dateAndTime'}
      //       closeAction={(cleanUp) => handleCloseAction(cleanUp)}
      //       Child={SelectDateAndTime}
      //       headerTitle={'Data | Laikas'}
      //     />,
      //   );
      //   break;
      // }
      default:
        setDisplayModal();
        return;
    }
  }, [modalSwitch, openedProduct, order.state.savedProducts]);

  const { width } = useObserver({
    ref: displayRef,
    debounceDelay: 10,
  });

  useEffect(() => {
    if (modalIsShowing) {
      setModalSwitch({ content: null, flashMessages: null });
      modalToggle();
    }
  }, [width, isMobile]);

  useEffect(() => {
    let x = order.state?.savedProducts;
    products.setSelectedProducts([].concat.apply([], x));
  }, [modalIsShowing]);

  // Functions
  const handleCloseAction = (action) => {
    switch (action) {
      case 'select-professionals':
        setModalSwitch({ content: action });
        break;
      default:
        saveFunc.setContextSaveFunc(() => () => console.log('default'));
        saveCond.setContextSaveCond(() => () => false);
        setModalSwitch({ content: null, flashMessages: null });
        if (modalIsShowing) modalToggle();
        return;
    }
  };

  const selectProfessionals = (prof) => {
    const newArr = [];

    if (multiSelect) {
      prof.forEach((item) => newArr.push(item.id));
    } else {
      newArr.push(prof.id);
    }

    professional.setCurrSelectedProfessional(newArr);

    setModalSwitch({ content: 'select-service' });
  };

  const selectProfessionalsFilter = (input, array, setState) => {
    if (input.length < 3) {
      setState(array);
    } else {
      let filteredInput = array.filter((item) => {
        return item.fieldName.toLowerCase().includes(input.toLowerCase());
      });
      setState(filteredInput);
    }
  };

  const updateService = (keyValue, index) => {
    updateServiceKey(keyValue, index);
    handleCloseAction();
  };

  return (
    <>
      <S.OrderCardMobile ref={displayRef}>
        <OrderDetailsHeader
          id={order.state.id}
          toggledStatus={toggledStatus}
          status={order.state.status}
          displayInfo={index === 0}
        />

        <div className="selectsContainer">
          <div className="selectsContainer_products">
            {!order.state.savedProducts?.length ? (
              <CustomSelect
                label={tr.services}
                action={() => {
                  modalToggle();
                  setModalSwitch({ content: 'select-service' });
                }}
                darkTheme={true}
                arrow={order.state.savedProducts?.length === 0}
                child={<SimpleSelectChild placeholder={tr.select} />}
                paddingSize="even"
              />
            ) : (
              <>
                {order.state.savedProducts.map((item, i) => {
                  const isBreak = item.serviceId === 1;

                  return isBreak ? (
                    order.state.savedProducts.length - 1 !== i && (
                      <div className="breakContainer" key={i}>
                        <OrderRowBreak
                          index={i}
                          data={item}
                          updateBreak={(obj) => updateServiceKey(obj, i)}
                          table={false}
                        />
                      </div>
                    )
                  ) : (
                    <CustomSelect
                      label={!i && tr.services}
                      key={i}
                      darkTheme={true}
                      paddingSize="even"
                    >
                      <SimpleSelectChild
                        placeholder={tr.select}
                        child={
                          <ServiceCard
                            key={i}
                            service={{ ...item, index: i }}
                            deleteService={deleteService}
                            openModal={modalToggle}
                            setModalContent={setModalSwitch}
                            opened={{ openedProduct, setOpenedProduct }}
                            updateService={updateService}
                          />
                        }
                      />
                    </CustomSelect>
                  );
                })}
                <div className="addMoreServices">
                  <div
                    onClick={() => {
                      setModalSwitch({ content: 'select-service' });
                      modalToggle();
                    }}
                  >
                    <Plus
                      black
                      inCircle
                      color={colors.bg_Button_Primary}
                      width={19}
                      height={19}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {!multiSelect ? (
            <CustomSelect
              label={`${tr.date} | ${tr.time}`}
              darkTheme={true}
              paddingSize="even"
              openInput={true}
            >
              <TimeAndDateChild
                disabled={disabled}
                darkTheme
                // arrow={
                //   order.state.savedProducts?.length
                //     ? () => {
                //         setModalSwitch({content: 'select-dateAndTime'});
                //         toggle();
                //       }
                //     : null
                // }
              />
            </CustomSelect>
          ) : null}

          <CustomSelect
            label={tr['note']}
            darkTheme={true}
            paddingSize="even"
            child={<OrderNoteMobile />}
          />
        </div>
      </S.OrderCardMobile>
    </>
  );
};

export default OrderCard;
