import { Loader } from 'components/Loader/Loader';

const Case = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Case;
