import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="m 25.003635,12.50556 c -0.6756,0 -1.2228,0.54717 -1.2228,1.22274 V 27.5544 H 6.22836 V 13.7283 c 0,-0.67557 -0.54717,-1.22274 -1.22274,-1.22274 -0.67557,0 -1.22274,0.54717 -1.22274,1.22274 V 27.6339 C 3.78288,28.9392 4.846665,30 6.151935,30 h 17.7084 c 1.3053,0 2.3691,-1.0608 2.3691,-2.3661 V 13.7283 c 0,-0.67557 -0.5472,-1.22274 -1.2228,-1.22274"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 11.22936,24.91635 v -7.3272 c 0,-0.6756 -0.54717,-1.2228 -1.22274,-1.2228 -0.67557,0 -1.22274,0.5472 -1.22274,1.2228 v 7.3272 c 0,0.6756 0.54717,1.2228 1.22274,1.2228 0.67557,0 1.22274,-0.5472 1.22274,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 16.227285,24.91635 v -7.3272 c 0,-0.6756 -0.5472,-1.2228 -1.22265,-1.2228 -0.67563,0 -1.2228,0.5472 -1.2228,1.2228 v 7.3272 c 0,0.6756 0.54717,1.2228 1.2228,1.2228 0.67545,0 1.22265,-0.5472 1.22265,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 21.228285,24.91635 v -7.3272 c 0,-0.6756 -0.54705,-1.2228 -1.22265,-1.2228 -0.6756,0 -1.2228,0.5472 -1.2228,1.2228 v 7.3272 c 0,0.6756 0.5472,1.2228 1.2228,1.2228 0.6756,0 1.22265,-0.5472 1.22265,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 28.378335,8.6937 h -2.15205 V 6.926835 c 0,-1.30527 -1.0638,-2.369055 -2.3691,-2.369055 h -3.6009 C 19.886385,1.9839 17.676285,0 15.001485,0 12.32676,0 10.116645,1.98696 9.746775,4.55778 h -3.60099 c -1.30527,0 -2.365995,1.063785 -2.365995,2.369055 V 8.6937 H 1.627755 c -0.675564,0 -1.22274,0.547185 -1.22274,1.22274 0,0.67557 0.547176,1.22274 1.22274,1.22274 h 26.74443 c 0.6756,0 1.2228,-0.54717 1.2228,-1.22274 0,-0.675555 -0.5472,-1.22274 -1.2228,-1.22274 M 15.004635,2.44548 c 1.32045,0 2.4363,0.895665 2.76945,2.1123 H 12.23811 c 0.333195,-1.216635 1.44894,-2.1123 2.769525,-2.1123 M 6.23139,7.00326 H 23.783835 V 8.6937 H 6.23139 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="m 4.576671,13.03518 c -0.35058,0 -0.63741,0.286845 -0.63741,0.637425 V 28.1706 c 0,1.0104 0.82227,1.8294 1.829385,1.8294 h 18.462825 c 1.01025,0 1.83255,-0.8223 1.83255,-1.8294 V 13.672605 c 0,-0.35058 -0.28695,-0.637425 -0.6375,-0.637425 -0.35055,0 -0.63735,0.286845 -0.63735,0.637425 V 28.1706 c 0,0.306 -0.24855,0.55455 -0.5577,0.55455 H 5.768646 c -0.305955,0 -0.55455,-0.24855 -0.55455,-0.55455 V 13.672605 c 0,-0.35058 -0.286845,-0.637425 -0.637425,-0.637425 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 10.424976,25.3374 v -7.6395 c 0,-0.35055 -0.28683,-0.63735 -0.63741,-0.63735 -0.35058,0 -0.637425,0.2868 -0.637425,0.63735 v 7.6395 c 0,0.35055 0.286845,0.63735 0.637425,0.63735 0.35058,0 0.63741,-0.2868 0.63741,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15.639021,25.3374 v -7.6395 c 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 -0.35064,0 -0.637485,0.2868 -0.637485,0.63735 v 7.6395 c 0,0.35055 0.286845,0.63735 0.637485,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 20.849871,25.3374 v -7.6395 c 0,-0.35055 -0.2868,-0.63735 -0.63735,-0.63735 -0.35055,0 -0.6375,0.2868 -0.6375,0.63735 v 7.6395 c 0,0.35055 0.28695,0.63735 0.6375,0.63735 0.35055,0 0.63735,-0.2868 0.63735,-0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 28.941921,9.06087 h -2.8812 V 6.581325 c 0,-1.01031 -0.82215,-1.829385 -1.83255,-1.829385 h -4.3344 C 19.814121,2.11941 17.650071,0 14.998431,0 12.346791,0 10.182756,2.11941 10.103076,4.75194 h -4.33443 c -1.010295,0 -1.829385,0.82227 -1.829385,1.829385 V 9.06087 H 1.058145 c -0.353766,0 -0.637416,0.286845 -0.637416,0.637425 0,0.350565 0.28365,0.63741 0.637416,0.63741 h 27.883776 c 0.3537,0 0.63735,-0.28365 0.63735,-0.63741 0,-0.353775 -0.28365,-0.637425 -0.63735,-0.637425 z M 15.001671,1.271646 c 1.95045,0 3.5472,1.545729 3.62685,3.477099 h -7.25061 c 0.07968,-1.93137 1.6764,-3.477099 3.62691,-3.477099 M 5.214096,6.581325 c 0,-0.30597 0.248595,-0.55455 0.55455,-0.55455 h 18.462825 c 0.30585,0 0.5577,0.24858 0.5577,0.55455 V 9.06087 H 5.214096 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
