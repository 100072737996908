// icons
import { Euro } from 'icons/dynamic';

// components
import Badge from 'components/Badge/Badge';

// hooks
import useTranslate from 'hooks/useTranslate';
import useStatusList from 'hooks/useStatusList';
import { useModifyOrderHandler } from './useModifyOrderHandler';
import {
  useOrderPaymentStatusPatch,
  useServicesModifyOrderStatus,
} from 'services/orderService';
import { colors } from 'common/colors/colors';
import { ORDER_COMPLETION_STATUS } from 'common/constants';
import { toSentenceCase } from 'common/helpers/stringOperations';

export const useAppointmentCard = ({ isPending, appointment }) => {
  const tr = useTranslate().use().global;
  const statusList = useStatusList(isPending ? 'pending-orders' : 'all');
  const currentStatus = useStatusList(
    appointment.completionStatus.toLowerCase(),
  );
  const { mutate: mutatePaymentStatus } = useModifyOrderHandler({
    context: useOrderPaymentStatusPatch({
      searchParams: {
        serviceListMode: 'both',
      },
    }),
    isPending,
  });
  const { mutate: mutateOrderStatus } = useModifyOrderHandler({
    context: useServicesModifyOrderStatus(),
    isPending,
  });

  const updatePaymentStatus = (payed) =>
    mutatePaymentStatus({
      id: appointment.id,
      version: appointment.version,
      isPaid: payed,
    });

  const getBalanceActions = () => [
    {
      id: '1.1',
      label: tr['paid-for-known'],
      icon: <Badge icon={<Euro />} sm color={colors.paidStatus} />,
      onClick: () => updatePaymentStatus(true),
    },
    {
      id: '2.1',
      label: tr['unpayed-for-known'],
      icon: <Badge icon={<Euro />} sm color={colors.debtStatus} />,
      onClick: () => updatePaymentStatus(false),
    },
  ];

  const getStatusActions = (action) => {
    if (currentStatus.id === ORDER_COMPLETION_STATUS.CONFIRMED) {
      return [
        {
          id: '1-cancel-accomplishment',
          label: tr['cancel-accomplished'],
          statusColor: colors.ordered,
          onClick: () => {
            mutateOrderStatus({
              id: appointment.id,
              version: appointment.version,
              completionStatus: toSentenceCase(ORDER_COMPLETION_STATUS.ORDERED),
            });
          },
        },
      ];
    } else {
      return statusList.map((item) => {
        return {
          id: `1-${item.id}`,
          label: item.title,
          statusColor: item.background,
          onClick: () => {
            const sameStatus =
              item.id.toLowerCase() ===
              appointment.completionStatus.toLowerCase();

            if (sameStatus) return;

            mutateOrderStatus({
              id: appointment.id,
              version: appointment.version,
              completionStatus: item.id,
            });
          },
        };
      });
    }
  };

  return {
    getBalanceActions,
    getStatusActions,
  };
};

export default useAppointmentCard;
