import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const InputValueNumber = styled.div`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  padding-inline: 10px;
  position: relative;

  :focus-within input {
    font-weight: 900;
  }

  &.custom {
    border-color: ${colors.text_Secondary};
  }

  .reset {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
    color: white;
    display: none;
    cursor: pointer;
  }

  &.custom .reset {
    display: block;
  }

  input {
    background-color: ${({ isChanged }) =>
      isChanged ? colors.info : 'transparent'};
    border: none;
    border-radius: 2.5px;
    color: inherit;
    text-align: right;
    padding: 0;
    width: 100%;

    :focus,
    :hover {
      background-color: revert;
      outline: none;
      cursor: ${({ disabled }) => !disabled && 'text'};
    }

    :disabled {
      -webkit-text-fill-color: ${colors.disabledGrey};
      opacity: 1;
    }

    &::-webkit-inner-spin-button {
      display: none;
    }
  }
`;
