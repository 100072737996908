import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

// style
import * as S from './Table.styled';

// components
import { MobileSort } from './partials/MobileSort/MobileSort';
import { MobileFilter } from './partials/MobileFilter/MobileFilter';
import { TableHeading } from './partials/TableHeading/TableHeading';
import { TableHead } from './partials/TableHead/TableHead';
import { TableRecord } from './partials/TableRecord/TableRecord';
import { RecordDeleteConfirmationDialog } from './partials/RecordDeleteConfirmationDialog/RecordDeleteConfirmationDialog';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import Loader from 'components/Loader/Loader';

export const Table = ({
  mobileElement,
  isLoading,
  isRecordInactive = () => null,
  actions,
}) => {
  const {
    action,
    setAction,
    navigation,
    delete: {
      action: deleteAction,
      warning: deleteWrning,
      activeRecordDeleteMessage,
    } = {},
  } = actions;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const {
    state: {
      data: { records },
      columns,
    },
  } = useContext(TableContext);

  const [showSort, setShowSort] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const MobileElement = mobileElement;

  const handleRecordDelete = useCallback((record) => {
    if (isRecordInactive(record)) {
      setAction({
        type: 'delete',
        record,
      });

      return;
    }

    if (activeRecordDeleteMessage) {
      toast.warning(activeRecordDeleteMessage);
    }
  }, []);

  return (
    <S.Table>
      <TableHeading
        onSortClick={() => setShowSort(true)}
        onFilterClick={() => setShowFilter(true)}
      />
      {mobileElement && isMobile ? (
        <S.DataCards>
          {records?.map((record) => (
            <MobileElement
              data={record}
              key={record.id}
              columns={columns}
              inactive={isRecordInactive(record)}
              actions={actions}
              onRecordDelete={handleRecordDelete}
            />
          ))}
        </S.DataCards>
      ) : (
        <S.TableScrollableContainer>
          <table>
            <TableHead
              hasNavigation={!!navigation}
              hasDeleteButton={!!deleteAction}
            />
            <tbody>
              {records?.map((record) => (
                <TableRecord
                  isInactive={isRecordInactive}
                  key={record.id}
                  data={record}
                  navigation={navigation}
                  hasDeleteButton={!!deleteAction}
                  onRecordDelete={handleRecordDelete}
                />
              ))}
            </tbody>
          </table>
        </S.TableScrollableContainer>
      )}
      {isMobile && (
        <>
          {showSort && <MobileSort onClose={() => setShowSort(false)} />}
          {showFilter && <MobileFilter onClose={() => setShowFilter(false)} />}
        </>
      )}
      {action?.type === 'delete' && (
        <RecordDeleteConfirmationDialog
          isVisible={!!action.record}
          onReject={() => setAction(null)}
          onConfirm={deleteAction}
          recordDeleteWarning={deleteWrning}
        />
      )}

      {isLoading && <Loader />}
    </S.Table>
  );
};
