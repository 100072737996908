import * as S from '../SVG.styled';

const VeterinarySVG = ({ color, width, height }) => {
  const content = (
    <>
      <path d="m150.32,35.78c1.84-.52,3.75-.78,5.68-.78,6.04,0,11.8,2.62,15.8,7.19,1.19,1.35,2.85,2.05,4.52,2.05,1.4,0,2.81-.49,3.95-1.49,2.49-2.18,2.74-5.97.56-8.47-6.28-7.17-15.33-11.28-24.83-11.28-3.03,0-6.04.41-8.94,1.23-3.19.9-5.05,4.21-4.15,7.4.9,3.19,4.21,5.05,7.4,4.15Z" />
      <path d="m205.92,262.69c-3.21-.82-6.48,1.11-7.31,4.31-5,19.43-22.53,32.99-42.62,32.99-24.26,0-44-19.74-44-44v-56h-56c-24.26,0-44-19.74-44-44s19.74-44,44-44h56v-56c0-24.26,19.74-44,44-44s44,19.74,44,44v8.85c0,3.31,2.69,6,6,6s6-2.69,6-6v-8.85c0-30.88-25.12-56-56-56s-56,25.12-56,56v44h-44C25.12,100,0,125.12,0,156s25.12,56,56,56h44v44c0,30.88,25.12,56,56,56,25.57,0,47.87-17.27,54.24-42,.83-3.21-1.11-6.48-4.31-7.31Z" />
      <path d="m111.21,166.52c0,15.87,12.91,28.78,28.78,28.78s28.78-12.91,28.78-28.78-12.91-28.78-28.78-28.78-28.78,12.91-28.78,28.78Zm45.55,0c0,9.25-7.53,16.78-16.78,16.78s-16.78-7.53-16.78-16.78,7.53-16.78,16.78-16.78,16.78,7.53,16.78,16.78Z" />
      <path d="m281.26,137.75c-15.87,0-28.78,12.91-28.78,28.78s12.91,28.78,28.78,28.78,28.78-12.91,28.78-28.78-12.91-28.78-28.78-28.78Zm0,45.55c-9.25,0-16.78-7.53-16.78-16.78s7.53-16.78,16.78-16.78,16.78,7.53,16.78,16.78-7.53,16.78-16.78,16.78Z" />
      <path d="m231.55,130.27c4.43,2.56,9.35,3.87,14.34,3.87,2.5,0,5.02-.33,7.5-.99,7.42-1.99,13.63-6.75,17.47-13.41,7.93-13.74,3.21-31.37-10.53-39.31-6.66-3.84-14.41-4.86-21.84-2.88-7.42,1.99-13.63,6.75-17.47,13.41-7.93,13.74-3.21,31.38,10.53,39.31Zm-.14-33.31c2.24-3.88,5.86-6.66,10.19-7.82,4.33-1.16,8.85-.56,12.73,1.68,8.01,4.62,10.76,14.9,6.14,22.92-2.24,3.88-5.86,6.66-10.19,7.82-4.33,1.16-8.85.57-12.73-1.68-8.01-4.62-10.76-14.91-6.14-22.92Z" />
      <path d="m189.69,130.27c6.66-3.84,11.42-10.05,13.41-17.47s.97-15.18-2.87-21.84c-3.84-6.66-10.05-11.42-17.47-13.41-7.42-1.99-15.18-.97-21.83,2.88s-11.42,10.05-13.41,17.47c-1.99,7.42-.97,15.18,2.87,21.83,5.32,9.22,15.02,14.38,24.97,14.38,4.88,0,9.82-1.24,14.34-3.85Zm-28.92-16.53c-2.24-3.88-2.84-8.4-1.68-12.73s3.94-7.95,7.82-10.19c2.58-1.49,5.45-2.25,8.36-2.25,1.46,0,2.92.19,4.37.58,4.33,1.16,7.95,3.94,10.19,7.82s2.84,8.4,1.68,12.73c-1.16,4.33-3.94,7.95-7.82,10.19-8.01,4.62-18.29,1.87-22.92-6.14Z" />
      <path d="m253.06,195.46l-5.81-3.35c-5.06-2.92-8.2-8.37-8.2-14.21v-6.77c0-15.66-12.74-28.41-28.4-28.41s-28.41,12.74-28.41,28.41v6.71c0,5.84-3.14,11.29-8.2,14.21l-5.87,3.39c-6.57,3.79-11.27,9.92-13.23,17.25-1.96,7.33-.96,14.98,2.84,21.55,3.79,6.57,9.92,11.27,17.25,13.24,7.33,1.96,14.98.96,21.55-2.84l5.81-3.35c5.06-2.92,11.34-2.92,16.4,0l5.87,3.39c4.46,2.58,9.34,3.8,14.15,3.8,9.82,0,19.39-5.09,24.65-14.2,7.83-13.56,3.17-30.97-10.4-38.8Zm0,32.8c-4.52,7.83-14.58,10.53-22.41,6l-5.86-3.39c-4.38-2.53-9.29-3.79-14.2-3.79s-9.82,1.26-14.2,3.79l-5.81,3.35c-3.8,2.19-8.22,2.77-12.45,1.64-4.23-1.13-7.77-3.85-9.96-7.64-4.52-7.83-1.83-17.89,6-22.41l5.87-3.39c8.76-5.06,14.2-14.48,14.2-24.6v-6.71c0-9.05,7.36-16.41,16.41-16.41s16.4,7.36,16.4,16.41v6.77c0,10.12,5.44,19.54,14.2,24.6l5.81,3.35c7.83,4.52,10.53,14.58,6,22.41Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 310.03 312"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default VeterinarySVG;
