import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const TableRecord = styled.tr`
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? '#fafafa' : props.details && colors.bg_grey_1};
  height: ${(props) => (props.details ? '16px' : '32px')};

  td {
    ${({ inactive }) =>
      inactive &&
      css`
        color: ${colors.bg_grey_3};
        filter: grayscale(1);
      `}
  }
`;
