// style
import * as S from './ServiceGroup.styled';

// components
import { ServiceContents } from '../ServiceContents/ServiceContents';
import { ServiceTree } from '../ServiceTree/ServiceTree';
import { Message } from 'components/Message/Message';
import { TheButton } from 'components/Buttons/TheButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

export const ServiceGroup = ({
  group,
  serviceActivity,
  activeGroup,
  getDbItem,
  initialButtons,
  hasAnyChanges,
  close,
  updateCache,
  toggleActivity,
  isDisabledByParent,
  getItem,
  updatePropDownstream,
  hasValueChanged,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  const selectAll = () => updatePropDownstream(group, 'isEmpAssigned', true);

  const deselectAll = () => updatePropDownstream(group, 'isEmpAssigned', false);

  // debug
  // useEffect(() => console.log({ localCache }), [localCache]);

  return (
    <S.ServiceGroup>
      {!isMobile ? (
        <>
          {(group?.subgroups || group?.services) && (
            <>
              <Message>
                <span>
                  {tr['employee-services-info-01']}
                  <b>{tr['employee-services-info-02']}</b>
                  {tr['employee-services-info-03']}
                </span>
              </Message>

              <div className="controls">
                <TheButton
                  title={tr['select-all']}
                  secondary
                  outline
                  flat
                  action={selectAll}
                />
                <TheButton
                  title={tr['deselect-all']}
                  secondary
                  outline
                  flat
                  action={deselectAll}
                />
              </div>

              <div className="header">
                <span>{tr['service-name']}</span>
                <span>{tr['price']}</span>
                <span>{tr['duration']}</span>
                <span>{tr['break']}</span>
              </div>
            </>
          )}

          {group?.subgroups?.map((subgroup) => (
            <ServiceContents
              className="subgroup"
              key={subgroup.id}
              contents={subgroup}
              getDbItem={getDbItem}
              getItem={getItem}
              toggleActivity={toggleActivity}
              updateCache={updateCache}
              isDisabledByParent={isDisabledByParent}
              hasValueChanged={hasValueChanged}
            />
          ))}

          {group?.services?.map((service) => (
            <ServiceContents
              className={service.type}
              key={service.id}
              contents={service}
              getDbItem={getDbItem}
              getItem={getItem}
              toggleActivity={toggleActivity}
              updateCache={updateCache}
              isDisabledByParent={isDisabledByParent}
              hasValueChanged={hasValueChanged}
            />
          ))}
        </>
      ) : (
        <ServiceTree
          data={group}
          id={activeGroup.id}
          getDbItem={getDbItem}
          getItem={getItem}
          toggleActivity={toggleActivity}
          updateCache={updateCache}
          initialButtons={initialButtons}
          hasAnyChanges={hasAnyChanges}
          hasValueChanged={hasValueChanged}
          close={close}
          isDisabledByParent={isDisabledByParent}
          updatePropDownstream={updatePropDownstream}
        />
      )}
    </S.ServiceGroup>
  );
};
