import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

const useTooltipWhenOverflows = (ref, text) => {
  useEffect(() => {
    if (!ref.current) return;

    const current = ref.current;

    if (current.offsetWidth < current.scrollWidth) {
      current.setAttribute('data-tooltip-id', 'tooltip');
      current.setAttribute(
        'data-tooltip-html',
        ReactDOMServer.renderToStaticMarkup(text),
      );
    }
  }, [ref.current]);
};

export default useTooltipWhenOverflows;
