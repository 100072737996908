import * as S from '../SVG.styled';

const ContentCreationSVG = ({ color, width, height }) => {
  const content = (
    <>
      <path d="M16.78,99.99c-1.51-7.15-2.68-14.54-3.48-21.97-.35-3.3-3.31-5.68-6.6-5.33-3.29.35-5.68,3.31-5.33,6.6.84,7.83,2.07,15.63,3.67,23.18.6,2.82,3.09,4.76,5.86,4.76.41,0,.83-.04,1.25-.13,3.24-.69,5.31-3.87,4.63-7.11Z" />
      <path d="M75.24,130.51c-2.7-.35-5.3,1.18-6.32,3.71-1.69,4.2-2.55,8.59-2.55,13.07,0,21.99,20.86,39.88,46.5,39.88s46.5-17.89,46.5-39.88c0-4.48-.86-8.88-2.55-13.07-1.02-2.53-3.62-4.06-6.32-3.71-.2.03-20.37,2.55-37.63,2.55s-37.43-2.52-37.63-2.55ZM147.37,147.29c0,15.37-15.47,27.88-34.5,27.88s-34.5-12.51-34.5-27.88c0-1.45.14-2.88.41-4.3,7.29.78,21.26,2.06,34.09,2.06s26.79-1.28,34.08-2.06c.27,1.41.41,2.85.41,4.3Z" />
      <path d="M85.19,63.63c-11.43-9.23-29.7-8.09-39.11,2.43-6.42,7.19-6.56,13.21-5.55,17,3.15,11.82,20.16,15.4,25.3,16.22,2.71.43,5.59.68,8.46.68,8.15,0,16.2-2.04,19.92-7.77,5.91-9.11-1.44-22.44-9.02-28.57ZM84.14,85.66c-.93,1.43-7.21,3.24-16.43,1.77-9.69-1.54-15.03-5.36-15.59-7.46-.25-.95.36-3.07,2.91-5.92,2.77-3.1,7.23-4.7,11.76-4.7,3.91,0,7.87,1.18,10.86,3.6,5.09,4.12,7.52,11.13,6.5,12.7Z" />
      <path d="M151.46,99.97c2.87,0,5.75-.25,8.46-.68,5.14-.82,22.16-4.4,25.3-16.22,1.01-3.79.87-9.82-5.56-17.01-9.41-10.53-27.68-11.66-39.11-2.43-7.58,6.12-14.93,19.46-9.02,28.57,3.72,5.74,11.77,7.77,19.92,7.77ZM148.1,72.96c6.46-5.22,17.45-4.69,22.62,1.1,2.55,2.85,3.16,4.97,2.91,5.92-.56,2.1-5.9,5.91-15.59,7.46-9.23,1.47-15.51-.35-16.43-1.77-1.02-1.57,1.4-8.58,6.5-12.7Z" />
      <path d="M285.67,240.96c-2.92-1.57-6.56-.48-8.13,2.44-1.34,2.49-2.75,4.95-4.18,7.29-1.73,2.83-.83,6.52,2,8.25.98.6,2.05.88,3.12.88,2.02,0,4-1.02,5.13-2.88,1.55-2.54,3.06-5.18,4.51-7.87,1.57-2.92.47-6.56-2.44-8.13Z" />
      <path d="M310.06,115.02c-.91-6.75-4.43-12.66-9.93-16.67-5.49-4-12.2-5.54-18.89-4.33-15.36,2.77-37.64,6.19-60.37,7.7,3.2-15.44,4.87-31.65,4.87-48.24,0-10.8-.71-21.59-2.12-32.08-.91-6.75-4.43-12.66-9.93-16.67-5.49-4-12.2-5.54-18.89-4.33-21.42,3.86-53.02,8.46-81.93,8.46S52.36,4.26,30.94.4c-6.69-1.21-13.4.33-18.89,4.33C6.56,8.74,3.03,14.66,2.12,21.4.71,31.9,0,42.69,0,53.48c0,3.31,2.69,6,6,6s6-2.69,6-6c0-10.26.68-20.52,2.02-30.49.47-3.47,2.28-6.51,5.1-8.56,2.82-2.05,6.26-2.84,9.69-2.22,21.9,3.95,54.26,8.65,84.06,8.65s62.16-4.7,84.06-8.65c3.43-.62,6.88.17,9.69,2.22,2.82,2.06,4.63,5.1,5.1,8.56,1.34,9.97,2.02,20.22,2.02,30.49,0,18.62-2.21,36.47-6.25,52.99-.04.13-.07.27-.1.4-14.32,58.06-51.29,99.5-94.52,99.5-37.06,0-71.09-30.87-88.82-80.56-1.11-3.12-4.54-4.75-7.67-3.63-3.12,1.11-4.75,4.55-3.63,7.67,17,47.64,48.37,79.53,84.17,86.89,18.43,58,58.33,95.26,102.39,95.26,26.52,0,52.24-13.59,72.44-38.26,2.1-2.56,1.72-6.34-.84-8.44-2.56-2.1-6.35-1.72-8.44.84-17.88,21.84-40.31,33.86-63.16,33.86-37.42,0-71.78-31.78-89.21-81.69.92.03,1.84.07,2.77.07,30.61,0,59.21-17.47,80.55-49.19,10.8-16.06,19.08-34.84,24.56-55.27,24.56-1.39,48.9-5.12,65.39-8.09,3.43-.62,6.88.17,9.69,2.22,2.82,2.06,4.63,5.1,5.1,8.56,1.34,9.97,2.02,20.23,2.02,30.49,0,28.97-5.41,57.19-15.64,81.6-1.28,3.06.16,6.57,3.21,7.85.76.32,1.54.47,2.32.47,2.35,0,4.57-1.38,5.54-3.68,10.84-25.86,16.57-55.68,16.57-86.24,0-10.79-.71-21.59-2.12-32.08Z" />
      <path d="M199.31,224.08c-25.64,0-46.5,17.89-46.5,39.88,0,4.48.86,8.88,2.55,13.07.92,2.29,3.14,3.76,5.56,3.76.25,0,.5-.02.75-.05.2-.03,20.37-2.55,37.63-2.55s37.43,2.52,37.63,2.55c2.7.34,5.3-1.18,6.32-3.71,1.69-4.2,2.55-8.59,2.55-13.07,0-21.99-20.86-39.88-46.5-39.88ZM233.4,268.26c-7.29-.78-21.26-2.06-34.09-2.06s-26.8,1.28-34.09,2.06c-.27-1.42-.41-2.85-.41-4.3,0-15.37,15.47-27.88,34.5-27.88s34.5,12.51,34.5,27.88c0,1.45-.14,2.88-.41,4.3Z" />
      <path d="M271.66,176.69c1.01-3.79.87-9.82-5.56-17-9.41-10.53-27.68-11.67-39.11-2.43-7.58,6.12-14.93,19.46-9.02,28.57,3.72,5.74,11.77,7.77,19.92,7.77,2.87,0,5.75-.25,8.46-.68,5.14-.82,22.16-4.4,25.3-16.22ZM228.04,179.29c-1.02-1.57,1.4-8.58,6.5-12.7,6.46-5.22,17.45-4.69,22.62,1.1,2.55,2.85,3.16,4.97,2.91,5.92-.56,2.1-5.9,5.91-15.59,7.46-9.23,1.47-15.51-.35-16.43-1.77Z" />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 312.18 312"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};

export default ContentCreationSVG;
