import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPendingOrders } from 'redux/actions/pendingOrdersAction';
import { useServicesGetPendingOrders } from 'services/orderService';

export const usePendingOrders = (sectorId, employeeIds) => {
  const dispatch = useDispatch();

  const pendingOrdersBE = useServicesGetPendingOrders({
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      enabled: !!sectorId,
    },
    searchParams: {
      sectorId,
      serviceListMode: 'both',
      ...(employeeIds && { employeeIds }),
    },
  });

  // SUCCESS
  useEffect(() => {
    const data = pendingOrdersBE.data;

    if (!data) return;

    dispatch(setPendingOrders(data));
  }, [pendingOrdersBE?.data]);

  // ERROR
  // TODO:

  return pendingOrdersBE;
};
