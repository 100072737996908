import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const TimeAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
  width: 100%;

  .date-time-select-wrapper {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
    justify-content: space-between;
    min-height: 25px;
    row-gap: 5px;
    width: 100%;
  }

  .date-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .date-select {
      align-items: center;
      background-color: ${(props) =>
        props.disabled
          ? colors.bg_grey_1
          : props.darkTheme
            ? 'white'
            : colors.bg_grey_1};
      border-radius: 5px;
      color: ${(props) =>
        props.disabled
          ? colors.bg_grey_3
          : props.openCalendor
            ? colors.bg_Button_Primary
            : colors.bg_Primary};
      display: flex;
      font-family: ${fontFamilies.family_Secondary};
      /* font-size: 16px; */
      min-height: 25px;
      padding: 0px 10px;

      .date-select__format {
        padding-right: 5px;
      }
    }

    .warning-icon {
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 100%;
    }
  }

  .time-select {
    align-items: center;
    display: flex;

    .time-input {
      align-items: center;
      background-color: ${(props) =>
        props.darkTheme ? 'white' : colors.bg_grey_1};
      border-radius: 5px;
      display: flex;
      min-height: 25px;
      /* min-width: 50%; */
      padding: 2px 10px;
    }

    .long-dash {
      padding: 2px 5px;
    }

    .time-picker-icon {
      align-items: center;
      display: flex;
      margin-right: -3px;
      padding-left: 15px;
    }
  }

  .calendar-wrapper {
    border-radius: 3px;
    height: ${(props) => (props.openCalendor ? 220 : 0)}px;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .date-select__open-calendar {
    background-color: white;
    margin-top: 10px;
    padding: 10px;
  }
`;
