import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

import { InputField } from 'components/InputField/InputField.styled';

export const SearchProfessionalsContainer = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${colors.bg_Secondary};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px 0 40px;

  box-shadow: 2px 0 3px rgba(0, 0, 0, 50%);

  .inputsWrapper {
    flex: 1;

    display: flex;
    height: 100%;

    .select_wrapper {
      max-width: ${100 / 3}%;
      min-width: ${100 / 3}%;

      height: 100%;

      display: flex;
      align-items: center;

      ${InputField} {
        height: 65px;
        width: 100%;

        .select {
          height: 100%;
        }

        .select__control {
          height: 100%;
          border-bottom: 0;
          border-top: 0;

          border-radius: 0;
        }

        .inputField__select_wrapper {
          height: 100%;
        }

        .select__menu {
          margin-top: 3px;
        }

        .select__input {
          height: 35px;
        }

        .select__indicator-separator {
          display: none;
        }

        :last-child .select__control {
          border-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 0 20px 10px 20px;

    font-size: 14px;

    .inputsWrapper {
      width: 100%;
      flex-direction: column;

      .select_wrapper {
        min-width: 100%;
        max-width: 100%;
      }

      .select_wrapper ${InputField} {
        height: 40px;

        .select__input {
          height: 20px;
        }

        .select__control {
          border-left: 0;
          border-bottom: 1px solid ${colors.bg_grey_2};
        }
      }

      .select_wrapper:last-child .select__control {
        border-bottom: 0;
      }
    }
  }

  .label {
    width: 35%;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 900;
    font-size: 18px;

    @media screen and (max-width: 960px) {
      width: 100%;
      font-size: 10px;
      margin: 15px 0;
    }
  }

  .exitBtn {
    border-left: 1px solid ${colors.bg_grey_2};
    height: 65px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn_wrapper {
      padding: 2px 6px;

      cursor: pointer;

      :hover {
        background-color: ${colors.bg_grey_1};
        border-radius: 3px;
      }
    }

    @media screen and (max-width: 960px) {
      border: none;
      width: fit-content;
      height: fit-content;

      padding: 0;
      right: 8px;
      top: 8px;

      position: absolute;

      .btn_wrapper {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
