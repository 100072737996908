import { useContext, useEffect } from 'react';
import AutoNumeric from 'autonumeric';

// contexts
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import { useSystemGetCultureById } from 'services/systemService';

export const useNumber = ({ price } = {}) => {
  const { locale } = useContext(UserContext);
  const { sectorData, adjustSectorData } = useContext(MainContext);

  const { isSuccess: isCultureSuccess, data: culture } =
    useSystemGetCultureById({
      id: locale,
    });

  useEffect(() => {
    if (price) {
      adjustSectorData();
    }
  }, []);

  let formatOptions = {};
  let currencySymbolPlacement = 's';
  let currencySymbol = sectorData?.settings?.currencySymbol ?? '';

  if (culture?.numberFormatInfo?.currencyPositivePattern !== undefined) {
    if ([0, 2].includes(culture.numberFormatInfo.currencyPositivePattern)) {
      currencySymbolPlacement = 'p';
    }

    switch (culture.numberFormatInfo.currencyPositivePattern) {
      case 2:
        currencySymbol += ' ';

        break;
      case 3:
        currencySymbol = ' ' + currencySymbol;

        break;
    }
  }

  if (isCultureSuccess) {
    formatOptions = {
      decimalPlaces: +culture.numberFormatInfo.numberDecimalDigits,
      decimalCharacter: culture.numberFormatInfo.numberDecimalSeparator,
      decimalCharacterAlternative:
        culture.numberFormatInfo.numberDecimalSeparator === ',' ? '.' : ',',
      digitGroupSeparator: culture.numberFormatInfo.numberGroupSeparator,
      digitalGroupSpacing:
        culture.numberFormatInfo.numberGroupSizes[0].toString(),
    };
  }

  const format = (number, options = {}) =>
    AutoNumeric.format(number, { ...formatOptions, ...options });

  const formatPrice = (number, fourDecimalPlaces, options = {}) =>
    AutoNumeric.format(number, {
      ...formatOptions,
      currencySymbolPlacement,
      decimalPlaces: fourDecimalPlaces ? 4 : 2,
      currencySymbol,
      ...options,
    });

  const formatUnits = (number) =>
    AutoNumeric.format(number, {
      ...formatOptions,
      decimalPlaces: 3,
      allowDecimalPadding: false,
    });

  return {
    format,
    formatPrice,
    formatUnits,
    currencySymbol,
    currencySymbolPlacement,
  };
};

export default useNumber;
