import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const TimeOff = styled.div`
  width: 100vw;

  .time-event__wrapper {
    padding: 20px 10px;
  }

  .proffesionalTimeAndDate {
    margin-top: 20px;
  }

  /* Priezastys */
  .excusesSelect {
    margin-top: 10px;
  }

  /* Pasikartojantys */
  .selectLabel {
    color: ${colors.searchInpuyPlaceholder};
    font-size: 12px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 500;
  }

  .selectContainer {
    height: 35px;
    background-color: white;
    border-radius: 3px;
    margin-top: 5px;

    padding: 5px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    .date_select_openCalendar {
      > div {
        top: 35px;
        left: 0px;
        width: 100%;
      }
    }
  }

  .selectContainer {
    align-items: flex-start;
    height: ${(props) => props.openCalendar && 280}px;
    transition-duration: 500ms;
  }

  .iconContainer {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(50%, -55%);
    svg {
      fill: ${colors.bg_Primary};
      height: 7.806px;
      width: 13.676px;
      transform: rotate(90deg);
    }
  }
`;
