import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const IconValue = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  .icon-wrapper {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .value-wrapper {
    margin-left: 5px;
    font-family: ${fontFamilies.family_Secondary};
    font-size: 14px;
  }
`;
