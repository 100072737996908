import { employeeHeaders } from 'models/employee';

const employeeReducer = (
  state = {
    headers: employeeHeaders,
    // data: [],
    // status: 'idle',
    filterStatus: false,
  },
  action,
) => {
  switch (action.type) {
    // case 'EMPLOYEE_REQUEST':
    //   return { ...state, status: 'loading', data: [] };
    // case 'EMPLOYEE_SUCCESS':
    // case 'EMPLOYEE_UPDATE':
    //   return {
    //     ...state,
    //     status: 'success',
    //     data: action.payload,
    //   };
    // case 'EMPLOYEE_FAIL':
    //   return {
    //     ...state,
    //     status: 'failed',
    //     data: action.payload,
    //   };
    case 'EMPLOYEE_UPDATE_FILTER_STATUS':
      return { ...state, filterStatus: action.payload };
    case 'EMPLOYEE_TABLE_UPDATE':
      return { ...state, headers: action.payload };
    default:
      return state;
  }
};

export default employeeReducer;
