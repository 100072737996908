import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const Header = styled.nav`
  align-items: center;
  background-color: ${(props) =>
    props.searchMode ? 'white' : colors.bg_Primary};
  box-shadow: ${(props) =>
    props.searchMode ? shadow.search : '0px 2px 3px 0px rgba(0, 0, 0, 0.5)'};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding-left: 40px;
  padding-right: 40px;
  position: fixed;
  top: ${(props) => (props.shiftBy ? `-${props.shiftBy}px` : '0')};
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 5;

  @media (max-width: 960px) {
    align-items: center;
    display: flex;
    height: 54px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 2;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const SearchIcon = styled.img`
  display: none;

  @media (max-width: 960px) {
    display: block;
    height: 22px;
    width: 22px;
  }
`;
