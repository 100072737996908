import styled from 'styled-components/macro';

export const OfficeSelector = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 20%);

  padding: 30px;
  position: absolute;
  top: calc(100% + 2px);

  button {
    width: 100%;
  }
`;
