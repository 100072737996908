import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// style
import * as S from './DialogBox.styled';

// icons
import { Ex } from 'icons/dynamic';

// components
import DialogButton from './DialogButton/DialogButton';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';

export const DialogBox = ({
  close,
  closeIfConfirmed,
  closeIfRejected,
  headerText,
  headerIcon,
  children,
  buttons,
  cancelOverflow,
  type,
}) => {
  const isMobile = useMediaQuery('(max-width: 960px');
  const ChildrenFunction = children; // in case a render function is provided

  // calculate available height and maintain on resize
  const headerRef = useRef();
  const footerRef = useRef();

  const updateMainHeight = useCallback(() => {
    setMainHeight(
      window.innerHeight -
        headerRef?.current?.clientHeight -
        footerRef?.current?.clientHeight -
        (isMobile ? 40 : 100),
    );
  }, [headerRef, footerRef, isMobile]);

  const [mainHeight, setMainHeight] = useState();

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      updateMainHeight();
    }

    window.addEventListener('resize', updateMainHeight);

    return () => {
      window.removeEventListener('resize', updateMainHeight);
    };
  }, [updateMainHeight]);

  return (
    <S.DialogBox
      onClick={(e) => {
        e.stopPropagation();
      }}
      type={type}
    >
      <S.Header ref={headerRef}>
        <div className="title-container">
          {headerIcon && <div className="icon">{headerIcon}</div>}
          <span className="title">{headerText}</span>
        </div>

        <div
          className="exit-icon"
          onClick={closeIfRejected ? closeIfRejected : close}
        >
          <Ex black width={14} height={14} />
        </div>
      </S.Header>

      {children && (
        <S.Main height={mainHeight} cancelOverflow={cancelOverflow}>
          {typeof children === 'function' ? (
            <ChildrenFunction buttons={buttons} />
          ) : (
            Children.map(children, (child) => cloneElement(child, { buttons }))
          )}
        </S.Main>
      )}

      <S.Footer buttons={buttons?.length} ref={footerRef}>
        {buttons?.length &&
          buttons.map((button, i) => {
            if (Array.isArray(button)) {
              return (
                <div className="button-group" key={i}>
                  {button.map((item) => (
                    <DialogButton
                      key={item.id}
                      data={item}
                      close={closeIfConfirmed ? closeIfConfirmed : close}
                    />
                  ))}
                </div>
              );
            } else
              return (
                <DialogButton
                  key={button.id}
                  data={button}
                  close={closeIfConfirmed ? closeIfConfirmed : close}
                />
              );
          })}
      </S.Footer>
    </S.DialogBox>
  );
};

export default DialogBox;
