import { useEffect, useState } from 'react';

export const useResizeObserver = (id) => {
  const [dimensions, setDimensions] = useState();

  useEffect(() => {
    const onResize = (element) => {
      setDimensions({
        width: element.offsetWidth,
        height: element.offsetHeight,
        x: element.offsetLeft,
        y: element.offsetTop,
        parent: element.offsetParent,
      });
    };

    const headerToStickTo = document.getElementById(id);
    const headerObserver = new ResizeObserver(() => onResize(headerToStickTo));
    headerObserver.observe(headerToStickTo);

    return () => headerObserver.unobserve(headerToStickTo);
  }, [id]);

  return dimensions;
};
