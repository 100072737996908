import styled from 'styled-components/macro';
import { InputFieldGroup } from '../../ServiceDetails/ServiceGeneralData/ServiceGeneralData.styled';

export const SectorSelectorItem = styled.li`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    flex-direction: row-reverse;
    gap: 10px;
    justify-content: flex-end;

    span.labelText {
      /* margin-left: 5px; */
      margin-inline: 0;
      padding: 0;
    }
  }

  ${InputFieldGroup} {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: min-content;

    > div {
      /* flex-basis: min-content; */
      /* min-width: 125px; */
    }
  }

  @media (max-width: 960px) {
    ${InputFieldGroup} {
      flex-wrap: wrap;
      width: revert;

      > div {
        /* flex-basis: calc(50% - 10px); */
        /* min-width: revert; */
      }
    }
  }

  @media (min-width: 961px) {
    > div {
      flex-basis: min-content;
    }
  }
`;
