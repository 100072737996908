import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { RootContainer, ScrollableContainer } from 'pages/Pages.styled';

export { RootContainer, ScrollableContainer };

export const ContactUs = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  width: 100%;
`;

export const Container = styled.div`
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  height: calc(100vh - 206.5px);
  overflow: auto;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 10px;
    height: unset;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .info {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    gap: 40px;
    height: fit-content;

    @media screen and (max-width: 960px) {
      font-size: 1rem;
    }
  }

  h2 {
    font-family: ${fontFamilies.family_Primary};
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0 0 20px;

    @media screen and (max-width: 960px) {
      font-size: 1.125rem;
    }
  }

  .info__description {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .info__description > div {
    align-items: center;
    display: flex;
    gap: 20px;
    white-space: nowrap;
  }

  .info__description svg {
    height: 20px;
    width: 20px;
  }

  .picture {
    flex: 1 0 auto;
    height: fit-content;
    max-width: 50vh;
    min-width: 30vh;

    @media screen and (max-width: 960px) {
      max-width: calc(100vw - 20px);
      min-width: calc(100vw - 20px);
    }
  }

  .picture svg {
    /* max-height: calc(100% - 50px); */
  }
`;
