import { useContext, useEffect } from 'react';

// style
import * as S from './SearchWidget.styled';

// icons
import { Ex } from 'icons/dynamic';

// components
import { DebouncedSearch } from 'components/DebouncedSearch/DebouncedSearch';
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';

// contexts
import { MainContext } from 'pages/Main/Main';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

export const SearchWidget = (props) => {
  const isDarkThemeOverriden = 'darkTheme' in props;
  const { queryId, darkTheme, ...restProps } = props;

  const isMobile = useMediaQuery('(max-width: 960px)');

  const { setState, setSearchQuery, searchWidgetVisible, searchQueries } =
    useContext(MainContext);

  const close = () => {
    setState({ type: 'hide-search-widget' });
    setSearchQuery('', queryId);
  };

  const Wrapper = (children) => {
    return (
      <>
        {children}

        <div className="close-button" onClick={close}>
          <Ex black />
        </div>
      </>
    );
  };

  return (
    (!isMobile ||
      (searchQueries && searchQueries[queryId] !== '') ||
      searchWidgetVisible) && (
      <S.SearchWidget isMobile={isMobile}>
        <ConditionalWrapper condition={isMobile} wrapper={Wrapper}>
          <DebouncedSearch
            autofocus
            queryId={queryId}
            darkTheme={isDarkThemeOverriden ? darkTheme : !isMobile}
            {...restProps}
          />
        </ConditionalWrapper>
      </S.SearchWidget>
    )
  );
};

export default SearchWidget;
