// components
import { Avatar } from 'components/Avatar/Avatar';

// helpers
import { getDateOptions, getOptions } from 'common/helpers/modelFunctions';
import { colors } from 'common/colors/colors';

// TODO: create function for avatar display (according to given employeeId)
const showAvatarByEmployeeId = (data, employeeId) =>
  data.find((record) => record.id === employeeId).avatar;

const getStaticImage = () => (
  <Avatar
    imagePath={
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/109.jpg'
    }
    size="30px"
  />
);

// TODO: GET RID OF THIS, use locale from UserContext.
const locale = localStorage.getItem('lng') || 'en';

const getDateTime = (data) => {
  return new Date(data).toLocaleString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const getForeignOptions = (sliceName, id) => {
  return;
};

export const orderHeaders = [
  {
    id: 'orderId',
    level: 1,
    text: 'Užsakymo numeris',
    type: 'search',
    options: getOptions,
  },
  {
    id: 'incomeDate',
    level: 1,
    text: 'Pajamų data',
    type: 'calendar',
    display: getDateTime,
    options: getDateOptions,
  },
  // {
  //   id: 'clientId',
  //   slice: 'clients',
  //   level: 1,
  //   text: 'Klientas',
  //   type: 'search',
  //   // justify: 'center',
  //   display: getStaticImage,
  //   options: getForeignOptions,
  //   // hide: true,
  // },
  {
    id: 'employeeId',
    slice: 'employees',
    level: 1,
    text: 'Darbuotojas',
    type: 'search',
    justify: 'center',
    display: getStaticImage,
    options: getForeignOptions,
  },
  {
    id: 'registerDateTimeStart',
    level: 1,
    text: 'Užsakymo pradžia',
    type: 'calendar',
    display: getDateTime,
    options: getDateOptions,
    hide: true,
  },
  {
    id: 'registerDateTimeEnd',
    level: 1,
    text: 'Užsakymo pabaiga',
    type: 'calendar',
    display: getDateTime,
    options: getDateOptions,
    hide: true,
  },
  {
    id: 'duration',
    level: 1,
    text: 'Užsakymo trukmė',
    type: 'func',
    count: true,
  },
  {
    id: 'amount',
    level: 1,
    text: 'Pajamų suma',
    type: 'search',
    count: true,
    options: getOptions,
  },
  {
    id: 'discount',
    level: 1,
    text: 'Nuolaida',
    type: 'search',
    count: true,
    options: getOptions,
    minWidth: 90,
  },
  {
    id: 'count',
    level: 1,
    text: 'Paslaugų kiekis',
    type: 'calc',
    // count: true,
    // options: getOptions,
  },
  {
    id: 'title',
    level: 1,
    text: 'Paslaugos',
    type: 'search',
    count: true,
    options: getOptions,
    wide: true,
  },
  {
    id: 'status',
    level: 1,
    text: 'Būsena',
    type: 'switch',
    options: [
      {
        value: 1,
        label: 'Užsakyta',
        style: { backgroundColor: colors.ordered },
      },
      {
        value: 2,
        label: 'Atvyks',
        style: { backgroundColor: colors.willArrive },
      },
      { value: 3, label: 'Atvyko', style: { backgroundColor: colors.arrived } },
      { value: 4, label: 'Apmokėta', style: { backgroundColor: colors.paid } },
      { value: 5, label: 'Skola', style: { backgroundColor: colors.debt } },
      {
        value: 6,
        label: 'Atsisakė',
        style: { backgroundColor: colors.canceled },
      },
      {
        value: 7,
        label: 'Neatvyko',
        style: { backgroundColor: colors.absent },
      },
      // { value: 8, label: 'Apmokėtas', style: { backgroundColor: colors.paid } },
    ],
  },
  {
    id: 'services',
    level: 1,
    type: 'children',
    hide: true,
    children: [
      // { parentId: 'orderId' },
      'duration',
      'amount',
      'discount',
      'count',
      'title',
    ],
  },
  // {
  //   id: 'duration/capacity',
  //   level: 1,
  //   text: 'Trukme/Talpa',
  //   minWidth: 80,
  // },
  // {
  //   id: 'sms',
  //   level: 1,
  //   text: 'SMS',
  //   minWidth: 80,
  // },
  // {
  //   id: 'productAmount',
  //   level: 1,
  //   text: 'Kiekis',
  //   minWidth: 50,
  // },
  // {
  //   id: 'price',
  //   level: 1,
  //   text: 'Vnt. kaina',
  //   minWidth: 90,
  // },
  // {
  //   id: 'vat',
  //   level: 1,
  //   text: 'PVM',
  //   minWidth: 60,
  // },
  // {
  //   id: 'totalPrice',
  //   level: 1,
  //   text: 'Galutine kaina',
  //   minWidth: 100,
  // },
];
