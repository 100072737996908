// style
import * as S from './RecordCell.styled';

// components
import Avatar from 'components/Avatar/Avatar';

// hooks
import useDate from 'hooks/useDate';

// helpers
import { getOpbjectEntryByString } from 'common/helpers/objectFunctions';
import { fillImgPath } from 'common/helpers/imagesFunctions';

export const RecordCell = ({
  data,
  column,
  children,
  dashIfNoContent = false,
  ...rest
}) => {
  const { getDateTime, getDate } = useDate();

  let content;
  if (children) {
    content = children;
  } else {
    content = getOpbjectEntryByString(data, column.id);
  }

  switch (column.type) {
    case 'DATE':
      content = content ? getDate(content) : '';

      break;
    case 'DATETIME':
      content = content ? getDateTime(content) : '';

      break;
    case 'AVATAR':
      content = <Avatar className="avatar" imagePath={fillImgPath(content)} />;

      break;
    case 'OPTION':
      content = column.options?.find(
        (option) => option.value === content,
      )?.label;

      break;
  }

  if (!content && dashIfNoContent) {
    content = '-';
  }

  return (
    <S.RecordCell className={column?.cellClassNames} {...rest}>
      {content}
    </S.RecordCell>
  );
};
