import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const AnnouncementsAndSuggestions = styled.section`
  padding: 30px 25px 50px 25px;
  height: calc(100vh - 70px);

  background-color: ${colors.bg_grey_1};

  overflow: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .section__wrapper {
    max-width: 580px;
    min-width: 450px;

    max-height: 880px;

    margin: 20px 25px 0;

    flex: 1 0;
  }

  @media screen and (max-width: 960px) {
    padding: 20px;
    height: calc(100vh - 54px);

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: unset;

    .section__wrapper {
      max-width: unset;
      min-width: unset;

      margin: 0;

      &:last-child {
        margin-top: 40px;
      }
    }
  }
`;
