import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { shadow } from 'common/styles/shadows';

export const ClientCard = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: ${shadow.lg};
  flex-basis: calc(50% - 10px);
  font-family: ${fontFamilies.family_Secondary};
  margin-bottom: 20px;

  .mark {
    background-color: ${({ selected }) =>
      selected ? colors.bg_Primary : '#fff'};
    border-radius: 100%;
    min-width: 16px;
    height: 16px;
  }

  @media screen and (max-width: 600px) {
    flex-basis: 100%;
    margin-bottom: 10px;

    :last-of-type {
      margin-bottom: unset;
    }
  }
  ${({ inactive }) =>
    inactive &&
    css`
      color: ${colors.bg_grey_3};
    `}
`;

export const Header = styled.div`
  align-items: center;
  background-color: ${({ inactive }) =>
    inactive ? colors.filterGrey : colors.bg_Primary};
  border-radius: 5px 5px 0 0;
  color: ${colors.text_Primary};
  display: flex;
  font-weight: 500;
  height: 36px;
  padding: 3px 10px;
  font-family: ${fontFamilies.family_Primary};
  gap: 10px;

  span {
    line-height: 0;
  }

  .avatar {
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    filter: grayscale(${({ inactive }) => !inactive});
    user-select: none;
  }

  .full-name {
    flex-basis: 100%;
    font-weight: 800;
    cursor: pointer;
  }

  .delete-button {
    color: ${({ inactive }) =>
      inactive ? colors.bg_Primary : colors.bg_grey_3};
    cursor: ${({ inactive }) => (inactive ? 'pointer' : 'cursor')};
    flex: 0 0 16px;
  }

  .details {
    color: ${({ inactive }) => (inactive ? colors.bg_Primary : '#fff')};
    cursor: pointer;
  }
`;

export const Main = styled.table`
  font-size: 0.75rem;
  font-weight: 400;
  padding: 10px;
  table-layout: fixed;
  width: 100%;

  tr > td + td {
    border-left-width: 10px;
  }

  tr + tr > td {
    border-top-width: 5px;
  }

  td {
    border: 0px solid transparent;
    vertical-align: top;

    // label
    :nth-child(2n + 1) {
      font-weight: 500;
      width: 40%;
    }

    // value
    :nth-child(2n) {
      overflow: auto;
      word-wrap: normal;
      white-space: nowrap;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
