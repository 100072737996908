import { useEffect, useState } from 'react';

// hooks and helpers
import { useFetch } from './useApi';
import { useStore } from 'hooks/headers-store/store';

const DB_SLICE = 'users';

// export const useUsersOptionsGetAll = () => {
//   // const { data, isSuccess, ...context } = useUsersGetAll();

//   const [options, setOptions] = useState();

//   useEffect(() => {
//     if (isSuccess)
//       setOptions(() =>
//         Object.keys(data[0])?.reduce(
//           (acc, headerId) => ({
//             ...acc,
//             [headerId]: data.reduce((acc, record) => {
//               return [
//                 ...acc,
//                 ...(!acc.some((valueObj) => valueObj.value === record[headerId])
//                   ? [{ value: record[headerId], label: record[headerId] }]
//                   : []),
//               ];
//             }, []),
//           }),
//           {}
//         )
//       );
//   }, [isSuccess, data]);

//   return {
//     ...context,
//     isSuccess: isSuccess && options !== undefined,
//     options: options,
//   };
// };

export const useUsersGetOne = (id) => {
  // get specific client data from B/E
  const context = useFetch(DB_SLICE, { id }, { retry: true });

  // get headers
  const [{ [DB_SLICE]: headers }] = useStore(false);

  // get specific header
  const getHeader = (headerId) => {
    return headers.find((header) => header.id === headerId);
  };

  // get label according to value
  const getLabel = (record, header) => {
    const options = header?.options;
    // console.log(
    //   'header.options',
    //   Array.isArray(header.options) ? header.options : header.options(header.id)
    // );
    const value = record[header?.id];
    // console.log('record[header.id]', record[header.id]);

    if (options) {
      if (Array.isArray(options)) {
        return options.find((option) => option.value === value).label;
      } else {
        return header.display(record, header);
      }
    } else return header?.display(record, header);
  };

  // set state variable for processed data
  const [data, setData] = useState();

  useEffect(() => {
    if (context.isSuccess) {
      setData(() => {
        let newData = Object.entries(context.data).reduce((acc, entry) => {
          const header = getHeader(entry[0]);
          // console.log({ entry });
          return entry[0] === 'id'
            ? acc
            : {
                ...acc,
                [entry[0]]: {
                  type: header?.type,
                  value: Array.isArray(entry[1])
                    ? entry[1]
                    : {
                        value: entry[1],
                        label: getLabel(context.data, header) || entry[1],
                      },
                },
              };
        }, {});
        // newData['age'] = {
        //   type: getHeader('age').type,
        //   value: {
        //     value: getLabel(context.data, getHeader('age')),
        //     label: getLabel(context.data, getHeader('age')),
        //   },
        // };
        return newData;
      });
    }
  }, [context.isSuccess, context.data]);

  if (context.isError) {
    console.log(context.error.stack);
  }

  return {
    ...context,
    isSuccess: context.isSuccess && data !== undefined,
    data,
  };
};
