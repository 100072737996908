import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';

import { Cell } from 'components/ScheduleCustom/DailyView/ScheduleDay/ScheduleDay.styled';
export { Cell };

export const TimelineHeader = styled.div``;

export const ColumnDate = styled.div`
  padding: 2px 10px;
  text-align: center;
  background-color: white;
  z-index: 2;
  height: 50px;
  display: flex;
  color: ${({ isInPast, isToday, isWeekend }) => {
    if (isToday) {
      return colors.mandyRed;
    } else if (isInPast) {
      return colors.bg_grey_6;
    } else if (isWeekend) {
      return colors.bg_grey_3;
    }

    return colors.text_Secondary;
  }};

  .date-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    transform: translate3d(-10px, 0, 0);

    .weekday {
      font-size: 1rem;
      text-transform: capitalize;
    }

    .date {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  .office-number {
    min-width: 20px;
    height: 20px;
    background-color: ${colors.calendar_office_number};
    border-radius: 100px;
    padding: 0 5px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 900;
    font-size: 0.875rem;
    transition: all 0.3s ease-in-out;
    margin-left: 0;
  }

  @media screen and (max-width: 960px) {
    justify-content: center;
    margin-left: -20px;
    gap: 0;
    transition: all 0.3s ease-in-out;

    .office-number {
      transform: translate3d(2px, 0, 0);
    }

    &.mobile-office-badge-extended {
      margin-left: 0;
      gap: 14px;

      .office-number {
        width: 30px;
        height: 30px;
        margin-left: -41px;
        transform: none;
      }
    }

    .date-container {
      margin: unset;
      transform: none;
    }
  }
`;

export const TimelineCellSkeleton = styled.div`
  padding: 2px 10px;
  text-align: center;
  background-color: white;
  z-index: 2;
  height: 50px;
  display: flex;
  width: 200px;

  .office-number {
    min-width: 20px;
    height: 20px;
    background-color: ${colors.bg_grey_5};
    border-radius: 100%;
    padding: 0 5px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 900;
    font-size: 0.875rem;
  }

  .date-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    transform: translate3d(-10px, 0, 0);
    gap: 6px;
    margin-top: 4px;
  }

  .weekday {
    height: 16px;
    width: 20px;
    background-color: ${colors.bg_grey_5};
    margin: 0 auto;
  }

  .date {
    height: 12px;
    background-color: ${colors.bg_grey_5};

    width: 76px;
  }

  @media screen and (max-width: 960px) {
    justify-content: center;
    gap: 14px;

    .office-number {
      width: 30px;
      height: 30px;
      margin-left: -41px;
    }

    .date-container {
      margin: unset;
      transform: none;
    }
  }
`;
