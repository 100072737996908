import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// style
import * as S from './AddButton.styled';

// icons
import { Plus } from 'icons/dynamic';

// components
import ButtonSvg from 'components/Buttons/ButtonSvg';
import Overlay from 'components/Overlay/Overlay';
import ProfessionalsWorkspace from 'components/Overlay/ProfessionalsWorkspace/ProfessionalsWorkspace';
import DialogBox from 'components/DialogBox/DialogBox';
import SelectProfessionals from 'components/DialogLayout/SelectProfessionals/SelectProfessionals';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';

// contexts
import { ScheduleContext } from 'context/ScheduleContext';

// hooks
import useModal from 'hooks/useModal';
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';
import { getFirstDayOfWeek, toIsoString } from 'common/helpers/dateOperations';

const AddButton = () => {
  // Hooks
  const tr = useTranslate().use().global;
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { toggle, isShowing } = useModal();

  // -- state
  // --- local
  const [displayModal, setDisplayModal] = useState(null);
  const [modalSwitch, setModalSwitch] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // --- global
  const { dataSource, numberOfDays, searchParams } =
    useContext(ScheduleContext);
  const { cell, selectedProfessional, dateState } = useSelector(
    (state) => state.scheduleReducer,
  );

  const sectorId = searchParams.get('sectorId');

  const getCalendarType = () => {
    if (multiSelect) return 'i';
    if (numberOfDays.visible === 1) return 'd';
    if (numberOfDays.visible === 7) return 'w';
  };

  const getDatetoCheck = () => {
    if (numberOfDays.visible === 1) return toIsoString(dateState).split('T')[0];

    if (numberOfDays.visible === 7) {
      const monday = getFirstDayOfWeek(dateState);

      return toIsoString(monday).split('T')[0];
    }
  };

  // -- side effect
  useEffect(() => {
    switch (modalSwitch) {
      case 'workspace':
        setDisplayModal(
          <ProfessionalsWorkspace
            setModalSwitch={(view) => {
              if (view === 'select-professional') {
                setSearchInput('');
              }

              setModalSwitch(view);
            }}
            selectedProfessional={selectedProfessional}
            date={getRelevantDate()}
            numberOfDays={numberOfDays}
            searchParams={Object.fromEntries([...searchParams])}
          />,
        );
        break;
      case 'select-professional':
        setDisplayModal(() => {
          return isMobile ? (
            <SelectionPanel
              closeAction={() => toggleView(null)}
              headerTitle={tr.professionals}
              searchTitle={tr.professionalSearch}
              arrayFilter={(value) => setSearchInput(value)}
            >
              <SelectProfessionals
                select={(prof) => selectProfessionals(prof)}
                calendarView={getCalendarType()}
                checkDay={getDatetoCheck()}
                searchInput={searchInput}
                sectorId={sectorId}
              />
            </SelectionPanel>
          ) : (
            <div className="modal-wrapper">
              <DialogBox
                headerText={tr['choose-specialist']}
                close={() => toggleView(null)}
                cancelOverflow
              >
                <SelectProfessionals
                  select={(prof) => selectProfessionals(prof)}
                  calendarView={getCalendarType()}
                  checkDay={getDatetoCheck()}
                  sectorId={sectorId}
                />
              </DialogBox>
            </div>
          );
        });
        break;
      default:
        setDisplayModal(<></>);
        return;
    }
  }, [modalSwitch, isMobile, searchInput]);

  // Vars
  const multiSelect = location.state?.multi;

  // Functions
  const toggleView = (newView) => {
    toggle();
    setModalSwitch(newView);
  };

  const selectProfessionals = (prof) =>
    navigate('/functions/daily-functions/client-search', {
      state: {
        ...location.state,
        ...(prof?.length
          ? {
              wantedEmployees: prof,
            }
          : {
              employee: prof,
            }),
      },
    });

  const getRelevantDate = () => {
    return cell ? cell.time : dateState;
  };

  return (
    <>
      <S.AddButton>
        <ButtonSvg
          svg={<Plus black color="#fff" />}
          action={() => toggleView('workspace')}
          id="more"
        />
      </S.AddButton>

      <Overlay isVisible={isShowing} hide={() => toggleView(null)}>
        {displayModal}
      </Overlay>
    </>
  );
};

export default AddButton;
