import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { TheButton } from 'components/Buttons/TheButton.styled';

export const ServiceGroup = styled.div`
  .info {
    display: flex;

    svg {
      align-self: start;
      margin-right: -4px;
    }
  }

  .header {
    display: grid;
    flex: 1;
    font-family: ${fontFamilies.family_Primary};
    font-size: 0.875rem;
    font-weight: 600;
    grid-gap: 10px;
    grid-template-columns: minmax(min-content, 100%) 100px 70px 70px;
    margin: 20px 10px 10px 26px;
    align-items: start;
  }

  .controls {
    display: flex;
    gap: 10px;
    margin: 20px 10px 0 26px;

    @media screen and (max-width: 960px) {
      margin: 20px 20px 0;
    }
  }

  .controls ${TheButton} {
    width: max-content;
    height: max-content;
    padding-inline: 10px;
    font-size: 0.75rem;
    background-color: white;

    :active {
      box-shadow: 0 0 3px -1px ${colors.bg_Button_Secondary} inset;
    }
  }
`;
