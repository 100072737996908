import { useEffect, useRef } from 'react';

// scrolls into view an element referenced by ref
export const useScrollIntoView = (config = {}) => {
  const ref = useRef();

  const { auto, vertical, horizontal } = config;

  useEffect(
    () =>
      auto &&
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: vertical || 'center',
        inline: horizontal || 'center',
      }),
    [ref, auto, vertical, horizontal],
  );

  const scroll = () => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: vertical || 'center',
      inline: horizontal || 'center',
    });
    ref?.current?.focus({ preventScroll: true });
  };

  return { ref, scroll };
};
