import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { fontFamilies } from 'common/fonts/fonts';

export const UserMenu = styled.div`
  position: absolute;
  right: 5px;
  border-radius: 5px;
  padding: 30px 50px;
  color: ${colors.text_Secondary};
  background-color: ${colors.bg_Secondary};
  box-shadow: ${shadow.lg};
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: visible;
  transition: visibility 0.2s ease-in-out;

  @media (max-width: 960px) {
    right: 0;
    margin-top: 8px;
  }

  :hover {
    .close-button {
      opacity: 100%;
    }
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    padding: 2px 6px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .avatar {
    width: 80px;
    height: 80px;
    margin: auto auto 10px;
    border-radius: 50%;
    /* box-shadow: 0 5px 5px rgba(0, 0, 0, 15%); */
  }

  .user-name {
    font-size: 1.125rem;
    font-weight: 900;
    line-height: 23px;
  }

  .user-email,
  a {
    font-family: ${fontFamilies.family_Secondary};
    font-size: 0.875rem;
  }

  .user-email {
    color: ${colors.bg_grey_3};
    line-height: 1.33333;
    margin-bottom: 30px;
  }

  a {
    margin-bottom: 20px;
  }
`;
