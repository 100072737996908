import { useContext } from 'react';

// Context
import { UserContext } from 'context/UserContext';

export const useTime = () => {
  const { locale } = useContext(UserContext);

  const getTime = (data) => {
    const time = new Date(data).toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    });

    return isNaN(data) ? undefined : time;
  };

  return { getTime };
};

export default useTime;
