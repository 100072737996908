import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import styled from 'styled-components/macro';

export const OperationDataSettings = styled.div`
  width: 100%;
  max-width: 850px;

  display: flex;
  flex-wrap: wrap;

  .login__operation-form {
    flex: 1;
    background-color: ${colors.bg_grey_1};
    padding: 30px;
    border-radius: 10px;

    min-width: 380px;
  }

  .login__input-row {
    margin-bottom: 20px;

    display: flex;

    > div {
      width: 100%;
      margin-right: 20px;

      .select__menu-list {
        max-height: 180px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .login__inner-multi-inputs {
      display: flex;
      > div {
        width: 100%;

        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    padding: 10px;

    .login__operation-form {
      flex-grow: 2;

      border-radius: 5px;
      padding: 10px;

      min-width: 280px;
    }

    .double {
      flex-direction: column;

      > div {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
