import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const DataPanelCell = styled.div`
  border-top: 1px solid ${colors.bg_grey_2};
  border-left: 1px solid ${colors.bg_grey_2};

  background-color: white;

  /* background-color: ${(props) =>
    props.isWorkingHours && colors.bg_grey_1}; */

  height: 20px;

  display: flex;
  /* align-items: center; */
  position: relative;
  user-select: none;

  .dataPanelCell_label {
    position: relative;

    font-size: 9px;
    color: ${(props) =>
      props.isWorkingHours ? colors.bg_Primary : colors.bg_grey_2};
    padding: 0 5px;

    height: 100%;

    display: flex;
    align-items: center;

    ${(props) =>
      props.selected &&
      css`
         {
          padding: 0 7px;
          padding-bottom: 2px;
          border-radius: 100px;

          font-size: 12px;

          color: white;
          background-color: ${colors.bg_Primary};
          border: 1px solid ${colors.bg_Primary};
          font-weight: 900;
        }
      `}
  }

  &.dragging {
    pointer-events: none;
  }

  @media screen and (min-width: 961px) {
    ${(props) =>
      !props.dragging &&
      !props.selected &&
      css`
        :hover {
          .dataPanelCell_label {
            padding: 0 7px;
            padding-bottom: 2px;
            border-radius: 100px;

            font-size: 12px;

            color: ${colors.bg_Primary};
            border: 1px solid ${colors.bg_Primary};
            font-weight: 900;
          }
        }
      `}
  }
`;
