// Style
import * as S from './AnnouncementsAndSuggestions.styled';

// Components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import SimpleHeader from 'components/Header/SimpleHeader/SimpleHeader';
import AnnouncementsForm from '../../components/AnnouncementsForm/AnnouncementsForm';
import AnnouncementsDetails from 'components/AnnouncementsDetails/AnnouncementsDetails';

const AnnouncementsAndSuggestions = () => {
  return (
    <ContentLayout>
      <SimpleHeader />
      <S.AnnouncementsAndSuggestions>
        <div className="section__wrapper">
          <AnnouncementsForm />
        </div>
        <div className="section__wrapper">
          <AnnouncementsDetails />
        </div>
      </S.AnnouncementsAndSuggestions>
    </ContentLayout>
  );
};

export default AnnouncementsAndSuggestions;
