import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import AutoNumeric from 'autonumeric';

// style
import * as S from './OrderRowEntry.styled';

// icons
import { Clock, CubeSVG, RulerSVG, TrashCan } from 'icons/dynamic';

// components
import ActionColumn from './ActionColumn/ActionColumn';
import IconValueColumn from './IconValueColumn/IconValueColumn';
import DiscountColumn from './DiscountColumn/DiscountColumn';
import { BaseInput, BaseSelect } from 'components/Inputs';

// hooks
import useNumber from 'hooks/useNumber';
import useService from 'hooks/useService';
import useTranslate from 'hooks/useTranslate';
import { useSystemGetUnits } from 'services/systemService';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

const OrderRowEntry = ({
  index,
  headerId,
  service,
  deleteEntrie,
  updateServiceKey,
  updateAmount,
  compItem,
  isComp,
}) => {
  const tr = useTranslate().use().global;
  const { getSmsDurationOptions, getTranslatedSmsDuration } = useService({
    service,
  });
  const currentSmsDurationOptions = getSmsDurationOptions();
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits, currencySymbol, currencySymbolPlacement } =
    useNumber({ price: true });

  const [isSelectMenuOpen, setSelectMenuOpen] = useState(false);

  const isMaterial = service.unitId !== undefined;

  useEffect(() => {
    if (headerId === 'amount' && service.quantity === 0) {
      updateAmount(1, index, compItem);
    }
  }, [service.quantity]);

  const updatePrice = (val) =>
    updateServiceKey(
      {
        unitPriceWithVat: val ?? 0,
        ...(service.unitPriceWithVat > (val ?? 0) && {
          discountWithVat: 0,
        }),
      },
      index,
    );

  let content = <></>;

  switch (headerId) {
    case 'delete':
      content = !compItem && (
        <ActionColumn icon={<TrashCan />} action={() => deleteEntrie(index)} />
      );

      break;
    case 'title':
      {
        const danger = service?.canBeSold === false;

        content = (
          <div
            className={`title-column ${compItem && 'compItem'}${danger ? ' danger' : ''}`}
          >
            {service.hasComp && (
              <div className="title-column__logo">
                <CubeSVG />
              </div>
            )}
            <span
              className="title-column__title"
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                danger && tr['service-not-provided'],
              )}
            >
              {service.parentTitle && service.parentTitle + ' / '}
              {service.name}
            </span>
          </div>
        );
      }

      break;
    case 'duration/capacity':
      if (!isMaterial) {
        const differFromNorm =
          service?.unitDurationInMinutesNorm !== undefined &&
          service?.unitDurationInMinutes !== service.unitDurationInMinutesNorm;

        content = (
          <IconValueColumn
            data-tooltip-id="tooltip"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              differFromNorm ? (
                <CenteredTooltipContent
                  title={tr['current-duration']}
                  content={getTimeFromMinutes(
                    service.unitDurationInMinutesNorm,
                  )}
                />
              ) : (
                ''
              ),
            )}
            icon={<Clock />}
            value={
              <span className={differFromNorm ? 'danger' : ''}>
                {getTimeFromMinutes(service.unitDurationInMinutes)}
              </span>
            }
          />
        );

        break;
      } else {
        const unitShortName = getUnitsById(service.unitId)?.label;

        content = (
          <IconValueColumn
            icon={<RulerSVG />}
            value={
              formatUnits(service.unitVolume) +
              (!!unitShortName && ` ${unitShortName}`)
            }
          />
        );

        break;
      }
    case 'smsPeriodCustomer':
      if (!isMaterial && !compItem) {
        const differFromNorm =
          service?.smsPeriod !== undefined &&
          service?.smsPeriod !== service.smsPeriodCustomer;

        const value = currentSmsDurationOptions.find(
          (option) => option.value === service.smsPeriodCustomer,
        );

        content = (
          <div className="select-column">
            <div
              data-tooltip-offset={6}
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                differFromNorm ? (
                  <CenteredTooltipContent
                    title={tr['current-sms']}
                    content={
                      service.smsPeriod === null
                        ? '-'
                        : getTranslatedSmsDuration(service.smsPeriod)
                    }
                  />
                ) : (
                  ''
                ),
              )}
            >
              <BaseSelect
                className={differFromNorm ? 'warning' : ''}
                options={currentSmsDurationOptions}
                value={value.value}
                onChange={(val) => updateServiceKey({ [headerId]: val }, index)}
                menuVisibilityCallback={setSelectMenuOpen}
                // styles={S.CustomSelectStyles}
                inPortal={false}
              />
            </div>
          </div>
        );

        break;
      } else {
        break;
      }
    case 'vatPercent': {
      const differFromNorm = service?.vatPercent !== service.vatPercentNorm;

      content = !compItem && (
        <div
          data-tooltip-offset={6}
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            differFromNorm ? (
              <CenteredTooltipContent
                title={tr['current-vat']}
                content={
                  service.vatPercentNorm === null
                    ? '-'
                    : AutoNumeric.format(service.vatPercentNorm, {
                        decimalPlaces: 2,
                        currencySymbol: '%',
                        currencySymbolPlacement: 's',
                      })
                }
              />
            ) : (
              ''
            ),
          )}
        >
          <BaseInput
            className={`vat ${differFromNorm || service?.canBeSold === false ? 'danger' : ''}`}
            type="number"
            placeholder="-"
            value={service?.vatPercent}
            onBlur={(val) => updateServiceKey({ [headerId]: val }, index)}
            disabled={compItem}
            decimalPlaces={2}
            currencySymbol="%"
            currencySymbolPlacement="s"
            maximumValue="99.99"
            minimumValue="0"
          />
        </div>
      );

      break;
    }

    case 'amount':
      content = (
        <div className={'amount-column'}>
          <BaseInput
            type="number"
            value={service.quantity}
            onBlur={(val) => updateAmount(val, index, compItem)}
            disabled={compItem}
            decimalPlaces={3}
            minimumValue="0"
            emptyInputBehavior={1}
          />
        </div>
      );

      break;
    case 'unitPriceWithVat':
      const differFromNorm =
        service?.unitPriceWithVat !== service.unitPriceWithVatNorm;

      content = !compItem && (
        <div
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            differFromNorm ? (
              <CenteredTooltipContent
                title={tr['current-price']}
                content={
                  service.unitPriceWithVatNorm === null
                    ? '-'
                    : formatPrice(service.unitPriceWithVatNorm, true)
                }
              />
            ) : (
              ''
            ),
          )}
          className={`price-column${differFromNorm || service?.canBeSold === false ? ' danger' : ''}`}
        >
          <BaseInput
            className={
              differFromNorm || service?.canBeSold === false ? 'danger' : ''
            }
            type="number"
            value={service.unitPriceWithVat}
            onBlur={updatePrice}
            disabled={compItem}
            currencySymbol={currencySymbol}
            currencySymbolPlacement={currencySymbolPlacement}
            decimalPlaces={4}
            minimumValue="0"
            emptyInputBehavior={0}
          />
        </div>
      );

      break;
    case 'discount':
      content = !compItem && (
        <DiscountColumn
          service={service}
          index={index}
          saveChange={updateServiceKey}
        />
      );

      break;
    case 'totalPrice':
      content = !compItem && (
        <div className="total-price-column">
          {formatPrice(service?.finalAmountWithVat || 0)}
        </div>
      );

      break;
    default:
      break;
  }

  return (
    <S.OrderRowEntry
      compItem={compItem}
      headerId={headerId}
      selectIsOpen={isSelectMenuOpen}
    >
      <div className="content">{content}</div>
    </S.OrderRowEntry>
  );
};

const CenteredTooltipContent = ({ title, content }) => {
  return (
    <div className="centered">
      <div>{title + ':'}</div>
      <div>{content}</div>
    </div>
  );
};

export default OrderRowEntry;
