import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';

export const DataPanel = styled.div`
  display: flex;
  flex: 1;
  padding-top: 10px;

  @media screen and (min-width: 961px) {
    transition: min-width 300ms ease-in-out;
  }
`;

export const NoDataBox = styled.div`
  max-width: 660px;
  border: 1px solid ${colors.bg_grey_3};
  border-radius: 10px;
  padding: 30px;
  margin: 16px 50px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${colors.bg_grey_3};

  span {
    font-size: 20px;
    font-weight: 900;
  }

  p {
    margin: 0;
    font-family: ${fontFamilies.family_Secondary};
  }

  @media screen and (max-width: 961px) {
    margin: 0 20px;
    gap: 20px;
    padding: 20px;

    span {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
`;
