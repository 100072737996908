import styled from 'styled-components/macro';

import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';
import { dropdownRollIn, dropdownRollOut } from 'common/styles/animations';

export const SideBarFilter = styled.div`
  animation: ${dropdownRollIn} 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.875rem;
  margin: 10px;
  transform-origin: top;

  /* .spacer {
    border: 1.5px solid ${colors.bg_Secondary};
    border-radius: 1.5px;
    margin-right: 10px;
    width: 0;
  } */

  .criterias {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;

    > div {
      margin-top: 10px;

      :first-child {
        margin-top: 0;
      }
    }
  }

  svg {
    max-width: 17px;
  }

  .select {
    &__menu {
      animation: ${dropdownRollIn} 0.2s ease-in-out;
      transform-origin: top;

      &--close {
        animation: ${dropdownRollOut} 0.1s ease-in-out;
      }
    }

    &__indicator {
      &:hover {
        color: ${colors.text_Secondary};
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &__clear-indicator {
      padding: 4px 8px;
    }

    &__dropdown-indicator {
      padding: 6px 8px;
    }
  }

  > button {
    bottom: 40px;
    left: 25%;
    position: absolute;
    z-index: 1;
  }
`;
