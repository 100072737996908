import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const AvatarCropper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: 20px;
  justify-content: center;
  margin: auto;
  position: relative;

  canvas {
    max-height: calc(100vh - 367px);
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    color: red;
    width: 100%;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: ${colors.bg_grey_2};
    border: none;
    border-radius: 3px;
    height: 5px;
    margin-block: 5px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: ${colors.bg_Button_Primary};
    border: none;
    border-radius: 50%;
    height: 16px;
    margin-top: -6px;
    width: 16px;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  button {
    filter: opacity(50%);
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 10px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;
