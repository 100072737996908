import { useCallback, useContext } from 'react';

// Style
import * as S from './TimelineHeader.styled';

// Context
import { ScheduleContext } from 'context/ScheduleContext';
import { UserContext } from 'context/UserContext';

// Helper
import {
  dateIsInThePast,
  dateIsWeekend,
  datesAreOnSameDay,
} from 'common/helpers/dateOperations';

// Hook
import { useColumnLooper } from 'hooks/schedule/useColumnLooper';

export const TimelineHeader = () => {
  const {
    columnWidth,
    cellWidth,
    loader,
    professionalsQuantity,
    defaultColumnWidth,
  } = useContext(ScheduleContext);
  const { locale } = useContext(UserContext);
  const { loop } = useColumnLooper();

  const checkIfPast = useCallback((date) => dateIsInThePast(date), []);
  const checkifWeekend = useCallback((date) => dateIsWeekend(date), []);

  const TimeSkeleton = () => {
    return (
      <S.TimelineCellSkeleton>
        <div className="office-number"></div>
        <div className="date-container">
          <div className="weekday"></div>
          <div className="date"></div>
        </div>
      </S.TimelineCellSkeleton>
    );
  };

  return (
    <S.TimelineHeader className="timeline">
      <div className="filler"></div>
      {loop({
        keyPrefix: 'time',
        skeleton: <TimeSkeleton />,
        appendToEnd: loader.end,
        callback: (sourceItem, day) => (
          <S.Cell
            width={cellWidth.value}
            columnWidth={columnWidth}
            defaultColumnWidth={defaultColumnWidth}
          >
            <S.ColumnDate
              className={`column-date${professionalsQuantity <= 2 ? ' mobile-office-badge-extended' : ''}`}
              isToday={datesAreOnSameDay(day.date, new Date())}
              isInPast={checkIfPast(day.date)}
              isWeekend={checkifWeekend(day.date)}
            >
              <div className="office-number">2</div>
              <div className="date-container">
                <span className="weekday">
                  {day.date.toLocaleString(locale, {
                    weekday: 'short',
                  })}
                </span>
                <span className="date">
                  {day.date.toLocaleString(locale, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  })}
                </span>
              </div>
            </S.ColumnDate>
          </S.Cell>
        ),
      })}
    </S.TimelineHeader>
  );
};

export default TimelineHeader;
