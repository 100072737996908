import * as S from '../SVG.styled';

export default ({ color, width, height }) => {
  const content = (
    <>
      <path
        d="M 15,0 C 6.730275,0 0,6.730275 0,15 0,23.2698 6.730275,30 15,30 23.2698,30 30,23.2698 30,15 30,6.730275 23.27265,0 15,0 Z m 0,26.7342 C 8.527785,26.7342 3.2628,21.4692 3.2628,14.99703 3.2628,8.524815 8.527785,3.259845 15,3.259845 c 6.4722,0 11.7372,5.26497 11.7372,11.737185 0,6.47217 -5.265,11.73717 -11.7372,11.73717 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15,6.58209 c 1.22805,0 2.22465,0.996645 2.22465,2.224635 0,1.228005 -0.9966,2.22465 -2.22465,2.22465 -1.228005,0 -2.224635,-0.996645 -2.224635,-2.22465 0,-1.22799 0.99663,-2.224635 2.224635,-2.224635 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 15,12.54108 c -0.901695,0 -1.631385,0.729675 -1.631385,1.6314 v 7.97307 c 0,0.9018 0.72969,1.6314 1.631385,1.6314 0.9018,0 1.6314,-0.7296 1.6314,-1.6314 v -7.97307 c 0,-0.901725 -0.7296,-1.6314 -1.6314,-1.6314 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 20}
      height={height ? height : 20}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
