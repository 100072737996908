import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const DialSelector = styled.div`
  border: 1px solid ${colors.bg_grey_2};
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px;
  min-width: 110px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: hsl(0, 0%, 80%);
      cursor: pointer;
      display: block;

      :hover {
        fill: ${colors.bg_Button_Secondary};
      }
    }

    :first-child {
      padding-left: 3px;
      padding-right: 1px;
    }
    :last-child {
      padding-left: 1px;
      padding-right: 3px;
    }

    :first-child svg {
      transform: rotate(180deg);
    }

    :nth-child(2) {
      color: ${colors.text_Secondary};
      font-family: ${fontFamilies.family_Secondary};
      font-size: 1rem;
      font-weight: normal;
      padding-top: 1px;
      text-align: center;
      width: 60px;
    }
  }
`;
