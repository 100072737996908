const sideBarReducer = (
  state = { isSideBarOpen: true, hideToggleButton: false },
  action,
) => {
  switch (action.type) {
    case 'SIDE_BAR_OPEN':
    case 'SIDE_BAR_CLOSE':
      return {
        ...state,
        isSideBarOpen: action.payload,
      };
    case 'SIDE_BAR_TOGGLE':
      return {
        ...state,
        isSideBarOpen: !state.isSideBarOpen,
      };
    case 'SIDE_BAR_TOGGLE_BUTTON_SHOW':
    case 'SIDE_BAR_TOGGLE_BUTTON_HIDE':
      return {
        ...state,
        hideToggleButton: action.payload,
      };

    default:
      return state;
  }
};

export default sideBarReducer;
