import { useState, useEffect, useRef, useContext } from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';
import ClickAwayListener from 'react-click-away-listener';

// style
import * as S from './SchedulerCalendar.styled';
import { colors } from 'common/colors/colors';

// icons
import {
  Arrow,
  Calendar as CalendarIcon,
  CalendarSideBarDatePickerSVG,
} from 'icons/dynamic';
import useMediaQuery from 'hooks/useMediaQuery';

// components
import TheButton from 'components/Buttons/TheButton';

// contexts
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import useTranslate from 'hooks/useTranslate';

const today = new Date();

export const SchedulerCalendar = ({
  close,
  selectDay,
  selected,
  returnValue,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { locale } = useContext(UserContext);
  const { sectorData } = useContext(MainContext);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [isToday, setIsToday] = useState(true);
  const [selectedDay, setSelectedDay] = useState();
  const containerRef = useRef();
  const calendarRef = useRef();

  // if two values supplied (a range)
  const isArray = Array.isArray(selected);

  // if two supplied values are equal (a range of different days)
  const isRange =
    isArray && returnValue && selected[0]?.getTime() !== selected[1]?.getTime();

  const isTodaySelected =
    selectedDay?.toLocaleDateString() === today?.toLocaleDateString();

  // --- side effect
  useEffect(() => {
    if (selected) {
      if (isArray) {
        if (returnValue === 'end') {
          setCalendarDate(selected[1]);
          setSelectedDay(selected[1]);
        } else {
          setCalendarDate(selected[0]);
          setSelectedDay(selected[0]);
        }
      } else {
        setCalendarDate(selected);
        setSelectedDay(selected);
      }
    }
  }, [selected, returnValue, isArray]);

  // Functions
  const formatMonthYear = (locale, date) => {
    let year = date.getFullYear();

    let monthLong = new Intl.DateTimeFormat(locale, {
      month: 'long',
    }).format(date);

    monthLong = monthLong.charAt(0).toUpperCase() + monthLong.slice(1);

    return `${year} ${monthLong}`;
  };

  const formatDay = (locale, date) => {
    return date.getDate();
  };

  const formatShortWeekday = (locale, date, dd) => {
    let day = date.toLocaleDateString(locale, { weekday: 'short' });
    day = day.charAt(0).toUpperCase() + day.slice(1);
    day = day.slice(0, 2);

    return day;
  };

  const tileClassName = ({ date, view }) => {
    const today = new Date();
    let isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    const checkIfSelected = new Date(selected);
    checkIfSelected.setHours(0, 0, 0, 0);

    return view === 'month' &&
      isToday &&
      selectedDay?.getTime() === date.getTime()
      ? 'today selected'
      : view === 'month' && isToday
        ? 'today'
        : selectedDay?.getTime() === date.getTime()
          ? 'selected'
          : date.getTime() === checkIfSelected.getTime()
            ? 'currentlySelected'
            : 'none';
  };

  const activeStartDateChange = ({ action, activeStartDate, value, view }) => {
    let viewDate = `${new Date(activeStartDate).getFullYear()} ${new Date(
      activeStartDate,
    ).getMonth()}`;

    let thisMonth = `${calendarDate?.getFullYear()} ${calendarDate?.getMonth()}`;

    setIsToday(viewDate === thisMonth);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={(e) => {
          close && close(false);
          e.stopPropagation();
        }}
      >
        <S.Calendar
          close={close}
          isArray={isArray}
          ref={containerRef}
          tileHeight={`${containerRef.current?.offsetWidth / 7}`}
          isRange={isRange}
        >
          <Calendar
            ref={calendarRef}
            value={
              isArray
                ? [
                    (returnValue === 'start' && calendarDate) || selected[0],
                    (returnValue === 'end' && calendarDate) || selected[1],
                  ]
                : calendarDate
            }
            returnValue={returnValue}
            onChange={setCalendarDate}
            locale={locale}
            formatDay={formatDay}
            formatShortWeekday={formatShortWeekday}
            formatMonthYear={formatMonthYear}
            prevLabel={<Arrow bold color={colors.bg_Primary} width={20} />}
            nextLabel={<Arrow bold color={colors.bg_Primary} width={20} />}
            tileClassName={tileClassName}
            onClickDay={(day) => {
              selectDay(day);
              setSelectedDay(day);
              close && close(false);
            }}
            onActiveStartDateChange={activeStartDateChange}
            calendarType={
              sectorData?.settings?.firstDayOfWeek === 7 ? 'gregory' : 'iso8601'
            }
          />
          {isMobile ? (
            <>
              <CalendarIcon
                className="miniCalendarIcon"
                height={20}
                width={20}
                onClick={() => close && close(false)}
              />
              <TheButton
                title={tr['today']}
                outline
                {...(isTodaySelected &&
                  isToday && {
                    secondary: true,
                    disabled: true,
                  })}
                font="secondary"
                height="20"
                fontWeight={400}
                width="auto"
                float="right"
                flat
                action={() => {
                  // if (!isToday) {
                  //   calendarRef.current.setActiveStartDate(new Date());
                  //   return;
                  // }
                  if (isTodaySelected && isToday) {
                    return;
                  }
                  selectDay(today);
                  setSelectedDay(today);
                  close && close(false);
                }}
                // action={() =>
                //   calendarRef.current.setActiveStartDate(new Date())
                // }
              />
            </>
          ) : (
            <CalendarSideBarDatePickerSVG
              color={isToday ? colors.disabledGrey : colors.bg_Primary}
              check={isToday}
              className="miniCalendarIcon"
              onClick={() => calendarRef.current.setActiveStartDate(new Date())}
            />
          )}
        </S.Calendar>
      </ClickAwayListener>
    </>
  );
};

SchedulerCalendar.propTypes = {
  close: PropTypes.func,
  selectDay: PropTypes.func.isRequired,
};

export default SchedulerCalendar;
