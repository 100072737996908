import styled, { css } from 'styled-components';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { dropdownRollIn, dropdownRollOut } from 'common/styles/animations';

// import { components } from 'react-select';
// const { Menu: OriginalMenu } = components;

import {
  Menu as StyledMenu,
  InputField,
} from 'components/InputField/InputField.styled';

export const Menu = styled(StyledMenu)`
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__next2-button {
    transform: ${({ selectProps: { menuRender } }) =>
      menuRender === 'relative' && 'rotate(0deg)'};
  }
`;

export const InputFieldDate = styled(InputField)``;
