// const appointments = [
//   // MOCK DATA
import { DateTime } from 'luxon';
import { areArraysIdentical } from 'common/helpers/arrayFunctions';
import {
  datesAreOnSameDay,
  formatToIsoWithoutMilliseconds,
  toIsoString,
} from 'common/helpers/dateOperations';
import { areObjectsIdentical } from 'common/helpers/objectFunctions';

const appointmentsReducer = (state = null, action) => {
  let updatedState;

  switch (action.type) {
    case 'APPOINTMENTS_SET':
      if (state === null) {
        return action.payload;
      }

      if (action.payload === null) {
        return action.payload;
      } else if (Array.isArray(action.payload)) {
        const same = areArraysIdentical(action.payload, state);
        return same ? state : action.payload;
      } else {
        const same = areObjectsIdentical(action.payload, state);
        return same ? state : action.payload;
      }

    case 'APPOINTMENT_ADD':
      const newOrder = action.payload.order;

      updatedState = addToEmployeeOrder(
        state,
        newOrder,
        newOrder.employeeId,
        newOrder.startTimeUtc,
      );

      return { employees: updatedState };

    case 'APPOINTMENTS_UPDATE':
      const isArray = Array.isArray(action.payload);
      const data = action.payload;

      return [...state, ...(isArray ? [...data] : [data])];

    case 'APPOINTMENT_UPDATED':
      const { order, newColumn, timezone } = action.payload;
      const employeeId = order.employeeId;

      if (newColumn?.date) {
        if (newColumn?.employeeId) {
          // date and employeeId changes
          updatedState = deleteEmployeeOrder(state, order, employeeId);
          updatedState = addToEmployeeOrder(
            { employees: updatedState },
            order,
            newColumn.employeeId,
            // toIsoString(new Date(newColumn.date)).split('T')[0]
            newColumn.date,
            timezone,
          );
        } else {
          // for same employee diff day
          console.log('for same employee diff time');

          updatedState = deleteEmployeeOrder(state, order, employeeId);
          updatedState = addToEmployeeOrder(
            { employees: updatedState },
            order,
            employeeId,
            newColumn.date,
            timezone,
          );
        }
      } else if (newColumn?.employeeId) {
        // only employee changes

        updatedState = deleteEmployeeOrder(state, order, employeeId);
        updatedState = addToEmployeeOrder(
          { employees: updatedState },
          { ...order, employeeId: newColumn.employeeId },
          newColumn.employeeId,
          order.startTimeUtc,
          timezone,
        );
      } else {
        // same employee and same date
        updatedState = updateEmployeeOrder(state, order, employeeId);
      }

      return { employees: updatedState };

    case 'APPOINTMENT_RESET':
      const oldOrder = action.payload.order;

      updatedState = deleteEmployeeOrderById(state, oldOrder.id);
      updatedState = addToEmployeeOrder(
        { employees: updatedState },
        oldOrder,
        oldOrder.employeeId,
        oldOrder.startTimeUtc,
      );

      return { employees: updatedState };

    case 'APPOINTMENT_KEY_UPDATED':
      return state.map((item) => {
        if (item.id === action.payload.id) {
          const x = updateObjectKey(
            item,
            action.payload.key,
            action.payload.value,
          );
          return x;
        } else {
          return item;
        }
      });

    case 'APPOINTMENTS_ADDED_COPY':
      const found = state.find((element) => {
        return element.id === action.payload.id;
      });

      if (!found) return state;

      const isCopy = found.id.toString().includes('-copy');

      if (isCopy) {
        const originalId = found.id.split('-')[0];

        const nthCopy = state.reduce((prev, curr) => {
          return curr.id.toString().includes(originalId) ? prev + 1 : prev;
        }, 0);

        return [...state, { ...found, id: originalId + `-copy(${nthCopy})` }];
      } else {
        const nthCopy = state.reduce((prev, curr) => {
          return curr.id.toString().includes(found.id) ? prev + 1 : prev;
        }, 0);

        return [...state, { ...found, id: found.id + `-copy(${nthCopy})` }];
      }

    case 'APPOINTMENT_DELETED':
      updatedState = deleteEmployeeOrderById(state, action.payload.id);

      return { employees: updatedState };

    default:
      return state;
  }
};

const updateObjectKey = (obj, key, value) => {
  return { ...obj, [key]: value };
};

const addToEmployeeOrder = (state, order, employeeId, orderDay, timezone) => {
  let orderAdded = false;

  const orderDayIso = toIsoString(new Date(orderDay)).split('T')[0];

  const newState = state.employees.map((el) => {
    if (el.employee.id === employeeId) {
      const newDays = el.days.map((day) => {
        if (day.day === orderDayIso) {
          const orderDateTime = new Date(orderDay);
          order.startTimeUtc = DateTime.now()
            .setZone(timezone)
            .set({
              year: orderDateTime.getFullYear(),
              month: orderDateTime.getMonth() + 1,
              day: orderDateTime.getDate(),
              hours: orderDateTime.getHours(),
              minutes: orderDateTime.getMinutes(),
              millisecond: 0,
            })
            .toUTC()
            .toISO({ suppressMilliseconds: true });

          order.employeeId = employeeId;
          const orders = [...day.orders, order];

          orderAdded = true;

          return { ...day, orders };
        } else {
          return day;
        }
      });

      if (!orderAdded) {
        const orderDateTime = new Date(orderDay);
        order.startTimeUtc = DateTime.now()
          .setZone(timezone)
          .set({
            year: orderDateTime.getFullYear(),
            month: orderDateTime.getMonth() + 1,
            day: orderDateTime.getDate(),
            hours: orderDateTime.getHours(),
            minutes: orderDateTime.getMinutes(),
            millisecond: 0,
          })
          .toUTC()
          .toISO({ suppressMilliseconds: true });

        const newDay = {
          day: orderDayIso,
          orders: [order],
        };

        orderAdded = true;

        return { ...el, days: [...el.days, newDay] };
      } else {
        return { ...el, days: newDays };
      }
    } else {
      return el;
    }
  });

  if (orderAdded) {
    return newState;
  } else {
    // order added to employee who do not had any orders

    order.employeeId = employeeId;
    order.startTimeUtc = formatToIsoWithoutMilliseconds(new Date(orderDay));

    const newEmployee = {
      employee: { id: employeeId },
      days: [
        {
          day: orderDayIso,
          orders: [order],
        },
      ],
    };

    return [...state.employees, newEmployee];
  }
};

const updateEmployeeOrder = (state, order, employeeId) => {
  const orderDate = toIsoString(new Date(order.startTimeUtc)).split('T')[0];

  return state.employees.map((el) => {
    if (el.employee.id === employeeId) {
      const newDays = el.days.map((day) => {
        if (day.day === orderDate) {
          const orders = day.orders.map((el) => {
            if (el.id === order.id) {
              return order;
            } else return el;
          });

          return { ...day, orders };
        } else {
          return day;
        }
      });

      return { ...el, days: newDays };
    } else {
      return el;
    }
  });
};

const deleteEmployeeOrder = (state, oldOrder, employeeId) => {
  const orderDate = toIsoString(new Date(oldOrder.startTimeUtc)).split('T')[0];

  return state.employees.map((el) => {
    if (el.employee.id === employeeId) {
      const newDays = el.days.map((day) => {
        if (day.day === orderDate) {
          const orders = day.orders.filter((order) => {
            return order.id !== oldOrder.id;
          });

          return { ...day, orders };
        } else {
          return day;
        }
      });

      return { ...el, days: newDays };
    } else {
      return el;
    }
  });
};

// slower
const deleteEmployeeOrderById = (state, id) => {
  let foundAndDeleted = false;

  return state.employees.map((el) => {
    const newDays = el.days.map((day) => {
      if (!foundAndDeleted) {
        const orders = day.orders.filter((order) => {
          if (order.id === id) foundAndDeleted = true;
          return order.id !== id;
        });

        return { ...day, orders };
      } else {
        return day;
      }
    });

    return { ...el, days: newDays };
  });
};

export default appointmentsReducer;
