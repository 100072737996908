import { useRef } from 'react';
import Select, { components } from 'react-select';

// style
import * as S from './InputFieldDate.styled';

// icons
import { Calendar } from 'icons/dynamic';

// components
import { SideBarDatePicker } from 'components/SideBar/SideBarDatePicker/SideBarDatePicker';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { animateClose } from 'common/helpers/animateClose';

export const InputFieldDate = ({
  id,
  headerId,
  className,
  label,
  placeholder,
  disabled,
  state, // { value: { value, label }, default: { value, label }, setValue: () => {}}
  menuRender, // [absolute, relative, portal]
  stayOpen,
  notClearable,
  noIndicators,
  hasDateValues,
  minDate,
  maxDate,
  activeStartDate,
  onActiveStartDateChange,
  setShouldRefetch,
  focus,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;

  if (!headerId) headerId = id;

  const isMenuOpen = useRef(); // required for correct operation of menu open/close
  const noValue = !state?.value;

  const onChange = (value, { action }) => {
    state.setValue(headerId, action === 'clear' ? null : value);
    setShouldRefetch && setShouldRefetch(true);
    // setInputValue(''); // not sure if it breaks anything
  };

  const menuOpenCallback = () => {
    isMenuOpen.current = true;
  };

  const menuCloseCallback = () => {
    animateClose('select__menu');
    isMenuOpen.current = false;
  };

  return (
    <S.InputFieldDate
      stayOpen={stayOpen}
      className={'input-field ' + (className ? className : '')}
    >
      <label htmlFor={id}>{label}</label>

      <Select
        id={id}
        headerId={headerId}
        className="select"
        classNamePrefix="select"
        placeholder={
          noValue && disabled ? '-' : placeholder ? placeholder : tr['select']
        }
        value={state?.value || null}
        state={state}
        onChange={onChange}
        {...(stayOpen && { menuIsOpen: true })}
        components={{
          Control,
          Menu,
          DropdownIndicator,
          ...((disabled || noIndicators || notClearable) && {
            ClearIndicator: null,
          }),
          ...((disabled || noIndicators) && { IndicatorSeparator: null }),
          ...((disabled || noIndicators) &&
            !isMobile && {
              DropdownIndicator: null,
            }),
        }}
        isSearchable={false}
        inputProps={{
          autoComplete: 'nope',
          autoCorrect: 'nope',
          spellCheck: 'nope',
        }}
        {...(menuRender === 'portal' && {
          menuPortalTarget: document.getElementById('portal'),
        })}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          ...(menuRender === 'relative' && {
            menu: (base) => ({ ...base, position: 'relative' }),
          }),
        }}
        stayOpen={stayOpen}
        menuRender={menuRender}
        hasDateValues={hasDateValues}
        minDate={minDate}
        maxDate={maxDate}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        focus={focus}
        {...props}
      />
    </S.InputFieldDate>
  );
};

export default InputFieldDate;

const Control = (props) => {
  const {
    selectProps: { focus },
  } = props;

  return (
    <div onClick={focus}>
      <components.Control {...props} />
    </div>
  );
};

const Menu = (props) => {
  const {
    setValue,
    selectProps: {
      // headerId,
      formatDay,
      formatMonthYear,
      formatShortWeekday,
      state,
      menuRender,
      noToday,
      hasDateValues,
      minDate,
      maxDate,
      activeStartDate,
      onActiveStartDateChange,
      focus,
      locale,
      calendarType,
    },
  } = props;
  return (
    <S.Menu {...props}>
      <SideBarDatePicker
        value={state?.value?.value.toString()}
        // onChange={(value) => setValue(value, 'select-option')}
        onChange={(value) => {
          setValue(value, 'select-option');
          // state.setValue(headerId, value);
          focus();
        }}
        container={menuRender}
        formatDay={formatDay}
        formatMonthYear={formatMonthYear}
        formatShortWeekday={formatShortWeekday}
        noToday={noToday}
        hasDateValues={hasDateValues}
        minDate={minDate}
        maxDate={maxDate}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        locale={locale}
        calendarType={calendarType}
      />
    </S.Menu>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props} className={'close'}>
    <Calendar width={16} height={16} />
  </components.DropdownIndicator>
);
