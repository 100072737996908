import { useState } from 'react';

export const useToggler = (initialState) => {
  const [active, setActive] = useState(initialState);

  const toggle = () => {
    setActive((oldState) => !oldState);
  };

  const on = () => {
    setActive(() => true);
  };

  const off = () => {
    setActive(() => false);
  };

  return {
    active,
    toggle,
    on,
    off,
  };
};

export default useToggler;
