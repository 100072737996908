import styled from 'styled-components/macro';

export const HeaderRowCell = styled.th`
  position: relative;
  font-size: 12px;
  padding: 9px 5px 0;
  vertical-align: ${({ isIcon }) => (isIcon ? 'middle' : 'top')};
  text-align: ${({ isIcon }) => (isIcon ? 'center' : 'left')};
  padding-top: ${({ isIcon }) => (isIcon ? 0 : 9)}px;
`;
