import { useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router';

// style
import * as S from './OrderInformation.styled';

// components
import { Avatar } from 'components/Avatar/Avatar';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFunctionBar } from 'hooks/useFunctionBar';

const LabelValue = ({ arr }) => {
  const { locale } = useContext(UserContext);

  return (
    <S.LabelValue>
      <div className="label-container">
        {arr.map((item, i) => (
          <span className="label" key={i}>
            {item.label}:
          </span>
        ))}
      </div>
      <div className="value-container">
        {arr.map((item, i) =>
          item?.employee ? (
            <div key={i} className="employee-wrapper">
              <div className="avatar">
                <Avatar raised />
              </div>
              <span className="fullName">{item.employee.fullName}</span>
            </div>
          ) : item?.date ? (
            <div key={i} className="date-wrapper">
              {item.date?.toLocaleDateString(locale, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </div>
          ) : (
            ''
          ),
        )}
      </div>
    </S.LabelValue>
  );
};

const OrderInformation = () => {
  // Hooks
  const tr = useTranslate().use().global;
  const func = useFunctionBar(['functions', 'print']);
  const { setFuncBarFunctions } = useOutletContext();
  const orderInfoArray = [
    {
      label: tr['created-by'],
      employee: {
        pic: '',
        fullName: 'Vardenis Pavardenis',
      },
    },
    {
      label: tr['creation-date/time'],
      date: new Date(),
    },
    {
      label: tr['primary-specialist'],
      employee: {
        pic: '',
        fullName: 'Vardenis Pavardenis',
      },
    },
    {
      label: tr['original-order-date/time'],
      date: new Date(),
    },
  ];

  // state
  // -- local

  // -- side effects
  useEffect(() => {
    setFuncBarFunctions([]);
  }, []);

  return (
    <S.OrderInformation>
      <LabelValue arr={orderInfoArray} />
    </S.OrderInformation>
  );
};

export default OrderInformation;
