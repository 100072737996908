// import { MainContext } from 'pages/Main/Main';
import { createContext, useContext, useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    // case 'SET_TABLE_STATE':
    //   return { ...state, tableState: action.payload };
    // case 'SET_TABLES_FILTERS':
    //   return { ...state, tablesFilters: action.payload };
    // case 'SET_TABLES_SORT':
    //   return { ...state, tablesSort: action.payload };

    default:
      return state;
  }
};

export const FunctionsContext = createContext();

export const FunctionsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    // tablesFilters: {},
    // tablesSort: {},
    // tableState: {},
  });
  return (
    <FunctionsContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </FunctionsContext.Provider>
  );
};
