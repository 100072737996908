import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

// style
import * as S from './SettingsTemplate.styled';

// context
import { SettingsTemplateContext } from 'context/SettingsTemplateContext';

// components
import { FunctionBar } from 'components/FunctionBar/FunctionBar';
import { TabTemplate } from 'pages/Settings/TabTemplate';

// hooks and helpers
import { useFunctionBar } from 'hooks/useFunctionBar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSideBarMenu } from 'hooks/useSideBarMenu';

const PageContent = ({ element, subElement, tabs }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <S.PageContents>
      <S.Title>
        <span>{element.name}&nbsp;|&nbsp;</span>
        <div>{subElement.name}</div>
      </S.Title>

      <S.Container>
        {tabs && (
          <S.Links>
            {tabs.map((tab, key) => (
              <NavLink to={tab.route} key={key}>
                {tab.name}
              </NavLink>
            ))}
          </S.Links>
        )}

        {tabs ? (
          <Routes>
            {!isMobile && tabs && (
              <Route index element={<Navigate to={tabs[0].route} />} />
            )}
            {tabs.map((tab, key) => (
              <Route
                key={key}
                path={tab.route}
                element={<TabTemplate name={tab.name} view={tab.view} />}
              />
            ))}
          </Routes>
        ) : (
          <TabTemplate name={subElement.name} view={subElement.view} />
        )}
      </S.Container>
    </S.PageContents>
  );
};

const SettingsTemplate = ({ funcTabs }) => {
  const location = useLocation();

  const parseString = location.pathname.split('/');

  const currRootMenuItem = parseString[1];
  const currSideBarMenuCategory = parseString[2];
  const currSideBarMenuSubItem = parseString[3];
  // const currTab = parseString[4];

  const menu = useSideBarMenu(currRootMenuItem);

  const element = menu.find((item) => item.id === currSideBarMenuCategory);

  const subElement = element.subItems.find(
    (item) => item.id === currSideBarMenuSubItem,
  );

  const tabs = subElement.tabs;

  // Hooks
  const [functionBarFunctions, setFunctionBarFunctions] = useState(undefined);

  const contextObj = {
    functionBarFunctions: {
      state: functionBarFunctions,
      setState: setFunctionBarFunctions,
    },
  };

  // console.log(
  //   'location:',
  //   location,
  //   '\ncurrRootMenuItem:',
  //   currRootMenuItem,
  //   '\ncurrSideBarMenuCategory:',
  //   currSideBarMenuCategory,
  //   '\ncurrSideBarMenuSubItem:',
  //   currSideBarMenuSubItem,
  //   '\ncurrTab:',
  //   currTab,
  //   '\nmenu:',
  //   menu,
  //   '\nelement:',
  //   element,
  //   '\nsubElement:',
  //   subElement,
  //   '\ncurrTabs:',
  //   tabs
  // );

  const pageContentProps = {
    element,
    subElement,
    tabs,
  };

  return (
    <SettingsTemplateContext.Provider value={contextObj}>
      <S.SettingsTemplate>
        <FunctionBar functions={functionBarFunctions} tabs={funcTabs} />

        {funcTabs?.length ? (
          <Routes>
            <Route index element={<Navigate to={funcTabs[0].route} />} />
            <Route
              path=":branchId/*"
              element={<PageContent {...pageContentProps} />}
            />
          </Routes>
        ) : (
          <PageContent {...pageContentProps} />
        )}
      </S.SettingsTemplate>
    </SettingsTemplateContext.Provider>
  );
};

export default SettingsTemplate;
