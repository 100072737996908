import { useContext } from 'react';

// style
import * as S from './SetContents.styled';
import { colors } from 'common/colors/colors';

// components
import { ServiceSelector } from '../ServiceSelector/ServiceSelector';

// icons
import { Clock, Minus, Plus, PriceTagSVG, RulerSVG } from 'icons/dynamic';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useToggler } from 'hooks/useToggler';
import useNumber from 'hooks/useNumber';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

export const SetContents = ({
  name,
  state,
  disabled,
  breadcrumbs,
  getUnitsById,
}) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { formatPrice, formatUnits } = useNumber({ price: true });

  const {
    active: serviceSelectorVisible,
    on: showServiceSelector,
    off: hideServiceSelector,
  } = useToggler();

  const removeItem = (serviceId) => {
    // console.log(`remove service ${serviceId}`);

    let updatedValue = state.value.value;

    updatedValue = updatedValue.filter((service) => service.id !== serviceId);

    state.setValue('composition', { value: updatedValue, label: updatedValue });
  };

  const hasContents = state?.value?.value?.length > 0;

  return (
    <S.SetContents disabled={disabled}>
      <label>{tr['composition']}</label>

      <S.SetServiceContainer disabled={disabled} hasContents={hasContents}>
        {hasContents ? (
          state?.value?.value?.map((service, index) => (
            <S.SetService key={index}>
              <div className="info">
                <div className="info__title">
                  {(service.subgroupName || service.groupName) && (
                    <span>{service.subgroupName || service.groupName}. </span>
                  )}
                  {service.name}
                </div>

                <div className="info__data">
                  {'baseDurationInMinutes' in service && (
                    <span>
                      <Clock width={13} height={13} />
                      {getTimeFromMinutes(service.baseDurationInMinutes)}
                    </span>
                  )}

                  {'unitId' in service && (
                    <span>
                      <RulerSVG width={13} height={13} />
                      {`${formatUnits(service.unitVolume)} ${getUnitsById(service.unitId)?.label}`}
                    </span>
                  )}

                  <span>
                    <PriceTagSVG width={13} height={13} />
                    {('avgUnitPriceWithVat' in service &&
                      formatPrice(service.avgUnitPriceWithVat)) ||
                      '-'}
                  </span>

                  <span>
                    {`${tr['amount']}: ${(+service.compServiceCount).toLocaleString(locale) || '-'} ${tr['pcs']}`}
                  </span>
                </div>
              </div>

              {!disabled && (
                <button type="button" onClick={() => removeItem(service.id)}>
                  <Minus color={colors.bg_grey_3} bold inCircle />
                </button>
              )}
            </S.SetService>
          ))
        ) : (
          <div className="empty">{tr['empty-fill-in']}</div>
        )}

        {!disabled && (
          <button type="button" onClick={showServiceSelector}>
            <Plus
              black
              inCircle
              width={20}
              height={20}
              color={colors.bg_Button_Primary}
            />
          </button>
        )}

        {serviceSelectorVisible && (
          <ServiceSelector
            name={name}
            data={{ state: state?.value?.value, setState: state?.setValue }}
            close={hideServiceSelector}
            breadCrumbs={breadcrumbs}
            toastTextOnAdd={tr['composition-extended']}
          />
        )}
      </S.SetServiceContainer>
    </S.SetContents>
  );
};

export default SetContents;
