import { Controller, useFormContext } from 'react-hook-form';

// components
import { BaseInputPhone } from 'components/Inputs';

export default ({ name, prefixName, label, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const { prefix, number } = value;
        let inputValue = '';

        if (number !== '') {
          inputValue = prefix + number;
        } else {
          inputValue = prefix;
        }

        return (
          <BaseInputPhone
            id={name}
            label={label}
            value={inputValue}
            onChange={(val, val2) =>
              // TODO: Speak to BE about consistency
              {
                if (value.prefix + ' ' + value.number !== val2.inputValue) {
                  onChange({
                    prefix: '+' + val2.country.dialCode,
                    number: val2?.inputValue
                      ?.replace('+' + val2.country.dialCode, '')
                      .trim(),
                  });
                }
              }
            }
            errorMessage={error?.message}
            {...other}
          />
        );
      }}
    />
  );
};
