import { useCallback, useContext, useEffect, useState } from 'react';

// components
import { Search } from 'components/Search/Search';

// context
import { MainContext } from 'pages/Main/Main';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useDebouncedCallback } from 'use-debounce';
import { useMediaQuery } from 'hooks/useMediaQuery';

export const DebouncedSearch = ({
  setSearchQuery: setAuxSearchQuery,
  queryId,
  autofocus,
  ...restProps
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const { searchQueries, setSearchQuery, searchWidgetVisible, setState } =
    useContext(MainContext);

  // restore search phrase from main context, setting localSearchQuery
  const [localSearchQuery, setLocalSearchQuery] = useState(
    searchQueries?.[queryId] && !setAuxSearchQuery
      ? searchQueries[queryId]
      : '',
  );

  // store value using prop-provided setter (if any),
  // otherwise - into main context under prop-provided queryId
  const setQuery = useCallback(() => {
    setAuxSearchQuery
      ? setAuxSearchQuery(localSearchQuery)
      : setSearchQuery(localSearchQuery, queryId);
  }, [queryId, localSearchQuery, setAuxSearchQuery, setSearchQuery]);

  // debounce value storing if at least 3 chars were input
  const debounceQuery = useDebouncedCallback(() => {
    // localSearchQuery?.length >= 3 && setQuery();
    setQuery();
  }, 500);

  // update search widget status according to query presence
  useEffect(() => {
    if (localSearchQuery?.length === 0) {
      searchWidgetVisible &&
        !isMobile &&
        setState({ type: 'hide-search-widget' });
    } else {
      !searchWidgetVisible &&
        !isMobile &&
        setState({ type: 'show-search-widget' });
    }
  }, [localSearchQuery, searchWidgetVisible, setState, isMobile]);

  // if search query was cleared, store value immediately
  useEffect(() => {
    localSearchQuery?.length === 0 ? setQuery() : debounceQuery();
  }, [localSearchQuery, setQuery, debounceQuery]);

  return (
    <Search
      placeholder={tr['search']}
      state={{ input: localSearchQuery, setInput: setLocalSearchQuery }}
      autofocus={autofocus}
      {...restProps}
    />
  );
};

const queryIdOrSetSearchQueryRequired = (props) => {
  if (!props.queryId && !props.setSearchQuery) {
    return new Error(
      'either [queryId] (for main context store) or [setSearchQuery] (local context setter) is required for ${componentName}',
    );
  }
};

DebouncedSearch.propTypes = {
  queryId: queryIdOrSetSearchQueryRequired,
  setSearchQuery: queryIdOrSetSearchQueryRequired,
};

// DebouncedSearch.propTypes = {
//   queryId: (props, propName, componentName) => {
//     if (!props.queryId && !props.setSearchQuery)
//       return new Error(
//         `either [${propName}] (for main context store) or [setSearchQuery] (local context setter) is required for ${componentName}`,
//       );
//   },
//   setSearchQuery: (props, propName, componentName) => {
//     if (!props.queryId && !props.setSearchQuery)
//       return new Error(
//         `either [queryId] (for main context store) or [${propName}] (local context setter) is required for ${componentName}`,
//       );
//   },
// };
