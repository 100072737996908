import DialogBox from '../DialogBox';

// Style
import * as S from './DialogPresets.styled';

const DialogPresets = ({ preset, custom, close, buttons }) => {
  // Var
  const presets = {
    smsLink: {
      headerText: 'SMS zinute',
      text: (
        <>
          Sveiki, pas mus galite užsiregistruoti internetu, paspaude nuorodą{' '}
          <a href="//serve.lt" target="_blank" rel="noreferrer noopener">
            serveonline/Manodantukasklinika
          </a>
        </>
      ),
    },
    smsReminder: {
      headerText: 'SMS zinute',
      text: (
        <>
          Sveiki, 2021-09-01 18:00, laukiame Jūsų pas specialistą Joną Jonaitį.
          Vilniaus odontologijos centre, adresu: Dariaus ir Girėno g. 245 - 96,
          Vilnius. Administratorė: +370 684 45678. Prašome patvirtinti savo
          atvykimą žinute įraše žodį TAIP arba NE. Dėkojame.
        </>
      ),
    },
    deleteOrder: {
      headerText: 'Dėmesio',
      text: <>Ar tikrai norite ištrinti užsakymą?</>,
    },
    deleteTimeOff: {
      headerText: 'Dėmesio',
      text: <>Ar tikrai norite ištrinti nedarbo priežastį?</>,
    },
  };

  // Functions
  const getContent = (content) => {
    if (!preset && !custom) {
      console.log('NOTE: you should give "preset" or "custom" fields');
      return '';
    }

    if (!presets[preset]) {
      console.log('Wrong preset key');
      return;
    }

    return custom ? custom[content] : presets[preset][content];
  };

  return (
    <S.DialogPresets>
      <DialogBox
        close={close}
        headerText={getContent('headerText')}
        buttons={buttons}
      >
        <div className="content">{getContent('text')}</div>
      </DialogBox>
    </S.DialogPresets>
  );
};

export default DialogPresets;
