import { colors } from 'common/colors/colors';
import { ContentLayout } from 'components/ContentLayout/ContentLayout.styled';
import styled from 'styled-components/macro';

export const DailyView = styled.div`
  height: calc(100vh - 70px);
  width: 100%;

  display: flex;
  flex-direction: column;

  nav {
    z-index: 6;
  }

  section {
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;

    position: relative;

    margin: 0 50px;

    .schedule__wrapper {
      height: 100%;
      max-height: 100%;

      display: flex;
      flex-direction: column;

      ${ContentLayout} {
        z-index: 11;
      }
    }

    .schedule__add-button-container {
      position: absolute;
      bottom: 58px;
      right: 50px;

      z-index: 4;

      height: 70px;
      width: 70px;
    }
  }

  .loader {
    position: absolute;

    z-index: 10;

    width: 100%;
    height: 100%;

    backdrop-filter: grayscale(100%);
  }

  .search_header-contaiener {
    position: fixed;
    top: ${(props) => (props.showSearchHeader ? 0 : -85)}px;
    left: 0;
    right: 0;

    height: 70px;
    z-index: 5;

    transition: top 0.2s ease-in-out;
  }

  @media screen and (max-width: 960px) {
    height: ${(props) =>
      props.showSearchHeader
        ? 'calc(100svh - 55px - 117px)'
        : 'calc(100svh - 55px)'};

    margin-top: ${(props) => (props.showSearchHeader ? 117 : 0)}px;

    transition: all 200ms ease-in-out;

    section {
      margin: 0;
      margin-top: 10px;

      .schedule__wrapper {
      }

      .navigation-container {
        margin: 0 20px;
      }

      .schedule__add-button-container {
        bottom: 20px;
        right: 85px;
        height: 48px;
        width: 48px;
      }
    }

    .search_header-contaiener {
      top: ${(props) => (props.showSearchHeader ? 0 : -184)}px;
    }
  }
`;
