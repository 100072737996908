const calendarViewReducer = (
  state = {
    employees: null,
    pinnedEmployees: [],
  },
  action,
) => {
  const employees = structuredClone(state.employees);
  const pinnedEmployees = structuredClone(state.pinnedEmployees);

  let sortedEmployees;

  switch (action.type) {
    case 'CALENDAR_VIEW_SET':
      sortedEmployees = sortEmployees(
        action.payload.employees,
        pinnedEmployees,
      );

      return { ...state, employees: sortedEmployees };
    case 'CALENDAR_VIEW_UPDATE':
      // return { professionals: action.payload };
      return;
    case 'PROFESSIONAL_FAIL':
      // return { professionals: action.payload };
      return;
    case 'EMPLOYEE_PINNED':
      const newId = action.payload.id;

      const index = pinnedEmployees.indexOf(newId);

      if (index !== -1) {
        pinnedEmployees.splice(index, 1);
      } else {
        pinnedEmployees.push(newId);
      }

      sortedEmployees = sortEmployees(employees, pinnedEmployees);

      return {
        employees: sortedEmployees,
        pinnedEmployees,
      };

    default:
      return state;
  }
};

const sortEmployees = (employees, pinnedEmployees) => {
  if (!employees) {
    return null;
  }

  const updatedByPinned = employees.map((obj) => {
    const index = pinnedEmployees.indexOf(obj.employee.id);

    if (index !== -1) {
      return { ...obj, employee: { ...obj.employee, pinned: true } };
    } else {
      return { ...obj, employee: { ...obj.employee, pinned: false } };
    }
  });

  const onlyPrioritized = updatedByPinned.filter(
    (item) => item.employee.pinned,
  );
  const otherEmployees = updatedByPinned
    .filter((item) => !item.employee.pinned)
    .sort((a, b) => {
      const lastNameA = a.employee.lastName.toLowerCase();
      const lastNameB = b.employee.lastName.toLowerCase();

      return lastNameA < lastNameB ? -1 : lastNameA > lastNameB ? 1 : 0;
    });

  return [...onlyPrioritized, ...otherEmployees];
};

export default calendarViewReducer;
