import { useEffect } from 'react';
import classNames from 'classnames';

// style
import * as S from '../CategoryTree.styled';
import { colors } from 'common/colors/colors';

// icons
import {
  Clock,
  CubeSVG,
  Plus,
  VerticalEllipsis,
  PriceTagSVG,
  RulerSVG,
  TrashCan,
} from 'icons/dynamic';

// components
import { ServiceItemButton } from '../../ServiceItemButton/ServiceItemButton';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import useNumber from 'hooks/useNumber';
import { useSystemGetUnits } from 'services/systemService';
import { useServicesDelete } from 'services/serviceService';
import {
  getParent,
  isCurrent,
  isNew,
  isParentEmpty,
  removeObjectById,
} from 'common/helpers/serviceFunctions';
import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

export const Service = ({
  service,
  activeItem,
  setActiveItem,
  parentInactive,
  serviceAction,
  noIndent,
  categoryName,
  groupName,
  subgroupName,
  search,
  setCategories,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  parentIsOpenHandler,
  averagePrices = true,
  ...props
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { getUnitsById } = useSystemGetUnits();
  const { formatPrice, formatUnits } = useNumber({ price: true });

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  const isInactive = (context) =>
    (parentInactive || !context.isActive) && 'inactive';

  const isSet = service.type === 'set';

  // data delete handler
  const { mutate, isSuccess: isDeletionSuccess } = useServicesDelete('service');

  // delete active item from CategoryTree in case of successful removal from B/E
  useEffect(() => {
    if (isDeletionSuccess) {
      // remove current object
      removeObjectById(service.id, service.type, setCategories, setActiveItem);

      // update isEmpty/isOpen status
      setCategories((oldCategories) => {
        const newCategories = [...oldCategories];
        const parent = getParent(service, newCategories);

        if (isParentEmpty(parent)) {
          // update isEmpty status of parent
          parent.isEmpty = true;
          // update isOpen status of parent
          parentIsOpenHandler && parentIsOpenHandler(false);
        }

        return newCategories;
      });
    }
  }, [
    isDeletionSuccess,
    service.id,
    service.type,
    setActiveItem,
    setCategories,
    service,
    parentIsOpenHandler,
  ]);

  const showServiceDetails = () =>
    setActiveItem &&
    service.id !== 'new' &&
    setActiveItem({ id: service.id, type: service.type });

  const path = {
    categoryName: service.categoryName || categoryName,
    groupName: service.groupName || groupName,
    ...((service.subgroupName || subgroupName) && {
      subgroupName: service.subgroupName || subgroupName,
    }),
  };

  const parentName = path.subgroupName || path.groupName || path.categoryName;

  const getFunctionList = (action) => {
    return [
      {
        id: 'delete',
        label: tr['delete'],
        icon: <TrashCan bold />,
        fontColor: 'red',
        onClick: () => {
          console.log('delete service');
          handleConfirmDialog(service);
          action && action();
        },
      },
    ];
  };

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  // confirm dialog handler
  const handleConfirmDialog = (context) => {
    setConfirmDeleteButtons((old) => [
      ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
      {
        ...old.find((button) => button.id === 'cancel'),
        ...{
          message: `${tr['confirm-message']} ${tr['remove']} ${
            tr[context.type + '/accusative']
          }?`,
        },
      },
      {
        ...old.find((button) => button.id === 'remove'),
        ...{
          action: () => {
            console.log(context.type + ' deletion confirmed');
            mutate({ id: context?.id });
            context?.id === activeItem?.id && setActiveItem(undefined);
            confirmDialogHandler.off();
          },
        },
      },
    ]);
    confirmDialogHandler.on();
  };

  const isMaterial =
    service?.baseDurationInMinutes === undefined ||
    service?.baseDurationInMinutes === null;

  const price = averagePrices
    ? service?.avgUnitPriceWithVat
    : (service?.empUnitPriceWithVat ?? service?.sectorUnitPriceWithVat);

  return (
    <S.CategoryTreeItem
      className={classNames(
        service.type,
        isCurrent(service, activeItem),
        isInactive(service),
        serviceAction && 'action',
      )}
      onClick={showServiceDetails}
      noIndent={noIndent}
      serviceAction={!!serviceAction}
      search={search}
      isNew={isNew(activeItem)}
      isSet={isSet}
      onMouseEnter={showContextMenuIcon}
      onMouseLeave={debounceHideContextMenuIcon}
      {...((isNew(service) || isCurrent(service, activeItem)) && { ref })} // required for scrolling into view
      {...props}
    >
      <div>
        {isSet && (
          <div>
            <CubeSVG />
          </div>
        )}

        <div className={serviceAction && 'item-info'}>
          <span>{service?.name}</span>

          {search && parentName && (
            <span className="path"> ({parentName})</span>
          )}

          {(serviceAction || search) && (
            <div className="item-details">
              <div className="item-details-wrapper">
                <div className="item-details-wrapper-label">
                  {isMaterial ? <RulerSVG /> : <Clock />}
                </div>

                <div className="item-details-wrapper-value">
                  {isMaterial
                    ? `${formatUnits(service.unitVolume)} ${getUnitsById(service.unitId)?.label}`
                    : getTimeFromMinutes(
                        service?.empDurationInMinutes ??
                          service?.baseDurationInMinutes,
                      )}
                </div>
              </div>

              <div className="item-details-wrapper">
                <div className="item-details-wrapper-label">
                  <PriceTagSVG />
                </div>

                <div className="item-details-wrapper-value">
                  {price === undefined || price === null
                    ? '-'
                    : formatPrice(price)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="context-menu-icon-container">
        {serviceAction && (
          <ServiceItemButton
            id={service.type + '-' + service.id}
            icon={<Plus inCircle bold />}
            action={() => serviceAction({ ...service, ...path })}
            parentId={'category-container'}
          />
        )}

        {((!isMobile && isHovered) ||
          (isMobile && isCurrent(service, activeItem))) &&
          !isNew(activeItem) &&
          !search &&
          !serviceAction && (
            <ServiceItemButton
              id={service.type + '-' + service.id}
              icon={
                <VerticalEllipsis
                  {...(isCurrent(service, activeItem) && {
                    color: colors.text_Primary,
                  })}
                />
              }
              parentId={'category-container'}
              items={getFunctionList}
              triggerCallback={setIsContextMenuOpen}
            />
          )}
      </div>
    </S.CategoryTreeItem>
  );
};
