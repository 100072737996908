import * as S from '../SVG.styled';

export default ({ color, width, height, bold }) => {
  const content = bold ? (
    <path
      d="M 29.99535,18.813891 C 29.925,15.708141 27.7272,12.565731 23.6646,12.565731 H 4.17099 l 8.08539,-8.0853747 c 0.476865,-0.47688 0.476865,-1.250265 0,-1.73019 -0.476865,-0.476865 -1.25331,-0.476865 -1.73019,0 L 0.3560325,12.920331 c -0.05808,0.05808 -0.1069905,0.11922 -0.1528425,0.186465 -0.018342,0.03057 -0.0336255,0.06114 -0.0489105,0.09171 -0.0213978,0.03974 -0.0458526,0.07643 -0.06113685,0.11616 -0.01834125,0.03973 -0.0275118,0.08253 -0.0397392,0.12534 -0.0091706,0.03361 -0.0244548,0.06724 -0.0305685,0.10392 -0.03056865,0.15897 -0.03056865,0.321015 0,0.479865 0.0061137,0.03675 0.02139795,0.07035 0.0305685,0.10395 0.0122274,0.0429 0.02139795,0.08265 0.0397392,0.1254 0.0183411,0.03975 0.03973905,0.0795 0.06113685,0.1161 0.018342,0.0306 0.0305685,0.0642 0.0489105,0.0918 0.045852,0.0672 0.0947625,0.1314 0.1528425,0.18645 L 10.52619,24.817641 c 0.23844,0.23835 0.550245,0.3576 0.865095,0.3576 0.314865,0 0.626655,-0.11925 0.865095,-0.3576 0.476865,-0.47685 0.476865,-1.25025 0,-1.73025 l -8.08233,-8.0823 H 23.6676 c 2.62275,0 3.84255,1.938 3.8853,3.8577 0.04275,1.85865 -1.05465,3.78135 -3.5979,3.8976 -0.13455,0.0062 -0.2721,0.0092 -0.40965,0.0092 -0.6756,0 -1.2228,0.5472 -1.2228,1.2228 0,0.67545 0.5472,1.22265 1.2228,1.22265 0.1743,0 0.3516,-0.003 0.52275,-0.01215 3.9831,-0.18045 6.0006,-3.3534 5.93025,-6.39495 z"
      fill="currentColor"
      strokeWidth="1.5"
      transform="translate(0,1.1962207)"
    />
  ) : (
    <path
      d="m 0.04700757,14.737888 c -0.009561,0.02231 -0.01274788,0.04143 -0.01912196,0.06374 -0.0063739,0.01912 -0.01274788,0.03505 -0.01593483,0.05418 -0.01593489,0.08286 -0.01593489,0.165795 0,0.248595 0.0031869,0.01905 0.0095609,0.03825 0.01593483,0.05415 0.0063741,0.02235 0.01274802,0.0414 0.01912196,0.06375 0.009561,0.02235 0.02230885,0.0414 0.03186984,0.06375 0.0095608,0.0159 0.01593477,0.0318 0.02549575,0.04785 0.0223089,0.03495 0.0509917,0.0669 0.0796746,0.0987 L 10.787124,26.035776 c 0.12429,0.1242 0.286829,0.18795 0.449369,0.18795 0.16254,0 0.325064,-0.06375 0.449354,-0.18795 0.248595,-0.248699 0.248595,-0.653398 0,-0.901948 L 2.1759104,15.617547 H 24.038653 c 3.148644,0 4.636941,2.409295 4.687941,4.646541 0.051,2.246845 -1.277998,4.573341 -4.359742,4.71359 -0.149849,0.0063 -0.305999,0.0096 -0.458999,0.0096 -0.350549,0 -0.637348,0.2868 -0.637348,0.637349 0,0.350549 0.286799,0.637349 0.637348,0.637349 0.1722,0 0.34425,-0.0032 0.516299,-0.01275 3.920093,-0.1752 5.637889,-3.241194 5.57414,-6.016938 -0.06705,-2.928895 -2.135396,-5.892784 -5.962939,-5.892784 H 2.1759104 L 11.692222,4.8263728 c 0.248595,-0.2485795 0.248595,-0.6533237 0,-0.9019032 -0.248579,-0.2485945 -0.653324,-0.2485945 -0.901903,0 L 0.18723524,14.530724 c -0.0286829,0.02869 -0.0573662,0.06375 -0.079675,0.09881 -0.009561,0.01593 -0.01593492,0.03186 -0.0254959,0.0478 -0.01274788,0.01913 -0.02230886,0.04143 -0.03186984,0.06373 z"
      fill="currentColor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color ? color : 'currentColor'}
    >
      {content}
    </S.StyledSVG>
  );
};
