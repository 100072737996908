import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const ServiceMenuContainer = styled.div`
  @media screen and (max-width: 960px) {
    display: none;
  }

  .layout_container {
    display: flex;
    position: relative;
  }
`;

export const Content = styled.section`
  flex-grow: 1;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
`;

export const EmergingContainer = styled.div`
  height: 100vh;
  min-width: ${(props) => (props.showMenu ? 0 : 400)}px;
  max-width: 540px;
  width: ${(props) => (props.showMenu ? '30%' : 0)};
  overflow: hidden;
  transition: 0.3s ${(props) => (props.show ? 'ease-in' : 'ease-out')};
  border-radius: 0 10px 0 0;
  background-color: ${colors.bg_grey_1};
  position: absolute;
  top: 0;
  left: ${(props) => (props.show ? 0 : -80)}%;
  z-index: 6;
  box-shadow: ${shadow.md};
`;
