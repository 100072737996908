import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { pulse } from 'common/styles/animations';

export const TheButton = styled.button`
  align-items: center;
  animation: ${pulse} 0.3s ease 0s normal forwards;
  background-color: ${(props) => props.backgroundColor};
  border: ${({ borderless, borderColor }) =>
    borderless ? 'none' : '1px solid ' + borderColor};
  border-radius: 25px;
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => props.color};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  float: ${({ float }) => (float ? float : 'none')};
  font-family: ${({ font }) => {
    switch (font) {
      case 'secondary':
        return fontFamilies.family_Secondary;
      case 'primary':
      default:
        return fontFamilies.family_Primary;
    }
  }};
  font-size: ${(props) =>
    props.height?.replace(/[^0-9]/g, '') <= '25' ? '12px' : '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  gap: 5px;
  height: ${(props) => (props.height ? props.height + 'px' : '100%')};
  justify-content: center;
  padding: ${({ noTitle }) => (noTitle ? 0 : '3px 20px')};
  width: ${({ width }) =>
    width ? width + (isNaN(width) ? '' : 'px') : '100%'};

  span {
    display: block;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
  }
`;
