// components
import DialogBox from 'components/DialogBox/DialogBox';
import Overlay from 'components/Overlay/Overlay';

// hooks
import useTranslate from 'hooks/useTranslate';

// helpers
import { DIALOG_BOX_TYPE } from 'common/constants';

export const DangerousDataSubmitionWarningDialogBox = ({
  isVisible,
  toggle,
  onConfirm,
}) => {
  const tr = useTranslate().use().global;

  const confirmationButtons = [
    {
      id: 'cancel',
      title: tr['cancel'],
      message: tr['confirmation-of-dangerous-data-submition-for-employee'],
      action: toggle,
      inverted: true,
    },
    {
      id: 'confirm',
      title: tr['save'],
      action: onConfirm,
    },
  ];

  return (
    <Overlay isVisible={isVisible} hide={toggle}>
      <div className="modal-wrapper">
        <DialogBox
          close={toggle}
          headerText={tr['warning']}
          buttons={confirmationButtons}
          type={DIALOG_BOX_TYPE.CONFIRMATION}
        >
          <>{confirmationButtons[0]?.message}</>
        </DialogBox>
      </div>
    </Overlay>
  );
};
