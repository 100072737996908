import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const CustomSelect = styled.div`
  .label {
    color: ${colors.searchInpuyPlaceholder};
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 500;
  }

  .container {
    min-height: 35px;
    /* height: ${(props) => props.animation && '35px'}; */
    background-color: ${(props) =>
      props.darkTheme ? colors.bg_grey_1 : 'white'};
    border-radius: 5px;
    margin-top: 2px;

    padding: ${(props) => props.paddingSize === 'uneven' && '5px 10px'};
    padding: ${(props) => props.paddingSize === 'even' && '10px'};
    padding: ${(props) => props.paddingSize === 'SmEven' && '5px'};

    display: flex;
    justify-content: space-between;

    position: relative;

    /* align-items: ${(props) =>
      props.openInput ? 'flex-start' : 'center'}; */
    align-items: flex-start;

    /* height: ${(props) => props.openInput && 276}px; */
    transition-duration: 500ms;

    /* overflow: hidden; */
  }

  .icon {
    position: absolute;
    right: 8px;
  }

  /* .date_select_openCalendar > div {
    width: 100%;
    top: 35px;
    left: 0;
  } */
`;
