const quantityOfSpecialistsReducer = (state = { quantity: 3 }, action) => {
  switch (action.type) {
    case 'QUANTITY_OF_SPECIALISTS_UPDATE':
      return { quantity: action.payload };
    default:
      return state;
  }
};

export default quantityOfSpecialistsReducer;
