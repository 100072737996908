import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 300px;

  @media screen and (max-width: 960px) {
    background-color: ${colors.text_Primary};
    border-radius: 10px;
    min-height: calc(100svh - 100px);
    padding: 40px;
    width: calc(100vw - 80px);
    z-index: 1;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-family: ${fontFamilies.family_Primary};
    font-size: 1.25rem;
    font-weight: 900;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    overflow: visible;
  }

  button {
    bottom: 0;
    flex-shrink: 0;
    margin-top: auto;
  }
`;
