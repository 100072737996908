import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './Services.styled';

// icons
import { ArrowSVG } from 'icons/dynamic';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { ServiceCategories } from './ServiceCategories/ServiceCategories';
import { ServiceGroup } from './ServiceGroup/ServiceGroup';
import { CategoryTree } from './ServiceTree/CategoryTree';
import { UnsavedChangesDialogBox } from 'components/UnsavedChangesDialogBox/UnsavedChangesDialogBox';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { useServiceCache } from 'hooks/employee-services/useServiceCache';
import { useChangesStatus } from 'hooks/useChangesStatus';
import { useOverflowShading } from 'hooks/useOverflowShading';
import { useEmployeesModifyServices } from 'services/employeeService';
import {
  useServicesGetCategories,
  useServicesGetGroupById,
} from 'services/serviceService';
import { getParent } from 'common/helpers/serviceFunctions';

export const Services = ({ disabled, title }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const navigate = useNavigate();
  const tr = useTranslate().use().global;
  const { employeeId } = useParams();

  // get search params
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId'); // current sector tab

  const serviceActivity = true;

  // category data fetch (GET) context
  const { data: dbData, isFetched: isDbFetched } = useServicesGetCategories({
    searchParams: { serviceActivity, employeeId, sectorId },
    queryParams: {
      retry: false,
      // staleTime: 1000 * 60 * 60,
    },
  });

  const {
    cache,
    liveArray: categories,
    setDbArray: setCategories,
    activeGroup,
    setActiveGroup,
    getDbItem,
    getCachedItem,
    getItem,
    toggleActivity,
    hasValueChanged,
    hasAnyChanges,
    updateCache,
    hydrateDbGroup,
    clearCache,
    isDisabledByParent,
    updatePropDownstream,
  } = useServiceCache(dbData);

  useEffect(() => {
    isDbFetched && setCategories(dbData);
  }, [isDbFetched, dbData, setCategories]);

  // group data fetch (GET) context
  const { data: dbGroupData, isSuccess } = useServicesGetGroupById({
    id: activeGroup?.id,
    searchParams: { serviceActivity, sectorId, employeeId },
    queryParams: {
      enabled: !!activeGroup,
      retry: false,
      // staleTime: 1000 * 60 * 60,
    },
  });

  useEffect(() => {
    if (isSuccess) hydrateDbGroup(dbGroupData);
  }, [isSuccess, dbGroupData, hydrateDbGroup]);

  const invalidationList = useMemo(() => {
    const getGroupIds = (objectType) =>
      cache[objectType]?.reduce((acc, item) => {
        const serviceItem = getItem(objectType, item.id);

        let groupItem = getParent(serviceItem, categories);
        if (groupItem?.type === 'subgroup') {
          groupItem = getParent(groupItem, categories);
        }

        return [...new Set([...acc, groupItem?.id])];
      }, []);

    const groupIdsServices = getGroupIds('service');
    const groupIdsSets = getGroupIds('set');

    return Array.prototype
      .concat(groupIdsServices, groupIdsSets)
      .map((groupId) => ['groups', { id: groupId }]);
  }, [cache, getItem, categories]);

  // data update (POST) context
  const { mutate: saveChanges } = useEmployeesModifyServices({
    id: employeeId,
    queryParams: { invalidate: invalidationList },
  });

  const cancelChanges = useCallback(() => {
    console.log('undo changes');
    clearCache();
  }, [clearCache]);

  const exportObject = useCallback(() => {
    console.log('save changes start', cache);

    const modifiedObject = {
      sectorId,
      categoriesToAdd: [],
      categoriesToRemove: [],
      groupsSubgroupsToAdd: [],
      groupsSubgroupsToRemove: [],
    };

    if (cache?.category?.length) {
      cache.category.forEach((category) => {
        if (category.isEmpAssigned) {
          modifiedObject.categoriesToAdd.push(category.id);
        } else {
          modifiedObject.categoriesToRemove.push(category.id);
        }
      });
    }

    if (cache?.group?.length) {
      cache.group.forEach((group) => {
        if (group.isEmpAssigned) {
          modifiedObject.groupsSubgroupsToAdd.push(group.id);
        } else {
          modifiedObject.groupsSubgroupsToRemove.push(group.id);
        }
      });
    }

    if (cache?.subgroup?.length) {
      cache.subgroup.forEach((subgroup) => {
        if (subgroup.isEmpAssigned) {
          modifiedObject.groupsSubgroupsToAdd.push(subgroup.id);
        } else {
          modifiedObject.groupsSubgroupsToRemove.push(subgroup.id);
        }
      });
    }

    const dtoScope = [
      'id',
      'isEmpAssigned',
      'empDurationInMinutes',
      'empBreakInMinutes',
      'empUnitPriceWithVat',
    ];

    const filterByDto = (object) =>
      Object.keys(object)
        .filter((key) => dtoScope.includes(key))
        .reduce((obj, key) => {
          obj[key] = object[key];
          return obj;
        }, {});

    if (cache?.service?.length) {
      modifiedObject.services = cache.service.map((service) => {
        const processedService = getItem('service', service.id);
        return filterByDto(processedService);
      });
    }

    if (cache?.set?.length) {
      const mergedArray = Array.prototype.concat(
        modifiedObject?.services,
        cache.set.map((set) => {
          const processedSet = getItem('set', set.id);
          return filterByDto(processedSet);
        }),
      );

      modifiedObject.services = mergedArray;
    }

    modifiedObject.sectorId = Number(modifiedObject.sectorId);

    console.log({ modifiedObject });

    console.log({ dbData, activeGroup, cache, modifiedObject });

    console.log('save changes end');

    return modifiedObject;
  }, [cache, sectorId, dbData, activeGroup, getItem]);

  const confirmChanges = useCallback(() => {
    console.log('save changes start');

    saveChanges(exportObject(), {
      onSuccess: (response) => {
        console.log('onSUCCESS', { response });
        response.status === 207 && console.log('partial SUCCESS');
        clearCache({ refresh: true });
      },
      onError: (err) => {
        console.log('onERROR', { err });
      },
      onSettled: () => console.log('onSETTLED'),
    });

    console.log('save changes end');
  }, [saveChanges, exportObject, clearCache]);

  // |- BUTTONS ->

  // retrieve control button's handler
  const { setButtons, setIsGoBackEnabled } = useOutletContext();

  useChangesStatus({
    setButtons,
    hasAnyChanges,
    // hasAnyErrors,
    setIsGoBackEnabled,
    onReset: clearCache,
    onSubmit: confirmChanges,
  });

  // <- BUTTONS -|

  // |- SHADING ->

  // overflow shading for category tree view
  const { init: initTreeShading } = useOverflowShading();

  // overflow shading for group contents view
  const { init: initGroupShading } = useOverflowShading();

  // initialize shading and reflect to dynamic changes
  useEffect(() => {
    !isMobile &&
      initTreeShading &&
      initTreeShading('category-tree', { right: 40 });
  }, [initTreeShading, isMobile]);

  // initialize shading and reflect to dynamic changes
  useEffect(() => {
    !isMobile && initGroupShading && initGroupShading('group-contents');
  }, [initGroupShading, isMobile]);

  // <- SHADING -|

  // log
  // useEffect(() => console.log({ categories, cache }), [categories, cache]);

  return (
    <S.Services>
      {isMobile && (
        <S.Controls>
          <TheButton
            secondary
            inverted
            raised
            icon={<ArrowSVG />}
            action={() => navigate(-1)}
          />
          <div className="back-button-text">{title}</div>
        </S.Controls>
      )}

      {!isMobile ? (
        <>
          <S.Section id="category-tree">
            <ServiceCategories
              categories={categories}
              getItem={getItem}
              toggleActivity={toggleActivity}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
              isDisabledByParent={isDisabledByParent}
            />
          </S.Section>

          <S.Section id="group-contents">
            {activeGroup && (
              <ServiceGroup
                group={getItem('group', activeGroup.id, categories)}
                serviceActivity={serviceActivity}
                activeGroup={activeGroup}
                getDbItem={getDbItem}
                getCachedItem={getCachedItem}
                toggleActivity={toggleActivity}
                updateCache={updateCache}
                hydrateDbGroup={hydrateDbGroup}
                isDisabledByParent={isDisabledByParent}
                getItem={getItem}
                updatePropDownstream={updatePropDownstream}
                hasValueChanged={hasValueChanged}
              />
            )}
          </S.Section>
        </>
      ) : (
        <CategoryTree
          modal={false}
          db={categories}
          data={{ categories }}
          serviceActivity={serviceActivity}
          toggleActivity={toggleActivity}
          type={'category'}
          contentId="categories"
          cancelChanges={cancelChanges}
          confirmChanges={confirmChanges}
          getDbItem={getDbItem}
          getCachedItem={getCachedItem}
          getItem={getItem}
          hasAnyChanges={hasAnyChanges}
          hasValueChanged={hasValueChanged}
          updateCache={updateCache}
          setActiveGroup={setActiveGroup}
          hydrateDbGroup={hydrateDbGroup}
          isDisabledByParent={isDisabledByParent}
          updatePropDownstream={updatePropDownstream}
        />
      )}

      <UnsavedChangesDialogBox condition={hasAnyChanges()} />
    </S.Services>
  );
};

export default Services;
