import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

// style
import * as S from './Overlay.styled';

// hooks
import { usePreventBodyScrolling } from 'hooks/usePreventBodyScrolling';

export const Overlay = ({
  isVisible,
  hide,
  children,
  passThrough,
  htmlFor,
}) => {
  usePreventBodyScrolling(!!isVisible);

  const [dimensions, setDimensions] = useState();

  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    if (htmlFor) {
      const container = document.getElementById(htmlFor);
      isVisible && setDimensions(container?.getBoundingClientRect());
      setShow(isVisible);
    }
  }, [htmlFor, isVisible]);

  const nodeRef = useRef(null);

  return createPortal(
    <>
      {passThrough && (
        <S.PassThrough isVisible={isVisible}>{passThrough}</S.PassThrough>
      )}
      <CSSTransition
        in={htmlFor ? show : isVisible}
        timeout={300}
        classNames="modal"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <S.Overlay
          id="overlay"
          isVisible={isVisible}
          onClick={hide}
          {...(htmlFor ? { viewport: { dimensions } } : {})}
          ref={nodeRef}
        >
          {children}
        </S.Overlay>
      </CSSTransition>
    </>,
    document.getElementById('portal'),
  );
};

export default Overlay;
