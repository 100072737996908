import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="m 22.153635,4.77009 c -0.1953,0 -0.3846,0.02136 -0.56775,0.05493 V 3.73245 c 0,-1.660215 -1.3488,-3.009153 -3.00915,-3.009153 -0.47295,0 -0.91545,0.11292 -1.31535,0.308241 C 16.709085,0.4028505 15.900285,0 14.999985,0 c -1.46796,0 -2.691765,1.0559505 -2.95422,2.450655 -0.20142,-0.04272 -0.40896,-0.06408 -0.62259,-0.06408 -1.660215,0 -3.00915,1.348935 -3.00915,3.00915 V 9.81486 C 8.230905,9.77823 8.041695,9.759915 7.84638,9.759915 c -1.66023,0 -3.009165,1.348935 -3.009165,3.00915 v 3.195285 3.87285 c 0,5.60325 4.55952,10.1628 10.16277,10.1628 5.60325,0 10.1628,-4.55955 10.1628,-10.1628 V 7.776195 c 0,-1.66023 -1.34895,-3.00915 -3.00915,-3.00915 z m 0.5676,15.07026 c 0,4.2573 -3.4638,7.72125 -7.72125,7.72125 -4.257375,0 -7.721265,-3.46395 -7.721265,-7.72125 V 15.9705 12.772125 c 0,-0.31434 0.253305,-0.567645 0.56766,-0.567645 0.31434,0 0.567645,0.253305 0.567645,0.567645 v 4.916625 c 0,0.6744 0.546285,1.2207 1.22076,1.2207 1.91352,0 3.46998,1.5564 3.46998,3.46995 0,0.67455 0.546285,1.22085 1.22076,1.22085 0.67446,0 1.22076,-0.5463 1.22076,-1.22085 0,-2.8413 -2.01426,-5.22165 -4.6938,-5.78325 V 5.40183 c 0,-0.31434 0.253305,-0.567645 0.567645,-0.567645 0.31434,0 0.567645,0.253305 0.567645,0.567645 v 8.1882 c 0,0.67446 0.546285,1.220745 1.22076,1.220745 0.67446,0 1.220745,-0.546285 1.220745,-1.220745 V 3.018315 c 0,-0.314355 0.253305,-0.56766 0.56766,-0.56766 0.314295,0 0.567645,0.253305 0.567645,0.56766 V 11.9298 c 0,0.674475 0.5463,1.22076 1.2207,1.22076 0.67455,0 1.22085,-0.546285 1.22085,-1.22076 V 3.741615 c 0,-0.314355 0.2532,-0.56766 0.5676,-0.56766 0.3144,0 0.5676,0.253305 0.5676,0.56766 V 15.9735 c 0,0.67455 0.5463,1.22085 1.22085,1.22085 0.6744,0 1.2207,-0.5463 1.2207,-1.22085 V 7.78536 c 0,-0.314355 0.25335,-0.56766 0.5676,-0.56766 0.3144,0 0.56775,0.253305 0.56775,0.56766 v 12.06099 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  ) : (
    <path
      d="m 14.999978,30 c 5.489865,0 9.955514,-4.46565 9.955514,-9.9555 V 7.47456 c 0,-1.377225 -1.122749,-2.500005 -2.50005,-2.500005 -0.44535,0 -0.8652,0.11769 -1.22775,0.32442 v -2.04198 c 0,-1.377225 -1.12275,-2.4999975 -2.50005,-2.4999975 -0.582,0 -1.1163,0.200382 -1.5426,0.534351 C 16.758893,0.521628 15.938243,0 14.999978,0 13.622753,0 12.499973,1.122774 12.499973,2.500005 v 0.30852 C 12.137378,2.60178 11.717528,2.48409 11.272238,2.48409 c -1.3772255,0 -2.5000055,1.12278 -2.5000055,2.500005 v 5.5089 c -0.362595,-0.20673 -0.782445,-0.32442 -1.227735,-0.32442 -1.377225,0 -2.49999,1.12278 -2.49999,2.500005 v 3.33327 4.03635 c 0,5.48985 4.46565,9.9555 9.9554705,9.9555 z M 6.3167625,16.0083 v -3.33336 c 0,-0.67749 0.55026,-1.227735 1.227735,-1.227735 0.67749,0 1.227735,0.550245 1.227735,1.227735 V 17.799 c 0,0.3498 0.28626,0.63615 0.636135,0.63615 2.3441555,0 4.2525455,1.9083 4.2525455,4.2525 0,0.34995 0.28626,0.63615 0.636135,0.63615 0.349875,0 0.636134,-0.2862 0.636134,-0.63615 0,-2.8308 -2.140589,-5.16855 -4.89186,-5.4867 v -3.676785 -8.53689 c 0,-0.677475 0.55026,-1.227735 1.227735,-1.227735 0.677475,0 1.227735,0.55026 1.227735,1.227735 v 8.53371 c 0,0.349875 0.28626,0.636135 0.636135,0.636135 0.349875,0 0.636135,-0.28626 0.636135,-0.636135 v -2.48091 -8.53689 c 0,-0.67749 0.550245,-1.2277395 1.227735,-1.2277395 0.677445,0 1.227795,0.5502495 1.227795,1.2277395 v 9.287535 c 0,0.34986 0.2862,0.63612 0.636,0.63612 0.34995,0 0.63615,-0.28626 0.63615,-0.63612 V 3.256995 c 0,-0.677475 0.55035,-1.227735 1.22775,-1.227735 0.67755,0 1.22775,0.55026 1.22775,1.227735 V 16.00515 c 0,0.3498 0.28635,0.636 0.63615,0.636 0.3498,0 0.63615,-0.2862 0.63615,-0.636 V 7.47138 c 0,-0.67749 0.5502,-1.227735 1.22775,-1.227735 0.6774,0 1.22775,0.550245 1.22775,1.227735 v 12.56997 c 0,4.78695 -3.8964,8.6832 -8.683245,8.6832 -4.786905,0 -8.6832145,-3.89625 -8.6832145,-8.6832 v -4.0362 z"
      fill="currentcolor"
      strokeWidth="1.5"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
