import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

export const useScheduleSize = (
  ref,
  setHeight,
  setWidth,
  professionals,
  showPendingOrders,
) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  let timeout = useRef();

  const updateSizes = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (!ref.current) return;

      setHeight &&
        setHeight(
          ref.current.getBoundingClientRect().height - (isMobile ? 0 : 8),
        );

      const timeWrapper = document.querySelector('.time__cell-container');
      const timeWidth = timeWrapper?.getBoundingClientRect()?.width || 0;
      const pendingOrdersWrapper = document.querySelector(
        '.pending-orders-mobile',
      );
      const pendingOrdersWidth =
        pendingOrdersWrapper?.getBoundingClientRect()?.width || 0;

      const visibleProfessionalsQuantity = showPendingOrders
        ? 1
        : professionals;

      setWidth &&
        setWidth(
          (window.innerWidth - timeWidth - pendingOrdersWidth) /
            visibleProfessionalsQuantity,
        );
    }, 250);
  }, [professionals, isMobile, showPendingOrders]);

  useEffect(() => {
    updateSizes();
    window.addEventListener('resize', updateSizes);
    return () => {
      window.removeEventListener('resize', updateSizes);
    };
  }, [updateSizes]);
};

export default useScheduleSize;
