const compareProfessionalsReducer = (
  state = {
    compareProfessionals: [],
  },
  action,
) => {
  switch (action.type) {
    case 'COMPARE_PROFESSIONALS_UPDATE':
      return { compareProfessionals: action.payload };
    default:
      return state;
  }
};

export default compareProfessionalsReducer;
