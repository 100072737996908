import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// style
import * as S from './UserMenu.styled';

// icons
import { Ex } from 'icons/dynamic';

// components
import { Avatar } from 'components/Avatar/Avatar';
import { TheButton } from 'components/Buttons/TheButton';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';

export const UserMenu = ({ userName, userEmail, userImage, closeAction }) => {
  const { logoutUser } = useContext(UserContext);
  const APP_ROOT = process.env.REACT_APP_APP_ROOT;
  const tr = {
    global: useTranslate().use().global,
    main: useTranslate().use().main,
  };

  return (
    <S.UserMenu>
      <button className="close-button" onClick={closeAction}>
        <Ex black width={10} height={10} />
      </button>

      <div className="avatar">
        <Avatar imagePath={userImage} raised />
      </div>

      <div className="user-name">{userName}</div>

      <div className="user-email">{userEmail}</div>

      <NavLink to={APP_ROOT + 'dashboard/my-account'} onClick={closeAction}>
        {tr.main['my-account']}
      </NavLink>

      <TheButton
        title={tr.global['logout']}
        primary
        width="170"
        height="30"
        action={logoutUser}
      />
    </S.UserMenu>
  );
};

UserMenu.propTypes = {
  imagePath: PropTypes.string,
  closeAction: PropTypes.func.isRequired,
};

export default UserMenu;
