import { Controller, useFormContext } from 'react-hook-form';

// components
import { BaseInput } from 'components/Inputs';

export default ({ name, reference, label, ...other }) => {
  const { control, register, setFocus, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <BaseInput
          id={name}
          label={label}
          value={value}
          onChange={onChange}
          // onBlur={onBlur}
          errorMessage={error?.message}
          {...other}
        />
      )}
    />
  );
};
