import styled from 'styled-components/macro';

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  width: 100%;

  @media screen and (max-width: 960px) {
    height: calc(100vh - 54px);
  }

  > *:not(.footer) {
    flex: 1 0 auto;
  }
`;
