import React, { useContext } from 'react';

import * as S from './Header.styled';

// components
import { NavBarMobile } from './NavBarMobile/NavBarMobile';
import { NavBarDesktop } from './NavBarDesktop/NavBarDesktop';

// context

// hooks
import { useHideOnScroll } from 'hooks/useHideOnScroll.js';
import { MainContext } from 'pages/Main/Main';

export const Header = (props) => {
  const { shiftBy } = useHideOnScroll(); // hide on scrolldown function
  const { headerFixed } = useContext(MainContext);

  return (
    <S.Header
      id="header-nav"
      shiftBy={!headerFixed.position && shiftBy}
      searchMode={headerFixed.position}
    >
      <NavBarMobile {...props} />

      <NavBarDesktop />
    </S.Header>
  );
};

export default Header;
