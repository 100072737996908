import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';

// style
import * as S from './SideBarMenu.styled';

// components
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper';
import { SideBarFilter } from '../SideBarFilter/SideBarFilter';

// context
import { UserContext } from 'context/UserContext';
import { MainContext } from 'pages/Main/Main';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

export const SideBarMenu = forwardRef(
  ({ menu, level = 1, parentRoute, showMenu }, ref) => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const { userAcl, userType, companyId } = useContext(UserContext);

    const getIndex = (item, index) => `${item.id}-${index}`;

    const getRoute = useCallback(
      (currentRoute) => (!!parentRoute ? parentRoute + '/' : '') + currentRoute,
      [parentRoute],
    );

    const { setState } = useContext(MainContext);

    const isExpanded = (item) =>
      location.pathname.includes(getRoute(item.route)) || level < 2;

    const isVisible = useCallback(
      (item) => {
        const showOnMobile = (2 & item.scope) === 2;
        const showOnDesktop = (1 & item.scope) === 1;

        return (
          ((isMobile &&
            showOnMobile &&
            (companyId ||
              (!companyId && getRoute(item?.route).includes('dashboard')))) ||
            (!isMobile && showOnDesktop)) &&
          userAcl >= item.acl &&
          (userType & item.aclType) === userType
        );
      },
      [userAcl, userType, isMobile, companyId, getRoute],
    );

    const isCurrent = useCallback(
      (item) => {
        const response =
          location.pathname.includes(getRoute(item.route)) &&
          (!item.subItems ||
            item.subItems?.every((subItem) => !isVisible(subItem)));

        // response && setActiveMenuItem(item);
        // response && setTest(item);

        return response;
      },
      [getRoute, isVisible, location.pathname],
    );

    useEffect(() => {
      if (!menu) return;

      const found = menu.find(
        (item) =>
          location.pathname.includes(getRoute(item.route)) &&
          (!item.subItems ||
            item.subItems?.every((subItem) => !isVisible(subItem))),
      );

      found && setState({ type: 'menu-item', value: found });

      // found && console.log(found);
    }, [location, getRoute, isVisible, setState]);

    const handleMenu = (item) =>
      showMenu && (!item.filter || !!item.cascade) && showMenu(false);

    const isSimpleItem = (item) => typeof item.name === 'string';

    const { filterCriteria } = useContext(MainContext);

    return menu
      ? menu.map((item, index) => (
          <ConditionalWrapper
            key={getIndex(item, index)}
            condition={
              !parentRoute &&
              (companyId ||
                (!companyId && getRoute(item?.route).includes('dashboard')))
            }
            wrapper={(children) => (
              <S.MenuSection
                hasFilter={
                  filterCriteria &&
                  Object.values(filterCriteria).some(
                    (criteria) => !!criteria.value,
                  )
                }
              >
                {children}
              </S.MenuSection>
            )}
          >
            {isVisible(item) && (
              <Fragment key={getIndex(item, index)}>
                <S.SideBarItem
                  level={level}
                  to={{
                    pathname: item?.globalRoute
                      ? item.globalRoute
                      : getRoute(item.route),
                    search: location.search,
                  }}
                  className={isSimpleItem(item) && isCurrent(item) && 'current'}
                  onClick={() => handleMenu(item)}
                  {...(item.id === 'my-account' && { ref })}
                >
                  {item.icon}
                  {item.name}
                </S.SideBarItem>

                {item.filter && location.pathname.includes(item.route) && (
                  <SideBarFilter
                    fields={item.filter}
                    criterias={null} // TODO: filter criterias should be accesible from some central store -> for ClientTable and SideBarFilter
                    basicFeature={location.pathname.includes('private-clients')}
                  />
                )}

                {item.subItems && isExpanded(item) && (
                  <SideBarMenu
                    menu={item.subItems}
                    level={level + 1}
                    parentRoute={getRoute(item.route)}
                    showMenu={showMenu}
                  />
                )}
              </Fragment>
            )}
          </ConditionalWrapper>
        ))
      : null;
  },
);

SideBarMenu.displayName = 'SideBarMenu';

export default SideBarMenu;
