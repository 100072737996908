import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';
import { EmployeeGeneralInformation } from './partials/EmployeeGeneralInformation/EmployeeGeneralInformation.styled';
import { SectorStats } from './partials/EmployeeGeneralInformation/partials/SectorStats/SectorStats.styled';
import { Common } from './partials/WorkplaceSettings/Common/Common.styled';
import { fontFamilies } from 'common/fonts/fonts';

export const Employee = styled.div`
  @media screen and (max-width: 960px) {
    background-color: ${colors.bg_Secondary};
    width: 100vw;

    .back-button-text {
      font-family: ${fontFamilies.family_Primary};
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  margin: 40px 40px 0;

  @media screen and (max-width: 960px) {
    margin: 10px 10px 80px;
  }

  ${EmployeeGeneralInformation}, ${Common} {
    column-gap: 60px;
    display: flex;
    row-gap: 40px;

    @media screen and (max-width: 1300px) {
      flex-direction: column;
      /* gap: 20px; */

      ${SectorStats} {
        padding: 0 20px;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 960px) {
      row-gap: 10px;
    }

    form {
      display: flex;

      @media screen and (max-width: 960px) {
        flex-direction: column;
        /* margin-top: 65px; */
      }
    }

    ${SectorStats} {
      padding-inline: 0;

      @media screen and (max-width: 960px) {
        padding-inline: 40px;
      }

      @media screen and (max-width: 600px) {
        padding-inline: 20px;
      }
    }
  }
`;
