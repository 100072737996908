import styled from 'styled-components/macro';

export const Carousel3d = styled.div`
  .scene {
    height: calc(100svh - 310px);
    margin: 0 auto;
    perspective: 1000px;
    position: relative;
    width: 50vw;
  }

  .carousel {
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 500ms;
    width: 100%;
  }
  .carousel div {
    transform-style: preserve-3d;
  }

  .item {
    border-radius: 10px;
    display: flex;
    font-size: 5em;
    height: calc(100svh - 310px);
    justify-content: center;
    line-height: 360px;
    position: absolute;
    transition:
      transform 500ms,
      opacity 500ms ease-in-out;
    width: 50vw;
  }

  img {
    height: 100%;
    object-fit: contain;
  }

  .fadeIn {
    opacity: 1;
  }

  .fadeOut {
    opacity: 0.5;
  }

  .hide {
    opacity: 0;
  }

  .next,
  .prev {
    height: fit-content;
    transition:
      box-shadow 0.1s,
      top 0.1s;
    width: 50px;
  }
  .next:hover,
  .prev:hover {
    color: #000;
  }
  .next:active,
  .prev:active {
    box-shadow: 0 1px 0 #999;
  }
`;
