import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const Table = styled.div`
  position: relative;

  .avatar {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 960px) {
    margin-bottom: 1px;

    tr {
      vertical-align: middle;

      :first-of-type th {
        :first-of-type {
          border-top-left-radius: 5px;
        }

        :last-of-type {
          border-top-right-radius: 5px;
        }
      }

      :last-of-type td {
        :first-of-type {
          border-bottom-left-radius: 5px;
        }

        :last-of-type {
          border-bottom-right-radius: 5px;
        }
      }

      :hover td {
        background-color: ${colors.table_row_hover};
      }
    }

    th,
    td {
      font-size: 12px;

      :nth-child(${(props) => props.wideColumn}) {
        width: 100%;
      }
    }

    th {
      font-size: 12px;
      border-bottom: 1px solid;
      border-left: 1px solid;
      font-weight: 400;
      width: auto;

      :nth-of-type(1),
      :nth-of-type(2) {
        width: 2%;
        min-width: 38px;
      }
    }
  }
`;

export const TableScrollableContainer = styled.div`
  border: 0;
  min-height: 390px;
  overflow-x: auto;
  padding-bottom: 10px;
  position: relative;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const DataCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-inline: -10px;
`;
