import { useContext } from 'react';

// style
import * as S from './OrderItemsMobile.styled';

// icons
import { Check } from 'icons/dynamic';

// components
import DiscountModal from 'pages/Order/partials/OrderTable/partials/OrderRowEntries/OrderRowEntry/DiscountColumn/DiscountModal/DiscountModal';
import Overlay from 'components/Overlay/Overlay';
import SelectionPanel from 'components/Overlay/SelectionPanel/SelectionPanel';
import OrderCardMobile from './OrderCardMobile/OrderCardMobile';
import OrderDetailsFooter from './OrderDetailsFooter/OrderDetailsFooter';
import { EmployeesSelector } from '../EmployeesSelector/EmployeesSelector';

// contexts
import { OrderContext } from 'context/OrderContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import useModal from 'hooks/useModal';
import { useOrderFormStatus } from 'hooks/useOrderFormStatus';

const presetButtons = [
  {
    id: 'save',
    icon: <Check bold />,
    borderless: true,
    show: true,
  },
];

export const OrderItemsMobile = ({
  setButtons,
  setDisplayModal,
  modalToggle,
  modalIsShowing,
}) => {
  // Hooks
  const tr = useTranslate().use().global;
  const { isShowing, toggle } = useModal();

  // --- global
  const {
    client,
    order,
    deleteService,
    footerData,
    saveDiscount,
    professional,
    wantedEmployees,
    toggleWantedEmployeesSelection,
  } = useContext(OrderContext);
  useOrderFormStatus({ setButtons });

  return (
    <>
      <S.CreateNewOrderContainer>
        <div className="content_wrapper">
          <EmployeesSelector
            employee={professional}
            wantedEmployees={wantedEmployees}
            toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
          />
          {/* <ClientInfoMobile client={client} /> */}
          <>
            <OrderCardMobile
              order={order.state}
              deleteService={deleteService}
              setDisplayModal={setDisplayModal}
              modalToggle={modalToggle}
              modalIsShowing={modalIsShowing}
            />
          </>
        </div>
        {!!order.state.savedProducts?.length && (
          <OrderDetailsFooter footerData={footerData} setModal={{ toggle }} />
        )}
      </S.CreateNewOrderContainer>
      <Overlay isVisible={isShowing} hide={toggle}>
        <SelectionPanel
          closeAction={() => toggle()}
          headerTitle={tr.discount}
          presetButtons={presetButtons}
        >
          <DiscountModal
            onSave={(value) => {
              saveDiscount(value);
              toggle();
            }}
            price={order.state.amountBeforeDiscountWithVat}
            discount={order.state.discountWithVat}
          />
        </SelectionPanel>
      </Overlay>
    </>
  );
};

export default OrderItemsMobile;
