// style
import * as S from './BaseRadioButton.styled';

// icons
import { CheckedRadioButton, Circle } from 'icons/dynamic';

export default ({ value, label, onClick, disabled, excludeLabel, ...rest }) => {
  const commonProps = {
    width: 16,
    height: 16,
    onClick: disabled ? null : onClick,
  };

  return (
    <S.BaseRadioButton
      disabled={disabled}
      excludeLabel={excludeLabel}
      {...rest}
    >
      {value ? (
        <CheckedRadioButton {...commonProps} />
      ) : (
        <Circle bold {...commonProps} />
      )}
      <label onClick={disabled || excludeLabel ? null : onClick}>{label}</label>
    </S.BaseRadioButton>
  );
};
