import { useEffect } from 'react';

export const usePreventBodyScrolling = (condition = true) => {
  const cleanUp = () => {
    document.body.removeAttribute('style');
    document.getElementById('root').removeAttribute('style');
  };

  useEffect(() => {
    if (condition) {
      document.body.style.overflow = 'hidden';
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      cleanUp();
    }

    return () => cleanUp();
  }, [condition]);
};
