import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { BaseInput } from 'components/Inputs/BaseInput/BaseInput.styled';
import { InputField } from 'components/InputField/InputField.styled';
import { shadow } from 'common/styles/shadows';

export const OrderRowEntry = styled.div`
  background-color: ${colors.bg_grey_1};
  height: 100%;
  // but not first
  padding-top: ${(props) => !props.compItem && '5px'};

  .content {
    height: 100%;
    min-height: 26px;
    padding: 6px 0;
    background-color: white;
    align-items: center;
    display: flex;
    justify-content: ${({ headerId }) =>
      headerId === 'delete' ? 'center' : 'unset'};
  }

  .title-column {
    height: 100%;
    /* padding: 0 5px; */
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    font-weight: 600;
    /* white-space: nowrap; */
    min-width: 250px;
    margin-left: -4px;

    &__logo {
      display: flex;
      margin-right: 10px;
    }

    &__title {
      margin-right: 10px;
    }
  }

  .compItem {
    font-weight: 400;
  }

  .select-column {
    height: 100%;

    display: flex;
    align-items: center;
    padding: 0 2px;

    ${InputField} {
      position: relative;
      min-width: 88px;
      filter: ${({ selectIsOpen }) =>
        selectIsOpen && `drop-shadow(${shadow.lg})`};
      z-index: ${({ selectIsOpen }) => selectIsOpen && 1};
      cursor: pointer;

      .select {
        &.warning {
          .select__control {
            border: 1px solid ${colors.warningText};
          }
        }
      }

      .select__placeholder {
        color: ${colors.filterGrey};
      }

      .select__value-container {
        padding: 0 0 0 5px;
        height: 28px;
        min-height: unset;
      }

      .select__control {
        cursor: pointer;
        border: 1px solid transparent;
        box-shadow: none;
        background-color: ${(props) =>
          props.bright ? 'white' : colors.bg_grey_1};
        height: 28px;

        .select__value-container {
          cursor: pointer;

          .select__input-container {
            cursor: pointer;

            input {
              cursor: pointer;
            }
          }
        }
      }

      .select__indicators {
        padding: 0 6px 0 2px;

        .select__indicator {
          cursor: pointer;
          height: 28px;
          width: 10px;
          padding: 0;

          svg {
            width: 10px;
          }
        }
      }

      .select__menu {
        background-color: ${colors.bg_grey_1};
        border: 0;

        .select__menu-list {
          padding: 0;

          .select__option {
            margin: 0;
            padding: 0 5px;
            height: 25px;
            margin-bottom: 5px;
            display: flex;
            align-items: 'center';
            border-radius: 0;
            align-items: center;

            &--is-focused {
              background-color: #fff;
              cursor: pointer;
            }

            &--is-selected {
              background-color: ${colors.bg_grey_2};
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .amount-column {
    height: 100%;
    padding: 0 2px;

    display: flex;
    align-items: center;
  }

  .price-column {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};
  }

  .discount-column {
  }

  .total-price-column {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};
    color: ${colors.bg_Primary};
    font-weight: 900;
  }

  .red input {
    color: ${colors.mandyRed};
  }

  .danger {
    color: ${colors.warningText};
  }

  ${BaseInput} {
    &.danger {
      .input-wrapper {
        border: 1px solid ${colors.warningText};
      }
    }

    .input-wrapper {
      border: 0;
      border-radius: 5px;
      background-color: ${colors.bg_grey_1};
      border: 1px solid ${colors.bg_grey_1};

      .clear-icon {
        display: none;
      }

      input {
        min-width: 100px;
        animation: unset;
        width: 100%;
        background-color: ${colors.bg_grey_1};
        border-radius: 5px;
        border: none;
        outline: none;
        min-height: unset;
        height: 28px;
        padding: 0 5px;
        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};
        color: ${colors.bg_Primary};
        -moz-appearance: textfield;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        :disabled {
          background-color: unset;
        }

        &.danger {
          color: ${colors.warningText};
        }
      }
    }

    &.vat .input-wrapper input {
      min-width: unset;
      width: 60px;
    }
  }
`;

// export const CustomSelectStyles = {
//   menu: {
//     border: 0,
//     boxShadow: shadow.lg + ' !important',
//     backgroundColor: colors.bg_grey_1,
//     '.select__menu-list': {
//       padding: 0,
//       '.select__option': {
//         margin: 0,
//         padding: '0 5px',
//         height: 25,
//         marginBottom: 5,
//         display: 'flex',
//         alignItems: 'center',
//         borderRadius: 0,
//         '&--is-focused': {
//           backgroundColor: '#fff',
//           cursor: 'pointer',
//         },
//         '&--is-selected': {
//           backgroundColor: colors.bg_grey_2,
//           cursor: 'pointer',
//         },
//       },
//     },
//   },
// };
