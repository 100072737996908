import { useState, useEffect, useCallback } from 'react';

// style
import * as S from './SectorSelector.styled';

// components
import { SectorSelectorItem } from './SectorSelectorItem/SectorSelectorItem';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useSectorsGetAll } from 'services/sectorService';

export const SectorSelector = ({
  sectorDb,
  data,
  ancestors,
  disabled,
  activeItem,
  isParentNone,
}) => {
  const tr = useTranslate().use().global;

  const isService = ['service', 'set'].includes(activeItem?.type);

  const [sectors, setSectors] = useState([]);

  // get sector data from B/E
  const { data: db, isSuccess } = useSectorsGetAll({
    queryParams: { enabled: !sectorDb },
  });

  if (data?.value === null) data.value = { value: [], label: [] };

  // identifiers
  const isDisabledByParent = useCallback(
    (sector) =>
      ancestors?.length > 0 &&
      !ancestors.every((ancestor) =>
        ancestor.assignedToSectorIds?.includes(
          sector?.id || sector?.sectorId || sector,
        ),
      ),
    //  || (isService &&
    //       activeItem.id !== 'new' &&
    //       !sector.isSectorAssignedToParents)
    [ancestors],
  );

  const hasValues = data?.value?.value?.some(
    (item) =>
      (isService ? !!item.isAssigned : !!item) && !isDisabledByParent(item),
  );

  const formatSectorData = useCallback(
    (dbData) => {
      const filteredSectors = dbData.reduce((acc, item) => {
        // filter by activity
        if (item.isActive) return [...acc, item];
        else return acc;
      }, []);

      filteredSectors.sort((a, b) => {
        if (isDisabledByParent(a) === true && isDisabledByParent(b) === false) {
          return 1;
        } else if (
          isDisabledByParent(a) === false &&
          isDisabledByParent(b) === true
        ) {
          return -1;
        } else {
          return 0;
        }
      });

      setSectors(
        filteredSectors?.map((sector) => {
          const correspondingPropItem = [...(data?.value?.value || [])].find(
            (item) =>
              (isService ? item.sectorId : item) ===
              (sector?.id ?? sector?.params?.sectorId),
          );

          return {
            ...sector,
            ...(isService
              ? correspondingPropItem
              : correspondingPropItem && { isAssigned: true }),
          };
        }),
      );
    },
    [data?.value?.value, isDisabledByParent, isService],
  );

  // set sectors according to B/E (hydrate)
  useEffect(() => {
    if (isSuccess) {
      formatSectorData(db);
    }
  }, [db, isSuccess, formatSectorData]);

  useEffect(() => {
    if (sectorDb && !isSuccess) {
      formatSectorData(sectorDb);
    }
  }, [formatSectorData, isSuccess, sectorDb]);

  const updateSectorProp = (sectorId, propId, value) => {
    // let newItem = [...data?.value?.value];
    let newItem = structuredClone([...(data?.value?.value || [])]);

    let requiredItem = newItem?.find(
      (sector) => (isService ? sector.sectorId : sector) === sectorId,
    );

    if (isService) {
      if (!requiredItem) {
        newItem.push({ id: sectorId, [propId]: value });
      } else {
        requiredItem[propId] = value;
      }
    } else {
      if (newItem.includes(sectorId)) {
        newItem = newItem.filter((sector) => sector !== sectorId);
      } else {
        newItem.push(sectorId);
      }
    }

    data.setValue(isService ? 'sectors' : 'assignedToSectorIds', {
      value: newItem,
      label: newItem,
    });
  };

  // useEffect(() => {
  //   sectors && console.log({ sectors, ancestors });
  // }, [sectors, ancestors]);
  // useEffect(() => console.log({ data, hasValues }), [data, hasValues]);

  return (
    <S.SectorSelector>
      <label className="title">{tr['provided-at-sectors']}</label>

      <ul>
        {hasValues || !disabled || isParentNone ? (
          sectors?.map(
            (sector, index) =>
              (!disabled || (disabled && sector.isAssigned)) && (
                <SectorSelectorItem
                  key={index}
                  data={sector}
                  updateSectorProp={updateSectorProp}
                  disabled={disabled}
                  isService={isService}
                  disabledByParent={isDisabledByParent(sector)}
                  isParentNone={isParentNone}
                />
              ),
          )
        ) : (
          <li>-</li>
        )}
      </ul>
    </S.SectorSelector>
  );
};

export default SectorSelector;
