import styled from 'styled-components/macro';

import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';

export const SimpleSelectContainer = styled.div`
  color: ${(props) => (props.selected ? colors.bg_Primary : colors.filterGrey)};
  font-size: 16px;
  width: 100%;
  font-family: ${fontFamilies.family_Secondary};
`;
