import { useCallback, useContext } from 'react';

// style
import * as S from './SectorStats.styled';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { DateTime, Interval } from 'luxon';

const now = DateTime.now().startOf('day');

export const SectorStats = ({ data }) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);

  const getDateClassName = useCallback((scheduleTillDay) => {
    let className = '';

    if (!scheduleTillDay) {
      className += 'red';
    } else {
      const date = DateTime.fromISO(scheduleTillDay);
      const interval = Interval.fromDateTimes(now, now.plus({ days: 8 }));

      if (date <= now) {
        className += 'red';
      } else if (interval.contains(date)) {
        className += 'yellow';
      }
    }

    return className;
  }, []);

  return (
    <S.SectorStats>
      {data?.map((sector) => (
        <div key={sector?.id} className="sector">
          <b>{sector.name}</b>
          <ul>
            <li>
              {tr['work-schedule-until']}:{' '}
              <b className={getDateClassName(sector.scheduleTillDay)}>
                {sector.scheduleTillDay
                  ? new Date(sector.scheduleTillDay).toLocaleDateString(locale)
                  : `-`}
              </b>
            </li>
            <li>
              {tr['services-assigned']}:{' '}
              <b className={sector.serviceCount <= 0 ? 'red' : ''}>
                {sector.serviceCount}
              </b>
            </li>
          </ul>
        </div>
      ))}
    </S.SectorStats>
  );
};
