import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { RootContainer, ScrollableContainer } from 'pages/Pages.styled';

export { RootContainer, ScrollableContainer };

export const MySettings = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  width: 100%;

  .control button {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 60px;
  /* height: calc(100vh - 186.5px); */
  overflow: auto;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 20px;
    height: revert;
    padding-bottom: 60px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AvatarPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
    flex-direction: unset;
    justify-content: space-between;
    margin-inline: 10px;
  }

  .avatar-picker__image {
    flex-shrink: 0;
    height: 120px;
    margin: 0 auto 20px;
    width: 120px;

    @media screen and (max-width: 960px) {
      height: 70px;
      margin: 0;
      width: 70px;
      /* margin-bottom: unset; */
    }
  }

  .avatar-picker__button {
    @media screen and (max-width: 960px) {
      width: fit-content;
    }
  }
`;

export const DetailedInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    height: fit-content;
    background-color: ${(props) =>
      props.disabled ? 'transparent' : colors.bg_grey_1};
    border: 1px solid
      ${(props) => (props.disabled ? colors.bg_grey_2 : 'transparent')};
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px;
    width: 670px;

    @media screen and (max-width: 960px) {
      border: none;
      padding: 10px;
      width: unset;
    }

    > div {
      width: calc(50% - 10px);

      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: -webkit-link;
    text-decoration: underline;
    padding: 0;
    width: fit-content;
    /* margin: 0; */

    @media screen and (max-width: 960px) {
      margin-left: 10px;
    }
  }
`;
