import { useState } from 'react';

export const useModal = (initialShow) => {
  const [isShowing, setIsShowing] = useState(initialShow);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return {
    isShowing,
    toggle,
  };
};

export default useModal;
