import { Fragment, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

// style
import * as S from './ScheduleTemplateCreationDialog.styled';

// icons
import { TrashCan, Plus, Minus } from 'icons/dynamic';

// components
import { DialSelector } from 'components/DialSelector/DialSelector';
import { ScheduleDayCard } from '../ScheduleDayCard/ScheduleDayCard';
import { TheButton } from 'components/Buttons/TheButton';
import { BaseCheckbox, BaseRadioButton } from 'components/Inputs';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useFormState } from 'hooks/useFormState';
import { useScheduleHelpers } from 'hooks/employee-schedule/useScheduleHelpers';

export const ScheduleTemplateCreationDialog = (props) => {
  const {
    type,
    fields,
    setParentValue,
    buttons,
    // hasChanged, TODO: implement if necessary
    closeIfConfirmed,
    sectorId,
  } = props;

  const tr = useTranslate().use().global;

  const { getWeekDaysArray } = useScheduleHelpers();

  // create saparate local template state
  const { state: templateState, setValue: setTemplateState } = useFormState(
    fields.filter((field) => field.id === 'schedule-template'),
  );

  // create local form state of remaining fields
  const { options, state, setValue } = useFormState(
    fields.filter((field) => field.id !== 'schedule-template'),
  );

  // in case the template is in edit mode
  const templateDefault = templateState['schedule-template'].value;

  // set function to DialogBox's confirmation button
  if (buttons) {
    buttons[0].action = () => {
      const errors = hasTemplateAnyErrors();

      if (!errors) {
        setParentValue('repetition', null);

        Object.entries(state).forEach((entry) => {
          setParentValue(entry[0], entry[1].value);
        });
        setParentValue(
          'schedule-template',
          templateState['schedule-template'].value,
        );

        if (state['repetition-daily']) {
          setParentValue('repetition-weekly', undefined);
        } else if (state['repetition-weekly']) {
          setParentValue('repetition-daily', undefined);
        } else {
          setParentValue('repetition-weekly', undefined);
          setParentValue('repetition-daily', undefined);
        }

        closeIfConfirmed();
      } else {
        toast.error(tr[errors[0].error]);
      }
    };
  }

  const [templateLength, setTemplateLength] = useState(
    templateDefault ? templateDefault.length : type === 'weekly' ? 7 : 1,
  );

  const increment = type === 'weekly' ? 7 : type === 'continuous' ? 1 : 0;

  const [templateColumns, setTemplateColumns] = useState(
    templateDefault
      ? (templateDefault.length < 7 ? templateDefault.length : 7) *
          (type === 'continuous' ? 2 : 1)
      : type === 'weekly'
        ? 7
        : 1 + increment,
  );

  const addElements = (index) => {
    const startIndex = index + 1;

    setTemplateLength((old) => (old += increment));

    let newArrayStart = [
      ...templateState['schedule-template'].value.slice(0, startIndex),
    ];

    let newArrayEnd = [
      ...templateState['schedule-template'].value.slice(startIndex),
    ];

    for (
      var templateAddOn = [], i = startIndex;
      i < startIndex + increment;
      i++
    ) {
      templateAddOn.push({
        hours: [],
        office: state['office-number'].value,
      });
    }

    setTemplateState('schedule-template', [
      ...newArrayStart,
      ...templateAddOn,
      ...newArrayEnd,
    ]);

    type === 'continuous' &&
      templateLength < 7 &&
      setTemplateColumns((old) => (old += increment * 2));
  };

  const removeElements = (index) => {
    if (templateLength > 1) {
      const startIndex = index - increment + 1;

      setTemplateLength((old) => (old -= increment));

      let newArrayStart = [
        ...templateState['schedule-template'].value.slice(0, startIndex),
      ];

      let newArrayEnd = [
        ...templateState['schedule-template'].value.slice(
          startIndex + increment,
          templateState['schedule-template'].value.length,
        ),
      ];

      setTemplateState('schedule-template', [...newArrayStart, ...newArrayEnd]);

      type === 'continuous' &&
        templateLength <= 7 &&
        setTemplateColumns((old) => (old -= increment * 2));
    }
  };

  const resetTemplate = (action) => {
    const anyOffice = { value: undefined, label: '-' };
    setValue('office-number', anyOffice);
    setTemplateState(
      'schedule-template',
      new Array(templateLength).fill().map((_, index) => ({
        hours0: undefined,
        hours1: undefined,
        hours2: undefined,
        hours3: undefined,
        office: anyOffice,
      })),
    );
  };

  const updateTemplate = useCallback((fieldId, fieldValue, fieldIndex) => {
    let templateValue = templateState['schedule-template'].value;

    if (templateValue) {
      setTemplateState(
        'schedule-template',
        templateValue.reduce((acc, day, index) => {
          return [
            ...acc,
            {
              ...day,
              ...((fieldIndex === undefined || index === fieldIndex) && {
                // if no fieldIndex, fill all fields
                [fieldId]: fieldValue,
              }),
            },
          ];
        }, []),
      );
    }
  }, []);

  // update ofice number
  useEffect(() => {
    updateTemplate('office', state['office-number'].value);
  }, [state['office-number'].value, updateTemplate]);

  // fill template with random values upon Dialog start (template creation mode)
  useEffect(() => {
    !fields.find((field) => field.id === 'schedule-template')?.default &&
      resetTemplate();
  }, [fields]);

  const repetition = 'repetition-' + type;

  const applications = ['apply-workdays', 'apply-weekends', 'apply-holidays'];

  const invert = (id) =>
    setValue(
      id,
      options[id]?.find((option) => option.value === !state[id]?.value?.value),
    );

  /**
   * Function to check for errors in a template array based on index and time.
   * @param {number} templateIndex - The index of the object in the array to check.
   * @param {number} timeIndex - The index of the hour value to validate.
   * @param {Array} templateArray - The array of template objects, where each object contains hours.
   * @returns {number} - Returns 1 if the corresponding value is missing, 2 if the order is incorrect, otherwise returns 0.
   */
  const hasTemplateItemErrors = (templateIndex, timeIndex) => {
    const templateArray = templateState['schedule-template']?.value?.map(
      (day) => ({
        hours: { 0: day.hours0, 1: day.hours1, 2: day.hours2, 3: day.hours3 },
        office: day.office,
      }),
    );

    if (templateIndex < 0 || templateIndex >= templateArray.length) {
      throw new Error('Invalid templateIndex');
    }

    const entry = templateArray[templateIndex];
    if (!entry || !entry.hours) {
      return 0; // No entry or hours found, no errors can be determined.
    }

    const hours = entry.hours || {};
    const isEvenIndex = timeIndex % 2 === 0;

    // Determine the corresponding index to check against
    const correspondingIndex = isEvenIndex ? timeIndex + 1 : timeIndex - 1;

    // 1. Check if the current index exists and the corresponding value is missing (either way)
    if (
      (hours.hasOwnProperty(timeIndex) &&
        !hours.hasOwnProperty(correspondingIndex)) ||
      (!hours.hasOwnProperty(timeIndex) &&
        hours.hasOwnProperty(correspondingIndex)) ||
      (hours[timeIndex] && !hours[correspondingIndex]) ||
      (!hours[timeIndex] && hours[correspondingIndex])
    ) {
      return 1; // One of the corresponding values does not exist
    }

    // 2. Check the order of values
    const currentValue = hours[timeIndex];
    const correspondingValue = hours[correspondingIndex];

    if (currentValue !== undefined && correspondingValue !== undefined) {
      if (isEvenIndex && currentValue > correspondingValue) {
        return 2; // Error: current value is greater than the corresponding value
      } else if (!isEvenIndex && currentValue < correspondingValue) {
        return 2; // Error: current value is less than the corresponding value
      }
    }

    return 0; // No errors found
  };

  /**
   * Function to check if template has any errors in any hour field.
   * @returns {Array<{ date: Date, error: string }> | undefined} -
   * An array of objects with 'date' and 'error' keys, or undefined if no errors found.
   */
  const hasTemplateAnyErrors = () => {
    const templateArray = templateState['schedule-template']?.value?.map(
      (day) => ({
        hours: { 0: day.hours0, 1: day.hours1, 2: day.hours2, 3: day.hours3 },
        office: day.office,
      }),
    );
    const errorEntries = [];

    templateArray.forEach((entry, templateIndex) => {
      if (entry && entry.hours) {
        for (let timeIndex = 0; timeIndex < 4; timeIndex++) {
          // Assuming we only check indices 0-3, as in the original function
          const errorType = hasTemplateItemErrors(
            templateIndex,
            timeIndex,
            templateArray,
          );

          if (errorType === 1) {
            errorEntries.push({ templateIndex, error: 'missing-value' });
            break; // Break out of the loop once an error is found for this templateIndex
          } else if (errorType === 2) {
            errorEntries.push({
              templateIndex,
              error: 'time-sequence-mismatch',
            });
            break; // Break out of the loop once an error is found for this templateIndex
          }
        }
      }
    });

    return errorEntries.length > 0 ? errorEntries : undefined;
  };

  return (
    <S.ScheduleTemplateCreationDialog>
      <div>{tr[`repeat-${type}-schedule`]}</div>

      <S.TemplateSettingsSection
        type={type}
        columns={templateColumns}
        templateLength={templateLength}
      >
        {/* <div>
          <label>{tr['office-number']}</label>

          <DialSelector
            id={'office-number'}
            state={{
              value: state['office-number'].value,
              setValue: (value) =>
                state['office-number'].setValue('office-number', value),
            }}
            options={options['office-number']}
          />
        </div> */}

        <div>
          <div>
            <label>
              {tr['work-schedule-setup']}

              <TheButton
                title={tr['clear']}
                primary
                outline
                height="25"
                icon={<TrashCan width={16} height={16} />}
                action={resetTemplate}
              />
            </label>
          </div>

          <div className="template">
            {type === 'weekly' &&
              getWeekDaysArray({ sectorId }).map((weekday, index) => (
                <h3 key={index}>{weekday}</h3>
              ))}
            {templateState['schedule-template']?.value?.map((day, index) => {
              return (
                <Fragment key={index}>
                  <ScheduleDayCard
                    isTemplate
                    date={{
                      index,
                      hours: {
                        0: day.hours0,
                        1: day.hours1,
                        2: day.hours2,
                        3: day.hours3,
                      },
                      ofice: day.office,
                    }}
                    changeHandler={(_, value, valIndex) => {
                      const newTemplate = [
                        ...templateState['schedule-template'].value,
                      ];
                      newTemplate[index] = {
                        ...day,
                        [`hours${valIndex}`]: value,
                      };
                      setTemplateState('schedule-template', newTemplate);
                    }}
                    hasHourChanged={() => false}
                    hasDateErrors={hasTemplateItemErrors}
                  />

                  {((type === 'weekly' && (index + 1) % 7 === 0) ||
                    type === 'continuous') && (
                    <div className="template__controls">
                      <div onClick={() => addElements(index)}>
                        <Plus bold width={16} height={16} inCircle add />
                      </div>
                      <div onClick={() => removeElements(index)}>
                        <Minus bold width={16} height={16} inCircle add />
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </S.TemplateSettingsSection>

      <S.RepetitionSection>
        {type !== 'continuous' && (
          <fieldset>
            <legend>{tr['repetition']}</legend>

            {options[repetition]?.map((option, index) => {
              const isChecked =
                state[repetition]?.value?.value === option.value;

              return (
                <BaseRadioButton
                  key={index}
                  label={option.label}
                  value={isChecked}
                  onClick={() => setValue(repetition, option)}
                />
              );
            })}
          </fieldset>
        )}

        <fieldset>
          <legend>{tr['application']}</legend>

          {applications.map((application, index) => {
            const value = state[application]?.value?.value;

            return (
              <BaseCheckbox
                key={index}
                value={value}
                label={tr[application]}
                onClick={() => invert(application)}
              />
            );
          })}
        </fieldset>
      </S.RepetitionSection>
    </S.ScheduleTemplateCreationDialog>
  );
};

export default ScheduleTemplateCreationDialog;
