import styled from 'styled-components/macro';

export const RootContainer = styled.div`
  margin: 40px 40px 0;

  @media screen and (max-width: 960px) {
    /* margin: 10px 10px 80px; */
    margin: 10px;
  }

  @media screen and (max-width: 960px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

export const ScrollableContainer = styled.div`
  display: flex;
`;
