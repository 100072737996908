import { useContext, useState } from 'react';

import * as S from './UserWidget.styled';

import { Avatar } from 'components/Avatar/Avatar';
import { UserMenu } from 'components/UserMenu/UserMenu';

// hooks
import ClickAwayListener from 'react-click-away-listener';
import { useMediaQuery } from 'hooks/useMediaQuery';

// context
import { UserContext } from 'context/UserContext';

export const UserWidget = () => {
  const { user } = useContext(UserContext);

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => setMenuVisible((currentState) => !currentState);

  const closeMenu = () => setMenuVisible(false);

  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <S.UserWidget isMobile={isMobile}>
        <Avatar
          imagePath={user?.photoUrl}
          fullName={user?.fullName}
          onClick={toggleMenu}
          raised
        />

        {menuVisible && (
          <UserMenu
            userName={user?.fullName}
            userEmail={user?.email}
            userImage={user?.photoUrl}
            closeAction={closeMenu}
          />
        )}
      </S.UserWidget>
    </ClickAwayListener>
  );
};

export default UserWidget;
