// style
import * as S from './ContentLayout.styled';

// hooks
import { useSideBarStatus } from 'hooks/useSideBarStatus';
import { usePreventBodyScrolling } from 'hooks/usePreventBodyScrolling';

export const ContentLayout = ({ children, hideFab }) => {
  useSideBarStatus('hide');
  usePreventBodyScrolling();

  return <S.ContentLayout hideFab={hideFab}>{children}</S.ContentLayout>;
};

export default ContentLayout;
