import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { RootContainer, ScrollableContainer } from 'pages/Pages.styled';

export { RootContainer, ScrollableContainer };

export const Projects = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  width: 100%;

  .control button {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: calc(100vh - 186.5px); */
  overflow: auto;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 10px;
    height: revert;
    padding-bottom: 60px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CompanyCard = styled.div`
  border: 1px solid;
  border-color: ${({ error, current }) =>
    error
      ? colors.error
      : current
        ? colors.table_borders_Secondary
        : colors.filterGrey};
  border-radius: 5px;
  cursor: pointer;
  max-width: 500px;
  padding: 10px 20px;

  &:hover {
    border-color: ${({ error }) =>
      error ? colors['error--hover'] : colors.bg_grey_3};
  }

  /* span:first-child {
      height: 100%;
      width: 3px;
      background-color: ${colors.bg_Button_Primary};
      border-radius: 99px;
    } */

  .name {
    display: flex;
    justify-content: space-between;
  }

  .name span:first-child {
    font-weight: bold;
  }

  .name span:last-child {
    color: ${({ error }) => (error ? colors.error : colors.text_Secondary)};
  }

  .address,
  .message {
    color: ${colors.bg_grey_3};
    font-size: 0.875rem;
  }

  .message {
    color: ${colors.error};
    /* display: ${({ error }) => (error ? 'block' : 'none')}; */
  }
`;
