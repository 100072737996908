import { useState, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { DateTime } from 'luxon';

// style
import * as S from './TimeAndDateChild.styled';

// icons
import { Arrow, LetterIInCircle } from 'icons/dynamic';

// components
import SchedulerCalendar from '../../../../Calendar/SchedulerCalendar/SchedulerCalendar';
import InputFieldTime from 'components/InputFieldTime/InputFieldTime';

// contexts
import { UserContext } from 'context/UserContext';
import { OrderContext } from 'context/OrderContext';

// hooks
import useOrder from 'hooks/order/useOrder';
import { useAppointment } from 'hooks/useAppointment';
import useTranslate from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

const TimeAndDateChild = ({ darkTheme, arrow, disabled }) => {
  const tr = useTranslate().use().global;
  const { locale } = useContext(UserContext);
  const { order } = useContext(OrderContext);
  const { handleDateTimeInput } = useOrder({
    order: order.state,
    setOrder: order.setState,
  });
  const { getDateAndTime } = useAppointment();
  const [showCalendar, setShowCalendar] = useState(false);

  const { jsDate, startTimeString, endTimeString } = getDateAndTime(
    order?.state?.startTimeUtc,
    order?.state?.endTimeUtc,
    order?.state?.timezoneIanaId,
  );

  return (
    <S.TimeAndDateContainer
      darkTheme={darkTheme}
      disabled={disabled}
      openCalendor={showCalendar}
    >
      <div className="date-time-select-wrapper">
        <div className="date-wrapper">
          <div
            className="date-select"
            onClick={(e) => {
              if (!disabled) {
                setShowCalendar((prev) => !prev);
                if (
                  window.innerHeight -
                    e.target.getBoundingClientRect().top -
                    120 <
                  276
                ) {
                  setTimeout(() => {
                    window.scrollTo(0, window.window.innerHeight / 2 - 150);
                  }, 300);
                }
              }
            }}
          >
            {jsDate.toLocaleDateString(locale)}
          </div>
          {order.state.multipleDaysSpanWarning && (
            <span
              className="warning-icon"
              data-tooltip-id="tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                tr['order-cannot-extend-to-the-next-day'],
              )}
            >
              <LetterIInCircle width={16} height={16} color={colors.mandyRed} />
            </span>
          )}
        </div>

        <div className="time-select">
          <InputFieldTime
            className="time-input"
            value={startTimeString}
            onChange={(value) => handleDateTimeInput('startTime', value)}
          />

          <span className="long-dash">&#8212;</span>

          <InputFieldTime
            className="time-input"
            value={endTimeString}
            onChange={(value) => handleDateTimeInput('endTime', value)}
            disabled={!order.state.savedProducts.length}
          />

          {arrow && (
            <div className="time-picker-icon" onClick={arrow}>
              <Arrow black height={18} width={14} />
            </div>
          )}
        </div>
      </div>

      <div className="calendar-wrapper">
        <div className="date-select__open-calendar">
          <SchedulerCalendar
            selectDay={(date) => {
              handleDateTimeInput('date', DateTime.fromJSDate(date));
              setShowCalendar(false);
            }}
            selected={jsDate}
          />
        </div>
      </div>
    </S.TimeAndDateContainer>
  );
};

export default TimeAndDateChild;
