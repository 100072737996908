import { Controller, useFormContext } from 'react-hook-form';

// components
import { BaseSelect } from 'components/Inputs';

export default ({ name, type, reference, label, options, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <BaseSelect
          id={name}
          label={label}
          value={value}
          onChange={onChange}
          options={options}
          errorMessage={error?.message}
          {...other}
        />
      )}
    />
  );
};
