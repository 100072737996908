import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';

export const SearchWidget = styled.div`
  @media screen and (max-width: 960px) {
    align-items: center;
    background-color: white;
    box-shadow: ${shadow.search};
    display: flex;
    justify-content: space-between;
    height: 55px;
    left: 0;
    padding-inline: 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
    transition: top 0.2s ease-in-out;
  }

  .close-button {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5.5px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`;
