import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const BasicTableHead = styled.thead`
  height: 40px;

  tr th {
    min-width: 200px;

    text-align: left;
    position: sticky;
    top: 0;

    background-color: ${colors.bg_grey_1};
    padding-left: 45px;
    padding-bottom: 20px;
  }
`;
