import { StyledSVG } from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <StyledSVG
    width={width ? width : '15.977'}
    height={height ? height : '15.182'}
    viewBox="0 0 15.977 15.182"
    color={color}
    {...rest}
  >
    <g transform="translate(-292.892 -2428.641)">
      <path
        d="M309.952,2444.563h9.623a.339.339,0,1,0,0-.679h-9.623a.339.339,0,1,0,0,.679Z"
        transform="translate(-13.883 -12.656)"
      />
      <path
        d="M309.952,2459.63h9.623a.339.339,0,1,0,0-.679h-9.623a.339.339,0,1,0,0,.679Z"
        transform="translate(-13.883 -25.166)"
      />
      <path
        d="M309.952,2474.7h4.723a.339.339,0,0,0,0-.679h-4.723a.339.339,0,0,0,0,.679Z"
        transform="translate(-13.883 -37.676)"
      />
      <path
        d="M292.892,2428.641v15.182l3.9-3.516h12.08v-11.666Zm15.3,10.987H296.528l-2.957,2.668V2429.32H308.19Z"
        transform="translate(0 0)"
      />
    </g>
  </StyledSVG>
);
