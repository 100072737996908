import styled from 'styled-components/macro';

import { ControlsSection as MainControlsSection } from '../../Schedule.styled';

export const TemplateSection = styled.div`
  @media screen and (max-width: 960px) {
    padding: 0 10px 0;
  }

  h3 {
  }

  .template-type-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    button {
      display: inline-flex;
    }
  }

  .schedule-setup-details {
    font-size: 0.875rem;

    > {
      line-height: 1.3571;
    }

    label {
      display: block;
      margin-bottom: 5px;

      :not(:first-child) {
        margin-top: 15px;
      }
    }

    div {
      font-weight: bold;
    }

    .day {
      line-height: 20px;

      &__label {
        display: inline-block;
        font-weight: normal;
        width: 30px;
      }
    }
  }
`;

export const ControlsSection = styled(MainControlsSection)`
  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
    padding-inline: 0;
  }
`;
