import styled from 'styled-components/macro';

export const ContentLayout = styled.div`
  background-color: white;
  left: 0;
  min-height: 100dvh;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${(props) => (props.hideFab ? 7 : 5)};

  @media screen and (max-width: 960px) {
    z-index: ${({ hideFab }) => (hideFab ? 5 : 2)}; // TODO: remove if residual
  }
`;
