import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { FLASH_MESSAGE_TYPE } from 'common/constants';

export const FlashMessage = styled.div`
  height: 37px;
  width: 100%;
  background: ${({ type }) =>
    type === FLASH_MESSAGE_TYPE.WARNING ? colors.warning : colors.info};
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;

  svg {
    margin: 0 5px;
  }
`;
