import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const SectorSelector = styled.div`
  color: ${colors.bg_Primary};
  font-family: ${fontFamilies.family_Secondary};

  > label {
    color: ${colors.bg_grey_3};
    display: block;
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  ul {
    font-size: 16px;
    list-style-type: none;
    margin: 0;
    margin-top: 20px;
    padding: 0;
  }
`;
