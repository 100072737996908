import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors.js';
import { fontFamilies } from 'common/fonts/fonts.js';
import { BaseCheckbox } from 'components/Inputs/BaseCheckbox/BaseCheckbox.styled';

export const ColumnsVisibility = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  font-size: 14px;
  list-style: none;
  color: ${colors.text_Secondary};

  ${BaseCheckbox} {
    width: auto;
  }

  @media screen and (max-width: 960px) {
    background-color: ${colors.bg_grey_1};
    width: fit-content;
    border: 1px solid ${colors.table_borders_Secondary};
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    padding: 8px 10px;

    border-radius: 5px;

    :hover {
      background-color: ${colors.paid};
    }
  }
`;
