// style
import * as S from './BasicTableHead.styled';

export const BasicTableHead = ({ columns, hasNavigation }) => (
  <S.BasicTableHead>
    <tr>
      {hasNavigation && <th className="navigation-column"></th>}
      {columns.map((column, i) => (
        <th key={i}>{column.label}</th>
      ))}
    </tr>
  </S.BasicTableHead>
);
