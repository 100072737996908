import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { Avatar } from 'components/Avatar/Avatar.styled';
import { ProfessionalSelect } from 'components/DialogLayout/CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild.styled';
import StyledSVG from 'icons/SVG.styled';
import styled from 'styled-components/macro';

const overflowSroll = `overflow: auto;
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: white;
  border: 1px solid rgba(217, 217, 217, 0.3);
  border-radius: 10px;
  margin-top: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: ${colors.bg_Primary};
}`;

export const EmployeesSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .row {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .row-title {
      font-size: 16px;
      font-family: ${fontFamilies.family_Secondary};
      display: flex;

      ${StyledSVG} {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .row-content {
      max-height: 200px;
      ${overflowSroll}

      ::-webkit-scrollbar-track {
        margin-top: 0px;
        background-color: ${colors.bg_grey_1};
      }

      > div {
        box-shadow: none;

        .iconContainer {
          display: none;
        }
      }

      .single {
        border-radius: 100px !important;
      }

      ${ProfessionalSelect} {
        padding: 0 10px 0 0;
        height: 30px;
        flex-grow: 1;
        cursor: default;
        gap: 10px;

        .proffesionalSelect_img {
          height: 30px;
          width: 30px;
          min-width: 30px;
        }

        .proffesionalSelect_info {
          padding: 0;

          span {
            line-height: 14px;
            overflow: visible;
          }

          .proffesionalSelect_info_name {
            font-size: 14px;
            font-family: ${fontFamilies.family_Primary};
          }

          .proffesionalSelect_info_profession {
            font-size: 12px;
            font-family: ${fontFamilies.family_Secondary};
          }
        }
      }

      &.multiple {
        display: flex;
        gap: 6px;
        flex-flow: wrap;

        ${ProfessionalSelect} {
          width: min-content;
          .proffesionalSelect_info {
            min-width: unset;
            width: auto;
            flex-wrap: unset;
          }
        }
      }
    }
  }
`;

export const EmptyEmployee = styled.div`
  height: 30px;
  border-radius: 100px !important;
  background-color: ${colors.bg_grey_1};
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;

  > span {
    font-family: ${fontFamilies.family_Secondary};
    font-size: 12px;
    color: ${colors.bg_grey_3};
  }

  ${Avatar} {
    width: 20px;
    height: 20px;
  }
`;
