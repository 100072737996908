import * as S from '../SVG.styled';

export default ({ color, width, height, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 26.94315,9.33261 H 3.056865 C 1.3725285,9.33261 0,10.70514 0,12.389475 V 17.6106 c 0,1.68435 1.3725285,3.05685 3.056865,3.05685 H 26.94315 C 28.6275,20.66745 30,19.29495 30,17.6106 V 12.389475 C 30,10.70514 28.6275,9.33261 26.94315,9.33261 Z m 0.6114,8.27799 c 0,0.3363 -0.2751,0.6114 -0.6114,0.6114 H 3.056865 C 2.72061,18.222 2.44548,17.9469 2.44548,17.6106 v -5.221125 c 0,-0.336255 0.27513,-0.611385 0.611385,-0.611385 H 26.94315 c 0.3363,0 0.6114,0.27513 0.6114,0.611385 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 28.7772,22.9233 H 1.222743 C 0.5471775,22.9233 0,23.4705 0,24.1461 c 0,0.6756 0.5471775,1.2228 1.222743,1.2228 H 28.7772 c 0.6756,0 1.2228,-0.5472 1.2228,-1.2228 0,-0.6756 -0.5472,-1.2228 -1.2228,-1.2228 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 28.7772,4.6311 H 1.222743 C 0.5471775,4.6311 0,5.178285 0,5.85384 0,6.52941 0.5471775,7.076595 1.222743,7.076595 H 28.7772 C 29.4528,7.076595 30,6.52941 30,5.85384 30,5.178285 29.4528,4.6311 28.7772,4.6311 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <>
      <path
        d="M 27.4506,9.7290974 H 2.5494 C 1.144041,9.7290974 0,10.873147 0,12.278498 v 5.44293 c 0,1.405349 1.144041,2.5494 2.5494,2.5494 h 24.9012 c 1.405349,0 2.5494,-1.144051 2.5494,-2.5494 v -5.44293 C 30,10.873147 28.855949,9.7290974 27.4506,9.7290974 Z m 1.2747,7.9923306 c 0,0.704249 -0.570451,1.2747 -1.2747,1.2747 H 2.5494 c -0.70428,0 -1.274703,-0.570451 -1.274703,-1.2747 v -5.44293 c 0,-0.704265 0.570423,-1.274701 1.274703,-1.274701 h 24.9012 c 0.704249,0 1.2747,0.570435 1.2747,1.274701 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 29.362649,23.897377 H 0.63734849 C 0.28680749,23.897377 0,24.184177 0,24.534728 c 0,0.350549 0.28680749,0.637349 0.63734849,0.637349 H 29.362649 C 29.7132,25.172077 30,24.885277 30,24.534728 30,24.184177 29.7132,23.897377 29.362649,23.897377 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="M 0.63734849,6.1026225 H 29.362649 C 29.7132,6.1026225 30,5.8158074 30,5.4652725 c 0,-0.35055 -0.2868,-0.63735 -0.637351,-0.63735 H 0.63734849 C 0.28680749,4.8279225 0,5.1147225 0,5.4652725 c 0,0.3505349 0.28680749,0.63735 0.63734849,0.63735 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
