import styled from 'styled-components/macro';
import { Controls } from 'pages/Employee/partials/WorkplaceSettings/WorkplaceSettings.styled';
import { FormContainer } from 'components/Form/FormContainer.styled';

export { Controls };

export const Common = styled.div`
  ${FormContainer} {
    /* padding: 10px 10px 0; */
  }
`;
