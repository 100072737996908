export const animateClose = (elementClass) => {
  const portalEl = document.querySelector(`.${elementClass}`)?.parentElement;
  const containerEl = portalEl?.parentElement;

  const clonedPortalEl = portalEl?.cloneNode(true);
  const clonedMenuEl = clonedPortalEl?.childNodes[0];

  if (!clonedMenuEl) return;

  clonedMenuEl.classList.add(`${elementClass}--close`);
  clonedMenuEl.addEventListener('animationend', () => {
    containerEl?.removeChild(clonedPortalEl);
  });

  containerEl?.appendChild(clonedPortalEl);
};
