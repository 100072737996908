import * as S from '../SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 22.669966,2.7950905e-7 H 7.3300336 C 6.0957929,2.7950905e-7 5.0911242,1.0047439 5.0911242,2.2389098 V 27.76109 C 5.0911242,28.995331 6.0958678,30 7.3300336,30 H 22.669966 c 1.234241,0 2.23891,-1.004744 2.23891,-2.23891 V 2.2389098 C 24.908876,1.0046689 23.904132,2.7950905e-7 22.669966,2.7950905e-7 Z m 0,2.23890952049095 V 21.329921 H 7.3300336 V 2.2389098 Z M 7.3300336,27.76109 V 23.568643 H 22.669966 v 4.192447 z"
        strokeWidth="0.279878"
      />
      <path
        d="m 12.146098,6.4902319 h 5.703866 c 0.618518,0 1.119492,-0.500975 1.119492,-1.1194922 0,-0.6185173 -0.500974,-1.1194923 -1.119492,-1.1194923 h -5.703866 c -0.618517,0 -1.119492,0.500975 -1.119492,1.1194923 0,0.6185172 0.500975,1.1194922 1.119492,1.1194922 z"
        strokeWidth="0.279878"
      />
    </>
  ) : (
    <>
      <path
        d="m 7.0325,29.9999 h 15.935 c 0.9623,0 1.7444,-0.78205 1.7444,-1.7444 V 1.7445 C 24.7119,0.7822 23.92985,1e-4 22.9675,1e-4 H 7.0325 c -0.9623,0 -1.7444,0.78205 -1.7444,1.7444 v 26.511 c 0,0.9623 0.78205,1.7444 1.7444,1.7444 z M 22.9675,28.837 H 7.0325 c -0.3198,0 -0.58145,-0.26165 -0.58145,-0.58145 v -4.9365 h 17.098 v 4.9365 c 0,0.3198 -0.26165,0.58145 -0.58145,0.58145 z M 7.0325,1.163 h 15.935 c 0.3198,0 0.58145,0.26165 0.58145,0.58145 v 20.412 H 6.45095 V 1.74445 C 6.45095,1.42465 6.7126,1.163 7.0324,1.163 Z"
        strokeWidth="0.29073"
      />
      <path
        d="m 12.0358,5.5789 h 5.925 c 0.3198,0 0.58145,-0.26165 0.58145,-0.58145 0,-0.3198 -0.26165,-0.58145 -0.58145,-0.58145 h -5.925 c -0.3198,0 -0.58145,0.26165 -0.58145,0.58145 0,0.3198 0.26165,0.58145 0.58145,0.58145 z"
        strokeWidth="0.29073"
      />
    </>
  );

  return (
    <S.StyledSVG
      width={width}
      height={height}
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
