import { useContext } from 'react';

// style
import * as S from './AppliedFilters.styled';

// components
import { FilterChip } from './partials/FilterChip/FilterChip';

// contexts
import { TableContext } from 'context/TableContext';
import { useSearchParams } from 'react-router-dom';

export const AppliedFilters = ({ openFilters }) => {
  const {
    state: {
      filters: { applied },
      columns,
    },
  } = useContext(TableContext);
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  const isFilterVisible = (filter) => {
    const column = columns.find((column) => filter.field === column.filter);

    if (!sectorId && column?.isSectorSpecific) {
      return false;
    }

    return true;
  };

  return (
    <S.AppliedFilters>
      {applied.map(
        (filter) =>
          isFilterVisible(filter) && (
            <FilterChip
              key={filter.field}
              filter={filter}
              openFilters={openFilters}
            />
          ),
      )}
    </S.AppliedFilters>
  );
};
