import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { UList } from 'components/DialogLayout/CustomSelect/children/SelectList/SelectList.styled';
import { TheButton } from 'components/Buttons/TheButton.styled';

export const MobileFilter = styled.div`
  ${UList} {
    margin: 0;
  }

  ${TheButton} {
    span {
      margin-top: -3px;
    }
  }
`;

export const FilterList = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  margin: 20px 20px 80px;
`;

export const Controls = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: calc(100% - 40px);
  bottom: 20px;
  left: 20px;
`;
