import * as S from '../SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <>
      <path
        d="M 28.777186,15.85129 H 17.072469 c -0.675564,0 -1.222747,0.547126 -1.222747,1.222615 v 10.480901 h -5.276076 c -1.2563652,0 -2.2773803,-1.020883 -2.2773803,-2.277193 v -4.814074 l 1.8922543,1.895067 c 0.379051,0.379013 1.030166,0.110044 1.030166,-0.424857 v -1.815586 c 0,-0.158944 -0.06419,-0.311776 -0.177299,-0.424857 L 7.073502,15.725983 3.1056171,19.693306 c -0.1131041,0.1131 -0.1772986,0.265913 -0.1772986,0.424857 v 1.815586 c 0,0.537957 0.6511141,0.806927 1.0301651,0.424857 l 1.8922547,-1.895067 v 4.814074 C 5.8507387,27.881807 7.9691186,30 10.573688,30 h 18.20367 c 0.675564,0 1.222747,-0.547126 1.222747,-1.222615 V 17.073793 c 0,-0.675489 -0.547183,-1.222616 -1.222747,-1.222616 z m -1.222747,11.703591 h -9.259336 v -9.25821 h 9.259336 z"
        strokeWidth="0.305663"
      />
      <path
        d="M 14.149899,12.926095 V 2.4451935 h 5.276076 c 1.256365,0 2.277381,1.0208838 2.277381,2.2771932 V 9.5364613 L 19.808101,7.6413941 C 19.42905,7.2623806 18.777936,7.5313575 18.777936,8.0662514 v 1.8125295 c 0,0.1589401 0.0642,0.3117761 0.1773,0.4279131 l 3.967885,3.967323 3.967885,-3.967323 c 0.1131,-0.113092 0.177301,-0.265913 0.177301,-0.4279131 V 8.0662514 c 0,-0.5379576 -0.648058,-0.8069271 -1.030166,-0.4248573 L 24.145887,9.5364613 V 4.7223867 C 24.145887,2.1181927 22.027506,-9.9906001e-8 19.423125,-9.9906001e-8 H 1.2226417 C 0.54707752,-9.9906001e-8 -1.0510016e-4,0.54712627 -1.0510016e-4,1.2226155 V 12.926207 c 0,0.675489 0.54718262016,1.222616 1.22274680016,1.222616 H 12.927359 c 0.675564,0 1.222746,-0.547127 1.222746,-1.222616 z M 11.70433,11.703479 H 2.4449948 V 2.4452685 H 11.70433 Z"
        strokeWidth="0.305663"
      />
    </>
  ) : (
    <>
      <path
        d="m 29.359802,16.52295 h -12.196 c -0.35047,0 -0.63721,0.28675 -0.63721,0.63721 v 11.562 h -6.1364 c -1.6599,0 -3.0108,-1.3509 -3.0108,-3.0108 v -7.4873 l 1.0578,1.0578 c 0.17205,0.17205 0.46517,0.05098 0.46517,-0.19116 v -1.0641 c 0,-0.07328 -0.028675,-0.14019 -0.079652,-0.19116 l -2.0805,-2.0805 -2.0805,2.0805 C 4.610733,17.88642 4.582058,17.95651 4.582058,18.0266 v 1.0641 c 0,0.24214 0.29312,0.36321 0.46517,0.19116 l 1.0578,-1.0578 v 7.4904 c 3e-7,2.3641 1.9212,4.2853 4.2853,4.2853 h 18.97 c 0.35047,0 0.63721,-0.28675 0.63721,-0.63721 v -12.203 c 0,-0.35047 -0.28675,-0.63721 -0.63721,-0.63721 z m -0.63721,12.199 h -10.922 v -10.925 h 10.925 v 10.925 z"
        strokeWidth="0.31861"
      />
      <path
        d="m 0.640202,13.47395 h 12.199 c 0.35047,0 0.63721,-0.28675 0.63721,-0.63721 v -11.562 h 6.1364 c 1.6599,0 3.0108,1.3509 3.0108,3.0108 v 7.4873 l -1.0578,-1.0578 c -0.17205,-0.17205 -0.46517,-0.05098 -0.46517,0.19116 v 1.0641 c 0,0.07328 0.02867,0.14019 0.07965,0.19116 l 2.0805,2.0805 2.0805,-2.0805 c 0.05098,-0.05098 0.07965,-0.12107 0.07965,-0.19116 v -1.0641 c 0,-0.24214 -0.29312,-0.36321 -0.46517,-0.19116 l -1.0578,1.0578 v -7.4873 c 4e-6,-2.3641 -1.9212,-4.2853 -4.2853,-4.2853 h -18.973 c -0.35047,0 -0.63721,0.28675 -0.63721,0.63721 v 12.199 c 0,0.35047 0.28675,0.63721 0.63721,0.63721 z m 0.63721,-12.199 h 10.925 v 10.925 h -10.925 z"
        strokeWidth="0.31861"
      />
    </>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
