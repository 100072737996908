import * as S from '../SVG.styled';

export default ({ color, width = 30, height = 30, bold, ...rest }) => {
  const content = bold ? (
    <path
      d="m 29.278107,0.23465599 c -0.48058,-0.29954934 -1.08911,-0.31177432 -1.58529,-0.0397349 L 0.7903755,15.004201 c -0.5211551,0.287324 -0.83009817,0.852786 -0.78639202,1.439659 0.04680928,0.586874 0.43689279,1.097323 0.99860402,1.305166 l 4.787052,1.763659 c 0.6428423,0.235349 1.363722,-0.08254 1.607134,-0.715255 0.2434119,-0.632717 -0.084257,-1.335747 -0.7302359,-1.574153 L 3.7799814,16.159573 27.388055,3.1658521 25.765321,26.6408 12.599287,18.965692 24.233074,9.4534632 C 24.763586,9.0194204 24.832229,8.2491534 24.392226,7.732592 23.949089,7.2160306 23.162697,7.1457183 22.635317,7.5767049 L 9.9592197,17.941682 c -0.021844,0.01834 -0.040569,0.03668 -0.062413,0.05502 -0.3526305,0.330112 -0.5242676,0.791661 -0.471224,1.268491 0.012488,0.09782 0.034327,0.192562 0.062413,0.284268 l 1.7693963,9.243167 c 0.124826,0.574649 0.583555,1.033142 1.16711,1.167635 C 12.539966,29.984717 12.655427,30 12.770889,30 c 0.471224,0 0.929942,-0.2109 1.226416,-0.586874 l 3.682304,-4.633865 8.129232,4.73774 c 0.246525,0.143662 0.518024,0.213956 0.792636,0.213956 0.246525,0 0.493068,-0.05807 0.720861,-0.174225 0.48058,-0.247593 0.789523,-0.712198 0.826967,-1.240985 L 29.99672,1.6195561 C 30.034167,1.0632448 29.759551,0.53140221 29.278971,0.23185287 Z M 13.319079,26.285849 12.445294,21.725296 15.519162,23.51648 Z"
      strokeWidth="0.308849"
    />
  ) : (
    <path
      d="m 29.238617,0.1478022 c -0.28674,-0.19116 -0.66905,-0.19116 -0.95579,-0.03186 L 0.78782723,15.535942 c -0.44603,0.25488 -0.60533,0.86021 -0.35046,1.3062 0.09558,0.19116 0.28674,0.3186 0.47789,0.41417 l 4.90639997,1.8479 c 0.35046,0.09558 0.70091,-0.09558 0.79649,-0.41417 0.09558,-0.3186 -0.06372,-0.63719 -0.35045,-0.76463 l -4.2373,-1.593 26.3159998,-14.8149998 -1.816,26.6979998 -15.133,-9.0162 14.687,-14.0179998 c 0.25488,-0.25488 0.25488,-0.63719 0.03186,-0.89207 -0.22302,-0.25488 -0.63719,-0.25488 -0.89207,-0.03186 l -15.006,14.3049998 c -0.2230198,0.19116 -0.3185998,0.50975 -0.2867398,0.79649 0,0.06372 0.03186,0.1593 0.06372,0.22302 l 1.7840998,9.6534 c 0.06372,0.35045 0.35046,0.63719 0.70091,0.73277 0.06372,0.03186 0.1593,0.03186 0.22302,0.03186 0.28674,0 0.57347,-0.12744 0.76463,-0.35046 l 4.1099,-5.2887 8.7932,5.225 c 0.44603,0.25488 1.0514,0.09558 1.3062,-0.35045 0.06372,-0.12744 0.09558,-0.25488 0.12744,-0.38232 l 1.8797,-27.8129998 c 0,-0.38231 -0.1593,-0.73277 -0.44603,-0.89207 z m -16.344,28.0999998 -1.4018,-7.5188 4.9701,2.9629 z"
      strokeWidth="0.3186"
    />
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
