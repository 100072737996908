import { keyframes } from 'styled-components/macro';

export const dropdownRollIn = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(100%);
  }
`;

export const dropdownRollOut = keyframes`
  0% {
    opacity: 1;
    transform: scaleY(100%);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
`;

// ------------------------------

// copy this to the element you want to animate
// animation: 'keyframes' 1s ease 0s 1 normal forwards;

export const pulse = keyframes`
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(1);
	}
`;

// ------------------------------
export const rotate180 = keyframes`
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
`;

export const rotate180Back = keyframes`
  from {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
`;

// ------------------------------

export const notificationAppear = keyframes`
        from {
          transform: translateY(-200%)
          scale(0.5);
          opacity: 0;
        }
        to {
          transform: translateY(0%)
          scale(1);
          opacity: 1;
        }
`;

export const notificationClose = keyframes`
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
`;
