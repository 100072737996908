import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const NavigationPanel = styled.div`
  padding: 24px 0;

  .calendar__wrapper {
    position: absolute;
    top: 50px;
    left: -4px;
    padding: 10px;

    width: 260px;

    z-index: 6;

    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;

    overflow: hidden;
  }

  @media screen and (max-width: 960px) {
    .calendar__wrapper {
      left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .calendar__wrapper {
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      top: 0;
      padding: 25px 24px 10px 24px;

      .react-calendar__navigation {
        margin-bottom: 28px;
      }

      .react-calendar__navigation__label {
        margin-left: 0;
        margin-top: -4px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: row-reverse;
    gap: unset;
  }
`;

export const Navigation = styled.div`
  display: flex;
  gap: 18px;

  @media screen and (max-width: 600px) {
    gap: 14px;
  }

  > * {
    align-self: center;
  }

  .actions-wrapper {
    display: flex;

    > * {
      align-self: center;
    }
    .calendar-icon {
      cursor: pointer;
    }
  }

  .arrow {
    display: flex;
    cursor: pointer;

    &__left {
      transform: rotate(-180deg);
    }

    &__double-left {
      transform: rotate(180deg);
    }
  }

  .today-button {
    font-size: 12px;
    line-height: 14px;
    font-family: ${fontFamilies.family_Secondary};
    letter-spacing: 0px;
    border-radius: 18px;
    padding: 2.5px 10px 1.5px 10px;
    margin-left: 10px;
    cursor: ${(props) => (props.isToday ? 'default' : 'pointer')};
    color: ${(props) =>
      props.isToday ? colors.disabledGrey : colors.calendar_button_Secondary};
    border: 1px solid
      ${(props) =>
        props.isToday ? colors.disabledGrey : colors.calendar_button_Secondary};
  }
`;

export const Date = styled.div`
  font-size: 20px;
  color: #0f1d5b;
  font-family: ${fontFamilies.family_Primary};
  margin-top: -4px;

  @media screen and (max-width: 600px) {
    margin-right: auto;
    font-size: 18px;
  }

  .date-string {
    font-weight: 600;
    text-wrap: nowrap;
  }
`;
