import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const TableHeading = styled.div`
  .total-records {
    font-family: ${fontFamilies.family_Secondary};
    margin-bottom: 10px;
    font-size: 0.75rem;

    b {
      font-weight: 800;
    }
  }
`;
