import { useContext } from 'react';

// style
import * as S from './SetServiceSelectModal.styled';

// components
import { ServiceList } from './ServiceList/ServiceList';
import { SetList } from './SetList/SetList';
import { ContentLayout } from 'components/ContentLayout/ContentLayout';
import { SelectionPanel } from 'components/Overlay/SelectionPanel/SelectionPanel';

// context
import { MainContext } from 'pages/Main/Main';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';

const SetServiceSelectModal = ({
  close,
  breadCrumbs,
  name,
  action,
  selectedServices,
  save,
  searchParams,
  handleAdd,
  toastTextOnAdd,
  autoExpandCategories,
  includeUngroupedCategory,
}) => {
  // Hooks
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const { searchQueries, setSearchQuery } = useContext(MainContext);
  const queryId = 'services-modal';
  const { [queryId]: searchQuery } = {
    ...(searchQueries && { ...searchQueries }),
  };

  return (
    <S.SetServiceSelectModal
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isMobile ? (
        <ContentLayout>
          <SelectionPanel
            headerTitle={tr['services']}
            closeAction={() => {
              close();
              save(selectedServices.state);
            }}
            Child={ServiceList}
            searchTitle={tr['search']}
            arrayFilter={(filter) => setSearchQuery(filter, queryId)}
            childProps={{
              selectedServices,
              searchQuery,
              searchParams,
              queryId,
              handleAdd,
              toastTextOnAdd,
              autoExpandCategories,
              includeUngroupedCategory,
            }}
          />
        </ContentLayout>
      ) : (
        <>
          <ServiceList
            selectedServices={selectedServices}
            searchQuery={searchQuery}
            searchParams={searchParams}
            queryId={queryId}
            handleAdd={handleAdd}
            toastTextOnAdd={toastTextOnAdd}
            autoExpandCategories={autoExpandCategories}
            includeUngroupedCategory={includeUngroupedCategory}
          />
          <SetList
            close={close}
            breadCrumbs={breadCrumbs}
            name={name}
            selectedServices={selectedServices}
            save={save}
          />
        </>
      )}
    </S.SetServiceSelectModal>
  );
};

export default SetServiceSelectModal;
