import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Calendar = styled.div`
  width: 100%;
  height: 100%;

  position: relative;

  background-color: white;

  /* padding: 20px; */

  @media screen and (max-width: 960px) {
    /* top: 20px;
    left: 45px; */
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    /* top: 6px; */
    /* left: 0; */
    border-radius: 0;
    box-shadow: none;

    /* padding: 15px; */
  }

  .react-calendar {
    height: 100%;

    abbr {
      text-decoration: none;
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 15px;
    }
  }

  .react-calendar__navigation {
    position: relative;
    margin-bottom: 20px;
  }

  .react-calendar__navigation__label {
    border: none;
    background-color: white;
    padding: 0;
    margin-left: 2px;

    font-size: 16px;
    font-weight: 600;
    font-family: ${fontFamilies.family_Primary};
    color: ${colors.calendar_color_Primary};

    cursor: pointer;

    @media screen and (max-width: 600px) {
      margin-left: ${(props) => props.close && 35}px;
      font-size: 18px;
    }
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    border: none;
    background-color: white;
    position: absolute;
    cursor: pointer;
  }

  .react-calendar__navigation__prev-button {
    right: 72px;
    top: -5px;
    height: 30px;
    width: 17px;
    padding: 0;
    transform: rotate(180deg);

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media screen and (max-width: 600px) {
      right: 127px;
    }
  }

  .react-calendar__navigation__next-button {
    box-sizing: border-box;
    right: 0px;
    top: -5px;
    height: 30px;
    width: 17px;
    padding: 0;

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media screen and (max-width: 600px) {
      right: 17px;
    }
  }

  .react-calendar__tile,
  .react-calendar__month-view__days__day,
  .react-calendar__month-view__days__day--neighboringMonth {
    border: none;
    background-color: white;
    color: ${colors.calendar_date_text_not_selected};
  }

  .react-calendar__tile {
    margin-top: 1px;
    margin-bottom: 1px;
    /* height: ${({ tileHeight, isArray }) => isArray && tileHeight}px; */
    padding: 0;
  }

  .react-calendar__tile,
  abbr {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__tile--hover,
  .react-calendar__tile--range {
    background-color: ${({ isArray, isRange }) =>
      isArray && isRange && colors.paid};
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    abbr {
      background-color: ${colors.bg_Button_Secondary};
      color: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }
  }

  .react-calendar__tile--rangeStart {
    background: ${({ isRange }) =>
      isRange && `linear-gradient(90deg, transparent 50%, ${colors.paid} 50%)`};
  }

  .react-calendar__tile--rangeEnd {
    background: ${({ isRange }) =>
      isRange && `linear-gradient(90deg, ${colors.paid} 50%, transparent 50%)`};
  }

  .react-calendar__month-view__days__day {
    /* width: 100%; */

    /* border: 1px solid green; */
    /* margin-right: 5px; */
    /* width: 27px; */

    /* position: relative; */

    abbr {
      min-width: 25px;
      max-width: 25px;
      min-height: 25px;
      max-height: 25px;
      /* display: block; */
      /* padding: 5.5px; */

      /* position: absolute; */

      font-size: 0.875rem;
      font-family: ${fontFamilies.family_Secondary};

      /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */

      border-radius: 50%;

      /* display: flex;
      justify-content: center;
      align-items: center; */

      &:hover {
        cursor: pointer;
        background-color: ${colors.calendar_color_Primary};
        color: white;
        /* border: none; */
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${colors.calendar_diff_month_day};
  }

  .react-calendar__month-view__weekdays {
    /* box-sizing: border-box;
    display: inline-flex !important; */

    div {
      /* border: 2px solid purple; */

      text-align: center;

      /* box-sizing: border-box; */

      /* width: 25px; */
      /* margin-right: 7px; */
      margin-bottom: 5px;

      abbr {
        /* display: block; */
        /* width: 23px; */
        font-size: 0.875rem;

        /* text-align: center; */

        font-weight: bold;
        color: ${colors.calendar_color_Primary};
      }
    }
  }

  /* menesiu ir menesiu vaizdai */

  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year {
    border-radius: 0;
    cursor: pointer;
    font-family: ${fontFamilies.family_Secondary};
    margin-bottom: 5px;

    text-align: left;

    &:hover {
      color: ${colors.calendar_color_Primary};
    }
  }

  .react-calendar__century-view__decades__decade {
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
    cursor: pointer;

    width: 48%;
    max-width: 48% !important;
    flex-basis: 48% !important;
    margin-left: 4px;

    text-align: left;

    &:hover {
      color: ${colors.calendar_color_Primary};
    }
  }

  .today {
    abbr {
      border: 1px solid ${colors.mandyRed};
    }
  }

  .selected {
    abbr {
      background-color: ${colors.bg_Primary};
      color: white;
    }
  }

  .currentlySelected {
    abbr {
      background-color: #cfd1de;
      color: white;
    }
  }

  /* ----------------------- */
  .miniCalendarIcon {
    position: absolute;
    /* top: 21px;
    right: 56px; */

    top: 1.5px;
    right: 36px;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
    }

    @media screen and (max-width: 600px) {
      top: 1.5px;
      right: 71px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .mobileToggleCalendarIcon {
    display: none;

    @media screen and (max-width: 600px) {
      display: block;
      position: absolute;

      /* top: 10px;
      left: 15px; */
      left: 0;
      /* top: 16px; */
      top: 0;

      /* padding: 5px; */
      border-radius: 50%;

      svg {
        fill: ${colors.bg_Primary};
        width: 20px;
        height: 20px;
      }
    }
  }
`;
