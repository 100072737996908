import React from 'react';

// Style
import { SvgButton } from './Button.styled';

const ButtonSvg = ({ id, svg, action, text, isTooltip }) => {
  return (
    <SvgButton id={id} onClick={action} data-text={text} isTooltip={isTooltip}>
      <div>{svg}</div>
    </SvgButton>
  );
};

export default ButtonSvg;
