import { useState } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router';

// style
import * as S from './Client.styled';

// components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import Footer from 'components/Footer/Footer';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import FunctionBar from 'components/FunctionBar/FunctionBar';
import { ClientGeneralInformation } from './partials/ClientGeneralInformation/ClientGeneralInformation';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useClientsGet } from 'services/clientService';

export const Client = () => {
  // useConfigureStore();

  const navigate = useNavigate();
  const tr = useTranslate().use().global;
  const { clientId } = useParams();
  const isNewClient = clientId === 'new';
  const clientContext = useClientsGet({
    id: clientId,
    queryParams: {
      retry: false,
      enabled: !isNewClient,
    },
  });
  const { data } = clientContext;
  const [isGoBackEnabled, setIsGoBackEnabled] = useState(true);

  let dialogTitle = '';

  if (isNewClient) {
    dialogTitle = tr['new-client'];
  } else {
    dialogTitle = data?.customer?.name || '';

    if (data?.customer?.lastName) {
      dialogTitle += ' ' + data.customer.lastName;
    }
  }

  const tabs = [
    {
      id: 'general-information',
      name: tr['general-information'],
      route: 'general-information',
      acl: 1,
    },
    // {
    //   id: 'orders',
    //   name: tr['orders'],
    //   acl: 1,
    ////   element: <ClientOrders />,
    // },
  ];

  return (
    <Routes>
      {/* {tabs && <Route index element={<Navigate to="general-information" />} />} */}

      <Route
        element={
          <ContentLayout hideFab>
            {/* {isDataLoading && <Loader />} */}
            <DialogLayout
              headerText={dialogTitle}
              getBack={() => {
                if (isGoBackEnabled) {
                  if (!history.state?.idx) {
                    navigate('/functions/tables/clients');
                  } else {
                    navigate(-1);
                  }
                }
              }}
              darkTheme
              footer={<Footer content={2} />}
              Child={ClientWrapper}
              childProps={{
                tabs: tabs,
                clientData: data,
                setIsGoBackEnabled: setIsGoBackEnabled,
                clientContext: clientContext,
              }}
            />
          </ContentLayout>
        }
      >
        <Route
          path={'general-information/*'}
          element={<ClientGeneralInformation />}
        />
        {/* <Route path={'orders'} element={<ClientOrders />} /> */}
      </Route>
    </Routes>
  );
};

const ClientWrapper = ({
  tabs,
  setButtons,
  clientData,
  setIsGoBackEnabled,
  clientContext,
}) => {
  const [functions, setFunctions] = useState([]);

  return (
    <MainLayout>
      <S.Client>
        <FunctionBar functions={functions} fixed /> {/* tabs={tabs}  */}
        <S.Container>
          <Outlet
            context={{
              setFunctions,
              setButtons,
              clientData,
              setIsGoBackEnabled,
              clientContext,
            }}
          />
        </S.Container>
      </S.Client>
    </MainLayout>
  );
};
