// Translation
import { useTranslate } from 'hooks/useTranslate';

// Style
import * as S from './RecommendationModal.styled';

// Components
import Avatar from 'components/Avatar/Avatar';

const RecommendationModal = () => {
  const tr = useTranslate().use().global;

  return (
    <S.RecommendationModal>
      <div className="info-wrapper">
        <div className="label-container">
          <span>{tr['created-by']}:</span>
          <span>{tr['creation-date']}:</span>
        </div>
        <div className="value-container">
          <span className="value ">
            <div className="value__avatar">
              <Avatar raised />
            </div>
            <span className="value__name">Vardenis Pavardenis</span>
          </span>
          <span className="value">2022-12-03 14:23</span>
        </div>
      </div>
      <div className="info-wrapper">
        <div className="label-container">
          <span className="extraMargin">{tr['to-the-employee']}:</span>
          <span>{tr['recommended-services']}:</span>
        </div>
        <div className="value-container">
          <span className="value extraMargin ">
            <div className="value__avatar">
              <Avatar raised />
            </div>
            <span className="value__name">Vardenis Pavardenis</span>
          </span>
          <span className="value">
            <div className="value__list">
              <span>Galvos plovimas;</span>
              <span>Modelinis kirpimas;</span>
              <span>Plaukų sušukavimas;</span>
              <span>Dažymas viena spalva visiem plaukam + sruogų dažymas.</span>
              <div className="total-price-container">
                <span>Bendra kaina: 140,00 €</span>
              </div>
            </div>
          </span>
        </div>
      </div>
    </S.RecommendationModal>
  );
};

export default RecommendationModal;
