import PropTpyes from 'prop-types';

// style
import * as S from './Checkbox.styled';
import { colors } from 'common/colors/colors';

// icons
import { Check, Circle } from 'icons/dynamic';

export const Checkbox = ({ text, value, svgColor, disabled }) => {
  const appliedcColor = svgColor
    ? svgColor
    : disabled
      ? colors.disabledGrey
      : colors.bg_Primary;

  return (
    <S.Container disabled={disabled}>
      <input type="checkbox" onClick={value?.action} />

      <span aria-hidden="true" className="labelText">
        {text}
      </span>

      {value?.state ? (
        <Check inCircle black width={16} height={16} />
      ) : (
        <Circle bold width={16} height={16} />
      )}
    </S.Container>
  );
};

Checkbox.propTypes = {
  text: PropTpyes.string,
  value: PropTpyes.object.isRequired,
};

export default Checkbox;
