import { memo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

// Style
import * as S from './DataPanelCell.styled';

// Context
import ContextTree from 'components/ContextTree/ContextTree';
import useMediaQuery from 'hooks/useMediaQuery';

const DataPanelCell = ({
  id,
  label,
  isWorkingHours,
  select,
  selected,
  onAway,
  contextTreeItems,
  doubleClickAction,
  // columnDay,
}) => {
  // Hooks
  const isMobile = useMediaQuery('(max-width: 960px)');
  // -- state
  const [loadContext, setLoadContext] = useState(false);

  // Functions
  const handleMouseOver = () => {
    if (loadContext) return;
    setLoadContext(true);
  };

  return (
    <ClickAwayListener
      mouseEvent="mouseup"
      onClickAway={(e) => onAway && onAway(e, selected)}
    >
      <S.DataPanelCell
        className="dataPanelCell"
        isWorkingHours={isWorkingHours}
        selected={selected}
        onClick={() => select(label)}
        onContextMenu={(e) => {
          e.preventDefault();
          select(label);
        }}
        onDoubleClick={doubleClickAction}
        onMouseOver={handleMouseOver}
      >
        <span id={id} className="dataPanelCell_label">
          {label}
        </span>

        {!isMobile && loadContext && (
          <ContextTree
            items={contextTreeItems}
            containerId={id}
            parentId={'schedule'}
            openOnContext
            fixedWidth={200}
            type="schedule-body-item"
          />
        )}
      </S.DataPanelCell>
    </ClickAwayListener>
  );
};

export default memo(DataPanelCell);
