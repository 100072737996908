import { useContext, useEffect, useRef, useState } from 'react';

// style
import * as S from './TableHeading.styled';

// components
import { PageHeader } from 'components/PageHeader/PageHeader';
import Pagination from 'components/Pagination/Pagination';
import { AppliedFilters } from './partials/AppliedFilters/AppliedFIlters';
import { TableHeadingControls } from './partials/TableHeadingControls/TableHeadingControls';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslate from 'hooks/useTranslate';

export const TableHeading = ({ onSortClick, onFilterClick }) => {
  const tr = useTranslate().use().pagination;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const {
    state: {
      pagination: { skip, take },
      data: { totalCount },
    },
    dispatch,
  } = useContext(TableContext);
  const pageChangeDispatch = useRef();
  const currentPage = skip / take + 1;
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [skip, take]);

  useEffect(() => {
    if (page !== currentPage) {
      clearTimeout(pageChangeDispatch.current);

      pageChangeDispatch.current = setTimeout(() => {
        dispatch({
          type: 'SET_PAGE',
          payload: page - 1,
        });
      }, 200);
    }
  }, [page]);

  return (
    <S.TableHeading>
      <PageHeader
        className="no-hr"
        controls={
          isMobile && (
            <TableHeadingControls
              onSortClick={onSortClick}
              onFilterClick={onFilterClick}
            />
          )
        }
      >
        {isMobile && <AppliedFilters openFilters={onFilterClick} />}
        <Pagination
          recordsPerPage={take}
          recordsPerPageHandler={(amount) =>
            dispatch({
              type: 'SET_RECORDS_PER_PAGE',
              payload: amount,
            })
          }
          totalRecords={totalCount}
          page={page}
          changePage={setPage}
        />
      </PageHeader>
      {isMobile && (
        <div className="total-records">
          {tr['overall']}: <b>{totalCount}</b>
        </div>
      )}
    </S.TableHeading>
  );
};
