import { useContext } from 'react';

// style
import * as S from './FilterRow.styled';

// components
import { BaseInput, BaseInputDate } from 'components/Inputs';
import { FilterButton } from './partials/FilterButton/FilterButton';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import { useTable } from 'hooks/useTable';

// helpers
import { TABLE_FILTER_TYPE } from 'common/constants';
export const FilterRow = ({ column, setTargetColumn }) => {
  const {
    state: {
      filters: { state: filtersState },
    },
  } = useContext(TableContext);
  const { setFilter, removeFilters } = useTable();

  let content = <></>;

  switch (column.filterType) {
    case TABLE_FILTER_TYPE.INPUT_RANGE:
    case TABLE_FILTER_TYPE.DATE_RANGE:
      const filterNames = {
        from: column.filter + 'From',
        to: column.filter + 'Till',
      };
      const fromValueStr = filtersState?.find(
        (filter) => filter.field === filterNames.from,
      )?.valueStr;
      const toValueStr = filtersState?.find(
        (filter) => filter.field === filterNames.to,
      )?.valueStr;

      content = (
        <FilterButton
          onClick={() => setTargetColumn(column)}
          onClear={() =>
            removeFilters([filterNames.from, filterNames.to], false)
          }
          hasValueSelected={fromValueStr || toValueStr}
        >
          <div>{fromValueStr}</div>

          {(fromValueStr || toValueStr) && (
            <div style={{ lineHeight: 0.5 }}> - </div>
          )}

          <div>{toValueStr}</div>
        </FilterButton>
      );

      break;
    case TABLE_FILTER_TYPE.SELECT:
      const appliedFilterValue = filtersState?.find(
        (filter) => filter.field === column.filter,
      )?.value;
      const selectedOption = column.options?.find(
        (option) => option.value === appliedFilterValue,
      );

      content = (
        <FilterButton
          onClick={() => setTargetColumn(column)}
          onClear={() => removeFilters([column.filter], false)}
          hasValueSelected={selectedOption?.label}
        >
          {selectedOption?.label}
        </FilterButton>
      );

      break;
    case TABLE_FILTER_TYPE.INPUT:
      content = (
        <BaseInput
          value={
            filtersState?.find((filter) => filter.field === column.filter)
              ?.value || ''
          }
          onChange={(val) => setFilter(val, column.filter, false)}
          variation="secondary"
          {...(column?.inputProps && { ...column.inputProps })}
        />
      );

      break;
    case TABLE_FILTER_TYPE.DATE:
      content = (
        <BaseInputDate
          onChange={(value) => setFilter(value, column.filter, false)}
          value={
            filtersState?.find((filter) => filter.field === column.filter)
              ?.value
          }
          isClearable={true}
        />
      );
      break;
  }

  return (
    <S.FilterRow className="row">
      <div>{column.label}</div>
      {content}
    </S.FilterRow>
  );
};
