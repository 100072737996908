import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

import { Controls } from 'pages/Employee/partials/WorkplaceSettings/WorkplaceSettings.styled';
export { Controls };

export const Services = styled.div`
  display: flex;
  height: calc(100vh - 220px);
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: 960px) {
    display: revert;
    height: revert;
    overflow: revert;
  }

  .mark,
  .arrow {
    align-items: center;
    display: flex;
    padding: 0;
  }

  /* .mark {
    margin-right: 10px;
  } */

  .arrow {
    margin-left: auto;
  }
`;

export const Section = styled.section`
  float: left;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 960px) {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  :first-of-type {
    border-right: 1px solid ${colors.table_borders_Primary};
    margin-right: 40px;
    padding-right: 40px;
    /* width: fit-content; */
  }

  :last-of-type {
    align-self: start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    margin-right: auto;
    max-width: 1200px;
  }
`;
