export const useOverflowShading = () => {
  let containerId,
    elem,
    width,
    height,
    offset,
    shadowContainer,
    shadowTop,
    shadowBottom,
    shadowLeft,
    shadowRight,
    timeout;

  const initShadows = (config = {}) => {
    const {
      brightness = 0.1, // shadow intensity
      type = 'radial', // shadow type: { radial / linear }
    } = config;

    const shadowRadial = (x, y) => `radial-gradient(
        farthest-side at ${x}% ${y}%,
        rgba(0, 0, 0, ${brightness}) 0%,
        rgba(0, 0, 0, 0) 100%
      )`;

    const shadowLinear = (side) => `linear-gradient(
          to ${side},
          rgba(0, 0, 0, ${brightness}) 0%,
          rgba(0, 0, 0, 0) 100%
        )`;

    shadowContainer = document.querySelector(
      `#sh-${containerId}.shadow-container`,
    );
    shadowTop = document.querySelector(`#sh-${containerId} .shadow-top`);
    shadowBottom = document.querySelector(`#sh-${containerId} .shadow-bottom`);
    shadowLeft = document.querySelector(`#sh-${containerId} .shadow-left`);
    shadowRight = document.querySelector(`#sh-${containerId} .shadow-right`);

    const initialStyle = `
      opacity: 0;
      position: absolute;
      transition: opacity 125ms;`;

    if (!shadowContainer) {
      shadowContainer = document.createElement('div');
      shadowContainer.setAttribute('id', `sh-${containerId}`);
      shadowContainer.classList.add(`shadow-container`);
      shadowContainer.style.cssText = `position: absolute; pointer-events: none; overflow: hidden;`;
      elem.after(shadowContainer);
    }

    if (!shadowTop) {
      shadowTop = document.createElement('div');
      shadowTop.classList.add('shadow-top');
      shadowTop.style.cssText = initialStyle;
      shadowTop.style.backgroundImage =
        type === 'radial' ? shadowRadial(50, 0) : shadowLinear('bottom');
      shadowContainer.append(shadowTop);
    }

    if (!shadowBottom) {
      shadowBottom = document.createElement('div');
      shadowBottom.classList.add('shadow-bottom');
      shadowBottom.style.cssText = initialStyle;
      shadowBottom.style.backgroundImage =
        type === 'radial' ? shadowRadial(50, 100) : shadowLinear('top');
      shadowContainer.append(shadowBottom);
    }

    if (!shadowLeft) {
      shadowLeft = document.createElement('div');
      shadowLeft.classList.add('shadow-left');
      shadowLeft.style.cssText = initialStyle;
      shadowLeft.style.backgroundImage =
        type === 'radial' ? shadowRadial(0, 50) : shadowLinear('right');
      shadowContainer.append(shadowLeft);
    }

    if (!shadowRight) {
      shadowRight = document.createElement('div');
      shadowRight.classList.add('shadow-right');
      shadowRight.style.cssText = initialStyle;
      shadowRight.style.backgroundImage =
        type === 'radial' ? shadowRadial(100, 50) : shadowLinear('left');
      shadowContainer.append(shadowRight);
    }
  };

  const isScrollbarVisible = (axis) => {
    return axis === 'vertical'
      ? elem.scrollHeight > elem.clientHeight
      : elem.scrollWidth > elem.clientWidth;
  };

  const calcPosition = (config = {}) => {
    const {
      top = 0, // top offset
      bottom = 0, // bottom offset
      left = 0, // left offset
      right = 0, // right offset
      spread = 20, // shadow thickness/spread
      scrollbarOffsetVertical = 0, // width of horizontal scrollbar. Assumed hidden by default
      scrollbarOffsetHorizontal = 0, // width of vertical scrollbar. Assumed hidden by default
      containerStyle = '',
    } = config;

    width = elem.offsetWidth;
    height = elem.offsetHeight;
    offset = { top: elem.offsetTop, left: elem.offsetLeft };

    // update
    shadowContainer.style.cssText = `
      ${shadowContainer.style.cssText}
      height: ${height - (top + bottom)}px;
      width: ${width - (left + right)}px;
      top: ${offset.top + top}px;
      left: ${offset.left + left}px;
      ${containerStyle}
    `;

    shadowTop.style.cssText = `
      ${shadowTop.style.cssText}
      height: ${spread}px;
      width: ${width - (left + right)}px;
      top: ${0}px;
      left: ${left}px;
    `;

    shadowBottom.style.cssText = `
      ${shadowBottom.style.cssText}
      height: ${spread}px;
      width: ${width - (left + right)}px;
      top: ${height - (top + bottom) - (isScrollbarVisible('horizontal') && scrollbarOffsetVertical) - spread}px;
      left: ${left}px;
    `;

    shadowLeft.style.cssText = `
      ${shadowLeft.style.cssText}
      height: ${height - (top + bottom)}px;
      width: ${spread}px;
      top: ${0}px;
      left: ${0}px;
    `;

    shadowRight.style.cssText = `
      ${shadowRight.style.cssText}
      height: ${height - (top + bottom)}px;
      width: ${spread}px;
      top: ${0}px;
      left: ${width - right - (isScrollbarVisible('vertical') && scrollbarOffsetHorizontal) - spread}px;
    `;
  };

  const addScrollListener = () => {
    const toggle = (element, action) => {
      if (action === 'show') {
        element.style.opacity = 1;
      } else {
        element.style.opacity = 0;
      }
    };

    const handleScroll = () => {
      if (elem.scrollTop > 0) {
        toggle(shadowTop, 'show');
      } else {
        toggle(shadowTop, 'hide');
      }
      if (Math.round(elem.scrollTop + height) >= elem.scrollHeight) {
        toggle(shadowBottom, 'hide');
      } else {
        toggle(shadowBottom, 'show');
      }

      if (elem.scrollLeft > 0) {
        toggle(shadowLeft, 'show');
      } else {
        toggle(shadowLeft, 'hide');
      }
      if (Math.round(elem.scrollLeft + width) >= elem.scrollWidth) {
        toggle(shadowRight, 'hide');
      } else {
        toggle(shadowRight, 'show');
      }
    };

    elem.removeEventListener('scroll', handleScroll);
    elem.addEventListener('scroll', handleScroll);
  };

  const addResizeListener = () => {
    const handleResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        calcPosition();
        elem.dispatchEvent(new Event('scroll'));
      }, 10);
    };

    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
  };

  return {
    init: (containerQuery, config) => {
      containerId = containerQuery;
      elem = document.querySelector(`#${containerQuery}`);
      initShadows(config);
      calcPosition(config);
      addScrollListener();
      addResizeListener();
      elem.dispatchEvent(new Event('scroll'));
    },
    update: calcPosition,
  };
};
