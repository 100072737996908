import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <path
          d="M 10,0 C 4.486,0 0,4.486 0,10 0,15.514 4.486,20 10,20 15.514,20 20,15.514 20,10 20,4.486 15.514,0 10,0 Z m 0,17.6503 C 5.78226,17.6503 2.34972,14.2177 2.34972,10 2.34972,5.78226 5.78226,2.34972 10,2.34972 c 4.2177,0 7.6503,3.43254 7.6503,7.65028 0,4.2177 -3.4326,7.6503 -7.6503,7.6503 z"
          fill="currentcolor"
          transform="scale(1.5)"
        />
      );

      break;
    case 700:
      content = (
        <path
          d="M 10,20 C 15.5146,20 20,15.5146 20,10 20,4.48543 15.5146,0 10,0 4.48543,0 0,4.48543 0,10 0,15.5146 4.48543,20 10,20 Z M 10,1.63032 c 4.6159,0 8.3697,3.75383 8.3697,8.36968 0,4.6159 -3.7538,8.3697 -8.3697,8.3697 C 5.38415,18.3697 1.63032,14.6159 1.63032,10 1.63032,5.38415 5.38415,1.63032 10,1.63032 Z"
          fill="currentcolor"
          transform="scale(1.5)"
        />
      );

      break;
    default:
      content = (
        <path
          d="M 15,30 C 23.2728,30 30,23.26965 30,15 30,6.730395 23.2728,0 15,0 6.727215,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
