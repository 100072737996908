// Reducers
import functionBarReducer from './functionBarReducer';
import clientReducer from '../reducers/clientReducer';
import employeeReducer from './employeeReducer';
import orderReducer from './orderReducer';
import quantityOfSpecialistsReducer from './quantityOfSpecialists';
import specialistsReducer from './specialistsReducer';
import compareProfessionalsReducer from './compareProfessionalsReducer';
import calendarViewReducer from './calendarViewReducer';
// import filtersReducer from './filtersReducer';
import sideBarReducer from './sideBarReducer';
import appointmentsReducer from './appointmentsReducer';
import pendingOrdersReducer from './pendingOrdersReducer';
import scheduleReducer from './scheduleReducer';

import { combineReducers } from 'redux';

const allReducers = combineReducers({
  functionBarItems: functionBarReducer,
  privateClients: clientReducer,
  employees: employeeReducer,
  orders: orderReducer,
  quantityOfSpecialists: quantityOfSpecialistsReducer,
  specialists: specialistsReducer,
  compareProfessionals: compareProfessionalsReducer,
  calendarView: calendarViewReducer,
  // filters: filtersReducer,
  sideBar: sideBarReducer,
  appointments: appointmentsReducer,
  pendingOrders: pendingOrdersReducer,
  scheduleReducer: scheduleReducer,
});

export default allReducers;
