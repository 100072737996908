import { BaseCheckbox } from 'components/Inputs/BaseCheckbox/BaseCheckbox.styled';
import styled from 'styled-components/macro';

export const UserDetails = styled.div`
  gap: 50px;

  &,
  .field-group {
    display: flex;
    flex-direction: column;
  }

  .field-group {
    gap: 20px;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  fieldset > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  fieldset > div > * {
    display: inline;
  }

  label,
  ${BaseCheckbox} {
    display: flex;
    flex-direction: row;
  }
`;
