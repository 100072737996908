import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { Services } from 'pages/Employee/partials/WorkplaceSettings/Services/Services.styled';
import { StyledSVG } from 'icons/SVG.styled';

export const ServiceTree = styled.div`
  background-color: ${({ isFinal }) => isFinal && colors.bg_grey_1};
  border-radius: ${({ isFinal }) => isFinal && '5px'};
  font-size: 0.875rem;
  margin-inline: ${({ isFinal }) => (isFinal ? 10 : 20)}px;

  .item {
    align-items: center;
    border-bottom: 1px solid
      ${({ isFinal }) => (isFinal ? 'none' : colors.bg_grey_2)};
    display: flex;
    font-weight: ${({ type }) => type === 'set' && 600};
    margin-top: ${({ isFinal }) => isFinal && '10px'};
    min-height: 50px;
    padding-inline: ${({ isFinal }) => isFinal && '10px'};
  }

  .item > :first-child > ${StyledSVG} {
    color: ${({ isDisabledByParent }) =>
      isDisabledByParent && colors.disabledGrey};
    display: ${({ isServiceItem }) => isServiceItem && 'none'};
  }

  :first-child .item {
    border-top: ${({ isFinal }) => !isFinal && '1px solid' + colors.bg_grey_2};
    margin-top: 20px;
  }

  .item .mark {
    background-color: white;
    border-radius: 50%;
  }

  .contents {
    .icon {
      margin-right: 10px;
    }

    svg {
      display: block;
    }
  }

  ${Services} > & {
    font-weight: 600;
    margin-inline: 10px;

    .item {
      border-bottom: 1px solid ${colors.bg_grey_2};
    }

    :nth-of-type(2) .item {
      border-top: 1px solid ${colors.bg_grey_2};
      margin-top: 20px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    gap: 2px;
    margin: 0 10px 10px;
    padding-bottom: 10px;

    > * {
      align-items: center;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding-inline: 10px;
    }

    > * {
      background-color: white;
      min-height: 35px;
    }

    span:first-child {
      color: ${colors.bg_grey_3};
    }

    > div > div {
      background-color: ${colors.bg_grey_1};
      justify-content: right;
      /* padding: 5px; */
      width: 100px;
      min-height: 25px;

      > input {
        display: flex;
        justify-content: right;
      }
    }

    .amount div {
      align-items: center;
      border-radius: 5px;
      color: ${colors.disabledGrey};
      display: flex;
      gap: 5px;
      padding-inline: 10px;
    }

    .price span:last-child::after {
      content: '€';
      margin-left: 5px;
    }

    .duration span:last-child::after,
    .break span:last-child::after {
      content: '';
      margin-left: 12px;
    }
  }
`;
