import { StyledSVG } from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <StyledSVG
    viewBox="0 0 30 30"
    width={width || '100%'}
    height={height || '100%'}
    color={color || 'currentColor'}
    {...rest}
  >
    <g>
      <path d="m29.815 8.9762-8.782-8.7884a0.6373 0.6373 0 0 0-0.89859 0l-19.944 19.935a0.6373 0.6373 0 0 0 0 0.90815l8.7884 8.7788a0.6373 0.6373 0 0 0 0.4493 0.188 0.6373 0.6373 0 0 0 0.45248-0.188l19.935-19.932a0.6373 0.6373 0 0 0 0-0.89859zm-2.409 1.5168-1.4658-1.453a0.6373 0.6373 0 0 0-0.90178 0.89859l1.453 1.4562-0.90815 0.90815-3.6199-3.623a0.63765 0.63765 0 1 0-0.90178 0.90178l3.6199 3.623-0.90815 0.90815-1.4658-1.453a0.6373 0.6373 0 0 0-0.90178 0.89859l1.453 1.4562-0.89541 0.91771-1.453-1.453a0.63765 0.63765 0 0 0-0.90178 0.90178l1.453 1.453-0.90815 0.90815-3.623-3.6294a0.64106 0.64106 0 0 0-0.91452 0.89859l3.6358 3.623-0.90815 0.90815-1.453-1.453a0.6373 0.6373 0 1 0-0.89859 0.90178l1.453 1.453-0.91134 0.90496-1.4562-1.453a0.6373 0.6373 0 0 0-0.89859 0.90178l1.453 1.453-0.90815 0.91134-3.623-3.6199a0.63765 0.63765 0 0 0-0.90178 0.90178l3.623 3.6199-0.90815 0.91134-1.4403-1.4562a0.63765 0.63765 0 1 0-0.90178 0.90178l1.4562 1.453-0.90815 0.91134-1.4562-1.4562a0.63765 0.63765 0 0 0-0.90178 0.90178l1.4371 1.453-0.77432 0.77432-7.8834-7.8866 19.033-19.033 7.8866 7.8866z" />
      <path d="m3.8417 18.705a2.5492 2.5492 0 1 0 3.588 0 2.5492 2.5492 0 0 0-3.588 0zm2.6862 2.6862a1.2746 1.2746 0 1 1 0-1.7844 1.2746 1.2746 0 0 1 0 1.7844z" />
    </g>
  </StyledSVG>
);
