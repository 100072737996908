import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

import { NavLink } from 'react-router-dom';

export const MenuSection = styled.div`
  color: ${colors.leftside_Secondary};
  padding-bottom: 1rem;
  padding-top: 1rem;
  transition: margin-bottom 0.3s ease-in-out;

  :not(:last-child) {
    border-bottom: 1px solid ${colors.table_arrows_Primary};
  }

  :last-child {
    margin-bottom: ${({ hasFilter }) => hasFilter && '80px'};
  }
`;

export const SideBarItem = styled(NavLink)`
  align-items: center;
  border-radius: 5px;
  color: ${colors.text_Secondary};
  display: flex;
  font-family: ${({ level }) =>
    level === 1 ? fontFamilies.family_Primary : fontFamilies.family_Secondary};
  font-size: ${({ level }) => (level === 1 ? 18 : 14)}px;
  font-weight: ${({ level }) => level === 1 && 700};
  margin-inline: 10px;
  padding: 10px ${({ level }) => (level <= 2 ? 10 : 10 + (level - 2) * 10)}px;
  text-decoration: none;
  transition: 0.15s ease-in-out;

  &:hover {
    background-color: ${colors.bg_grey_1};
  }

  &:focus {
    background-color: revert;
  }

  &.active {
    font-weight: bold;
  }

  &.current {
    background-color: ${({ level }) => level > 1 && colors.sidebar_bg_selected};
  }

  > svg {
    height: 16px;
    margin-right: 10px;
    width: 16px;
  }
`;
