export const openSideBar = () => (dispatch) => {
  dispatch({ type: 'SIDE_BAR_OPEN', payload: true });
};

export const closeSideBar = () => (dispatch) => {
  dispatch({ type: 'SIDE_BAR_CLOSE', payload: false });
};

export const toggleSideBar = () => (dispatch) => {
  dispatch({ type: 'SIDE_BAR_TOGGLE' });
};

export const showSideBarToggleButton = () => (dispatch) => {
  dispatch({ type: 'SIDE_BAR_TOGGLE_BUTTON_SHOW', payload: true });
};

export const hideSideBarToggleButton = () => (dispatch) => {
  dispatch({ type: 'SIDE_BAR_TOGGLE_BUTTON_SHOW', payload: false });
};
