import styled from 'styled-components';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Container = styled.div`
  height: 40px;
  background-color: ${(props) => props.bg};

  display: flex;
  justify-content: space-between;
  padding: 10px;

  .orderStatus {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    font-family: ${fontFamilies.family_Secondary};
    padding-top: 2px;
  }

  .orderInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    font-size: 14px;
    font-family: ${fontFamilies.family_Secondary};

    input[type='checkbox'] {
      appearance: auto;
      background: red;

      border: red;

      border-radius: 50%;
    }

    .info_id {
      padding-top: 2px;
    }

    input[type='checkbox']:checked {
      content: '';

      background: red;

      border: red;

      border-radius: 50%;
    }
  }
`;
