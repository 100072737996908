import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Component
import { ProfessionalSelectChild } from 'components/DialogLayout/CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild';
import { ScheduleContext } from 'context/ScheduleContext';

// Employee select button
export const EmployeeSelectButton = () => {
  const { selectedProfessional, toggleEmployeeSelection } =
    useContext(ScheduleContext);

  const employee = useSelector(
    (state) => state.calendarView.employees?.[0]?.employee,
  );

  const [currEmployee, setCurrEmployee] = useState(employee);

  useEffect(() => {
    if (employee) {
      setCurrEmployee(employee);
    }
  }, [employee]);

  return (
    <ProfessionalSelectChild
      darkTheme
      currentProfessional={selectedProfessional?.value || currEmployee}
      setShowSelect={toggleEmployeeSelection}
    />
  );
};

export default EmployeeSelectButton;
