import { StyledSVG } from '../SVG.styled';

export default ({ color, width, height, ...rest }) => (
  <StyledSVG
    width={width ? width : '16.212'}
    height={height ? height : '16.214'}
    viewBox="0 0 16.212 16.214"
    color={color}
    {...rest}
  >
    <g>
      <path
        d="M601.239,2818.718l-1.076-1.077a2.107,2.107,0,0,0-2.979,0h0l-2.666,2.671-1.64-1.632a1.674,1.674,0,0,0-2.365,0l-1.4,1.4a.345.345,0,0,0,0,.486l3.617,3.617L587,2829.917a.333.333,0,0,0-.069.1l-1.263,2.733a.344.344,0,0,0,.313.489.36.36,0,0,0,.145-.031l2.734-1.263a.326.326,0,0,0,.1-.069l5.732-5.732,3.617,3.617a.347.347,0,0,0,.487,0l1.4-1.4a1.676,1.676,0,0,0,0-2.367l-1.638-1.629,2.678-2.673A2.106,2.106,0,0,0,601.239,2818.718Zm-7.031,6.939-5.684,5.684-1.833.842.846-1.824,5.684-5.685.493.493Zm5.5,2.22-1.152,1.154-4.759-4.757-.334-.334h0l-3.617-3.617,1.154-1.156a.985.985,0,0,1,1.392,0l3.659,3.658,1.783,1.779h0l1.876,1.879a.986.986,0,0,1,0,1.394Zm1.034-6.669-2.666,2.669-.9-.9L595,2820.8l2.673-2.672a1.417,1.417,0,0,1,2,0l1.076,1.077a1.417,1.417,0,0,1,0,2Z"
        transform="translate(-585.642 -2817.024)"
        //   fill="#0f1d5b"
      />
    </g>
  </StyledSVG>
);
