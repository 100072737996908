import { StyledSVG } from '../SVG.styled';

export default ({ color, width, height, check, ...rest }) => (
  <StyledSVG
    color={color || 'currentColor'}
    width={width ? width : '17.114'}
    height={height ? height : '16.447'}
    viewBox="0 0 17.114 16.447"
    {...rest}
  >
    <path
      d="M16.751,2.772H12.813V.364a.364.364,0,1,0-.727,0V2.772h-7V.364a.364.364,0,1,0-.727,0V2.772h-4A.364.364,0,0,0,0,3.136V16.084a.364.364,0,0,0,.364.364H16.751a.364.364,0,0,0,.364-.364V3.136A.364.364,0,0,0,16.751,2.772Zm-.364.727V5.032H.727V3.5ZM.727,15.72V5.759h15.66V15.72Z"
      fill={color}
    />
    <path
      d="M19.091,41.44H16.364a.364.364,0,1,0,0,.727h2.727a.364.364,0,1,0,0-.727Z"
      transform="translate(-13.091 -33.906)"
      fill={color}
    />
    <path
      d="M37.944,42.167h2.727a.364.364,0,1,0,0-.727H37.944a.364.364,0,0,0,0,.727Z"
      transform="translate(-30.748 -33.906)"
      fill={color}
    />
    <path
      d="M59.464,42.167h2.727a.364.364,0,1,0,0-.727H59.464a.364.364,0,0,0,0,.727Z"
      transform="translate(-48.355 -33.906)"
      fill={color}
    />
    <path
      d="M19.091,54.59H16.364a.364.364,0,1,0,0,.727h2.727a.364.364,0,1,0,0-.727Z"
      transform="translate(-13.091 -44.666)"
      fill={color}
    />
    <path
      d="M41.014,54.954a.364.364,0,0,0-.364-.364H37.924a.364.364,0,1,0,0,.727h2.727A.364.364,0,0,0,41.014,54.954Z"
      transform="translate(-30.731 -44.666)"
      fill={color}
    />
    <path
      d="M19.091,67.74H16.364a.364.364,0,0,0,0,.727h2.727a.364.364,0,0,0,0-.727Z"
      transform="translate(-13.091 -55.426)"
      fill={color}
    />
    <path
      d="M57.86,55.95l-3.612,3.614-1.491-1.491a.364.364,0,0,0-.513.514l1.747,1.747a.364.364,0,0,0,.513,0l3.87-3.87a.364.364,0,0,0-.514-.514Z"
      transform="translate(-42.669 -45.691)"
      fill={check ? color : '#e54651'}
    />
  </StyledSVG>
);
