import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import {
  EmployeesSelector,
  EmptyEmployee,
} from '../EmployeesSelector/EmployeesSelector.styled';
import { ProfessionalSelect } from 'components/DialogLayout/CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild.styled';
export { EmptyEmployee };

export const CreateNewOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 53px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 20px 10px;
    gap: 20px;

    ${EmployeesSelector} {
      padding: 0 10px;

      ${ProfessionalSelect} {
        background-color: #fff;
      }

      ${EmptyEmployee} {
        background-color: ${colors.bg_grey_2};
      }
    }
  }

  .client_basic {
    /* padding: 0 10px; */
  }

  .client_basic_info {
    align-items: center;
    display: flex;
  }

  .pickClientAgain {
    padding-right: 10px;

    svg {
      fill: ${colors.bg_Primary};
      height: 15.94px;
      width: 20px;
    }
  }

  .client_fullName {
    color: ${colors.bg_Primary};
    font-family: ${fontFamilies.family_Secondary};
    font-size: 18px;
    font-weight: 600;
  }

  .client_orders_info {
    display: flex;
    margin-top: 20px;

    > div {
      margin-right: 5px;
    }
  }

  .breakContainer {
    background-color: white;
    border-radius: 5px;
    padding: 5px 10px;
    width: 180px;

    .input-container {
      font-family: ${fontFamilies.family_Secondary};
      width: 100%;

      > div {
        align-items: center;
        border-radius: 5px;
        display: flex;
        font-weight: 900;
        height: 24px;
        justify-content: center;
        padding-top: 2px;
      }

      input {
        border: none;
        color: ${colors.bg_Primary};
        font-weight: 900;
        outline: none;
        text-align: center;

        ::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }
  }
`;

export const Text = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  max-width: 460px;
  padding: 10px 0px;

  a {
    color: ${colors.link};
  }
`;
