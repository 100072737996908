export const setEmployees = (employees) => async (dispatch) => {
  const updatedEmployees = employees?.map((employee) => {
    return {
      ...employee,
      employee: { ...employee.employee, prioritize: false },
    };
  });

  dispatch({
    type: 'CALENDAR_VIEW_SET',
    payload: { employees: updatedEmployees },
  });
};

export const updateEmployee = (employee) => async (dispatch) => {
  dispatch({ type: 'CALENDAR_VIEW_UPDATE', payload: employee });
};

export const pinEmployee = (id) => async (dispatch) => {
  if (!id) return;

  dispatch({
    type: 'EMPLOYEE_PINNED',
    payload: { id },
  });
};
