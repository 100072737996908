import styled, { css } from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import { BaseCheckbox } from 'components/Inputs/BaseCheckbox/BaseCheckbox.styled';
import { BaseRadioButton } from 'components/Inputs/BaseRadioButton/BaseRadioButton.styled';

export const ScheduleTemplateCreationDialog = styled.div`
  font-family: ${fontFamilies.family_Secondary};
  min-width: 580px;

  @media screen and (max-width: 960px) {
    min-width: revert;
  }

  > div:not(:last-child) {
    margin-bottom: 40px;

    @media screen and (max-width: 960px) {
      margin-bottom: 20px;
    }
  }
`;

export const RepetitionSection = styled.div`
  > div:first-child {
    > div {
      border: 1px solid ${colors.bg_grey_2};
      border-radius: 5px;
    }
  }

  fieldset {
    border: none;
    font-size: 0.875rem;
    margin: 20px 0 0;
    padding: 0;

    > legend {
      margin-bottom: 20px;
      font-weight: 700;
    }

    > label,
    ${BaseCheckbox}, > label,
    ${BaseRadioButton} {
      align-items: center;
      display: flex;

      :not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    appearance: none;
  }

  svg {
    margin-right: 10px;
    display: flex;
    flex: 0 0 16px;
  }

  ${BaseCheckbox}, ${BaseRadioButton} {
    svg {
      margin: 0;
    }
  }
`;

const template = '.template';

export const TemplateSettingsSection = styled.div`
  > div {
    :not(:last-child) {
      margin-bottom: 20px;
    }

    label {
      display: block;
      font-weight: 700;
      margin-bottom: 10px;
      font-size: 0.875rem;
    }

    label:nth-child(1) {
      /* border: 1px solid red; */
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        width: fit-content;
      }
    }

    > :last-child:not(.template) {
      width: fit-content;
    }
  }

  /* label:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      width: fit-content;
    }
  } */

  /* .header {
    grid: 1fr / repeat(7, 1fr);
  } */

  /* .header, */
  .template {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, max-content);
    grid-column-gap: ${({ type }) => (type === 'weekly' ? 10 : 5)}px;
    grid-row-gap: ${({ type }) => (type === 'weekly' ? 5 : 10)}px;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    /* &::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    overflow: auto;
    touch-action: manipulation; */
  }

  .template > :hover {
    ${({ type, templateLength }) =>
      ((type !== 'weekly' && templateLength > 1) ||
        (type === 'weekly' && templateLength > 7)) &&
      css`
        &.template__controls > * {
          display: block;
        }
      `}

    ${({ type, templateLength }) =>
      type !== 'weekly' &&
      templateLength > 1 &&
      css`
        + .template__controls > * {
          display: block;
        }
      `}
  }

  .template__controls {
    display: flex;

    ${({ type }) =>
      type !== 'weekly' &&
      css`
        flex-direction: column;
        height: calc(50% + 8px);
        justify-content: space-between;
        margin-top: auto;
      `}

    grid-column: ${({ type }) => type === 'weekly' && '1 / span 7'};

    & > :last-child {
      display: none;
    }

    > :not(:first-child) {
      margin-left: ${({ type }) => type === 'weekly' && 10}px;
      color: ${colors.mandyRed};
    }

    div {
      cursor: pointer;
    }

    svg {
      display: block;
    }
  }

  .template h3 {
    font-size: 1rem;
    margin: 0;
  }
`;
