import styled from 'styled-components/macro';
import { colors } from '../../../common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const FunctionBarOptions = styled.div`
  background-color: ${colors.bg_Secondary};
  position: absolute;

  top: 0;
  right: calc(100% + 1px);
  min-width: 200px;
  width: max-content;
  font-size: 12px;

  border-radius: 5px;
  box-shadow: ${shadow.tooltip};

  z-index: 1;

  padding: 5px;

  > p {
    font-size: 12px;
    border-top-left-radius: 3px;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-weight: bold;
    padding: 3px 25px 3px 10px;
    height: 20px;
    margin: 0;
    margin-right: -15px;
    color: ${colors.text_Primary};
    background-color: ${colors.bg_Primary};

    > span svg {
      transition: 0.2s ease-in-out;
      transform: rotate(-90deg);
      cursor: pointer;

      position: absolute;
      height: 5.65px;
      width: 10.45px;
      top: 8px;
      right: -12px;
    }
  }
`;
