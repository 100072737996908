// Style
import * as S from './ScheduleWeek.styled';

// Components
import DailyView from 'components/ScheduleCustom/DailyView/DailyView';
import Footer from 'components/Footer/Footer';
import { memo } from 'react';

const ScheduleWeek = () => {
  return (
    <S.ScheduleWeek>
      <DailyView numberOfDays={7} />
      {/* <Footer content={2} /> */}
    </S.ScheduleWeek>
  );
};

export default memo(ScheduleWeek);
