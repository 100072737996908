import PropTypes from 'prop-types';

// Style
import * as S from './TheButton.styled';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const TheButton = ({
  className,
  action,
  data,
  title,
  Icon,
  icon,
  primary,
  secondary,
  tertiary,
  outline,
  flat,
  raised,
  inverted,
  width,
  height,
  type,
  severity,
  transparent,
  borderless,
  disabled,
  font,
  fontWeight,
  float,
  customBackgroundColor,
  customTextColor,
  ...props
}) => {
  let color, borderColor, backgroundColor;
  let boxShadow = shadow.md; // 0px 1px 3px rgba(0, 0, 0, 16%)

  switch (true) {
    case secondary:
      if (inverted) {
        color = colors.bg_Button_Secondary;
        backgroundColor = colors.button_Primary;
      } else {
        color = colors.button_Primary;
        backgroundColor = colors.bg_Button_Secondary;
      }
      break;

    case tertiary:
      color = colors.text_Secondary;
      backgroundColor = colors.bg_Button_Tertiary;
      break;

    case primary:
    default:
      if (inverted) {
        color = colors.bg_Button_Primary;
        backgroundColor = colors.button_Primary;
      } else {
        color = colors.button_Primary;
        backgroundColor = colors.bg_Button_Primary;
      }
      break;
  }

  switch (severity) {
    case 'danger':
      backgroundColor = colors.absent;
      break;
    default:
  }

  if (disabled) {
    backgroundColor = colors.disabledGrey;
  }

  if (customBackgroundColor) {
    backgroundColor = disabled ? colors.disabledGrey : customBackgroundColor;
    color = disabled ? colors.button_Primary : customTextColor;
  }

  borderColor = backgroundColor;

  if (transparent) {
    backgroundColor = 'transparent';
    borderColor = backgroundColor;
  }

  if (outline) {
    color = borderColor;
    backgroundColor = 'transparent';
  }

  if (flat) {
    boxShadow = null;
  } else if (raised) {
    boxShadow = (typeof raised === 'string' && raised) || shadow.lg; // 0px 3px 6px rgba(0, 0, 0, 16%)
  }

  return (
    <S.TheButton
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          return false;
        }
        action && action(data);
      }}
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      boxShadow={boxShadow}
      width={width}
      height={height}
      type={type || 'button'}
      className={className}
      borderless={borderless}
      fontWeight={fontWeight}
      font={font}
      float={float}
      disabled={disabled}
      noTitle={!title}
      {...props}
    >
      {Icon && <Icon color={color} width="100%" height="100%" sm />}
      {icon}
      {title && <span>{title}</span>}
    </S.TheButton>
  );
};

TheButton.propTypes = {
  action: PropTypes.func,
  data: PropTypes.number,
  title: PropTypes.string,
  // icon: PropTypes.oneOfType(PropTypes.element, PropTypes.elementType),
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  flat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
};

export default TheButton;
