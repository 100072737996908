import styled from 'styled-components/macro';

export const TableHeadingControls = styled.span`
  .controls {
    font-weight: 400;

    .sort,
    .filter {
      display: inline;
      cursor: pointer;
    }

    :last-child {
      display: none;

      *:not(:last-child) {
        margin-right: 10px;
        margin-left: 10px;
      }

      @media (max-width: 960px) {
        display: unset;
      }
    }
  }
`;
