// components
import Avatar from 'components/Avatar/Avatar';

// helpers
import { getDate, getDuration } from './dateOperations';

export const getAgeOptions = (data) => {
  if (data) {
    if (Array.isArray(data)) {
      // if data's an array -> return options object of distinct values (for FunctionRow fills)
      return data.reduce((acc, record) => {
        const currAge = getPersonAge(record['dob']);
        if (acc.find((item) => item.value === currAge) === undefined) {
          return [...acc, { value: currAge, label: currAge }];
        } else return acc;
      }, []);
    } else {
      // if data's a record (=object), return the calculated age value (for DataRow fills)
      const currAge = getPersonAge(data['dob']);
      return { value: currAge, label: currAge };
    }
  }
};

export const getDateOptions = (data, headerId) => {
  if (data && headerId) {
    if (Array.isArray(data)) {
      return data.reduce((acc, record) => {
        const currDate = new Date(record[headerId]);
        if (acc.find((item) => item.value === currDate) === undefined) {
          return [...acc, { value: currDate, label: getDate(currDate) }];
        } else return acc;
      }, []);
    } else {
      return { value: data[headerId], label: getDate(data[headerId]) };
    }
  }
};

export const getDurationOptions = (
  data,
  headerId,
  periodStartId,
  periodEndId,
) => {
  if (data && headerId) {
    if (Array.isArray(data)) {
      return data.reduce((acc, record) => {
        const currDuration = getDuration(
          record[periodStartId],
          record[periodEndId],
        );

        if (acc.find((item) => item.value === currDuration) === undefined) {
          return [...acc, { value: currDuration, label: currDuration }];
        } else return acc;
      }, []);
    } else {
      return { value: data[headerId], label: getDate(data[headerId]) };
    }
  }
};

export const getOptions = (data, headerId) => {
  if (data && headerId) {
    if (Array.isArray(data)) {
      let newArray = [];
      data.forEach((record) => {
        const currValue = record[headerId];
        if (
          newArray.find((item) => item.value === currValue) === undefined &&
          ![undefined, null, ''].includes(currValue)
        ) {
          newArray.push({ value: currValue, label: currValue });
        }

        const childrenKey = Object.keys(record).find((key) =>
          Array.isArray(record[key]),
        );

        const services = record[childrenKey];

        if (childrenKey) {
          services.forEach((service) => {
            if (
              newArray.find((item) => item.value === service[headerId]) ===
                undefined &&
              ![undefined, null, ''].includes(service[headerId])
            ) {
              newArray.push({
                value: service[headerId],
                label: service[headerId],
              });
            }
          });
        }
      });
      return newArray;
    } else {
      return { value: data[headerId], label: data[headerId] };
    }
  }
};

export const getArrayOptions = (data, headerId) => {
  if (data && headerId) {
    if (Array.isArray(data)) {
      let newArray = [];
      data.forEach((record) => {
        if (Array.isArray(record[headerId])) {
          record[headerId].forEach((value) => {
            const currValue = value;
            if (
              newArray.find((item) => item.value === currValue) === undefined &&
              ![undefined, null, ''].includes(currValue)
            ) {
              newArray.push({ value: currValue, label: currValue.toString() });
            }
          });
        }
      });
      return newArray;
    } else {
      return { value: data[headerId], label: data[headerId] };
    }
  }
};

// // another approach for getOptions
// const getOptions = (data, headerId) => {
//   if (Array.isArray(data)) {
//     return data.reduce((acc, record) => {
//       const currValue = record[headerId];
//       if (acc.find((item) => item.value === currValue) === undefined) {
//         return [...acc, { value: currValue, label: currValue }];
//       } else return acc;
//     }, []);
//   } else {
//     return { value: data[headerId], label: data[headerId] };
//   }
// };

export const getPersonAge = (dob) => {
  return Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e10);
};

export const getAvatar = (data) => <Avatar imagePath={data} size="30px" />;

export const getPredefinedLabel = (record, header) => {
  return header.options?.find((option) => option.value === record[header.id])
    .label;
};

export const getPriority = (record, priorities) => {
  // console.log(
  //   'record:',
  //   record,
  //   '\npriorities:',
  //   priorities,
  //   '\nfound?',
  //   priorities.some((priority) => priority == record.id) || ''
  // );
  const priority =
    priorities?.findIndex((priority) => priority === record.id) + 1;
  return priority === 0 ? '' : priority;
};

export const getArray = (data) => data.toString();
