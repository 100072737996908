// style
import * as S from './Message.styled';

// icons
import { Info, LetterIInCircle } from 'icons/dynamic';

export const Message = ({ children, severity }) => {
  return (
    <S.Message severity={severity}>
      <span className="severity">
        {(!severity || severity === 'info') && <LetterIInCircle />}
      </span>
      <span className="contents">{children}</span>
    </S.Message>
  );
};
