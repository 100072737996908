// style
import * as S from './ProfessionalSelectChild.styled';

// icons
import { Arrow } from 'icons/dynamic';

// components
import { Avatar } from 'components/Avatar/Avatar';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { fillImgPath } from 'common/helpers/imagesFunctions';

export const ProfessionalSelectChild = ({
  setShowSelect,
  currentProfessional,
  darkTheme,
  className,
}) => {
  const tr = useTranslate().use().global;

  const getFullName = () => {
    if (currentProfessional?.firstName) {
      return `${currentProfessional.firstName} ${currentProfessional.lastName}`;
    } else {
      return null;
    }
  };

  return (
    <S.ProfessionalSelect
      darkTheme={darkTheme}
      onClick={setShowSelect}
      className={className}
    >
      <div className="proffesionalSelect_img">
        <Avatar imagePath={fillImgPath(currentProfessional?.photoUrl)} />
      </div>
      <div className="proffesionalSelect_info">
        <span className="proffesionalSelect_info_name">
          {getFullName() || tr['name']}
        </span>
        <span className="proffesionalSelect_info_profession">
          {currentProfessional?.profession || tr['occupation']}
        </span>
      </div>
      <div className="iconContainer">
        <Arrow black height={18} width={14} />
      </div>
    </S.ProfessionalSelect>
  );
};

export default ProfessionalSelectChild;
