import classNames from 'classnames';

// style
import * as S from './BreadCrumbs.styled';

export const Breadcrumbs = ({ path }) => {
  const isLast = (index) => index === path.length - 1;

  return (
    <S.Breadcrumbs>
      {path?.map((node, index) => (
        <span
          key={'bc' + index}
          className={classNames(isLast(index) && 'last')}
        >
          {node}
          {!isLast(index) && ' | '}
        </span>
      ))}
    </S.Breadcrumbs>
  );
};

export default Breadcrumbs;
