import * as S from '../SVG.styled';

export default ({ color, width, height, bold, black, ...rest }) => {
  let weight;

  if (bold) {
    weight = 700;
  } else if (black) {
    weight = 900;
  }

  let content;

  switch (weight) {
    case 900:
      content = (
        <>
          <path
            d="M 5.6314332,11.649181 H 2.4801743 c -1.3656431,0 -2.47577967,1.154096 -2.47577967,2.57382 v 11.697302 c 0,1.419648 1.11013657,2.573819 2.47577967,2.573819 h 3.1512589 c 1.3656446,0 2.4757792,-1.154171 2.4757792,-2.573819 V 14.223001 c 0,-1.419724 -1.1101346,-2.57382 -2.4757792,-2.57382 z M 4.5829786,24.952162 H 3.5257039 v -9.761005 h 1.0572747 z"
            fill="currentcolor"
            strokeWidth="1.50376"
          />
          <path
            d="m 20.929643,24.951861 h -8.152767 c -0.09985,0 -0.205575,-0.109146 -0.205575,-0.268493 v -8.30593 c 0,-0.977035 -0.79002,-1.770965 -1.762124,-1.770965 -0.9721052,0 -1.7621249,0.79393 -1.7621249,1.770965 v 8.30593 c 0,2.101508 1.6740149,3.810453 3.7298239,3.810453 h 8.152767 c 0.97215,0 1.76205,-0.79402 1.76205,-1.770905 0,-0.977035 -0.7899,-1.771055 -1.76205,-1.771055 z"
            fill="currentcolor"
            strokeWidth="1.50376"
          />
          <path
            d="m 29.42879,25.415127 -2.925149,-2.688844 2.725499,-4.492312 c 0.9897,-1.632362 1.02495,-3.680744 0.0939,-5.345443 -0.916199,-1.641105 -2.566799,-2.62105 -4.414048,-2.62105 h -4.375949 l 1.45665,-5.5519902 c 0.49935,-1.9037941 -0.45225,-3.78396663 -2.261399,-4.4716948 -1.7709,-0.67296789 -3.676949,0.085598 -4.537349,1.8034434 L 11.666651,9.0897897 2.9529591,1.0791016 C 2.2363643,0.41793927 1.1203542,0.46811917 0.46249645,1.1912655 -0.19536283,1.9144072 -0.1454355,3.0330757 0.57409791,3.6942365 L 23.035343,24.34372 c 0,0 0.0087,0.0089 0.01455,0.01191 l 3.997198,3.674623 c 0.33765,0.30995 0.7635,0.463417 1.18935,0.463417 0.4758,0 0.954449,-0.194774 1.301099,-0.575578 0.65775,-0.723015 0.60795,-1.841759 -0.1116,-2.502965 z M 18.339144,3.6381561 c 0.03525,-0.067884 0.0618,-0.094447 0.0588,-0.097402 0.03225,0 0.11745,0.017714 0.1704,0.073794 0.01755,0.020653 0.0498,0.050171 0.0117,0.1977437 L 16.541845,11.58686 c -0.138,0.531286 -0.0264,1.098 0.3084,1.531884 0.334799,0.433885 0.848699,0.690679 1.394999,0.690679 h 6.663748 c 0.79875,0 1.204049,0.569668 1.339199,0.814658 0.31425,0.560744 0.30255,1.22195 -0.0294,1.76798 l -2.364149,3.902111 -9.483132,-8.719116 3.970634,-7.9339451 z"
            fill="currentcolor"
            strokeWidth="1.50376"
          />
        </>
      );

      break;
    case 700:
      content = (
        <>
          <path
            d="m 2.03859,29.993025 h 3.600375 c 1.12473,0 2.038575,-0.92295 2.038575,-2.0538 v -12.11235 c 0,-1.13385 -0.913845,-2.0538 -2.038575,-2.0538 H 2.03859 C 0.913848,13.773075 0,14.696085 0,15.830025 v 12.1122 c 0,1.134 0.913848,2.05395 2.03859,2.05395 z m 0.406485,-13.77495 h 2.79045 v 11.32995 h -2.79045 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-9.7499997e-4)"
          />
          <path
            d="m 11.204595,15.955275 c 0.311745,-0.5991 0.07641,-1.338735 -0.52263,-1.64742 -0.59904,-0.311745 -1.338675,-0.07641 -1.647375,0.52263 -0.34536,0.66324 -0.52875,1.41204 -0.52875,2.16384 v 9.6306 c 0,1.86135 1.5129,3.3741 3.374205,3.3741 h 8.906205 c 1.16745,0 2.23425,-0.5898 2.85765,-1.5801 0.36075,-0.5715 0.18645,-1.32645 -0.38505,-1.68405 -0.5715,-0.3606 -1.32645,-0.18645 -1.68705,0.3852 -0.1713,0.27195 -0.4647,0.43395 -0.78555,0.43395 h -8.906205 c -0.510405,0 -0.929115,-0.41565 -0.929115,-0.9291 v -9.6306 c 0,-0.3606 0.08862,-0.7182 0.253665,-1.03605 z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-9.7499997e-4)"
          />
          <path
            d="m 27.90765,29.635275 c 0.23835,0.23835 0.55005,0.3576 0.8649,0.3576 0.31485,0 0.62655,-0.11925 0.8649,-0.3576 0.47685,-0.47685 0.47685,-1.2501 0,-1.72995 l -3.4842,-3.4842 3.093,-4.9269 c 0.9537,-1.5189 1.0056,-3.36195 0.13755,-4.92981 -0.8679,-1.56789 -2.45415,-2.506185 -4.2483,-2.506185 h -5.6787 l 1.8093,-6.6567 C 21.72465,3.71748 20.90865,2.097615 19.28265,1.46496 17.65665,0.8322975 15.9603,1.471071 15.1566,3.02064 L 11.60505,9.87294 2.09064,0.358566 c -0.47679,-0.4767882 -1.2500415,-0.4767882 -1.7298885,0 -0.47679045,0.4767885 -0.47679045,1.250049 0,1.729884 L 27.91065,29.638275 Z M 17.3235,4.14843 c 0.31785,-0.614325 0.85275,-0.485955 1.06665,-0.40344 0.2139,0.082515 0.69375,0.35148 0.51345,1.017765 l -2.2281,8.20014 c -0.1008,0.366765 -0.0213,0.76104 0.2079,1.06362 0.2322,0.302565 0.5898,0.479835 0.97185,0.479835 h 7.27725 c 0.88935,0 1.6779,0.464565 2.10885,1.243875 0.43095,0.7794 0.40335,1.6932 -0.0672,2.44815 l -2.7966,4.45305 L 13.41441,11.688405 17.3205,4.14843 Z"
            fill="currentcolor"
            strokeWidth="1.5"
            transform="translate(0,-9.7499997e-4)"
          />
        </>
      );

      break;
    default:
      content = (
        <>
          <path
            d="m 1.59348,30.0027 h 4.21314 c 0.8796,0 1.59348,-0.6756 1.59348,-1.5042 V 15.86865 c 0,-0.8286 -0.71388,-1.504215 -1.59348,-1.504215 H 1.59348 C 0.713877,14.364435 0,15.04005 0,15.86865 V 28.4985 c 0,0.8286 0.713877,1.5042 1.59348,1.5042 z M 1.2747795,15.86865 c 0,-0.1083 0.130665,-0.2295 0.3187005,-0.2295 h 4.21314 c 0.188025,0 0.31869,0.1212 0.31869,0.2295 V 28.4985 c 0,0.1083 -0.130665,0.2295 -0.31869,0.2295 H 1.59348 c -0.1880355,0 -0.3187005,-0.1212 -0.3187005,-0.2295 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 10.481955,15.70605 0.52584,-1.01022 c 0.16254,-0.312315 0.03825,-0.697935 -0.27408,-0.860475 C 10.4214,13.67283 10.03578,13.79712 9.87324,14.109435 L 9.3474,15.1197 c -0.31233,0.6024 -0.47805,1.28115 -0.47805,1.9599 v 10.04205 c 0,1.58715 1.29072,2.88105 2.881005,2.88105 h 9.286695 c 0.99765,0 1.90905,-0.50355 2.4381,-1.34805 l 0.54495,-0.8637 c 0.1881,-0.29955 0.09885,-0.6915 -0.2007,-0.8796 -0.2997,-0.18795 -0.69165,-0.09885 -0.8796,0.20085 l -0.5451,0.86355 c -0.29625,0.47175 -0.8031,0.7521 -1.3608,0.7521 h -9.286725 c -0.885975,0 -1.60623,-0.72015 -1.60623,-1.6062 V 17.0796 c 0,-0.47805 0.114735,-0.9528 0.334635,-1.37355 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
          <path
            d="m 24.46305,25.3656 c 0.051,0.07965 0.11475,0.14985 0.19755,0.20085 0,0 0.0033,0 0.0065,0 l 4.245,4.245 c 0.12435,0.1242 0.2868,0.18795 0.4494,0.18795 0.16245,0 0.32505,-0.06375 0.44925,-0.18795 0.2487,-0.24855 0.2487,-0.6534 0,-0.90195 l -3.99315,-3.99315 1.18545,-1.8867 2.3106,-3.6777 c 0.87,-1.38315 0.9147,-3.0627 0.12435,-4.493655 C 28.6476,13.42737 27.2007,12.57327 25.5657,12.57327 H 18.8094 L 20.91285,4.82901 C 21.3654,3.159045 20.3583,1.9257 19.22685,1.482723 18.0987,1.042926 16.518,1.269198 15.7212,2.8053 L 12.4674,9.08676 11.61966,10.721655 1.086804,0.188826 c -0.2485815,-0.25176795 -0.6501375,-0.25176795 -0.898719,0 -0.24858255,0.2485815 -0.24858255,0.653322 0,0.9019035 L 24.4662,25.36875 Z M 13.605135,9.676335 16.85895,3.39489 c 0.4653,-0.898725 1.3194,-0.94971 1.90905,-0.720255 0.58965,0.229455 1.1856,0.844545 0.91785,1.822935 l -2.54325,9.353655 h 8.4231 c 1.16325,0 2.1927,0.608715 2.7567,1.625325 0.56415,1.0167 0.52905,2.2149 -0.0891,3.19965 l -2.3106,3.67785 -1.02945,1.64115 -12.32388,-12.323835 1.032585,-1.99503 z"
            fill="currentcolor"
            strokeWidth="1.5"
          />
        </>
      );

      break;
  }

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
