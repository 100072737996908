import { useEffect, useState } from 'react';
import classNames from 'classnames';

// style
import * as S from '../CategoryTree.styled';
import { colors } from 'common/colors/colors';

// icons
import { Arrow, TrashCan, VerticalEllipsis } from 'icons/dynamic';

// components
import { ServiceItemButton } from '../../ServiceItemButton/ServiceItemButton';
import { Group } from '../Group/Group';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOnHoverContextMenu } from 'hooks/useOnHoverContextMenu';
import { useServicesDelete } from 'services/serviceService';
import {
  emptyStatus,
  getItem,
  isCurrent,
  isInactive,
  isNew,
  removeObjectById,
  updateTree,
} from 'common/helpers/serviceFunctions';

export const Category = ({
  category,
  categories,
  setCategories,
  activeItem,
  setActiveItem,
  serviceAction,
  groupAction,
  searchParams,
  setConfirmDeleteButtons,
  confirmDialogHandler,
  sectors,
  averagePrices,
  autoExpandCategories,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [isOpen, setIsOpen] = useState(autoExpandCategories); // initally undefined for no icon opening animation
  const isSpecialItem = (context) => context.id === 0;

  // handler for context menu, called on hover
  const {
    isHovered,
    setIsHovered,
    setIsContextMenuOpen,
    showContextMenuIcon,
    debounceHideContextMenuIcon,
  } = useOnHoverContextMenu();

  // data delete handler
  const { mutate, isSuccess } = useServicesDelete('category');

  // delete active item from CategoryTree in case of successful removal from B/E
  useEffect(() => {
    // remove current object
    isSuccess &&
      removeObjectById(
        category.id,
        category.type,
        setCategories,
        setActiveItem,
      );
  }, [isSuccess, category.id, category.type, setActiveItem, setCategories]);

  const getFunctionList = (action) => {
    const item = getItem(categories, category.id, category.type);

    const setNewActiveItem = (context) =>
      setActiveItem({ id: 'new', type: context, selected: true });

    const addNewItemToServiceTree = (context) =>
      updateTree(
        category.id,
        category.type,
        {
          id: 'new',
          type: context,
          name: tr['new-' + context],
          [category.type + 'Id']: category.id,
          isActive: true,
          isEmpty: true,
          assignedToSectorIds: [...category.assignedToSectorIds],
        },
        item,
        setCategories,
      );

    const createNewItem = (context) => {
      console.log('create new ' + context);
      !isOpen && openBranch();
      setIsHovered(false);
      if (activeItem?.id === 'new') {
        setActiveItem(undefined);
      }
      addNewItemToServiceTree(context);
      setNewActiveItem(context);
      action && action();
    };

    return [
      {
        id: 'new-group',
        label: tr['new-group'],
        onClick: () => createNewItem('group'),
      },
      ...(isSpecialItem(item)
        ? []
        : [
            {
              id: 'delete',
              label: tr['delete'],
              icon: <TrashCan bold />,
              fontColor: 'red',
              onClick: () => {
                console.log('delete category');
                handleConfirmDialog(category);
                action && action();
              },
            },
          ]),
    ];
  };

  const openBranch = (e) => {
    if (isNew(activeItem) && isCurrent(category, activeItem)) return;
    e?.stopPropagation();
    setIsOpen((old) => !old);
  };

  const showBranchDetails = () =>
    setActiveItem &&
    category.id !== 'new' &&
    setActiveItem({ id: category.id, type: category.type });

  const { ref } = useScrollIntoView({ auto: true }); // scroll into view currently selected item

  // confirm dialog handler
  const handleConfirmDialog = (context) => {
    setConfirmDeleteButtons((old) => [
      ...old.filter((button) => !['cancel', 'remove'].includes(button.id)),
      {
        ...old.find((button) => button.id === 'cancel'),
        ...{
          message: `${tr['confirm-message']} ${tr['remove']} ${
            tr[context.type + '/accusative']
          }?`,
        },
      },
      {
        ...old.find((button) => button.id === 'remove'),
        ...{
          action: () => {
            console.log(context.type + ' deletion confirmed');
            mutate({ id: context?.id });
            confirmDialogHandler.off();
          },
        },
      },
    ]);
    confirmDialogHandler.on();
  };

  return (
    <>
      <S.CategoryTreeItem
        className={classNames(
          category.type,
          isOpen !== undefined && (isOpen ? 'open' : 'closed'),
          isCurrent(category, activeItem),
          emptyStatus(category),
          isInactive(category),
        )}
        isNew={isNew(activeItem)}
        isEmpty={!!emptyStatus(category)}
        onClick={showBranchDetails}
        onMouseEnter={showContextMenuIcon}
        onMouseLeave={debounceHideContextMenuIcon}
        {...((isNew(category) || isCurrent(category, activeItem)) && { ref })} // required for scrolling into view
      >
        <div>
          <div
            className="icon"
            {...(!category.isEmpty && { onClick: openBranch })}
          >
            <Arrow black />
          </div>

          <div>
            {isSpecialItem(category)
              ? tr['unassigned-to-category']
              : category.name}
          </div>
        </div>

        <div className="context-menu-icon-container">
          {!isSpecialItem(category) &&
            ((!isMobile && isHovered) ||
              (isMobile && isCurrent(category, activeItem))) &&
            !isNew(category) &&
            !serviceAction && (
              <ServiceItemButton
                isSelected={isCurrent(category, activeItem)}
                icon={
                  <VerticalEllipsis
                    {...(isCurrent(category, activeItem) && {
                      color: colors.text_Primary,
                    })}
                  />
                }
                action={groupAction}
                id={category.type + '-' + category.id}
                parentId={'category-container'}
                items={getFunctionList}
                triggerCallback={setIsContextMenuOpen}
              />
            )}
        </div>
      </S.CategoryTreeItem>

      {isOpen &&
        category.groups?.map((group) => (
          <Group
            key={'group' + group.id}
            group={group}
            setCategories={setCategories}
            parentInactive={isInactive(category)}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            serviceAction={serviceAction}
            searchParams={searchParams}
            categoryName={category.name}
            setConfirmDeleteButtons={setConfirmDeleteButtons}
            confirmDialogHandler={confirmDialogHandler}
            parentIsOpenHandler={setIsOpen}
            sectors={sectors}
            averagePrices={averagePrices}
          />
        ))}
    </>
  );
};
