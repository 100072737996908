import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const DisplayMobileSettings = styled.div`
  width: 100%;
  max-width: 750px;

  .form-container {
    background-color: ${colors.bg_grey_1};
    padding: 30px;

    border-radius: 10px;

    width: 100%;
    max-width: 750px;
  }

  .form__time-input {
    width: fit-content;
    margin-bottom: 20px;

    .select__menu-list {
      max-height: 150px;
    }
  }

  .form__card-input-wrapper {
  }

  @media screen and (max-width: 960px) {
    padding: 10px;

    .form-container {
      border-radius: 5px;
      padding: 10px;
    }

    .form__time-input {
      width: 100%;

      .select__menu-list {
        max-height: 100px;
      }
    }
  }
`;
