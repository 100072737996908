// style
import * as S from './BasicTable.styled';

// components
import Loader from 'components/Loader/Loader';
import { BasicTableHead } from './partials/BasicTableHead/BasicTableHead';
import { BasicTableRecord } from './partials/BasicTableRecord/BasicTableRecord';

// hooks
import useTranslate from 'hooks/useTranslate';

const BasicTable = ({
  columns,
  data,
  select,
  isLoading,
  navigation,
  hasMore,
}) => {
  const tr = useTranslate().use().global;

  return (
    <S.BasicTable hasNavigation={!!navigation}>
      <table>
        <BasicTableHead columns={columns} hasNavigation={!!navigation} />
        <tbody>
          {(data?.records || []).map((record) => (
            <BasicTableRecord
              key={record.id}
              selected={select.selected.id === record.id}
              onClick={() => select.setSelected(record)}
              columns={columns}
              record={record}
              navigation={navigation}
            />
          ))}
        </tbody>
      </table>
      {hasMore && <span className="has-more">{tr['has-more']}</span>}
      {isLoading && <Loader />}
    </S.BasicTable>
  );
};

export default BasicTable;
