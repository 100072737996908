// style
import { useCallback } from 'react';
import * as S from './Chip.styled';

export const Chip = ({ onRemove, children, ...other }) => {
  const handleRemove = useCallback((e) => {
    e.stopPropagation();
    onRemove(e);
  }, []);

  return (
    <S.Chip {...other}>
      {children}
      <span onClick={handleRemove}>&times;</span>
    </S.Chip>
  );
};
