import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const ServiceCardDisplaySettings = styled.div`
  .settings__label {
    margin: 0px 10px 5px 10px;

    font-size: 14px;
    color: ${colors.bg_grey_3};

    overflow: hidden;
    white-space: nowrap;
  }

  .settings__container {
    background-color: white;
    border-radius: 5px;

    padding: 10px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }

  .settings__list {
    display: flex;
    flex-direction: column;

    list-style: none;

    margin: 0;
    padding: 0;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      flex-direction: row-reverse;
      justify-content: flex-end;
      height: unset;
    }

    svg {
      min-width: 16px;
    }

    span {
      margin: 0 5px;
    }
  }

  .settings__card {
  }

  .settings__service-card-wrapper {
    min-height: 120px;
    width: 170px;
    margin-bottom: 20px;

    display: flex;
  }
`;
