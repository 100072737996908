import { cloneElement, useContext } from 'react';

// style
import * as S from './FunctionBarSubItem.styled';

// context
import { UserContext } from 'context/UserContext';

// hooks and helpers
import { useMediaQuery } from 'hooks/useMediaQuery';

export const FunctionBarSubItem = ({ item, setActive, closeMenu }) => {
  const { userAcl } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 960px)');

  const processedIcon = item.icon && cloneElement(item.icon, { sm: isMobile }); // add prop for bold icon if isMobile

  return (
    <S.FunctionBarSubItem
      itemColor={item.color}
      itemBackground={item.background}
      onClick={(e) => {
        e.stopPropagation();
        item.action();
        setActive(null);
        closeMenu();
      }}
    >
      {item.background ? (
        <span>
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" />
          </svg>
        </span>
      ) : (
        <span>{processedIcon}</span>
      )}
      <span>{item.name || item.title}</span>
    </S.FunctionBarSubItem>
  );
};

export default FunctionBarSubItem;
