export const setPendingOrders = (data) => async (dispatch) => {
  dispatch({ type: 'PENDING_ORDERS_SET', payload: data });
};

export const addPendingOrder = (data) => async (dispatch) => {
  if (!data) return;
  dispatch({ type: 'PENDING_ORDERS_ADD', payload: data });
};

export const updatePendingOrder = (data) => async (dispatch) => {
  if (!data) return;
  dispatch({ type: 'PENDING_ORDER_UPDATE', payload: data });
};

export const deletePendingOrder = (id) => (dispatch) => {
  if (!id) return;
  dispatch({ type: 'PENDING_ORDER_DELETED', payload: id });
};
