import { useContext, useState } from 'react';

// style
import * as S from './OrderLayout.styled';

// components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import ExplodeComposition from 'components/ExplodeComposition/ExplodeComposition';
import ServiceMenuContainer from 'components/ServiceMenu/ServiceMenuContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// contexts
import { OrderContext } from 'context/OrderContext';

const OrderLayout = ({
  children,
  isMobile,
  showMenu,
  emerging,
  employees,
  customerId,
  employeeId,
  wantedEmployees,
  search,
  employeeSelected,
  selectEmployee,
  multiSelect,
  toggleWantedEmployeesSelection,
}) => {
  const [selectedComp, setSelectedComp] = useState(null);
  const { addToOrder, sectorId, order } = useContext(OrderContext);

  const selectService = (service) => {
    if (!service) return;

    if (service.hasComp) {
      setSelectedComp(service);
    } else addToOrder(service);
  };

  return (
    <ContentLayout>
      <ErrorBoundary>
        <S.OrderLayout_Container>
          {isMobile ? (
            <div className="mobile-view">{children}</div>
          ) : (
            <ServiceMenuContainer
              showMenu={showMenu}
              emerging={emerging}
              employees={employees}
              customerId={customerId}
              employeeId={employeeId}
              selectService={selectService}
              sectorId={sectorId}
              search={search}
              employeeSelected={employeeSelected}
              selectEmployee={selectEmployee}
              order={order.state}
              multiSelect={multiSelect}
              wantedEmployees={wantedEmployees}
              toggleWantedEmployeesSelection={toggleWantedEmployeesSelection}
              averagePrices={false}
              autoExpandCategories={true}
              includeUngroupedCategory={false}
            >
              {children}
            </ServiceMenuContainer>
          )}
        </S.OrderLayout_Container>
        <ExplodeComposition selectedComp={selectedComp} add={addToOrder} />
      </ErrorBoundary>
    </ContentLayout>
  );
};

export default OrderLayout;
