import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const Chip = styled.div`
  align-items: center;
  background-color: ${colors.bg_grey_1};
  border-radius: 11px;
  color: ${colors.disabledGrey};
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.75rem;
  padding: 4px 10px;
  width: max-content;
  line-height: 1.33333;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  height: 22px;

  span {
    margin-left: 5px;
    color: ${colors.text_Secondary};
  }
`;
