import React from 'react';

// Style
import * as S from './SimpleSelectChild.styled';

const SimpleSelectChild = ({ selected, placeholder, child, children }) => {
  return (
    <S.SimpleSelectContainer selected={selected || child?.length}>
      {selected ? selected.label : child ? child : placeholder}
      {children}
    </S.SimpleSelectContainer>
  );
};

export default SimpleSelectChild;
