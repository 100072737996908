import { memo } from 'react';

// style
import * as S from './SchedulePersonal.styled';

// components
import { DailyView } from 'components/ScheduleCustom/DailyView/DailyView';
// import Footer from 'components/Footer/Footer';

const ScheduleWeek = () => {
  return (
    <S.SchedulePersonal>
      <DailyView personal />
      {/* <Footer content={2} /> */}
    </S.SchedulePersonal>
  );
};

export default memo(ScheduleWeek);
