import { MainContext } from 'pages/Main/Main';
import { createContext, useContext, useReducer } from 'react';

const tableReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return action.payload;
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload,
      };
    case 'SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          skip: action.payload * state.pagination.take,
        },
      };
    case 'SET_RECORDS_PER_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          take: action.payload,
          skip: 0,
        },
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: action.payload,
      };
    case 'SET_COLUMN':
      const columns = [...state.columns];
      for (let index in columns) {
        if (columns[index].id === action.payload.id) {
          columns[index] = action.payload;
        }
      }

      return {
        ...state,
        columns,
      };
    case 'SET_SORT':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          skip: 0,
        },
        sort: action.payload,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          state: action.payload,
        },
      };
    case 'SET_APPLIED_FILTERS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          skip: 0,
        },
        filters: {
          ...state.filters,
          applied: action.payload,
        },
      };
    case 'SET_TABLE_NAME':
      return {
        ...state,
        tableName: action.payload,
      };
  }

  return state;
};
export const TableContext = createContext();
export const TableContextProvider = ({ children }) => {
  const { tableState: cachedTableState, setState } = useContext(MainContext);
  const [tableState, tableDispatch] = useReducer(tableReducer, {
    tableName: undefined,
    data: {
      records: [],
      totalCount: undefined,
    },
    pagination: {
      skip: 0,
      take: 20,
    },
    sort: [],
    filters: {
      state: [],
      applied: [],
    },
    columns: [],
  });

  return (
    <TableContext.Provider
      value={{
        state: tableState,
        dispatch: (action) => {
          tableDispatch(action);

          if (tableState?.tableName) {
            setState({
              type: 'SET_TABLE_STATE',
              value: {
                ...cachedTableState,
                [tableState?.tableName]: tableReducer(tableState, action),
              },
            });
          }
        },
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
