import { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';

// style
import * as S from './SideBarDatePicker.styled';

// components
import { TheButton } from 'components/Buttons/TheButton';

// icons
import { Arrow, ArrowDouble } from 'icons/dynamic';

// context
import { UserContext } from 'context/UserContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
} from 'common/helpers/dateOperations';

export const SideBarDatePicker = ({
  value,
  onChange,
  container,
  noToday,
  hasDateValues,
  minDate,
  maxDate,
  activeStartDate,
  onActiveStartDateChange,
  toISOValue,
  locale,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { locale: defaultLocale } = useContext(UserContext);
  const tr = useTranslate().use().global;

  const monthYearFormat = (locale, date) => {
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
    }).format(date);
  };

  const weekdayShortFormat = (locale, date) => {
    return new Intl.DateTimeFormat(locale, {
      weekday: 'short',
    })
      .format(date)
      .substring(0, 2);
  };

  const dayShortFormat = (_, date) => date.getDate();

  const today = new Date();

  const calendarRef = useRef();

  const selectToday = () => calendarRef.current.setActiveStartDate(today);

  const tileClassName = ({ date, view }) => {
    let classNames = '',
      myDate,
      localMinDate,
      localMaxDate;

    const optSpace = () => classNames && ' ';

    switch (view) {
      case 'month':
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const firstDayOfTheMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          1,
        );
        const lastDayOfTheMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0,
        );

        const firstDayOfTheWeek = getFirstDayOfWeek(date);
        const lastDayOfTheWeek = getLastDayOfWeek(date);

        // const loc = new Intl.Locale('lt-LT');

        myDate = date.getTime();

        if (myDate === new Date(value).getTime()) {
          classNames += optSpace() + 'selected';
        }

        if (myDate === today.getTime()) {
          classNames += optSpace() + 'today';
        }

        if (myDate === firstDayOfTheMonth.getTime()) {
          classNames += optSpace() + 'month-start';
        } else if (myDate === lastDayOfTheMonth.getTime()) {
          classNames += optSpace() + 'month-end';
        }

        if (myDate === firstDayOfTheWeek.getTime()) {
          classNames += optSpace() + 'week-start';
        } else if (myDate === lastDayOfTheWeek.getTime()) {
          classNames += optSpace() + 'week-end';
        }

        if (hasDateValues && hasDateValues(date)) {
          classNames += optSpace() + 'has-values';
        }

        localMinDate = new Date(minDate);
        localMaxDate = new Date(maxDate);

        break;

      case 'year':
        myDate = date.getMonth();

        if (myDate % 3 === 0) {
          classNames += optSpace() + 'row-start';
        }
        if ((myDate + 1) % 3 === 0) {
          classNames += optSpace() + 'row-end';
        }

        localMinDate = new Date(minDate).setDate(1);
        localMaxDate = new Date(maxDate).setMonth(maxDate?.getMonth() + 1, 0);

        break;

      case 'decade':
        myDate = date.getFullYear();
        if (((myDate % 10) - 1) % 3 === 0 || myDate % 10 === 0) {
          classNames += optSpace() + 'row-start';
        }
        if ((myDate % 10) % 3 === 0) {
          classNames += optSpace() + 'row-end';
        }

        localMinDate = new Date(minDate).setFullYear(
          minDate?.getFullYear(),
          0,
          1,
        );
        localMaxDate = new Date(maxDate).setFullYear(
          maxDate?.getFullYear() + 1,
          0,
          0,
        );

        break;

      case 'century':
        localMinDate = new Date(minDate).setFullYear(
          minDate?.getFullYear() - (minDate?.getFullYear() % 10) + 1,
          0,
          1,
        );
        localMaxDate = new Date(maxDate).setFullYear(
          maxDate?.getFullYear() - (maxDate?.getFullYear() % 10) + 10,
          0,
          0,
        );

        break;

      default:
    }

    if (date < localMinDate || date > localMaxDate) {
      classNames += optSpace() + 'disabled';
    }
    return classNames;
  };

  const arrowProps = { width: '100%', height: '100%', bold: true };

  return (
    <S.SideBarDatePicker container={container} noToday={noToday}>
      <Calendar
        className="date-picker"
        // value={value && new Date(value)}
        value={value}
        locale={locale || defaultLocale}
        formatDay={props?.formatDay || dayShortFormat}
        formatMonthYear={props?.formatMonthYear || monthYearFormat}
        formatShortWeekday={props?.formatShortWeekday || weekdayShortFormat}
        prevLabel={props.prevLabel || <Arrow {...arrowProps} />}
        nextLabel={<Arrow {...arrowProps} />}
        prev2Label={<ArrowDouble {...arrowProps} />}
        next2Label={<ArrowDouble {...arrowProps} />}
        // navigationLabel={({ date, label, locale, view }) =>
        //   `${date.getUTCFullYear(locale)}----${date.getUTCMonth(locale)}`
        // }
        onClickDay={(date, event) => {
          onChange({
            value: toISOValue ? date.toISOString() : date,
            label: date.toLocaleDateString(locale),
          });
          // TODO: close Menu if isMobile
        }}
        ref={calendarRef}
        tileClassName={tileClassName}
        minDate={minDate}
        maxDate={maxDate}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        {...props}
      />
      {!noToday && (
        <TheButton
          title={tr['today']}
          primary
          rounded
          height="25"
          action={selectToday}
        />
      )}
    </S.SideBarDatePicker>
  );
};

SideBarDatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SideBarDatePicker;
