import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSideBar, openSideBar } from 'redux/actions/sideBarActions';
import useMediaQuery from './useMediaQuery';

export const useSideBarStatus = (status) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const dispatch = useDispatch();
  const isSideBarOpen = useSelector((state) => state.sideBar.isSideBarOpen);
  const prevSideBarStatus = isSideBarOpen;

  useEffect(() => {
    if (isMobile) return;

    if (isSideBarOpen !== status) {
      switch (status) {
        case 'show':
          if (!isSideBarOpen) {
            dispatch(openSideBar());
          }
          break;
        case 'hide':
          if (isSideBarOpen) {
            dispatch(closeSideBar());
          }
          break;
        default:
        // return isSideBarOpen;
      }
    }

    return () => {
      if (prevSideBarStatus) dispatch(openSideBar());
      else dispatch(closeSideBar());
    };
  }, []);

  return isSideBarOpen;
};
