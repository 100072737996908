export const shadow = {
  xsm: '0 1px 2px rgba(0, 0, 0, 16%)',
  sm: '0 2px 3px rgba(0, 0, 0, 30%)',
  md: '0 1px 3px rgba(0, 0, 0, 16%)',
  lg: '0 3px 6px rgba(0, 0, 0, 16%)',
  xl: '0 0 10px rgba(0, 0, 0, 20%)',
  avatar: '0 5px 5px rgba(0, 0, 0, 15%)',
  search: '2px 0 3px rgba(0, 0, 0, 30%)',
  negative: '0 -2px 3px rgba(0, 0, 0, 30%)',
  context: '0 1px 3px rgba(15, 29, 91, 50%)',
  tooltip: '0 0 3px #0f1d5b26, 0 4px 10px #0f1d5b4d',
};
