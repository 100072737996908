/*
  available props:
    - hide:       {boolean}:         hides respective column in the table
    - hideSort:   {boolean}:         prevents field from sort
    - hideFilter: {boolean}:         prevents field from filter
    - count:      {boolean}:         accounts field into table TotalsRow
    - display:    {function}:        function providing label (or other display form) dependent on value
    - options:    {function, array}: function providing (or) the array of unique value objects - [{value, label}, ...]
    - default:    {any}:             default value from the array of options
*/

export const clientHeaders = [
  {
    id: 'cardNumber',
    level: 2,
    text: 'Kortelės numeris',
    type: 'search',
    inputType: 'text',
    minWidth: 75,
  },
  {
    id: 'cardDate',
    level: 2,
    text: 'Kortelės viršelio data',
    type: 'calendar',
    minWidth: 123,
  },
  {
    id: 'clientId',
    level: 2,
    text: 'Kliento ID',
    type: 'search',
    inputType: 'text',
    minWidth: 83,
  },
  {
    id: 'registerDate',
    level: 1,
    text: 'Registracijos data',
    type: 'calendar',
    // subType: 'range',
    inputType: 'date',
    justify: 'justify',
    filterType: 'range',
  },
  {
    id: 'avatar',
    level: 1,
    text: 'Foto',
    type: 'avatar',
    // inputType: 'text',
    justify: 'center',
    hideFilter: true,
    hideSort: true,
  },
  {
    id: 'firstName',
    level: 1,
    text: 'Vardas',
    type: 'search',
    inputType: 'text',
    minWidth: 75,
    width: 100,
    count: true,
  },
  {
    id: 'lastName',
    level: 1,
    text: 'Pavardė',
    type: 'search',
    inputType: 'text',
    minWidth: 125,
    width: 150,
    count: true,
  },
  {
    id: 'dob',
    level: 1,
    text: 'Gimimo data',
    type: 'calendar',
    inputType: 'date',
    justify: 'justify',
    filterType: 'range',
  },
  {
    id: 'age',
    level: 1,
    text: 'Amžius',
    type: 'age',
    // subType: 'range',
    // inputType: 'number',
    minWidth: 75,
    width: 75,
    filterType: 'range',
  },
  {
    id: 'personalId',
    level: 2,
    text: 'Asmens kodas',
    type: 'search',
    inputType: 'number',
    minWidth: 90,
  },
  {
    id: 'phone1',
    level: 1,
    text: 'Mobilus tel. nr.',
    type: 'search',
    inputType: 'tel',
    minWidth: 150,
    width: 150,
  },
  {
    id: 'phone2',
    level: 1,
    text: 'Telefono nr.',
    type: 'search',
    inputType: 'tel',
    minWidth: 150,
    width: 150,
  },
  {
    id: 'email',
    level: 1,
    text: 'El. paštas',
    type: 'search',
    inputType: 'text',
    count: true,
    // minWidth: 225,
    // width: 225,
  },
  {
    id: 'gender',
    level: 2,
    text: 'Lytis',
    type: 'select',
    minWidth: 85,
    options: [
      { value: 'Vyras', label: 'Vyras' },
      { value: 'Moteris', label: 'Moteris' },
    ],
  },
  {
    id: 'address',
    level: 2,
    text: 'Adresas',
    type: 'search',
    inputType: 'text',
    minWidth: 156,
  },
  {
    id: 'city',
    level: 1,
    text: 'Miestas',
    type: 'select',
    count: true,
    minWidth: 140,
    width: 140,
  },
  {
    id: 'country',
    level: 1,
    text: 'Šalis',
    type: 'select',
    count: true,
    minWidth: 150,
    width: 150,
  },
  {
    id: 'findOut',
    level: 2,
    text: 'Apie mūsų įstaigą sužinojo',
    type: 'select',
    minWidth: 169,
    options: [
      { value: 'Soc. tinklai', label: 'Soc. tinklai' },
      { value: 'Draugai', label: 'Draugai' },
      { value: 'Reklama', label: 'Reklama' },
    ],
  },
  {
    id: 'discountProducts',
    level: 2,
    text: 'Nuolaidų grupė PREKĖMS',
    type: 'select',
    count: true,
    minWidth: 169,
    options: [
      { value: 10, label: '10%' },
      { value: 20, label: '20%' },
      { value: 25, label: '25%' },
      { value: 50, label: '50%' },
      { value: 80, label: '80%' },
    ],
  },
  {
    id: 'discountServices',
    level: 2,
    text: 'Nuolaidų grupė PASLAUGOMS',
    type: 'select',
    minWidth: 182,
    count: true,
    options: [
      { value: 10, label: '10%' },
      { value: 20, label: '20%' },
      { value: 25, label: '25%' },
      { value: 50, label: '50%' },
      { value: 80, label: '80%' },
    ],
  },
  {
    id: 'group1',
    level: 2,
    text: 'Klientų grupė 1',
    type: 'select',
    minWidth: 139,
    count: true,
    options: [
      { value: 'Vaikai', label: 'Vaikai' },
      { value: 'Jaunimas iki 18m.', label: 'Jaunimas iki 18m.' },
      { value: 'Jaunimas iki 26m.', label: 'Jaunimas iki 26m.' },
      { value: 'Vidutinio amžiaus', label: 'Vidutinio amžiaus' },
      { value: 'Senjorai', label: 'Senjorai' },
    ],
  },
  {
    id: 'group2',
    level: 2,
    text: 'Klientų grupė 2',
    type: 'select',
    minWidth: 139,
    count: true,
    options: [
      { value: 'Vaikai', label: 'Vaikai' },
      { value: 'Jaunimas iki 18m.', label: 'Jaunimas iki 18m.' },
      { value: 'Jaunimas iki 26m.', label: 'Jaunimas iki 26m.' },
      { value: 'Vidutinio amžiaus', label: 'Vidutinio amžiaus' },
      { value: 'Senjorai', label: 'Senjorai' },
    ],
  },
  {
    id: 'group3',
    level: 2,
    text: 'Klientų grupė 3',
    type: 'select',
    minWidth: 139,
    count: true,
    options: [
      { value: 'Vaikai', label: 'Vaikai' },
      { value: 'Jaunimas iki 18m.', label: 'Jaunimas iki 18m.' },
      { value: 'Jaunimas iki 26m.', label: 'Jaunimas iki 26m.' },
      { value: 'Vidutinio amžiaus', label: 'Vidutinio amžiaus' },
      { value: 'Senjorai', label: 'Senjorai' },
    ],
  },
  {
    id: 'representative',
    level: 2,
    text: 'Paciento atstovas',
    type: 'search',
    inputType: 'text',
    minWidth: 192,
    count: true,
  },
  {
    id: 'lastVisited',
    level: 2,
    text: 'Paskutinio apsilankymo data',
    type: 'calendar',
    minWidth: 126,
  },
  {
    id: 'acceptAds',
    level: 2,
    text: 'Sutinka gauti reklamas',
    type: 'switch',
    inputType: 'text',
    minWidth: 100,
    count: true,
    options: [
      { value: true, label: 'Taip' },
      { value: false, label: 'Ne' },
    ],
  },
  {
    id: 'cardState',
    level: 2,
    text: 'Kortelės būsena',
    type: 'search',
    inputType: 'text',
    minWidth: 100,
    count: true,
    options: [
      { value: true, label: 'true' },
      { value: false, label: 'false' },
    ],
  },
  {
    id: 'inActive',
    level: 1,
    text: 'Aktyvumas',
    type: 'switch',
    inputType: 'text',
    minWidth: 105,
    width: 105,
    options: [
      { value: false, label: 'Aktyvus' },
      { value: true, label: 'Neaktyvus' },
    ],
    default: false,
  },
  {
    id: 'memo',
    level: 1,
    text: 'Pastaba',
    type: 'search',
    inputType: 'text',
    minWidth: 75,
    width: 100,
    hide: true,
  },
];
