// style
import * as S from './EmployeesSelector.styled';

// icons
import { Pencil } from 'icons/dynamic';

// components
import ProfessionalSelectChild from 'components/DialogLayout/CustomSelect/children/ProfessionalSelectChild/ProfessionalSelectChild';

// hooks
import useTranslate from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

export const EmployeesSelector = ({
  employee,
  wantedEmployees,
  toggleWantedEmployeesSelection,
}) => {
  const tr = useTranslate().use().global;

  return (
    <S.EmployeesSelector>
      <div className="row">
        <div className="row-title">
          <span>{tr['specialist']}:</span>
        </div>
        <div className="row-content">
          {employee ? (
            <ProfessionalSelectChild
              darkTheme
              currentProfessional={employee}
              className="single"
            />
          ) : (
            <S.EmptyEmployee>
              <span>{tr['select-a-specialist-in-the-calendar']}</span>
            </S.EmptyEmployee>
          )}
        </div>
      </div>
      <div className="row">
        <div className="row-title">
          <span>{tr['preferred-specialists']}:</span>
          <Pencil
            color={
              toggleWantedEmployeesSelection
                ? colors.bg_Primary
                : colors.filterGrey
            }
            style={{
              cursor: toggleWantedEmployeesSelection ? 'pointer' : 'default',
            }}
            onClick={toggleWantedEmployeesSelection}
            width={16}
            height={16}
            bold
          />
        </div>
        {!!wantedEmployees?.length && (
          <div className="row-content multiple">
            {wantedEmployees.map((employee, i) => (
              <ProfessionalSelectChild
                key={i}
                darkTheme
                currentProfessional={employee}
              />
            ))}
          </div>
        )}
      </div>
    </S.EmployeesSelector>
  );
};
