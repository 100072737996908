import React from 'react';

// Style
import * as S from './Selections.styled';

const Selection = ({ svg, text, action, bg }) => {
  return (
    <S.StyledSelection bg={bg} onClick={action}>
      <S.Wrapper>
        <div>{svg}</div>
        <span>{text}</span>
      </S.Wrapper>
    </S.StyledSelection>
  );
};

export default Selection;
