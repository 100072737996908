import PropTypes from 'prop-types';

// style
import * as S from './OrderDetailsFooter.styled';

// components
import OrderDetailsFooterCell from './OrderDetailsFooterCell/OrderDetailsFooterCell';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useNumber from 'hooks/useNumber';
import { minutesToHoursAndMinutes } from 'common/helpers/timeCalculations';

const OrderDetailsFooter = ({ footerData, setModal, preventOpenModal }) => {
  const tr = useTranslate().use().global;
  const { formatPrice } = useNumber({ price: true });

  const getTimeFromMinuts = (minutes) => {
    const time = minutesToHoursAndMinutes(minutes);

    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    return `${padToTwoDigits(time[0])}:${padToTwoDigits(time[1])}`;
  };

  return (
    <S.OrderFooter>
      <div className="orderFooterInfo">
        <OrderDetailsFooterCell
          topText={formatPrice(footerData?.discount)}
          bottomText={tr.discount}
          {...(!preventOpenModal && {
            action: () => {
              if (setModal?.setModalSwitch) {
                setModal.setModalSwitch('footer-discount');
              }

              setModal?.toggle();
            },
          })}
          opacityBg
        />
        <OrderDetailsFooterCell
          topText={formatPrice(footerData?.totalPrice)}
          bottomText={tr.priceWithoutDiscount}
        />
        <OrderDetailsFooterCell
          topText={getTimeFromMinuts(footerData?.totalDuration)}
          bottomText="Bendra trukme"
        />
        <OrderDetailsFooterCell
          topText={formatPrice(footerData?.priceToPay)}
          bottomText={tr.pay}
          background
        />
        <OrderDetailsFooterCell
          topText={formatPrice(footerData?.totalVat)}
          bottomText={tr.vat}
        />
      </div>
    </S.OrderFooter>
  );
};

OrderDetailsFooter.propTypes = {
  footerData: PropTypes.object,
  setModal: PropTypes.object,
};

export default OrderDetailsFooter;
