import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const FiltersRow = styled.tr`
  height: 40px;
  font-family: ${fontFamilies.family_Secondary};
  vertical-align: inherit !important;

  th {
    background-color: ${colors.bg_grey_1};
    border-color: ${colors.table_arrows_Primary};
    color: ${colors.filterGrey};
    padding: 0 5px;
    vertical-align: middle;

    :last-of-type {
      border-right: 1px solid ${colors.table_arrows_Primary};
    }
  }
`;
