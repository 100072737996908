import { Navigate, Route, Routes } from 'react-router';

// pages
import Projects from 'pages/Projects/Projects';
import MySettings from 'pages/MySettings/MySettings';
import ContactUs from 'pages/ContactUs/ContactUs';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions/TermsAndConditions';

// hooks
import { useRestoreAppUserCredentials } from 'hooks/useRestoreAppUserCredentials';

export const UserSpace = () => {
  const { user } = useRestoreAppUserCredentials();

  return !!user ? (
    <Routes>
      <Route index element={<Navigate to="my-account" />} />

      <Route path="my-account">
        <Route index element={<Navigate to="my-projects" />} />

        <Route path="my-projects" element={<Projects />} />
        <Route path="my-settings" element={<MySettings />} />
      </Route>

      <Route path="serve-pro">
        <Route index element={<Navigate to="contact-us" />} />

        <Route path="contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      </Route>
    </Routes>
  ) : (
    <></>
  );
};

export default UserSpace;
