import styled from 'styled-components/macro';
import { colors } from '../../../common/colors/colors';

export const FunctionContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    width: 270px;

    display: flex;
    align-items: center;
    background-color: ${colors.overlay_white};

    margin-bottom: 10px;
  }
`;
