import React from 'react';
import { colors } from '../common/colors/colors';

const SortingArrows = ({ direction, onClick }) => {
  // console.log('direction: ', direction);
  // direction = 'asc';
  return (
    <div
      className="sorting-arrows"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {(direction === 'desc' || !direction) && (
        <div style={{ height: '10px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.134"
            height="6.179"
            viewBox="0 0 7.134 6.179"
          >
            <path
              id="Path_91"
              data-name="Path 91"
              d="M1219.776,3028.41l-3.567,6.179h7.134Z"
              transform="translate(-1216.209 -3028.41)"
              fill={colors.table_head_borders_and_arrows_Primary}
            />
          </svg>
        </div>
      )}
      {((direction === 'asc' || !direction) && (
        <div style={{ height: '10px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.134"
            height="6.179"
            viewBox="0 0 7.134 6.179"
          >
            <path
              id="Path_92"
              data-name="Path 92"
              d="M1219.776,3087.679l3.567-6.179h-7.134Z"
              transform="translate(-1216.209 -3081.5)"
              fill={colors.table_head_borders_and_arrows_Primary}
            />
          </svg>
        </div>
      )) || <div style={{ height: '10px' }}></div>}
    </div>
  );
};

export default SortingArrows;
