import {
  datesAreOnSameDay,
  formatToIsoWithoutMilliseconds,
  getDurationInMinutes,
  toIsoString,
} from './dateOperations';
import { addMinutes } from './timeCalculations';

export const checkForOverlapingOrder = (allOrders, order, newColumn) => {
  const employeeId = newColumn?.employeeId || order.employeeId;
  const startDate = toIsoString(
    new Date(newColumn?.date || order.startTimeUtc),
  ).split('T')[0];

  const foundColumn = allOrders.find((el) => el.employee.id === employeeId);

  if (foundColumn) {
    const foundDay = foundColumn.days.find((day) => day.day === startDate);

    if (foundDay) {
      const orders = foundDay.orders.sort(
        (a, b) =>
          new Date(a.startTimeUtc).getTime() -
          new Date(b.startTimeUtc).getTime(),
      );

      for (let i = 0; i < orders.length; i++) {
        const currentInterval = orders[i];

        // Check for overlap
        if (order.id !== currentInterval.id) {
          if (
            new Date(order.startTimeUtc).getTime() <
              new Date(currentInterval.startTimeUtc).getTime() +
                currentInterval.durationInMinutes * 60000 &&
            new Date(order.startTimeUtc).getTime() +
              order.durationInMinutes * 60000 >
              new Date(currentInterval.startTimeUtc).getTime()
          ) {
            return true; // Found overlapping interval
          }
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }

  return false; // No overlapping intervals found
};

export const getColumnOrders = (allOrders, employeeId, startDate) => {
  const foundColumn = allOrders.find((el) => el.employee.id === employeeId);

  if (foundColumn) {
    const foundDay = foundColumn.days.find((day) => day.day === startDate);

    if (foundDay) {
      return foundDay.orders;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

function splitOrderDuration(orderServices, newOrderDurationInMinutes) {
  // We do not want to modify original object, thus creating clones
  var osClone = structuredClone(orderServices);
  if (osClone.length <= 0) {
    return osClone;
  }

  var totalDuration = newOrderDurationInMinutes;
  if (totalDuration <= 0) {
    osClone.forEach(function (element, index) {
      // Skip Materials (not timed services)
      if (!(element.unitDurationInMinutes == null)) {
        element.totalDurationInMinutes = 0;
      }
    });
    return osClone;
  }

  // Set to wanted duration first
  var totalWantedDuration = 0;
  osClone.forEach(function (element, index) {
    // Skip Materials (not timed services)
    if (!(element.unitDurationInMinutes == null)) {
      element.totalDurationInMinutes = getNormServiceDurationInMinutes(
        element.unitDurationInMinutes,
        element.quantity,
      );
      totalWantedDuration += element.totalDurationInMinutes;
    }
  });

  if (totalWantedDuration === totalDuration) {
    // Cool, nothing to do!
    return osClone;
  }
  if (totalWantedDuration === 0) {
    // No timed services that can accept duration, nothing to do
    var idxOfFirstTimedService = osClone.findIndex(
      (os, i) =>
        !(os.unitDurationInMinutes == null) && isServiceSellableItem(os),
    );
    if (idxOfFirstTimedService >= 0) {
      osClone[idxOfFirstTimedService].totalDurationInMinutes = totalDuration;
      return osClone;
    }

    // No timed services that can accept duration.
    // Order will have duration, but splits won't. Such order will never overlap with anything.
    return osClone;
  }

  var addTime = totalDuration > totalWantedDuration;
  var offsetLeft = addTime
    ? totalDuration - totalWantedDuration
    : totalWantedDuration - totalDuration;
  var percentToChange =
    offsetLeft / (addTime ? totalWantedDuration : totalDuration);
  var reduceTimeByDivision = !addTime && percentToChange > 0;
  if (reduceTimeByDivision) {
    percentToChange = totalWantedDuration / totalDuration;
  }

  osClone.forEach(function (element, index) {
    if (offsetLeft > 0 && hasServiceDuration(element)) {
      var offset = 0;
      if (reduceTimeByDivision) {
        var newDuration = Math.max(
          0,
          Math.floor(element.totalDurationInMinutes / percentToChange),
        );
        if (newDuration > element.totalDurationInMinutes) {
          // Should probably never get in here..
          newDuration = 0;
        }
        offset = element.totalDurationInMinutes - newDuration;
      } else {
        offset = getNormServiceDurationInMinutes(
          element.totalDurationInMinutes,
          percentToChange,
        );
      }

      offset = Math.min(offset, offsetLeft);
      offsetLeft -= offset;
      element.totalDurationInMinutes = addTime
        ? element.totalDurationInMinutes + offset
        : element.totalDurationInMinutes - offset;
    }
  });

  return osClone;
}

function getNormServiceDurationInMinutes(unitDuration, quantity) {
  var result = Math.ceil(unitDuration * quantity);
  return result;
}

export function createInterval(startUtc, intervalDurationInMinutes) {
  var result = {
    startTimeUtc: startUtc,
    endTimeUtc: addMinutes(startUtc, intervalDurationInMinutes),
    durationInMinutes: intervalDurationInMinutes,
  };
  return result;
}

function getServiceSplitsByBreak(orderServices) {
  // !!! Algorithm below assumes there is no Breaks at the start !!!
  // Saved order will not have breaks at the beginning, thus assumption is safe.
  var intervals = [];
  var startIdx = 0;
  do {
    var idx = orderServices.findIndex(
      (os, i) => i >= startIdx && isServiceBreak(os) && hasServiceDuration(os),
    );

    var rowCount = 0;
    if (idx < 0) {
      rowCount = orderServices.length - startIdx;
    } else {
      var neededServices = takeWhileCount(
        skipElements(orderServices, idx + 1),
        (os) => !(hasServiceDuration(os) && isServiceSellableItem(os)),
      );
      rowCount = idx + 1 - startIdx + neededServices;
    }

    var record = { skip: startIdx, take: rowCount };
    startIdx += rowCount;

    intervals.push(record);
  } while (startIdx < orderServices.length);

  return intervals;
}

function skipElements(array, n) {
  return array.filter((el, i) => {
    return i > n - 1;
  });
}

function takeWhileCount(array, predicate) {
  var index = array.findIndex((el, i) => !predicate(el, i));
  return index >= 0 ? index : array.length;
}

function isServiceBreak(orderService) {
  return orderService.serviceId === 1;
}

function isServiceDiscountCoupon(orderService) {
  return orderService.serviceId === 2 || orderService.unitPriceWithVat < 0;
}

function isServiceSellableItem(orderService) {
  return (
    !isServiceBreak(orderService) && !isServiceDiscountCoupon(orderService)
  );
}

function hasServiceDuration(orderService) {
  return (
    !(orderService.totalDurationInMinutes == null) &&
    orderService.totalDurationInMinutes > 0
  );
}

// function addMinutes(startUtc, durationInMinutes) {
// return moment(startUtc).add(durationInMinutes, 'm').toDate();
// }

export function getOrderTimeIntervals(order, newOrderDurationInMinutes) {
  if (newOrderDurationInMinutes < 0) {
    throw new Error('Order duration cannot be negative');
  }

  var intervals = [];
  var startTimeUtc = new Date(order.startTimeUtc);
  if (order.employeeId == null) {
    // Order does not take time because it is on 'Waiting orders column'.
    intervals.push(createInterval(startTimeUtc, 0));
    return intervals;
  }

  if (order.services == null) {
    // We have splitted services instead of full list (depends on GET parameter),
    // algorithm will not work
    throw new Error(
      'Need full list of services, cannot use splits as they have Breaks removed',
    );
  }

  if (newOrderDurationInMinutes === 0 || order.services.length <= 0) {
    // Order does not take time -OR- no services to split time
    intervals.push(createInterval(startTimeUtc, newOrderDurationInMinutes));
    return intervals;
  }

  var adjustedOrderServices = splitOrderDuration(
    order.services,
    newOrderDurationInMinutes,
  );
  var splitsByBreak = getServiceSplitsByBreak(adjustedOrderServices);

  var start = startTimeUtc;
  splitsByBreak.forEach(function (element, index) {
    var slice = skipElements(adjustedOrderServices, element.skip).slice(
      0,
      element.take,
    );

    var takenDuration = slice
      .filter((el, i) => {
        return !isServiceBreak(el) && hasServiceDuration(el);
      })
      .map((el) => el.totalDurationInMinutes)
      .reduce((a, b) => a + b, 0);

    var breakDuration = slice
      .filter((el, i) => {
        return isServiceBreak(el) && hasServiceDuration(el);
      })
      .map((el) => el.totalDurationInMinutes)
      .reduce((a, b) => a + b, 0);

    intervals.push(createInterval(start, takenDuration));
    start = addMinutes(start, takenDuration + breakDuration);
  });

  return intervals;
}

// $(document).ready(function () {
// Time intervals with given new total order duration
// var newIntervals = getOrderTimeIntervals(order, 20);
// console.log(newIntervals);

// Relevant intervals taken by other orders (excluding the current order that is being extended/shrinked IF the same column is kept)
var otherTakenIntervals = [];
otherTakenIntervals.push(createInterval(new Date('2023-09-10T17:00:00Z'), 10));
otherTakenIntervals.push(createInterval(new Date('2023-09-10T17:30:00Z'), 30));
otherTakenIntervals.push(createInterval(new Date('2023-09-10T17:35:00Z'), 15));
otherTakenIntervals.push(createInterval(new Date('2023-09-10T17:17:00Z'), 1));
//otherTakenIntervals.push(createInterval(new Date("2021-10-31T00:30:00Z"), 30));
//otherTakenIntervals.push(createInterval(new Date("2021-10-31T01:00:00Z"), 30));
//otherTakenIntervals.push(createInterval(new Date("2021-10-31T00:55:00Z"), 35));
//otherTakenIntervals.push(createInterval(new Date("2021-10-31T00:05:00Z"), 1));

// var willOrderOverlap = newIntervals.some((interval) => {
//   return (
//     interval.durationInMinutes > 0 &&
//     otherTakenIntervals.some((el) => {
//       return (
//         el.startTimeUtc < interval.endTimeUtc &&
//         el.endTimeUtc > interval.startTimeUtc &&
//         el.durationInMinutes > 0
//       );
//     })
//   );
// });

export function willOrderOverlap(orders, newIntervals) {
  const columnIntervals = [];

  orders.forEach((order) => {
    order.splits.forEach((split) => {
      columnIntervals.push(
        createInterval(new Date(split.startTimeUtc), split.durationInMinutes),
      );
    });
  });

  if (!columnIntervals.length) return false;

  return newIntervals.some((interval) => {
    return (
      interval.durationInMinutes > 0 &&
      columnIntervals.some((el) => {
        return (
          el.startTimeUtc.getTime() < interval.endTimeUtc.getTime() &&
          el.endTimeUtc.getTime() > interval.startTimeUtc.getTime() &&
          el.durationInMinutes > 0
        );
      })
    );
  });
}

const getHeightPercentage = (hour, minutes, hourInterval) => {
  const minFraction = hour + minutes / 60;
  return (minFraction * 100) / hourInterval;
};

export const getStartDateByPosition = (top, date, hourInterval, startHour) => {
  const fixedTop = top < 0 ? 0 : top;

  const duration = (hourInterval * fixedTop) / 100;

  const startDate = new Date(date);
  startDate.setHours(startHour, 0, 0, 0);
  startDate.setSeconds(+duration.toFixed(2) * 60 * 60);

  return startDate.toISOString();
};

export const calculatePositions = (appointment, hourInterval, startHour) => {
  const appointmentStartHour = new Date(appointment?.startTimeUtc).getHours();

  const appointmentStartMinutes = new Date(
    appointment?.startTimeUtc,
  ).getMinutes();

  // top position
  const diffFromStartingHour = appointmentStartHour - startHour;

  const topPosition = getHeightPercentage(
    diffFromStartingHour,
    appointmentStartMinutes,
    hourInterval,
  );

  // const lastInSplit = appointment?.splits.at(-1);

  // const endDate = addMinutes(
  //   new Date(appointment.startTimeUtc),
  //   lastInSplit.durationInMinutes
  // );

  const endDate = new Date(
    new Date(appointment.startTimeUtc).getTime() +
      appointment.durationInMinutes * 60000,
  );

  // height
  const duration =
    (endDate.getTime() - new Date(appointment.startTimeUtc).getTime()) / 60000;

  const durationHours = Math.floor(duration / 60);
  const durationMinutes = Math.ceil(duration % 60);

  const height = getHeightPercentage(
    durationHours,
    durationMinutes,
    hourInterval,
  );
  return { topPosition, height };
};

export const getAppointmentPosition = (
  order,
  top,
  employeeId,
  columnDate,
  hourInterval,
  startHour,
) => {
  const foundStart = new Date(order.startTimeUtc);

  const startDate = !isNaN(top)
    ? new Date(getStartDateByPosition(top, foundStart, hourInterval, startHour)) // FIXME:
    : foundStart;

  const updatedOrder = structuredClone(order);

  const sameDate = datesAreOnSameDay(foundStart, new Date(columnDate));

  sameDate &&
    (updatedOrder.startTimeUtc = formatToIsoWithoutMilliseconds(
      new Date(startDate),
    ));

  columnDate.setHours(startDate.getHours(), startDate.getMinutes(), 0, 0);

  const sameEmployee = employeeId === order.employeeId;
  const sameTime =
    new Date(order.startTimeUtc).getTime() === columnDate.getTime();

  if (sameEmployee && sameTime) return; // do not update. its the same
  const tp = calculatePositions(updatedOrder);
  updatedOrder.topPosition = tp.topPosition;

  const newColumn = {
    ...(updatedOrder.employeeId !== employeeId && { employeeId }),
    ...(!sameDate && {
      date: columnDate,
    }),
  };

  const newIntervals = getOrderTimeIntervals(
    updatedOrder,
    updatedOrder.durationInMinutes,
  );

  const updatedSplits = updatedOrder.splits.map((split, i) => {
    return { ...split, ...newIntervals[i] };
  });

  updatedOrder.splits = updatedSplits;

  return { updatedOrder, newColumn };
};

const getEndDateByHeight = (height, startDate, hourInterval) => {
  if (!height) return null;

  const duration = (hourInterval * height) / 100;

  const endDate = new Date(startDate);

  endDate.setHours(endDate.getHours(), endDate.getMinutes(), 0, 0);
  endDate.setSeconds(+duration.toFixed(2) * 60 * 60);

  return endDate.toISOString();
};

export const getAppointmentHeight = (
  order,
  height,
  top,
  hourInterval,
  startHour,
) => {
  if (!height) return;

  const foundStart = new Date(order.startTimeUtc);

  const startDate = !isNaN(top)
    ? new Date(getStartDateByPosition(top, foundStart, hourInterval, startHour))
    : foundStart;

  const endDate = new Date(getEndDateByHeight(height, startDate, hourInterval));
  const duration = getDurationInMinutes(startDate, endDate);

  const sameDuration = order.durationInMinutes === duration;

  if (sameDuration) return;

  const updatedOrder = structuredClone(order);

  updatedOrder.startTimeUtc = formatToIsoWithoutMilliseconds(
    new Date(startDate),
  );
  updatedOrder.durationInMinutes = duration;

  const newIntervals = getOrderTimeIntervals(
    updatedOrder,
    updatedOrder.durationInMinutes,
  );

  const updatedSplits = updatedOrder.splits.map((split, i) => {
    return { ...split, ...newIntervals[i] };
  });

  updatedOrder.splits = updatedSplits;

  return { updatedOrder, newIntervals };
};
