import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setEmployees } from 'redux/actions/calendarViewActions';

// services
import { useGetWorkingEmployees } from 'services/scheduleService';

// hooks
import { getFirstDayOfWeek, toIsoString } from 'common/helpers/dateOperations';

export const useCalenderViews = (params) => {
  const {
    personal,
    sectorId,
    employeeId,
    days,
    dateState,
    past,
    page,
    personalDays,
    showOnlyWorkingDays,
    displayProfessionals,
  } = params;

  const pageHistoryRef = useRef([]);
  const dispatch = useDispatch();

  const getSearchParamsForWorkingEmployees = useCallback(() => {
    let startDay = new Date(dateState);
    let calendarDays = days?.visible;

    if (days?.visible === 7) {
      startDay = getFirstDayOfWeek(dateState);
    }

    if (employeeId) {
      calendarDays = past + 10;

      startDay.setDate(startDay.getDate() - personalDays.past + 0);
      startDay.setDate(startDay.getDate() + (page - 1) * 7);

      if (showOnlyWorkingDays) {
        if (page > 1) {
          calendarDays = 7;
          const lastDay =
            displayProfessionals.professionals[0].days.at(-1)?.day;

          const x = new Date(lastDay);
          x.setDate(x.getDate() + 1);

          startDay.setTime(x.getTime());

          if (pageHistoryRef.current[page]) {
            const prevDate = new Date(pageHistoryRef.current[page - 1]);

            startDay.setTime(prevDate.getTime());
          }
        }
      }
    }

    startDay = toIsoString(startDay).split('T')[0];

    const found = pageHistoryRef.current.find((item) => item === startDay);
    if (!found) {
      pageHistoryRef.current.push(startDay);
    }

    return {
      sectorId,
      startDay,
      ...(showOnlyWorkingDays
        ? { workingDays: calendarDays }
        : { calendarDays }),
      ...(employeeId && { employeeId }),
    };
  }, [
    dateState,
    days?.visible,
    personalDays.past,
    page,
    employeeId,
    sectorId,
    showOnlyWorkingDays,
  ]);

  const employeesBE = useGetWorkingEmployees({
    queryParams: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: personal
        ? !!sectorId && !!days?.visible && !!employeeId
        : !!sectorId && !!days?.visible,
    },
    searchParams: getSearchParamsForWorkingEmployees(),
  });

  // -- side effect;
  useEffect(() => {
    if (employeesBE.isError) {
      console.log('error');
    }
  }, [employeesBE.isError]);

  useEffect(() => {
    if (employeesBE.isSuccess) {
      if (!employeesBE.data?.employees) return;

      dispatch(setEmployees(employeesBE.data.employees));
    }
  }, [employeesBE.data?.employees]);

  useEffect(() => {
    if (employeesBE.isRefetching) {
      dispatch(setEmployees(employeesBE.data.employees));
    }
  }, [employeesBE.isRefetching]);

  useEffect(() => {
    pageHistoryRef.current = [];
  }, [sectorId, showOnlyWorkingDays, dateState]);

  return employeesBE;
};

export default useCalenderViews;
