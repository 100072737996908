import { orderHeaders } from 'models/order';

const orderReducer = (
  state = {
    headers: orderHeaders,
    data: [],
    status: 'idle',
    filterStatus: false,
    expanded: false,
  },
  action,
) => {
  switch (action.type) {
    case 'ORDER_REQUEST':
      return { ...state, status: 'loading', data: [] };
    case 'ORDER_SUCCESS':
    case 'ORDER_UPDATE':
      return {
        ...state,
        status: 'success',
        data: action.payload,
      };
    case 'ORDER_FAIL':
      return {
        ...state,
        status: 'failed',
        data: action.payload,
      };
    case 'ORDER_UPDATE_FILTER_STATUS':
      return { ...state, filterStatus: action.payload };
    case 'ORDER_UPDATE_EXPAND_STATUS':
      return { ...state, expanded: action.payload };
    default:
      return state;
  }
};

export default orderReducer;
