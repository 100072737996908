import { forwardRef, useContext } from 'react';
import { TimePicker } from 'react-time-picker';

// style
import * as S from './InputFieldTime.styled';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

// context
import { UserContext } from 'context/UserContext';

export const InputFieldTime = forwardRef(
  ({ id, value, onChange, disabled, onFocus, isSelected, className }, ref) => {
    const { locale } = useContext(UserContext);

    return (
      <S.InputFieldTime
        ref={ref}
        isSelected={isSelected}
        disabled={disabled}
        hasValue={!!value}
        onFocus={onFocus}
        className={className}
      >
        <TimePicker
          id={id}
          clearIcon={null}
          disableClock
          locale={locale}
          // locale="LT"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          disabled={disabled}
          // onInvalidChange={() => console.log('invalid Change')}
        />
      </S.InputFieldTime>
    );
  },
);

InputFieldTime.displayName = 'InputFieldTime';

export default InputFieldTime;
