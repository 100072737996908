import { useEffect } from 'react';

// style
import * as S from './ServiceItemButton.styled';

// components
import { ContextTree } from 'components/ContextTree/ContextTree';

export const ServiceItemButton = ({
  id,
  icon,
  action,
  color,
  parentId,
  items,
  triggerCallback,
  ...restProps
}) => {
  useEffect(() => {
    return () => {
      triggerCallback && triggerCallback(false);
    };
  }, []);

  return (
    <S.ServiceItemButton
      id={id}
      className="functions"
      onClick={(e) => {
        e.stopPropagation();
        triggerCallback && triggerCallback(true);
        action && action();
      }}
      {...restProps}
    >
      {icon}

      {!action && <ContextTree containerId={id} openOnClick items={items} />}
    </S.ServiceItemButton>
  );
};

export default ServiceItemButton;
