import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import * as S from './NavBarDesktop.styled';

// Components
import UserWidget from 'components/UserWidget/UserWidget';

// context
import { UserContext } from 'context/UserContext';

// hooks and helpers
import { useTopMenu } from 'hooks/useTopMenu';

export const NavBarDesktop = ({ contents }) => {
  const { userAcl, companyId } = useContext(UserContext);

  const mainMenuItems = useTopMenu();

  const menuItems = contents || mainMenuItems;

  return (
    <S.NavBarDesktop>
      <S.NavLinks>
        {menuItems?.map(
          (item, key) =>
            item &&
            userAcl >= item.acl &&
            companyId && (
              <li key={key}>
                <NavLink to={item.route} replace={true}>
                  {item.name}
                </NavLink>
                <S.Active />
              </li>
            ),
        )}
      </S.NavLinks>

      <UserWidget />
    </S.NavBarDesktop>
  );
};

export default NavBarDesktop;
