import { useState, useEffect, useContext, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

// style
import * as S from './ClientsSelect.styled';
import { ContentLayout } from 'components/ContentLayout/ContentLayout.styled';

// icons
import { CreateNewBoldSVG } from 'icons/dynamic';

// components
import ClientListItem from '../CustomSelect/children/ClientListItem/ClientListItem';
import CustomerInfo from 'components/Day Functions/CustomerSearch/CustomerInfo/CustomerInfo';
import TheButton from 'components/Buttons/TheButton';
import DialogLayout from '../DialogLayout';

// contexts
import { CreateOrderContext } from '../../../context/SchedulerCreatingEventContext';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import useFunctionBar from 'hooks/useFunctionBar';
import useModal from 'hooks/useModal';

const ClientsSelect = ({ records, navigation, hasMore }) => {
  const tr = useTranslate().use().global;
  const navigate = useNavigate();
  const location = useLocation();
  const displayRef = useRef();
  const functionBarItems = useFunctionBar('select-client');
  const { isShowing, toggle } = useModal();
  const { client } = useContext(CreateOrderContext);
  const [displayModal, setDisplayModal] = useState(<></>);
  const [modalSelect, setModalSelect] = useState('');
  const createOrder = location.state?.toOrder;

  functionBarItems.find((item) => item.id === 'new-client').action = () =>
    navigate('create-new-client');

  useEffect(() => {
    if (!isShowing) {
      setDisplayModal(undefined);
    }
  }, [isShowing]);

  useEffect(() => {
    switch (modalSelect) {
      case 'client-information':
        setDisplayModal(
          <DialogLayout
            key={'client-information'}
            modal
            getBack={handleCloseAction}
            headerText={
              client.selectedClient.firstName +
              ' ' +
              client.selectedClient.lastName
            }
          >
            <CustomerInfo
              customer={client.selectedClient}
              createOrder={createOrder}
            />
          </DialogLayout>,
        );
        break;
      default:
        return;
    }
  }, [modalSelect]);

  const handleCloseAction = () => {
    setModalSelect(null);
    setDisplayModal(null);
    toggle();
    return;
  };

  const navigateToCreateOrder = () => {
    navigate('/functions/tables/orders/new', {
      state: {
        ...location.state,
        selectedClient: client.selectedClient,
      },
    });
  };

  const isEmoloyeeSelected =
    location?.state?.employee || location?.state?.wantedEmployees?.length;

  return (
    <Routes>
      <Route
        index
        element={
          <S.ClientSelectContainer
            ref={displayRef}
            toOrder={createOrder}
            hasEmployeeSelected={!!isEmoloyeeSelected}
          >
            <div className="clientSelectWrapper">
              <div className="client-list">
                <div className="container">
                  <h3>{tr['clientSearch']}</h3>
                </div>
                {/* {createOrder && (
                  <ClientListItem
                    anonymous
                    select={() => {
                      client.setSelectedClient({
                        id: null,
                        firstName: tr['anonymous'],
                        lastName: '',
                      });
                      navigate('create-new-order', {
                        state: location.state,
                      });
                    }}
                  />
                )} */}
                {records.map((record, i) => (
                  <ClientListItem
                    navigation={navigation}
                    key={i}
                    client={record}
                    isSelected={client.selectedClient?.id === record.id}
                    select={() =>
                      isEmoloyeeSelected
                        ? client.setSelectedClient(record)
                        : navigation(record)
                    }
                  />
                ))}
                {hasMore && <span className="has-more">{tr['has-more']}</span>}
              </div>
            </div>
            {isShowing && <ContentLayout hideFab>{displayModal}</ContentLayout>}
            {isEmoloyeeSelected && (
              <div className="footer">
                <TheButton
                  title={tr['create-order']}
                  icon={<CreateNewBoldSVG />}
                  disabled={!client.selectedClient?.id}
                  action={navigateToCreateOrder}
                />
              </div>
            )}
          </S.ClientSelectContainer>
        }
      />
    </Routes>
  );
};

export default ClientsSelect;
