import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const Message = styled.div`
  background-color: ${({ severity }) =>
    (!severity || severity === 'info') && colors.info};
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  gap: 10px;
  padding: 10px;

  .severity {
    line-height: 0;
  }

  .contents {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
`;
