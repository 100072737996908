import { cloneElement } from 'react';

// style
import * as S from './Badge.styled';

export const Badge = ({ icon, color, xs, sm, md, ...rest }) => {
  let size = 30;
  let iconSize = 16;

  if (xs) {
    size = 12;
    iconSize = 8;
  } else if (sm) {
    size = 16;
    iconSize = 8;
  } else if (md) {
    size = 20;
    iconSize = 10;
  }

  const Icon = icon && cloneElement(icon, { color: '#fff', black: true });

  return (
    <S.Badge size={size} iconSize={iconSize} color={color} {...rest}>
      {Icon}
    </S.Badge>
  );
};

export default Badge;
