import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { dropdownRollIn } from 'common/styles/animations';

export const Container = styled.div`
  color: ${colors.footer_Secondary};
  display: flex;
  flex: 1;
  font-family: var(--font-family-secondary);
  font-size: 1.125rem;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: max-content;

  @media (max-width: 960px) {
    font-size: 0.875rem;
  }
`;

export const ControlSection = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-size: 1.125rem;
  gap: 18px;

  @media (max-width: 960px) {
    font-size: 0.875rem;
    gap: 14px;
  }

  .arrow {
    cursor: pointer;
  }

  .first-page {
    transform: rotate(180deg);
  }

  .previus-page {
    transform: rotate(180deg);
  }

  .page-indication {
    font-weight: bold;
  }
`;

export const InfoSection = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 40px;
  gap: 10px;

  @media (min-width: 961px) {
    #select-page-qty {
      z-index: 2;
    }
  }

  @media (max-width: 960px) {
    margin-left: auto;

    .select__menu {
      z-index: 0;
    }
  }

  .select {
    height: 36px;
    /* width: 100px; */
    width: fit-content;
    /* z-index: 4; */

    @media (max-width: 960px) {
      height: 26px;
      /* width: 85px; */
    }

    span {
      width: 0;
    }

    &__control {
      /* border: none; */
      /* border-radius: 18px; */
      background-color: transparent;
      box-shadow: none;
      padding: 2px 10px;
      min-height: unset;
      width: 100%;
      height: 100%;

      @media (max-width: 960px) {
        /* padding: 1px 5px; */
        padding: 0 5px;
      }
    }

    &__value-container {
      font-weight: bold;
      padding-left: 10px;

      @media (max-width: 960px) {
        padding: 0 5px;
      }
    }

    &__input-container {
      /* margin-top: 1px;
      margin-bottom: 1px; */
      @media (max-width: 960px) {
      }
    }

    &__indicator {
      padding: 2px;
      cursor: pointer;
      color: ${colors.text_Secondary}; // for dropdown icon to be always blue

      &:hover {
        color: ${colors.text_Secondary};
      }
    }

    &__menu {
      margin-top: -1px;
      padding-top: 2px;
      border: 1px solid currentColor;
      border-top: none;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      transform-origin: top;
      animation: ${dropdownRollIn} 0.2s ease-in-out;

      @media (max-width: 960px) {
        /* margin-top: -1px; */
      }
    }

    &__menu-list {
      padding-top: 0;
      padding-bottom: 10px;
    }

    &__option {
      padding-left: 20px;

      @media (max-width: 960px) {
        padding-left: 10px;
      }
    }
  }
`;
