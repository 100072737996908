const tempData = [
  {
    text: 'Vardenis Pavardenis',
    fieldName: 'Vardenis Pavardenis',
    id: 1,
  },
  {
    text: 'Aardenis Pavardenis',
    fieldName: 'Aardenis Pavardenis',
    id: 2,
  },
  {
    text: 'Rolandas Pavardenis',
    fieldName: 'Rolandas Pavardenis',
    id: 3,
  },
  {
    text: 'Tomas Pavardenis',
    fieldName: 'Tomas Pavardenis',
    id: 4,
  },
  {
    text: 'Gintaras Pavardenis',
    fieldName: 'Gintaras Pavardenis',
    id: 5,
  },
  {
    text: 'Karolis Pavardenis',
    fieldName: 'Karolis Pavardenis',
    id: 6,
  },
  {
    text: 'Abardenis Pavardenis',
    fieldName: 'Abardenis Pavardenis',
    id: 7,
  },
  {
    text: 'Acardenis Pavardenis',
    fieldName: 'Acardenis Pavardenis',
    id: 8,
  },
];

const specialistsReducer = (state = { specialists: tempData }, action) => {
  switch (action.type) {
    case 'SPECIALISTS_REQUEST':
      return { loading: true, specialists: [] };
    case 'SPECIALISTS_SUCCESS':
      return { loading: false, specialists: action.payload };
    case 'SPECIALISTS_UPDATE':
      return { loading: false, specialists: action.payload };
    case 'SPECIALISTS_FAIL':
      return { loading: false, specialists: action.payload };
    default:
      return state;
  }
};

export default specialistsReducer;
