import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// style
import * as S from './CategoryDetails.styled';

// components
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { GeneralInfo } from '../GeneralInfo/GeneralInfo';
import { SectorSelector } from '../SectorSelector/SectorSelector';
import { ServiceGeneralData } from '../ServiceDetails/ServiceGeneralData/ServiceGeneralData';
import { Overlay } from 'components/Overlay/Overlay';
import { Loader } from 'components/Loader/Loader';

// hooks
import { useTranslate } from 'hooks/useTranslate';
import { useServicesUpdate } from 'services/serviceService';
import { useFormState } from 'hooks/useFormState';
import { singularize } from 'common/helpers/stringOperations';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { areArraysIdentical } from 'common/helpers/arrayFunctions';
import { areObjectsIdentical } from 'common/helpers/objectFunctions';
import {
  getAncestors,
  getItem,
  getPath,
  hasSiblingsNamedEqually,
  hydrateServiceData,
  updateTree,
} from 'common/helpers/serviceFunctions';

export const CategoryDetails = ({
  treeData,
  setCategories,
  activeItem,
  setActiveItem,
  setButtons,
  sectorId,
  sectors,
}) => {
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // data load handler
  const data = getItem(treeData, activeItem.id, activeItem.type);
  // data save handler
  const {
    isSuccess: isMutationSuccess,
    isError: isMutationError,
    error: mutationError,
    isLoading: isMutationLoading,
    data: db,
    mutate,
  } = useServicesUpdate(data?.type, { id: data?.id });

  // identifiers
  const isNew = data?.id === 'new';
  const isReservedCategory =
    ['category', 'group'].includes(activeItem.type) && activeItem.id === 0;

  // state
  const [disabled, setDisabled] = useState(!isNew);
  const [changed, setChanged] = useState(false);

  // configure required fields
  const setupFields = useCallback(() => {
    return [
      {
        id: 'name',
        level: 1,
        type: 'search',
        default: data?.name,
      },
      {
        id: 'assignedToSectorIds',
        level: 1,
        type: 'array',
        default: {
          value: data?.assignedToSectorIds || [
            ...(sectorId ? [+sectorId] : []),
          ],
          label: data?.assignedToSectorIds || [
            ...(sectorId ? [+sectorId] : []),
          ],
        },
      },
      {
        id: 'isActive',
        level: 1,
        type: 'switch',
        options: [
          { value: true, label: tr['active'] },
          { value: false, label: tr['inactive'] },
        ],
        default: data?.isActive
          ? { value: true, label: tr['active'] }
          : { value: false, label: tr['inactive'] },
      },
    ];
  }, [data, tr, sectorId]);

  // create form state
  const { state, options, resetState } = useFormState(setupFields());

  // check if there are actual changes in state and set identifier accordingly
  useEffect(() => {
    if (!disabled && data) {
      const changed = Object.entries(state).some((entry) => {
        if (Array.isArray(entry[1].value?.value)) {
          return !areArraysIdentical(entry[1].value?.value, data[entry[0]]);
        } else if (
          typeof entry[1].value?.value === 'object' &&
          entry[1].value?.value !== null
        ) {
          return !areObjectsIdentical(entry[1].value?.value, data[entry[0]]);
        } else {
          return String(data[entry[0]]) !== String(entry[1].value?.value);
        }
      });

      setChanged(changed);
    }
  }, [state, data, disabled]);

  // if mutation was a success, update categories
  useEffect(() => {
    if (isMutationSuccess) {
      const updatedObject = hydrateServiceData(
        db.data[singularize(activeItem.type)],
      );

      if (updatedObject.type !== 'category') {
        // update isEmpty status of parent
        getItem(
          treeData,
          updatedObject.subgroupId ||
            updatedObject.groupId ||
            updatedObject.categoryId,
          Object.keys(updatedObject)
            .find((prop) =>
              ['categoryId', 'subgroupId', 'groupId'].includes(prop),
            )
            ?.slice(0, -2),
        ).isEmpty = false;
      } else if (!('groups' in updatedObject)) {
        updatedObject.groups = []; // (re)create necessary field
      }

      // update item (assign id)
      updateTree(
        activeItem.id,
        activeItem.type,
        updatedObject,
        null,
        setCategories,
      );

      setActiveItem(
        isMobile ? undefined : { id: updatedObject.id, type: activeItem.type },
      );
    }
  }, [isMutationSuccess, db, isMobile]);

  const trimDescendants = (context) => {
    delete context.services;
    delete context.subgroups;
    delete context.groups;
  };

  const exportObject = useCallback(() => {
    const processedObj = structuredClone(data);

    Object.entries(state).forEach((entry) => {
      if (entry[1].value?.value !== entry[1].default?.value) {
        processedObj[entry[0]] = entry[1].value?.value;
      }
    });

    // if new object is created
    if (processedObj.id === 'new') {
      // check if parent group has a grouped descendant named the same as the subject being created
      if (hasSiblingsNamedEqually(processedObj, treeData)) {
        toast.error(tr['group-duplicate-key']);
        return undefined;
      } else {
        delete processedObj.id;
      }
    }

    trimDescendants(processedObj); // in order to not generate unnecessary traffic

    return processedObj;
  }, [data, state, tr, treeData]);

  const confirmChanges = useCallback(() => {
    const processedObject = exportObject();

    processedObject &&
      mutate(processedObject)?.then(() => {
        // disable Edit mode and reset change status only if saved to B/E
        setDisabled(true);
        setChanged(false);
      });
  }, [exportObject, mutate]);

  const resetChanges = useCallback(() => {
    resetState(setupFields());
    setDisabled(!isNew);
    setChanged(false);
  }, [resetState, setupFields, isNew]);

  // |- BUTTONS ->

  // itinialize buttons
  useEffect(() => {
    if (setButtons) {
      setButtons({
        type: 'update',
        id: 'edit',
        value: { action: () => setDisabled(false) },
      });
      setButtons({
        type: 'update',
        id: 'cancel',
        value: { action: resetChanges },
      });
      setButtons({
        type: 'update',
        id: 'save',
        value: { action: confirmChanges },
      });
    }
  }, [setButtons, confirmChanges, resetChanges]);

  // 'edit' button show/hide logic
  useEffect(() => {
    if (setButtons && !isReservedCategory) {
      setButtons({
        type: 'show',
        id: 'edit',
        value: !isNew && disabled,
      });
    }
  }, [setButtons, disabled, isNew, isReservedCategory]);

  // 'save'/'cancel' button show/hide logic
  useEffect(() => {
    if (setButtons) {
      setButtons({ type: 'show', id: 'cancel', value: changed });
      setButtons({ type: 'show', id: 'save', value: isNew || changed });
    }
  }, [setButtons, changed, isNew]);

  // -> BUTTONS -|

  // reset state values if another group was selected
  useEffect(() => {
    resetChanges();
  }, [activeItem, isNew, resetChanges]);

  // logs
  // useEffect(() => console.log({ state }), [state]);
  // useEffect(() => {
  //   isMutationError && console.log({ mutationError });
  // }, [isMutationError, mutationError]);

  const breadCrumbs = getPath(treeData, data)?.map((node, index) => {
    if (node === 'NONE') {
      if (index === 0) return tr['unassigned-to-category'];
      else if (index === 1) return tr['ungrouped'];
    }
    return node;
  });

  return (
    <S.CategoryDetails>
      <S.Container>
        {activeItem?.type !== 'category' && <Breadcrumbs path={breadCrumbs} />}

        <GeneralInfo
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          state={state?.['name']}
          disabled={{ state: disabled, setState: setDisabled }}
          changed={changed}
          confirmChanges={confirmChanges}
          resetChanges={resetChanges}
          {...(isReservedCategory && {
            reservedCategory: {
              forcedTitle:
                activeItem.type === 'category'
                  ? tr['unassigned-to-category']
                  : tr['ungrouped'],
            },
          })}
        />

        {!isReservedCategory && (
          <>
            <ServiceGeneralData
              state={state}
              options={options}
              disabled={disabled}
            />

            <SectorSelector
              data={state?.['assignedToSectorIds']}
              ancestors={getAncestors(data, treeData)}
              disabled={disabled}
              activeItem={activeItem}
              sectorDb={sectors}
            />
          </>
        )}

        <Overlay isVisible={isMutationLoading}>
          <Loader />
        </Overlay>
      </S.Container>
    </S.CategoryDetails>
  );
};
