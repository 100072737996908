import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useFetch } from './useApi2';
import { useTranslate } from 'hooks/useTranslate';

const COUNTRIES_API = 'https://restcountries.com/v3.1/all';

export const useSystemGetCultureOptions = (params = {}) => {
  const DB_SLICE = 'cultures';

  const { labelFormat } = params;

  const { isSuccess, data, ...context } = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(
    // () => isSuccess && setProcessedData(data[DB_SLICE]),
    () => {
      if (isSuccess) {
        setProcessedData(
          data[DB_SLICE]?.map((countryData) => {
            // form labels
            const label =
              labelFormat?.reduce((acc, prop, index) => {
                let addedProp;
                switch (prop) {
                  case 'englishName':
                    addedProp = acc + `/ ${countryData.englishName}`;
                    break;
                  case 'nativeName':
                    addedProp = acc + `/ ${countryData.nativeName}`;
                    break;
                  default:
                    addedProp = acc + countryData[prop];
                }
                if (index < countryData[prop].length) addedProp += ' ';
                return addedProp;
              }, '') || countryData;

            return {
              value: countryData.id, // culture code
              label: label,
            };
          }),
        );
      }
    },
    [isSuccess, data],
  );

  return {
    ...context,
    isSuccess: isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useSystemGetCultureById = (params = {}) => {
  const DB_SLICE = 'cultures';

  const { isSuccess, data, ...context } = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(() => {
    if (isSuccess) {
      setProcessedData(data);
    }
  }, [isSuccess, data]);

  return {
    ...context,
    isSuccess: isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useSystemGetUnits = (params = {}) => {
  const DB_SLICE = 'units';

  const tr = useTranslate().use().global; // TODO: units (probably) must be translated according to locale, not user.settings.uiLanguage

  const { isSuccess, data, ...context } = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  const getUnitsById = useCallback(
    (id) => processedData?.find((unit) => id == unit.value),
    [processedData],
  );

  useEffect(() => {
    if (isSuccess) {
      setProcessedData(
        data[DB_SLICE].map((unit) => ({
          value: unit.id,
          label: tr['unit-' + unit.id] || unit.englishShortName,
        })),
      );
    }
  }, [isSuccess, data, tr]);

  return {
    ...context,
    isSuccess: isSuccess && processedData !== undefined,
    data: processedData,
    getUnitsById,
  };
};

export const useSystemGetTimezoneOptions = (params = {}) => {
  const DB_SLICE = 'timezones';

  const { labelFormat } = params;

  const { isSuccess, data, ...context } = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(
    // () => isSuccess && setProcessedData(data[DB_SLICE]),
    () => {
      if (isSuccess) {
        // console.log(data);
        setProcessedData(
          data[DB_SLICE].map((timezoneData) => {
            // form labels
            const label = labelFormat?.reduce((acc, prop, index) => {
              let addedProp = acc + timezoneData[prop];
              if (index < timezoneData[prop].length) addedProp += ' ';
              return addedProp;
            }, '');

            return {
              value: timezoneData.id, // culture code
              label: label,
            };
          }),
        );
      }
    },
    [isSuccess, data],
  );

  return {
    ...context,
    isSuccess: isSuccess && processedData !== undefined,
    data: processedData,
  };
};

export const useCountriesGetAll = (params = {}) => {
  const { labelFormat } = params;

  const searchParams = labelFormat ? '?fields=' + labelFormat.toString() : '';
  // console.log({ searchParams });
  const { isSuccess, data, ...context } = useQuery(
    ['countries', ...(labelFormat ? labelFormat : [])],
    () => axios.get(COUNTRIES_API + searchParams).then((res) => res.data),
  );

  const [countries, setCountries] = useState();

  const formName = (data) => {
    return data.name.common;
  };

  const formCallingCode = (data) => {
    return data.idd.root + data.idd.suffixes?.[0];
  };

  useEffect(
    () =>
      isSuccess &&
      setCountries(() =>
        structuredClone(data)
          .filter((country) =>
            Object.values(country.idd).every((value) => !!value),
          )
          .sort((a, b) => {
            // sort according to name
            if (formCallingCode(a) < formCallingCode(b)) return -1;
            if (formCallingCode(a) > formCallingCode(b)) return 1;
            return 0;
          })
          .map((countryData) => {
            // form labels
            const label =
              labelFormat?.reduce((acc, prop, index) => {
                let addedProp;
                switch (prop) {
                  case 'name':
                    addedProp = acc + formName(countryData);
                    break;
                  case 'idd':
                    addedProp = acc + formCallingCode(countryData);
                    break;
                  default:
                    addedProp = acc + countryData[prop];
                }
                if (index < countryData[prop].length) addedProp += ' ';
                return addedProp;
              }, '') || countryData;

            return {
              value:
                countryData?.name?.common ||
                formCallingCode(countryData) ||
                countryData?.[labelFormat[0]], // cca2 country code
              label: label,
            };
          }),
      ),
    [data, isSuccess],
  );

  return {
    isSuccess: !!countries,
    data: countries,
    ...context,
  };
};

export const useCountriesCallingCode = () => {
  const { error, data, isSuccess } = useQuery(['countries-calling-codes'], () =>
    axios.get(COUNTRIES_API).then((res) => res.data),
  );

  const [countriesCallingCodes, setCountriesCallingCodes] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      const newArr = data.map((obj) => {
        return {
          value: Number(obj.idd.root + obj.idd.suffixes[0]),
          label: obj.idd.root + obj.idd.suffixes[0],
          country: obj.name.common,
        };
      });

      setCountriesCallingCodes(newArr);
    }
  }, [data, isSuccess]);

  return {
    isSuccess: !!countriesCallingCodes,
    error,
    data: countriesCallingCodes,
  };
};

export const useSystemGetBusinessAreaOptions = (params = {}) => {
  const DB_SLICE = 'businessAreas';

  const { labelFormat } = params;

  // console.log({ labelFormat });

  const { isSuccess, data, ...context } = useFetch({
    endpoint: DB_SLICE,
    ...params,
    queryParams: {
      retry: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      ...params.queryParams,
    },
  });

  const [processedData, setProcessedData] = useState();

  if (context.isError) {
    console.log(context.error.stack);
  }

  useEffect(
    // () => isSuccess && setProcessedData(data[DB_SLICE]),
    () => {
      if (isSuccess) {
        // console.log(data);
        setProcessedData(
          // data[DB_SLICE].map((countryData) => {
          //   // form labels
          //   const label = labelFormat.reduce((acc, prop, index) => {
          //     let addedProp;
          //     switch (prop) {
          //       case 'englishName':
          //         addedProp = acc + `/ ${countryData.englishName}`;
          //         break;
          //       case 'nativeName':
          //         addedProp = acc + `/ ${countryData.nativeName}`;
          //         break;
          //       default:
          //         addedProp = acc + countryData[prop];
          //     }
          //     if (index < countryData[prop].length) addedProp += ' ';
          //     return addedProp;
          //   }, '');

          //   return {
          //     value: countryData.id, // culture code
          //     label: label,
          //   };
          // })
          data[DB_SLICE],
        );
      }
    },
    [isSuccess, data],
  );

  return {
    ...context,
    isSuccess: isSuccess && processedData !== undefined,
    data: processedData,
  };
};
