import styled from 'styled-components/macro';

export const LanguageSelector = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    position: sticky;
    bottom: 0;
    margin-top: auto;
  }
`;
