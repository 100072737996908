import styled from 'styled-components/macro';
import { fontFamilies } from 'common/fonts/fonts';
import { FormContainer } from 'components/Form/FormContainer.styled';

export const ClientGeneralInformation = styled.div`
  font-family: ${fontFamilies.family_Secondary};

  @media screen and (max-width: 960px) {
    /* padding-top: 20px; */
  }

  ${FormContainer} {
    /* padding: 10px 10px 0; */
  }
`;
