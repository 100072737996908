import React, { useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

// Style
import * as S from './OrderDetailsHeader.styled';

// Custom hooks
import useStatusList from 'hooks/useStatusList';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

const OrderDetailsHeader = ({ id, toggledStatus, status, displayInfo }) => {
  const tr = useTranslate().use().global;

  // Hooks
  // -- state
  const [messageSelected, setMessageSelected] = useState(true);
  let currStatus = useStatusList([status])[0];

  return (
    <S.Container bg={currStatus?.background}>
      <div className="orderStatus">{currStatus?.title}</div>
      <div></div>
      {displayInfo && (
        <div className="orderInfo">
          <div className="info_id">
            {tr.id}: {id}
          </div>
          <Checkbox
            text={`${tr.sms}:`}
            value={{
              state: messageSelected,
              action: () => setMessageSelected(!messageSelected),
            }}
          />
          {/* {toggledStatus.ordersLength > 1 && (
          <Checkbox
            id={id}
            text={`${tr.selected}:`}
            value={{
              state: toggledStatus.selectedStatus,
              action: () => toggledStatus.changeState(id),
            }}
          />
        )} */}
        </div>
      )}
    </S.Container>
  );
};

export default OrderDetailsHeader;
