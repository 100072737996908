import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const FilterButton = styled.div`
  display: flex;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: ${({ hasValueSelected }) =>
      colors[hasValueSelected ? 'text_Secondary' : 'filterGrey']};

    .clear-icon {
      display: flex;
      height: 100%;
      padding: 8px;
      margin-left: auto;
      align-items: center;
      justify-content: center;

      color: ${colors.filterGrey};
    }
  }

  .chevron {
    border-left: 1px solid;
    color: hsl(0, 0%, 80%);
    padding: 9px 0 9px 8px;
    display: flex;

    svg {
      margin-right: -3px;
      fill: hsl(0, 0%, 80%);
    }

    :active svg,
    :focus svg {
      fill: ${colors.text_Secondary};
    }
  }
`;
