import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const Overlay = styled.div`
  background-color: ${colors.overlay};
  display: flex;
  height: ${({ viewport }) => (viewport ? `${viewport.height}px` : '100vh')};
  height: ${({ viewport }) => (viewport ? `${viewport.height}px` : '100dvh')};
  left: ${({ viewport }) => (viewport ? viewport.left : '0')}px;
  overflow: hidden;
  position: fixed;
  top: ${({ viewport }) => (viewport ? viewport.top : '0')}px;
  width: ${({ viewport }) => (viewport ? `${viewport.width}px` : '100vw')};
  z-index: 1000;

  &.modal-enter {
    opacity: 0;
  }

  &.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.modal-exit {
    opacity: 1;
  }

  &.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const PassThrough = styled.div`
  > * {
    z-index: ${(props) => props.isVisible && '1001'};
  }
`;
