import { shadow } from 'common/styles/shadows';
import styled, { css } from 'styled-components/macro';
import { colors } from '../../../../common/colors/colors';
import { Avatar } from 'components/Avatar/Avatar.styled';

export const ProfessionalHead = styled.div`
  height: 80px;
  width: 90px;
  position: relative;
  ${({ grayedOut }) =>
    grayedOut &&
    css`
      color: ${colors.bg_grey_6};
    `}

  .head_image {
    width: 50px;
    height: 50px;

    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);

    margin: 0 auto;

    border: ${(props) =>
      props.currentProfessional ? `2px solid ${colors.small_details}` : 'none'};

    /* img {
      width: 100%;
    } */
    font-size: 1.5rem;

    ${Avatar} {
      ${({ grayedOut }) =>
        grayedOut &&
        css`
          filter: grayscale(1);

          .initials {
            color: ${colors.bg_grey_6};
          }
        `}
    }
  }

  .head_fullName {
    padding: 5px 0;
    font-size: 10px;
    font-weight: 600;

    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-head {
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: ${shadow.md};

    position: absolute;
    top: 0;
    right: 0;

    svg {
      width: 6.5px;
      height: 6.5px;
      fill: ${colors.bg_Primary};
    }
  }

  @media screen and (min-width: 961px) {
    width: ${(props) => (props.small ? 122 : 180)}px;
    height: ${(props) => (props.small ? 90 : 140)}px;

    .head_image {
      height: ${(props) => (props.small ? 60 : 100)}px;
      width: ${(props) => (props.small ? 60 : 100)}px;
      cursor: pointer;
    }

    .head_fullName {
      font-size: ${(props) => (props.small ? 10 : 16)}px;
    }

    .delete-head {
      width: 20px;
      height: 20px;
      cursor: pointer;

      svg {
        width: 8px;
        height: 8px;
      }
    }
  }
`;
