import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';

export const ContextMenu = styled.div`
  background-color: ${colors.bg_Secondary};
  border-radius: 5px;
  box-shadow: ${shadow.context};
  left: ${({ xPos }) => xPos}px;
  padding: 5px;
  position: fixed;
  top: ${({ yPos }) => yPos}px;
  visibility: ${({ visibility }) => visibility};
  z-index: 2;
`;

export const ContextMenuItem = styled.div`
  color: ${({ color }) => color || 'inherit'};
  cursor: default;
  display: flex;
  min-width: 180px;
  max-width: 280px;
  padding: 10px;

  :hover,
  :focus,
  :active {
    background-color: ${colors.paid};
    border-radius: 5px;
  }

  :active {
    font-weight: 700;
  }

  svg {
    margin-right: 5px;
  }
`;
