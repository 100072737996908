import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// style
import * as S from './MobileSort.styled';

// icons
import { SortAscSVG, SortDescSVG } from 'icons/dynamic';

// components
import ContentLayout from 'components/ContentLayout/ContentLayout';
import DialogLayout from 'components/DialogLayout/DialogLayout';
import SelectList from 'components/DialogLayout/CustomSelect/children/SelectList/SelectList';
import TheButton from 'components/Buttons/TheButton';

// contexts
import { TableContext } from 'context/TableContext';

// hooks
import useTranslate from 'hooks/useTranslate';
import { useTable } from 'hooks/useTable';

export const MobileSort = ({ onClose }) => {
  const tr = useTranslate().use().global;
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');
  const {
    state: { columns },
  } = useContext(TableContext);
  const { sortOrder, sortColumn: appliedSortColumn, setSort } = useTable();

  const [sortColumn, setSortColumn] = useState(appliedSortColumn);
  const [order, setOrder] = useState(sortOrder || 'asc');

  const handleClose = () => {
    if (sortColumn?.filter) {
      setSort(sortColumn.filter, order);
    }
    onClose();
  };

  const sortItems = columns
    .filter((item) => {
      if (!sectorId && item?.isSectorSpecific) {
        return false;
      }

      return item.sortable;
    })
    .map((item) => ({
      ...item,
      value: item.filter,
    }));

  return (
    <S.Sort>
      <ContentLayout hideFab>
        <DialogLayout
          key={sortColumn?.id + '-' + order}
          headerText={tr['sort']}
          getBack={handleClose}
          modal
        >
          <S.Criterias>
            <SelectList
              arr={sortItems}
              currentlySelected={(value) => value === sortColumn?.filter}
              action={(column) => setSortColumn(column)}
            />
          </S.Criterias>
          <S.Controls>
            <TheButton
              secondary
              inverted={order !== 'desc'}
              raised
              action={() => setOrder('desc')}
              title={tr['descending']}
              icon={<SortDescSVG />}
              width="fit-content"
              height="35"
            />
            <TheButton
              secondary
              inverted={order !== 'asc'}
              raised
              action={() => setOrder('asc')}
              title={tr['ascending']}
              icon={<SortAscSVG />}
              width="fit-content"
              height="35"
            />
          </S.Controls>
        </DialogLayout>
      </ContentLayout>
    </S.Sort>
  );
};
