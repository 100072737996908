import * as S from '../SVG.styled';

export default ({ color, width, height, bold, inCircle, ...rest }) => {
  const content = bold ? (
    inCircle ? (
      <>
        <path
          d="M 15,0 C 6.728145,0 0,6.728145 0,15 0,23.2719 6.728145,30 15,30 23.2719,30 30,23.2719 30,15 30,6.728145 23.2719,0 15,0 Z m 0,27.55455 C 8.076225,27.55455 2.44548,21.92385 2.44548,15 2.44548,8.076225 8.076225,2.44548 15,2.44548 c 6.92385,0 12.55455,5.630745 12.55455,12.55452 0,6.92385 -5.6307,12.55455 -12.55455,12.55455 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
        <path
          d="m 10.09986,13.16601 c -1.011825,0 -1.83411,0.8223 -1.83411,1.83414 0,1.01175 0.822285,1.83405 1.83411,1.83405 1.011825,0 1.83411,-0.8223 1.83411,-1.83405 0,-1.01184 -0.822285,-1.83414 -1.83411,-1.83414 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
        <path
          d="m 14.99079,13.16601 c -1.011825,0 -1.83411,0.8223 -1.83411,1.83414 0,1.01175 0.822285,1.83405 1.83411,1.83405 1.01181,0 1.83411,-0.8223 1.83411,-1.83405 0,-1.01184 -0.8223,-1.83414 -1.83411,-1.83414 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
        <path
          d="m 19.9032,13.16601 c -1.0119,0 -1.8342,0.8223 -1.8342,1.83414 0,1.01175 0.8223,1.83405 1.8342,1.83405 1.01175,0 1.83405,-0.8223 1.83405,-1.83405 0,-1.01184 -0.8223,-1.83414 -1.83405,-1.83414 z"
          fill="currentcolor"
          strokeWidth="1.5"
        />
      </>
    ) : (
      <></>
    )
  ) : inCircle ? (
    <>
      <path
        d="M 15,30 C 23.26965,30 30,23.26965 30,15 30,6.730395 23.26965,0 15,0 6.730395,0 0,6.730395 0,15 0,23.26965 6.730395,30 15,30 Z M 15,1.274697 C 22.56855,1.274697 28.7253,7.43148 28.7253,15 28.7253,22.56855 22.56855,28.7253 15,28.7253 7.43148,28.7253 1.274697,22.56855 1.274697,15 1.274697,7.43148 7.43148,1.274697 15,1.274697 Z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 9.891645,16.27485 c 0.704265,0 1.2747,-0.57045 1.2747,-1.2747 0,-0.704325 -0.570435,-1.274745 -1.2747,-1.274745 -0.70428,0 -1.2747,0.57042 -1.2747,1.274745 0,0.70425 0.57042,1.2747 1.2747,1.2747 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 14.9904,16.27485 c 0.70425,0 1.2747,-0.57045 1.2747,-1.2747 0,-0.704325 -0.57045,-1.274745 -1.2747,-1.274745 -0.70428,0 -1.2747,0.57042 -1.2747,1.274745 0,0.70425 0.57042,1.2747 1.2747,1.2747 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
      <path
        d="m 20.11155,16.27485 c 0.70425,0 1.2747,-0.57045 1.2747,-1.2747 0,-0.704325 -0.57045,-1.274745 -1.2747,-1.274745 -0.7044,0 -1.2747,0.57042 -1.2747,1.274745 0,0.70425 0.5703,1.2747 1.2747,1.2747 z"
        fill="currentcolor"
        strokeWidth="1.5"
      />
    </>
  ) : (
    <></>
  );

  return (
    <S.StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      version="1.1"
      viewBox="0 0 30 30"
      color={color}
      {...rest}
    >
      {content}
    </S.StyledSVG>
  );
};
