import styled from 'styled-components/macro';

import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SideBarDatePicker = styled.div`
  background-color: ${colors.bg_Secondary};
  border-radius: 5px;
  font-family: ${fontFamilies.family_Secondary};
  padding: 10px 7px;
  /* margin-top: 280px; */

  > .date-picker {
    margin-bottom: ${({ noToday }) => !noToday && '10px'};

    button {
      background-color: inherit;
      border: 0;
      color: ${colors.calendar_date_text_not_selected};
      cursor: pointer;

      /* &:hover {
        background-color: #556b55;
      }

      &:active {
        background-color: #a5c1a5;
      } */
    }
  }

  .react-calendar__navigation {
    align-items: center;
    display: flex;
    /* font-family: ${fontFamilies.family_Primary}; */
    line-height: 1;
    margin-bottom: 20px;
    width: 100%;

    &__arrow {
      margin: 0 5px;
      padding: 0;
    }

    &__prev-button,
    &__prev2-button,
    &__next-button,
    &__next2-button {
      height: 16px;
      width: 16px;
    }

    &__prev2-button svg {
      transform: ${({ container }) =>
        container !== 'flex' && 'rotate(-180deg)'};
    }

    &__prev-button svg {
      transform: ${({ container }) =>
        container !== 'flex' && 'rotate(-180deg)'};
    }

    &__label__labelText {
      font-size: 0.875rem;
    }

    button {
      color: ${colors.text_Secondary};
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .react-calendar__month-view__weekdays {
    color: ${colors.text_Secondary};
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days {
    padding: 3px 0;

    /* &__day--weekend {
      color: ${colors.calendar_weekend};
    } */

    &__day {
      display: flex;
      justify-content: center;
    }

    &__day--neighboringMonth {
      color: ${colors.calendar_diff_month_day};
    }

    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;

      :hover,
      :active {
        background-color: ${colors.calendar_button_Secondary};
        border-radius: 50%;
        /* color: ${colors.text_Primary}; */
        /* border: 1px solid red; */
      }

      :active {
        border-color: red;
      }
    }

    button {
      padding: 2.5px 0;

      &.selected abbr {
        background-color: ${colors.bg_Button_Secondary};
        border-radius: 50%;
        color: ${colors.button_Primary};
      }
    }
  }

  .react-calendar__decade-view {
    padding: 0 0 10px;
  }

  .react-calendar__year-view {
    padding: 0 0 10px;

    &__months__month {
      text-align: left;
    }
  }

  .react-calendar__year-view__months__month {
  }

  // marker for hover and range tiles
  .react-calendar__tile--hasActive {
    &.react-calendar__century-view__decades__decade,
    &.react-calendar__decade-view__years__year,
    &.react-calendar__year-view__months__month,
    &.react-calendar__month-view__days__day {
      background-color: ${colors.bg_Button_Secondary};
      border-radius: 12.5px;
      color: white;

      :hover {
        color: white;
      }
    }
  }

  .react-calendar__tile--now {
    abbr {
      /* border: 1px solid red;
      border-radius: 50%; */
      color: ${colors.button_Secondary};
      font-weight: 900;
    }
  }
`;
