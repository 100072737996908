import { DateTime } from 'luxon';

// TODO: GET RID OF THIS, use useDate hook instead.
export const oldLocale = localStorage.getItem('lng') || 'en';

// TODO: GET RID OF THIS, use useDate hook instead.
export const getDate = (date) => new Date(date).toLocaleDateString(oldLocale);

export const getTimeString = (
  timeISO,
  timezoneIanaId,
  locale,
  fallbackToDashes = true,
) => {
  if (timeISO) {
    return DateTime.fromISO(timeISO, {
      ...(timezoneIanaId && {
        zone: timezoneIanaId,
      }),
    })
      .setLocale(locale)
      .toLocaleString({ hour: 'numeric', minute: 'numeric' });
  }

  return fallbackToDashes ? '-:-' : '00:00';
};

export const getDurationString = (startTime, endTime, timezoneIanaId) => {
  if (startTime && endTime) {
    let startDateTime = DateTime.fromISO(startTime);
    let endDateTime = DateTime.fromISO(endTime);

    if (timezoneIanaId) {
      startDateTime = startDateTime.setZone(timezoneIanaId);
      endDateTime = endDateTime.setZone(timezoneIanaId);
    }

    const differenceDurationObject = endDateTime
      .diff(startDateTime, ['hours', 'minutes'])
      .toObject();

    return DateTime.now()
      .set({
        hour: differenceDurationObject.hours,
        minute: differenceDurationObject.minutes,
      })
      .toFormat('HH:mm');
  }

  return '-:-';
};

export const getDuration = (dateStart, dateEnd) => {
  const displayTime = (time) => {
    if (time < 10) {
      return '0' + time;
    } else {
      return time;
    }
  };

  const dateStartObj = new Date(dateStart);
  const dateEndObj = new Date(dateEnd);

  const duration = (dateEndObj.getTime() - dateStartObj.getTime()) / 60000; // in min
  const durationHours = Math.floor(duration / 60);
  const durationMinutes = Math.ceil(duration % 60);

  return `${displayTime(durationHours)}:${displayTime(durationMinutes)}`;
};

export const getDurationFromMinutes = (minutes) => {
  if (isNaN(minutes) || minutes < 0) return '00:00';

  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  hours = hours < 10 ? '0' + hours : hours;
  remainingMinutes =
    remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

  return `${hours}:${remainingMinutes}`;
};

export const getDurationInMinutes = (startDate, endDate) => {
  const timeDifference = endDate.getTime() - startDate.getTime();
  return Math.floor(timeDifference / (1000 * 60));
};

export const getFirstDayOfWeek = (d) => {
  // 👇️ clone date object, so we don't mutate it
  const date = new Date(d);
  const day = date.getDay(); // 👉️ get day of week

  // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(date.setDate(diff));
};

export const getLastDayOfWeek = (d) => {
  const lastDay = new Date(getFirstDayOfWeek(d));
  lastDay.setDate(lastDay.getDate() + 6);

  return lastDay;
};

// 👇️ conversion of native getDay method for Monday as first day of the week
export const getDay = (date) =>
  date?.getDay() + (date?.getDay() === 0 ? 6 : -1);

// remove time from date (set to 00:00:00)
export const unTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const ifWeekend = (index) => {
  const wholeWeeks = Math.floor(index / 7);

  return (
    (index - wholeWeeks * 7 + 1) % 6 === 0 ||
    (index - wholeWeeks * 7 + 1) % 7 === 0
  );
};

export const getWeek = (date) => {
  const currentDate = new Date(date);
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

  return Math.ceil(days / 7);
};

const removeDST = (date) => {
  const startDayLocalized = new Date(date);
  const timezone = startDayLocalized.getTimezoneOffset() * 60 * 1000;
  const startDayISO = startDayLocalized.getTime() - timezone;
  return new Date(startDayISO);
};

export const isWeekOdd = (date) =>
  [
    1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39,
    41, 43, 45, 47, 49, 51, 53,
  ].includes(getWeek(removeDST(date)));

export const isWeekEven = (date) =>
  [
    2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40,
    42, 44, 46, 48, 50, 52,
  ].includes(getWeek(removeDST(date)));

export const isDayOdd = (date) =>
  [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31].includes(
    date.getDate(),
  );

export const isDayEven = (date) =>
  [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30].includes(
    date.getDate(),
  );

export const everySecondDay = (startDate, date) => {
  const startDay = removeDST(startDate);
  const currentDay = removeDST(date);
  const difference =
    (currentDay.getTime() - startDay.getTime()) / 1000 / 60 / 60 / 24;
  return difference % 2 === 0;
};

export const everySecondWeek = (startDate, date) => {
  const startWeek = getWeek(new Date(removeDST(startDate)));
  const currentWeek = getWeek(new Date(removeDST(date)));
  const difference = currentWeek - startWeek;
  return difference % 2 === 0;
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const datesAreOnSameMonth = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth();

export const datesAreOnSameYear = (first, second) =>
  first.getFullYear() === second.getFullYear();

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const dateIsInThePast = (date) => {
  const currentDate = new Date();

  return date.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
};

export const dateIsWeekend = (date) => {
  return !(date.getDay() % 6);
};

export const getVisiblePeriod = (start, weeks = 1) => {
  let startDay = start ? new Date(start) : new Date();
  startDay.setHours(0, 0, 0, 0);
  startDay.setDate(1);
  startDay = getFirstDayOfWeek(startDay);

  let endDay = new Date(startDay);
  endDay.setDate(endDay.getDate() + 6 * weeks);
  endDay.setHours(23, 59, 59, 999);
  endDay = getLastDayOfWeek(endDay);

  const periodArr = [];

  for (
    let i = new Date(startDay);
    i <= new Date(endDay);
    i.setDate(i.getDate() + 1)
  ) {
    periodArr.push(new Date(i));
  }

  return periodArr;
};

export const toIsoString = (date) => {
  let tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};

export const formatToIsoWithoutMilliseconds = (date) => {
  return date.toISOString().slice(0, -5) + 'Z';
};

// apply range start time values for comparison operations
export const applyStartTime = (date) => {
  let response = new Date(date);
  response.setHours(0, 0, 0, 0);
  return response;
};

// apply range end time values for comparison operations
export const applyEndTime = (date) => {
  let response = new Date(date);
  response.setHours(23, 59, 59, 999);
  return response;
};

// set from/to dates to current month
export const getCurrentMonthPeriodDates = () => ({
  start: new Date(DateTime.local().startOf('month').ts),
  end: new Date(DateTime.local().endOf('month').startOf('day').ts),
});

export const getDaysInYear = (year) =>
  (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
