import { useCallback, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// style
import * as S from './NavBarMobile.styled';
import { colors } from 'common/colors/colors';

// icons
import { Ex, BurgerMenu, MagnifyingGlass } from 'icons/dynamic';

// images
import { ServeProLogo } from 'images';

// components
import { Search } from 'components/Search/Search';

// contexts
import { MainContext } from 'pages/Main/Main';

export const NavBarMobile = ({ showBurgerMenu, setShowBurgerMenu }) => {
  const {
    headerFixed,
    searchPlaceholder,
    searchInput,
    searchIconVisible,
    setState,
  } = useContext(MainContext);

  const handleResize = useCallback(() => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(() => {
      if (headerFixed.position) {
        headerFixed.setPosition(false);
        searchInput.setInput('');
      }
    }, 1);
  }, [headerFixed, searchInput]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [headerFixed.position, handleResize]);

  // return (
  //   <S.NavBarMobile searchIconVisible={searchIconVisible}>
  //     {headerFixed?.position ? (
  //       <div className="search-container">
  //         <Search
  //           placeholder={searchPlaceholder.placeholder}
  //           state={{
  //             input: searchInput.input,
  //             setInput: searchInput.setInput,
  //           }}
  //         />

  //         <div
  //           className="search-exit"
  //           onClick={() => {
  //             headerFixed.setPosition(false);
  //             searchInput.setInput('');
  //           }}
  //         >
  //           <Ex black width={10} height={10} />
  //         </div>
  //       </div>
  //     ) : (
  //       <>
  //         <S.BurgerContainer onClick={() => setShowBurgerMenu(!showBurgerMenu)}>
  //           <BurgerMenu bold color="#fff" />
  //         </S.BurgerContainer>

  //         <S.LogoContainer>
  //           <NavLink to="/" replace={true}>
  //              <ServeProLogo color={colors.header_logo_and_text_Primary} />
  //           </NavLink>
  //         </S.LogoContainer>

  //         <div
  //           id="header-search"
  //           className="header-search-container"
  //           // onClick={() => headerFixed.setPosition(!headerFixed.position)}
  //           onClick={() => setState({ type: 'show-search-widget' })}
  //         >
  //           <HeaderSearchSVG />
  //         </div>
  //       </>
  //     )}
  //   </S.NavBarMobile>
  // );

  return (
    <S.NavBarMobile searchIconVisible={searchIconVisible}>
      <S.BurgerContainer onClick={() => setShowBurgerMenu(!showBurgerMenu)}>
        <BurgerMenu bold color="#fff" />
      </S.BurgerContainer>

      <S.LogoContainer>
        <NavLink to="/" replace={true}>
          <ServeProLogo color={colors.header_logo_and_text_Primary} />
        </NavLink>
      </S.LogoContainer>

      <div
        id="header-search"
        className="header-search-container"
        // onClick={() => headerFixed.setPosition(!headerFixed.position)}
        onClick={() => setState({ type: 'show-search-widget' })}
      >
        <MagnifyingGlass black width={17} height={17} />
      </div>
    </S.NavBarMobile>
  );
};

export default NavBarMobile;
