import { Loader } from 'components/Loader/Loader';

const LegalEntities = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default LegalEntities;
