// style
import * as S from './Loader.styled';

export const Loader = () => {
  return (
    <S.Loader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </S.Loader>
  );
};

export default Loader;
