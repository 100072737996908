import { useState } from 'react';

// style
import * as S from './WorkingAreasSettings.styled';

// icons
import { Check } from 'icons/dynamic';

// components
import { Checkbox } from 'components/Checkbox/Checkbox';
import { InputFieldGroup } from 'components/InputFieldGroup/InputFieldGroup';
import { Link } from 'components/Overrides';

// hooks
import { useEffect } from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';

export const WorkingAreasSettings = ({ setButtons }) => {
  // Hooks
  const tr = useTranslate().use().global;
  const isMobile = useMediaQuery('(max-width: 960px)');

  // -- state
  const [checkWorkArea, setCheckWorkArea] = useState(false);
  const [listWorkArea, setListWorkArea] = useState(['']);

  // setup/cleanup header buttons
  useEffect(() => {
    setButtons &&
      setButtons({
        type: 'setup',
        value: [
          {
            id: 'save',
            icon: <Check bold />,
            action: () => console.log('Settings saved'),
            borderless: true,
            show: true,
          },
        ],
      });

    return () => setButtons && setButtons({ type: 'cleanup' });
  }, [setButtons]);

  // Functions
  const addItemToListWorkArea = () => {
    setListWorkArea((prev) => {
      return [...prev, ''];
    });
  };

  const removeItemFromWorkArea = (id, index) => {
    setListWorkArea((prev) => {
      const newArr = [...prev];
      newArr.splice(index, 1);

      return newArr;
    });
  };

  return (
    <S.WorkingAreasSettings>
      <form className="form-container">
        <div className="working-areas__check-box-container">
          <p>
            {tr['work-area-settings-p']}{' '}
            <Link to={'#'}>{tr['employee-schedule'].toLocaleLowerCase()}</Link>
          </p>

          <Checkbox
            value={{
              state: checkWorkArea,
              action: () => setCheckWorkArea((prev) => !prev),
            }}
          />
        </div>

        <div className="working-areas__list-container">
          <div className="working-areas__list-number">
            <label>{tr['id']}</label>
            {listWorkArea.map((item, i) => (
              <div className="working-areas__number" key={i}>
                {i + 1}
              </div>
            ))}
          </div>

          <div className="working-areas__list-names">
            <InputFieldGroup
              label={tr['title']}
              state={listWorkArea}
              setState={(id, newValue) => setListWorkArea(newValue)}
              placeholder={tr['none']}
              addItemHandler={addItemToListWorkArea}
              removeItemHandler={removeItemFromWorkArea}
            />
          </div>
        </div>
      </form>
    </S.WorkingAreasSettings>
  );
};

export default WorkingAreasSettings;
