import styled, { css } from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

const styledLi = `  
  padding: 10px 6px;

  border-radius: 5px;

  :hover {
    cursor: pointer;
    background-color: ${colors.paid};
    font-weight: 900;
  }
`;

export const ContextTree = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;

  cursor: pointer;
`;

export const TreeMenu = styled.ul`
  z-index: 5 !important;

  ${({ left, top, override }) =>
    override &&
    css`
       {
        position: fixed !important;
        left: ${left + 'px !important;'}
        top: ${top + 'px !important;'}
    `}

  margin: 0 !important;
  padding: 5px !important;

  display: flex;
  flex-direction: column;
  white-space: nowrap;

  background-color: white;
  border-radius: 5px;
  box-shadow: ${shadow.tooltip};

  list-style-type: none;
  color: ${colors.text_Secondary};
  z-index: ${({ override }) => (override ? 2 : 9999)};
  font-family: ${fontFamilies.family_Secondary};
  font-size: 0.875rem;
  font-weight: 400;

  width: ${({ fixedWidth, textWidth }) =>
    fixedWidth ? fixedWidth : textWidth + 12 + 10}px;

  textarea {
    min-width: 190px;
    color: ${colors.text_Secondary};
    outline: none;
    border: none;

    height: 70px; // 4 lines
  }
`;

export const NestedMenuItem = styled.li`
  background-color: ${({ selected }) => selected && colors.paid};
  font-weight: ${({ selected }) => selected && 900};

  display: flex;
  justify-content: space-between;

  ${styledLi};

  .nestedMenu_item {
    display: flex;
    align-items: center;

    max-width: 90%;
  }

  .nestedMenu_item-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .nestedMenu_item-icon {
    margin-right: 5px;

    display: flex;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .nestedMenu_arrow {
    display: flex;
    align-items: center;
  }
`;

export const PlainItem = styled.li`
  ${styledLi};

  display: flex;
  align-items: center;

  color: ${({ fontColor }) => fontColor};

  .status-color {
    width: 14px;
    height: 14px;

    border-radius: 50%;
    margin-right: 5px;
  }

  .item-icon {
    margin-right: 5px;
    display: flex;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  span {
    flex: 1;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
