import * as S from './InputFieldGroup.styled';

// icons
import { Minus, Plus } from 'icons/dynamic';

// hooks and helpers
import { useTranslate } from 'hooks/useTranslate';
import { colors } from 'common/colors/colors';

export const InputFieldGroup = ({
  state,
  setState,
  id,
  type,
  valueType,
  label,
  placeholder,
  addItemHandler,
  removeItemHandler,
}) => {
  // translation engine
  const tr = useTranslate().use().global;

  // useEffect(() => {
  //   console.log('inner state:', state);
  // }, [state]);

  const setNewVal = (state, key, e) => {
    let newValue = [...state];
    newValue.splice(key, 1, e.target.value);

    setState(id, newValue);
  };

  return (
    <S.InputFieldGroup noLabel={!label} id={id}>
      {state && <label>{label}</label>}
      {state &&
        state.map((stateItem, key) => (
          <S.InputFieldItem key={key}>
            <input
              id={id + '-' + key}
              type={valueType || null}
              placeholder={placeholder}
              value={stateItem}
              onChange={(e) => setNewVal(state, key, e)}
            />
            <button
              id={id + '-' + key}
              type="button"
              onClick={() => removeItemHandler(id, key)}
            >
              <Minus inCircle bold width={20} height={20} />
            </button>
          </S.InputFieldItem>
        ))}
      <button type="button" onClick={() => addItemHandler(id)}>
        <Plus
          black
          inCircle
          width={20}
          height={20}
          color={colors.bg_Button_Primary}
        />
      </button>
    </S.InputFieldGroup>
  );
};

export default InputFieldGroup;
