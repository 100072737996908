import { useState } from 'react';

// style
import * as S from './OrderRowBreak.styled';

// icons
import { Clock } from 'icons/dynamic';

// components
import { TitleAndValueChild } from 'components/DialogLayout/CustomSelect/children/TitleAndValueChild/TitleAndValueChild';
import { InputFieldDuration } from 'components/InputFieldDuration/InputFieldDuration';

// hooks
import { minutesToHoursAndMinutes } from 'common/helpers/timeCalculations';

const OrderRowBreak = ({ colSpan, index, updateBreak, data, table = true }) => {
  const [timeValue, setTimeValue] = useState();

  const getTimeFromMinutes = (minutes) => {
    const time = minutesToHoursAndMinutes(minutes);

    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    return `${padToTwoDigits(time[0])}:${padToTwoDigits(time[1])}`;
  };

  const handleInput = (value) =>
    updateBreak({ unitDurationInMinutes: value?.value }, index);

  return table ? (
    <S.OrderRowBreak>
      <td colSpan={colSpan}>
        <div className="content">
          <div className="icon-container">
            <Clock />
          </div>

          <span className="text-container">Pertrauka</span>

          <InputFieldDuration
            id={'unitDurationInMinutes'}
            state={{
              value: timeValue,
              ...(data?.unitDurationInMinutes
                ? {
                    default: {
                      value: data?.unitDurationInMinutes,
                      label: getTimeFromMinutes(data?.unitDurationInMinutes),
                    },
                  }
                : {}),
              setValue: (id, value) => handleInput(value),
            }}
          />
        </div>
      </td>
    </S.OrderRowBreak>
  ) : (
    <TitleAndValueChild
      title="Pertrauka"
      icon={<Clock />}
      editable
      bold
      inputSize="S"
      textType="primary"
      justifyValue="flex-start"
    >
      <InputFieldDuration
        id={'unitDurationInMinutes'}
        state={{
          value: timeValue,
          ...(data?.unitDurationInMinutes
            ? {
                default: {
                  value: data?.unitDurationInMinutes,
                  label: getTimeFromMinutes(data?.unitDurationInMinutes),
                },
              }
            : {}),
          setValue: (id, value) => handleInput(value),
        }}
      />
    </TitleAndValueChild>
  );
};

export default OrderRowBreak;
