import { fontFamilies } from 'common/fonts/fonts';
import { colors } from 'common/colors/colors';
import styled from 'styled-components/macro';

export const DialogButton = styled.button`
  align-items: center;
  background-color: ${(props) =>
    props.disabled
      ? colors.disabledGrey
      : props.inverted
        ? 'white'
        : props.tertiary
          ? colors.bg_Button_Tertiary
          : colors.bg_Button_Primary};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? colors.disabledGrey
        : props.tertiary
          ? colors.bg_Button_Tertiary
          : colors.bg_Button_Primary};
  border-radius: 100px;
  color: ${(props) =>
    props.inverted
      ? props.tertiary
        ? colors.bg_Button_Tertiary
        : colors.bg_Button_Primary
      : props.tertiary
        ? colors.bg_Primary
        : 'white'};
  display: flex;
  height: 100%;
  font-family: ${fontFamilies.family_Primary};
  font-size: 1rem;
  justify-content: center;
  min-height: 35px;
  padding: 0 20px;

  .icon-container {
    height: 16px;
    flex-shrink: 0;
    margin-right: 10px;
    width: 16px;

    @media screen and (min-width: 961px) {
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .title-container {
    font-size: 16px;
    font-weight: 600;
    padding: 2px 0 4px 0;

    @media screen and (min-width: 961px) {
      font-size: 16px;
    }
  }
`;
