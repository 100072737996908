import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// contexts
import { TableContext } from 'context/TableContext';
import { FunctionsContext } from 'context/FunctionsContext';

export const useTable = () => {
  const {
    state: {
      pagination,
      tableName,
      sort,
      columns,
      filters: { applied: appliedFilters, state: filtersState },
    },
    dispatch,
  } = useContext(TableContext);
  const { state: { tablesFilters } = {}, dispatch: functionsCtxDispatch } =
    useContext(FunctionsContext);
  const filtersDispatchTimeouts = useRef();
  const [searchParams] = useSearchParams();
  const sectorId = searchParams.get('sectorId');

  const sortName = sort[0]?.split(' ')[0]; // same as filter name
  const sortOrder = sort[0]?.split(' ')[1];
  const sortColumn =
    sortName && columns.find((column) => column.filter === sortName);

  useEffect(() => {
    filtersDispatchTimeouts.current = {};
  }, []);

  const setSort = useCallback((sortName, order) => {
    dispatch({
      type: 'SET_SORT',
      payload: [sortName + ' ' + order],
    });

    if (tableName) {
      functionsCtxDispatch({
        type: 'SET_TABLES_SORT',
        payload: {
          ...tablesFilters,
          [tableName]: [sortName + ' ' + order],
        },
      });
    }
  }, []);

  const setFilter = useCallback(
    (value, filterName, autoApply = true) => {
      clearTimeout(filtersDispatchTimeouts.current?.filterName);

      if (!value && value !== false) {
        removeFilters([filterName], autoApply);

        return;
      }

      const newFilters = [...filtersState];
      const filter = newFilters.find((filter) => filter.field === filterName);
      const valueInString = value === null ? value : value.toString();
      const updatedFilter = {
        field: filterName,
        valueStr: valueInString,
        value: value,
      };

      if (filter?.valueStr === updatedFilter?.valueStr) {
        return;
      }

      if (filter) {
        const filterIndex = newFilters.indexOf(filter);

        if (valueInString?.trim() === '') {
          delete newFilters[filterIndex];
        }

        newFilters[filterIndex] = updatedFilter;
      } else {
        newFilters.push(updatedFilter);
      }

      dispatch({
        type: 'SET_FILTERS',
        payload: [...newFilters],
      });

      if (autoApply) {
        filtersDispatchTimeouts.current.filterName = setTimeout(() => {
          applyFilters([...newFilters]);
        }, 500);
      }
    },
    [filtersState],
  );

  const removeFilters = useCallback(
    (filterFields, autoApply = true) => {
      const newFilters = filtersState.filter(
        (filter) => !filterFields.includes(filter.field),
      );

      if (filtersState.length === newFilters.length) {
        return;
      }

      dispatch({
        type: 'SET_FILTERS',
        payload: newFilters,
      });

      if (autoApply) {
        applyFilters(newFilters);
      }
    },
    [filtersState],
  );

  const resetFilters = useCallback(
    () =>
      dispatch({
        type: 'SET_FILTERS',
        payload: appliedFilters,
      }),
    [appliedFilters],
  );

  const clearFilters = useCallback(
    () =>
      dispatch({
        type: 'SET_FILTERS',
        payload: [
          {
            field: 'IsActive',
            value: true,
            valueStr: 'true',
          },
        ],
      }),
    [],
  );

  const applyFilters = useCallback((filters) => {
    dispatch({
      type: 'SET_APPLIED_FILTERS',
      payload: filters,
    });

    if (tableName) {
      functionsCtxDispatch({
        type: 'SET_TABLES_FILTERS',
        payload: {
          ...tablesFilters,
          [tableName]: filters,
        },
      });
    }
  }, []);

  const getColumnVisibility = useCallback(
    (column) =>
      column.isVisible && (column?.isSectorSpecific ? !!sectorId : true),
    [sectorId],
  );

  const setData = (data, totalCount) => {
    if (data.length === 0 && pagination.skip !== 0) {
      dispatch({
        type: 'SET_PAGE',
        payload: 0,
      });
    } else if (data !== undefined) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          records: data,
          totalCount: totalCount,
        },
      });
    }
  };

  return {
    sortName,
    sortOrder,
    sortColumn,
    setSort,
    setFilter,
    applyFilters,
    clearFilters,
    removeFilters,
    resetFilters,
    getColumnVisibility,
    setData,
  };
};
