import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const FunctionBarSubItem = styled.div`
  align-items: center;
  border-radius: 17.5px;
  color: ${(props) => props.itemColor};
  display: flex;
  font-family: ${fontFamilies.family_Secondary};
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 5px;
  margin-top: 5px;
  /* min-height: 35px; */
  padding: 5px 10px;
  width: 100%;

  :first-child {
    margin-top: 15px;
  }

  :focus,
  :active,
  &.active {
    background-color: ${colors.bg_grey_2};
  }

  > span > div {
    margin-right: 10px;
  }

  > span {
    > svg {
      @media screen and (max-width: 960px) {
        fill: ${(props) => props.itemBackground};
        display: block;
        /* flex: 0 0 16px; */
        height: 16px;
        margin-right: 10px;
        width: 16px;
      }
    }
  }
`;
