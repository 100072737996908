// Style
import * as S from './TimeIndicator.styled';

const TimeIndicator = ({ value, showBubble }) => {
  return (
    <S.TimeIndicator position={value} showBubble={showBubble}>
      <div className="timeIndicator__bubble"></div>
      <div className="timeIndicator__line"></div>
    </S.TimeIndicator>
  );
};

export default TimeIndicator;
