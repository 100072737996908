import { Loader } from 'components/Loader/Loader';

const Bills = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Bills;
