import Decimal from 'decimal.js-light';

Number.prototype.truncate = function (decimalPlaces) {
  return new Decimal(this)
    .toDecimalPlaces(decimalPlaces, Decimal.ROUND_DOWN)
    .toNumber();
};

Number.prototype.minus = function (number) {
  return new Decimal(this).minus(number).toNumber();
};

Number.prototype.divideBy = function (number) {
  return new Decimal(this).dividedBy(number).toNumber();
};

Number.prototype.multiply = function (number) {
  return new Decimal(this).times(number).toNumber();
};
