import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';

// style
import * as S from './InputValueDuration.styled';

// hooks
import { useDebouncedCallback } from 'use-debounce';
import Tooltip from 'components/Tooltip/Tooltip';

export const InputValueDuration = ({
  id,
  disabled,
  value,
  defaultValue,
  updateValue,
  isCustom,
  tooltipTitle,
  tooltipContents,
  hasValueChanged,
  ...props
}) => {
  const tooltipId = `tooltip-${id}-${new Date().getTime()}`;

  // select focused value with marker
  const selectValue = (e) =>
    (e.target.name === 'hours' || e.target.name === 'minutes') &&
    e.target?.select();

  // const isEmpty = (context) => ['', '00'].includes(context);
  const isEmpty = (context) => [''].includes(context);

  // override delete and backspace keys
  const validateInput = (e) => {
    let localHours = hours;
    let localMinutes = minutes;
    if (['Delete', 'Backspace'].includes(e.key)) {
      if (e.target.name === 'hours') {
        localHours = '';
        setHours(localHours);
      } else if (e.target.name === 'minutes') {
        localMinutes = '';
        setMinutes(localMinutes);
      }
      if (isEmpty(localHours) && isEmpty(localMinutes)) {
        debounceValidate(e);
      }
    }
  };

  const debounceValidate = useDebouncedCallback((e) => validateValue(e), 500);

  // add leading '0' if only one digit has been input
  const fixValue = (e) => {
    // console.log({ e: e.target });
    if (!/^\d+$/.test(e.key)) {
      return; // allow only numbers
    }

    const perspectiveValue = `${
      (e.target.selectionStart === e.target.selectionEnd &&
        e.target.value.substr(1)) ||
      '0'
    }${e.key}`;

    if (e.target.name === 'hours') {
      setHours(perspectiveValue);
    } else {
      setMinutes(perspectiveValue);
    }
    debounceValidate(e);
  };

  const validateValue = (e) => {
    console.log('validating');
    // the updated state might be required inside effect
    let localHours = hours;
    let localMinutes = minutes;

    if (e.target.name === 'hours' && Number(e.target.value) > 23) {
      // autofix to max 23 hours
      setHours('23');
      localHours = '23';
    }
    if (e.target.name === 'minutes' && Number(e.target.value) > 59) {
      // autofix to max 59 minutes
      setMinutes('59');
      localMinutes = '59';
    }

    // if blurred away from both fields, update state
    if (e.target?.parentNode !== e.relatedTarget?.parentNode) {
      window.getSelection().removeAllRanges();

      // if one value is empty or '00', and another is not empty, set the empty value to '00'
      if (isEmpty(localHours) && !isEmpty(localMinutes)) {
        localHours = '00';
        setHours(localHours);
      }
      if (!isEmpty(localHours) && isEmpty(localMinutes)) {
        localMinutes = '00';
        setHours(localMinutes);
      }

      if (value !== localHours + ':' + localMinutes) {
        // console.log({ value, localHours, localMinutes, defaultValue });

        if (isEmpty(localHours) && isEmpty(localMinutes)) {
          console.log('unequal empty');
          value &&
            value !== tooltipContents &&
            updateValue(null, { reset: true }); // store null value into cache only if differs from sector value
          hasValue && setHasValue(false);
        } else {
          console.log('unequal not empty');
          updateValue(convertToMinutes(localHours, localMinutes));
          !hasValue && setHasValue(true);
        }
      }
    }

    if ((!isEmpty(localHours) || !isEmpty(localMinutes)) && !hasValue) {
      setHasValue(true);
    }
  };

  const convertToMinutes = (hours, minutes) =>
    Number(hours) * 60 + Number(minutes);

  const [hours, setHours] = useState(
    `${(value || defaultValue).split(':')[0] || ''}`,
  );
  const [minutes, setMinutes] = useState(
    `${(value || defaultValue).split(':')[1] || ''}`,
  );

  const [hasValue, setHasValue] = useState(!!value);

  useEffect(() => {
    // console.log({ value, defaultValue });
    setHours(`${value.split(':')[0] || ''}`);
    setMinutes(`${value.split(':')[1] || ''}`);
    value ? setHasValue(true) : setHasValue(false);
  }, [value, defaultValue, hasValue]);

  const resetToSector = () => {
    const defaultValueArr = tooltipContents.split(':');
    setHours(defaultValueArr[0]);
    setMinutes(defaultValueArr[1]);
    updateValue(null, { reset: true });
  };

  const commonProps = {
    type: 'text',
    placeholder: '--',
    pattern: '[0-9]{2,2}',
    maxLength: 2,
    size: 2,
    disabled,
    onKeyDown: fixValue,
    // onBlur: validateValue,
    onChange: () => null,
    autoComplete: 'off',
  };

  return (
    <S.InputValueDuration
      className={classNames(props.className, isCustom && 'custom')}
      hasValue={hasValue}
      isChanged={hasValueChanged}
      disabled={disabled}
      onKeyDown={validateInput} // intercept Delete, Backspace
      onClick={selectValue} // mark value with selector
      {...(isCustom && {
        'data-tooltip-id': tooltipId,
        'data-tooltip-html': value
          ? ReactDOMServer.renderToStaticMarkup(
              <div className="centered">
                {tooltipTitle}
                {tooltipTitle && (tooltipContents || '-') && <br />}
                {tooltipContents || '-'}
              </div>,
            )
          : undefined,
      })}
    >
      <input name="hours" value={hours} {...commonProps} />
      <span>:</span>
      <input name="minutes" value={minutes} {...commonProps} />

      <div className="reset" onClick={resetToSector}>
        &ndash;
      </div>

      <Tooltip id={tooltipId} variant="light" opacity={1} />
    </S.InputValueDuration>
  );
};

export default InputValueDuration;
