import { getTimeFromMinutes } from 'common/helpers/timeCalculations';

// style
import * as S from './ServiceCardComposition.styled';

// hooks
import useNumber from 'hooks/useNumber';
import { useSystemGetUnits } from 'services/systemService';

const ServiceCardComposition = ({ composition }) => {
  const { getUnitsById } = useSystemGetUnits();
  const { formatUnits } = useNumber({ price: true });

  return (
    <S.ServiceCardComposition>
      <thead>
        <tr>
          <th>Komplekto paslaugos</th>
          <th>Trukmė / Talpa</th>
          <th>Kiekis</th>
        </tr>
      </thead>
      <tbody>
        {composition.map((service, i) => {
          const unitShortName = getUnitsById(service.unitId)?.label;

          return (
            <tr key={i}>
              <td>{service.name}</td>
              <td>
                {service?.unitId !== null && service?.unitId !== undefined
                  ? formatUnits(service.unitVolume) +
                    (!!unitShortName && ` ${unitShortName}`)
                  : getTimeFromMinutes(service.unitDurationInMinutes)}
              </td>
              <td>{service.quantity}</td>
            </tr>
          );
        })}
      </tbody>
    </S.ServiceCardComposition>
  );
};

export default ServiceCardComposition;
