import { useCallback, useContext, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import AutoNumeric from 'autonumeric';

// components
import { ReactNumeric } from 'components/ReactNumeric/ReactNumeric';
import { Tooltip } from 'components/Tooltip/Tooltip';

// style
import * as S from './InputValueNumber.styled';

// hooks
import { useSystemGetCultureById } from 'services/systemService';
import { UserContext } from 'context/UserContext';
import { useNumber } from 'hooks/useNumber';

export const InputValueNumber = ({
  id,
  defaultValue,
  value,
  updateValue,
  disabled,
  isCustom,
  hasValueChanged,
  tooltipTitle,
  tooltipContents,
  ...props
}) => {
  const { locale } = useContext(UserContext);

  const { formatPrice } = useNumber({ price: true });

  const ref = useRef(null);

  const tooltipId = `tooltip-${id}-${new Date().getTime()}`;

  const { isSuccess: isCultureSuccess, data: culture } =
    useSystemGetCultureById({ id: locale });

  const format = useCallback(
    (value) => {
      if ([undefined, null].includes(value)) return '';
      else
        return AutoNumeric.format(value, {
          decimalPlaces: 4,
          ...(culture && {
            decimalCharacter:
              culture?.numberFormatInfo?.currencyDecimalSeparator,
          }),
        });
    },
    [culture],
  );

  const unformat = useCallback(
    (value) => {
      return Number(
        AutoNumeric.unformat(value, {
          decimalCharacter: culture?.numberFormatInfo?.currencyDecimalSeparator,
          digitGroupSeparator:
            AutoNumeric.options.digitGroupSeparator.noSeparator,
        }),
      );
    },
    [culture],
  );

  const validateValue = useCallback(
    (e) => {
      if (!e?.target) return; // required for onDrop event

      const scopedValue = e.target.value.replace(/^(-)|[^0-9.,]+/g, '$1');

      // passing by
      if (
        (scopedValue === '' && value === null) ||
        scopedValue === format(value)
      ) {
        console.log('passing by');
        return; // do nothing
      }

      // enter 0
      if (scopedValue && Number(scopedValue) === 0) {
        console.log('0 input');
        updateValue(Number(scopedValue));
        return;
      }

      // reset to sector
      if (scopedValue === '' && scopedValue !== format(value)) {
        console.log('reset to sector');

        ref.current.autonumeric.set(defaultValue); // restore default value in input field
        updateValue(null, { reset: true });
      } else {
        console.log('set value');

        updateValue(unformat(scopedValue));
      }
    },
    [value, updateValue, defaultValue, format, unformat],
  );

  // useEffect(() => console.log({ defaultValue }), [defaultValue]);
  // useEffect(() => console.log({ value }), [value]);
  // useEffect(() => console.log({ tooltipContents }), [tooltipContents]);

  return (
    <S.InputValueNumber
      className={classNames(props.className, isCustom && 'custom')}
      {...(isCustom && {
        'data-tooltip-id': tooltipId,
        'data-tooltip-html': value
          ? ReactDOMServer.renderToStaticMarkup(
              <div className="centered">
                {tooltipTitle}
                {tooltipTitle && (tooltipContents || '-') && <br />}
                {formatPrice(tooltipContents, { decimalPlaces: 4 }) || '-'}
              </div>,
            )
          : undefined,
      })}
      disabled={disabled}
      isChanged={hasValueChanged}
    >
      <ReactNumeric
        ref={ref}
        value={value}
        onBlur={validateValue}
        onDrop={validateValue}
        modifyValueOnWheel={false}
        minimumValue="0.00"
        maximumValue="999999.99"
        disabled={disabled}
        {...(isCultureSuccess &&
          culture?.numberFormatInfo && {
            decimalPlaces: +culture.numberFormatInfo.numberDecimalDigits,
            decimalCharacter: culture.numberFormatInfo.numberDecimalSeparator,
            decimalCharacterAlternative:
              culture.numberFormatInfo.numberDecimalSeparator === ','
                ? '.'
                : ',',
            digitGroupSeparator: culture.numberFormatInfo.numberGroupSeparator,
            digitalGroupSpacing:
              culture.numberFormatInfo.numberGroupSizes[0].toString(),
          })}
        {...props}
      />

      <div className="reset" onClick={() => updateValue(null)}>
        &ndash;
      </div>

      <Tooltip id={tooltipId} variant="light" opacity={1} />
    </S.InputValueNumber>
  );
};

export default InputValueNumber;
