import styled, { keyframes } from 'styled-components/macro';

import { colors } from 'common/colors/colors';

// keyframes
const ldsEllipsis1 = keyframes`
    0% {
        transform: scale(0);
    };
    100% {
        transform: scale(1);
    };
`;

const ldsEllipsis3 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`;

const ldsEllipsis2 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
`;

export const Loader = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;

  div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: ${colors.bg_Primary};
    border-radius: 100px;
    height: 18px;
    top: -9px;
    position: absolute;
    width: 18px;

    :nth-child(1) {
      animation: ${ldsEllipsis1} 0.6s infinite;
      left: -1px;
    }

    :nth-child(2) {
      animation: ${ldsEllipsis2} 0.6s infinite;
      left: -1px;
    }

    :nth-child(3) {
      animation: ${ldsEllipsis2} 0.6s infinite;
      left: 23px;
    }

    :nth-child(4) {
      animation: ${ldsEllipsis3} 0.6s infinite;
      left: 47px;
    }
  }
`;
