// style
import * as S from './TermsAndConditions.styled';

// components
import { PageHeader } from 'components/PageHeader/PageHeader';
import { Footer } from 'components/Footer/Footer';

// icons

// context

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

export const TermsAndConditions = ({ setStage, setNext }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  // useEffect(
  //   () => console.log({ user, state, options }),
  //   [user, state, options]
  // );

  return (
    <S.TermsAndConditions>
      <S.RootContainer>
        <PageHeader />

        <S.ScrollableContainer>
          <S.Container></S.Container>
        </S.ScrollableContainer>
      </S.RootContainer>

      {/* {!isMobile && <Footer content={2} />} */}
    </S.TermsAndConditions>
  );
};

export default TermsAndConditions;
