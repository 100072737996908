import React from 'react';
import PropTypes from 'prop-types';

// Style
import * as S from './OrderDetailsFooterCell.styled';

const OrderDetailsFooterCell = ({
  topText,
  bottomText,
  inputState,
  action,
  opacityBg,
  background,
}) => {
  // Functions
  const handleInputChange = (e) => {
    e.target.style.width = e.target.value.length + 2 + 'ch';
    inputState.setDiscountValue(e.target.value);
  };

  return (
    <S.FooterCell
      button={action}
      onClick={action}
      opacityBg={opacityBg}
      background={background}
      clickable={!!action}
    >
      <div className="footerCell_card">
        {inputState ? (
          <div className="inputWrapper">
            <input
              className="topInput"
              type="number"
              value={inputState.discountValue}
              onChange={(e) => handleInputChange(e)}
              onFocus={(e) => e.target.select()}
            />
            €
          </div>
        ) : (
          <span className="topSpan">{topText}</span>
        )}
        <span className="bottomSpan">{bottomText}</span>
      </div>
    </S.FooterCell>
  );
};

OrderDetailsFooterCell.propTypes = {
  topText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottomText: PropTypes.string,
  inputState: PropTypes.object,
  action: PropTypes.func,
};

export default OrderDetailsFooterCell;
