export const generateNewService = (service) => {
  const composition = (service.composition || service.osComposition)?.map(
    (item) => generateNewService(item),
  );

  return {
    ...service,
    id: service?.id || service.serviceId,
    serviceId: service.id || service.serviceId,
    name: service.name || service.serviceName,
    unitPriceWithVat:
      service?.empUnitPriceWithVat ??
      service?.sectorUnitPriceWithVat ??
      service?.unitPriceWithVat ??
      0,
    unitPriceWithVatNorm:
      service?.empUnitPriceWithVat ??
      service?.sectorUnitPriceWithVat ??
      service?.unitPriceWithVatNorm ??
      null,
    discountWithVat: service?.discountWithVat || 0.0,
    // sectorUnitPriceWithVat: 0.99,
    // sectorVatPercent: 9.0,
    vatPercent: service?.sectorVatPercent ?? service?.vatPercent ?? null,
    vatPercentNorm:
      service?.vatPercentNorm ?? service?.sectorVatPercent ?? null,
    hasComp: service.hasComp || service.hasComposition,
    quantity: service.compServiceCount || service.quantity || 1,
    composition,
    ...(!!service.unitVolume && { unitVolume: service.unitVolume }),
    ...(service.unitId !== undefined && { unitId: service.unitId }),
    smsPeriodCustomer: service?.smsPeriodCustomer ?? null,

    // FXIME: refactor
    ...(service.id !== 155 && {
      // unitDurationInMinutes: service.baseDurationInMinutes || 155,
      ...((service.baseDurationInMinutes !== undefined ||
        service.unitDurationInMinutes !== undefined) && {
        unitDurationInMinutes:
          service.baseDurationInMinutes || service.unitDurationInMinutes,
      }),
      ...(service.empDurationInMinutes && {
        unitDurationInMinutes: service.empDurationInMinutes,
      }),
      breakInMinutes: service.empBreakInMinutes || service.baseBreakInMinutes,
    }),
    baseDurationInMinutes: service.unitDurationInMinutes,
  };
};

export const modifyOrderCopy = (order, item, override) => {
  if (!item) return;
  const orderCopy = structuredClone(order);
  orderCopy.savedProducts = override ? [] : orderCopy.savedProducts;

  const addToOrderCopy = (item) => {
    orderCopy.savedProducts.push(item);
    if (item.breakInMinutes) {
      orderCopy.savedProducts.push({
        serviceId: 1,
        unitDurationInMinutes: item.breakInMinutes,
        quantity: 1,
      });
    }
  };

  if (Array.isArray(item)) {
    item.forEach((el) => addToOrderCopy(el));
  } else {
    addToOrderCopy(item);
  }

  return orderCopy;
};
