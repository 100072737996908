import styled from 'styled-components/macro';
import { TableScrollableContainer } from 'components/Tables/Table/Table.styled';
import { Container } from 'layouts/MainLayout/MainLayout.styled';
export { Container };

export const Employees = styled.div`
  ${TableScrollableContainer} {
    // TODO: find better solution
    height: calc(100vh - 260px);
  }
`;
