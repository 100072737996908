import { useContext } from 'react';
import { DateTime } from 'luxon';

// contexts
import { UserContext } from 'context/UserContext';

// hooks
import { getTimeString } from 'common/helpers/dateOperations';

export const useAppointment = () => {
  const { locale } = useContext(UserContext);

  const getDateAndTime = (startTime, endTime, timezoneIanaId) => {
    let jsDate = new Date();

    if (startTime && timezoneIanaId) {
      jsDate = DateTime.fromISO(startTime, {
        zone: timezoneIanaId,
      }).toJSDate();
    }

    return {
      startTimeString: getTimeString(startTime, timezoneIanaId, locale, false),
      endTimeString: getTimeString(endTime, timezoneIanaId, locale, false),
      jsDate,
    };
  };

  return { getDateAndTime };
};
