import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const WorkingAreasSettings = styled.div`
  width: 100%;
  max-width: 750px;

  .form-container {
    background-color: ${colors.bg_grey_1};
    padding: 30px;

    border-radius: 10px;

    width: 100%;
    max-width: 750px;
  }

  .working-areas__check-box-container {
    display: flex;
    margin-bottom: 20px;

    p {
      padding: 0;
      margin: 0;
      margin-right: 10px;

      font-size: 14px;
      color: ${colors.bg_grey_3};

      a {
        font-weight: 600;
        color: inherit;
      }
    }
  }

  .working-areas__list-container {
    display: flex;
  }

  .working-areas__list-number {
    margin-right: 20px;

    width: 100%;
    max-width: 100px;

    label {
      display: block;
      font-size: 14px;
      color: ${colors.bg_grey_3};
      margin: 0 10px 5px;
    }

    .working-areas__number {
      background-color: white;
      border-radius: 4px;
      height: 36px;
      margin-bottom: 10px;
      padding: 0 10px;

      display: flex;
      align-items: center;

      font-size: 14px;
    }
  }

  .working-areas__list-names {
    flex: 1;
  }

  @media screen and (max-width: 960px) {
    padding: 10px;

    .form-container {
      border-radius: 5px;
      padding: 10px;
    }

    .working-areas__list-number {
      margin-right: 10px;
      max-width: 50px;
    }
  }
`;
