import { useEffect, useRef } from 'react';
import { Zoom, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// style
import * as S from './Intro.styled';

// components
import { TheButton } from 'components/Buttons/TheButton';
import { Carousel3d } from 'components/Carousel3d/Carousel3d';

// images
import Fig01 from 'images/fig-01.png';
import Fig02 from 'images/fig-02.jpeg';
import Fig03 from 'images/fig-03.jpeg';
import Fig01m from 'images/fig-01-mobile.png';
import Fig02m from 'images/fig-02-mobile.jpeg';
import Fig03m from 'images/fig-03-mobile.jpeg';

// hooks
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useTranslate } from 'hooks/useTranslate';
import { useTouch } from 'hooks/useTouch';

export const Intro = ({ setStage }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const tr = useTranslate().use().global;
  const touchFrameRef = useRef(null);
  const carousel3dRef = useRef(null);

  const proceed = () => setStage('select-auth');

  const slideRef = useRef();
  let figures;

  if (isMobile) {
    figures = [
      {
        url: Fig01m,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (01)
          </>
        ),
      },
      {
        url: Fig01m,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (02)
          </>
        ),
      },
      {
        url: Fig01m,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (03)
          </>
        ),
      },
      {
        url: Fig01m,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (04)
          </>
        ),
      },
      {
        url: Fig01m,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (05)
          </>
        ),
      },
    ];
  } else {
    figures = [
      {
        url: Fig01,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (01)
          </>
        ),
      },
      {
        url: Fig02,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (02)
          </>
        ),
      },
      {
        url: Fig03,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (03)
          </>
        ),
      },
      {
        url: Fig02,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (04)
          </>
        ),
      },
      {
        url: Fig02,
        description: (
          <>
            <div className="title">{tr['perfect-service-scheduler']}</div>
            {tr['perfect-service-scheduler-description']} (05)
          </>
        ),
      },
    ];
  }

  const getShadow = () => {
    const shadow1 = document.createElement('div');
    shadow1.classList.add('bg-1');
    const shadow2 = document.createElement('div');
    shadow2.classList.add('bg-2');
    shadow1.append(shadow2);
    return shadow1;
  };

  const changeCorrespondingDescription = (from, to) => {
    const image = document.getElementById('image-' + to);
    const imageContainers = Array.from(
      image.closest('.react-slideshow-fadezoom-images-wrap').children,
    );

    const currentImage = imageContainers.find((imageContainer) =>
      imageContainer.getAttribute('style').includes('opacity: 1'),
    );

    const shadow = getShadow();

    if ((to === 0 && from === figures.length - 1) || to - from === 1) {
      // moving forward
      if (currentImage.nextSibling) {
        currentImage.nextSibling.appendChild(shadow);
      } else {
        currentImage.parentElement.firstChild.appendChild(shadow);
      }

      slideRef?.current?.goNext(); // trigger text slide
    } else {
      // moving backward
      if (currentImage.previousSibling) {
        currentImage.previousSibling.appendChild(shadow);
      } else {
        currentImage.parentElement.lastChild.appendChild(shadow);
      }

      slideRef?.current?.goBack(); // trigger text slide
    }

    if (currentImage.children.length > 1) {
      currentImage.lastChild.remove();
    }
  };

  useEffect(() => {
    // initial shadows
    const firstImage =
      document.getElementById('image-0')?.parentElement?.parentElement;
    firstImage?.appendChild(getShadow());
  }, []);

  const canSwipe = useRef(true);
  useTouch({
    touchFrameRef,
    onSwipe: (direction) => {
      if (!canSwipe.current) {
        return;
      }

      if (direction === 'left') {
        slideRef?.current?.goNext();
        carousel3dRef.current.slide('right');
      } else if (direction === 'right') {
        slideRef?.current?.goBack();
        carousel3dRef.current.slide('left');
      }
    },
  });

  return (
    <S.Intro ref={touchFrameRef}>
      <div className="slider-container">
        {isMobile ? (
          <Carousel3d
            ref={carousel3dRef}
            handleDescriptionChange={changeCorrespondingDescription}
            images={figures.map((item) => item.url)}
          />
        ) : (
          <Zoom
            indicators={true}
            scale={1.8}
            transitionDuration={500}
            onStartChange={changeCorrespondingDescription}
            autoplay={false}
          >
            {figures.map((figure, index) => (
              <div key={index}>
                <img src={figure.url} alt="ads" id={'image-' + index} />
              </div>
            ))}
          </Zoom>
        )}
      </div>

      <div className="description-container">
        <Slide
          arrows={false}
          transitionDuration={500}
          canSwipe={true}
          easing="cubic"
          autoplay={false}
          ref={slideRef}
          onStartChange={() => {
            canSwipe.current = false;
          }}
          onChange={() => {
            canSwipe.current = true;
          }}
        >
          {figures.map((figure, index) => (
            <div className="description" key={index}>
              {figure.description}
            </div>
          ))}
        </Slide>
      </div>

      {isMobile && (
        <TheButton
          title={tr['sign-in'] + ' →'}
          primary
          height={'35px'}
          width={'100%'}
          action={proceed}
        />
      )}
    </S.Intro>
  );
};
