import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const SchedulerCalendarDouble = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    gap: 10px;
  }

  .pickers {
    column-gap: 40px;
    display: flex;
    flex-wrap: nowrap;
    row-gap: 10px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    > * {
      flex: 0 0 calc(50% - 20px);
      min-width: 220px;

      @media screen and (max-width: 600px) {
        flex: 1 0 100%;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: right;
  }

  .reset-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${colors.bg_grey_3};
    display: flex;
    font-size: 0.75rem;

    :hover {
      color: ${colors.text_Secondary};
    }

    :active {
      font-weight: bold;
    }

    svg {
      margin-right: 4px;
    }
  }

  label {
    color: ${colors.text_Secondary};
    font-size: 1.125rem;
    font-weight: 900;
    min-width: 30px;

    @media screen and (max-width: 960px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 0.875rem;
    }
  }

  .react-calendar {
    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view {
    /* max-width: 250px; */
  }

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 20px;
  }

  .react-calendar__navigation__label {
    background-color: white;
    border: none;
    color: ${colors.calendar_color_Primary};
    cursor: pointer;
    /* font-family: ${fontFamilies.family_Primary}; */
    font-size: 0.875rem;
    font-weight: 600;
    margin-left: 2px;
    padding: 0;

    @media screen and (max-width: 600px) {
      background-color: transparent;
      /* font-size: 1.125rem; */
      margin-left: ${({ close }) => close && 35}px;
    }
  }

  .react-calendar__navigation__arrow {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
  }

  .react-calendar__viewContainer {
    display: flex;
    gap: 40px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .react-calendar__tile,
  .react-calendar__month-view__days__day,
  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: white;
    border: none;
    color: ${colors.calendar_date_text_not_selected};

    :hover abbr {
      /* background-color: hsl(0, 0%, 95%); */
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .react-calendar__tile {
    margin-bottom: 1px;
    margin-top: 1px;
    padding: 0;
  }

  .react-calendar__tile,
  abbr {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
  }

  // marker for hover and range tiles
  .react-calendar__tile--hasActive {
    &.react-calendar__century-view__decades__decade,
    &.react-calendar__decade-view__years__year,
    &.react-calendar__year-view__months__month,
    &.react-calendar__month-view__days__day {
      background-color: ${colors.bg_Button_Secondary};
      border-radius: 12.5px;
      color: white;

      :hover {
        color: white;
      }
    }
  }

  .react-calendar__century-view__decades__decade,
  .react-calendar__decade-view__years__year,
  .react-calendar__year-view__months__month,
  .react-calendar__month-view__days__day {
    &.disabled {
      /* color: ${colors.bg_grey_2}; */
      text-decoration: line-through;
    }

    abbr {
      border-radius: 50%;
      max-height: 25px;
      max-width: 25px;
      min-height: 25px;
      min-width: 25px;
      font-family: ${fontFamilies.family_Secondary};
      font-size: 0.875rem;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${colors.calendar_diff_month_day};
    pointer-events: none;
    visibility: hidden;
  }

  .react-calendar__month-view__weekdays {
    color: ${colors.calendar_color_Primary};
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    text-transform: capitalize;
  }

  /* year ir month views */

  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
    font-size: 0.875rem;
    margin-bottom: 2.5px;
    margin-top: 2.5px;

    &:hover {
      color: ${colors.calendar_color_Primary};
    }
  }

  .react-calendar__year-view__months__month abbr {
    border-radius: 12.5px;
    width: 100%;
  }

  .react-calendar__month-view__years__year {
  }

  .react-calendar__century-view__decades {
    justify-content: space-between;
  }

  .react-calendar__century-view__decades__decade {
    flex-basis: 48% !important;
    padding: 0;
  }

  .today {
    abbr {
      color: ${colors.button_Secondary};
      font-weight: 900;
    }
  }

  .has-values {
    position: relative;
  }

  .has-values:after {
    content: '';
    background: ${colors.calendar_color_Primary};
    border-radius: 50%;
    height: 3px;
    position: absolute;
    transform: translate(0, 8px);
    width: 3px;
  }

  .has-values.react-calendar__tile--rangeStart:after,
  .has-values.react-calendar__tile--rangeEnd:after {
    background: ${colors.calendar_button_text_color};
  }

  .react-calendar__navigation__label__divider {
    position: relative;

    :after {
      background: white;
      content: '…';
      left: 2px;
      position: absolute;
    }
  }
`;
