import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';
import styled from 'styled-components/macro';

export const ServiceCardComposition = styled.table`
  margin-top: 20px;
  font-size: 0.875rem;

  thead {
    font-family: ${fontFamilies.family_SecondaryCondensed};
    color: ${colors.bg_grey_3};

    tr {
      th {
        font-weight: 400;
        min-width: 85px;
        text-align: right;

        &:nth-child(1) {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 8px 0;

        text-align: right;

        &:nth-child(1) {
          text-align: left;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
`;
