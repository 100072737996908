import { useContext, useMemo } from 'react';
import { createTranslations } from 'react-ridge-translations';

import languages from 'common/language/languages.json';
import * as MainLng from 'pages/Main/Main.lng.json';
import * as SideBarFilterLng from 'components/SideBar/SideBarFilter/SideBarFilter.lng.json';
import * as PaginationLng from 'components/Pagination/Pagination.lng.json';

// hooks
import { UserContext } from 'context/UserContext';

const deviceLanguage = navigator.language;
const availableLanguages = ['en', 'lt'];
const fallback = 'en';

const getBestLanguage = (currLocale) => {
  const availableLang =
    availableLanguages.find((ln) => deviceLanguage.startsWith(ln)) || fallback;

  return currLocale || availableLang;
};

export const useTranslate = () => {
  const { language } = useContext(UserContext);

  return useMemo(
    () =>
      createTranslations()(
        {
          global: languages,
          main: MainLng,
          filter: SideBarFilterLng,
          pagination: PaginationLng,
        },

        {
          language: getBestLanguage(language),
          fallback,
        },
      ),
    [language],
  );
};

export default useTranslate;
