import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { shadow } from 'common/styles/shadows';
import { fontFamilies } from 'common/fonts/fonts';

export const CustomerInfo = styled.section`
  .selected-client__header {
    display: flex;
    align-items: baseline;

    h2 {
      color: ${colors.text_Secondary};
      font-size: 1.875rem;
      font-weight: 600;
      margin: 20px 60px 20px 0;
      padding-bottom: 2px;
    }

    button {
      width: fit-content;
      padding: 0 30px;
      height: 35px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .selected-client__main {
    display: flex;
    flex-flow: wrap-reverse;

    background-color: ${colors.bg_grey_1};
    padding: 20px 20px 10px 20px;
    border-radius: 10px;

    .orders__wrapper {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }

    .client__pending-orders-container {
      flex: 1 1;

      margin-bottom: 10px;
    }

    .pending-orders {
      margin-right: 20px;
      min-height: 115px;

      display: flex;
      justify-content: center;

      flex-direction: column;

      > div {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .client__info-container {
      flex-basis: 30%;
      min-width: 400px;

      margin-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      flex: 0;
    }

    .client__cart-container {
      width: 50%;
    }

    .cart {
      margin-right: 20px;
      min-height: 115px;

      justify-content: ${(props) =>
        props.cart ? 'space-between' : 'flex-start'};
      align-items: center;

      background-color: ${(props) => props.cart && 'white'};
      border-radius: 10px;
      padding: ${(props) => (props.cart ? 20 : 0)}px;
      padding-bottom: 0;
      display: flex;
      flex-wrap: wrap;

      > div {
        margin-bottom: 20px;
      }
    }

    .client__card-container {
      width: 50%;
    }

    .card {
      display: flex;
      justify-content: space-between;
      align-items: center;

      flex-direction: column;

      background-color: white;
      height: 115px;
      border-radius: 10px;
      padding: 20px;

      span {
        background-color: ${colors.bg_grey_1};
        border-radius: 10px;
        width: 100%;
        height: 32px;
        padding: 0 15px;

        display: flex;
        align-items: center;

        font-size: 14px;
        font-family: ${fontFamilies.family_Secondary};

        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .selected-client__header {
      display: none;
    }

    .selected-client__main {
      background-color: white;
      border-radius: 0;
      padding: 20px 10px;

      h4 {
        font-size: 16px;
      }

      .client__pending-orders-container {
      }

      .pending-orders {
        margin-right: 0;
        min-height: 60px;

        > div {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .client__info-container {
        flex: 1;
        min-width: 100%;
      }

      .client__cart-container {
        width: 100%;
      }

      .cart {
        background-color: ${colors.bg_grey_1};
        border-radius: 5px;
        margin-right: 0;
        min-height: 60px;
        padding: ${(props) => (props.cart ? 5 : 0)}px;
        justify-content: flex-start;
        margin-bottom: 10px;

        > div {
          margin-bottom: 0px;
          margin-right: 5px;
          background-color: white;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .client__card-container {
        display: none;
      }

      .none {
        color: ${colors.bg_grey_3};
        padding: 10px;
      }
    }
  }
`;

export const CartCard = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${colors.bg_grey_1};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  position: relative;

  transition: 0.1s ease-in-out;

  .icon {
    svg {
      height: 30px;
      width: 100%;
    }
  }

  .tooltip {
    display: none;
    position: absolute;
    margin: 0;
    left: 50px;

    > span {
      border-radius: 10px;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
      padding: 5px 10px;
      position: relative;

      top: 16px;
      left: 0;
      z-index: 1;

      display: flex;
      flex-wrap: wrap;

      white-space: nowrap;

      background-color: white;
      border: 1px solid ${colors.bg_grey_2};
      box-shadow: ${shadow.lg};
    }
  }

  :hover {
    background-color: ${colors.bg_grey_2};

    .tooltip {
      display: unset;
    }
  }

  @media screen and (max-width: 960px) {
    width: 50px;
    height: 50px;
    border-radius: 5x;

    .icon {
      svg {
        height: 25px;
      }
    }

    :hover {
      .tooltip {
        display: none;
      }
    }
  }
`;
