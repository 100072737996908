import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';

export const ServiceContents = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: start;
  gap: 10px;

  :not(:first-child) {
    margin-top: 10px;
  }

  > .mark {
    align-items: ${({ isGroup }) => (isGroup ? 'flex-start' : 'center')};
    color: ${({ isDisabledByParent }) =>
      isDisabledByParent && colors.disabledGrey};
    display: flex;
    margin-top: ${({ isGroup }) => isGroup && '17px'};
    min-height: 50px;
    padding: 0;
  }

  > .details {
    border: 1px solid
      ${({ isGroup }) => (isGroup ? colors.bg_grey_2 : 'transparent')};
    border-radius: 5px;
    display: grid;
    grid-template-columns: minmax(min-content, 100%) 100px 70px 70px;
    grid-gap: 10px;
    flex: 1;
    padding-right: 10px;

    > * {
      background-color: ${({ isGroup }) => !isGroup && colors.bg_grey_1};
      min-height: 50px;
    }

    .title {
      align-items: center;
      border-radius: 5px;
      display: flex;
      padding: 10px;

      svg {
        display: block;
        flex-shrink: 0;
      }

      .icon {
        margin-right: 10px;
      }
    }

    .subgroup {
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: -10px;

      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .amount {
      align-items: center;
      background-color: transparent;
      display: flex;
      gap: 5px;
      grid-column: span 2;
      padding-inline: 10px;
    }
  }

  &.subgroup > .title,
  &.subgroup .subgroup {
    grid-column: span 4;
  }
`;
