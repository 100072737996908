import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const RecordCell = styled.td`
  border-bottom: ${(props) => props.details && !props.last && 'transparent'};
  color: ${(props) => props.inActive && colors.bg_grey_3};
  /* text-align: left; */

  :not(:first-of-type) {
    border-left: ${(props) => props.details && 'transparent'};
  }

  &.record-navigation,
  &.record-delete {
    text-align: center;
  }

  &.record-navigation {
    filter: unset;
    color: ${colors.bg_Primary};
  }

  &.record-delete {
    filter: grayscale(1);
    color: ${colors.bg_grey_3};

    &.inactive {
      filter: unset;
      color: ${colors.bg_Primary};
    }
  }

  @media screen and (min-width: 961px) {
    padding: 2px 5px 0;
    color: ${colors.table_text_Primary};
    border-bottom: 1px solid ${colors.table_arrows_Primary};
    border-left: 1px solid ${colors.table_arrows_Primary};
    font-family: ${fontFamilies.family_Secondary};
    white-space: nowrap;

    :last-of-type {
      border-right: 1px solid ${colors.table_arrows_Primary};
    }

    &.center {
      text-align: center;

      div {
        margin: 0 auto;
      }
    }

    &.text-right {
      text-align: right;
    }
  }
`;
