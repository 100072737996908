import styled from 'styled-components/macro';
import { colors } from 'common/colors/colors';
import { fontFamilies } from 'common/fonts/fonts';

export const AppointmentsDetailsMobile = styled.div`
  background-color: white;

  .details {
    font-family: ${fontFamilies.family_Secondary};
    padding: 10px;

    &__customer-info {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;
      border-radius: 5px;
      background-color: ${colors.bg_grey_1};

      .full-name {
        display: flex;
        gap: 10px;
        font-size: 16px;
        font-weight: 900;
        font-family: ${fontFamilies.family_Primary};

        svg {
          align-self: flex-end;
          width: 15px;
          height: 15px;
        }
      }

      .phone {
        display: flex;
        align-items: center;

        font-size: 14px;
        gap: 10px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__container {
      padding: 10px;
      border-bottom: 1px solid ${colors.bg_grey_1};
      display: flex;
      flex-direction: column;
      gap: 10px;

      &:last-child {
        border-bottom: none;
      }

      &.row {
        flex-direction: row;
        align-items: center;
      }

      ul {
        margin: 0;
        padding: 0;
        padding-left: 13px;
        font-size: 14px;
        gap: 8px;
        display: flex;
        flex-direction: column;

        li span {
          margin-left: -3px;
        }
      }

      span,
      div {
        font-size: 14px;
        line-height: 1;
      }

      p {
        margin: 0;
        font-size: 14px;
      }

      .label {
        font-weight: 700;
      }

      .flex-gap-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .font-bold {
        font-weight: 700;
      }

      &.order-number {
        padding: 20px 10px;
        gap: 10px;
        display: flex;
        font-weight: 700;
        text-decoration: none;

        /* HACK FOR LINK UNDERLINE [START]~> */
        .wrapper {
          span {
            align-items: flex-end;
            display: flex;
          }
          .label {
            font-size: 16px;
          }
        }
        :hover {
          .wrapper {
            text-decoration: none;
            position: relative;

            :after {
              content: '';

              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;

              border-width: 0 0 1.5px;
              border-style: solid;
            }
          }
        }
        /* <~[END] HACK FOR LINK UNDERLINE */

        svg {
          width: 15px;
          height: 15px;
          margin-top: -1px;
        }
      }

      &.balance {
        .flex-gap-row {
          gap: 30px;

          div {
            gap: 6px;
            display: flex;
            flex-direction: column;
          }
        }

        .debit,
        .credit {
          font-size: 14px;
        }

        .credit {
          color: ${colors.button_Secondary};
          font-weight: 700;
        }
      }

      &.additional-info {
        ul {
          list-style: none;
          gap: 10px;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
              width: 16px;
              height: 16px;
            }

            > * {
              display: flex;
              justify-self: center;
            }
          }
        }
      }
    }

    &__status-bubble {
      background-color: ${(props) => props.statusColor};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      flex-shrink: 0;
      transition: background-color 300ms ease-in-out;
    }

    &__specialist span {
      padding: 10px 0;
    }

    &__avatar {
      width: 40px;
      height: 40px;
    }
  }
`;
