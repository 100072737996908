import { useEffect, useState } from 'react';

// components
import { DialogBox } from 'components/DialogBox/DialogBox';
import { Overlay } from 'components/Overlay/Overlay';

export const ExplodeComposition = ({ selectedComp, add }) => {
  const [displayDialogBox, setDisplayDialogBox] = useState(false);

  useEffect(() => {
    if (!selectedComp) return;

    switch (selectedComp.explodeOnAdd) {
      case 0:
      default:
        add(selectedComp);
        break;
      case 1:
        add(selectedComp.composition);
        break;
      case 2:
        setDisplayDialogBox(true);
        break;
    }
  }, [selectedComp]);

  return (
    <Overlay
      isVisible={displayDialogBox}
      hide={() => setDisplayDialogBox(false)}
    >
      <div className="modal-wrapper">
        <DialogBox
          headerText="Kaip įkelti norimą komplektą"
          close={() => setDisplayDialogBox(false)}
          buttons={[
            {
              title: 'Įkelti kaip komplektą',

              action: () => add(selectedComp),
              inverted: true,
            },
            {
              title: 'Įkelti kaip paslaugas',
              action: () => {
                if (!selectedComp.composition) return;
                add({ ...selectedComp, toSplit: true });
              },
            },
          ]}
        />
      </div>
    </Overlay>
  );
};

export default ExplodeComposition;
