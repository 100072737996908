import { useContext, useEffect, useRef, useState } from 'react';
import { PhoneInput } from 'react-international-phone';

// style
import * as S from './InputFieldPhone.styled';
import 'react-international-phone/style.css';

// context
import { UserContext } from 'context/UserContext';

// hooks
import ClickAwayListener from 'react-click-away-listener';
import useTranslate from 'hooks/useTranslate';

export const InputFieldPhone = ({
  id,
  label,
  disabled,
  state,
  ...restProps
}) => {
  const tr = useTranslate().use().global;

  const { locale } = useContext(UserContext);

  const [dropdownDisplayStyle, setDropdownDisplayStyle] = useState('none');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const phoneInputRef = useRef();
  const flagButtonRef = useRef();
  const dropdownRef = useRef();

  let delayedClose;

  const handleClick = (e) => {
    e.stopPropagation();
    clearTimeout(delayedClose);
    if (!flagButtonRef.current || !dropdownRef.current) {
      flagButtonRef.current = phoneInputRef.current.querySelector(
        '.react-international-phone-country-selector-button',
      );
      dropdownRef.current = phoneInputRef.current.querySelector(
        '.react-international-phone-country-selector-dropdown',
      );
    }
    // need timeout this to determine actual attribute value
    setTimeout(() => {
      setDropdownVisible(
        flagButtonRef.current.getAttribute('aria-expanded') === 'true',
      );
    });
  };

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    if (!dropdownVisible) {
      delayedClose = setTimeout(() => {
        flagButtonRef.current.setAttribute('aria-expanded', false);
        setDropdownDisplayStyle('none');
      }, 180);
    } else {
      flagButtonRef.current.setAttribute('aria-expanded', true);
      setDropdownDisplayStyle('block');
    }
  }, [dropdownVisible]);

  // log
  // useEffect(() => console.log(state), [state]);

  return (
    <ClickAwayListener
      mouseEvent="mousedown"
      onClickAway={() => dropdownVisible && flagButtonRef.current.click()}
    >
      <S.InputFieldPhone
        onClick={handleClick}
        dropdownDisplayStyle={dropdownDisplayStyle}
        dropdownVisible={dropdownVisible}
        ref={phoneInputRef}
        phoneInputRef={phoneInputRef.current}
      >
        <label htmlFor={id}>{label}</label>
        <div className="input-wrapper">
          {disabled ? (
            <div className="disabled-input">{state?.value?.value || '-'}</div>
          ) : (
            <PhoneInput
              defaultCountry={new Intl.Locale(locale).region.toLowerCase()}
              // disableDialCodeAndPrefix
              // showDisabledDialCodeAndPrefix
              value={state?.value?.value || ''}
              onChange={(phone) => state?.setValue(id, phone)}
              inputProps={{
                id,
                placeholder: tr['input'],
                disabled,
                ...restProps,
              }}
            />
          )}
        </div>
      </S.InputFieldPhone>
    </ClickAwayListener>
  );
};

export default InputFieldPhone;
