import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getItem as getTreeItem,
  updateTreeDownstream,
} from 'common/helpers/serviceFunctions';

export const useServiceCache = (initialCategories) => {
  const initialCache = useCallback(
    () => ({
      category: [],
      group: [],
      subgroup: [],
      service: [],
      set: [],
    }),
    [],
  );

  const [dbArray, setDbArray] = useState(initialCategories);
  const [cache, setCache] = useState(initialCache());
  const [activeGroup, setActiveGroup] = useState();

  const getDbItem = useCallback(
    (type, id, source) => getTreeItem(source || dbArray, id, type),
    [dbArray],
  );

  const getCachedItem = useCallback(
    (type, id, source) =>
      (source || cache)[type]?.find((item) => item.id === id),
    [cache],
  );

  const getItem = useCallback(
    (type, id, source) => {
      const mergedItem = {
        ...getDbItem(type, id, source),
        ...getCachedItem(type, id),
      };
      return mergedItem;
    },
    [getCachedItem, getDbItem],
  );

  const hasValueChanged = (type, id, propId) => {
    const cacheValue = cache[type].find((object) => object.id === id);
    return cacheValue && propId in cacheValue;
  };

  const hasAnyChanges = useCallback(
    () => !Object.values(cache).every((value) => value.length === 0),
    [cache],
  );

  const updateCache = useCallback(
    (type, id, propId, value, mutableCache) => {
      const cachedItem = getCachedItem(type, id, mutableCache);
      const dbItem = getDbItem(type, id);

      console.log('-----------------------------');
      console.log({ propId, cachedItem, dbItem });

      // Check if the cachedItem exists
      const cachedObjectExists = !!cachedItem;
      // const cachedValueExists =
      //   cachedObjectExists && Object.hasOwn(cachedItem, propId);
      // const cachedValue = cachedValueExists ? cachedItem[propId] : undefined;
      const dbValueExists = dbItem && Object.hasOwn(dbItem, propId);
      const dbValue = dbValueExists ? dbItem[propId] : undefined;

      const log = (contents) => console.log('%c' + contents, 'color: red;');

      const logVitals = () =>
        console.log({ id, propId, value, name: getDbItem(type, id).name });

      const update = (value, source) => {
        if (!source) source = cache;
        if (!cachedObjectExists) {
          log('no cached object: create');
          // logVitals();
          source[type] = [...(source[type] || []), { id, [propId]: value }];
        } else {
          log('cached object exists: update');
          source[type][source[type].findIndex((item) => item.id === id)][
            propId
          ] = value;
        }
      };

      const sweep = (value, source) => {
        if (!source) source = cache;
        if (value === dbValue || (value === null && !dbValueExists)) {
          log(
            (value === dbValue
              ? 'new value euqals db value'
              : value === null && !dbValueExists
                ? 'no respective prop in db for null value'
                : ''
            ).concat(': remove'),
          );
          // logVitals();
          delete source[type][source[type].findIndex((item) => item.id === id)][
            propId
          ];
        }
      };

      const maintain = (source) => {
        if (!source) source = cache;
        const cachedItem = getCachedItem(type, id, source);
        if (
          cachedItem &&
          Object.keys(cachedItem).length === 1 &&
          'id' in cachedItem
        ) {
          log('cached object empty: remove');
          // logVitals();
          source[type] = source[type].filter((item) => item.id !== id);
        }
      };

      const process = (value, source) => {
        // cache value update logic:
        if (value === null) {
          log('null value (reset to sector)');
        } else if (value === 0) {
          log('0 value');
        } else if (typeof value === 'boolean') {
          log('isEmpAssigned checkbox');
        } else {
          log('substantial value');
        }

        update(value, source); // set new value
        sweep(value, source); // remove new value if overlaps with db
        maintain(source); // if no keys (except id) after changes, remove changed object overall
      };

      if (mutableCache) {
        log('mutable cache');
        process(value, mutableCache);
      } else {
        log('conventional cache');
        setCache((old) => {
          const newCache = { ...old };
          process(value, newCache);
          return newCache;
        });
      }
    },
    [getCachedItem, getDbItem, cache],
  );

  // merge changes from cache into dbArray
  const mergeCacheIntoDb = useCallback((dbNode, cacheObj) => {
    // check if the current dbNode is in cache and merge its properties
    const cachedNode = cacheObj[dbNode.type]?.find(
      (item) => item.id === dbNode.id,
    );
    const mergedNode = cachedNode
      ? { ...dbNode, ...cachedNode }
      : structuredClone(dbNode);

    // recursively merge child arrays (groups, subgroups, services)
    if (mergedNode.groups) {
      mergedNode.groups = mergedNode.groups.map((group) =>
        mergeCacheIntoDb(group, cacheObj),
      );
    }

    if (mergedNode.subgroups) {
      mergedNode.subgroups = mergedNode.subgroups.map((subgroup) =>
        mergeCacheIntoDb(subgroup, cacheObj),
      );
    }

    if (mergedNode.services) {
      mergedNode.services = mergedNode.services.map((service) =>
        mergeCacheIntoDb(service, cacheObj),
      );
    }

    return mergedNode;
  }, []);

  const liveArray = useMemo(() => {
    // recursively merge cache into dbArray
    return dbArray?.map((category) => mergeCacheIntoDb(category, cache));
  }, [dbArray, cache, mergeCacheIntoDb]);

  // if activeGroup changed, include/update it in dbArray
  const hydrateDbGroup = useCallback((group) => {
    setDbArray((old) => {
      const newDbArray = [...old];
      let dbItem = getTreeItem(newDbArray, group?.id, group?.type);
      if (group) {
        if ('subgroups' in group) dbItem.subgroups = group.subgroups;
        if ('services' in group) dbItem.services = group.services;
      }
      return newDbArray;
    });
  }, []);

  const toggleActivity = useCallback(
    (type, id) => {
      const requiredObj = getItem(type, id).isEmpAssigned;

      const newValue = !requiredObj;
      updateCache(type, id, 'isEmpAssigned', newValue);
      console.log(
        `${type} %c${id}%c activity changed to: %c${newValue}`,
        'color: red;',
        'color: revert;',
        'color:lightgreen;',
      );
    },
    [getItem, updateCache],
  );

  const updatePropDownstream = useCallback(
    (data, prop, value) => {
      const mutableCache = { ...cache };

      if (data.groups) {
        updateTreeDownstream(
          data.groups,
          (item) =>
            item[prop] !== value &&
            updateCache(item.type, item.id, prop, value, mutableCache),
        );
      } else {
        data.subgroups &&
          updateTreeDownstream(
            data.subgroups,
            (item) =>
              item[prop] !== value &&
              updateCache(item.type, item.id, prop, value, mutableCache),
          );
        data.services &&
          updateTreeDownstream(
            data.services,
            (item) =>
              item[prop] !== value &&
              updateCache(item.type, item.id, prop, value, mutableCache),
          );
      }

      setCache(mutableCache);
    },
    [cache, updateCache],
  );

  const clearCache = useCallback(
    (config = {}) => {
      const { refresh } = config;
      if (refresh) {
        console.log('apply cache changes to db');
        setDbArray(() => liveArray); // set liveArray to current dbArray without refetching
      }

      setCache(initialCache()); // clear changes from cache
    },
    [initialCache, liveArray],
  );

  const getParentItem = useCallback(
    (type, id) => {
      const currentItem = getItem(type, id); // use getItem to fetch the current object

      if (!currentItem) return null; // return null if the object doesn't exist

      let parentId, parentType;

      // determine the parentId and parentType based on the current type
      switch (type) {
        case 'service':
        case 'set':
          // if the service belongs to a subgroup, parent is a subgroup
          parentId = currentItem.subgroupId || currentItem.groupId; // Check for subgroupId first, fallback to groupId
          parentType = currentItem.subgroupId ? 'subgroup' : 'group';
          break;
        case 'subgroup':
          parentId = currentItem.groupId;
          parentType = 'group';
          break;
        case 'group':
          parentId = currentItem.categoryId;
          parentType = 'category';
          break;
        case 'category':
        default:
          return null; // no parent for categories
      }

      // fetch the parent object using the getItem function
      return parentId ? getItem(parentType, parentId) : null;
    },
    [getItem],
  );

  const getAncestors = useCallback(
    (type, id) => {
      const ancestors = [];
      let currentItem = getParentItem(type, id);

      while (currentItem) {
        ancestors.push(currentItem);
        currentItem = getParentItem(currentItem.type, currentItem.id);
      }

      return ancestors;
    },
    [getParentItem],
  );

  const isDisabledByParent = useCallback(
    (type, id) =>
      getAncestors(type, id)?.some((ancestor) => !ancestor.isEmpAssigned),
    [getAncestors],
  );

  useEffect(
    () => console.log({ dbArray, cache, liveArray }),
    [dbArray, cache, liveArray],
  );

  return {
    cache,
    dbArray,
    liveArray,
    activeGroup,
    setActiveGroup,
    getDbItem,
    getItem,
    getCachedItem,
    toggleActivity,
    updateCache,
    hydrateDbGroup,
    clearCache,
    hasValueChanged,
    hasAnyChanges,
    setDbArray,
    isDisabledByParent,
    updatePropDownstream,
  };
};
