import styled from 'styled-components/macro';
import { shadow } from 'common/styles/shadows';

export const ScheduleHeader = styled.div`
  box-shadow: ${shadow.md};
  top: 0;
  display: flex;
  position: sticky;
  min-width: 100%;
  width: fit-content;
  z-index: 5;
  background-color: #fff;
  min-height: 110px;
  contain: paint;

  .flex {
    display: flex;
  }

  .container {
    width: 100%;
    transition: all 300ms ease-in-out 0s;

    .timeline {
      display: flex;
    }
  }

  .filler {
    z-index: 4;
    background-color: #fff;
    position: sticky;
    left: 0;
    min-width: ${(props) => (['en-US'].includes(props.locale) ? 87 : 57)}px;

    /* &__icon {
      box-shadow: ${shadow.sm};
      border-radius: 100%;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    } */
  }

  @media screen and (max-width: 961px) {
    min-height: 75px;

    .filler {
      min-width: ${(props) => (['en-US'].includes(props.locale) ? 61 : 41)}px;
    }
  }
`;
