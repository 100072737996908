import useTranslate from './useTranslate';

const useService = ({ service }) => {
  const tr = useTranslate().use().global;

  const smsDurationOptions = [
    { label: '—', value: null },
    { label: `3 ${tr['days-short']}.`, value: '3d' },
    { label: `5 ${tr['days-short']}.`, value: '5d' },
    { label: `1 ${tr['weeks-short']}.`, value: '1w' },
    { label: `2 ${tr['weeks-short']}.`, value: '2w' },
    { label: `3 ${tr['weeks-short']}.`, value: '3w' },
    { label: `4 ${tr['weeks-short']}.`, value: '4w' },
    { label: `5 ${tr['weeks-short']}.`, value: '5w' },
    { label: `6 ${tr['weeks-short']}.`, value: '6w' },
    { label: `1 ${tr['months-short']}.`, value: '1m' },
    { label: `2 ${tr['months-short']}.`, value: '2m' },
    { label: `3 ${tr['months-short']}.`, value: '3m' },
    { label: `4 ${tr['months-short']}.`, value: '4m' },
    { label: `5 ${tr['months-short']}.`, value: '5m' },
    { label: `6 ${tr['months-short']}.`, value: '6m' },
    { label: `18 ${tr['months-short']}.`, value: '18m' },
    { label: `1 ${tr['years-short']}.`, value: '1y' },
    { label: `2 ${tr['years-short']}.`, value: '2y' },
    { label: `3 ${tr['years-short']}.`, value: '3y' },
  ];

  const getTranslatedSmsDuration = (value) => {
    if (!value) return '—';
    let label = parseInt(value);

    if (value.includes('d')) {
      label += ' ' + tr['days-short'] + '.';
    } else if (value.includes('w')) {
      label += ' ' + tr['weeks-short'] + '.';
    } else if (value.includes('m')) {
      label += ' ' + tr['months-short'];
    } else if (value.includes('y')) {
      label += ' ' + tr['years-short'] + '.';
    }

    return label;
  };

  const getSmsDurationOptions = () => {
    let options = [...smsDurationOptions];
    let smsPeriodCustomerOption = options.find(
      (option) => option.value === service.smsPeriodCustomer,
    );

    if (!smsPeriodCustomerOption) {
      smsPeriodCustomerOption = {
        label: getTranslatedSmsDuration(service.smsPeriodCustomer),
        value: service.smsPeriodCustomer,
      };

      options.push(smsPeriodCustomerOption);
    }

    let smsPeriodOption = options.find(
      (option) => option.value === service.smsPeriod,
    );

    if (!smsPeriodOption && service?.smsPeriod) {
      smsPeriodOption = {
        label: getTranslatedSmsDuration(service.smsPeriod),
        value: service.smsPeriod,
      };

      options.push(smsPeriodOption);
    }

    options = options.sort((a, b) => {
      if (b.value === null) {
        return 1;
      }

      const aValueString = a.value.replace(/[0-9]/g, '');
      const bValueString = b.value.replace(/[0-9]/g, '');

      if (aValueString === 'd' && ['w', 'm', 'y'].includes(bValueString)) {
        return -1;
      }

      if (aValueString === 'w' && ['m', 'y'].includes(bValueString)) {
        return -1;
      }

      if (aValueString === 'm' && ['y'].includes(bValueString)) {
        return -1;
      }

      return 0;
    });

    return options;
  };

  return {
    getTranslatedSmsDuration,
    getSmsDurationOptions,
  };
};

export default useService;
